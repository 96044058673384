import React from 'react';
import { Modal } from 'react-bootstrap';
import useTirRev from '../useTirRev';

function ModalTautan(props) {
    const { tirRevAction, tirRevState } = useTirRev();
    return (
        <div>
            <Modal
                dialogClassName='modal-editor-trainee'
                backdropClassName='bg-transparent'
                show={tirRevState.modalTautan?.show}
                onHide={() => tirRevAction.resetModalTautan()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Link Video
                    </Modal.Title>
                </Modal.Header>
                <form
                    onSubmit={(e) => {
                        e.preventDefault(e);
                        tirRevAction.confirmAddUpdate({
                            "tir_id": tirRevState.formDataTautan?.tir_id,
                            "video_url": tirRevState.formDataTautan?.url,
                            "training_id": tirRevState.formDataTautan?.training_id
                        });
                    }}
                >

                    <Modal.Body>

                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">URL</label>
                            <div className="col-sm-10">
                                <div className='text-muted border border-secondary p-2 rounded'>
                                    {tirRevState.formDataTautan?.url}
                                </div>
                                {/* <input
                                    value={tirRevState.formDataTautan?.url}
                                    onChange={(e) => tirRevAction.setFormDataTautan((prev) => ({ ...prev, url: e.target.value }))}
                                    required
                                    type="url"
                                    placeholder='example: https://frontlinebuma.afledu.com/'
                                    className="form-control"
                                /> */}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            // tirRevState.modalTautan?.modeEdit ? (
                            //     <>
                            //         <button type='button' className='btn btn-sm btn-danger mr-1'
                            //             onClick={() => tirRevAction.confirmDelete(tirRevState.formDataTautan)}
                            //         >Delete</button>
                            //         <a href={tirRevState.formDataTautan?.url} target="_blank">
                            //             <button type='button' className='btn btn-sm btn-primary mr-1'>Go to URL</button>
                            //         </a>
                            //         <button type='submit' className='btn btn-sm btn-success mr-1'>Save</button>
                            //     </>
                            // ) : (
                            //     <>
                            //         <button
                            //             type='button'
                            //             onClick={() => tirRevAction.resetModalTautan()}
                            //             className='btn btn-sm btn-secondary mr-1'>Cancel</button>
                            //         <button type='submit' className='btn btn-sm btn-success mr-1'>Save</button>
                            //     </>
                            // )
                        }
                        <a href={tirRevState.formDataTautan?.url} target="_blank">
                            <button type='button' className='btn btn-sm btn-primary mr-1'>Go to URL</button>
                        </a>
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    );
}

export default ModalTautan;