import React from 'react';
import { TableList } from '../../../../components';
import useTirRev from '../useTirRev';

function Index(props) {
    const { tirRevAction, tirRevState } = useTirRev();
    return (
        <div className='card'>
            <div className='card-body'>
                <div className='row mb-3'>
                    <div className='col-md-12 col-sm-12'>
                        <TableList
                            isLoading={tirRevState.loadingTraining}
                            data={tirRevState.listTraining || []}
                            withCard={false}
                            columns={[
                                {
                                    name: '#',
                                    field: '#',
                                },
                                {
                                    name: 'Competency',
                                    field: 'competency_name',
                                },
                                {
                                    name: 'Course',
                                    field: 'course_name',
                                },
                                {
                                    name: 'Form',
                                    field: '',
                                    minWidth: 300,
                                    onRender: (item) => (
                                        <div>
                                            <ul>
                                                {
                                                    [...(item?.listForm || [])].map((d, i) => (
                                                        <>
                                                            {
                                                                d?.status ? (
                                                                    <li className='text-mutted'
                                                                    // onClick={() => tirRevAction.toFormPage({ ...d, trainee_nik: tirRevState.pageState?.dataPage?.trainee_nik }, true)}
                                                                    >Form {i + 1} {d?.status ? `(${d?.status})` : ``}</li>
                                                                ) : (
                                                                    <li className='text-primary' role={'button'}
                                                                        onClick={() => tirRevAction.toFormPage({ ...d, trainee_nik: tirRevState.pageState?.dataPage?.trainee_nik }, true)}
                                                                    >Form {i + 1}</li>
                                                                )
                                                            }
                                                        </>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    )
                                },
                                {
                                    name: 'Link Video',
                                    field: '',
                                    minWidth: 300,
                                    onRender: (item) => (
                                        <div>
                                            <ul>
                                                {
                                                    [...(item?.listVideoUrl || [])].map((d, i) => (
                                                        <>
                                                            {
                                                                d?.status ? (
                                                                    <li key={i} className='text-mutted'
                                                                        role={'button'} >Link {i + 1} {d?.status ? `(${d?.status})` : ``}</li>
                                                                ) : (
                                                                    <li key={i} className='text-primary' role={'button'} onClick={() => tirRevAction.setModalTautan({
                                                                        show: true,
                                                                        modeEdit: true,
                                                                        data: { ...d, training_id: item?.training_id, trainee_nik: tirRevState.pageState?.dataPage?.trainee_nik }
                                                                    })}>Link {i + 1}</li>
                                                                )
                                                            }
                                                        </>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    )
                                },
                                // {
                                //     name: '',
                                //     field: '',
                                //     minWidth: 200,
                                //     onRender: (item) => (
                                //         <div>
                                //             <button className='btn btn-sm btn-success mr-1 mb-1'>Approve</button>
                                //             <button className='btn btn-sm btn-danger mr-1 mb-1'>Decline</button>
                                //         </div>
                                //     )
                                // },
                            ]}
                            withActions={false}
                            pagination={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;