import React from 'react';
import { Form } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { DateInput, SelectSearch, StatusIcon, TableList } from '../../../../components';
import useCreateSchedule, { CrScProvider } from './useCreateSchedule';
import moment from 'moment';
import './index.css';
import { toInteger } from 'lodash';

function Index(props) {
    const { crScState, crScAction } = useCreateSchedule();
    const history = useHistory();
    return (
        <div>
            <section className="content-header">
                <div className="container-fluid">
                    <div className='row'>
                        <div className='col-md-6'>
                            <h4>Create Annual Training Schedule</h4>
                            <h6>Create Schedule</h6>
                        </div>
                        <div className='col-md-6'>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-end">
                                    <li className="breadcrumb-item"><Link to={"/ats/ats-create"}>List Jobsite</Link></li>
                                    <li className="breadcrumb-item"><Link to={"#"} onClick={() => history.goBack()}>List IDP</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Create Schedule</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <div className='card'>
                <div className='card-body'>
                    <div className='font-weight-bold'><label style={{ width: '150px' }}>Nama Jobsite</label>: {crScState.dataState?.jobsite_name}</div>
                    <div className='font-weight-bold'><label style={{ width: '150px' }}>Nama Training</label>: {crScState.dataState?.course_name}</div>
                    <div className='font-weight-bold'><label style={{ width: '150px' }}>Peserta Terdaftar</label>: <span className='bg-warning p-y px-5 rounded'>{crScState.dataGet?.totalTrainee || 0}</span></div>
                </div>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <h5 className='font-weight-bold text-center mb-5'>Form Create</h5>
                    <Form
                        noValidate
                        validated={crScState.validated}
                        onSubmit={(e) => {
                            const form = e.currentTarget;
                            e.preventDefault(e);
                            e.stopPropagation(e);
                            if (form.checkValidity() === true) {
                                if (crScState.formData?.trainer_nik) {
                                    crScAction.onSubmit();
                                }
                            }
                            crScAction.setValidated(true);
                        }}
                    >
                        <div className='row'>
                            <div className='col-md-6 col-sm-12'>
                                <div className='form-group row'>
                                    <label className='col-sm-3'>Trainer</label>
                                    <div className='col-sm-9'>
                                        <SelectSearch
                                            zIndex={2}
                                            placeholder={crScState.loadingTrainer ? "Loading Data" : "Select Trainer"}
                                            required={crScState.validated}
                                            options={crScState?.dataTrainer}
                                            onMenuOpen={crScAction.getTrainer}
                                            keyValue='nik'
                                            display='name'
                                            isInValid={crScState.formData?.trainer_nik === '' && crScState.validated ? true : false}
                                            value={crScState.formData?.trainer_nik}
                                            onChange={(val) => crScAction.handleChange('trainer_nik', val.value)}
                                        />
                                        <div className="invalid-feedback">
                                            Please select trainer.
                                        </div>
                                    </div>
                                </div>
                                <div className='form-group row'>
                                    <label className='col-sm-3'>Kuota Training</label>
                                    <div className='col-sm-9'>
                                        <input
                                            required
                                            type='number'
                                            min={1}
                                            max={
                                                (crScState.dataGet?.totalTrainee || 0) //- [...(crScState.dataTable || [])].reduce((tot, prev) => tot += toInteger(prev?.quota, 0), 0)
                                            }
                                            className='form-control'
                                            value={crScState.formData?.quota}
                                            onChange={(e) => crScAction.handleChange('quota', e.target.value)}
                                        ></input>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-sm-12'>
                                <div className='form-group row'>
                                    <label className='col-sm-3'>Start Date</label>
                                    <div className='col-sm-9'>
                                        <DateInput className='form-control'
                                            popperClassName='z-index-date-input'
                                            required
                                            // minDate={moment().format('YYYY-MM-DD')}
                                            value={crScState.formData?.start_date}
                                            onChange={(val) => crScAction.handleChange('start_date', val)}
                                        />
                                    </div>
                                </div>
                                <div className='form-group row'>
                                    <label className='col-sm-3'>Duration</label>
                                    <div className='col-sm-9'>
                                        <input type='number' className='form-control' min={1}
                                            required
                                            value={crScState.formData?.duration}
                                            onChange={(val) => crScAction.handleChange('duration', val.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='form-group row'>
                                    <label className='col-sm-3'>End Date</label>
                                    <div className='col-sm-9'>
                                        <span className='text-muted p-2'>{moment(crScState.formData?.end_date).format('DD/MM/YYYY') === 'Invalid date' ? '' : moment(crScState.formData?.end_date).format('DD/MM/YYYY')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='text-md-right'>
                            <button type='submit' className='btn btn-sm btn-primary'>Add</button>
                        </div>
                    </Form>
                    <TableList
                        curPage={1}
                        minHeight="300px"
                        maxHeight="500px"
                        navtable={"TABLE 1"}
                        withCard={false}
                        isLoading={crScState.loadingGetTable}
                        // onChangeShow={rekAction.changeShow}
                        // onNextPage={rekAction.nextPage}
                        // onPrevPage={rekAction.prevPage}
                        withActions={false}
                        pagination={false}
                        columns={[
                            {
                                name: '#',
                                field: '#',
                                maxWidth: '50px',
                            },
                            {
                                name: 'Trainer',
                                field: 'trainer_nik',
                                onRender: (item) => (
                                    <span className='d-block text-wrap'>{item?.trainer_nik} - {item?.trainer_name}</span>
                                )
                            },
                            {
                                name: 'Kuota Training',
                                field: 'quota',
                            },
                            {
                                name: 'Start',
                                field: 'start_date',
                                onRender: (item) => (
                                    <span className='text-wrap'>{moment(item?.start_date).format('DD/MM/YYYY')}</span>
                                )
                            },
                            {
                                name: 'Finish',
                                field: 'end_date',
                                onRender: (item) => (
                                    <span className='text-wrap'>{moment(item?.end_date).format('DD/MM/YYYY')}</span>
                                )
                            },
                            {
                                name: 'Status',
                                field: 'status',
                                onRender: (item, index) => {
                                    return (
                                        <StatusIcon status={item?.status} />
                                    )
                                }
                            },
                            {
                                name: '',
                                field: 'status',
                                onRender: (item, index) => {
                                    return (
                                        <div>
                                            {
                                                (item?.status !== 'submitted' && item?.status !== 'declined') && (
                                                    <button className='btn btn-sm btn-danger'
                                                        onClick={() => crScAction.deleteDataKirim(item?.ats_id, index)}
                                                    >Delete</button>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            },
                        ]}
                        data={[...(crScState.dataTable || [])]}
                    />

                </div>
                <div className='card-footer'>
                    <div className='text-md-right'>
                        <button className='btn btn-sm btn-light mr-1 '
                            onClick={() => crScAction.confirmSubmit('draft')}
                        >Save as Draft</button>
                        {
                            [...(crScState.dataTable || [])].some(d => d?.status !== 'submitted') && (
                                <button className='btn btn-sm btn-primary'
                                    onClick={() => crScAction.confirmSubmit('submitted')}
                                >Submit</button>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default (props) => <CrScProvider><Index {...props} /></CrScProvider>;