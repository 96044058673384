import moment from 'moment';
import React from 'react';
import useTirTrainer, { TirTrainerProvider } from './useTirTrainer';
import { DateInput } from '../../../components';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function Page(props) {
    const { tirAction, tirState } = useTirTrainer();
    return (
        <div>
            <section className="content-header">
                <div className="container-fluid">
                    <div className='row'>
                        <div className='col-md-6'>
                            <h4>Development</h4>
                            <h6>Tir For Trainer</h6>
                        </div>
                        <div className='col-md-6'>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-end">
                                    <li className="breadcrumb-item" aria-current="page">
                                        <Link to="/development/tir-for-trainer"> Tir For Trainer </Link> </li>
                                    <li className="breadcrumb-item" aria-current="page" onClick={() => tirAction.formBackButton()}>
                                        <Link to="#"> Detail </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page"> Form </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <form
                onSubmit={(e) => {
                    e.preventDefault(e);
                    tirAction.confirmAddUpdateForm(Object.keys((tirState.formData || {})).map((d) => {
                        const x = { ...tirState.formData[d] }
                        if (x?.answer_type === 'img') {
                            return {
                                answer_type: x?.answer_type,
                                answer_id: x?.answer_id || 'XX',
                                form_id: x?.form_id,
                                img_id: x?.value
                            }
                        } else {
                            return {
                                answer_type: x?.answer_type,
                                answer_id: x?.answer_id || 'XX',
                                form_id: x?.form_id,
                                value: x?.value
                            }
                        }
                    }), tirState.form, tirState.form?.dataPage?.modeEdit, tirState.form?.dataPage?.detail?.title)
                }}
            >
                {
                    (tirState.form?.dataPage?.detail?.status === "declined") && (
                        <div className='card mb-3' style={{ backgroundColor: '#ffe2e5' }}>
                            <div className='card-body text-danger'>
                                <h5 className='text-center'>Declined</h5>
                                <h7>Note :</h7>
                                <span>{tirState.form?.dataPage?.detail?.note}</span>
                            </div>
                        </div>
                    )
                }
                <div className='card'>
                    <div className='card-body p-5'>
                        {
                            [...(tirState.listForm || [])].map(({ sub_title_en, sub_title_id, questions = [] }, i) => (
                                <div className='form row mb-5' key={i}>
                                    <div className='col-md-12'>
                                        <div className='text-center'>
                                            <span className='d-block text-center font-weight-bold mb-1'>{sub_title_en}</span>
                                            <span className='d-block font-weight-normal font-italic mb-5'>{sub_title_id}</span>
                                        </div>
                                        <div className='row'>
                                            {
                                                [...(questions || [])].map(({
                                                    question_parent,
                                                    question_parent_id,
                                                    type_list,
                                                    position,
                                                    sub_questions = [] }, k) => (
                                                    <div className={position === null ? `col-md-12` : `col-md-${position?.split('-')?.[1]}`} key={`${i}${k}`}>
                                                        {
                                                            question_parent && <div className='d-inline-flex font-weight-bold border-bottom border-dark mb-2'>{question_parent}</div>
                                                        }
                                                        <div className={type_list === "horizontal" ? "row" : ""}>

                                                            {
                                                                [...(sub_questions || [])].map((d, j) => (
                                                                    <>
                                                                        {
                                                                            d?.answer_type === 'longtext' ? (
                                                                                <div className="form-group" key={`${i}${k}${j}`}>
                                                                                    <span className='d-block font-weight-bold mb-2'>{d?.question_en} <span className='font-weight-normal font-italic'>/ {d?.question_id}</span>{d?.is_required ? <span className='text-danger pl-1'>*</span> : null}</span>
                                                                                    <CreateForm form={d} />
                                                                                </div>
                                                                            ) : d?.answer_type === 'checkbox' && type_list === 'horizontal' ? (
                                                                                <div className="col-md-3" key={`${i}${k}${j}`}>
                                                                                    <div className={question_parent ? "form-check" : "form-check pl-0"}>
                                                                                        <div className='row'>
                                                                                            <div className='col col-sm-7'>
                                                                                                <label className="form-check-label float-left" htmlFor={d?.id}>
                                                                                                    <span className='d-block font-weight-bold'>{d?.question_en}{d?.is_required ? <span className='text-danger'>*</span> : null}</span>
                                                                                                    <small className='font-italic'>{d?.question_id}</small>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className='col-auto col-sm-5 text-center'>
                                                                                                <CreateForm form={d} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ) : d?.answer_type === 'img' ? (
                                                                                <div className="form-group" key={`${i}${k}${j}`}>
                                                                                    <span className='d-block font-weight-bold mb-2'>{d?.question_en} <span className='font-weight-normal font-italic'>/ {d?.question_id}</span>{d?.is_required ? <span className='text-danger pl-1'>*</span> : null}</span>
                                                                                    <CreateForm form={d} />
                                                                                </div>
                                                                            ) : (
                                                                                <>
                                                                                    {
                                                                                        (d?.answer_type === "checkbox" || d?.answer_type === "optional") && (
                                                                                            <div className={question_parent ? "form-check" : "form-check pl-0"} key={`${i}${k}${j}`}>
                                                                                                <div className='row'>
                                                                                                    <div className='col col-sm-7'>
                                                                                                        <label className="form-check-label float-left" htmlFor={d?.id}>
                                                                                                            <span className='d-block font-weight-bold'>{d?.question_en}{d?.is_required ? <span className='text-danger pl-1'>*</span> : null}</span>
                                                                                                            <small className='font-italic'>{d?.question_id}</small>
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='col-auto col-sm-5 text-center'>
                                                                                                        <CreateForm form={d} />
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                    {
                                                                                        (d?.answer_type !== "checkbox" && d?.answer_type !== "optional") && (
                                                                                            <div className="form-group row" key={`${i}${k}${j}`}>
                                                                                                <div className='col-sm-7 col-form-label py-0'>
                                                                                                    <span className='d-block font-weight-bold'>{d?.question_en}{d?.is_required ? <span className='text-danger pl-1'>*</span> : null}</span>
                                                                                                    <small className='font-italic'>{d?.question_id}</small>
                                                                                                </div>
                                                                                                <div className="col-sm-5">
                                                                                                    <CreateForm form={d} />
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                </>
                                                                            )
                                                                        }
                                                                    </>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        <div className="form-row mb-3">
                            <div className='col'>
                                <button
                                    type='button'
                                    onClick={() => tirAction.formBackButton()}
                                    className='btn btn-sm btn-secondary'>Back</button>
                            </div>
                            <div className='col text-right'>
                                {
                                    tirState.form?.dataPage?.modeEdit === false ? (
                                        <>
                                            <button
                                                type='submit'
                                                className='btn btn-sm btn-success'>Submit</button>
                                        </>
                                    ) : (
                                        <>
                                            <button
                                                type="button"
                                                className='btn btn-sm btn-primary mr-1'
                                                onClick={() => window.print()}
                                            ><i className='fa fa-print mr-1'></i> Print</button>
                                            {/* {
                                                (tirState.form?.dataPage?.detail?.status !== "approved") &&
                                                <button
                                                    type='button'
                                                    className='btn btn-sm btn-danger mr-1'
                                                    onClick={() => tirAction.confirmDeleteForm(tirState.form?.dataPage?.detail)}
                                                >Delete</button>
                                            }
                                            {
                                                (tirState.form?.dataPage?.detail?.status === "submitted") && (
                                                    <button
                                                        type='submit'
                                                        className='btn btn-sm btn-success mr-1'>Upadate</button>
                                                )
                                            } */}
                                        </>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
function CreateForm({ form }) {
    const { tirAction, tirState } = useTirTrainer();
    if (form.answer_type === "string")
        return (
            <div className="form-group">
                <input
                    placeholder={form?.question_id}
                    name={form?.id}
                    required={form?.is_required}
                    className="form-control form-control-sm"
                    disabled
                    value={tirState.formData[form?.id]?.value}
                    onChange={(e) => tirAction.handleChangeForm(form?.id, {
                        "form_id": form?.id,
                        "value": e.target.value,
                        "answer_id": tirState.formData[form?.id]?.answer_id || 'XX',
                        "answer_type": form?.answer_type
                    })}
                />
            </div>
        )
    if (form.answer_type === "date")
        return (
            <div className="form-group">
                <DateInput className='form-control'
                    popperClassName='z-index-date-input'
                    name={form?.id}
                    placeholder={form?.question_id}
                    required={form?.is_required}
                    disabled
                    value={tirState.formData[form?.id]?.value}
                    onChange={(e) => tirAction.handleChangeForm(form?.id, {
                        "form_id": form?.id,
                        "value": moment(e).format('YYYY-MM-DD'),
                        "answer_id": tirState.formData[form?.id]?.answer_id || 'XX',
                        "answer_type": form?.answer_type
                    })}
                />
            </div>
        )
    if (form.answer_type === "longtext")
        return (
            <div className="form-group">
                <textarea
                    name={form?.id}
                    placeholder={form?.question_id}
                    className="form-control form-control-sm"
                    rows={4}
                    required={form?.is_required}
                    disabled
                    value={tirState.formData[form?.id]?.value}
                    onChange={(e) => tirAction.handleChangeForm(form?.id, {
                        "form_id": form?.id,
                        "value": e.target.value,
                        "answer_id": tirState.formData[form?.id]?.answer_id || 'XX',
                        "answer_type": form?.answer_type
                    })}
                />
            </div>
        )
    if (form.answer_type === "optional")
        return (
            <div>
                <div className="form-check form-check-inline">
                    <label className="form-check-label mr-1" >Y</label>
                    <input className="form-check-input" type="radio" name={form?.id} id={form?.id + "Y"}
                        value={'Y'}
                        placeholder={form?.question_id}
                        required={form?.is_required}
                        disabled
                        checked={tirState.formData[form?.id]?.value === 'Y'}
                        onChange={(e) => tirAction.handleChangeForm(form?.id, {
                            "form_id": form?.id,
                            "value": e.target.value,
                            "answer_id": tirState.formData[form?.id]?.answer_id || 'XX',
                            "answer_type": form?.answer_type
                        })}
                    />
                </div>
                <div className="form-check form-check-inline">
                    <label className="form-check-label mr-1" >N</label>
                    <input className="form-check-input" type="radio" name={form?.id} id={form?.id + "N"}
                        value={'N'}
                        placeholder={form?.question_id}
                        required={form?.is_required}
                        checked={tirState.formData[form?.id]?.value === 'N'}
                        disabled
                        onChange={(e) => tirAction.handleChangeForm(form?.id, {
                            "form_id": form?.id,
                            "value": e.target.value,
                            "answer_id": tirState.formData[form?.id]?.answer_id || 'XX',
                            "answer_type": form?.answer_type
                        })}
                    />
                </div>
            </div>
        )
    if (form.answer_type === "img")
        return (
            <div className='row'>
                <div className='col-md-12'>
                    <img className='mb-2' width={240} height={240} src={tirState.formData[form?.id]?.path}></img>
                </div>
                <div className='col-md-5'>
                    <input disabled
                        // required={form?.is_required ? (tirState.formData[form?.id]?.path ? false : true) : false}
                        onChange={(e) => tirAction.doUploadImage(e.target.files[0], form?.id, form?.answer_type, tirState.formData[form?.id]?.answer_id || 'XX')}
                        accept="image/png, image/jpeg"
                        className='form-control' type='file' />
                </div>
            </div>
        )
    if (form.answer_type === "checkbox")
        return (
            <input
                id={form?.id}
                type="checkbox"
                name={form?.id}
                placeholder={form?.question_id}
                required={form?.is_required}
                disabled
                checked={tirState.formData[form?.id]?.value}
                onChange={(e) => tirAction.handleChangeForm(form?.id, {
                    "form_id": form?.id,
                    "value": e.target.checked,
                    "answer_type": form?.answer_type,
                    "answer_id": tirState.formData[form?.id]?.answer_id || 'XX',
                })}
            />
        )
}

export default () => <TirTrainerProvider>
    <Page />
</TirTrainerProvider>

