import React from 'react';
import { TableList } from '../../../components';
import usePHMO from './usePHMO';

export default () => {


    const {
        PHMOState: {
            pageStatus: {
                isLoading = false,
                paramState: {
                    course_name = "",
                    competency_name = ""
                }
            },
            listTrainee = []
        },
        PHMOAction: {
            toApprovalPage = () => null,
            resetPage = () => null
        }
    } = usePHMO()
    return (<>
        <section className="content-header">
            <div className="container-fluid">
                <h4>Hours Meter (HM) Operator</h4>
                <h6>Pencapaian HM Operator</h6>
            </div>
        </section>

        <div className='row'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='card-header'>
                        <div className='card-title'>
                            <h5> List Trainee [ {competency_name} -{course_name} ] </h5>
                        </div>
                    </div>
                    <div className='card-body'>

                        <div className='row mt-2'>
                            <div className='col-md-12'>

                                <TableList
                                    isLoading={isLoading}
                                    minHeight='max-content'
                                    withCard={false}
                                    withActions={false}
                                    filter={false}
                                    data={listTrainee}
                                    pagination={false}
                                    columns={[
                                        {
                                            name: "Nama",
                                            field: "trainee_name"
                                        },
                                        {
                                            name: "NIK",
                                            field: "trainee_nik"
                                        },
                                        {
                                            name: "Total HM",
                                            field: "total_hm"
                                        },
                                        {
                                            name:"",
                                            className:"text-right",
                                            onRender: (item)=> <>
                                                <button
                                                    className='btn btn-sm btn-primary'
                                                    onClick={()=> toApprovalPage(item)}
                                                > Detail </button>
                                            </>
                                        }
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='card-footer'>
                        <button onClick={resetPage} className="btn btn-default"> Back </button>
                    </div>
                </div>
            </div>
        </div>

    </>)
}