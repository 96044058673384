import React from 'react';
import useMentoringAdmin from '../useMentoringAdmin';

function Index(props) {
    const { menAdminAction, menAdminState } = useMentoringAdmin();
    return (
        <div className='card p-3'>
            <div className='card-body'>
                <form
                    onSubmit={(e) => {
                        e.preventDefault(e);
                        menAdminAction.toTraineePage();
                    }}
                >
                    <div className="form-group">
                        <label htmlFor="inputEmail4">Cari berdasarkan NIK : </label>
                        <div className="input-group mb-3">
                            <input type="text"
                                value={menAdminState.searchNik}
                                required                            
                                onChange={(e) => menAdminAction.setSearchNik(e.target.value)}
                                className="form-control" placeholder="Cari berdasarkan NIK" aria-label="Cari berdasarkan NIK" aria-describedby="basic-addon2" />
                            <div className="input-group-append">
                                <button type='submit' className='btn btn-sm btn-primary'><i className='fa fa-search icon-nm mr-1'></i>Search</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Index;