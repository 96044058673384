import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useModalAlert } from "../../../components/modalAlert";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { useParams, useHistory } from 'react-router-dom';

const context = createContext({
    matrixCompState: {},
    matrixCompAction: {}
});

export function MatrixCompProvider({ children }) {
    const { tipe = "", ctipe = "" } = useParams();
    const history = useHistory();
    const queryParam = useQueryParams();
    const id = queryParam.get("id");
    const cid = queryParam.get("cid");
    const [formData, setFormData] = useState({});
    const [formDataCourse, setFormDataCourse] = useState({});
    const [disabledForm, setDisabledForm] = useState(true);
    const [pagination, setPagination] = useState({
        page: 1,
        show: 10,
        totalPage: 1,
        totalData: 0,
    });

    const [filter, setFilter] = useState({
        cluster: "",
        grade: "",
        group: "",
        name: "",
        type: ""
    });
    const { doGet, doPost, wait } = useFetch();
    const { modalAction } = useModalAlert();
    const {
        closeLoading,
        setDataAlert,
        setDataConfirm,
        closeConfirm,
        openLoading,
    } = modalAction;
    const [loadingList, setLoadingList] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [dataDetail, setDataDetail] = useState({});
    const [dataType, setDataType] = useState([]);

    const getDataList = useCallback(
        async function (page = pagination.page, type) {
            if(type || filter.type !== ""){
                setLoadingList(true);
                try {
                    const { status, statusText, payload } = await doPost({
                        url: "/api/data-master/list-competency-pagination",
                        data: {
                            ...filter,
                            type: type ?? filter.type,
                            page: page,
                            row_in_page: pagination.show,
                        },
                    });
    
                    if (status === 401) {
                        closeLoading();
                    } else if (status === 200) {
                        const { data = {}, code, msg } = payload;
                        if (code === "00") {
                            setPagination((v) => ({
                                ...v,
                                totalPage: data.totalPage,
                                totalData: data.totalData,
                            }));
                            setDataList(data.listData || []);
                            setFilter((prev) => ({ ...prev, batch: data?.batch, nik: data?.nik }));
                        } else
                            setDataAlert({
                                show: true,
                                body: msg,
                                header: "Get List Training",
                                callBack: () => null,
                            });
                    } else
                        setDataAlert({
                            show: true,
                            body: statusText,
                            header: "Get List Training",
                            callBack: () => null,
                        });
                } catch (error) {
                    setDataAlert({
                        show: true,
                        body: error.toString(),
                        header: "Get List Training",
                        callBack: () => null,
                    });
                }
                setLoadingList(false);
            }
        },
        [filter, dataType, pagination]
    );
    async function getAllType() {
        try {
            const { status, statusText, payload } = await doGet({
                url: '/api/data-master/list-type',
            });
            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDataType(data);
                    setFilter(s => ({ ...s, type: data[0] }) );
                    if(tipe === "" && ctipe === ""){
                        getDataList(1, data[0])
                    }
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get All Type",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get All Type",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get All Type",
                callBack: () => null
            })
        }
    }
    const getDetail = useCallback(
        async function () {
            setLoadingList(true);
            try {
                const { status, statusText, payload } = await doGet({
                    url: `/api/data-master/detail-competency/${id}`,
                });

                if (status === 401) {
                    closeLoading();
                } else if (status === 200) {
                    const { data = {}, code, msg } = payload;
                    if (code === "00") {
                        setFormData({ ...data?.data });
                        setDataDetail(data?.data);
                        if(ctipe){
                            setFormDataCourse(s => ({ ...s,
                                "type": data?.data?.type,
                                "competency_name": data?.data?.name,
                                "competency_id": data?.data?.id,
                                "name": ctipe === "add" ? "" : data?.data?.courses?.find(d => d.id == cid)?.name
                            }));
                        }
                    } else
                        setDataAlert({
                            show: true,
                            body: msg,
                            header: "Get Detail Training",
                            callBack: () => null,
                        });
                } else
                    setDataAlert({
                        show: true,
                        body: statusText,
                        header: "Get Detail Training",
                        callBack: () => null,
                    });
            } catch (error) {
                setDataAlert({
                    show: true,
                    body: error.toString(),
                    header: "Get Detail Training",
                    callBack: () => null,
                });
            }
            setLoadingList(false);
        },
        [id, cid, ctipe]
    );

    const doCreate = () => {
        setDataConfirm({
            show: true,
            body: "Apakah anda yakin?",
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                {
                    name: "Yes",
                    onClick: async () => {
                        closeConfirm();
                        await tambahData();
                    },
                    className: "btn btn-sm btn-success",
                },
                {
                    name: "No",
                    onClick: closeConfirm,
                    className: "btn btn-sm btn-secondary",
                },
            ],
        });
    }
    const tambahData = useCallback(
        async function () {
            openLoading("Tambah Data ...");
            try {
                const { status, statusText, payload } = await doPost({
                    url: `/api/data-master/add-competency`,
                    method: "POST",
                    data: {
                        "type": formData.type,
                        "cluster": formData.cluster,
                        "grade": formData.grade,
                        "group": formData.group,
                        "name": formData.name,
                        "point": formData.point,
                        "cluster_id": formData.cluster_id,
                    },
                    type: 1,
                });

                if (status === 401) {
                    closeLoading();
                } else if (status === 200) {
                    const { data = {}, code, msg } = payload;
                    if (code === "00") {
                        setDataAlert({
                            show: true,
                            body: msg,
                            header: "Success",
                            callBack: () => {
                                // getDetail();
                                history.push("/master-data/matrix-competency")
                            },
                        });
                    } else
                        setDataAlert({
                            show: true,
                            body: msg,
                            header: "Tambah data",
                            callBack: () => {
                                closeLoading();
                            },
                        });
                } else
                    setDataAlert({
                        show: true,
                        body: statusText,
                        header: "Update data",
                        callBack: () => {
                            closeLoading();
                        },
                    });
            } catch (error) {
                setDataAlert({
                    show: true,
                    body: error.toString(),
                    header: "Update data",
                    callBack: () => null,
                });
            }
            closeLoading();
        },
        [formData,]
    );

    const doUbah = () => {
        setDataConfirm({
            show: true,
            body: "Apakah anda yakin ingin meubah data?",
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                {
                    name: "Yes",
                    onClick: async () => {
                        closeConfirm();
                        await ubahData();
                    },
                    className: "btn btn-sm btn-success",
                },
                {
                    name: "No",
                    onClick: closeConfirm,
                    className: "btn btn-sm btn-secondary",
                },
            ],
        });
    }
    const ubahData = useCallback(
        async function () {
            openLoading("Update Data ...");
            try {
                const { status, statusText, payload } = await doPost({
                    url: `/api/data-master/update-competency?_method=PUT`,
                    method: "POST",
                    data: {
                        id,
                        "type": formData.type,
                        "cluster": formData.cluster,
                        "grade": formData.grade,
                        "group": formData.group,
                        "name": formData.name,
                        "point": formData.point,
                        "cluster_id": formData.cluster_id,
                    },
                    type: 1,
                });

                if (status === 401) {
                    closeLoading();
                } else if (status === 200) {
                    const { data = {}, code, msg } = payload;
                    if (code === "00") {
                        setDataAlert({
                            show: true,
                            body: msg,
                            header: "Success",
                            callBack: () => {
                                // getDetail();
                                history.push("/master-data/matrix-competency")
                            },
                        });
                    } else
                        setDataAlert({
                            show: true,
                            body: msg,
                            header: "Update data",
                            callBack: () => {
                                closeLoading();
                            },
                        });
                } else
                    setDataAlert({
                        show: true,
                        body: statusText,
                        header: "Update data",
                        callBack: () => {
                            closeLoading();
                        },
                    });
            } catch (error) {
                setDataAlert({
                    show: true,
                    body: error.toString(),
                    header: "Update data",
                    callBack: () => null,
                });
            }
            closeLoading();
        },
        [formData,]
    );

    const doDelete = (idDel) => {
        setDataConfirm({
            show: true,
            body: "Apakah akan menghapus data?",
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                {
                    name: "Yes",
                    onClick: async () => {
                        closeConfirm();
                        await deleteData(idDel);
                    },
                    className: "btn btn-sm btn-success",
                },
                {
                    name: "No",
                    onClick: closeConfirm,
                    className: "btn btn-sm btn-secondary",
                },
            ],
        });
    }
    const deleteData = useCallback(
        async function (idDelete) {
            openLoading("Menghapus Data ...");
            try {
                const { status, statusText, payload } = await doPost({
                    url: `/api/data-master/delete-competency/${idDelete}`,
                    method: "DELETE",
                });

                if (status === 401) {
                    closeLoading();
                } else if (status === 200) {
                    const { data = {}, code, msg } = payload;
                    if (code === "00") {
                        setDataAlert({
                            show: true,
                            body: msg,
                            header: "Success",
                            callBack: () => {
                                getDataList();
                            },
                        });
                    } else
                        setDataAlert({
                            show: true,
                            body: msg,
                            header: "Delete data",
                            callBack: () => {
                                closeLoading();
                            },
                        });
                } else
                    setDataAlert({
                        show: true,
                        body: statusText,
                        header: "Delete data",
                        callBack: () => {
                            closeLoading();
                        },
                    });
            } catch (error) {
                setDataAlert({
                    show: true,
                    body: error.toString(),
                    header: "delete data",
                    callBack: () => null,
                });
            }
            closeLoading();
        },
        [getDataList]
    );

    function nextPage({ page, limit }) {
        setPagination((prevState) => ({
            ...prevState,
            page,
            show: limit,
        }));
    }
    function prevPage({ page, limit }) {
        setPagination((prevState) => ({
            ...prevState,
            page,
            show: limit,
        }));
    }
    function changeShow({ page, limit }) {
        setPagination((prevState) => ({
            ...prevState,
            page,
            show: limit,
        }));
    }

    function handleChangeFom(name, val) {
        setFormData((prev) => ({
            ...prev,
            [name]: val
        }));
    }
    function doFilter() {
        getDataList(1);
        setPagination((v) => ({
            ...v,
            page: 1,
        }));
    }

    const doUpload = useCallback(
        function () {
            let body = "Pastikan form terisi dengan benar. Lanjutkan?";
            if (!formData?.type) {
                return setDataAlert({
                    body: "Harap pilih tipe",
                    show: true,
                    header: "Info",
                    callBack: () => { },
                });
            }
            if (!formData.file) {
                return setDataAlert({
                    body: "File Belum ada",
                    show: true,
                    header: "Info",
                    callBack: () => { },
                });
            }
            setDataConfirm({
                show: true,
                body,
                witTextArea: false,
                header: "Confirmation",
                title: "",
                closeButton: false,
                footerButton: [
                    {
                        name: "Yes",
                        onClick: async () => {
                            closeConfirm();
                            openLoading("Uploading ...");
                            try {
                                const { status, payload, statusText } =
                                    await doPost({
                                        url: "/api/data-master/add-competency-course-bulk",
                                        data: {
                                            ...formData,
                                        },

                                        type: 1,
                                        paramImage: "file",
                                    });

                                if (status !== 401) {
                                    if (status === 200 || status === 201) {
                                        const { code, msg } = payload;
                                        if (code === "00") {
                                            setDataAlert({
                                                body: "Unggah file berhasil",
                                                show: true,
                                                header: "Success",
                                                callBack: () => {
                                                    getDataList();
                                                    setFormData({});
                                                },
                                            });
                                        } else
                                            setDataAlert({
                                                show: true,
                                                body: msg,
                                                header: "Info",
                                                callBack: () => null,
                                            });
                                    } else
                                        setDataAlert({
                                            show: true,
                                            body: statusText,
                                            header: "Info",
                                            callBack: () => null,
                                        });
                                }
                            } catch (error) {
                                setDataAlert({
                                    show: true,
                                    body: error.toString(),
                                    header: "Info",
                                    callBack: () => null,
                                });
                            }
                            closeLoading();
                        },
                        className: "btn btn-sm btn-success",
                    },
                    {
                        name: "No",
                        onClick: closeConfirm,
                        className: "btn btn-sm btn-secondary",
                    },
                ],
            });
        },
        [formData, getDataList]
    );

    // course
    function handleChangeFomCourse(name, val) {
        setFormDataCourse((prev) => ({
            ...prev,
            [name]: val
        }));
    }

    const actCourse = useCallback(async (ncid, actType) => {
        let objCourse = {
            add: {
                msgTitle: "Tambah data",
                path: "add-course",
                method: "POST",
            },
            ubah: {
                msgTitle: "Update data",
                path: "update-course?_method=PATCH",
                method: "POST",
            },
            delete: {
                msgTitle: "Delete data",
                path: `delete-course/${ncid}`,
                method: "DELETE",
            },
        }
        let { msgTitle, path, method } = objCourse[actType];
        openLoading(`${msgTitle} ...`);
        try {
            const { status, statusText, payload } = await doPost({
                url: `/api/data-master/${path}`,
                method,
                data: { id: ncid,
                    "type": formDataCourse.type,
                    "competency_id": formDataCourse.competency_id,
                    "competency_name": formDataCourse.competency_id,
                    "name": formDataCourse.name
                },
                type: 1,
            });

            if (status === 401) {
                closeLoading();
            } else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDataAlert({
                        show: true,
                        body: msg,
                        header: "Success",
                        callBack: () => {
                            if(actType === "delete"){
                                getDetail(formDataCourse.competency_id)
                            } else {
                                history.push(`/master-data/matrix-competency/detail?id=${dataDetail?.id}`)
                            }
                        },
                    });
                } else
                    setDataAlert({
                        show: true,
                        body: msg,
                        header: msgTitle,
                        callBack: () => {
                            closeLoading();
                        },
                    });
            } else
                setDataAlert({
                    show: true,
                    body: statusText,
                    header: msgTitle,
                    callBack: () => {
                        closeLoading();
                    },
                });
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: msgTitle,
                callBack: () => null,
            });
        }
        closeLoading();
    },[formDataCourse]);

    const doActCourse = (ncid) => {
        let body = ctipe === "add" ? "Apakah anda yakin?" : (ctipe === "ubah" ? 
            "Apakah anda yakin ingin meubah data?" : "Apakah akan menghapus data?"
        );
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                {
                    name: "Yes",
                    onClick: async () => {
                        closeConfirm();
                        await actCourse(ncid || cid, ctipe || "delete");
                    },
                    className: "btn btn-sm btn-success",
                },
                {
                    name: "No",
                    onClick: closeConfirm,
                    className: "btn btn-sm btn-secondary",
                },
            ],
        });
    }

    useEffect(() => {
        if(dataType.length === 0){
            getAllType();
        }
        if (tipe === "") {
            getDataList();
        }
        if (tipe === "add") {
            setDisabledForm(false);
        }
        if (tipe === "ubah" && id) {
            setDisabledForm(false);
            getDetail(id);
        }
        if (tipe === "detail" && id) {
            setDisabledForm(true);
            getDetail(id);
        }

        if (["add", "ubah", "detail"].includes(ctipe) && id) {
            getDetail(id);
        }
    }, [pagination.page, pagination.show, dataType.length, tipe, ctipe, id]);

    return <context.Provider
        value={{
            matrixCompState: {
                loadingList,
                dataList,
                dataDetail,
                pagination,
                formData,
                tipe,
                ctipe,
                disabledForm,
                filter,
                dataType,
                formDataCourse
            },
            matrixCompAction: {
                nextPage,
                prevPage,
                changeShow,
                handleChangeFom,
                setFilter,
                doCreate,
                doUbah,
                doDelete,
                doFilter,
                doUpload,
                handleChangeFomCourse,
                doActCourse
            }
        }}
    >{children}</context.Provider>
}

export default function useMatrixComp() {
    return useContext(context);
}
