import moment from 'moment';
import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { DateInput, MultipleSelect, SelectSearch, TableList } from '../../../../components';
import useLsIdp from '../ls-idp/useLsIdp';
import useCreateMts, { CrtMtsProvider } from './useCreateMts';

function Index(props) {
    const { crtMtsAction, crtMtsState } = useCreateMts();
    const { lsIdpAction } = useLsIdp();
    useEffect(() => {
        crtMtsAction.setForm(props?.data, props?.modeEdit);
    }, [])
    return (
        <div>
            {/* <section className="content-header">
                <div className="container-fluid">
                    <div className='row'>
                        <div className='col-md-6'>
                            <h4>MTS Create</h4>
                            <h6>Create MTS</h6>
                        </div>
                        <div className='col-md-6'>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-end">
                                    <li className="breadcrumb-item"><Link to={"/ats/mts-create"}>List Jobsite</Link></li>
                                    <li className="breadcrumb-item"><Link to={"#"} onClick={() => history.goBack()}>List IDP</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Create MTS</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <Form
                validated={crtMtsState.validated}
                noValidate
                onSubmit={(e) => {
                    const form = e.currentTarget;
                    e.preventDefault(e);
                    e.stopPropagation(e);
                    if (form.checkValidity() === true) {
                        // console.log(crtMtsState.formData?.training_id, props?.data?.trainees, [...(props?.data?.trainees || [])].filter(d => ![...(crtMtsState.formData?.training_id || [])].some(x => x?.value === d?.training_id)))
                        if (crtMtsState.formData?.trainer_nik !== '' && [...(crtMtsState.formData?.training_id || [])].length > 0) {
                            props?.onSubmit({
                                ...crtMtsState.formData,
                                training_id: [...(crtMtsState.formData?.training_id || [])],
                                deleted_training_ids: props?.modeEdit ? [...(props?.data?.trainees || [])].filter(d => ![...(crtMtsState.formData?.training_id || [])].some(x => x === d?.training_id)).map(y => y.training_id).filter(z => z !== null) : []
                            });
                        }
                    }
                    crtMtsAction.setValidated(true)
                }}
            >
                <div className='row'>
                    <div className='col-md-6 mb-3'>
                        <div className="form-group row mb-0">
                            <label htmlFor="staticEmail" className="col-sm-4 col-form-label">Nama Jobsite</label>
                            <div className="col-sm-8">
                                <span className="form-control-plaintext">{props?.data?.jobsite_name}</span>
                            </div>
                        </div>
                        <div className="form-group row mb-0">
                            <label htmlFor="staticEmail" className="col-sm-4 col-form-label">Category</label>
                            <div className="col-sm-8">
                                <span className="form-control-plaintext">{props?.data?.type}</span>
                            </div>
                        </div>
                        <div className="form-group row mb-0">
                            <label htmlFor="staticEmail" className="col-sm-4 col-form-label">Training Name</label>
                            <div className="col-sm-8">
                                <span className="form-control-plaintext">{props?.data?.course_name}</span>
                            </div>
                        </div>
                        <div className="form-group row mb-0">
                            <label htmlFor="staticEmail" className="col-sm-4 col-form-label">Trainer</label>
                            <div className="col-sm-8">
                                <SelectSearch
                                    zIndex={2}
                                    placeholder={crtMtsState.loadingTrainer ? "Loading Data" : "Select Trainer"}
                                    required={crtMtsState.validated}
                                    options={crtMtsState?.dataTrainer}
                                    onMenuOpen={() => crtMtsAction.getTrainer(props?.data?.jobsite_code, props?.data?.type)}
                                    keyValue='nik'
                                    display='name'
                                    isInValid={crtMtsState.formData?.trainer_nik === '' && crtMtsState.validated ? true : false}
                                    value={crtMtsState.formData?.trainer_nik}
                                    onChange={(val) => crtMtsAction.handleChange('trainer_nik', val.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 mb-3'>
                        <div className="form-group row mb-2">
                            <label htmlFor="staticEmail" className="col-sm-4 col-form-label pt-0">Kuota Training</label>
                            <div className="col-sm-8">
                                <span className='text-wrap'>{props?.data?.quota}</span>
                            </div>
                        </div>
                        <div className="form-group row mb-2">
                            <label htmlFor="staticEmail" className="col-sm-4 col-form-label pt-0">Start</label>
                            <div className="col-sm-8">
                                <DateInput className='form-control'
                                    popperClassName='z-index-date-input'
                                    required
                                    maxDate={crtMtsState.formData?.end_date}
                                    value={crtMtsState.formData?.start_date}
                                    onChange={(val) => crtMtsAction.handleChange('start_date', val)}
                                />
                            </div>
                        </div>
                        <div className="form-group row mb-2">
                            <label htmlFor="staticEmail" className="col-sm-4 col-form-label pt-0">Finish</label>
                            <div className="col-sm-8">
                                <DateInput className='form-control'
                                    popperClassName='z-index-date-input'
                                    required
                                    minDate={crtMtsState.formData?.start_date}
                                    value={crtMtsState.formData?.end_date}
                                    onChange={(val) => crtMtsAction.handleChange('end_date', val)}
                                />
                            </div>
                        </div>
                        <div className="form-group row mb-2">
                            <label htmlFor="staticEmail" className="col-sm-4 col-form-label pt-0">Trainee</label>
                            <div className="col-sm-8">
                                <MultipleSelect
                                    // placeholder={atsState.loadingTrainee ? "Loading Data" : 'Select Trainee'}
                                    required
                                    isInValid={[...(crtMtsState.formData?.training_id || [])].length <= 0 && crtMtsState.validated ? true : false}
                                    options={crtMtsState?.dataTrainee}
                                    // onMenuOpen={() => crtMtsAction.getSourceTrainee(props?.data?.course_name)}
                                    keyValue='training_id'
                                    display='trainee_name'
                                    value={[...(crtMtsState.formData?.training_id || [])]}
                                    onChange={(val) => crtMtsAction.handleChange('training_id', val)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 text-md-right'>
                        <button type='button' className='btn btn-sm btn-secondary mr-1'
                            onClick={() => lsIdpAction?.setModal({
                                show: false,
                                data: {},
                                modeEdit: false
                            })}
                        >Batal</button>
                        <button type='submit' className='btn btn-sm btn-primary'>Save</button>
                    </div>
                </div>
            </Form> */}
            <div className='row'>
                <div className='col-md-6 mb-3'>
                    <div className="form-group row mb-0">
                        <label htmlFor="staticEmail" className="col-sm-4 col-form-label">Nama Jobsite</label>
                        <div className="col-sm-8">
                            <span className="form-control-plaintext">{props?.data?.jobsite_name}</span>
                        </div>
                    </div>
                    <div className="form-group row mb-0">
                        <label htmlFor="staticEmail" className="col-sm-4 col-form-label">Category</label>
                        <div className="col-sm-8">
                            <span className="form-control-plaintext">{props?.data?.type}</span>
                        </div>
                    </div>
                    <div className="form-group row mb-0">
                        <label htmlFor="staticEmail" className="col-sm-4 col-form-label">Training Name</label>
                        <div className="col-sm-8">
                            <span className="form-control-plaintext">{props?.data?.course_name}</span>
                        </div>
                    </div>
                    <div className="form-group row mb-0">
                        <label htmlFor="staticEmail" className="col-sm-4 col-form-label">Trainer</label>
                        <div className="col-sm-8">
                            <SelectSearch
                                zIndex={2}
                                placeholder={crtMtsState.loadingTrainer ? "Loading Data" : "Select Trainer"}
                                required={crtMtsState.validated}
                                options={crtMtsState?.dataTrainer}
                                onMenuOpen={() => crtMtsAction.getTrainer(props?.data?.jobsite_code, props?.data?.type)}
                                keyValue='nik'
                                display='name'
                                // disabled={!props?.modeEdit ? false : moment(moment(new Date)).isBefore(moment(crtMtsState.formData?.start_date)) ? false : true}
                                isInValid={crtMtsState.formData?.trainer_nik === '' && crtMtsState.validated ? true : false}
                                value={crtMtsState.formData?.trainer_nik}
                                onChange={(val) => crtMtsAction.handleChange('trainer_nik', val.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='col-md-6 mb-3'>
                    <div className="form-group row mb-2">
                        <label htmlFor="staticEmail" className="col-sm-4 col-form-label pt-0">Kuota Training</label>
                        <div className="col-sm-8">
                            <span className='text-wrap'>{props?.data?.quota}</span>
                        </div>
                    </div>
                    <div className="form-group row mb-2">
                        <label htmlFor="staticEmail" className="col-sm-4 col-form-label pt-0">Start</label>
                        <div className="col-sm-8">
                            <DateInput className='form-control'
                                popperClassName='z-index-date-input'
                                required
                                // disabled={!props?.modeEdit ? false : moment(moment(new Date)).isBefore(moment(crtMtsState.formData?.start_date)) ? false : true}
                                // minDate={!props?.modeEdit ? null : new Date()}
                                // maxDate={!props?.modeEdit ? null : moment(crtMtsState.formData?.end_date).toDate()}
                                value={crtMtsState.formData?.start_date}
                                onChange={(val) => crtMtsAction.handleChange('start_date', val)}
                            />
                        </div>
                    </div>
                    <div className="form-group row mb-2">
                        <label htmlFor="staticEmail" className="col-sm-4 col-form-label pt-0">Duration</label>
                        <div className="col-sm-8">
                            <input type='number' className='form-control' min={1}
                                required
                                value={crtMtsState.formData?.duration}
                                onChange={(val) => crtMtsAction.handleChange('duration', val.target.value)}
                            />
                            {/* <DateInput className='form-control'
                                popperClassName='z-index-date-input'
                                required
                                disabled={moment(moment(new Date)).isBefore(moment(crtMtsState.formData?.start_date)) ? false : true }
                                minDate={crtMtsState.formData?.start_date}
                                value={crtMtsState.formData?.end_date}
                                onChange={(val) => crtMtsAction.handleChange('end_date', val)}
                            /> */}
                        </div>
                    </div>
                    <div className="form-group row mb-2">
                        <label htmlFor="staticEmail" className="col-sm-4 col-form-label pt-0">Finish</label>
                        <div className="col-sm-8">
                            {crtMtsState.formData?.duration && (
                                <span>{moment(crtMtsState.formData?.end_date).format('DD/MM/YYYY')}</span>
                            )}
                            {/* <DateInput className='form-control'
                                popperClassName='z-index-date-input'
                                required
                                disabled={moment(moment(new Date)).isBefore(moment(crtMtsState.formData?.start_date)) ? false : true }
                                minDate={crtMtsState.formData?.start_date}
                                value={crtMtsState.formData?.end_date}
                                onChange={(val) => crtMtsAction.handleChange('end_date', val)}
                            /> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mb-5'>
                <div className='col-md-12'>
                    <h6 className='font-weight-bold'>Trainee</h6>
                    {
                        ([...(crtMtsState.formData?.training_id || [])].length <= 0 && crtMtsState.validated) && (
                            <span className='text-danger'>Trainee tidak boleh kosong</span>
                        )
                    }
                    <TableList
                        filter={true}
                        formsFilter={[
                            {
                                type: 'text',
                                placeholder: 'NIK',
                                value: crtMtsState.filter?.trainee_nik,
                                onChange: (val) => crtMtsAction.setFilter(e => ({ ...e, trainee_nik: val }))
                            },
                            {
                                type: 'text',
                                placeholder: 'Nama',
                                value: crtMtsState.filter?.trainee_name,
                                onChange: (val) => crtMtsAction.setFilter(e => ({ ...e, trainee_name: val }))
                            }
                        ]}
                        resetFilter={false}
                        onFilter={() => crtMtsAction.onFilter()}
                        isLoading={crtMtsState.loadingTrainee}
                        withCard={false}
                        minHeight="300px"
                        submitButton={<><i className='fa fa-search'></i> Search</>}
                        maxHeight="300px"
                        navtable={"TABLE 1"}
                        pagination={true}
                        withActions={false}
                        selectionMode={2}
                        onSelection={(e) => crtMtsAction.handleChange('training_id', e)}
                        fieldSelectionKey="training_id"
                        defaultSelection={[...(crtMtsState.formData?.training_id || [])]}

                        onChangeShow={crtMtsAction.changeShow}
                        onNextPage={crtMtsAction.nextPage}
                        onPrevPage={crtMtsAction.onPrevPage}
                        defaultShow={crtMtsState.pagination?.show}
                        curPage={crtMtsState.pagination?.page}
                        maxPage={crtMtsState.dataTrainee?.totalPage || 0}
                        orderData={{
                            order_by: crtMtsState.pagination.order_by,
                            order_type: crtMtsState.pagination.order_type
                        }}
                        onOrdering={crtMtsAction.onOrdering}
                        totalData={crtMtsState.dataTrainee?.totalData || 0}

                        columns={[
                            {
                                name: "#",
                                field: '#'
                            },
                            {
                                name: "Trainee NIK",
                                field: 'trainee_nik',
                            },
                            {
                                name: "Trainee Name",
                                field: 'trainee_name',
                            },
                        ]}
                        data={[...(crtMtsState?.dataTrainee?.listData || [])]}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12 text-md-right'>
                    <button type='button' className='btn btn-sm btn-secondary mr-1'
                        onClick={() => lsIdpAction?.setModal({
                            show: false,
                            data: {},
                            modeEdit: false
                        })}
                    >Batal</button>
                    <button className='btn btn-sm btn-primary'
                        onClick={() => crtMtsAction.btnSave()}
                    >Save</button>
                </div>
            </div>
        </div>
    );
}

export default (props) => <CrtMtsProvider dataProps={{ ...props }}><Index {...props} /></CrtMtsProvider>;