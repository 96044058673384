import React, { createContext, useContext, useEffect, useState } from 'react'
import { useModalAlert } from '../../../components/modalAlert';
import useFetch from '../../../hooks/useFetch';
import { htmlToExcel } from '../../../modules';
import moment from 'moment';

const idpDwnldContext = createContext({
    idpDwnldState: {},
    idpDwnldAction: {}
});

export function IdpDwnldProvider({ children }) {
    const [modalDetail, setModalDetail] = useState({
        show: false,
        data: {}
    });
    const [loadingGet, setLoadingGet] = useState(false)
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const { doGet, doPost } = useFetch();
    const [dataList, setDataList] = useState([]);
    const [batch, setBatch] = useState(new Date().getFullYear().toString());
    const [jobsite_code, setJobsite_code] = useState('');
    const [type, setType] = useState('Mechanic');
    const [listType, setListType] = useState([
        'Mechanic', 'Operator', 'Pengawas',
    ])
    async function getDataList(download = false) {
        // openLoading("Get competency")
        setLoadingGet(true)
        try {
            const { status, statusText, payload } = await doGet({
                url: '/api/show-idp-regis',
                param: {
                    batch,
                    jobsite_code,
                    type
                }
            });
            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDataList(data.dataIDP || []);
                    setBatch(data?.batch || '');
                    if (download) {
                        if ([...(data?.dataIDP || [])].length < 1) {
                            setDataAlert({
                                show: true,
                                body: "No data record",
                                header: "Info",
                                callBack: () => null
                            })
                        } else {
                            htmlToExcel(
                                "table-to-xls",
                                `${data?.batch
                                }_IDP Status_${moment(Date.now()).format(
                                    "YYYY-MM-DD HH_mm_SS"
                                )}`,
                                "statement"
                            )
                        }
                    }
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get IDP Status",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get IDP Status",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get detail register",
                callBack: () => null
            })
        }
        setLoadingGet(false)
    }

    const [dataJobsite, setDataJobsite] = useState([]);
    async function getAllJobsite() {
        // openLoading("Get competency")
        // setLoadingGet(true)
        try {
            const { status, statusText, payload } = await doGet({
                url: '/api/list-all-jobsite',
            });
            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDataJobsite(data?.jobsites);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get All Jobsite",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get All Jobsite",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get All Jobsite",
                callBack: () => null
            })
        }
        // setLoadingGet(false)
    }
    function onFilter() {
        getDataList(true);
    }
    useEffect(function () {
        getAllJobsite();
        // getDataList()
    }, [])

    return <idpDwnldContext.Provider
        value={{
            idpDwnldState: {
                modalDetail,
                dataList,
                loadingGet,
                batch,
                jobsite_code,
                dataJobsite, type, listType
            },
            idpDwnldAction: {
                setModalDetail,
                setBatch,
                onFilter,
                setJobsite_code,
                setType, setListType

            }
        }}
    >{children}</idpDwnldContext.Provider>
}

export default function useIdpDwnld() {
    return useContext(idpDwnldContext);
}