import React, { useState} from 'react';
import { SelectSearch } from '../../../../components';
import { useModalAlert } from '../../../../components/modalAlert';
import useFetch from '../../../../hooks/useFetch';

const DropDownList = ({
    competency_name ='',
    type = '',
    group='',
    cluster='',
    competency_id='',
    onChange = () => null,
    value = '',

}) => {
    const [laoding, setLoadingState] = useState(false)
    const { doGet, doPost } = useFetch();
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const [dataList, setDataList] = useState([]);
    async function getDataList() {
        // console.log(await doGet({
        //     param: {
        //       type,
        //       competency_name
        //     }
        //     ,
        //     url: '/api/list-course'
        // }))
        // openLoading("Get competency")
        setLoadingState(true)
        try {
            const { status, statusText, payload } = await doPost({
                data: {
                  type,
                  competency_name,
                  group,
                  cluster,
                  competency_id
                }
                ,
                url: '/api/list-course'
            });
            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = [], code } = payload;
                if (code === "00") {
                    setDataList(data)
                } else setDataAlert({
                    show: true,
                    body: data.msg,
                    header: "Get dropdown course",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get dropdown course",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get dropdown course",
                callBack: () => null
            })
        }
        setLoadingState(false)
    }

    return ( 
        <div>
            <SelectSearch
                // size='sm'
                options={dataList}
                keyValue="id"
                display='name'
                placeholder= { laoding ? 'Loading data' : 'Select course name'}
                onFocus={getDataList}
                onChange={onChange}
                value={value}
            />
        </div>
     );
}
 
export default DropDownList;