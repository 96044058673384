import React from 'react';
import 'anychart';
import AnyChart from 'anychart-react';

import useReportDataTrainerDidTraining from './useReportDataTrainerDidTraining';

const ChartData = () => {
    const { reportDtTrainerDidTrainingState } = useReportDataTrainerDidTraining();

    // create a data set
    const data = anychart.data.set(reportDtTrainerDidTrainingState.dataList);

    // map the data
    const seriesData_1 = data.mapAs({ x: 0, value: 1 });
    const seriesData_2 = data.mapAs({ x: 0, value: 2 });
    const seriesData_3 = data.mapAs({ x: 0, value: 3 });

    // create a chart
    const chart = anychart.column();
    chart.credits().enabled(false);
    chart.container("container");

    // adding extra Y scale
    const extraYScale = anychart.scales.linear();
    chart.yAxis(1).orientation("right");
    chart.yAxis(1).scale(extraYScale);
    extraYScale.ticks().interval(20);

    // disable the y-label & y-stroke on the first plot
    chart.crosshair().yStroke(null);
    chart.crosshair().yLabel(false);

    chart.xAxis().labels().rotation(-90);
    chart.xAxis().labels().padding(5,0,5,0);

    // create the first series, set the data and name
    const series1 = chart.column(seriesData_1);
    series1.fill('#3a1cff');
    series1.stroke("#3a1cff");
    series1.labels(true);
    series1.labels().format("{%value}");

    // create the second series, set the data and name
    const series2 = chart.column(seriesData_2);
    series2.fill('#ff4500');
    series2.stroke("#ff4500");
    series2.labels(true);
    series2.labels().format("{%value}");

    const series3 = chart.line(seriesData_3);
    series3.labels(true);
    series3.labels().format("{%value}%");
    series3.stroke(`6px #a0a0a0`);
    series3.yScale(extraYScale);

    // set the padding between bars
    chart.barsPadding(0);

    // set the chart title
    chart.title("Trainer Close Training");

    // enable the legend
    chart.legend(true);
    chart.legend().position("bottom");
    chart.legend().useHtml(true);
    chart.legend().itemsSpacing(10);
    chart.legend().iconTextSpacing(5);
    chart.legend().padding(30);
    chart.legend().itemsFormatter(legendItems => {
        const itemName = { 0: "Plan", 1: "Actual",2: "Ach.%" };
        for (var i = 0; i < legendItems.length; i++) {
            legendItems[i].iconStroke = 30;
            legendItems[i].text = itemName[i];
        }
        return legendItems;
    });
    
    return (
        <AnyChart
            instance={chart}
            height={window.innerHeight - 250}
            id="chart-trainer-did-training-container"
        />
    )
}

export default ChartData;
