import React, { createContext, useContext, useState } from 'react';

const allNotContext = createContext({
    allNotState: {},
    allNotAction: {}
})

export function AllNotifProvider({ children }) {
    const [tab, setTab] = useState('unread');

    return <allNotContext.Provider
        value={{
            allNotState: {
                tab,                
            },
            allNotAction: {
                setTab
            }
        }}
    >{children}</allNotContext.Provider>
}

export default function useAllNotif() {
    return useContext(allNotContext);
}