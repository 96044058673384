import React, { createContext, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useModalAlert } from '../../../../components/modalAlert';
import { useGlobalState } from '../../../../context';
import useFetch from '../../../../hooks/useFetch';

const idpApprvDContext = createContext({
    idpAppDState: {},
    idpAppDAction: {}
});

export function IdpApprovalDProvider({ children }) {
    const { globalState, dispatch, actions } = useGlobalState();
    const [loadingGet, setLoadingGet] = useState(false);
    const { modalState, modalAction } = useModalAlert();
    const [showApprove, setShowApprove] = useState(false);
    const [showRevise, setShowRevise] = useState(false);
    const [showReject, setShowReject] = useState(false);
    const [idApprove, setIdApprove] = useState('');
    const [idRevise, setIdRevise] = useState('');
    const [idReject, setIdReject] = useState('');
    const [noteA, setNoteA] = useState('');
    const [noteR, setNoteR] = useState('');
    const [defaultSelection, setdefaultSelection] = useState([]);
    const [trainee, setTrainee] = useState({
        name: '',
        nik: ''
    })
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const { doGet, doPost } = useFetch();
    const [dataIDP, setDataIDP] = useState([]);
    const { nik } = useParams();

    async function getDetailIDP() {
        // openLoading("Get competency")
        setLoadingGet(true)
        try {
            const { status, statusText, payload } = await doGet({
                param: {
                    junior_nik: nik || ''
                },
                url: '/api/detail-regis-idp'
            });
            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDataIDP([...data?.dataIDP])
                    actions.getNotification();
                    setdefaultSelection([]);
                    setTrainee({
                        name: data?.trainee_name,
                        nik: data?.trainee_nik
                    })
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Subordinate",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Subordinate",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Subordinate",
                callBack: () => null
            })
        }
        setLoadingGet(false)
    }

    function approve(id = []) {
        if (id.length <= 0) {
            return setDataAlert({
                show: true,
                body: "Tidak ada data terpilih",
                header: "Warning !",
                callBack: () => null
            })
        }
        setDataConfirm({
            show: true,
            body: <>Apa anda yakin ?</>,
            witTextArea: false,
            header: "Konfirmasi Approve",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Yes", onClick: () => { modalAction.closeConfirm(); doNext({ "status": "approved", note: noteA, training_ids: id }); }, className: "btn btn-sm btn-success" },
                { name: "No", onClick: modalAction.closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }
    function reject(id = []) {
        if (id.length <= 0) {
            return setDataAlert({
                show: true,
                body: "Tidak ada data terpilih",
                header: "Warning !",
                callBack: () => null
            })
        }
        setDataConfirm({
            show: true,
            body: <>Apa anda yakin ?</>,
            witTextArea: false,
            header: "Konfirmasi Decline",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Yes", onClick: () => { modalAction.closeConfirm(); doNext({ "status": "declined", note: noteR, training_ids: id }); }, className: "btn btn-sm btn-danger" },
                { name: "No", onClick: modalAction.closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }
    function revision(id = []) {
        if (id.length <= 0) {
            return setDataAlert({
                show: true,
                body: "Tidak ada data terpilih",
                header: "Warning !",
                callBack: () => null
            })
        }
        setDataConfirm({
            show: true,
            body: <>Apa anda yakin ?</>,
            witTextArea: false,
            header: "Konfirmasi Revisi",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Yes", onClick: () => { modalAction.closeConfirm(); doNext({ "status": "need revise", note: noteR, training_ids: id }); }, className: "btn btn-sm btn-danger" },
                { name: "No", onClick: modalAction.closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }
    async function doNext(data = {}) {
        openLoading("Please wait.")
        try {
            const resp = await doPost({
                url: `/api/update-idp-status`,
                data: data,
                method: 'PUT'
            });

            closeLoading()
            if (resp.status === 200) {
                if (resp.payload?.code === '00') {
                    setShowApprove(false);
                    setShowReject(false);
                    setShowRevise(false);
                    setDataAlert({
                        show: true,
                        body: <div className="d-flex justify-content-center">
                            <div className="d-flex align-items-center p-2 justify-content-center display-1">
                                <i className="fa fa-check text-success"></i>
                            </div>
                        </div>,
                        // header: 'Success',
                        title: resp.payload?.msg,
                        callBack: () => getDetailIDP()
                    })
                } else {
                    setDataAlert({ show: true, body: resp.payload?.msg, header: 'Info', title: "", callBack: () => null })
                }
            } else if (resp.status === 401) {
                closeLoading();
            }
            else {
                setDataAlert({ show: true, body: resp.statusText, header: 'Error', title: "", callBack: () => null })
            }
        } catch (error) {
            closeLoading();
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Subordinate",
                callBack: () => null
            })
        }
    }
    useEffect(() => {
        getDetailIDP();
    }, [nik])

    useEffect(() => {
        if (showApprove === false) {
            setIdApprove('');
            setNoteA('')
        }
        if (showReject === false) {
            setIdReject('');
            setNoteR('');
        }
        if (showRevise === false) {
            setIdRevise('');
            setNoteR('');
        }
    }, [showApprove, showReject, showRevise])
    return <idpApprvDContext.Provider
        value={{
            idpAppDState: {
                loadingGet,
                dataIDP,
                showApprove,
                showReject,
                showRevise,
                idApprove,
                idRevise,
                idReject,
                noteA,
                noteR,
                defaultSelection,
                nik,
                trainee
            },
            idpAppDAction: {
                approve,
                reject,
                revision,
                setShowApprove,
                setShowReject,
                setShowRevise,
                setIdApprove,
                setIdReject,
                setIdRevise,
                setNoteA,
                setNoteR,
                setdefaultSelection
            }
        }}
    >{children}</idpApprvDContext.Provider>
}

export default function useIdpApprovalD() {
    return useContext(idpApprvDContext);
}