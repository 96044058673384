import React, { useState, useEffect, createContext, useContext } from 'react';
import { useModalAlert } from '../../components/modalAlert';
import { useAuth } from '../../context';
import useFetch from '../../hooks/useFetch';

const sideContext = createContext({
    sideAction: {},
    sideState: {}
});

export function SideProvider({ children }) {
    const [menuItems, setMenuItems] = useState([]);
    const [loadingMenu, setLoadingMenu] = useState(false)
    const { doGet } = useFetch();
    const { modalState, modalAction } = useModalAlert();
    const { menus, setMenus} = useAuth()
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;

    function createListMenu(menus = [], p_name = null) {
        return menus
            .filter(({ parent_name }) => parent_name === p_name)
            .map(({
                parent_name,
                name,
                url,
                icon
            }) => {
                let hasChild = menus.some((c) => c.parent_name === name)
                return ({
                    hasChild,
                    name,
                    url,
                    icon,
                    ...(hasChild ? {
                        childs: createListMenu(menus, name)
                    } : {})
                })
            })
    }
    async function getMenu() {
        setLoadingMenu(true)
        try {
            const { status, statusText, payload } = await doGet({
                url: '/api/list-menu'
            });
            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = [], code, msg } = payload;
                if (code === "00") {
                    let newMenu = createListMenu(data)
                    setMenus(newMenu)
                    setMenuItems(newMenu);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get IDP Status",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get IDP Status",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get detail register",
                callBack: () => null
            })
        }
        setLoadingMenu(false)
    }
    useEffect(function () {
        getMenu()
    }, [])

    return <sideContext.Provider value={{
        sideAction: {},
        sideState: {
            loadingMenu,
            menuItems,
        }
    }}>
        {children}
    </sideContext.Provider>
}

export default () => useContext(sideContext);