import React from 'react';
import { Modal } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { MTSCreateCreateUpdate } from '..';
import { TableList } from '../../../../components';
import useLsIdp, { LsIdpProvider } from './useLsIdp';
import { htmlToExcel } from '../../../../modules';
import './index.css';
import { BiFile } from 'react-icons/bi';
import TableToHTML from './export';
import moment from 'moment';
import ModalTrainee from './components/modalTrainee';
import { useAuth } from '../../../../context';

function Index(props) {
    const history = useHistory();
    const { userInfo } = useAuth();
    const role_id = userInfo?.role_id;
    const { lsIdpState, lsIdpAction } = useLsIdp();
    return (
        <div>
            {/* show modal create mts */}
            <Modal
                dialogClassName='modal-mts-create'
                backdropClassName='bg-transparent'
                show={lsIdpState.modal?.show}
                onHide={() => lsIdpAction.setModal({ show: false, id: '', data: {}, modeEdit: false })}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{lsIdpState.modal.modeEdit ? "Update" : "Createy"} Monthly Training schedule</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MTSCreateCreateUpdate {...lsIdpState.modal} jobsite_code={lsIdpState.dataState?.jobsite_code} onSubmit={(data) => lsIdpAction.confirmSubmit(data)} />
                </Modal.Body>
            </Modal>

            {/* show modal trainee */}
            <Modal
                dialogClassName='modal-mts-create'
                // backdropClassName='bg-transparent'
                show={lsIdpState.modalTrainee?.show}
                onHide={() => lsIdpAction.setModalTrainee({ show: false, data: [] })}
            >
                <Modal.Header closeButton>
                    <Modal.Title>List Trainee</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ModalTrainee {...lsIdpState.modalTrainee} />
                </Modal.Body>
            </Modal>
            <section className="content-header">
                <div className="container-fluid">
                    <div className='row'>
                        <div className='col-md-6'>
                            <h4>Create Monthly Training schedule</h4>
                            <h6>List Idp</h6>
                        </div>
                        <div className='col-md-6'>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-end">
                                    <li className="breadcrumb-item"><Link to={"/ats/mts-create"}>List Jobsite</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">List IDP</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <div className='card'>
                <div className='card-body'>
                    <div className='font-weight-bold mb-5'><label style={{ width: '150px' }}>Nama Jobsite</label>: {lsIdpState.dataState?.jobsite_name}</div>
                    <TableList
                        filter={true}
                        formsFilter={[
                            {
                                type: 'custom',
                                placeholder: 'Nama',
                                component: (
                                    <input type='number' className='form-control form-control-sm mb-2'
                                        value={lsIdpState?.batch}
                                        onChange={(val) => lsIdpAction.setBatch(val.target.value)}
                                    >
                                    </input>
                                )
                            },
                            {
                                type: 'custom',
                                placeholder: 'Nama',
                                component: (
                                    <input type='text' className='form-control form-control-sm mb-2'
                                        value={lsIdpState?.cluster}
                                        placeholder='Cluster'
                                        onChange={(val) => lsIdpAction.setCluster(val.target.value)}
                                    >
                                    </input>
                                )
                            },
                            {
                                type: 'custom',
                                placeholder: 'Nama',
                                component: (
                                    <input type='text' className='form-control form-control-sm mb-2'
                                        value={lsIdpState?.group_competency}
                                        placeholder='Group Competency'
                                        onChange={(val) => lsIdpAction.setGroup_competency(val.target.value)}
                                    >
                                    </input>
                                )
                            },
                            {
                                type: 'custom',
                                placeholder: 'Nama',
                                component: (
                                    <input type='text' className='form-control form-control-sm mb-2'
                                        value={lsIdpState?.course_name}
                                        placeholder='Training Name'
                                        onChange={(val) => lsIdpAction.setCourse_name(val.target.value)}
                                    >
                                    </input>
                                )
                            },                            
                        ]}
                        customToolbar={<div className='text-right mb-3'>
                            <button className='btn btn-sm btn-success'
                                onClick={() => htmlToExcel("table-to-xls", `${lsIdpState?.batch}_MTS_${moment(Date.now()).format("YYYY-MM-DD HH_mm_SS")}`, "statement")}
                            >
                                <BiFile style={{ fonstSize: '12pt' }} /> Export Excel
                            </button>
                        </div>}
                        labelFilter="Tahun IDP Register :"
                        resetFilter={false}
                        onFilter={() => lsIdpAction.onFilter()}
                        minHeight="300px"
                        maxHeight="500px"
                        navtable={"TABLE 1"}
                        withCard={false}
                        isLoading={lsIdpState.loadingGet}
                        withActions={false}

                        onChangeShow={lsIdpAction.changeShow}
                        onNextPage={lsIdpAction.nextPage}
                        onPrevPage={lsIdpAction.prevPage}
                        defaultShow={lsIdpState?.pagination?.row_in_page}
                        curPage={lsIdpState?.pagination?.page}
                        maxPage={lsIdpState?.dataGet?.tableData?.totalPage || 0}
                        totalData={lsIdpState?.dataGet?.tableData?.totalData || 0}

                        columns={[
                            {
                                name: '#',
                                field: '#',
                                maxWidth: '50px',
                            },
                            {
                                name: 'Category',
                                field: 'type',
                            },
                            {
                                name: 'Training Name',
                                field: 'course_name',
                            },
                            {
                                name: 'Trainer',
                                field: 'trainer_nik',
                                onRender: (item) => (
                                    <span className='text-wrap'>{item?.trainer_nik} - {item?.trainer_name}</span>
                                )
                            },
                            {
                                name: 'Kuota Training',
                                field: 'quota',
                            },
                            {
                                name: 'Trainee',
                                field: 'trainees',
                                maxWidth: '300px',
                                onRender: (item) => {
                                    return (
                                        <div>
                                            {
                                                [...(item?.trainees || [])].slice(0, 5).map((d, i) => (
                                                    <span className='badge badge-info mr-1'>
                                                        {
                                                            d?.trainee_name
                                                        }
                                                    </span>
                                                ))
                                            }
                                            {
                                                [...(item?.trainees || [])].length > 5 && (
                                                    <div className='d-block'><span type='button' className="badge badge-warning"
                                                        onClick={() => lsIdpAction.setModalTrainee({ show: true, data: [...(item?.trainees || [])] })}
                                                    >See All</span></div>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            },
                            {
                                name: 'Start',
                                field: 'start_date_mts',
                                onRender: (item) => (
                                    <span className='text-wrap'>{moment(item?.start_date_mts || item?.start_date_ats).format('DD/MM/YYYY')}</span>
                                )
                            },
                            {
                                name: 'Finish',
                                field: 'end_date_mts',
                                onRender: (item) => (
                                    <span className='text-wrap'>{moment(item?.end_date_mts || item?.end_date_ats).format('DD/MM/YYYY')}</span>
                                )
                            },
                            ...(role_id !== 1 ? [{
                                name: 'Action',
                                field: '',
                                maxWidth: '100px',
                                minWidth: '100px',
                                onRender: (item) => (
                                    <div>
                                        {
                                            // moment(moment(new Date)).isBefore(moment(item?.start_date_mts || item.start_date_ats)) ?
                                                (item?.start_date_mts && item.end_date_mts) ? (
                                                    <button className='btn btn-sm btn-success'
                                                        onClick={() =>
                                                            lsIdpAction.setModal({
                                                                show: true,
                                                                data: {
                                                                    jobsite_name: lsIdpState.dataState?.jobsite_name,
                                                                    jobsite_code: lsIdpState.dataState?.jobsite_code,
                                                                    tahun:lsIdpState?.batch,
                                                                    ...item
                                                                },
                                                                modeEdit: true
                                                            })
                                                        }
                                                    >Update MTS</button>
                                                ) : (
                                                    <button className='btn btn-sm btn-primary'
                                                        onClick={() =>
                                                            lsIdpAction.setModal({
                                                                show: true,
                                                                data: {
                                                                    jobsite_name: lsIdpState.dataState?.jobsite_name,
                                                                    jobsite_code: lsIdpState.dataState?.jobsite_code,
                                                                    tahun:lsIdpState?.batch,
                                                                    ...item
                                                                }
                                                            })
                                                        }
                                                    >Create MTS</button>
                                                )
                                                // :
                                                // (<span className='text-dark'>Training telah berjalan</span>)
                                        }

                                    </div>
                                )
                            }] : [])
                        ]}
                        data={[...(lsIdpState.dataGet?.tableData?.listData || [])]}
                    />

                    <button className='btn btn-sm btn-secondary'
                        onClick={() => history.goBack()}
                    >Back</button>
                </div>
            </div>
            <TableToHTML />
        </div>
    );
}

export default (props) => <LsIdpProvider><Index {...props} /></LsIdpProvider>;