import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useModalAlert } from '../../../components/modalAlert';
import useFetch from '../../../hooks/useFetch'
import ModalDetil from './modalDetil';

const jobContext = createContext({
    jobAction: {

    },
    jobState: {

    }
})

export function JobProvider({ children }) {
    const refInputFile = useRef();
    const [tipeUpload, setTipeUpload] = useState(0);
    const { doGet, doPost, wait } = useFetch()
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const [dataModal, setDataModal] = useState({
        show: false,
        data:{}
    })
    const [loadingTraining, setLoadingTraining] = useState(false)
    const [listTraining, setListTraining] = useState([]);
    const [dataDoc, setDataDoc] = useState([])
    const [formUpload, setFormUpload] = useState({
        training_id: undefined,
        title: "",
        file: undefined
    })
    const [pageStatus, setPage] = useState({
        title: "Job Assigment",
        type: "ListTrainingPage",
        detailPage: {
            actions: ["getListTraining"]
        }
    })
    const [loadingDoc, setLoadingDoc] = useState(false)
    const getListTraining = useCallback(async function () {
        setLoadingTraining(true);

        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/job-assignment/list-training',
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setListTraining(data || []);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get List Training",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List Training",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Training",
                callBack: () => null
            })
        }
        setLoadingTraining(false)
    }, [])
    const resetPage = useCallback(function () {
        setPage({
            title: "Job Assignment",
            type: "ListTrainingPage",
            detailPage: {
                actions: ["getListTraining"]
            }
        });
        resetFormUpload()
    }, [])
    const getLisDok = (async function () {
        // console.log(pageStatus)
        setLoadingDoc(true);

        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/job-assignment/list',
                    param: {
                        training_id: pageStatus.detailPage.training_id
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = [], code, msg } = payload;
                if (code === "00") {
                    setDataDoc(data || []);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get List Assigment",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List Assigment",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Assigment",
                callBack: () => null
            })
        }
        setLoadingDoc(false)
    })


    let firstActions = {
        getListTraining,
        getLisDok
    }
    useEffect(function () {
        resetPage()
    }, [])
    useEffect(function () {
        (pageStatus.detailPage?.actions || []).forEach(function (v) {
            if (typeof firstActions[v] === "function") firstActions[v]()
        })
    }, [pageStatus])

    function resetFormUpload() {
        setFormUpload({
            training_id: undefined,
            title: "",
            file: pageStatus.detailPage.training_id
        })
    }
    const toUploadPage = useCallback(function (detailPage) {
        setPage({
            title: "Upload Job Assigment",
            type: "UploadPage",
            detailPage: {
                ...detailPage,
                actions: ["getLisDok"]
            }
        })

        setFormUpload(p => ({
            ...p,
            training_id: detailPage.training_id
        }))

    }, [pageStatus])

    const doDelete = (function ({ job_assignment_id }) {
        let body = "Dokument yang dihapus, akan hilang secara permanen. Lanjutkan?"
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                {
                    name: "Yes", onClick: async () => {
                        closeConfirm();
                        openLoading("Deletting data ...")
                        try {
                            const { status, payload, statusText } = await doPost({
                                url: "/api/job-assignment/delete",
                                method: "delete",
                                data: {
                                    job_assignment_id
                                },
                            });

                            if (status !== 401) {
                                if (status === 200 || status === 201) {
                                    const { code, msg } = payload;
                                    if (code === "00") {
                                        setDataAlert({
                                            body: msg,
                                            show: true,
                                            header: "Success",
                                            callBack: () => {
                                                getLisDok();
                                                setFormUpload((e) => ({
                                                    ...e,
                                                    title:'',
                                                    file: undefined
                                                }))
                                            }
                                        })
                                    } else setDataAlert({
                                        show: true,
                                        body: msg,
                                        header: "Delete dokumen",
                                        callBack: () => null
                                    })
                                } else setDataAlert({
                                    show: true,
                                    body: statusText,
                                    header: "Delete dokumen",
                                    callBack: () => null
                                })
                            }
                        } catch (error) {
                            setDataAlert({
                                show: true,
                                body: error.toString(),
                                header: "Info",
                                callBack: () => null
                            })
                        }
                        closeLoading()


                    }, className: "btn btn-sm btn-success"
                },
                { name: "No", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    })
    const doUpload = useCallback(function (data={}) {
        // console.log(data)
        let body = "Pastikan form terisi dengan benar. Lanjutkan?"
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                {
                    name: "Yes", onClick: async () => {
                        closeConfirm();
                        openLoading("Uploading ...")
                        try {
                            const { status, payload, statusText } = await doPost({
                                url: "/api/job-assignment/store",
                                data: {
                                    ...data
                                },

                                type: 1,
                                paramImage: 'file',
                            });

                            if (status !== 401) {
                                if (status === 200 || status === 201) {
                                    const { code, msg } = payload;
                                    if (code === "00") {
                                        setDataAlert({
                                            body: "Unggah file berhasil",
                                            show: true,
                                            header: "Success",
                                            callBack: () => {
                                                if(refInputFile.current){
                                                    refInputFile.current.value = null;
                                                }
                                                getLisDok();
                                                setFormUpload((e) => ({
                                                    ...e,
                                                    title:'',
                                                    file: undefined
                                                }))
                                            }
                                        })
                                    } else setDataAlert({
                                        show: true,
                                        body: msg,
                                        header: "Info",
                                        callBack: () => null
                                    })
                                } else setDataAlert({
                                    show: true,
                                    body: statusText,
                                    header: "Info",
                                    callBack: () => null
                                })
                            }
                        } catch (error) {
                            setDataAlert({
                                show: true,
                                body: error.toString(),
                                header: "Info",
                                callBack: () => null
                            })
                        }
                        closeLoading()


                    }, className: "btn btn-sm btn-success"
                },
                { name: "No", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }, [formUpload])


    // type = 0,
    // paramImage = '',

    const openModalDetil = useCallback(function (data = {}) {
        setDataModal({ show: true, data })
    }, [])

    const closeModalDetil = useCallback(function () {
        setDataModal({ show: false, data:{} })
    }, [])

    return <jobContext.Provider value={{
        jobAction: {
            setTipeUpload,
            getListTraining,
            toUploadPage,
            resetPage,
            setFormUpload,
            doUpload,
            openModalDetil,
            closeModalDetil,
            doDelete
        },
        jobState: {
            tipeUpload,
            listTraining,
            loadingTraining,
            pageStatus,
            dataDoc,
            loadingDoc,
            formUpload,
            dataModal,
            refInputFile
        }
    }}>
        <ModalDetil />
        {children}
    </jobContext.Provider>
}

export default function () { return useContext(jobContext) }