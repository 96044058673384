import React from 'react';
import { TableList, UploadFile } from '../../../components';
import useJobAssigment, { JobProvider } from './useJobAssigment';
import * as pages from './pages';


import { AiOutlineCloudUpload } from 'react-icons/ai'
import { Link } from 'react-router-dom';
function JobPage() {
    const { jobAction, jobState } = useJobAssigment()
    const PageRendered = pages[jobState.pageStatus.type]

    function loadBreadcrumbs(){

        if(jobState.pageStatus.type === "UploadPage")
            return (
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb justify-content-end">
                        <li className="breadcrumb-item" onClick={() => jobAction.resetPage()}><Link to={"#"}>Job Assignment</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">{jobState.pageStatus.title}</li>
                    </ol>
                </nav>
            )
        else
            return (
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb justify-content-end">
                        <li className="breadcrumb-item active" aria-current="page">{jobState.pageStatus.title}</li>
                    </ol>
                </nav>
            )
    }
    return (<>
    <div className='row'>
        <div className='col-6'>
            <section className="content-header">
                <div className="container-fluid">
                    <h4>Development</h4>
                    <h6>{
                        jobState.pageStatus.title    
                    }</h6>
                </div>
            </section>
        </div>
        <div className='col-6'>
            {loadBreadcrumbs()}
        </div>
    </div>
        
        <PageRendered />
    </>)
}

export default () => <JobProvider> <JobPage /> </JobProvider>