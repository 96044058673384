import React from 'react';
import { TableList } from '../../../../components';
import usePencapaianHm from '../usePencapaianHm';

function Index(props) {
    const { phmState, phmAction } = usePencapaianHm();
    return (
        <div>
            <div className='card'>
                <div className='card-header'>
                    <div className='card-title'>
                        <h5> List Trainee </h5>
                    </div>
                </div>
                <div className='card-body'>
                    <div className='row mt-2'>
                        <div className='col-md-12'>
                            <TableList
                                isLoading={phmState.loadingTrainee}
                                minHeight='max-content'
                                withCard={false}
                                withActions={false}
                                filter={false}
                                data={[...(phmState.listTrainee || [])]}
                                pagination={false}
                                columns={[
                                    {
                                        name: "Nama",
                                        field: "name"
                                    },
                                    {
                                        name: "NIK",
                                        field: "nik"
                                    },
                                    {
                                        name: "",
                                        className: "text-right",
                                        onRender: (item) => <>
                                            <button
                                                className='btn btn-sm btn-primary'
                                                onClick={() => phmAction.toListTrainingPage(item)}
                                            > Detail </button>
                                        </>
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </div>
                <div className='card-footer'>
                    {/* <button onClick={resetPage} className="btn btn-default"> Back </button> */}
                </div>
            </div>
        </div>
    );
}

export default Index;