import React from 'react';
import { Modal } from 'react-bootstrap';
import usePeriksaJob from './usePeriksaJob';

const ModalDetil = () => {

    const { prjAction, prjState } = usePeriksaJob()
    return (
        <Modal size='xl' show={prjState.dataModal.show}>
            <Modal.Header closeButton onHide={prjAction.closeModalDetil} >
                <Modal.Title>
                    {prjState.dataModal.data?.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    (prjState.dataModal?.data?.status === "declined") && (
                        <div className='card mb-3' style={{ backgroundColor: '#ffe2e5' }}>
                            <div className='card-body text-danger'>
                                <h5 className='text-center'>Declined</h5>
                                <h7>Note : </h7>
                                <span>{prjState.dataModal?.data?.note}</span>
                            </div>
                        </div>
                    )
                }
                <div className='row'>
                    <div className='col-md-12'>
                        <iframe type="application/pdf" height={"700px"} width={"100%"} src={prjState.dataModal.data?.path_doc} />
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <div className='row'>
                    <div className='col'>
                        <button onClick={prjAction.closeModalDetil} className='btn btn-default float-right btn-sm '>
                            Close
                        </button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalDetil;
