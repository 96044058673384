import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useModalAlert } from "../../../components/modalAlert";
import useFetch from "../../../hooks/useFetch";
import ModalTautan from "./modal-tautan";

const tirApvContext = createContext({
    tirApvState: {},
    tirApvAction: {}
});

const iniStatePage = {
    title: "Subordinate",
    type: "TableJr",
    dataPage: {
        actions: ["getListTrainee"]
    }
};
const iniStatModalTautan = {
    show: false,
    modeEdit: false,
    data: {}
}
export function TirApvProvider({ children }) {
    const [pageState, setPageState] = useState(iniStatePage);
    const [modalTautan, setModalTautan] = useState(iniStatModalTautan);
    const [loadingForm, setLoadingForm] = useState(false)
    const [listForm, setListForm] = useState([]);
    const [formData, setFormData] = useState({});
    const { doGet, doPost } = useFetch()
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const [loadingTraining, setLoadingTraining] = useState(false)
    const [listTraining, setListTraining] = useState([]);
    const [loadingJr, setLoadingJr] = useState(false)
    const [listJr, setListJr] = useState([]);
    const [formDataTautan, setFormDataTautan] = useState({ tir_id: '', url: '' });
    const [modalDecline, setModalDecline] = useState({
        show: false,
        data: {}
    });

    const getListTraining = useCallback(async function (trainee_nik = '') {
        setLoadingTraining(true);

        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/tir/list-training',
                    param: {
                        trainee_nik
                    }
                },
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setListTraining(data || []);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get List Training",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List Training",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Training",
                callBack: () => null
            })
        }
        setLoadingTraining(false)
    }, []);
    const getListForm = useCallback(async function () {
        setLoadingForm(true);

        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/form/TIR',
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setListForm(data || []);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get List Form",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List Form",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Form",
                callBack: () => null
            })
        }
        setLoadingForm(false)
    }, []);
    const getDetilForm = useCallback(async function (tir_id = '') {
        openLoading("Get Detail");
        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/tir/detail-form',
                    param: {
                        tir_id
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data: { data_input = [] }, code, msg } = payload;
                if (code === "00") {
                    console.log([...(data_input || [])]?.reduce((prev, obj, i) => ({ ...prev, [obj?.form_id]: { ...obj } }), {}))
                    setFormData([...(data_input || [])]?.reduce((prev, obj, i) => ({ ...prev, [obj?.form_id]: { ...obj } }), {}))
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Detil Form",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Detil Form",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Detil Form",
                callBack: () => null
            })
        }
        closeLoading();
    }, []);
    const getListTrainee = useCallback(async function () {
        setLoadingJr(true);

        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/list-junior',
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = [], code, msg } = payload;
                if (code === "00") {
                    setListJr(data || []);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Subordinate",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Subordinate",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Subordinate",
                callBack: () => null
            })
        }
        setLoadingJr(false)
    }, []);
    const doUpdateStatus = useCallback(async function (data = {}, trainee_nik='') {
        closeConfirm()
        openLoading("Sending data, please wait...");
        try {
            const { status, payload, statusText } = await doPost({
                url: "/api/tir/update-status",
                data: {
                    ...data
                },
                method: 'PUT'
            });

            if (status !== 401) {
                if (status === 200 || status === 201) {
                    const { code, msg } = payload;
                    if (code === "00") {
                        setDataAlert({
                            body: msg,
                            show: true,
                            header: "Success",
                            callBack: () => {
                                setFormData({});
                                setModalTautan({...iniStatModalTautan});
                                toListPage(trainee_nik);
                            }
                        })
                    } else setDataAlert({
                        show: true,
                        body: msg,
                        header: "Info",
                        callBack: () => null
                    })
                } else setDataAlert({
                    show: true,
                    body: statusText,
                    header: "Info",
                    callBack: () => null
                })
            }
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Info",
                callBack: () => null
            })
        }
        closeLoading();
    }, []);
    const resetModalTautan = useCallback(function () {
        setModalTautan({ ...iniStatModalTautan });
        setFormDataTautan({ tir_id: '', url: '' });
    }, [])
    const toFormPage = useCallback(function (detailPage, modeEdit = false) {
        setPageState({
            title: "Form Detail",
            type: "Form",
            dataPage: {
                detail: { ...detailPage },
                actions: [],
                modeEdit: modeEdit
            }
        })
    }, [pageState]);
    const toListPage = useCallback(function (trainee_nik = '') {
        setPageState({
            title: "List Assignment",
            type: "TableList",
            dataPage: {
                trainee_nik,
                actions: ["getListTraining"]
            }
        });
        setFormData({});
    }, [pageState]);
    const toListJr = useCallback(function (detailPage) {
        setPageState({ ...iniStatePage })
    }, [pageState]);
    const confirmUpdateStatus = useCallback(function (data = {}, trainee_nik = '') {
        setDataConfirm({
            show: true,
            body: "Apa anda yakin?",
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Yes", onClick: () => doUpdateStatus(data, trainee_nik), className: "btn btn-sm btn-success" },
                { name: "No", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }, []);

    const closeModalDecline = useCallback(function () {
        setModalDecline({
            data: {},
            show: false
        })
    }, [setModalDecline])
    const openModalDecline = useCallback(function (data) {
        setModalDecline({
            data,
            show: true
        })
    }, [modalDecline, setModalDecline])
    const setNote = useCallback(function (note = '') {
        setModalDecline((prev) => ({
            ...prev,
            data: {
                ...prev?.data,
                note: note
            }
        }))
    }, [setModalDecline])
    let firstActions = {
        getListTraining,
    }
    useEffect(() => {
        if (pageState.type === 'TableList') getListTraining(pageState.dataPage?.trainee_nik);
        if (pageState.type === 'TableJr') getListTrainee();
        if (pageState.type === 'Form') {
            getListForm();
            getDetilForm(pageState.dataPage?.detail?.tir_id)
        }
    }, [pageState]);
    useEffect(() => {
        if (modalTautan.show) {
            setFormDataTautan({ ...modalTautan.data });
        }
    }, [modalTautan])

    return <tirApvContext.Provider
        value={{
            tirApvState: {
                pageState,
                listTraining,
                loadingTraining,
                listJr,
                loadingJr,
                modalTautan,
                formDataTautan,
                formData,
                listForm,
                modalDecline
            },
            tirApvAction: {
                setPageState,
                toFormPage,
                toListPage,
                setModalTautan,
                resetModalTautan,
                setFormDataTautan,
                toListJr,
                confirmUpdateStatus,
                closeModalDecline,
                openModalDecline,
                setNote
            }
        }}
    >
        <ModalTautan />
        {children}
    </tirApvContext.Provider>
}

export default function useTirApproval() {
    return useContext(tirApvContext);
}
