import React, { useEffect } from 'react';
import { TableList } from '../../../components';
import useNtUrd, { NtUrdProvider } from './useTabUnread';

function TabUnread(props) {
    const { ntUrdAction, ntUrdState } = useNtUrd();
    return (
        <div>
            <div className='row m-5 justify-content-center'>
                <div className='col'>
                    <TableList
                        curPage={ntUrdState.pagination?.page}
                        isLoading={ntUrdState?.loadingGet}
                        withCard={false}
                        minHeight="300px"
                        maxHeight="500px"
                        withActions={false}
                        onChangeShow={ntUrdAction.changeShow}
                        onNextPage={ntUrdAction.nextPage}
                        onPrevPage={ntUrdAction.onPrevPage}
                        defaultShow={ntUrdState.pagination?.show}
                        maxPage={ntUrdState.dataNotifUnRead?.totalPage || 0}
                        totalData={ntUrdState.dataNotifUnRead?.totalData}
                        orderData={{
                            order_by: ntUrdState.pagination?.order_by,
                            order_type: ntUrdState.pagination?.order_type
                        }}
                        onOrdering={ntUrdAction.onOrdering}

                        onClickRows={(item, key) => ntUrdAction.rowOnclick(item?.url, item?.type, item)}
                        columns={[
                            {
                                name: "Notification unreaded",
                                field: 'message',
                            },
                        ]}
                        data={[...(ntUrdState.dataNotifUnRead?.listData || [])]}
                    />
                </div>
            </div>
        </div>
    );
}

export default (props) => <NtUrdProvider><TabUnread {...props} /></NtUrdProvider>;