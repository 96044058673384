import {
    createContext,
    useContext,
    useState,
    useCallback,
    useEffect,
} from "react";
import useFetch from "../../../hooks/useFetch";
import { useModalAlert } from "../../../components/modalAlert";
import { useParams } from "react-router";
import React from "react";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { useHistory } from "react-router";

const context = createContext({
    midpState: {},
    midpAction: {},
});

export function MidpProvider({ children }) {
    const { tipe = "" } = useParams();
    const [form, setForm] = useState({
        type: "Mechanic",
        file: null,
    });
    const history = useHistory()
    const [formIdp, setFormIdp] = useState({});
    const queryParam = useQueryParams();
    const id = queryParam.get("id");

    const [pagination, setPagination] = useState({
        page: 1,
        show: 10,
        totalPage: 1,
        totalData: 0,
    });
    const [filter, setFilter] = useState({
        search: "",
        batch: "",
        type:"",
    });
    const { doGet, doPost, wait } = useFetch();
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading,
    } = modalAction;

    const [loadingList, setLoadingList] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [dataDetail, setDataDetail] = useState({});
    const getDataList = useCallback(
        async function (page = pagination.page) {
            setLoadingList(true);
            try {
                const { status, statusText, payload } = await doPost({
                    url: "/api/data-master/list-idp-register",
                    data: {
                        ...filter,
                        page: page,
                        row_in_page: pagination.show,
                    },
                });

                if (status === 401) {
                    closeLoading();
                } else if (status === 200) {
                    const { data = {}, code, msg } = payload;
                    if (code === "00") {
                        setPagination((v) => ({
                            ...v,
                            totalPage: data.totalPage,
                            totalData: data.totalData,
                        }));
                        setFilter( v=> ({
                            ...v,
                            batch: data.batch
                        }))
                        setDataList(data.listData || []);
                    } else
                        setDataAlert({
                            show: true,
                            body: msg,
                            header: "Get List Training",
                            callBack: () => null,
                        });
                } else
                    setDataAlert({
                        show: true,
                        body: statusText,
                        header: "Get List Training",
                        callBack: () => null,
                    });
            } catch (error) {
                setDataAlert({
                    show: true,
                    body: error.toString(),
                    header: "Get List Training",
                    callBack: () => null,
                });
            }
            setLoadingList(false);
        },
        [filter, pagination, setDataList, setFilter, setPagination]
    );

    const doUpload = useCallback(
        function () {
            // console.log(data)
            let body = "Pastikan form terisi dengan benar. Lanjutkan?";
            if (!form.file) {
                return setDataAlert({
                    body: "File Belum ada",
                    show: true,
                    header: "Info",
                    callBack: () => {},
                });
            }
            setDataConfirm({
                show: true,
                body,
                witTextArea: false,
                header: "Confirmation",
                title: "",
                closeButton: false,
                footerButton: [
                    {
                        name: "Yes",
                        onClick: async () => {
                            closeConfirm();
                            openLoading("Uploading ...");
                            try {
                                const { status, payload, statusText } =
                                    await doPost({
                                        url: "/api/data-master/upload-idp-register",
                                        data: {
                                            ...form,
                                        },

                                        type: 1,
                                        paramImage: "file",
                                    });

                                if (status !== 401) {
                                    if (status === 200 || status === 201) {
                                        const { code, msg } = payload;
                                        if (code === "00") {
                                            setDataAlert({
                                                body: "Unggah file berhasil",
                                                show: true,
                                                header: "Success",
                                                callBack: () => {
                                                    getDataList();
                                                    setFormUpload((e) => ({
                                                        ...e,
                                                        title: "",
                                                        file: undefined,
                                                    }));
                                                },
                                            });
                                        } else
                                            setDataAlert({
                                                show: true,
                                                body: msg,
                                                header: "Info",
                                                callBack: () => null,
                                            });
                                    } else
                                        setDataAlert({
                                            show: true,
                                            body: statusText,
                                            header: "Info",
                                            callBack: () => null,
                                        });
                                }
                            } catch (error) {
                                setDataAlert({
                                    show: true,
                                    body: error.toString(),
                                    header: "Info",
                                    callBack: () => null,
                                });
                            }
                            closeLoading();
                        },
                        className: "btn btn-sm btn-success",
                    },
                    {
                        name: "No",
                        onClick: closeConfirm,
                        className: "btn btn-sm btn-secondary",
                    },
                ],
            });
        },
        [form, getDataList]
    );

    const getDetail = useCallback(
        async function (page = pagination.page) {
            setLoadingList(true);
            try {
                const { status, statusText, payload } = await doGet({
                    url: `/api/data-master/detail-idp-register/${id}`,
                });

                if (status === 401) {
                    closeLoading();
                } else if (status === 200) {
                    const { data = {}, code, msg } = payload;
                    if (code === "00") {
                        setFormIdp(data.data);
                    } else
                        setDataAlert({
                            show: true,
                            body: msg,
                            header: "Get List Training",
                            callBack: () => null,
                        });
                } else
                    setDataAlert({
                        show: true,
                        body: statusText,
                        header: "Get List Training",
                        callBack: () => null,
                    });
            } catch (error) {
                setDataAlert({
                    show: true,
                    body: error.toString(),
                    header: "Get List Training",
                    callBack: () => null,
                });
            }
            setLoadingList(false);
        },
        [id]
    );

    const deleteData = useCallback(
        async function (idDelete) {
            openLoading("Menghapus Data ...");
            try {
                const { status, statusText, payload } = await doPost({
                    url: `/api/data-master/delete-idp-register/${idDelete}`,
                    method: "DELETE",
                });

                if (status === 401) {
                    closeLoading();
                } else if (status === 200) {
                    const { data = {}, code, msg } = payload;
                    if (code === "00") {
                        setDataAlert({
                            show: true,
                            body: msg,
                            header: "Success",
                            callBack: () => {
                                getDataList();
                            },
                        });
                    } else
                        setDataAlert({
                            show: true,
                            body: msg,
                            header: "delete data",
                            callBack: () => {
                                closeLoading();
                            },
                        });
                } else
                    setDataAlert({
                        show: true,
                        body: statusText,
                        header: "delete data",
                        callBack: () => {
                            closeLoading();
                        },
                    });
            } catch (error) {
                setDataAlert({
                    show: true,
                    body: error.toString(),
                    header: "delete data",
                    callBack: () => null,
                });
            }
            closeLoading();
        },
        [getDataList]
    );

    
    function doDelete(idDel) {
        setDataConfirm({
            show: true,
            body: "Apakah akan menghapus data?",
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                {
                    name: "Yes",
                    onClick: async () => {
                        closeConfirm();
                        await deleteData(idDel);
                    },
                    className: "btn btn-sm btn-success",
                },
                {
                    name: "No",
                    onClick: closeConfirm,
                    className: "btn btn-sm btn-secondary",
                },
            ],
        });
    }



    const ubahData = useCallback(
        async function () {
            openLoading("Updating Data ...");
            try {
                const { status, statusText, payload } = await doPost({
                    url: `/api/data-master/update-idp-register?_method=PUT`,
                    method: "POST",
                    data: formIdp,
                    type: 1,
                });

                if (status === 401) {
                    closeLoading();
                } else if (status === 200) {
                    const { data = {}, code, msg } = payload;
                    if (code === "00") {
                        setDataAlert({
                            show: true,
                            body: msg,
                            header: "Success",
                            callBack: () => {
                                getDetail();
                                // history.push("/master-data/idp-register")
                            },
                        });
                    } else
                        setDataAlert({
                            show: true,
                            body: msg,
                            header: "Update data",
                            callBack: () => {
                                closeLoading();
                            },
                        });
                } else
                    setDataAlert({
                        show: true,
                        body: statusText,
                        header: "Update data",
                        callBack: () => {
                            closeLoading();
                        },
                    });
            } catch (error) {
                setDataAlert({
                    show: true,
                    body: error.toString(),
                    header: "Update data",
                    callBack: () => null,
                });
            }
            closeLoading();
        },
        [formIdp,]
    );

    
    function doUbah() {
        setDataConfirm({
            show: true,
            body: "Apakah akan update data?",
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                {
                    name: "Yes",
                    onClick: async () => {
                        closeConfirm();
                        await ubahData();
                    },
                    className: "btn btn-sm btn-success",
                },
                {
                    name: "No",
                    onClick: closeConfirm,
                    className: "btn btn-sm btn-secondary",
                },
            ],
        });
    }


    function nextPage({ page, limit }) {
        // console.log({page, limit})
        setPagination((prevState) => ({
            ...prevState,
            page,
            show: limit,
        }));
    }
    function prevPage({ page, limit }) {
        setPagination((prevState) => ({
            ...prevState,
            page,
            show: limit,
        }));
    }
    function changeShow({ page, limit }) {
        setPagination((prevState) => ({
            ...prevState,
            page,
            show: limit,
        }));
    }
    function doFilter() {
        getDataList(1);
        setPagination((v) => ({
            ...v,
            page: 1,
        }));
    }

    useEffect(
        function () {
            if (!tipe) getDataList();
        },
        [pagination.page, pagination.show]
    );

    useEffect(
        function () {
            if (id && tipe !== "") getDetail();
        },
        [id]
    );

    function handleChangeFom(name, val) {
        setFormIdp((prev) => ({
            ...prev,
            [name]: val
        }));
    }
    return (
        <context.Provider
            value={{
                midpState: {
                    form,
                    loadingList,
                    dataList,
                    pagination,
                    filter,
                    tipe,
                    formIdp,
                },
                midpAction: {
                    setForm,
                    doUpload,
                    prevPage,
                    nextPage,
                    changeShow,
                    setFilter,
                    doFilter,
                    setFormIdp,
                    doDelete,
                    doUbah,
                    handleChangeFom
                },
            }}
        >
            {children}
        </context.Provider>
    );
}

export default function useMidp() {
    return useContext(context);
}
