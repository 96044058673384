import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import configLayout from '../../../layout.config';
import { menuItems } from '../../router/menuItems';
import Sidemenu from './sidemenu';
import SideUser from './sideUser';
import logo from '../images/logo.png';
import useSidebar, { SideProvider } from './useSidebar';
import Skeleton from 'react-loading-skeleton';

function SidebarElement() {
    const [keyOpened, setKeyOpened] = useState({});
    const location = useLocation();
    const { sideState } = useSidebar()
    const getActive = function (url, hasChild = false, child = [], key) {
        if (hasChild)
            return child.some((v) => location.pathname.indexOf(v.url) > -1 ) || (keyOpened[url] === undefined ? false : keyOpened[url]);
        return url === "/" ? location.pathname === "/" :  location.pathname.indexOf(url) > -1

    
    }
    function toggleChild(key) {
        setKeyOpened(prevState => {
            return ({
                ...prevState,
                [key]: !(prevState[key] === undefined ? false : prevState[key]),
            })
        });
    }

    return (
        <>
            <aside className="main-sidebar sidebar-dark-primary elevation-2">
                {/* <!-- Brand Logo --> */}
                <a className="brand-link text-center">
                    {/* <configLayout.logo height={300} width={25}/> */}
                    <img src={logo} alt="Logo" className="brand-image mx-auto" />
                    {/* <span className="brand-text text-center font-weight-light">Learn APSS</span> */}
                </a>

                {/* <!-- Sidebar --> */}
                <div className="sidebar" style={{paddingBottom:"30px"}}>
                    {/* <!-- Sidebar Menu --> */}
                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column nav-legacy nav-compact nav-child-indent nav-collapse-hide-child" data-widget="treeview" role="menu" data-accordion="false">
                            <li className="nav-item " style={{
                                // borderBottom: "1px solid",
                                paddingTop: 10,
                                paddingBottom: 10,
                                maxWidth: "100%"
                            }}>

                                <SideUser />
                            </li>
                            {/* <li className="nav-header">EXAMPLES</li> */}
                            {/* <!-- Add icons to the links using the .nav-icon class
                            with font-awesome or any other icon font library --> */}
                            {
                                ((sideState.loadingMenu ? menuItems : sideState.menuItems) || []).map((p, i) => {

                                    return (
                                        <li key={i*Math.random()}
                                            onClick={() => p.hasChild ? toggleChild(p.url) : null}
                                            style={{width:"100%"}}
                                            className={`nav-item ${(getActive(p.url, p.hasChild, p.childs)) ? "menu-is-opening menu-open" : ""}`}
                                        >
                                            {
                                                sideState.loadingMenu ? <Skeleton style={{ marginBottom : 10, marginLeft:25}} width={"70%"} height={20} /> :<Sidemenu
                                                    key={i*Math.random()}
                                                    {... { ...p, active: getActive(p.url, p.hasChild, p.childs) }}
                                                />
                                            }
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </nav>
                    {/* <!-- /.sidebar-menu --> */}
                </div>
                {/* <!-- /.sidebar --> */}
            </aside>

        </>
    )
}

export default () => <SideProvider><SidebarElement /> </SideProvider>;