import moment from 'moment';
import React from 'react';
import { BiFile } from 'react-icons/bi';
import { Link, useHistory } from 'react-router-dom';
import { TableList } from '../../../../components';
import { htmlToExcel } from '../../../../modules';
import TableToHTML from '../export';
import useLsIdp, { LsIdpProvider } from './useLsIdp';

function Index(props) {
    const history = useHistory();
    const { lsIdpState, lsIdpAction } = useLsIdp();
    return (
        <div>
            <section className="content-header">
                <div className="container-fluid">
                    <div className='row'>
                        <div className='col-md-6'>
                            <h4>Status Annual Training Schedule</h4>
                            <h6>List Idp</h6>
                        </div>
                        <div className='col-md-6'>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-end">
                                    <li className="breadcrumb-item"><Link to={"/ats/ats-status"}>List Jobsite</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">List IDP</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <div className='card'>
                <div className='card-body'>
                    <div className='font-weight-bold mb-5'><label style={{ width: '150px' }}>Nama Jobsite</label>: {lsIdpState.dataState?.jobsite_name}</div>
                    <TableList
                        filter={true}
                        formsFilter={[
                            {
                                type: 'custom',
                                placeholder: 'Nama',
                                component: (
                                    <input type='number' className='form-control form-control-sm'
                                        value={lsIdpState?.batch}
                                        onChange={(val) => lsIdpAction.setBatch(val.target.value)}
                                    >
                                    </input>
                                )
                            },
                            {
                                type: 'custom',
                                placeholder: 'Nama',
                                component: (
                                    <input type='text' className='form-control form-control-sm mb-2'
                                        value={lsIdpState?.course_name}
                                        placeholder='Training Name'
                                        onChange={(val) => lsIdpAction.setCourse_name(val.target.value)}
                                    >
                                    </input>
                                )
                            }, 
                        ]}
                        customToolbar={<div className='text-right mb-3'>
                            <button className='btn btn-sm btn-success'
                                onClick={() => htmlToExcel("table-to-xls", `${lsIdpState?.batch}_Schedule_${moment(Date.now()).format("YYYY-MM-DD HH_mm_SS")}`, "statement")}
                            >
                                <BiFile style={{ fonstSize: '12pt' }} /> Export Excel
                            </button>
                        </div>}
                        labelFilter="Tahun IDP Register :"
                        resetFilter={false}
                        onFilter={() => lsIdpAction.onFilter()}
                        minHeight="300px"
                        maxHeight="500px"
                        navtable={"TABLE 1"}
                        withCard={false}
                        isLoading={lsIdpState.loadingGet}

                        onChangeShow={lsIdpAction.changeShow}
                        onNextPage={lsIdpAction.nextPage}
                        onPrevPage={lsIdpAction.prevPage}
                        defaultShow={lsIdpState?.pagination?.row_in_page}
                        curPage={lsIdpState?.pagination?.page}
                        maxPage={lsIdpState?.dataGet?.tableData?.totalPage || 0}
                        totalData={lsIdpState?.dataGet?.tableData?.totalData || 0}

                        withActions={false}
                        columns={[
                            {
                                name: '#',
                                field: '#',
                                maxWidth: '50px',
                            },
                            {
                                name: 'Category',
                                field: 'category',
                            },
                            {
                                name: 'Training Name',
                                field: 'course_name',
                            },
                            {
                                name: 'Trainer',
                                field: 'trainer_nik',
                                onRender: (item) => (
                                    <span className='text-wrap'>{item?.trainer_nik} - {item?.trainer_name}</span>
                                )
                            },
                            {
                                name: 'Kuota Training',
                                field: 'quota',
                            },
                            {
                                name: 'Start',
                                field: 'start_date',
                                onRender: (item) => (
                                    <span className='text-wrap'>{moment(item?.start_date).format('DD/MM/YYYY')}</span>
                                )
                            },
                            {
                                name: 'Finish',
                                field: 'end_date',
                                onRender: (item) => (
                                    <span className='text-wrap'>{moment(item?.end_date).format('DD/MM/YYYY')}</span>
                                )

                            },
                            {
                                name: '',
                                minWidth:'100px',
                                field: '',
                                onRender: (item) => (
                                    <button className='btn btn-sm btn-danger' onClick={() => lsIdpAction.ConfirmDelete(item?.ats_id)}><i className='fa fa-trash-alt'/> Delete</button>
                                )
                            },
                        ]}
                        data={[...(lsIdpState.dataGet?.tableData?.listData || [])]}
                    />

                </div>
                <div className='card-footer'>
                    <div className='row'>
                        <div className='col-12'>
                            <button className='btn btn-sm btn-secondary'
                                onClick={() => history.goBack()}
                            >Back</button>
                        </div>
                    </div>
                </div>
            </div>
            <TableToHTML />
        </div>
    );
}

export default (props) => <LsIdpProvider><Index {...props} /></LsIdpProvider>;