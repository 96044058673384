import React from 'react';
import { Link } from 'react-router-dom';
import * as pages from './pages';
import useMentoring, { MenProvider } from './useMentoring';

function Index(props) {
    const { menAction: {
        toListPage = () => null,
        toListTraineePage = () => null,
    }, menState } = useMentoring();
    const PageRender = pages[menState.pageState.type];
    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <div className='row'>
                        <div className='col'>
                            <h4>Data Mentoring / Observation</h4>
                            <h6>{menState.pageState?.title}</h6>
                        </div>
                        <div className='col'>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-end">
                                    {
                                        menState.pageState.type === "ListCom" && (
                                            <>
                                                <li className="breadcrumb-item" aria-current="page"><Link onClick={toListTraineePage}>List Pengawas</Link></li>
                                                <li className="breadcrumb-item active" aria-current="page">{menState.pageState.title}</li>
                                            </>
                                        )
                                    }
                                    {
                                        menState.pageState?.type === "ListTrainee" && (
                                            <>
                                                {/* <li className="breadcrumb-item" aria-current="page"><Link onClick={toListPage}>List Competency</Link></li> */}
                                                <li className="breadcrumb-item active" aria-current="page">{menState.pageState.title}</li>
                                            </>
                                        )
                                    }
                                    {
                                        menState.pageState?.type === "FormPage" && (
                                            <>
                                                <li className="breadcrumb-item" aria-current="page"><Link onClick={() => toListTraineePage({ competency_id: menState.pageState?.dataPage?.competency_id })}>List Trainee</Link></li>
                                                <li className="breadcrumb-item" aria-current="page"><Link onClick={() => toListPage({ trainee_nik: menState.pageState?.dataPage?.trainee_nik })}>List Course</Link></li>
                                                <li className="breadcrumb-item active" aria-current="page">{menState.pageState.title}</li>
                                            </>
                                        )
                                    }
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <PageRender />
        </>
    );
}

export default (props) => <MenProvider><Index /></MenProvider>;