import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useModalAlert } from "../../../../components/modalAlert";
import useFetch from "../../../../hooks/useFetch";

const dtrContext = createContext({
    dtrState: {},
    dtrAction: {}
});
export function DtrProvider({ children }) {
    const history = useHistory();
    const [dataState, setdataState] = useState(history.location.state);
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const { doGet, doPost } = useFetch();
    const [loadingGet, setLoadingGet] = useState(false);
    const [data, setData] = useState([]);


    async function getList() {
        try {
            setLoadingGet(true);
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/job-assignment/training-trainee',
                    param: {
                        trainee_nik: dataState?.trainee_nik || ''
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = [], code, msg } = payload;
                if (code === "00") {
                    setData([...data]);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Job Assignment",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Job Assignment",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Job Assignment",
                callBack: () => null
            })
        }
        setLoadingGet(false)
    }
    useEffect(() => {
        if (!dataState?.trainee_nik) {
            return setDataAlert({
                show: true,
                body: "Buka halaman ini melalui menu Job Assigment [Trainer]",
                header: "Trainee tidak di pilih",
                callBack: () => null
            })
        }
        getList();
    }, [])
    return <dtrContext.Provider
        value={{
            dtrState: {
                data,
                loadingGet,
                dataState
            },
            dtrAction: {}
        }}
    >{children}</dtrContext.Provider>
}
export default function useDetailTrainee() {
    return useContext(dtrContext);
}