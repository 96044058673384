import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { TableList } from '../../../../components';
import './index.css';
import useResTrainee, { ResultTraineeProvider } from './useResultTrainee';
import moment from 'moment';
import { Link } from 'react-router-dom';

function ResultListTrainee(props) {
    const { resTeAction, resTeState } = useResTrainee();

    const imageUrl = useMemo(() => {
        if (resTeState.dataEditor?.file) {
            return URL.createObjectURL(resTeState.dataEditor?.file);
        } else {
            return resTeState.dataEditor?.certificate_path
        }
    }, [resTeState.dataEditor]);
    return (
        <div>
            <div className='row'>
                <div className='col-6'>
                    <section className="content-header">
                        <div className="container-fluid">
                            <h4>Training Result</h4>
                            <h6>List Trainee</h6>
                        </div>
                    </section>
                </div>
                <div className='col-6'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end">
                            <li className="breadcrumb-item" aria-current="page">
                                <Link to="/development/data-result-training">
                                    List Training
                                </Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">List Trainee</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="card">
                <div className="card-body p-3">
                    <div className='row'>
                        <div className='col-md-6 mb-2'>
                            <div className="form-group row mb-0">
                                <label className="col-sm-3 col-form-label">Jobsite<span className='float-right'>:</span></label>
                                <div className="col-sm-9">
                                    <span type="text" readonly className="form-control-plaintext">{resTeState.header?.jobsite || ''}</span>
                                </div>
                            </div>
                            <div className="form-group row mb-0">
                                <label className="col-sm-3 col-form-label">Cluster<span className='float-right'>:</span></label>
                                <div className="col-sm-9">
                                    <span type="text" readonly className="form-control-plaintext">{resTeState.header?.cluster || ''}</span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 mb-2'>
                            <div className="form-group row mb-0">
                                <label className="col-sm-4 col-form-label">Competency Name<span className='float-right'>:</span></label>
                                <div className="col-sm-8">
                                    <span type="text" readonly className="form-control-plaintext">{resTeState.header?.competency_name || ''}</span>
                                </div>
                            </div>
                            <div className="form-group row mb-0">
                                <label className="col-sm-4 col-form-label">Course Name<span className='float-right'>:</span></label>
                                <div className="col-sm-8">
                                    <span type="text" readonly className="form-control-plaintext">{resTeState.header?.course_name || ''}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <TableList
                        curPage={1}
                        withCard={false}
                        pagination={true}
                        minHeight="300px"
                        maxHeight="500px"
                        navtable={"TABLE 1"}
                        isLoading={resTeState.loadingGet}
                        // onChangeShow={rekAction.changeShow}
                        // onNextPage={rekAction.nextPage}
                        // onPrevPage={rekAction.prevPage}
                        withActions={true}
                        actionType={1}
                        actionItems={[
                            {
                                name: 'Update Nilai',
                                onClick: function (item) {
                                    resTeAction.setShowEditor({ show: true, data: { ...item } })
                                }
                            },
                        ]}
                        columns={[
                            {
                                name: '#',
                                field: '#',
                                maxWidth: '50px',
                            },
                            {
                                name: "Nama",
                                field: 'trainee_name',
                                minWidth: '100px',
                            },
                            {
                                name: "NIK",
                                field: 'trainee_nik',
                                minWidth: '100px',
                                ordering: true,
                            },
                            {
                                name: "Pre Test",
                                field: 'pre_test_score',
                                minWidth: '100px',
                                onRender: (item) => {
                                    return (
                                        <span className={`text-wrap ${!item?.pre_test_score ? "text-secondary" : ""}`}>{`${item?.pre_test_score || 'Belum diisi'}`}</span>
                                    )
                                }
                            },
                            {
                                name: "Post Test",
                                field: 'post_test_score',
                                minWidth: '100px',
                                onRender: (item) => {
                                    return (
                                        <span className={`text-wrap ${!item?.post_test_score ? "text-secondary" : ""}`}>{`${item?.post_test_score || 'Belum diisi'}`}</span>
                                    )
                                }
                            },
                            {
                                name: '% Kehadiran',
                                field: 'day_present',
                                maxWidth: '150px',
                                onRender: (item) => (
                                    <span>{(item?.day_present * 100 / item?.duration) || 0} %</span>
                                )
                            },
                            {
                                name: "Start Date",
                                field: 'start_date',
                                minWidth: '100px', onRender: (item) => (
                                    <span className='text-wrap'>{moment(item?.start_date).format('DD/MM/YYYY')}</span>
                                )
                            },
                            {
                                name: "End Date",
                                field: 'end_date',
                                minWidth: '100px',
                                onRender: (item) => (
                                    <span className='text-wrap'>{moment(item?.end_date).format('DD/MM/YYYY')}</span>
                                )
                            },
                            {
                                name: "Status",
                                field: 'is_remedial',
                                minWidth: '50px',
                                onRender: (item) => (
                                    <span className='text-wrap'>{item?.is_remedial == 1 ? 'Gagal Assessment Tahun Lalu' : 'Baru Ambil'}</span>
                                )
                            }
                        ]}
                        data={[...(resTeState.dataDetail?.listTrainee || [])]}
                    />
                    <Link to="/development/data-result-training">
                        <button className='btn btn-sm btn-secondary'>Back</button>
                    </Link>

                </div>
            </div>
            <Modal
                dialogClassName='modal-editor-trainee'
                backdropClassName='bg-transparent'
                show={resTeState.showEditor?.show}
                onHide={() => resTeAction.setShowEditor({ show: false, data: {} })}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        UPDATE DATA
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault(e);
                            resTeAction.confirmSubmit();
                        }}
                    >
                        <div className="form-group row">
                            <label className="col-sm-5 col-form-label">Nilai Pre Test</label>
                            <div className="col-sm-7">
                                <input
                                    required
                                    type="number"
                                    className="form-control"
                                    min={0}
                                    max={100}
                                    value={resTeState.dataEditor?.pre_test_score}
                                    onChange={(e) => resTeAction.handleChange('pre_test_score', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-5 col-form-label">Nilai Post Test</label>
                            <div className="col-sm-7">
                                <input
                                    required
                                    type="number"
                                    className="form-control"
                                    min={0}
                                    max={100}
                                    value={resTeState.dataEditor?.post_test_score}
                                    onChange={(e) => resTeAction.handleChange('post_test_score', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-5 col-form-label">Start</label>
                            <div className="col-sm-7">
                                <span className='date-style'>{resTeState.dataEditor?.start_date ? moment(resTeState.dataEditor?.start_date).format('DD/MM/YYYY') : ''}</span>
                                {/* <input
                                    readOnly
                                    type="date"
                                    className="form-control"
                                    value={resTeState.dataEditor?.start_date}
                                    max={resTeState.dataEditor?.end_date}
                                    onChange={(e) => resTeAction.handleChange('start_date', e.target.value)}
                                /> */}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-5 col-form-label">Finish</label>
                            <div className="col-sm-7">
                                <span className='date-style'>{resTeState.dataEditor?.end_date ? moment(resTeState.dataEditor?.end_date).format('DD/MM/YYYY') : ''}</span>
                                {/* <input
                                    readOnly
                                    type="date"
                                    min={resTeState.dataEditor?.start_date}
                                    className="form-control"
                                    value={resTeState.dataEditor?.end_date}
                                    onChange={(e) => resTeAction.handleChange('end_date', e.target.value)}
                                /> */}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-5 col-form-label">Kehadiran (hari)</label>
                            <div className="col-sm-7">
                                <input type="number" className="form-control" placeholder="jumlah hari hadir"
                                    required
                                    min={0}
                                    max={resTeState.dataEditor?.duration}
                                    value={resTeState.dataEditor?.day_present}
                                    onChange={(e) => resTeAction.handleChange('day_present', e.target.value)}
                                />
                                <div>
                                    <span className='d-block text-muted'>Persentase kehadiran = {(resTeState.dataEditor?.day_present * 100 / resTeState.dataEditor?.duration) || 0}%</span>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-5 col-form-label">Certificate</label>
                            <div className="col-sm-7">
                                <div className='d-block tw-h-[240px] tw-w-[240px] border border-secondary mb-3'>
                                    {
                                        imageUrl && (
                                            <img className='mb-2 tw-w-full tw-h-full' src={imageUrl}></img>
                                        )
                                    }
                                </div>
                                <input
                                    id="inputfile"
                                    accept="image/png, image/jpg, image/jpeg, image/png, image/bmp, image/gif, image/webp, image/image"
                                    onChange={(e) => resTeAction.handleChange('file', e.target.files[0])}
                                    type="file"
                                    className="form-control"                            
                                />
                            </div>
                        </div>
                        <div className='text-right'>
                            <button type='button' className='btn btn-sm btn-secondary mr-1'
                                onClick={() => resTeAction.setShowEditor({ show: false, data: {} })}
                            >Batal</button>
                            <button type='submit' className='btn btn-sm btn-primary'>Simpan</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default (props) => <ResultTraineeProvider><ResultListTrainee {...props} /></ResultTraineeProvider>
