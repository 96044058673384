import { createContext, useContext, useState } from "react";
import { useModalAlert } from "../../../components/modalAlert";
import useFetch from "../../../hooks/useFetch";

const RPContext = createContext({
    RPAction: {},
    RPState: {},
});

export function RPProvider({ children }) {
    const [formData, setFormData] = useState({
        nik: "",
        password: "",
        password_confirmation: "",
    });
    const { doGet, doPost } = useFetch();
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading,
    } = modalAction;

    async function doSubmit() {
        if(
            !formData.nik || formData.nik === "" || 
            !formData.password || formData.password === "" ||
            !formData.password_confirmation || formData.password_confirmation===""
        ) return setDataAlert({
            show: true,
            body: "Form Belum Lengkap",
            header: "Info",
            callBack: () => null,
        });
        try {
            setDataConfirm({
                show: true,
                body: `Apakah anda akan memperbarui password dengan nik ${formData.nik}. Lanjutkan?`,
                witTextArea: false,
                header: "Confirmation",
                title: "",
                closeButton: false,
                footerButton: [
                    {
                        name: "Yes",
                        onClick: async () => {
                            closeConfirm()
                            openLoading("Please wait, creating new password");
                            const { status, statusText, payload } =
                                await doPost({
                                    url: "/api/reset-password-byadmin",
                                    data: formData,
                                });
                            if (status === 401) {
                                closeLoading();
                            }else if(status === 200){
                                const { data = {}, code, msg } = payload;
                                if (code === "00"){
                                    setDataAlert({
                                        show: true,
                                        body: "Password berhasil di update",
                                        header: "Reset Password",
                                        callBack: () => setFormData({
                                            nik:"",
                                            password:"",
                                            password_confirmation:""
                                        }),
                                    });
                                }else setDataAlert({
                                    show: true,
                                    body: msg,
                                    header: "Reset Password",
                                    callBack: () => null,
                                });
                            }else setDataAlert({
                                show: true,
                                body: statusText,
                                header: "Reset Password",
                                callBack: () => null,
                            });
                            closeLoading()
                        },
                        className: "btn btn-sm btn-success",
                    },
                    {
                        name: "No",
                        onClick: closeConfirm,
                        className: "btn btn-sm btn-secondary",
                    },
                ],
            });
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Reset Password",
                callBack: () => null,
            });
        }
    }

    return (
        <RPContext.Provider
            value={{
                RPAction: {
                    setFormData,
                    doSubmit
                },
                RPState: {
                    formData,
                },
            }}
        >
            {children}
        </RPContext.Provider>
    );
}

export default () => useContext(RPContext);
