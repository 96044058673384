import React from 'react';
import { TableList } from '../../../components';
import useDashboard from '../useDashboard';

function Index(props) {
    const { dashState: {
        loadingJr = false,
        dataJr = []
    }, dashAction: {
        toListPage = () => null
    } } = useDashboard();
    return (
        <TableList
            curPage={1}
            minHeight="300px"
            maxHeight="500px"
            navtable={"TABLE 1"}
            isLoading={loadingJr}
            // onChangeShow={rekAction.changeShow}
            // onNextPage={rekAction.nextPage}
            // onPrevPage={rekAction.prevPage}
            withActions={false}
            actionType={1}
            actionItems={[
                {
                    name: 'Lihat detail',
                    onClick: function (item) {
                        // history.push(`/idp-online/approval/detail/${item?.nik || ''}`, { ...item })
                    }
                }
            ]}
            columns={[
                {
                    name: '#',
                    field: '#',
                    maxWidth: '50px',
                },
                {
                    name: 'NIK',
                    field: 'nik',
                    maxWidth: '150px',
                },
                {
                    name: 'Nama',
                    field: 'name',
                    maxWidth: '250px',
                },
                {
                    name: 'Jabatan',
                    field: 'job_title',
                    maxWidth: '150px',
                },
                {
                    name: 'Aksi',
                    field: '',
                    maxWidth: '150px',
                    onRender: (item, i) => (
                        <button className='btn btn-sm btn-success'
                            onClick={() => toListPage({nik:item?.nik})}
                        >Lihat</button>
                    )
                },
            ]}
            data={[...(dataJr || [])]}
        />
    );
}

export default Index;