import React, { createContext, useContext, useState } from "react";
import useAuth from "../../context/useAuth";

const loginContext = createContext({
    lgnAction: {},
    lgnState: {}
})

export function LoginProvider({ children }) {
    const { doLogin } = useAuth();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');


    function onSubmit() {
        doLogin(JSON.stringify({ nik: username, password }))
    }
    return <loginContext.Provider
        value={{
            lgnAction: {
                setUsername,
                setPassword,
                onSubmit
            },
            lgnState: {
                username,
                password
            }
        }}
    >{children}</loginContext.Provider>
}
export default function useLogin() {
    return useContext(loginContext);
}