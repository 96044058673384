import React from 'react';
import useAssNonIdp, { AssNonIdpProvider } from './useAssNonIdp';
import { StatusIcon, TableList } from '../../../components';
import { useHistory, Link } from 'react-router-dom';

function Index(props) {
    const { assNonIdpState, assNonIdpAction } = useAssNonIdp();
    const history = useHistory();
    const CustomToolbar = () => (
        <div className='tw-flex tw-justify-end'>
            <Link to="/master-data/assessment-non-idp/add"><button className='btn btn-sm btn-success'>Create</button></Link>
        </div>
    )
    return (
        <>
            <div className="row">
                <div className="col-6">
                    <section className="content-header">
                        <div className="container-fluid">
                            <h4>Master Data</h4>
                            <h6>Assessment Non IDP</h6>
                        </div>
                    </section>
                </div>
                <div className="col-6">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end">
                            <li className="breadcrumb-item" aria-current="page">
                                Master Data
                            </li>
                            <li
                                className="breadcrumb-item active"
                                aria-current="page"
                            >
                                Assessment Non IDP
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="tw-flex tw-items-center">
                                <h6>Upload Assessment Non IDP</h6>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="tw-grid md:tw-grid-cols-3 tw-grid-cols-1 tw-w-full tw-items-center tw-gap-x-3 tw-gap-y-2">
                                <div>
                                    <label> Type </label>
                                    <select
                                        onChange={(e) => assNonIdpAction.handleChangeFom("type", e.target.value)}
                                        value={assNonIdpState.formData?.type}
                                        className="form-control "
                                    >
                                        <option value={""}>
                                            - Pilih -
                                        </option>
                                        <option value={"Mechanic"}>
                                            Mechanic
                                        </option>
                                        <option value={"Operator"}>
                                            Operator
                                        </option>
                                    </select>
                                </div>
                                <div>
                                    <label>File</label>
                                    <input
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/csv, text/csv, .csv, .xls, .xlsx"
                                        onChange={(e) => assNonIdpAction.handleChangeFom("file", e.target.files[0])}
                                        type="file"
                                        className="form-control"
                                    />
                                </div>
                                <div>

                                    <label>&nbsp;</label>
                                    <button
                                        className="btn lg:btn-sm btn-primary md:tw-mt-8"
                                        onClick={() => {
                                            assNonIdpAction.doUpload()
                                        }}
                                    >
                                        Upload
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div className='tw-grid md:tw-grid-cols-5 tw-grid-cols-1 tw-w-full tw-items-center tw-gap-x-3 tw-gap-y-2'>
                                <div>
                                    <label className="font-weight-normal">Type</label>
                                    <select
                                        onChange={(e) => assNonIdpAction.setFilter(vs => ({ ...vs, type: e.target.value }))}
                                        value={assNonIdpState.filter?.type}
                                        className="form-select form-select-sm"
                                    >
                                        <option value={""}>
                                            - Semua -
                                        </option>
                                        <option value={"Mechanic"}>
                                            Mechanic
                                        </option>
                                        <option value={"Operator"}>
                                            Operator
                                        </option>
                                    </select>
                                </div>
                                <div>
                                    <label className="font-weight-normal">Batch</label>
                                    <input type='number' className='form-control form-control-sm'
                                        placeholder='Batch'
                                        value={assNonIdpState.filter?.batch}
                                        onChange={(e) => assNonIdpAction.setFilter(vs => ({ ...vs, batch: e.target.value }))}
                                    >
                                    </input>
                                </div>
                                <div>
                                    <label className="font-weight-normal">NIK</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="NIK"
                                        value={assNonIdpState.filter?.nik}
                                        onChange={(e) => {
                                            assNonIdpAction.setFilter(vs => ({ ...vs, nik: e.target.value }))
                                        }}
                                    ></input>
                                </div>
                                <div>
                                    <label className="font-weight-normal">Search</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Search"
                                        value={assNonIdpState.filter?.search}
                                        onChange={(e) => {
                                            assNonIdpAction.setFilter(vs => ({ ...vs, search: e.target.value }))
                                        }}
                                    ></input>
                                </div>
                                <div className="tw-flex tw-h-full">
                                    <button className='btn btn-sm btn-primary tw-mt-auto'
                                        onClick={() => assNonIdpAction.doFilter()}
                                    ><i className='fa fa-search'></i> Search</button>
                                </div>
                            </div>
                            <TableList
                                filter={true}
                                data={assNonIdpState.dataList || []}
                                labelFilter=""
                                withCard={false}
                                resetFilter={false}
                                isLoading={assNonIdpState.loadingList}
                                minHeight="300px"
                                maxHeight="500px"
                                navtable={"TABLE 1"}
                                customToolbar={<CustomToolbar />}
                                withButtonSubmit={false}

                                onChangeShow={assNonIdpAction.changeShow}
                                onNextPage={assNonIdpAction.nextPage}
                                onPrevPage={assNonIdpAction.onPrevPage}
                                defaultShow={assNonIdpState.pagination?.show}
                                curPage={assNonIdpState.pagination?.page || 1}
                                maxPage={assNonIdpState.pagination?.totalPage || 1}
                                totalData={assNonIdpState.pagination?.totalData || 0}
                                withActions={true}
                                actionType={1}
                                actionItems={[
                                    {
                                        name: "Lihat detail",
                                        onClick: function (item) {
                                            history.push(`/master-data/assessment-non-idp/detail?id=${item.id}`)
                                        },
                                    },
                                    {
                                        name: "Ubah",
                                        onClick: function (item) {
                                            history.push(`/master-data/assessment-non-idp/ubah?id=${item.id}`)
                                        },
                                    },
                                    {
                                        name: "Hapus",
                                        onClick: function (item) {
                                            assNonIdpAction.doDelete(item.id)
                                        },
                                    },
                                ]}
                                columns={[
                                    {
                                        name: "#",
                                        field: "#",
                                    },
                                    {
                                        name: "Assessment Name",
                                        field: "assessment_name",
                                    },
                                    {
                                        name: "NIK",
                                        field: "nik",
                                    },
                                    {
                                        name: "Nama Karyawan",
                                        field: "name",
                                    },
                                    {
                                        name: "Tanggal Pelaksanaan",
                                        field: "date",
                                    },
                                    {
                                        name: "Assessor NIK",
                                        field: "assessor_nik",
                                    },
                                    {
                                        name: "Assessor Name",
                                        field: "assessor_name",
                                    },
                                    {
                                        name: "Assessment_result",
                                        field: "score",
                                        onRender: ({ score }) => {
                                            return <StatusIcon status={score} />
                                        }
                                    },
                                ]}
                            />
                        </div>
                        <div className="card-footer"></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default () => <AssNonIdpProvider><Index /></AssNonIdpProvider>;
