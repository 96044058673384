import React, { createContext, useContext, useEffect, useState } from "react";
import 'anychart';

import useFetch from "../../../hooks/useFetch";
import { useModalAlert } from "../../../components/modalAlert";
import moment from "moment";

const context = createContext({
    reportDtStartTrainingAction: {},
    reportDtStartTrainingState: {}
});

export function ReportDataStartTrainingProvider({ children }) {
    const { modalAction } = useModalAlert();
    const {
        closeLoading,
        setDataAlert,
    } = modalAction;    

    const { doGet, doPost } = useFetch();
    const [dataList, setDataList] = useState([]);
    const [loadingGet, setLoadingGet] = useState(false);
    
    const [stream, setStream] = useState("Operator");
    const [start_date, setStartDdate] = useState("");
    const [end_date, setEndDate] = useState("");

    async function getDataList() {
        setLoadingGet(true)
        try {
            const { status, payload, statusText } = await doPost({
                url: '/api/reporting/report-started-training',
                data: { 
                    start_date, 
                    end_date, 
                    stream 
                }
            });
            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = [], code, msg } = payload;
                if (code === "00") {
                    setDataList(data.map(d => ([
                        d.pers_area_name,
                        d.total_plan,
                        d.total_actual,
                        Number(d.achivement_percentage)
                    ]) ) || []);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Report StartTraining",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Report StartTraining",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Report StartTraining",
                callBack: () => null
            })
        }
        setLoadingGet(false)
    }

    function onFilter() {
        getDataList();
    }

    useEffect(function () {
        getDataList();
    }, []);

    return <context.Provider
        value={{
            reportDtStartTrainingAction: {
                onFilter,
                setStartDdate,
                setEndDate,
                setStream,
                getDataList
            },
            reportDtStartTrainingState: {
                start_date,
                end_date,
                stream,
                dataList,
                loadingGet
            }
        }}
    >{children}</context.Provider>
}

export default function useReportDataStartTraining() {
    return useContext(context);
}