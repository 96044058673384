import logo from './logo.svg';
import './App.css';
import { realm } from './config';
import { Switch } from 'react-router';
import AppIndex from './apps';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak, { keycloakConfig } from './apps/module/keycloak.config';
import React from 'react';
import { AuthProvider, GlobalProvider } from './apps/context';
import { ModalAlertProvider } from './apps/components/modalAlert';
import "react-datepicker/dist/react-datepicker.css";


function App() {
  return (
    <ModalAlertProvider>
      <AuthProvider>
        <GlobalProvider>
          <AppIndex />
        </GlobalProvider>
      </AuthProvider>
    </ModalAlertProvider>
  );
}

export default App;
