import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { StatusIcon, TableList } from '../../../../components';
import usePeriksaJob, { PrjProvider } from './usePeriksaJob';

function Index(props) {
    const history = useHistory();
    const { prjAction, prjState } = usePeriksaJob();
    return (
        <div>
            <section className="content-header">
                <div className="container-fluid">
                    <div className='row'>
                        <div className='col-md-6'>
                            <h4>Job Assignment </h4>
                            <h6>List Job</h6>
                        </div>
                        <div className='col-md-6'>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-end">
                                    <li className="breadcrumb-item"><Link to={"/development/job-assignment"}>List Trainee</Link></li>
                                    <li className="breadcrumb-item"><Link to={'#'} onClick={() => history.goBack()}>Detail Trainee</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">List Job</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <div className='card'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='font-weight-bold mb-3'><label style={{ width: '150px' }}>Trainee</label>: {prjState.dataState?.trainee_name}</div>
                            <div className='font-weight-bold mb-5'><label style={{ width: '150px' }}>Training</label>: {prjState.dataState?.training_name}</div>
                            <TableList
                                withCard={false}
                                filter={false}
                                isLoading={prjState.loadingGet}
                                minHeight="300px"
                                maxHeight="500px"
                                navtable={"TABLE 1"}
                                withActions={false}
                                actionType={1}
                                columns={[
                                    {
                                        name: '#',
                                        field: '#',
                                        maxWidth: '50px',
                                    },
                                    {
                                        name: "Title",
                                        field: 'title',
                                    },
                                    {
                                        name: "Status",
                                        field: 'status',
                                        onRender: ({ status }) => {
                                            return (
                                                <StatusIcon status={status} />
                                                //         <span className={`
                                                // badge badge-${status === "declined" ? "danger" :
                                                //                 status === "approved" ? "success" :
                                                //                     status === "submitted" ? "primary" : ""
                                                //             }`}>
                                                //             {status}
                                                //         </span>
                                            )
                                        }
                                    },
                                    {
                                        name: "Aksi",
                                        field: 'trainee_nik',
                                        width: '300px',
                                        className: "text-right",
                                        onRender: (item) => (
                                            <div className='d-block'>
                                                <button
                                                    onClick={() => prjAction.openModalDetil(item)}
                                                    className='btn btn-sm btn-primary mr-1'
                                                >Lihat</button>
                                                {
                                                    item?.status === 'submitted' && (
                                                        <>
                                                            <button className='btn btn-sm btn-success mr-1'
                                                                onClick={() => prjAction.confirmSubmit('approved', item?.job_assignment_id)}
                                                            >Approve</button>
                                                            <button className='btn btn-sm btn-danger mr-1'
                                                                onClick={() => prjAction.openModalDecline({ status: 'declined', job_assignment_id: item?.job_assignment_id })}
                                                            >Decline</button>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                ]}
                                data={[...(prjState.data || [])]}
                            />
                        </div>
                    </div>
                    <div className='mt-3'>
                        <button className='btn btn-sm btn-secondary'
                            onClick={() => history.goBack()}
                        >Back</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default (props) => <PrjProvider><Index {...props} /></PrjProvider>;