import React, { createContext, useContext, useState } from "react";

const mtraContext = createContext({
    mtrAction:{},
    mtrState:{}
})

export function MtrProvider({children}){
    const [data, setData] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [filter, setFilter] = useState('');
    function onFilter(){
        let newData = [];        
        newData = data.filter((e) => {
            return  (e.trainee_name || '').toLowerCase().indexOf(filter.toLowerCase()) > -1 || (e.trainee_nik || '').toLowerCase().indexOf(filter.toLowerCase()) > -1
        });
        setDataTable([...newData]);
    }
    return <mtraContext.Provider
        value={{
            mtrAction:{
                onFilter,
                setData,
                setFilter,
                setDataTable
            },
            mtrState:{
                data,
                filter,
                dataTable
            } 
        }}
    >{children}</mtraContext.Provider>
}

export default function useModalTrainee(){
    return useContext(mtraContext);
}