import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useModalAlert } from "../../../components/modalAlert";
import useFetch from "../../../hooks/useFetch";

const phmContext = createContext({
    phmState: {},
    phmAction: {}
});
const iniStatePage = {
    title: "List Trainee",
    type: "ListTrainee",
    dataPage: {}
};
export function PhmProvider({ children }) {
    const { doGet, doPost, wait } = useFetch();
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const [pageState, setPageState] = useState(iniStatePage);
    const [listTrainee, setListTrainee] = useState([]);
    const [loadingTrainee, setLoadingTrainee] = useState(false);
    const [listTraining, setListTraining] = useState([]);
    const [loadingTraining, setLoadingTraining] = useState(false);
    const getListTrainee = async function () {
        setLoadingTrainee(true);
        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/list-junior',
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = [], code, msg } = payload;
                if (code === "00") {
                    setListTrainee(data || []);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get List Hm",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List Hm",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Hm",
                callBack: () => null
            })
        }
        setLoadingTrainee(false)
    }
    const getListTraininig = async function (trainee_nik = '') {
        setLoadingTraining(true);
        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/hm/training-trainee',
                    param:{
                        trainee_nik
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = [], code, msg } = payload;
                if (code === "00") {
                    setListTraining(data || []);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get List Training",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List Training",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Training",
                callBack: () => null
            })
        }
        setLoadingTraining(false)
    }
    const toListTrainingPage = useCallback(function (data_trainee = {}) {
        setPageState({
            title: 'List Training',
            type: "ListTraining",
            dataPage: {
                trainee:{
                    ...data_trainee
                },
            }
        })
    }, [pageState]);
    const toListTraineePage = useCallback(function () {
        setPageState({ ...iniStatePage })
    }, [pageState]);
    useEffect(function () {
        if (pageState.type === "ListTrainee") getListTrainee()
        if (pageState.type === "ListTraining") getListTraininig(pageState?.dataPage?.trainee?.nik)
    }, [pageState])
    return <phmContext.Provider
        value={{
            phmState: {
                pageState,
                loadingTrainee,
                listTrainee,
                listTraining,
                loadingTraining
            },
            phmAction: {
                toListTraineePage,
                toListTrainingPage
            }
        }}
    >{children}</phmContext.Provider>
}
export default function usePencapaianHm() {
    return useContext(phmContext)
}