import React from 'react';
import useDwnldDataEmployee, { DwnldDataEmployeeProvider } from './useDwnldDataEmployee';

import { BiFile } from 'react-icons/bi';
import TableToHTML from './export';
import { htmlToExcel } from '../../../modules';
import moment from 'moment';

function Index(props) {
    const { dwnldDtEmployeeAction, dwnldDtEmployeeState } = useDwnldDataEmployee();

    return (
        <div>
            <div className='row'>
                <div className='col-6'>
                    <section className="content-header">
                        <div className="container-fluid">
                            <h4>Download Data</h4>
                            <h6>Demografi Karyawan</h6>
                        </div>
                    </section>
                </div>
                <div className='col-6'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end">
                            <li className="breadcrumb-item active" aria-current="page">
                                Demografi Karyawan
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>

            {/*  */}
            <div className="card pb-2">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <span>Download data berdasarkan :</span>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-md col-lg col-sm-6">
                            <div className="form-group row mb-1">
                                <label htmlFor="inputEmail3" className="col-sm-3 font-weight-normal">Jobsite</label>
                                <div className="col-sm-9">
                                    <select className='form-select'
                                        value={dwnldDtEmployeeState?.jobsite_code}
                                        onChange={(val) => dwnldDtEmployeeAction.setJobsite_code(val.target.value)}
                                    >
                                        {
                                            [...(dwnldDtEmployeeState.dataJobsite || [])].map((d, i) => (
                                                <option value={d?.jobsite_code} key={i}>{d?.jobsite_name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="form-group row mb-1">
                                <label htmlFor="inputEmail3" className="col-sm-3 font-weight-normal">Roles</label>
                                <div className="col-sm-9">
                                    <select className='form-select'
                                        value={dwnldDtEmployeeState?.role_id}
                                        onChange={(val) => {
                                            let value = val.target.value;
                                            let jobType = value == 2 ? dwnldDtEmployeeState.filters.jobTypesTrainer[0] : dwnldDtEmployeeState.filters.jobTypesEmployee[0];

                                            dwnldDtEmployeeAction.setRole_id(value);
                                            dwnldDtEmployeeAction.setJob_type(jobType);
                                        } }
                                    >
                                        {
                                            [...(dwnldDtEmployeeState.filters.roles || [])].map((d, i) => (
                                                <option value={d?.id} key={i}>{d?.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="form-group row mb-1">
                                <label htmlFor="inputEmail3" className="col-sm-3 font-weight-normal">Job Type</label>
                                <div className="col-sm-9">
                                    <select className='form-select'
                                        value={dwnldDtEmployeeState?.job_type}
                                        onChange={(val) => dwnldDtEmployeeAction.setJob_type(val.target.value)}
                                    >
                                        {
                                            [...((
                                                dwnldDtEmployeeState?.role_id == 2 ? 
                                                    dwnldDtEmployeeState.filters.jobTypesTrainer
                                                : dwnldDtEmployeeState.filters.jobTypesEmployee
                                            ) || [])].map((d, i) => (
                                                <option value={d} key={i}>{d}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>

                            
                            <div className="form-group row mb-1 mt-4">
                                <div className='d-flex justify-content-end h-100 mb-3'>
                                    <button className='btn btn-sm btn-success mt-auto'
                                        style={{ height: 'fit-content' }}
                                        disabled={dwnldDtEmployeeState.loadingGet}
                                        onClick={async () => {
                                            let role = dwnldDtEmployeeState.filters.roles?.find(d => d.id == dwnldDtEmployeeState?.role_id);
                                            let jobsite = dwnldDtEmployeeState.dataJobsite?.find(d => d.jobsite_code === dwnldDtEmployeeState?.jobsite_code);
                                            await dwnldDtEmployeeAction.getDataList();
                                            htmlToExcel("table-to-xls", `Demografi ${role.name} ${jobsite.jobsite_name}_${moment(Date.now()).format("YYYY-MM-DD HH_mm_SS")}`, "statement")
                                        } }
                                    >
                                        <BiFile style={{ fonstSize: '12pt' }} /> 
                                        {dwnldDtEmployeeState.loadingGet ? "Mohon menunggu" : "Export Excel" }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TableToHTML />
        </div>
    );
}

export default () => <DwnldDataEmployeeProvider><Index /></DwnldDataEmployeeProvider>;