import React from 'react';
import { useHistory } from 'react-router-dom';
import { TableList } from '../../../../components';
import useJobAssigmentAdmin, { JbAsAdminProvider } from './useJobAssigmentAdmin';

function Index(props) {
    const { jobAsAdAction, jobAsAdState } = useJobAssigmentAdmin();
    const history = useHistory();
    return (
        <div>
            <section className="content-header">
                <div className="container-fluid">
                    <div className='row'>
                        <div className='col-md-6'>
                            <h4>Job Assignment</h4>
                            <h6>List Trainee</h6>
                        </div>
                        <div className='col-md-6'>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-end">
                                    <li className="breadcrumb-item active" aria-current="page">List Trainee</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <TableList
                filter={true}
                withButtonSubmit={false}
                labelFilter=""
                formsFilter={[
                    {
                        type: "custom",
                        component: (
                            <>
                                <div className="form-group row mb-1">
                                    <label htmlFor="inputEmail3" className="col-sm-3 font-weight-normal">Batch</label>
                                    <div className="col-sm-9">
                                        <input type='number' className='form-control form-control-sm' placeholder='Batch'
                                            value={jobAsAdState.filter?.batch}
                                            onChange={(val) => jobAsAdAction.setFilter(e => ({ ...e, batch: val.target.value }))}
                                        >
                                        </input>
                                    </div>
                                </div>
                                <div className="form-group row mb-1">
                                    <label htmlFor="inputPassword3" className="col-sm-3 font-weight-normal">Name/NIK</label>
                                    <div className="col-sm-9">
                                        <input type='text' className='form-control form-control-sm'
                                            placeholder='Name/NIK'
                                            value={jobAsAdState.filter?.search}
                                            onChange={(val) => jobAsAdAction.setFilter(e => ({ ...e, search: val.target.value }))}
                                        >
                                        </input>
                                    </div>
                                </div>
                                <div className="form-group row mb-1">
                                    <div className='col-sm-3'></div>
                                    <div className='col-sm-9'>
                                        <button className='btn btn-sm btn-primary'
                                            onClick={() => jobAsAdAction.onFilter()}
                                        ><i className='fa fa-search'></i> Search</button>
                                    </div>
                                </div>
                            </>
                        )
                    }
                    // {
                    //     type: 'text',
                    //     placeholder: 'Name or NIK',
                    //     value: jobAsAdState.filter?.search,
                    //     onChange: (val) => jobAsAdAction.setFilter(e => ({ ...e, search: val }))
                    // }
                ]}
                resetFilter={false}
                onFilter={() => jobAsAdAction.onFilter()}
                submitButton={<><i className='fa fa-search'></i> Search</>}
                isLoading={jobAsAdState.loadingGet}
                minHeight="300px"
                maxHeight="500px"
                navtable={"TABLE 1"}

                onChangeShow={jobAsAdAction.changeShow}
                onNextPage={jobAsAdAction.nextPage}
                onPrevPage={jobAsAdAction.onPrevPage}
                defaultShow={jobAsAdState.pagination?.show}
                curPage={jobAsAdState.pagination?.page}
                maxPage={jobAsAdState.data?.totalPage || 0}
                orderData={{
                    order_by: jobAsAdState.pagination.order_by,
                    order_type: jobAsAdState.pagination.order_type
                }}
                onOrdering={jobAsAdAction.onOrdering}
                totalData={jobAsAdState.data?.totalData || 0}

                withActions={false}
                actionType={1}
                actionItems={[
                    {
                        name: 'Lihat detail',
                        onClick: function (item) {

                        }
                    },
                ]}
                columns={[
                    {
                        name: '#',
                        field: '#',
                        maxWidth: '50px',
                    },
                    {
                        name: "Nik Trainee",
                        field: 'trainee_nik',
                    },
                    {
                        name: "Name Trainee",
                        field: 'trainee_name',
                    },
                    {
                        name: "Aksi",
                        field: 'trainee_nik',
                        className: "text-right",
                        minWidth: '100px',
                        onRender: (item) => (
                            <button className='btn btn-sm btn-primary'
                                onClick={() => history.push({
                                    pathname: '/development/job-assignment/detail-trainee',
                                    state: {
                                        trainee_nik: item?.trainee_nik,
                                        trainee_name: item?.trainee_name
                                    }
                                })}
                            >Lihat</button>
                        )
                    }
                ]}
                data={[...(jobAsAdState.data?.listData || [])]}
            />
        </div>
    );
}

export default (props) => <JbAsAdminProvider><Index {...props} /></JbAsAdminProvider>;