import React from 'react';
import { TableList } from '../../../components';
import useDashboard from '../useDashboard';

function Index(props) {
    const { dashState: {
        loadingTrainee = false,
        dataTrainee = {},
        pagination = {},
        filter = {},
        role_id = null
    }, dashAction: {
        toListPage = () => null,
        changeShow = () => null,
        nextPage = () => null,
        prevPage = () => null,
        onFilter = () => null,
        setFilter = null

    } } = useDashboard();
    return (
        <TableList
            withButtonSubmit={role_id === 2 ? false : true}
            labelFilter={role_id === 2 ? "" : "Cari data berdasarkan :"}
            filter={true}
            formsFilter={role_id === 2 ? [
                {
                    type: 'custom',
                    placeholder: 'Nama',
                    component: (
                        <>
                            <div className="form-group row mb-1">
                                <label htmlFor="inputEmail3" className="col-sm-3 font-weight-normal">Batch</label>
                                <div className="col-sm-9">
                                    <input type='number' className='form-control form-control-sm' placeholder='Batch'
                                        value={filter?.batch}
                                        onChange={(val) => setFilter(e => ({ ...e, batch: val.target.value }))}
                                    >
                                    </input>
                                </div>
                            </div>
                            <div className="form-group row mb-1">
                                <label htmlFor="inputPassword3" className="col-sm-3 font-weight-normal">Name/NIK</label>
                                <div className="col-sm-9">
                                    <input type='text' className='form-control form-control-sm'
                                        placeholder='Name/NIK'
                                        value={filter?.search}
                                        onChange={(val) => setFilter(e => ({ ...e, search: val.target.value }))}
                                    >
                                    </input>
                                </div>
                            </div>
                            <div className="form-group row mb-1">
                                <div className='col-sm-3'></div>
                                <div className='col-sm-9'>
                                    <button className='btn btn-sm btn-primary'
                                        onClick={onFilter}
                                    ><i className='fa fa-search'></i> Search</button>
                                </div>
                            </div>
                        </>
                    )
                }
            ] : role_id === 5 ? [
                {
                    type: 'text',
                    placeholder: 'Name',
                    value: filter?.filter_name,
                    onChange: (val) => setFilter(e => ({ ...e, filter_name: val }))
                },
                {
                    type: 'text',
                    placeholder: 'NIK',
                    value: filter?.filter_nik,
                    onChange: (val) => setFilter(e => ({ ...e, filter_nik: val }))
                },
                {
                    type: 'custom',
                    component: (
                        <select className='form-select form-select-sm mb-3'
                            value={filter?.job_type}
                            onChange={(val) => setFilter(e => ({ ...e, job_type: val.target.value }))}
                        >
                            {/* <option value={''}> - </option> */}
                            <option value={'Operator'}> Operator </option>
                            <option value={'Mechanic'}> Mechanic </option>
                        </select>
                    )
                }
            ] : []}
            resetFilter={false}
            onFilter={onFilter}
            submitButton={<><i className='fa fa-search'></i> Search</>}

            minHeight="300px"
            maxHeight="500px"
            navtable={"TABLE 1"}
            isLoading={loadingTrainee}

            onChangeShow={changeShow}
            onNextPage={nextPage}
            onPrevPage={prevPage}
            defaultShow={pagination?.show}
            curPage={pagination?.page}
            maxPage={dataTrainee?.totalPage || 0}
            orderData={{
                order_by: pagination.order_by,
                order_type: pagination.order_type
            }}
            // onOrdering={onOrdering}
            totalData={dataTrainee?.totalData || 0}

            withActions={false}
            actionType={1}
            actionItems={[
                {
                    name: 'Lihat detail',
                    onClick: function (item) {
                        // history.push(`/idp-online/approval/detail/${item?.nik || ''}`, { ...item })
                    }
                }
            ]}
            columns={[...(role_id === 2 ? [{
                name: '#',
                field: '#',
                maxWidth: '50px',
            },
            {
                name: "NIK Trainee",
                field: 'trainee_nik',
            },
            {
                name: "Name Trainee",
                field: 'trainee_name',
            }] : role_id === 5 ? [{
                name: '#',
                field: '#',
                maxWidth: '50px',
            },
            {
                name: "NIK Trainee",
                field: 'nik',
            },
            {
                name: "Name Trainee",
                field: 'name',
            }] : []), ...[
                {
                    name: 'Aksi',
                    field: '',
                    maxWidth: '150px',
                    onRender: (item, i) => (
                        <button className='btn btn-sm btn-success'
                            onClick={() => toListPage({ nik: role_id === 2 ? item?.trainee_nik : item?.nik })}
                        >Lihat</button>
                    )
                },
            ]]}
            data={[...(dataTrainee?.listData || [])]}
        />
    );
}

export default Index;