import React from 'react';
import { Modal } from 'react-bootstrap';
import { TableList } from '../../../../../components';
import useResultA from '../../useResultA';

function ModalTrainee(props) {
    const { raAction: {
        closeModalTrainee = () => null,
        onFilterModalTrainee = () => null,
        setModalTrainee = () => null
    }, 
    raState: {
        modalTrainee: {
            show = false,
            dataFilter = [],
            search = ''
        }
    } } = useResultA();
    return (
        <div>
            <Modal
                dialogClassName='modal-mts-create'
                backdropClassName='bg-transparent'
                show={show}
                onHide={() => closeModalTrainee()}
            >
                <Modal.Header closeButton><h5>List Assessee</h5></Modal.Header>
                <Modal.Body>
                    <TableList
                        filter={true}
                        formsFilter={[
                            {
                                type: 'custom',
                                placeholder: 'Nama',
                                component: (
                                    <input type='text' className='form-control form-control-sm'
                                        placeholder='Name or NIK'
                                        value={search}
                                        onChange={(val) => setModalTrainee((x) => ({ ...x, search: val.target.value }))}
                                    >
                                    </input>
                                )
                            }
                        ]}
                        resetFilter={false}
                        onFilter={() => onFilterModalTrainee()}
                        curPage={1}
                        pagination={false}
                        minHeight="300px"
                        maxHeight="500px"
                        navtable={"TABLE 1"}
                        withCard={false}
                        withActions={false}
                        columns={[
                            {
                                name: '#',
                                field: '#',
                                maxWidth: '50px',
                            },
                            {
                                name: 'Assessee NIK',
                                field: 'trainee_nik',
                            },
                            {
                                name: 'Assessee Name',
                                field: 'trainee_name',
                            },
                        ]}
                        data={[...dataFilter]}
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default ModalTrainee;