import React from 'react';
import { Link } from 'react-router-dom';
import * as pages from './pages';
import useMenSts, { MenStsProvider } from './useMentSts';

function Index(props) {
    const { menStsAction: {
        toListPage = () => null,
        toListTraineePage = () => null,
    }, menStsState } = useMenSts();
    const PageRender = pages[menStsState.pageState.type];
    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <div className='row'>
                        <div className='col'>
                            <h4>Data Mentoring / Observation Status</h4>
                            <h6>{menStsState.pageState?.title}</h6>
                        </div>
                        <div className='col'>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-end">
                                    {
                                        menStsState.pageState.type === "ListCom" && (
                                            <>
                                                <li className="breadcrumb-item" aria-current="page"><Link onClick={toListTraineePage}>List Pengawas</Link></li>
                                                <li className="breadcrumb-item active" aria-current="page">{menStsState.pageState.title}</li>
                                            </>
                                        )
                                    }
                                    {
                                        menStsState.pageState?.type === "ListTrainee" && (
                                            <>
                                                <li className="breadcrumb-item active" aria-current="page">{menStsState.pageState.title}</li>
                                            </>
                                        )
                                    }
                                    {
                                        menStsState.pageState?.type === "FormPage" && (
                                            <>
                                                <li className="breadcrumb-item" aria-current="page"><Link onClick={() => toListTraineePage({ competency_id: menStsState.pageState?.dataPage?.competency_id })}>List Trainee</Link></li>
                                                <li className="breadcrumb-item" aria-current="page"><Link onClick={() => toListPage({ trainee_nik: menStsState.pageState?.dataPage?.trainee_nik })}>List Course</Link></li>
                                                <li className="breadcrumb-item active" aria-current="page">{menStsState.pageState.title}</li>
                                            </>
                                        )
                                    }
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <PageRender />
        </>
    );
}

export default (props) => <MenStsProvider><Index /></MenStsProvider>;