import React, { useMemo } from "react";
import useCompReport, { CompReportProvider} from "./useCompReport";
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Skeleton from "react-loading-skeleton";
import { DateInput } from "../../../components";

function InputCustom({
    title = "",
    val = "",
    disabled = true,
    isLoading = true,
    type = "text",
    dataOptions = [],
    onChange = () => { },
    onRender = () => null,
    required = true
}) {

    let newVal = useMemo(function () {
        let xval = onRender(val);
        if (!xval)
            xval = val;
        return xval;

    }, [val, onRender])

    const imageUrl = useMemo(() => {
        if (type === "file") {
            if (typeof newVal === "string") {
                return newVal
            } else {
                return URL.createObjectURL(newVal);
            }
        }
    }, [newVal])

    function getInput() {
        if (type === "select") return (
            <select required={required} onChange={onChange} value={newVal} className="form-select" disabled={disabled}>
                {
                    dataOptions.map((v, i) => <option key={i} value={v.key}>{v.label}</option>)
                }
            </select>
        )
        else if (type === "date") return (
            <DateInput
                required={required}
                className="form-control"
                disabled={disabled}
                value={newVal}
                onChange={onChange}
            />
        )
        else if (type === "file") {
            return (
                <div className="tw-w-[240px]">
                    <div className='d-block tw-h-[240px] tw-w-[240px] border border-secondary mb-3'>
                        {
                            imageUrl && (
                                <img className='mb-2 tw-w-full tw-h-full' src={imageUrl}></img>
                            )
                        }
                    </div>
                    {
                        !disabled && (
                            <input
                                id="inputfile"
                                accept="image/png, image/jpg, image/jpeg, image/png, image/bmp, image/gif, image/webp, image/image"
                                onChange={onChange}
                                type="file"
                                className="form-control"
                            />
                        )
                    }
                </div>
            )
        }
        else return (
            <input
                onChange={onChange}
                type={type}
                value={newVal}
                disabled={disabled}
                required={required}
                className="form-control disabled:tw-bg-white"
                placeholder={title}
            />
        )
    }
    return (
        <div className="tw-px-2">
            <label>{title}</label>
            {isLoading ? <div>
                {
                    type === "file" ? <Skeleton height={240} width={240} /> : <Skeleton height={30} width={"100%"} />
                }
            </div> : getInput()}
        </div>
    );
}

function FormMasterComp() {
    const {
        compReportState: {
            loadingList,
            tipe,
            formData,
            disabledForm,
            dataJobsite = []
        },
        compReportAction: {
            handleChangeFom,
            doUbah = () => { },
            doCreate = () => { }
        },
    } = useCompReport();

    const history = useHistory();
    return (
        <>
            <div className="row">
                <div className="col-6">
                    <section className="content-header">
                        <div className="container-fluid">
                            <h4>Master Data</h4>
                            <h6>Competency Report</h6>
                        </div>
                    </section>
                </div>
                <div className="col-6">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end">
                            <li className="breadcrumb-item" aria-current="page">
                                Master Data
                            </li>
                            <li className="breadcrumb-item" aria-current="page">
                                <Link to="/master-data/competency-report" >
                                    Competency Report
                                </Link>
                            </li>
                            <li
                                className="breadcrumb-item active"
                                aria-current="page"
                            >
                                <span className="tw-capitalize">{tipe}</span>
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <form onSubmit={(e) => {
                        e.preventDefault(e);
                        if (tipe === "add") {
                            doCreate();
                        }
                        if (tipe === "ubah") {
                            doUbah();
                        }
                    }}>
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title tw-capitalize">
                                    {tipe}
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="tw-px-5 tw-py-3 tw-rounded-md tw-border tw-border-solid tw-border-success tw-mb-5">
                                    <div className="tw-mb-6 tw-mx-3 tw-mt-3 tw-border-solid tw-border-t-0 tw-border-x-0 tw-border-b-2 tw-border-b-success">
                                        <h5 className="text-success"> Detail Report</h5>
                                    </div>
                                    <div className="tw-grid tw-grid-cols-1 tw-gap-2 lg:tw-grid-cols-3">
                                        {[
                                            {
                                                title: "Tahun IDP Register",
                                                field: "date",
                                                type: "date",
                                                disabled: disabledForm,
                                                onChange: (e) => handleChangeFom("date", e)
                                            },
                                            {
                                                title: "Competency Name",
                                                field: "training_name",
                                                disabled: disabledForm,
                                                onChange: (e) => handleChangeFom("training_name", e.target.value)
                                            },
                                            {
                                                title: "Course Name",
                                                field: "training_name",
                                                disabled: disabledForm,
                                                onChange: (e) => handleChangeFom("training_name", e.target.value)
                                            },
                                            {
                                                title: "Point",
                                                field: "training_name",
                                                disabled: disabledForm,
                                                onChange: (e) => handleChangeFom("training_name", e.target.value)
                                            },
                                            {
                                                title: "Tanggal Training",
                                                field: "date",
                                                type: "date",
                                                disabled: disabledForm,
                                                onChange: (e) => handleChangeFom("date", e)
                                            },
                                            {
                                                title: "Score Training",
                                                field: "score",
                                                disabled: disabledForm,
                                                type: "number",
                                                onChange: (e) => handleChangeFom("score", e.target.value)
                                            },
                                            {
                                                title: "Trainer",
                                                field: "trainer_name",
                                                disabled: disabledForm,
                                                onChange: (e) => handleChangeFom("trainer_name", e.target.value)
                                            },
                                        ].map(({ onChange = () => { }, ...val }, x) => {
                                            return (
                                                <div key={x}>
                                                    <InputCustom
                                                        isLoading={loadingList}
                                                        title={val.title}
                                                        val={formData?.[val.field]}
                                                        disabled={val.disabled}
                                                        onChange={onChange}
                                                        type={val.type}
                                                        dataOptions={val.dataOptions}
                                                    />
                                                </div>
                                            );
                                        })}
                                        {/* <div className="tw-col-span-1 sm:tw-col-span-3 tw-col-start-1 tw-ml-[5px]">
                                            <InputCustom
                                                isLoading={loadingList}
                                                title="Certificate"
                                                val={formData?.["file"]}
                                                disabled={disabledForm}
                                                onChange={(e) => handleChangeFom("file", e.target.files[0])}
                                                type={"file"}
                                                dataOptions={[]}
                                            />
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="tw-flex tw-justify-between">
                                    <button
                                        type="button"
                                        onClick={() =>
                                            history.push(
                                                "/master-data/competency-report"
                                            )
                                        }
                                        className="btn btn-outline-secondary"
                                    >
                                        Back
                                    </button>
                                    {(tipe === "add" || tipe === "ubah") && (
                                        <button type="submit" className="btn btn-primary"
                                        >
                                            {tipe === "add" ? "Tambah" : "Ubah"}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default () => (
    <CompReportProvider>
        <FormMasterComp />
    </CompReportProvider>
);