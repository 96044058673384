import React, { memo } from 'react';
import { Modal } from 'react-bootstrap';
import { LoadingIcon } from '..';
import useModalAlert from './useModalAlert';

export default memo(function () {
    const {modalState, modalAction} = useModalAlert()
    return (
        <>
            {/* mdoal loading */}
            <Modal show={modalState.loading.show}
                backdrop="static"
            >
                <Modal.Body>
                    <div style={{
                        margin: "auto",
                        width: "fit-content",
                        padding: "5% 0%",
                        textAlign: "center",

                    }}>
                        <LoadingIcon width="100px" />
                        <h5 className="text-base">{modalState.loading.msg}</h5>
                    </div>
                </Modal.Body>
            </Modal>
            {/*  */}

            {/* modal Alert */}
            <Modal show={modalState.dataAlert.show} onHide={modalState.closeAlert} 
                backdrop="static"
            >
                {
                    modalState.dataAlert.header !== null && modalState.dataAlert.header !== "" && (
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {modalState.dataAlert.header}
                            </Modal.Title>
                        </Modal.Header>
                    )
                }
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <h5 style={{ textAlign: "center", margin: "5px", textTransform: "capitalize" }}>{modalState.dataAlert.title}</h5>
                            <div className="box">
                                <p>
                                    {modalState.dataAlert.body}
                                </p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col-12">
                            <button onClick={() => {
                                modalAction.closeAlert();
                                if (modalState.dataAlert.callBack !== null && modalState.dataAlert.callBack !== undefined) modalState.dataAlert.callBack();
                            }
                            } className="btn btn-primary btn-sm">
                                Ok
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* end modal alert */}


            {/* modal confirm */}
            <Modal show={modalState.dataConfirm.show}
                 onHide={modalAction.closeConfirm}
                backdrop="static"
            >
                {
                    modalState.dataConfirm.header !== null && modalState.dataConfirm.header !== "" && (
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {modalState.dataConfirm.header}
                            </Modal.Title>
                        </Modal.Header>
                    )
                }
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            {/* <h4 style={{ textAlign: "center" }}>{modalState.dataConfirm.title}</h4> */}

                            {/* <div className="box"> */}
                            <div className="box">
                                <p>
                                {modalState.dataConfirm.body}
                                </p>
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row">
                        <div className="col-12">
                            {
                                modalState
                                    .dataConfirm
                                    .closeButton && <button onClick={modalAction.closeConfirm} className="btn btn-secondary btn-sm float-right mr-2">
                                    Close
                                </button>
                            }
                            {
                                modalState
                                    .dataConfirm
                                    .footerButton?.map(
                                        ({
                                            className = "",
                                            onClick = () => null,
                                            style = {},
                                            name = "Ok" },
                                            i) => <button
                                                key={i}
                                                className={`${className} float-right mr-2`}
                                                onClick={() =>{
                                                    modalAction.closeAlert()
                                                    onClick()
                                                }}
                                                style={style}
                                            >
                                                {name}
                                            </button>
                                    )
                            }
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* end modal confirm */}
        </>
    )
});

export {default as useModalAlert, ModalAlertProvider} from './useModalAlert'