import useMatrixComp, { MatrixCompProvider } from "./useMatrixComp";
import { InputCustom } from './form';
import { Link, useHistory } from 'react-router-dom';

function FormCourse() {
    const history = useHistory();
    const {
        matrixCompState: {
            loadingList,
            ctipe,
            formDataCourse,
            dataType,
            dataDetail,
        },
        matrixCompAction: {
            handleChangeFomCourse,
            doActCourse = (act) => {},
        },
    } = useMatrixComp();

    return (<>
        <div className="row">
            <div className="col-6">
                <section className="content-header">
                    <div className="container-fluid">
                        <h4>Master Data</h4>
                        <h6>Course Matriks Kompetensi</h6>
                    </div>
                </section>
            </div>
            <div className="col-6">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb justify-content-end">
                        <li className="breadcrumb-item" aria-current="page">
                            Master Data
                        </li>
                        <li className="breadcrumb-item" aria-current="page">
                            <Link to={`/master-data/matrix-competency/detail?id=${dataDetail?.id}`} >
                                Course Matriks Kompetensi
                            </Link>
                        </li>
                        <li
                            className="breadcrumb-item active"
                            aria-current="page"
                        >
                            <span className="tw-capitalize">{ctipe}</span>
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <form onSubmit={(e) => {
                    e.preventDefault(e);
                    doActCourse();
                }}>
                    <div className="card">
                        <div className="card-body">
                            <div className="tw-px-5 tw-py-3 tw-rounded-md tw-border tw-border-solid tw-border-success tw-mb-2">
                                <div className="tw-mb-6 tw-mx-3 tw-mt-3 tw-border-solid tw-border-t-0 tw-border-x-0 tw-border-b-2 tw-border-b-success">
                                    <h5 className="text-success"> Detail Course Matrik Kompetensi</h5>
                                </div>
                                <div className="tw-grid tw-grid-cols-1 tw-gap-2 lg:tw-grid-cols-2">
                                    {[
                                        {
                                            title: "Type",
                                            field: "type",
                                            disabled: true,
                                            type: "select",
                                            // value: dataDetail?.type,
                                            dataOptions: [{ key: "", label: "- Pilih -" }, ...(dataType.map(d => ({ key: d, label: d })))],
                                            onChange: (e) => handleChangeFom("type", e.target.value)
                                        },
                                        {
                                            title: "Competency Name",
                                            field: "competency_name",
                                            disabled: true,
                                            // value: dataDetail?.name,
                                            onChange: (e) => handleChangeFomCourse("competency_name", e.target.value)
                                        },
                                        {
                                            title: "Course Name",
                                            field: "name",
                                            disabled: ctipe === "detail" ? true : false,
                                            onChange: (e) => handleChangeFomCourse("name", e.target.value)
                                        },
                                    ].map(({ onChange = () => { }, ...val }, x) => {
                                        return (
                                            <div key={"add-course-"+x}>
                                                <InputCustom
                                                    isLoading={loadingList}
                                                    title={val.title}
                                                    val={formDataCourse?.[val.field]}
                                                    disabled={val.disabled}
                                                    onChange={onChange}
                                                    type={val.type}
                                                    dataOptions={val.dataOptions}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="tw-flex tw-justify-between">
                                <button
                                    type="button"
                                    onClick={() => history.push(`/master-data/matrix-competency/detail?id=${dataDetail?.id}`)}
                                    className="btn btn-outline-secondary"
                                >
                                    Back
                                </button>
                                {(ctipe === "add" || ctipe === "ubah") && (
                                    <button type="submit" className="btn btn-primary"
                                    >
                                        {ctipe === "add" ? "Tambah" : "Ubah"}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>)
}

export default () => (
    <MatrixCompProvider>
        <FormCourse />
    </MatrixCompProvider>
);