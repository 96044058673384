import React, { useState } from 'react';
import DatePicker from 'react-datepicker'
import moment from "moment";
const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];


const DateInput = ({
    value = "",
    className = "",
    popperClassName = "",
    onChange = () => null,
    disabled = false,
    readOnly = false,
    format = "dd/MM/yyyy",
    showTimeSelect,
    required = false,
    minDate = null,
    maxDate = null
}) => {

    function validDate(d) {
        var timestamp = Date.parse(d)

        return !isNaN(timestamp)
    }
    const [yearMode, setYearMode] = useState(false);
    const [monthMode, setMonthMode] = useState(false)
    // const years = range(1990, getYear(new Date()) + 1, 1);

    // console.log(validDate(value), value)
    return (
        <DatePicker
            showTimeSelect={showTimeSelect}
            className={className}
            popperClassName={popperClassName}
            required={required}
            selected={
                (value !== undefined && value !== null
                    && validDate(value)
                ) && value ? new Date(value) : ""
            }
            minDate={minDate}
            maxDate={maxDate}
            readOnly={readOnly}
            type="date"
            dateFormat={format}
            onSelect={() => {
                if (yearMode) {
                    setYearMode(false)
                    setMonthMode(true)
                } else {
                    setMonthMode(false)
                    setYearMode(false)
                }
            }}
            disabledKeyboardNavigation
            onCalendarClose={()=>{
                setMonthMode(false)
                setYearMode(false)
            }}
            
            // onYearChange={()=> setYearMode(false)}
            showYearPicker={yearMode}
            showMonthYearPicker={monthMode}
            showMonthDropdown={true}
            {
            ... (
                !yearMode && !monthMode ? {
                    renderCustomHeader: ({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                    }) => (
                        <>

                            <div style={{ display: "inline-block", width: "100%" }}>
                                <button style={{top:13}} type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                    className="react-datepicker__navigation react-datepicker__navigation--previous"
                                    aria-label="Previous Month"><span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">Previous Month</span></button>

                                <button className='btn btn-sm btn-default' onClick={() => setYearMode(true)} >{months[date.getMonth()]}  {date.getFullYear()}</button>
                                <button type="button" style={{top:13}}
                                    onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                                    className="react-datepicker__navigation react-datepicker__navigation--next"
                                    aria-label="Next Month"><span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next"

                                    >Next Month</span></button>
                            </div>
                        </>
                    )

                } : {}
            )
            }
            onKeyDown={e => {

                let val = e.target.value.replace(/[^0-9 \-]/g, '')
                if (e.keyCode !== 8 && e.keyCode !== 46) {
                    val = e.target.value.replace(/[^0-9 / \-]/g, '')

                    if (val.length <= 10) {
                        if (val.length === 2 || val.length === 5) {
                            val = val + "/"
                            // console.log(val)
                            // console.log(val.length)
                            e.target.value = val

                        }
                    }
                }
            }}

            // readOnly
            value={
                (value !== undefined && value !== null
                    && validDate(value)
                ) && value ? new Date(value) : ""
            }

            // onKeyUp={() =>null}
            onChange={(val, e) => {
                //validDate(e) ? 
                // setYearMode(false)
                onChange?.(moment(val).format("YYYY-MM-DD")) // : onChange?.("")
            }}
            disabled={disabled}
            placeholderText={"DD/MM/YYYY"}
            timeFormat="p"
            shouldCloseOnSelect={!yearMode && !monthMode}
        // onBlur={
        //     (e) => {
        //         if (onFocusOut !== null && onFocusOut !== undefined) {
        //             onFocusOut?.(e.target.value)
        //         }
        //     }
        // }
        />
    );
}


export default DateInput;