import moment from 'moment';
import React from 'react';
import { BiFile } from 'react-icons/bi';
import { TableList } from '../../../components';
import useCrtSch from './useCrtSch';

function ListTraining(props) {
    const { crtSchAction, crtSchState } = useCrtSch();
    return (
        <div className='card'>
            <div className='card-body'>
                <TableList
                    filter={true}
                    labelFilter="Tahun IDP Register :"
                    formsFilter={[
                        {
                            type: 'custom',
                            component: (
                                <input type='number' className='form-control form-control-sm'
                                    placeholder='Batch'
                                    min={0}
                                    value={crtSchState?.batch}
                                    onChange={(val) => crtSchAction.setBatch(val.target.value)}
                                >
                                </input>
                            )
                        }
                    ]}
                    customToolbar={<div className='text-right mb-3'>
                        {/* <button className='btn btn-sm btn-success'
                            onClick={() => htmlToExcel("table-to-xls", `${crtSchState?.batch}_MTS_${moment(Date.now()).format("YYYY-MM-DD HH_mm_SS")}`, "statement")}
                        >
                            <BiFile style={{ fonstSize: '12pt' }} /> Export Excel
                        </button> */}
                    </div>}
                    resetFilter={false}
                    onFilter={() => crtSchAction.onFilter()}
                    submitButton={<><i className='fa fa-search'></i> Search</>}
                    curPage={1}
                    minHeight="300px"
                    maxHeight="500px"
                    navtable={"TABLE 1"}
                    withCard={false}
                    isLoading={crtSchState.loadingTraining}
                    // onChangeShow={rekAction.changeShow}
                    // onNextPage={rekAction.nextPage}
                    // onPrevPage={rekAction.prevPage}
                    withActions={false}
                    columns={[
                        {
                            name: '#',
                            field: '#',
                            maxWidth: '50px',
                        },
                        {
                            name: 'Competency Name',
                            field: 'competency_name',
                        },
                        {
                            name: 'Training Name',
                            field: 'course_name',
                        },
                        {
                            name: 'Assessor',
                            field: 'trainer_nik',
                            onRender: (item) => (
                                <span className='text-wrap'>{item?.trainer_nik} - {item?.trainer_name}</span>
                            )
                        },
                        {
                            name: 'Assessee',
                            field: 'trainees',
                            maxWidth: '300px',
                            onRender: (item) => {
                                return (
                                    <div>
                                        {
                                            [...(item?.trainees || [])].slice(0, 5).map((d, i) => (
                                                <span className='badge badge-info mr-1'>
                                                    {
                                                        d?.trainee_name
                                                    }
                                                </span>
                                            ))
                                        }
                                        {
                                            [...(item?.trainees || [])].length > 5 && (
                                                <div className='d-block'><span type='button' className="badge badge-warning"
                                                    onClick={() => crtSchAction.openModalTrainee([...(item?.trainees || [])])}
                                                >See All</span></div>
                                            )
                                        }
                                    </div>
                                )
                            }
                        },
                        {
                            name: 'Start',
                            field: 'start_date_mts',
                            onRender: (item) => (
                                <span className='text-wrap'>{item?.start_date ? moment(item?.start_date).format('DD/MM/YYYY') : 'Belum ada jadwal'}</span>
                            )
                        },
                        {
                            name: 'Finish',
                            field: 'end_date_mts',
                            onRender: (item) => (
                                <span className='text-wrap'>{item?.end_date ? moment(item?.end_date).format('DD/MM/YYYY') : 'Belum ada jadwal'}</span>
                            )
                        },
                        {
                            name: 'Action',
                            field: '',
                            maxWidth: '100px',
                            minWidth: '100px',
                            onRender: (item) => (
                                <div>
                                    {
                                        item?.start_date ? (
                                            <button className='btn btn-sm btn-success'
                                                onClick={() => crtSchAction.openModalCreate({ ...item }, true)}
                                            >Update Jadwal</button>
                                        ) : (
                                            <button className='btn btn-sm btn-primary'
                                                onClick={() => crtSchAction.openModalCreate({ ...item }, false)}
                                            >Buat Jadwal</button>
                                        )
                                    }

                                </div>
                            )
                        },
                    ]}
                    data={[...(crtSchState.listTraining || [])]}
                />

                {/* <button className='btn btn-sm btn-secondary'
                    onClick={() => history.goBack()}
                >Back</button> */}
            </div>
        </div>
    );
}

export default ListTraining;