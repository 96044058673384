import React from 'react';
import { StatusIcon, TableList } from '../../../components';
import useIdpStatus, { IdpStatusProvider } from './useIdpStatus';
import './idp-status.css';
import { Modal } from 'react-bootstrap';
import IdpStatusDetail from './idp-status-detail';

function Index(props) {
    const { idpStatAction, idpStatState } = useIdpStatus();
    return (
        <div>
            <div className='row'>
                <div className='col-6'>
                    <section className="content-header">
                        <div className="container-fluid">
                            <h4>IDP Online</h4>
                            <h6>IDP Status</h6>
                        </div>
                    </section>
                </div>
                <div className='col-6'>

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end">
                            <li className="breadcrumb-item active" aria-current="page">
                                IDP Status
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <TableList
                filter={true}
                formsFilter={[
                    {
                        type: 'custom',
                        placeholder: 'Nama',
                        component: (
                            <input type='number' className='form-control form-control-sm'
                                placeholder='Batch'
                                value={idpStatState?.batch}
                                onChange={(val) => idpStatAction.setBatch(val.target.value)}
                            >
                            </input>
                        )
                    }
                ]}
                labelFilter="Tahun IDP Register :"
                submitButton={<><i className='fa fa-search'></i> Search</>}
                resetFilter={false}
                onFilter={() => idpStatAction.onFilter()}
                isLoading={idpStatState.loadingGet}
                curPage={1}
                minHeight="300px"
                maxHeight="500px"
                navtable={"TABLE 1"}
                // onChangeShow={rekAction.changeShow}
                // onNextPage={rekAction.nextPage}
                // onPrevPage={rekAction.prevPage}
                withActions={true}
                actionType={1}
                actionItems={[
                    {
                        name: 'Lihat detail',
                        onClick: function (item) {
                            idpStatAction.setModalDetail({
                                show: true,
                                data: { ...item }
                            });
                        }
                    }
                ]}
                columns={[
                    {
                        name: "#",
                        field: '#'
                    },
                    {
                        name: "Grade",
                        field: 'grade'
                    },
                    {
                        name: "Cluster",
                        field: 'cluster'
                    },
                    {
                        name: "Group Competency",
                        field: 'competency_group'
                    },
                    {
                        name: "Competency Name",
                        field: 'competency_name'
                    },
                    {
                        name: "Point",
                        field: 'point'
                    },
                    {
                        name: "Course Name",
                        field: 'course_name'
                    },
                    {
                        name: "Catatan",
                        field: 'note'
                    },
                    {
                        name: 'Status',
                        field: 'status',
                        // maxWidth: '150px',
                        onRender: ({ status }) => {
                            return <StatusIcon status={status}/> //<span className={
                            //     `text-capitalize badge badge-pill badge-${status === 'draft' ? 'warning' :
                            //         status === 'submitted' || status === 'submit' ? 'success' :
                            //             status === 'approved' ? 'primary' :
                            //                 'danger'
                            //     }`
                            // }>
                            //     {status}
                            // </span>
                        }
                    }
                    // ,{
                    //     name: "Status Assessment",
                    //     field: 'status_assessment'
                    // },
                ]}
                data={idpStatState.dataList || []}
            />
            <Modal
                dialogClassName='cs-modal-status'
                show={idpStatState.modalDetail?.show} onHide={() => idpStatAction.setModalDetail({
                    show: false,
                    data: {}
                })}
            >
                <Modal.Header closeButton>
                    <Modal.Title>IDP Status Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <IdpStatusDetail data={idpStatState.modalDetail?.data} />
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default (props) => <IdpStatusProvider><Index {...props} /></IdpStatusProvider>;