import React, { createContext, useContext, useEffect, useState } from "react";
import { useModalAlert } from "../../../components/modalAlert";
import useFetch from "../../../hooks/useFetch";
import ModalDetail from "./modal-detail";

const assStatContext = createContext({
    assState: {},
    assAction: {}
});

export function AssStatProvider({ children }) {
    const [modalDetail, setModalDetail] = useState({
        show: false,
        data: {}
    });
    const [loadingGet, setLoadingGet] = useState(false)
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const { doGet, doPost } = useFetch();
    const [dataList, setDataList] = useState([])
    const [batch, setBatch] = useState();
    async function getDataList() {
        // openLoading("Get competency")
        setLoadingGet(true)
        try {
            const { status, statusText, payload } = await doGet({
                url: '/api/assessment/training-status',
                param:{
                    batch : batch || ''
                }
            });
            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data: { dataIDP = [], batch }, code, msg } = payload;
                if (code === "00") {
                    setDataList(dataIDP || []);
                    setBatch(batch);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get IDP Status",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get IDP Status",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get detail register",
                callBack: () => null
            })
        }
        setLoadingGet(false)
    }

    const closeModalDetail = () => {
        setModalDetail({
            show: false,
            data: {}
        })
    }
    const onFilter = () => {
        getDataList();
    }
    useEffect(() => {
        getDataList();
    }, [])
    return <assStatContext.Provider
        value={{
            assState: {
                modalDetail,
                dataList,
                loadingGet,
                batch                
            },
            assAction: {
                closeModalDetail,
                setModalDetail,
                setBatch,
                onFilter
            }
        }}
    >
        {children}
        <ModalDetail />
    </assStatContext.Provider>
}
export default function useAssStatus() {
    return useContext(assStatContext);
}