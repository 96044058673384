import moment from 'moment';
import React from 'react';
import { Modal } from 'react-bootstrap';
import useAssStatus from '../useAssStatus';
import { StatusIcon } from '../../../../components';

function ModalDetail(props) {
    const { assAction: {
        closeModalDetail = () => null
    }, assState: {
        modalDetail: {
            show = false,
            data = {}
        }
    } } = useAssStatus();
    return (
        <div>
            <Modal
                dialogClassName='modal-editor-trainee'
                backdropClassName='bg-transparent'
                show={show}
                onHide={() => closeModalDetail()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Detail
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col'>
                            <form>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        {/* <div className="form-group row">
                                            <label className="col-md-6 col-form-label">Cluster</label>
                                            <div className="col-md-6">
                                                <span type="text" readonly className="form-control-plaintext">{data?.cluster}</span>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-md-6 col-form-label">Grade</label>
                                            <div className="col-md-6">
                                                <span type="text" readonly className="form-control-plaintext">{data?.grade}</span>
                                            </div>
                                        </div> */}
                                        {/* <div className="form-group row">
                                            <label className="col-md-6 col-form-label">Competency Group</label>
                                            <div className="col-md-6">
                                                <span type="text" readonly className="form-control-plaintext">{data?.competency_group}</span>
                                            </div>
                                        </div> */}
                                        <div className="form-group row">
                                            <label className="col-md-6 col-form-label">Competency Name</label>
                                            <div className="col-md-6 d-flex">
                                                <span className='d-none d-md-inline mr-0 mr-md-1 font-weight-bold pt-cs'>:</span>
                                                <span type="text" readonly className="form-control-plaintext">{data?.competency_name}</span>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-md-6 col-form-label">Course Name</label>
                                            <div className="col-md-6 d-flex">
                                                <span className='d-none d-md-inline mr-0 mr-md-1 font-weight-bold pt-cs'>:</span>
                                                <span type="text" readonly className="form-control-plaintext">{data?.course_name}</span>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-md-6 col-form-label">Point</label>
                                            <div className="col-md-6 d-flex">
                                                <span className='d-none d-md-inline mr-0 mr-md-1 font-weight-bold pt-cs'>:</span>
                                                <span type="text" readonly className="form-control-plaintext">{data?.point}</span>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-md-6 col-form-label">Tanggal Training </label>
                                            <div className="col-md-6 d-flex">
                                                <span className='d-none d-md-inline mr-0 mr-md-1 font-weight-bold pt-cs'>:</span>
                                                <span type="text" readonly className="form-control-plaintext">{
                                                    data?.start_date_training ? moment(data?.start_date_training).format('DD/MM/YYYY') : "-"
                                                } - {
                                                        data?.end_date_training ? moment(data?.end_date_training).format('DD/MM/YYYY') : "-"
                                                    }</span>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-md-6 col-form-label">Score Training</label>
                                            <div className="col-md-6 d-flex">
                                                <span className='d-none d-md-inline mr-0 mr-md-1 font-weight-bold pt-cs'>:</span>
                                                <span type="text" readonly className="form-control-plaintext">{data?.post_test_score}</span>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-md-6 col-form-label">Presensi Training</label>
                                            <div className="col-md-6 d-flex">
                                                <span className='d-none d-md-inline mr-0 mr-md-1 font-weight-bold pt-cs'>:</span>
                                                <span type="text" readonly className="form-control-plaintext">{data?.percent_day_present * 100}%</span>
                                            </div>
                                        </div>


                                    </div>
                                    <div className='col-md-6'>
                                        <div className="form-group row">
                                            <label className="col-sm-6 col-form-label">Jumlah Job Assignment</label>
                                            <div className="col-sm-6 d-flex">
                                                <span className='d-none d-md-inline mr-0 mr-md-1 font-weight-bold pt-cs'>:</span>
                                                <span type="text" readonly className="form-control-plaintext">{data?.total_ja}</span>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-6 col-form-label">Jumlah TIR (Approve)</label>
                                            <div className="col-sm-6 d-flex">
                                                <span className='d-none d-md-inline mr-0 mr-md-1 font-weight-bold pt-cs'>:</span>
                                                <span type="text" readonly className="form-control-plaintext">{data?.total_approved_tir}</span>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-6 col-form-label">Tanggal Assessment</label>
                                            <div className="col-sm-6 d-flex">
                                                <span className='d-none d-md-inline mr-0 mr-md-1 font-weight-bold pt-cs'>:</span>
                                                <span type="text" readonly className="form-control-plaintext">{
                                                    data?.start_date ? moment(data?.start_date).format('DD/MM/YYYY') : "-"
                                                } - {
                                                        data?.end_date ? moment(data?.end_date).format('DD/MM/YYYY') : "-"
                                                    }</span>
                                            </div>
                                        </div>
                                        {/* <div className="form-group row">
                                            <label className="col-md-6 col-form-label">Request By</label>
                                            <div className="col-md-6 d-flex">
                                                <span className='d-none d-md-inline mr-0 mr-md-1 font-weight-bold pt-cs'>:</span>
                                                <span type="text" readonly className="form-control-plaintext">{data?.request_by}</span>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-md-6 col-form-label">Request Date</label>
                                            <div className="col-md-6 d-flex">
                                                <span className='d-none d-md-inline mr-0 mr-md-1 font-weight-bold pt-cs'>:</span>
                                                <span type="text" readonly className="form-control-plaintext">{data?.request_date}</span>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-md-6 col-form-label">Approval By</label>
                                            <div className="col-md-6 d-flex">
                                                <span className='d-none d-md-inline mr-0 mr-md-1 font-weight-bold pt-cs'>:</span>
                                                <span type="text" readonly className="form-control-plaintext">{data?.approval_by}</span>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-md-6 col-form-label">Approval Date</label>
                                            <div className="col-md-6 d-flex">
                                                <span className='d-none d-md-inline mr-0 mr-md-1 font-weight-bold pt-cs'>:</span>
                                                <span type="text" readonly className="form-control-plaintext">{data?.approval_date}</span>
                                            </div>
                                        </div> */}
                                        {/* <div className="form-group row">
                                            <label className="col-md-6 col-form-label">Point</label>
                                            <div className="col-md-6 d-flex">
                                                <span className='d-none d-md-inline mr-0 mr-md-1 font-weight-bold pt-cs'>:</span>
                                                <span type="text" readonly className="form-control-plaintext">{data?.point}</span>
                                            </div>
                                        </div> */}
                                        <div className="form-group row">
                                            <label className="col-md-6 col-form-label">Assessor</label>
                                            <div className="col-md-6 d-flex">
                                                <span className='d-none d-md-inline mr-0 mr-md-1 font-weight-bold pt-cs'>:</span>
                                                <span type="text" readonly className="form-control-plaintext">{data?.assessor_nik} - {data?.assessor_name}</span>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-md-6 col-form-label">Result Assessment</label>
                                            <div className="col-md-6 d-flex">
                                                <span className='d-none d-md-inline mr-0 mr-md-1 font-weight-bold pt-cs'>:</span>
                                                <span type="text" readonly className="form-control-plaintext"><span className={
                                                    `text-capitalize badge badge-pill badge-${data?.score === 'competent' ? 'success' :
                                                        data?.score === 'not_competent' ? 'danger' :
                                                            'secondary'
                                                    }`
                                                }>
                                                    {data?.score === "not_competent" ? "not competent" : data?.score}
                                                </span></span>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-md-6 col-form-label">Attended Ratio</label>
                                            <div className="col-md-6 d-flex">
                                                <span className='d-none d-md-inline mr-0 mr-md-1 font-weight-bold pt-cs'>:</span>
                                                <span type="text" readonly className="form-control-plaintext">{(data?.attended_ratio * 100).toFixed(2)} %</span>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-md-6 col-form-label">Status Assessment</label>
                                            <div className="col-md-6 d-flex">
                                                <span className='d-none d-md-inline mr-0 mr-md-1 font-weight-bold pt-cs'>:</span>
                                                <span type="text" readonly className="form-control-plaintext">
                                                    <StatusIcon status={data?.assessment_status}/>
                                                    {/* <span className={
                                                        `text-capitalize badge badge-pill
                                                        badge-${data?.assessment_status === "Passed" ? 'success' :
                                                            (data?.assessment_status === "Failed") ? 'danger' :
                                                                (data?.assessment_status === "Not Available") ? 'warning' :
                                                                    'info'}`
                                                    }>
                                                        {data?.assessment_status}
                                                    </span> */}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-md-6 col-form-label">Passed Date</label>
                                            <div className="col-md-6 d-flex">
                                                <span className='d-none d-md-inline mr-0 mr-md-1 font-weight-bold pt-cs'>:</span>
                                                <span type="text" readonly className="form-control-plaintext">{data?.passed_date}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default ModalDetail;
