import React from 'react';
import { TableList } from '../../../../components';
import useMentoringAdmin from '../useMentoringAdmin';

function Index(props) {
    const { menAdminState: {
        loadingTraining = false,
        dataTraining = {}
    },
        menAdminAction: {
            toTraineePage = () => null,
            toFormPage = () => null,
        } } = useMentoringAdmin();
    return (
        <div className='card'>
            <div className='card-body'>
                <div className='row mb-3'>
                    <div className='col-md-12 col-sm-12'>
                        <TableList
                            isLoading={loadingTraining}
                            data={[...(dataTraining?.listData || [])]}
                            withCard={false}
                            columns={[
                                {
                                    name: '#',
                                    field: '#',
                                },
                                {
                                    name: 'Course Name',
                                    field: 'course_name',
                                },
                                {
                                    name: 'Form Name',
                                    field: 'form_name',
                                },
                                {
                                    name: 'Aksi',
                                    field: '',
                                    minWidth: 200,
                                    maxWidt: 200,
                                    onRender: (item) => (
                                        <div>
                                            <button
                                                onClick={() => toFormPage({ ...item })}
                                                className='btn btn-sm btn-success'>
                                                Lihat Detail
                                            </button>
                                        </div>
                                    )
                                },
                            ]}
                            withActions={false}
                            pagination={false}
                        />
                        <button className='btn btn-sm btn-secondary mt-3'
                            onClick={toTraineePage}>Back</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;