import React from 'react';
import Layouts from '..';
import { useAuth } from '../../context';

const AutoSwitchLayout = ({children}) => {

    const { authenticated, initialized } = useAuth();
    if(authenticated)
        return <Layouts>
            {children}
        </Layouts>
    return children
}
 
export default AutoSwitchLayout;