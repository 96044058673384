import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
// import { baseUrl } from '../../../../config';
// import { Button, Modal } from 'react-bootstrap';
// import Layouts from "../../../layouts";
import { StatusIcon, TableList } from '../../../components';
import { useAuth } from '../../../context';
import useDashboard from '../useDashboard';

function Index(props) {
    const { userInfo } = useAuth();
    const { dashAction, dashState } = useDashboard();
    const history = useHistory();

    const {
        pageState: {
            dataPage = {}
        }
    } = dashState
    return (
        <div>
            <div className="card">
                <div className="card-body p-2">
                    <div className='row justify-content-between'>
                        <div className='col-md-6'>
                            <div className='row'>
                                <span className='col-md-4 font-weight-bold'>NAMA<span className='d-none d-md-inline d-lg-inline float-right'>:</span></span>
                                <span className='col-md-8 font-weight-bold'>{dashState.dataHeader?.name}</span>
                            </div>
                            <div className='row'>
                                <span className='col-md-4 font-weight-bold'>NIK<span className='d-none d-md-inline d-lg-inline float-right'>:</span></span>
                                <span className='col-md-8 font-weight-bold'>{dashState.dataHeader?.nik}</span>
                            </div>
                            <div className='row'>
                                <span className='col-md-4 font-weight-bold'>JABATAN<span className='d-none d-md-inline d-lg-inline float-right'>:</span></span>
                                <span className='col-md-8 font-weight-bold'>{dashState.dataHeader?.job_title}</span>
                            </div>
                            {/* <div className='font-weight-bold'><label className='style-txt-1'>NAMA</label>: {userInfo.name}</div>
                            <div className='font-weight-bold'><label className='style-txt-1'>NIK</label>: {userInfo?.nik}</div>
                            <div className='font-weight-bold'><label className='style-txt-1'>JABATAN</label>: {userInfo?.job_title}</div> */}
                        </div>
                        <div className='col-md-3'>
                            <div className='row'>
                                <span className='col-md-6 font-weight-bold'>Current Grade<span className='d-none d-md-inline d-lg-inline float-right'>:</span></span>
                                <span className='col-md-6 font-weight-bold'>{dashState.dataHeader?.grade}</span>
                            </div>
                            <div className='row'>
                                <span className='col-md-6 font-weight-bold'>Current Point<span className='d-none d-md-inline d-lg-inline float-right'>:</span></span>
                                <span className='col-md-6 font-weight-bold'>{dashState.dataHeader?.current_point}</span>
                            </div>
                            {/* <div className='font-weight-bold'><label className='style-txt-2'>CURRENT GRADE</label>: {userInfo?.grade}</div>
                            <div className='font-weight-bold'><label className='style-txt-2'>CURRENT POINT</label>: </div> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <TableList
                curPage={1}
                minHeight="450px"
                maxHeight="500px"
                navtable={"TABLE 1"}
                // onChangeShow={rekAction.changeShow}
                // onNextPage={rekAction.nextPage}
                // onPrevPage={rekAction.prevPage}
                withActions={false}
                actionItems={[]}
                columns={[
                    {
                        name: '#',
                        field: '#',
                        maxWidth: '50px',
                    },
                    {
                        name: 'Nama Kompetensi',
                        field: 'nomor_kompetensi1',
                        maxWidth: '250px',
                    },
                    {
                        name: 'Status',
                        field: 'status1',
                        maxWidth: '150px',
                    },
                    {
                        name: 'Nama Kompetensi',
                        field: 'nomor_kompetensi2',
                        maxWidth: '250px',
                    },
                    {
                        name: 'Status',
                        field: 'status2',
                        maxWidth: '150px',
                    },
                ]}
                data={[]}
            /> */}
            <div className='card'>
                <div className='card-body'>
                    <TableList
                        filter={true}
                        labelFilter="Tahun IDP Register :"
                        formsFilter={[
                            {
                                type: 'custom',
                                component: (
                                    <input type='number' className='form-control form-control-sm'
                                        placeholder='Batch'
                                        min={0}
                                        value={dashState?.batch}
                                        onChange={(val) => dashAction.setBatch(val.target.value)}
                                    >
                                    </input>
                                )
                            }
                        ]}
                        submitButton={<><i className='fa fa-search'></i> Search</>}
                        onFilter={() => dashAction.onFilterHistory()}
                        resetFilter={false}
                        pagination={false}
                        withCard={false}
                        curPage={1}
                        minHeight="450px"
                        maxHeight="500px"
                        navtable={"TABLE 1"}
                        isLoading={dashState.loadingHist}
                        // onChangeShow={rekAction.changeShow}
                        // onNextPage={rekAction.nextPage}
                        // onPrevPage={rekAction.prevPage}
                        withActions={true}
                        actionType={1}
                        actionItems={[
                            {
                                name: 'Lihat detail',
                                onClick: function (item) {
                                    dashAction.setModalDetail({
                                        show: true,
                                        data: { ...item }
                                    });
                                }
                            }
                        ]}
                        columns={[
                            {
                                name: '#',
                                field: '#',
                                maxWidth: '50px',
                            },
                            {
                                name: 'Competency name',
                                field: 'competency_name',
                                maxWidth: '250px',
                            },
                            {
                                name: 'Point',
                                field: 'point',
                                maxWidth: '150px',
                            },
                            {
                                name: 'Course Name',
                                field: 'course_name',
                                maxWidth: '250px',
                            },
                            {
                                name: 'Post Test',
                                field: 'post_test_score',
                                maxWidth: '150px',
                            },
                            {
                                name: 'Status Training',
                                field: 'training_status',
                                maxWidth: '150px',
                                onRender: ({ training_status }) => (
                                    <StatusIcon status={training_status} />
                                    // <span className={
                                    //     `text-capitalize badge badge-pill badge-${training_status === 'passed' ? 'success' :
                                    //         training_status === 'in progress' ? 'primary' : training_status === 'failed' ?
                                    //             'danger' : ''
                                    //     }`
                                    // }>
                                    //     {training_status}
                                    // </span>
                                )
                            },
                            {
                                name: 'Assesment Result',
                                field: 'assessment_result',
                                maxWidth: '150px',
                            },
                            {
                                name: 'Status Assesment',
                                field: 'assessment_status',
                                maxWidth: '150px',
                                onRender: ({ assessment_status }) => (
                                    <StatusIcon status={assessment_status} />
                                    // <span className={
                                    //     `text-capitalize badge badge-pill badge-${assessment_status === 'Passed' ? 'success' :
                                    //         assessment_status === 'in progress' ? 'primary' :
                                    //             assessment_status === 'Failed' ? 'danger' :
                                    //                 assessment_status === 'Not Available' ? 'warning' :
                                    //                     'info'
                                    //     }`
                                    // }>
                                    //     {assessment_status}
                                    // </span>
                                )
                            },
                        ]}
                        data={[...(dashState.dataHist || [])]}
                    />

                </div>
                <div className='card-footer'>
                    <div className='row'>
                        <div className='col'>
                            <button className='btn btn-sm btn-secondary'
                                onClick={() => dashAction.toListPage({ nik: dashState.pageState.dataPage?.nik })}
                            >Back</button>
                            {
                                dataPage.status !== "passed" && <button className='btn btn-sm btn-primary float-right'
                                    onClick={() => history.push("/idp-online/register", dataPage)}
                                >Go To IDP Online</button>
                            }
                        </div>
                    </div>
                </div>


            </div>
            <Modal
                show={dashState.showDetail}
                onHide={() => dashAction.setShowDetail(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Detail Training</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>Isi Content</div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
export default Index
