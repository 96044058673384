import React from 'react';
import moment from 'moment';
import { StatusIcon, TableList } from '../../../../components';
import useResultA from '../useResultA';


function Index(props) {
    const { raAction, raState } = useResultA();
    return (
        <div>
            <TableList
                filter={true}
                labelFilter="Tahun IDP Register :"
                formsFilter={[
                    {
                        type: 'custom',
                        component: (
                            <input type='number' className='form-control form-control-sm'
                                placeholder='Batch'
                                min={0}
                                value={raState?.batch}
                                onChange={(val) => raAction.setBatch(val.target.value)}
                            >
                            </input>
                        )
                    }
                ]}
                submitButton={<><i className='fa fa-search'></i> Search</>}
                onFilter={() => raAction.onFilter()}
                resetFilter={false}
                curPage={1}
                minHeight="300px"
                maxHeight="500px"
                navtable={"TABLE 1"}
                // onChangeShow={rekAction.changeShow}
                // onNextPage={rekAction.nextPage}
                // onPrevPage={rekAction.prevPage}
                isLoading={raState.loadingTraining}
                withActions={true}
                actionType={1}
                actionItems={[
                    {
                        name: 'Lihat detail',
                        onClick: function (item) {
                            raAction.toTraineePage(item)
                        }
                    },
                ]}
                columns={[
                    {
                        name: '#',
                        field: '#',
                        maxWidth: '50px',
                    },
                    {
                        name: "Jobsite",
                        field: 'jobsite',
                        minWidth: '100px',
                    },
                    {
                        name: 'Competency Name',
                        field: 'competency_name',
                        maxWidth: '220px',
                    },
                    {
                        name: 'Course Name',
                        field: 'course_name',
                        maxWidth: '250px',
                    },
                    {
                        name: 'Assessor',
                        field: 'trainer_name',
                        maxWidth: '150px',
                        onRender: (item) => {
                            return (
                                <span className='text-wrap'>{`${item?.trainer_name || ''} - ${item?.trainer_nik || ''}`}</span>
                            )
                        }
                    },
                    {
                        name: 'Assessee',
                        field: 'trainees',
                        maxWidth: '300px',
                        onRender: (item) => {
                            return (
                                <div>
                                    {
                                        [...(item?.trainees || [])].slice(0, 5).map((d, i) => (
                                            <span className='badge badge-info mr-1'>
                                                {
                                                    d?.trainee_name
                                                }
                                            </span>
                                        ))
                                    }
                                    {
                                        [...(item?.trainees || [])].length > 5 && (
                                            <div className='d-block'><span type='button' className="badge badge-warning"
                                                onClick={() => raAction.openModalTrainee([...(item?.trainees || [])])}
                                            >See Detail</span></div>
                                        )
                                    }
                                </div>
                            )
                        }
                    },
                    {
                        name: 'Start Date',
                        field: 'start_date',
                        maxWidth: '150px',
                        onRender: (item) => (
                            <span className='text-wrap'>{moment(item?.start_date).format('DD/MM/YYYY')}</span>
                        )
                    },
                    {
                        name: 'Finish',
                        field: 'end_date',
                        maxWidth: '150px',
                        onRender: (item) => (
                            <span className='text-wrap'>{moment(item?.end_date).format('DD/MM/YYYY')}</span>
                        )
                    },
                    {
                        name: 'Status',
                        field: 'status',
                        maxWidth: '150px',
                        onRender: (item) => {
                            return (
                                <>
                                    <StatusIcon status={item?.status} />
                                    {/* {
                                        item?.status === 'draft' &&
                                        <span className="badge badge-primary text-capitalize">{(item?.status || '')}</span>
                                    }
                                    {
                                        item?.status === 'submitted' &&
                                        <span className="badge badge-success text-capitalize">{(item?.status || '')}</span>
                                    } */}
                                </>
                            )
                        }
                    },
                ]}
                data={[...(raState.lsTraining || [])]}
            />
        </div>
    );
}

export default Index