import moment from 'moment';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { DateInput } from '../../../../components';
import useMenSts from '../useMentSts';
import './index.css';

function Index(props) {
    const { menStsAction: {
        toListPage = () => null,
        toListTraineePage = () => null,
        handleChange = () => null,
        handleFormCheck = () => null,
        setBtnSubType = null,
        onSubmitForm = () => null,
    }, menStsState } = useMenSts();
    const [validate, setValidate] = useState(false);
    return (
        <div>
            <Form
                noValidate
                onSubmit={(e) => {
                    e.preventDefault(e);
                    if(menStsState.btnSubType === "submitted"){
                        if (e.currentTarget.checkValidity() === true) {
                            onSubmitForm();
                        }
                        setValidate(!e.currentTarget.checkValidity());
                    }else{
                        setValidate(false);
                        onSubmitForm();
                    }
                }}
            >
                <div className='card'>
                    <div className='card-body p-5'>
                        <h6 className='text-center'>FORM PELAKSANAAN MENTORING</h6>
                        <h6 className='text-center'>{menStsState.pageState?.dataPage?.form_name}</h6>
                        <div className='form-row mt-5'>
                            <div className='col-md-6'>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>Nama Mentor</label>
                                        {/* <span>{menStsState.formData?.trainer_name}</span> */}
                                        <span>{menStsState.listForm?.trainer_name}</span>
                                    </div>
                                </div>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>NIK Mentor</label>
                                        {/* <span>{menStsState.formData?.trainer_nik}</span> */}
                                        <span>{menStsState.listForm?.trainer_nik}</span>
                                    </div>
                                </div>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>Section</label>
                                        <span>{menStsState.formData?.section}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                {/* <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width'>Hari</label>
                                        <span>{menStsState.formData?.date !== "" && menStsState.formData?.date !== "Invalid date" ? moment(menStsState.formData?.date).format('dddd') : ''}</span>
                                    </div>
                                </div> */}
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill cs-date-picker">
                                        <label className='label-width'>Tanggal</label>
                                        <DateInput
                                            value={menStsState.formData?.date}
                                            disabled={!menStsState.pageState.dataPage?.modeEdit}
                                            onChange={(val) => handleChange('date', val)}
                                            className='form-control flex-fill'
                                            popperClassName='z-index-date-input'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='col-md-6'>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>Mentoring Activity ke - </label>
                                        <input
                                            value={menStsState.formData?.order_mentoring_activity}
                                            onChange={(e) => handleChange('order_mentoring_activity', e.target.value)}
                                            disabled={!menStsState.pageState.dataPage?.modeEdit}
                                            type="number" className="form-control flex-fill align-self-start" placeholder='' />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='form-row'>
                            <div className='col-md-6'>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>Nama Pengawas</label>
                                        {/* <span>{menStsState.formData?.trainee_name}</span> */}
                                        <span>{menStsState.formData?.trainee_nik}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='col-md-6'>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width'>NIK Pengawas</label>
                                        {/* <span>{menStsState.formData?.trainee_nik}</span> */}
                                        <span>{menStsState.formData?.trainee_name}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='form-row'>
                            <div className='col-md-12'>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>Jabatan</label>
                                        <span>{menStsState.formData?.position}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='col-md-12'>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>Lokasi / Pit</label>
                                        <input
                                            value={menStsState.formData?.location}
                                            onChange={(e) => handleChange('location', e.target.value)}
                                            disabled={!menStsState.pageState.dataPage?.modeEdit}
                                            type="text" className="form-control flex-fill align-self-start" placeholder='Lokasi / Pit' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-row mb-3'>
                            <div className='col-md-12'>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start mb-2'>Materi & Praktik</label>
                                        <div>
                                            {
                                                [...(menStsState.listForm?.listData || [])].map(({ data = [], parent }, i) => (
                                                    <div className='row px-1 mb-2'>
                                                        <div className='col-12'>
                                                            <b>{parent}<span className='text-danger pl-1'>*</span></b>
                                                            {/* <div className="form-check justify-content-start">
                                                                <input className="form-check-input" type="checkbox" id={`parent_${i}`} />
                                                                <label className="form-check-label" htmlFor={`parent_${i}`}>
                                                                    <b>{parent}</b>
                                                                </label>
                                                            </div> */}
                                                        </div>
                                                        <div className='col-md-6'>
                                                            {
                                                                [...(data || [])].filter(d => d?.position === "left_col-6").map((d, j) => (

                                                                    <div className="form-check justify-content-start">
                                                                        <Form.Check
                                                                            className="style-check"
                                                                            disabled={!menStsState.pageState.dataPage?.modeEdit}
                                                                            id={d?.form_id}
                                                                            isInvalid={validate ? (menStsState.formCheck[d?.form_id]?.value ? false : true) : false}
                                                                            onChange={(e) => handleFormCheck(d?.form_id, {
                                                                                "form_id": d?.form_id,
                                                                                "value": e.target.checked,
                                                                                "answer_id": menStsState.formCheck[d?.form_id]?.answer_id || null,
                                                                            })}
                                                                            checked={menStsState.formCheck[d?.form_id]?.value}
                                                                            required
                                                                            label={d?.question} />
                                                                        {/* <input
                                                                            disabled={!menStsState.pageState.dataPage?.modeEdit}
                                                                            checked={menStsState.formCheck[d?.form_id]?.value}
                                                                            onChange={(e) => handleFormCheck(d?.form_id, {
                                                                                "form_id": d?.form_id,
                                                                                "value": e.target.checked,
                                                                                "answer_id": menStsState.formCheck[d?.form_id]?.answer_id || null,
                                                                            })}
                                                                            className="form-check-input align-self-start mt-1" type="checkbox" id={d?.form_id} />
                                                                        <label className="form-check-label" htmlFor={d?.form_id}>
                                                                            {d?.question}
                                                                        </label> */}
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                        <div className='col-md-6'>
                                                            {
                                                                [...(data || [])].filter(d => d?.position === "right_col-6").map((d, j) => (

                                                                    <div className="form-check justify-content-start">
                                                                        <Form.Check
                                                                            className="style-check"
                                                                            disabled={!menStsState.pageState.dataPage?.modeEdit}
                                                                            id={d?.form_id}
                                                                            isInvalid={validate ? (menStsState.formCheck[d?.form_id]?.value ? false : true) : false}
                                                                            onChange={(e) => handleFormCheck(d?.form_id, {
                                                                                "form_id": d?.form_id,
                                                                                "value": e.target.checked,
                                                                                "answer_id": menStsState.formCheck[d?.form_id]?.answer_id || null,
                                                                            })}
                                                                            checked={menStsState.formCheck[d?.form_id]?.value}
                                                                            required
                                                                            label={d?.question} />
                                                                        {/* <input
                                                                            disabled={!menStsState.pageState.dataPage?.modeEdit}
                                                                            checked={menStsState.formCheck[d?.form_id]?.value}
                                                                            onChange={(e) => handleFormCheck(d?.form_id, {
                                                                                "form_id": d?.form_id,
                                                                                "value": e.target.checked,
                                                                                "answer_id": menStsState.formCheck[d?.form_id]?.answer_id || null,
                                                                            })}
                                                                            className="form-check-input align-self-start mt-1" type="checkbox" id={d?.form_id} />
                                                                        <label className="form-check-label" htmlFor={d?.form_id}>
                                                                            {d?.question}
                                                                        </label> */}
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                                <small>Catatan : Checklist Poin Materi/ Praktik yang diberikan kepada mentee/ pengawas produksi</small>
                                {/* <div className='row px-1'>
                                    <div className='col-md-6'>
                                        <div className="form-check justify-content-start">
                                            <input className="form-check-input" type="checkbox" id='ch1' />
                                            <label className="form-check-label" htmlFor="ch1">
                                                <b>SAFETY LEADERSHIP</b>
                                            </label>
                                        </div>
                                        <div className="form-check justify-content-start">
                                            <input className="form-check-input" type="checkbox" id='ch1' />
                                            <label className="form-check-label" htmlFor="ch1">
                                                Metode analisa observasi dan diskusi digunakan sesuai dengan ketentuan
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="form-check justify-content-start">
                                            <input className="form-check-input" type="checkbox" id='ch1' />
                                            <label className="form-check-label" htmlFor="ch1">
                                                Temuan jam istirahat yang kurang ditindak lanjuti sesuai ketentuan
                                            </label>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='col-md-6'>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>Achievement </label>
                                        <span className='text-wrap'>{menStsState.formData?.achievement} %</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='col-md-6'>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>Durasi </label>
                                        <input
                                            value={menStsState.formData?.duration}
                                            disabled={!menStsState.pageState.dataPage?.modeEdit}
                                            onChange={(e) => handleChange('duration', e.target.value)}
                                            type="number" className="form-control flex-fill align-self-start" placeholder='' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='col-md-12'>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>Kendala & Support </label>
                                        <textarea
                                            value={menStsState.formData?.constraint_supporting}
                                            disabled={!menStsState.pageState.dataPage?.modeEdit}
                                            onChange={(e) => handleChange('constraint_supporting', e.target.value)}
                                            rows={5} type="number" className="form-control flex-fill align-self-start" placeholder='' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='col-md-12'>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>Catatan Pengembangan Selama Mentoring Berjalan baik/ Perlu ditingkatkan<span className='text-danger pl-1'>*</span></label>
                                        <textarea
                                            value={menStsState.formData?.note}
                                            disabled={!menStsState.pageState.dataPage?.modeEdit}
                                            onChange={(e) => handleChange('note', e.target.value)}
                                            rows={5} type="number" className="form-control flex-fill align-self-start" placeholder='' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-row mt-5'>
                            <div className='col-md-12 text-right'>
                                <button type="button" className='btn btn-secondary btn-sm mr-1 float-left'
                                    onClick={() => toListPage({ trainee_nik: menStsState.pageState?.dataPage?.trainee_nik })}
                                // onClick={() => toListTraineePage({ competency_id: menStsState.pageState?.dataPage?.competency_id })}
                                >Back</button>
                                {
                                    menStsState.formData?.status === 'approved' && <button className='btn btn-sm btn-primary mr-1'
                                        onClick={() => window.print()}
                                    ><i className='fa fa-print icon-nm mr-1'></i>Print</button>
                                }
                                {
                                    menStsState.pageState.dataPage?.modeEdit == true && (
                                        <>
                                            <button type='submit'
                                                onClick={() => setBtnSubType("draft")}
                                                className='btn btn-sm btn-light mr-1' value={"draft"}>Save as Draft</button>
                                            <button type='submit'
                                                onClick={() => setBtnSubType("submitted")}
                                                className='btn btn-sm btn-primary mr-1' value={"submit"}>Submit</button>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    );
}

export default Index;
