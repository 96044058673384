import React from 'react';
import './index.css';

//draft, submitted, decline, approved, inprogress, passed
const statusList = [
    { val: "draft", label: "Draft", style: "style-sts-icon-1" },
    { val: "submitted", label: "Submitted", style: "style-sts-icon-2" },
    { val: "not submitted", label: "Not Submitted", style: "style-sts-icon-4" },
    { val: "approved", label: "Approved", style: "style-sts-icon-3" },
    { val: "declined", label: "Decline", style: "style-sts-icon-4" },
    { val: "decline", label: "Decline", style: "style-sts-icon-4" },
    { val: "notavailable", label: "Not Available", style: "style-sts-icon-4" },
    { val: "not available", label: "Not Available", style: "style-sts-icon-4" },
    { val: "available", label: "Available", style: "style-sts-icon-2" },
    { val: "grade not match", label: "Grade Not Match", style: "style-sts-icon-4" },
    { val: "passed", label: "Passed", style: "style-sts-icon-3" },
    { val: "inprogress", label: "In Progress", style: "style-sts-icon-5" },
    { val: "in progress", label: "In Progress", style: "style-sts-icon-5" },
    { val: "failed", label: "Failed", style: "style-sts-icon-6" },
    { val: "needrevise", label: "Need Revise", style: "style-sts-icon-5" },
    { val: "need revise", label: "Need Revise", style: "style-sts-icon-5" },
    { val: "competent", label: "Competent", style: "style-sts-icon-3" },
    { val: "Competent", label: "Competent", style: "style-sts-icon-3" },
    { val: "not_competent", label: "Not Competent", style: "style-sts-icon-4" },
    { val: "Not Competent", label: "Not Competent", style: "style-sts-icon-4" }
]

export const StatusIcon = ({ status = null }) => {
    const RenderButton = () => {
        const style = statusList.find(d => d.val?.toLowerCase() == status?.toLowerCase());
        return style ? <div className={`status-icon ${style?.style}`}>{style?.label}</div> : <></>
    }
    return (
        <RenderButton />
    )
}
