import React, { createContext, useContext, useEffect, useState } from 'react';
import { useModalAlert } from '../../../components/modalAlert';
import { useAuth } from '../../../context';
import { useLocation } from 'react-router-dom'
import useFetch from '../../../hooks/useFetch';

const idprContext = createContext({
    regAction: {

    },
    regState: {

    }
});

export const RegProvider = ({ children }) => {

    const [loadingGet, setLoadingGet] = useState(false)
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const { doGet, doPost } = useFetch();
    const { doLogout } = useAuth();
    const [dataComp, setDataComp] = useState({
        "totalData": 0,
        "totalPage": 0,
        "listData": [],
        "dropdowGroup": []
    });
    const [idpDeleted, setIdpDeleted] = useState([])
    const [formData, setFormData] = useState([]);
    const location = useLocation()

    const [pagination, setPagination] = useState({
        page: 1, show: 10, order_by: "grade", order_type: "asc"
    });
    const [filter, setFilter] = useState({
        filter_group: "", filter_name: "",
        filter_grade: "", filter_cluster: "",
        type: "Mechanic"
    });
    const [dropData, setDropData] = useState([])
    function changeCourse(data) {
        if (dropData.some(v => v.id === data.id)) {
            let newData = data.value === "" ? dropData.filter((vx) => vx.id !== data.id) : dropData.map((v) => v.id === data.id ? ({
                course_id: data.value, course_name: data.label, id: data.id,
            }) : ({
                ...v
            }))
            setDropData(newData)
        } else {
            setDropData(prevState => [...prevState, { course_id: data.value, course_name: data.label, id: data.id }]);
        }

    }

    async function getDataCompetency(filterData = undefined) {
        if (!filterData) {
            filterData = filter
        }
        // openLoading("Get competency")
        setLoadingGet(true)
        try {
            const { status, statusText, payload } = await doPost({
                data: {
                    ...filterData,
                    "row_in_page": pagination.show,
                    "page": pagination.page,
                    "order_by": pagination.order_by,
                    "order_type": pagination.order_type
                }
                ,
                url: '/api/list-competency'
            });
            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDataComp({ ...data })
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get competency",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get competency",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get competency",
                callBack: () => null
            })
        }
        setLoadingGet(false)
    }


    const [loadingDetail, setLoadingDetail] = useState(false)
    async function getDetail() {
        // openLoading("Get competency")
        setLoadingDetail(true)
        try {
            const { status, statusText, payload } = await doGet({
                url: '/api/detail-regis-idp',
                param: { type_page: "register" }
            });
            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setFormData(data.dataIDP || [])
                    // set({ ...data })
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get detail register",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get detail register",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get detail register",
                callBack: () => null
            })
        }
        setLoadingDetail(false)
    }

    useEffect(function () {
        getDetail()
    }, [])
    const [withLocation, setWithLocation] = useState(false)

    useEffect(function () {

        if (location.state) setWithLocation(true)
    }, [location.state])

    useEffect(() => {
        if (location.state && withLocation) {
            setFilter(v => ({
                ...v,
                filter_name: `${location.state.competency_name}`
            }));
            getDataCompetency({
                filter_id: `${location.state.competency_id}`,
                filter_name: `${location.state.competency_name}`
            })
            setWithLocation(false)
        }
    }, [withLocation]);

    useEffect(() => {
        if (!withLocation) {
            getDataCompetency()
        }
    }, [pagination]);
    function nextPage({ page, limit }) {
        setPagination(prevState => ({
            ...prevState,
            page, show: limit,
        }))
    }
    function prevPage({ page, limit }) {
        setPagination(prevState => ({
            ...prevState,
            page, show: limit,
        }))
    }
    function changeShow({ page, limit }) {
        setPagination(prevState => ({
            ...prevState,
            page, show: limit,
        }))
    }
    function onOrdering({ order_by, order_type }) {
        setPagination(prevState => ({
            ...prevState,
            order_by, order_type
        }))
    }


    function doFilter() {
        setPagination(prevState => ({
            ...prevState,
            page: 1
        }))
    }
    function pilihCompetency(data) {
        let course = dropData.find(v => v.id === data.id)
        if (
            (course === undefined || course === null || course === "") ||
            (course?.course_id === undefined || course?.course_id === null || course?.course_id === "")
        ) {
            return setDataAlert({
                show: true,
                body: "Course belum dipilih",
                header: "Info",
                callBack: () => null
            })
        }

        // if (formData.length === 5) {
        //     return setDataAlert({
        //         show: true,
        //         body: "Hanya dapat memilih 5 course",
        //         header: "Info",
        //         callBack: () => null
        //     })
        // }
        if (
            formData.some(v => (
                v.competency_id === data.id &&
                v.course_id === course.course_id
            ))
        ) {
            return setDataAlert({
                show: true,
                body: `Competency dengan course name : "${course.course_name}" sudah terpilih`,
                header: "Info",
                callBack: () => null
            })
        }
        setFormData(prevState => [
            ...prevState,
            {
                "type": data.type,
                "competency_group": data.group,
                "competency_id": data.id,
                "competency_name": data.name,
                "course_id": course.course_id,
                "course_name": course.course_name,
                // "status": "draft",
                "point": data.point,
                "grade": data.grade,
                cluster: data.cluster
            }
        ]);
    }

    const [dropCluster, setDropCluster] = useState([])
    const [loadingCluster, setLoadingCluster] = useState(false)
    const [dropGrade, setDropGrade] = useState([])
    const [loadingGrade, setLoadingGrade] = useState(false)

    // useEffect(function(){
    //     getCluster()
    // },[])
    async function getCluster() {
        setLoadingCluster(true)
        // openLoading("Get competency")
        setDropCluster([])
        // if(type !== null && type !== undefined && type !== ""){
        try {
            const { status, statusText, payload } = await doGet({
                url: '/api/ats/list-cluster',
                param: { type: "Mechanic" }
            });
            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = [], code, msg } = payload;
                if (code === "00") {
                    setDropCluster(data)
                    // setFormData(data.dataIDP || [])
                    // set({ ...data })
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Cluster",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Cluster",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Cluster",
                callBack: () => null
            })
        }
        setLoadingCluster(false)
        // }
    }

    // useEffect(function(){
    //     filter.cluster !== "" && 
    //     filter.cluster !== undefined && 
    //     filter.cluster !== null && 
    //     getGrade()
    // },[filter.cluster])

    async function getGrade() {
        // openLoading("Get competency")s
        setLoadingGrade(true)
        setDropGrade([])
        // if(type !== null && type !== undefined && type !== ""){
        try {
            const { status, statusText, payload } = await doGet({
                url: '/api/ats/list-grade',
                param: { type: "Mechanic", cluster: filter.cluster }
            });
            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = [], code, msg } = payload;
                if (code === "00") {
                    setDropGrade(data)
                    // setFormData(data.dataIDP || [])
                    // set({ ...data })
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Cluster",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Cluster",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Cluster",
                callBack: () => null
            })
        }
        setLoadingGrade(false)
        // }
    }
    function confirmSubmit(status) {
        if (status === "draft") return doSubmit(status)
        // let formType = paramUrl.mode === "edit" ? "ubah data" : "pendaftaran";
        let body = `Sebelum melanjutkan, cek kembali data yang disubmit. Lanjutkan ?`;
        if (status === "draft") body = `Sebelum melanjutkan, cek kembali data yang disimpan. Lanjutkan dimpan sebagai draft?`
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Yes", onClick: () => doSubmit(status), className: "btn btn-sm btn-success" },
                { name: "No", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    async function doSubmit(sts) {

        closeConfirm()
        if (formData.length <= 0) {
            return setDataAlert({
                show: true,
                body: `Tidak ada course terpilih`,
                header: "Info",
                callBack: () => null
            })
        }
        openLoading("Sending data, please wait...");

        let data_input = (formData || []).map((input) => ({
            competency_id: input.competency_id,
            course_id: input.course_id,
            course_name: input.course_name,
            point: input.point,
            status: input.status || ""
        }))
        try {
            const { status, payload, statusText } = await doPost({
                url: "/api/regis-idp",
                data: {
                    status: sts,
                    data_input,
                    deleted_idp_regis_id: idpDeleted
                }
            });

            if (status !== 401) {
                if (status === 200 || status === 201) {
                    const { code, msg } = payload;
                    if (code === "00") {
                        setDataAlert({
                            body: msg,
                            show: true,
                            header: "Success",
                            callBack: () => getDetail()
                        })
                    } else setDataAlert({
                        show: true,
                        body: msg,
                        header: "Info",
                        callBack: () => null
                    })
                } else setDataAlert({
                    show: true,
                    body: statusText,
                    header: "Info",
                    callBack: () => null
                })
            }
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Info",
                callBack: () => null
            })
        }
        closeLoading();
    }

    const deleteFormData = ({ course_id, competency_id, id }) => {
        setDataConfirm({
            show: true,
            body: `Data akan terhapus. Lanjutkan?`,
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                {
                    name: "Yes", onClick: () => {
                        closeConfirm()
                        if (id !== undefined && id !== null && id !== "") {
                            setIdpDeleted(v => ([...v, id]))
                        }
                        const newData = formData.filter(v => {
                            return !((v.competency_id === competency_id) && (v.course_id === course_id))
                        })
                        setFormData(newData);

                        setDataAlert({
                            body: <>
                                <h5 className='text-center mb-3'>Berhasil dihapus</h5>
                                <p className='text-center ' style={{ fontSize: "small" }}> Data akan terhapus permanent setelah disubmit atau simpan sebagai draft </p>
                            </>,

                            show: true,
                            header: "",
                        })
                    }, className: "btn btn-sm btn-success"
                },
                { name: "No", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }
    return (
        <idprContext.Provider value={{
            regAction: {
                nextPage,
                prevPage,
                changeShow,
                onOrdering,
                setFilter,
                doFilter,
                changeCourse,
                pilihCompetency,
                deleteFormData,
                confirmSubmit
            },
            regState: {
                loadingGet,
                dataComp,
                pagination,
                filter,
                dropData,
                formData,
                loadingDetail,
                loadingCluster,
                loadingGrade,
                dropCluster,
                dropGrade
            }
        }}>{children}</idprContext.Provider>
    )
}

export default function useIdpRegister() {
    return useContext(idprContext)
}

