import React from 'react';
import { TableList } from '../../../components';
import useInputHmOperator, { HMOProvider } from './useInputHmOperator';
import * as pages from './pages'

function HMOPage() {
    const { 
        HMOState : {
            pageStatus,
            ...othState
        },
        HMOActions
    } = useInputHmOperator()
    const PageRendered = pages[pageStatus.rendered]
    return <>
        <PageRendered />
    </>
}

export default () => <HMOProvider>
    <HMOPage />
</HMOProvider>