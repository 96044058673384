import React from 'react';
import useTirRev from '../useTirRev';

function Index(props) {
    const { tirRevAction, tirRevState } = useTirRev();
    return (
        <div>
            <form>
                <div className='card'>
                    <div className='card-body p-5'>
                        <h5 className='text-center font-weight-bold mb-5'>TECHNICAL INFORMATION REPORT</h5>
                        <div className="form-row mb-3">
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <div className='col-sm-5 col-form-label py-0'>
                                        <span className='d-block font-weight-bold'>Unit Code</span>
                                        <small className='font-italic'>Kode Unit</small>
                                    </div>
                                    <div className="col-sm-7">
                                        <input type="text" className="form-control" placeholder="Unit Code" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className='col-sm-5 col-form-label py-0'>
                                        <span className='d-block font-weight-bold'>Unit Model</span>
                                        <small className='font-italic'>Model Unit</small>
                                    </div>
                                    <div className="col-sm-7">
                                        <input type="text" className="form-control" placeholder="Unit Model" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className='col-sm-5 col-form-label py-0'>
                                        <span className='d-block font-weight-bold'>Unit Serial No.</span>
                                        <small className='font-italic'>Serial number unit</small>
                                    </div>
                                    <div className="col-sm-7">
                                        <input type="text" className="form-control" placeholder="Unit Serial No." />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className='col-sm-5 col-form-label py-0'>
                                        <span className='d-block font-weight-bold'>Unit Hours Meter / KM</span>
                                        <small className='font-italic'>Jam / KM unit</small>
                                    </div>
                                    <div className="col-sm-7">
                                        <input type="text" className="form-control" placeholder="Unit Hours Meter / KM" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className='col-sm-5 col-form-label py-0'>
                                        <span className='d-block font-weight-bold'>Maitenance Order No.</span>
                                        <small className='font-italic'>Nomer Work Order</small>
                                    </div>
                                    <div className="col-sm-7">
                                        <input type="text" className="form-control" placeholder="Maitenance Order No." />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <div className='col-sm-5 col-form-label py-0'>
                                        <span className='d-block font-weight-bold'>Component</span>
                                        <small className='font-italic'>Komponen</small>
                                    </div>
                                    <div className="col-sm-7">
                                        <input type="text" className="form-control" placeholder="Component" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className='col-sm-5 col-form-label py-0'>
                                        <span className='d-block font-weight-bold'>Component Serial No.</span>
                                        <small className='font-italic'>Nomor Serial Komponen</small>
                                    </div>
                                    <div className="col-sm-7">
                                        <input type="text" className="form-control" placeholder="Component Serial No." />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className='col-sm-5 col-form-label py-0'>
                                        <span className='d-block font-weight-bold'>Component Hours</span>
                                        <small className='font-italic'>Umur Komponen</small>
                                    </div>
                                    <div className="col-sm-7">
                                        <input type="text" className="form-control" placeholder="Component Hours" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className='col-sm-5 col-form-label py-0'>
                                        <span className='d-block font-weight-bold'>Scheduled Hours</span>
                                        <small className='font-italic'>Jadwal Pergantian (Jam)</small>
                                    </div>
                                    <div className="col-sm-7">
                                        <input type="text" className="form-control" placeholder="Scheduled Hours" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className='col-sm-5 col-form-label py-0'>
                                        <span className='d-block font-weight-bold'>Date of Repair/Install</span>
                                        <small className='font-italic'>Tanggal Perbaikan/pemasangan</small>
                                    </div>
                                    <div className="col-sm-7">
                                        <input type="text" className="form-control" placeholder="Date of Repair/Install" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className='col-sm-5 col-form-label py-0'>
                                        <span className='d-block font-weight-bold'>PR /PO no.</span>
                                        <small className='font-italic'>Nomor PR /PO</small>
                                    </div>
                                    <div className="col-sm-7">
                                        <input type="text" className="form-control" placeholder="PR /PO no." />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form row mb-3 border p-3'>
                            <div className='col-12 mb-3'>
                                <span className='d-block font-weight-bold'>Description of Failure / Change Out <span className='font-weight-normal font-italic'>/ Uraian tentang Kerusakan / Penggantian</span></span>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group row">
                                    <div className='col-sm-5 col-form-label py-0'>
                                        <span className='d-block font-weight-bold'>Scheduled Change Out</span>
                                        <small className='font-italic'>Penggantian yang dijadwalkan</small>
                                    </div>
                                    <div className="col-sm-7">
                                        <div className='form-check form-check-inline'>
                                            <input type="checkbox" className="form-check-input" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className='col-sm-5 col-form-label py-0'>
                                        <span className='d-block font-weight-bold'>Failure</span>
                                        <small className='font-italic'>Kerusakan</small>
                                    </div>
                                    <div className="col-sm-7">
                                        <div className='form-check form-check-inline'>
                                            <input type="checkbox" className="form-check-input" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className='col-sm-5 col-form-label py-0'>
                                        <span className='d-block font-weight-bold'>Redo</span>
                                        <small className='font-italic'>Pengulangan Pekerjaan</small>
                                    </div>
                                    <div className="col-sm-7">
                                        <div className='form-check form-check-inline'>
                                            <input type="checkbox" className="form-check-input" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group row">
                                    <div className='col-sm-5 col-form-label py-0'>
                                        <span className='d-block font-weight-bold'>Subject to Warranty</span>
                                        <small className='font-italic'>Ditujukan untuk Warranty</small>
                                    </div>
                                    <div className="col-sm-7">
                                        <div className="form-check form-check-inline">
                                            <label className="form-check-label mr-1" >Y</label>
                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" />
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <label className="form-check-label mr-1" >N</label>
                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className='col-sm-5 col-form-label py-0'>
                                        <span className='d-block font-weight-bold'>Accident Damage</span>
                                        <small className='font-italic'>Kerusakan Kecelakaan </small>
                                    </div>
                                    <div className="col-sm-7">
                                        <div className='form-check form-check-inline'>
                                            <input type="checkbox" className="form-check-input" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className="form-group">
                                    <span className='d-block font-weight-bold mb-2'>Chronology <span className='font-weight-normal font-italic'>/ Kronologi</span></span>
                                    <textarea rows={2} className="form-control" />
                                </div>
                                <div className="form-group">
                                    <span className='d-block font-weight-bold mb-2'>Complaint  <span className='font-weight-normal font-italic'>/ Permasalahan</span></span>
                                    <textarea rows={2} className="form-control" />
                                </div>
                                <div className="form-group">
                                    <span className='d-block font-weight-bold mb-2'>Findings <span className='font-weight-normal font-italic'>/ Temuan</span></span>
                                    <textarea rows={2} className="form-control" />
                                </div>
                                <div className="form-group">
                                    <span className='d-block font-weight-bold mb-2'>Contributing Factors / Investigation <span className='font-weight-normal font-italic'>/ Faktor Pendukung / Penyelidikan</span></span>
                                    <textarea rows={2} className="form-control" />
                                </div>
                                <div className="form-group">
                                    <span className='d-block font-weight-bold mb-2'>Root Cause if known <span className='font-weight-normal font-italic'>/ Sebab Utama jika diketahui</span></span>
                                    <textarea rows={2} className="form-control" />
                                </div>
                            </div>
                            <div className='col-12 text-center mb-3'>
                                <div className='border my-3'></div>
                                <span className='d-block font-weight-bold'>Supporting Documentation ( Review & Attach as Required )</span>
                                <span className='d-block font-italic'>Dokumentasi Pendukung ( Tinjauan ulang & Lampiran sesuai yang diperlukan)</span>
                            </div>
                            <div className='col-md-4 mb-2'>
                                <div className='d-inline-flex font-weight-bold border-bottom border-dark mb-2'>History</div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" />
                                    <div className="form-check-label">
                                        <span className='d-block font-weight-bold'>P2H Check List</span>
                                        <small className='font-italic'>Pemeriksaan P2H</small>
                                    </div>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" />
                                    <div className="form-check-label">
                                        <span className='d-block font-weight-bold'>Daily/Weekly Inspection</span>
                                        <small className='font-italic'>Pemeriksaan Harian/Mingguan</small>
                                    </div>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" />
                                    <div className="form-check-label">
                                        <span className='d-block font-weight-bold'>PS Check Sheet</span>
                                        <small className='font-italic'>Laporan Pemeriksaan PS</small>
                                    </div>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" />
                                    <div className="form-check-label">
                                        <span className='d-block font-weight-bold'>Mechanical Inspection</span>
                                        <small className='font-italic'>Pemeriksaan Mekanik</small>
                                    </div>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" />
                                    <div className="form-check-label">
                                        <span className='d-block font-weight-bold'>Electrical Inspection</span>
                                        <small className='font-italic'>Pemeriksaan Elektrik</small>
                                    </div>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" />
                                    <div className="form-check-label">
                                        <span className='d-block font-weight-bold'>Downtime History</span>
                                        <small className='font-italic'>Sejarah Downtime</small>
                                    </div>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" />
                                    <div className="form-check-label">
                                        <span className='d-block font-weight-bold'>Work Order History</span>
                                        <small className='font-italic'>Sejarah Work Order</small>
                                    </div>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" />
                                    <div className="form-check-label">
                                        <span className='d-block font-weight-bold'>Component History</span>
                                        <small className='font-italic'>Sejarah Komponen</small>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 mb-2'>
                                <div className='d-inline-flex font-weight-bold border-bottom border-dark mb-2'>Condition Monitoring</div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" />
                                    <div className="form-check-label">
                                        <span className='d-block font-weight-bold'>Filter Cut Records</span>
                                        <small className='font-italic'>Data Filter Cut</small>
                                    </div>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" />
                                    <div className="form-check-label">
                                        <span className='d-block font-weight-bold'>Magnetic/Screen Records</span>
                                        <small className='font-italic'>Data Magnetic/Screen</small>
                                    </div>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" />
                                    <div className="form-check-label">
                                        <span className='d-block font-weight-bold'>Magnetic Plug Records</span>
                                        <small className='font-italic'>Data Magnetic Plug</small>
                                    </div>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" />
                                    <div className="form-check-label">
                                        <span className='d-block font-weight-bold'>SOS History</span>
                                        <small className='font-italic'>Sejarah SOS</small>
                                    </div>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" />
                                    <div className="form-check-label">
                                        <span className='d-block font-weight-bold'>On Board Monitor System</span>
                                        <small className='font-italic'>Data pada sistem On Board</small>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 mb-2'>
                                <div className='d-inline-flex font-weight-bold border-bottom border-dark mb-2'>Investigation</div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" />
                                    <div className="form-check-label">
                                        <span className='d-block font-weight-bold'>Mechanical Inspection</span>
                                        <small className='font-italic'>Pemeriksaan Mekanik</small>
                                    </div>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" />
                                    <div className="form-check-label">
                                        <span className='d-block font-weight-bold'>Electrical Inspection</span>
                                        <small className='font-italic'>Pemeriksaan Elektrik</small>
                                    </div>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" />
                                    <div className="form-check-label">
                                        <span className='d-block font-weight-bold'>Service Report (from Vendor)</span>
                                        <small className='font-italic'>Laporan Service</small>
                                    </div>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" />
                                    <div className="form-check-label">
                                        <span className='d-block font-weight-bold'>Stripdown Report</span>
                                        <small className='font-italic'>Laporan Teardown</small>
                                    </div>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" />
                                    <div className="form-check-label">
                                        <span className='d-block font-weight-bold'>Failure Analysis</span>
                                        <small className='font-italic'>Analisa Kerusakan</small>
                                    </div>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" />
                                    <div className="form-check-label">
                                        <span className='d-block font-weight-bold'>Photo's of Damage</span>
                                        <small className='font-italic'>Foto dari Kerusakan</small>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 mb-3'>
                                <div className='border my-3'></div>
                                <span className='d-block font-weight-bold'>Operator Report (if required) <span className='font-weight-normal font-italic'>/ Laporan Operator (jika diperlukan)</span></span>
                            </div>
                            <div className='col-md-3'>
                                <div className="form-group row">
                                    <div className='col-sm col-form-label py-0'>
                                        <span className='d-block font-weight-bold'>P2H Checklist</span>
                                        <small className='font-italic'>Checklist P2H</small>
                                    </div>
                                    <div className="col-sm-auto">
                                        <div className='form-check form-check-inline'>
                                            <input type="checkbox" className="form-check-input" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className="form-group row">
                                    <div className='col-sm col-form-label py-0'>
                                        <span className='d-block font-weight-bold'>Warning Alarm</span>
                                        <small className='font-italic'>Tanda Peringatan/Alarm</small>
                                    </div>
                                    <div className="col-sm-auto">
                                        <div className='form-check form-check-inline'>
                                            <input type="checkbox" className="form-check-input" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className="form-group row">
                                    <div className='col-sm col-form-label py-0'>
                                        <span className='d-block font-weight-bold'>Noises/Operation OK</span>
                                        <small className='font-italic'>Kebisingan /Operasi OK</small>
                                    </div>
                                    <div className="col-sm-auto">
                                        <div className='form-check form-check-inline'>
                                            <input type="checkbox" className="form-check-input" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className="form-group row">
                                    <div className='col-sm col-form-label py-0'>
                                        <span className='d-block font-weight-bold'>Operator Statement</span>
                                        <small className='font-italic'>Pernyataan Operator</small>
                                    </div>
                                    <div className="col-sm-auto">
                                        <div className='form-check form-check-inline'>
                                            <input type="checkbox" className="form-check-input" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className="form-group">
                                    <span className='d-block font-weight-bold mb-2'>Operator  Report Assessment <span className='font-weight-normal font-italic'>/ Penilaian Laporan Operator</span></span>
                                    <textarea rows={2} className="form-control" />
                                </div>
                            </div>
                            <div className='col-12 mb-3'>
                                <div className='border my-3'></div>
                                <div className="form-group">
                                    <span className='d-block font-weight-bold mb-2'>Required Corrective Action <span className='font-weight-normal font-italic'>/ Tindakan Perbaikan yang diperlukan</span></span>
                                    <textarea rows={4} className="form-control" />
                                </div>
                            </div>
                            <div className='col-12 mb-3'>
                                <div className='border my-3'></div>
                                <div className="form-group">
                                    <span className='d-block font-weight-bold mb-2'>Plant Department Head's Comment & Conclusion <span className='font-weight-normal font-italic'>/ Komentar & Kesimpulan dari Plant Department Head</span></span>
                                    <textarea rows={4} className="form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="form-row mb-3">
                            <div className='col'>
                                <button
                                    type='button'
                                    onClick={() => tirRevAction.toListPage()}
                                    className='btn btn-sm btn-secondary'>Back</button>
                            </div>
                            <div className='col text-right'>
                                {
                                    // tirRevState.pageState?.dataPage?.modeEdit  === false ? (
                                    //     <>
                                    //         <button
                                    //             type='submit'
                                    //             className='btn btn-sm btn-success'>Save</button>
                                    //     </>
                                    // ) : (
                                    //     <>
                                    //         <button
                                    //             type='button'
                                    //             className='btn btn-sm btn-danger mr-1'>Delete</button>
                                    //         <button
                                    //             type='submit'
                                    //             className='btn btn-sm btn-success mr-1'>Upadate</button>
                                    //     </>
                                    // )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Index;