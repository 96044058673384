import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useModalAlert } from "../../../components/modalAlert";
import useFetch from "../../../hooks/useFetch";
import useAllNotif from "../useAllNotif";

const ntUrdContext = createContext({
    ntUrdState: {},
    ntUrdAction: {}
});
export function NtUrdProvider({ children }) {
    const { allNotAction, allNotState } = useAllNotif();
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const { doGet } = useFetch();
    const [loadingGet, setLoadingGet] = useState(false);
    const [dataNotifUnRead, setDataNotifUnRead] = useState({});
    const history = useHistory();

    async function getDataNotifUnread() {
        // openLoading("Get Notification")
        setLoadingGet(true)
        try {
            const { status, statusText, payload } = await doGet({
                param: {
                    "filter_has_read": 0,
                    "filter_group": "",
                    "filter_name": "",
                    "row_in_page": pagination.show,
                    "page": pagination.page,
                    "order_by": pagination.order_by,
                    "order_type": pagination.order_type
                },
                url: '/api/my-notifications',
            });

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDataNotifUnRead({ ...data });
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Notif Unread",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Notif Unread",
                callBack: () => null
            })

        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get All Notif Unread",
                callBack: () => null
            })
        }
        setLoadingGet(false)
    }
    function rowOnclick(url = '', type = '', data = {}) {
        if (type === 'submit_idp') {
            const arr = url.split('=');
            history.push(`/idp-online/approval/detail/${arr[1]}`, {...data});

        }
        if (type === 'approval_idp') {
            history.push(url);
        }
    }
    function getData() {
        getDataNotifUnread()
    }

    const [pagination, setPagination] = useState({
        page: 1, show: 10, order_by: "point", order_type: "asc"
    });
    function nextPage({ page, limit }) {
        setPagination(prevState => ({
            ...prevState,
            page, show: limit,
        }))
    }
    function prevPage({ page, limit }) {
        setPagination(prevState => ({
            ...prevState,
            page, show: limit,
        }))
    }
    function changeShow({ page, limit }) {
        setPagination(prevState => ({
            ...prevState,
            page, show: limit,
        }))
    }
    function onOrdering({ order_by, order_type }) {
        setPagination(prevState => ({
            ...prevState,
            order_by, order_type
        }))
    }

    useEffect(() => {
        if (allNotState.tab === 'unread') {
            getData();
        }
    }, [allNotState.tab, pagination]);

    return <ntUrdContext.Provider
        value={{
            ntUrdState: {
                loadingGet,
                dataNotifUnRead,
                pagination
            },
            ntUrdAction: {
                rowOnclick,
                nextPage,
                prevPage,
                changeShow,
                onOrdering
            }
        }}
    >{children}</ntUrdContext.Provider>
}
export default function useNtUrd() {
    return useContext(ntUrdContext);
}