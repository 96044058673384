import Logo from './logo';

const configLayout = {
    baseColor: "#08a64a",
    primaryColor: "#0d6efd",
    secondaryColor: "#6c757d",
    successColor: "#198754",
    infoColor:"#0dcaf0",
    dangerColor:"#dc3545",
    warningColor:"#ffc107",
    logo:Logo

}

export default configLayout;