import React from 'react';
import 'anychart';
import AnyChart from 'anychart-react';

import useReportDataCompPassed from './useReportDataCompPassed';

const ChartData = () => {
    const { reportDtCompPassedState } = useReportDataCompPassed();

    // set the chart type
    const chart = anychart.line();
    chart.credits().enabled(false);

    // set the chart title
    chart.title("Average Ach.%");

    // create a series, set the data and name
    const series = chart.column(reportDtCompPassedState.dataList);
    
    series.labels(true);
    // series.labels().fontColor("green");
    // series.labels().fontWeight(900);
    series.labels().format("{%value}");

    chart.container('container-comp-passed');

    return (
        <AnyChart
            instance={chart}
            height={window.innerHeight - 550}
            id="chart-competency-passes-container"
        />
    )
}

export default ChartData;
