import React from 'react';
import { Modal } from 'react-bootstrap';
import { useAuth, useGlobalState } from '../../context';
import userDummy from '../images/userdummy.png'
import useNav, { NavProvider } from './use_navbar';
import './index.css';
import { Link } from 'react-router-dom';
const Navbar = () => {
    const {
        doLogout,
        userInfo
    } = useAuth();
    const { navAction, navState } = useNav();
    const { globalState } = useGlobalState();
    return (
        <>
            {/* <!-- Navbar --> */}
            <nav className="main-header navbar navbar-expand navbar-white navbar-light  elevation-1">
                {/* <!-- Left navbar links --> */}
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu"  role="button"><i className="fas fa-bars"></i></a>
                    </li>
                </ul>

                {/* <!-- Right navbar links --> */}
                <ul className="navbar-nav ml-auto">
                    {/* <!-- Navbar Search --> */}

                    {/* <!-- Notifications Dropdown Menu --> */}
                    <li className="nav-item dropdown">
                        <a className="nav-link" data-toggle="dropdown" href="#">
                            <i className="far fa-bell" style={{ fontSize: "16pt" }}></i>
                            {
                                globalState.notif?.totalNewNotif !== 0 && (
                                    <span className="badge badge-danger navbar-badge">{globalState.notif?.totalNewNotif}</span>
                                )
                            }
                        </a>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" style={{ left: 'inherit', right: 0 }}>
                            {
                                [...(globalState.notif?.listData || [])].length === 0 && (
                                    <div className='my-2'>
                                        <div className="media">
                                            <div className="media-body">
                                                <h3 className="dropdown-item-title">
                                                </h3>
                                                <p className="text-sm text-muted text-center"> Tidak ada pemberitahuan </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                [...(globalState.notif?.listData || [])].length > 0 && (
                                    <div>
                                        {
                                            [...(globalState.notif?.listData || [])].map((d, i) => (
                                                <a href="#" className="dropdown-item">
                                                    <div className="media" key={i} onClick={() => navAction.notifOnclick(d?.url, d?.type, d)}>
                                                        <div className="media-body">
                                                            <h3 className="dropdown-item-title">
                                                                <span className="float-right text-sm text-danger"><i className="fas fa-star"></i></span>
                                                            </h3>
                                                            <p className="text-sm text-muted">{d?.message}</p>
                                                        </div>
                                                    </div>
                                                </a>
                                            ))
                                        }
                                    </div>
                                )
                            }
                            <div className="dropdown-divider"></div>
                            <Link to={'/all-notification'}>
                                <span className="dropdown-item dropdown-footer">See All Notification</span>
                            </Link>
                        </div>
                    </li>
                    {/* <!-- User Dropdown Menu --> */}
                    <li className="nav-item dropdown">
                        <div className="nav-link" data-toggle="dropdown" role='button'>
                            <i style={{ fontSize: "16pt" }} className="fa fa-cog"></i>
                            {/* <span className="badge badge-warning navbar-badge">15</span> */}
                        </div>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" style={{
                            background: "#DAE3F3"
                        }}>

                            <div className="user-panel mt-0 pb-3 mb-3 pt-3 d-flex" style={{
                                background: "#44546A",
                            }}>
                                <div className="image">
                                    <img src={userDummy} className="img-circle" style={{
                                        background: "#DAE3F3",
                                        width: "60px"
                                    }} alt="User Image" />
                                </div>
                                <div className="info text-center" style={{
                                    overflow: "hidden",
                                    paddingLeft: "20px",
                                    whiteSpace: "break-spaces"
                                }}>
                                    <span style={{
                                        margin: "auto",
                                        display: "inline-block",
                                        fontWeight: "bold",
                                    }} href="#" className="d-block text-white">{
                                            userInfo.name
                                        }</span>
                                    {/* <Link to={'/all-notification'}>
                                        <button
                                            style={{
                                                marginTop: "3px",
                                                padding: "0px 10px",
                                                fontSize: "8pt",
                                                background: "white",
                                                border: "none"
                                            }}
                                        >0 messages</button>
                                    </Link> */}
                                </div>
                            </div>
                            <div className='p-3'>
                                <button onClick={() => doLogout()} className='btn btn-sm btn-default text-white' style={{
                                    background: "#44546A"
                                }}>
                                    Sign Out
                                </button>
                                <span
                                    role={'button'}
                                    className='d-block text-success my-3'
                                    onClick={() => navAction.showModalChangePass()}>Change password</span>
                            </div>
                            <Modal size='lg' show={navState.show} onHide={() => navAction.setShow(false)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Ubah Password</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <form
                                        // onInput={() => {
                                        //     navState.refConf.current.setCustomValidity((navState.refConf.current.value === '' || navState.refConf.current.value !== navState.refNew.current.value) ? "Confirm password tidak boleh kosong dan harus sama dengan password baru" : '');
                                        // }}
                                        onSubmit={(e) => {
                                            e.preventDefault(e);
                                            navAction.confirmChangePass();
                                        }}
                                    >
                                        <div className="form-group row">
                                            <label htmlFor="pass" className="col-sm-4 col-form-label">Password lama</label>
                                            <div className="col-sm-8">
                                                <input
                                                    value={navState.oldPass}
                                                    onChange={(e) => navAction.setOldPass(e.target.value)}
                                                    type="password"
                                                    className="form-control form-control-sm"
                                                    id="pass"
                                                    required
                                                    placeholder="Password lama"
                                                    name='pass' />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="newpass" className="col-sm-4 col-form-label">Password baru</label>
                                            <div className="col-sm-8">
                                                <input
                                                    ref={navState.refNew}
                                                    value={navState.newPass}
                                                    onChange={(e) => navAction.setNewPass(e.target.value)}
                                                    type="password"
                                                    className="form-control form-control-sm"
                                                    id="newpass"
                                                    required
                                                    placeholder="Password baru"
                                                    name='newpass' />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="confirm" className="col-sm-4 col-form-label">Konfirmasi password baru</label>
                                            <div className="col-sm-8">
                                                <input
                                                    ref={navState.refConf}
                                                    value={navState.confPass}
                                                    onChange={(e) => navAction.setConfPass(e.target.value)}
                                                    type="password"
                                                    className="form-control form-control-sm"
                                                    id="confirm"
                                                    required
                                                    placeholder="Konfirmasi password baru"
                                                    name='confirm' />
                                            </div>
                                        </div>
                                        <div className='form-group row'>
                                            <div className='col text-right'>
                                                <button type='submit' className='btn btn-sm btn-primary mr-2'>Ubah</button>
                                                <button type='button' className='btn btn-sm btn-secondary'
                                                    onClick={() => navAction.setShow(false)}
                                                >Batal</button>
                                            </div>
                                        </div>
                                    </form>
                                </Modal.Body>
                            </Modal>
                            {/* <span className="dropdown-item dropdown-header">15 Notifications</span>
                            <div className="dropdown-divider"></div>
                            <a href="#" className="dropdown-item">
                                <i className="fas fa-envelope mr-2"></i> 4 new messages
                                <span className="float-right text-muted text-sm">3 mins</span>
                            </a>
                            <div className="dropdown-divider"></div>
                            <a href="#" className="dropdown-item">
                                <i className="fas fa-users mr-2"></i> 8 friend requests
                                <span className="float-right text-muted text-sm">12 hours</span>
                            </a>
                            <div className="dropdown-divider"></div>
                            <a href="#" className="dropdown-item">
                                <i className="fas fa-file mr-2"></i> 3 new reports
                                <span className="float-right text-muted text-sm">2 days</span>
                            </a>
                            <div className="dropdown-divider"></div>
                            <a href="#" className="dropdown-item dropdown-footer">See All Notifications</a> */}
                        </div>
                    </li>
                </ul>
            </nav>
            {/* <!-- /.navbar --> */}
        </>
    );
}

export default (props) => <NavProvider><Navbar {...props} /></NavProvider>;