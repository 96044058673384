import React, { createContext, useContext, useEffect, useState } from "react";
import { useModalAlert } from "../../../../components/modalAlert";
import useFetch from "../../../../hooks/useFetch";

const lsJobContext = createContext({
    lsJobState: {},
    lsJobAction: {}
});

export function LsJobProvider({ children }) {
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const { doGet, doPost } = useFetch();
    const [dataList, setDataList] = useState({});
    const [loadingGet, setLoadingGet] = useState(false);
    const [batch, setBatch] = useState('');

    async function getList() {
        try {
            setLoadingGet(true);
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/ats/list-jobsite',
                    param: {
                        batch
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDataList({ ...data });
                    setBatch(data?.batch);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get List Jobsite",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List Jobsite",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Jobsite",
                callBack: () => null
            })
        }
        setLoadingGet(false)
    }
    function onFilter(){
        getList();
    }
    useEffect(() => {
        getList();
    }, [])
    return <lsJobContext.Provider
        value={{
            lsJobState: {
                dataList,
                loadingGet,
                batch
            },
            lsJobAction: {
                setBatch,
                onFilter
            }
        }}
    >{children}</lsJobContext.Provider>
}
export default function useLsJobsite() {
    return useContext(lsJobContext);
}