import moment from 'moment';
import React from 'react';
import { StatusIcon, TableList } from '../../../components';
import { useAuth } from '../../../context';
import useDashboard from '../useDashboard';
import Skeleton from 'react-loading-skeleton';

function Index(props) {
    const { dashAction, dashState } = useDashboard();
    const { userInfo } = useAuth();
    return (
        <div className='card'>
            <div className='card-body'>
                <h3 className='text-center font-weight-bold font-italic mb-5'><u>COMPETENCY REPORT</u></h3>
                <div className='row tw-text-[12pt]'>
                    <div className='col-md-4'>
                        <div className="form-group row">
                            <div className='col-sm-4 tw-inline-flex tw-justify-start sm:tw-justify-between'>
                                <b>NAMA</b>
                                <b className='ml-1'>:</b>
                            </div>
                            <div className="col-sm-8">
                                {
                                    dashState.loadingDash ? (
                                        <Skeleton height={30} />
                                    ) : (
                                        <span>{dashState.dataHeader?.name}</span>
                                    )
                                }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className='col-sm-4 tw-inline-flex tw-justify-start sm:tw-justify-between'>
                                <b>NIK</b>
                                <b className='ml-1'>:</b>
                            </div>
                            <div className="col-sm-8">
                                {
                                    dashState.loadingDash ? (
                                        <Skeleton height={30} />
                                    ) : (
                                        <span>{dashState.dataHeader?.nik}</span>
                                    )
                                }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className='col-sm-4 tw-inline-flex tw-justify-start sm:tw-justify-between'>
                                <b>JABATAN</b>
                                <b className='ml-1'>:</b>
                            </div>
                            <div className="col-sm-8">
                                {
                                    dashState.loadingDash ? (
                                        <Skeleton height={30} />
                                    ) : (
                                        <span>{dashState.dataHeader?.job_title}</span>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className="form-group row">
                            <div className='col-sm-5 tw-inline-flex tw-justify-start sm:tw-justify-between'>
                                <b>Current Grade</b>
                                <b className='ml-1'>:</b>
                            </div>
                            <div className="col-sm-6">
                                {
                                    dashState.loadingDash ? (
                                        <Skeleton height={30} />
                                    ) : (
                                        <span>{dashState.dataHeader?.grade}</span>
                                    )
                                }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className='col-sm-5 tw-inline-flex tw-justify-start sm:tw-justify-between'>
                                <b>Current Point</b>
                                <b className='ml-1'>:</b>
                            </div>
                            <div className="col-sm-6">
                                {
                                    dashState.loadingDash ? (
                                        <Skeleton height={30} />
                                    ) : (
                                        <span>{dashState.dataHeader?.current_point}</span>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className="form-group row">
                            <div className='col-sm-4 tw-inline-flex tw-justify-start sm:tw-justify-between'>
                                <b>Jobsite</b>
                                <b className='ml-1'>:</b>
                            </div>
                            <div className="col-sm-8">
                                {
                                    dashState.loadingDash ? (
                                        <Skeleton height={30} />
                                    ) : (
                                        <span>{dashState.dataHeader?.pers_area}</span>
                                    )
                                }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className='col-sm-4 tw-inline-flex tw-justify-start sm:tw-justify-between'>
                                <b>Section</b>
                                <b className='ml-1'>:</b>
                            </div>
                            <div className="col-sm-8">
                                {
                                    dashState.loadingDash ? (
                                        <Skeleton height={30} />
                                    ) : (
                                        <span>{dashState.dataHeader?.section}</span>
                                    )
                                }
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className='col-sm-4 tw-inline-flex tw-justify-start sm:tw-justify-between'>
                                <b>Join Date</b>
                                <b className='ml-1'>:</b>
                            </div>
                            <div className="col-sm-8">
                                {
                                    dashState.loadingDash ? (
                                        <Skeleton height={30} />
                                    ) : (
                                        <span>{dashState.dataHeader?.join_date ? moment(dashState.dataHeader?.join_date).format('DD/MM/YYYY') : "-"}</span>
                                    )
                                }
                            </div>
                        </div>
                        {/* <div className='row'>
                            <span className='col-md-4 font-weight-bold'>Jobsite<span className='d-none d-md-inline d-lg-inline float-right'>:</span></span>
                            <span className='col-md-8 font-weight-bold'>{dashState.dataHeader?.pers_area}</span>
                        </div>
                        <div className='row'>
                            <span className='col-md-4 font-weight-bold'>Section<span className='d-none d-md-inline d-lg-inline float-right'>:</span></span>
                            <span className='col-md-8 font-weight-bold'>{dashState.dataHeader?.section}</span>
                        </div>
                        <div className='row'>
                            <span className='col-md-4 font-weight-bold'>Join Date<span className='d-none d-md-inline d-lg-inline float-right'>:</span></span>
                            <span className='col-md-8 font-weight-bold'>{dashState.dataHeader?.join_date ? moment(dashState.dataHeader?.join_date).format('DD/MM/YYYY') : "-"}</span>
                        </div> */}
                    </div>
                </div>

                <div className='tw-w-full tw-mt-10 tw-p-4 tw-border-success tw-rounded-md tw-pb-5'>
                    <div className='tw-w-full tw-p-0 tw-mb-5' style={{ borderBottom:"rgb(8 166 74 / var(--tw-border-opacity)) 1px solid" }}>
                        <div className='tw-flex tw-space-x-2 tw-m-0' >
                            <div onClick={()=>{
                                dashAction.setTabActive(1)
                            }} className=' tw-border-b tw-border-b-success tw-px-5 tw-pt-2 tw-cursor-pointer tw-pb-3'
                                style={{
                                ...( dashState.tabActive === 1 ? { borderBottom: "rgb(8 166 74 / var(--tw-border-opacity)) 2px solid"} : {})
                                }}
                            >IDP</div>
                            <div
                                onClick={()=>{
                                    dashAction.setTabActive(2)
                                }}
                                className=' tw-border-b tw-border-b-success tw-px-5 tw-pt-2 tw-pb-3 tw-cursor-pointer'style={{
                                    ...( dashState.tabActive === 2 ? { borderBottom: "rgb(8 166 74 / var(--tw-border-opacity)) 2px solid"} : {})
                                }}
                            >Training Non IDP</div>

                            <div
                                onClick={()=>{
                                    dashAction.setTabActive(3)
                                }}
                                className=' tw-border-b tw-border-b-success tw-px-5 tw-pt-2 tw-pb-3 tw-cursor-pointer'style={{
                                    ...( dashState.tabActive === 3 ? { borderBottom: "rgb(8 166 74 / var(--tw-border-opacity)) 2px solid"} : {})
                                }}
                            >Assessment Non IDP</div>
                        </div>
                    </div>
                    <div>
                        {
                           dashState.tabActive === 1 ? <TableList
                                curPage={1}
                                minHeight="450px"
                                isLoading={dashState.loadingDash}
                                maxHeight="500px"
                                navtable={"TABLE 1"}
                                // onChangeShow={rekAction.changeShow}
                                // onNextPage={rekAction.nextPage}
                                // onPrevPage={rekAction.prevPage}
                                withActions={false}
                                withCard={false}
                                actionItems={[]}
                                pagination={false}
                                columns={[
                                    {
                                        name: '#',
                                        field: '#',
                                        maxWidth: '50px',
                                    },
                                    {
                                        name: 'Nama Kompetensi',
                                        field: 'competency_name',
                                        maxWidth: '250px',
                                        onRender: (item) => (
                                            <a href='#'
                                                className='tw-text-[12pt]'
                                                onClick={() => dashAction.toDetailPage({
                                                    "nik": dashState.pageState.dataPage?.nik,
                                                    "competency_id": item?.competency_id,
                                                    "competency_name": item?.competency_name,
                                                    "status": item?.status
                                                })}
                                            >
                                                {
                                                    item?.competency_name
                                                }
                                            </a>
                                        )
                                    },
                                    {
                                        name: 'Status',
                                        field: 'status',
                                        maxWidth: '150px',
                                        onRender: ({ status }) => (
                                            <StatusIcon status={status} />
                                        )
                                    },
                                    {
                                        name: '#',
                                        field: 'no_right',
                                        maxWidth: '50px',
                                    },
                                    {
                                        name: 'Nama Kompetensi',
                                        field: 'competency_name_right',
                                        maxWidth: '250px',
                                        onRender: (item) => (
                                            <a href='#'
                                                className='tw-text-[12pt]'
                                                onClick={() => dashAction.toDetailPage({
                                                    "nik": dashState.pageState?.dataPage?.nik,
                                                    "competency_id": item?.competency_id_right,
                                                    "competency_name": item?.competency_name_right,
                                                    "status": item?.status_right
                                                })}
                                            >
                                                {
                                                    item?.competency_name_right
                                                }
                                            </a>
                                        )
                                    },
                                    {
                                        name: 'Status',
                                        field: 'status_right',
                                        maxWidth: '150px',
                                        onRender: ({ status_right }) => (
                                            <StatusIcon status={status_right} />
                                        )
                                    },
                                ]}
                                data={[...( dashState.dataDash  || [])]}
                            />
                                :
                                dashState.tabActive === 2 ? <TableList
                                curPage={1}
                                minHeight="450px"
                                isLoading={dashState.loadingDash}
                                maxHeight="500px"
                                navtable={"TABLE 1"}
                                // onChangeShow={rekAction.changeShow}
                                // onNextPage={rekAction.nextPage}
                                // onPrevPage={rekAction.prevPage}
                                withActions={false}
                                withCard={false}
                                actionItems={[]}
                                pagination={false}
                                columns={[
                                    {
                                        name: '#',
                                        field: '#',
                                        maxWidth: '50px',
                                    },
                                    {
                                        name: 'NIK',
                                        field: 'nik',
                                    },
                                    {
                                        name: 'Training Name',
                                        field: 'training_name',
                                    },
                                    {
                                        name: 'Trainer NIK',
                                        field: 'trainer_nik',
                                    },
                                    {
                                        name: 'Trainer Name',
                                        field: 'trainer_name',
                                    },
                                    {
                                        name: 'Tipe',
                                        field: 'type',
                                    },
                                    {
                                        name: 'Score',
                                        field: 'score',
                                    },

                                    {
                                        name: 'Batch',
                                        field: 'batch',
                                    },
                                    {
                                        name: 'Jobsite Code',
                                        field: 'pers_area_code',
                                    },
                                ]}
                                data={  dashState.dataNonIDP || []}
                            /> :
                            <TableList
                                curPage={1}
                                minHeight="450px"
                                isLoading={dashState.loadingDash}
                                maxHeight="500px"
                                navtable={"TABLE 1"}
                                // onChangeShow={rekAction.changeShow}
                                // onNextPage={rekAction.nextPage}
                                // onPrevPage={rekAction.prevPage}
                                withActions={false}
                                withCard={false}
                                actionItems={[]}
                                pagination={false}
                                columns={[
                                    {
                                        name: '#',
                                        field: '#',
                                        maxWidth: '50px',
                                    },
                                    {
                                        name: 'NIK',
                                        field: 'nik',
                                    },
                                    {
                                        name: 'Assessment Name',
                                        field: 'assessment_name',
                                    },
                                    {
                                        name: 'Assessor NIK',
                                        field: 'assessor_nik',
                                    },
                                    {
                                        name: 'Assessor Name',
                                        field: 'assessor_name',
                                    },
                                    {
                                        name: 'Tipe',
                                        field: 'type',
                                    },
                                    {
                                        name: 'Result',
                                        field: 'score',
                                    },

                                    {
                                        name: 'Batch',
                                        field: 'batch',
                                    },
                                    {
                                        name: 'Jobsite Code',
                                        field: 'pers_area_code',
                                    },
                                ]}
                                data={  dashState.dataAssesmentNonIDP || []}
                            />
                        }
                    </div>

                </div>


                {
                    dashState.role_id !== 3 && <button className='btn btn-sm btn-secondary mt-3' onClick={() => dashAction.backOnclik()}>Back</button>
                }
            </div>
        </div >
    );
}

export default Index;
