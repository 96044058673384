import React, { createContext, useContext, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useModalAlert } from "../../../components/modalAlert";

const context = createContext({
    dwnldDtEmployeeAction: {},
    dwnldDtEmployeeState: {}
});

export function DwnldDataEmployeeProvider({ children }) {
    const { modalAction } = useModalAlert();
    const {
        closeLoading,
        setDataAlert,
    } = modalAction;    

    const { doGet, doPost } = useFetch();
    const [dataList, setDataList] = useState([]);
    const [loadingGet, setLoadingGet] = useState(false);
    const [batch, setBatch] = useState('');

    const [jobsite_code, setJobsite_code] = useState('');
    const [role_id, setRole_id] = useState('');
    const [job_type, setJob_type] = useState('');

    async function getDataList() {
        setLoadingGet(true)
        try {
            const { status, payload, statusText } = await doPost({
                url: '/api/download-data/download-employee',
                data: {
                    job_type,
                    jobsite_code,
                    role_id
                }
            });
            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = [], code, msg } = payload;
                if (code === "00") {
                    setDataList(data.map(d => ({ ...d, function_: d.function })) || []);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Download Job Assignment",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Download Job Assignment",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Download Demografi Karyawan",
                callBack: () => null
            })
        }
        setLoadingGet(false)
    }

    const[dataJobsite, setDataJobsite] = useState([]);
    async function getAllJobsite() {
        try {
            const { status, statusText, payload } = await doGet({
                url: '/api/list-all-jobsite',
            });
            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDataJobsite(data?.jobsites);
                    setJobsite_code(data?.jobsites[0]?.jobsite_code);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get All Jobsite",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get All Jobsite",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get All Jobsite",
                callBack: () => null
            })
        }
    }

    const [filters, setFilter] = useState({});
    async function getListFilter() {
        try {
            const { status, statusText, payload } = await doGet({
                url: '/api/download-data/filters-download-employee',
            });
            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    let jobTypesFilter = data.jobTypesTrainer;
                    setFilter({ ...data, jobTypesFilter });
                    setRole_id(data.roles[0]?.id);
                    setJob_type(jobTypesFilter[0]);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Filters Donwload Employe",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Filters Donwload Employe",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Filters Donwload Employe",
                callBack: () => null
            })
        }
    }

    function onFilter() {
        getDataList();
    }

    function onChangeRoleId(val){
        let jobTypesFilter = val === 2 ? filters.jobTypesTrainer : filters.jobTypesEmployee;
        setFilter(s => ({ ...s, jobTypesFilter }) );
        setJob_type(jobTypesFilter[0])
        setRole_id(val);
    }

    useEffect(function () {
        getAllJobsite();
        getListFilter();
    }, []);

    return <context.Provider
        value={{
            dwnldDtEmployeeAction: {
                onFilter,
                setJobsite_code,
                setBatch,
                setJob_type,
                setRole_id,
                onChangeRoleId,
                getDataList
            },
            dwnldDtEmployeeState: {
                jobsite_code,
                job_type,
                role_id,
                filters,
                batch,
                dataJobsite,
                dataList,
                loadingGet
            }
        }}
    >{children}</context.Provider>
}

export default function useDwnldDataEmployee() {
    return useContext(context);
}