import React from 'react';
import useDwnldDataHm from './useDwnldDataHm';

const TableToHTML = () => {
    const { dwnldDtHmState } = useDwnldDataHm();
    return (
        <table className="w-100 f-10" id="table-to-xls" style={{ display: "none" }}>
            <thead>
                <tr>
                    <th>#</th>
                    <th>NIK</th>
                    <th>Nama Karyawan</th>
                    <th>Competency Name</th>
                    <th>Course Name</th>
                    <th>HM Total</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {
                    (dwnldDtHmState.dataList || []).map(({
                        nik,
                        name,
                        competency_name,
                        course_name,
                        total_hm,
                        status,
                    }, i) => (
                        <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{nik}</td>
                            <td>{name}</td>
                            <td>{competency_name}</td>
                            <td>{course_name}</td>
                            <td>{total_hm}</td>
                            <td>{status}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    );
}

export default TableToHTML;