import { useEffect } from 'react';

export default (ref, refTrigger, callback, typeListener = 'mousedown') => {
    return useEffect(() => {
        const handleOutside = (e) => {
            if (ref && !ref?.contains(e.target) && !refTrigger?.contains(e.target)) {
                callback();
            }
        };

        document.addEventListener(typeListener, handleOutside);

        return () => {
            document.removeEventListener(typeListener, handleOutside);
        };
    });
};
