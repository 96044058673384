import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { BiCheckCircle, BiXCircle } from "react-icons/bi";
import { useHistory, useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { StatusIcon, TableList } from '../../../../components';
import useIdpApprovalD, { IdpApprovalDProvider } from './useIdpApprovalDetail';

function IdpApprovalDetail(props) {
    const { idpAppDAction, idpAppDState } = useIdpApprovalD();
    const history = useHistory()
    const data = useLocation()?.state;
    useEffect(function () {
        // if (!data?.nik || !data?.name) history.push("/idp-online/approval")
    }, [data])
    return (
        <div className='row'>
            <div className='col'>
                <div className='row'>
                    <div className='col-6'>
                        <section className="content-header">
                            <div className="container-fluid">
                                <h4>IDP Online</h4>
                                <h6>IDP Approval Detail</h6>
                            </div>
                        </section>
                    </div>
                    <div className='col-6'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-end">
                                <li className="breadcrumb-item" aria-current="page">
                                    <Link to="/idp-online/approval"> IDP Approval </Link></li>
                                <li className="breadcrumb-item active" aria-current="page">IDP Approval Detil</li>
                            </ol>
                        </nav>
                    </div>
                </div>


                {/* <div className='card'>
                    <div className='card-body'>

                    </div>
                </div> */}
                <div className='card'>
                    <div className='card-header'>
                        <div className='card-title'>
                            <h4>
                                {idpAppDState?.trainee?.nik} - {idpAppDState?.trainee?.name}
                            </h4>
                        </div>
                    </div>
                    <div className='card-body'>
                        <div className='d-block text-md-right'>
                            {
                                ([...(idpAppDState.defaultSelection || [])].length > 0) &&
                                <>
                                    <button className='btn btn-sm btn-success mx-1 d-inline-flex mb-1'
                                        onClick={() => {
                                            // idpAppDAction.setShowApprove(true);
                                            idpAppDAction.approve([...(idpAppDState.defaultSelection || [])]);
                                            // idpAppDAction.setIdApprove(item?.id);
                                        }}
                                    >
                                        <BiCheckCircle className='size-icon-approval my-auto mx-1' />
                                        Approve
                                    </button>
                                    <button className='btn btn-sm btn-danger mx-1 d-inline-flex mb-1'
                                        onClick={() => {
                                            idpAppDAction.setShowReject(true)
                                            idpAppDAction.setIdReject([...(idpAppDState.defaultSelection || [])]);
                                        }}
                                    >
                                        <BiXCircle className='size-icon-approval my-auto mx-1' />
                                        Decline
                                    </button>
                                </>
                            }
                        </div>
                        <TableList
                            curPage={1}
                            isLoading={idpAppDState.loadingGet}
                            withCard={false}
                            minHeight="300px"
                            maxHeight="500px"
                            navtable={"TABLE 1"}
                            pagination={false}
                            selectionMode={1}
                            onSelection={(e, { status }) => {
                                return (status !== "approved" && status !== "declined" && status !== "need revise") && idpAppDAction.setdefaultSelection(e)
                            }}
                            fieldSelectionKey="id"
                            defaultSelection={idpAppDState.defaultSelection}
                            filterSelection={({ status }) => {
                                return status === "approved" || status === "declined" || status === "need revise"
                            }}
                            // onChangeShow={rekAction.changeShow}
                            // onNextPage={rekAction.nextPage}
                            // onPrevPage={rekAction.prevPage}
                            columns={[
                                {
                                    name: "#",
                                    field: '#'
                                },
                                // {
                                //     name: "Request By",
                                //     field: "request_by"
                                // },
                                {
                                    name: "Grade",
                                    field: 'grade',
                                },
                                {
                                    name: "Cluster",
                                    field: 'cluster',
                                },
                                {
                                    name: "Group Competency",
                                    field: 'competency_group',
                                    minWidth: '100px',
                                    ordering: true,
                                },
                                {
                                    name: "Competency Name",
                                    field: 'competency_name',
                                    minWidth: '200px',
                                    ordering: true,
                                },
                                {
                                    name: "Point",
                                    field: 'point',
                                    ordering: true,
                                },
                                {
                                    name: "Course Name",
                                    field: 'course_name',
                                    minWidth: '172px'
                                },
                                {
                                    name: "Status",
                                    field: 'status',
                                    minWidth: '100px',
                                    onRender: (item, i) => (
                                        <div>
                                            <StatusIcon status={item?.status} />
                                            {/* {
                                                item?.status === 'declined' &&
                                                <span className="badge badge-danger">{(item?.status || '').toUpperCase()}</span>
                                            }
                                            {
                                                item?.status === 'approved' &&
                                                <span className="badge badge-success">{(item?.status || '').toUpperCase()}</span>
                                            }
                                            {
                                                item?.status === 'submitted' &&
                                                <span className="badge badge-primary">{(item?.status || '').toUpperCase()}</span>
                                            }
                                            {
                                                item?.status === 'need revise' &&
                                                <span className="badge badge-warning">{('Revision').toUpperCase()}</span>
                                            } */}
                                        </div>
                                    )
                                },
                                {
                                    name: "Action",
                                    minWidth: '223px',
                                    className: "text-right",
                                    onRender: (item, i) => {
                                        return (
                                            <div>
                                                {
                                                    (item?.status === 'submitted' || item?.status === 'submit') &&
                                                    <>
                                                        <button className='btn btn-sm btn-success mx-1 d-inline-flex mb-1'
                                                            onClick={() => {
                                                                // idpAppDAction.setShowApprove(true);
                                                                idpAppDAction.approve([item?.id]);
                                                                // idpAppDAction.setIdApprove(item?.id);
                                                            }}
                                                        >
                                                            <BiCheckCircle className='size-icon-approval my-auto mx-1' />
                                                            Approve
                                                        </button>
                                                        <button className='btn btn-sm btn-danger mx-1 d-inline-flex mb-1'
                                                            onClick={() => {
                                                                idpAppDAction.setShowReject(true)
                                                                idpAppDAction.setIdReject([item?.id]);
                                                            }}
                                                        >
                                                            <BiXCircle className='size-icon-approval my-auto mx-1' />
                                                            Decline
                                                        </button>
                                                    </>
                                                }
                                                {
                                                    (item?.status === 'approved') && <>
                                                        <button className='btn btn-sm btn-warning mx-1 d-inline-flex mb-1'
                                                            onClick={() => {
                                                                idpAppDAction.setShowRevise(true);
                                                                // idpAppDAction.revision([item?.id]);
                                                                idpAppDAction.setIdRevise([item?.id]);
                                                            }}
                                                        >
                                                            {/* <BiCheckCircle className='size-icon-approval my-auto mx-1' /> */}
                                                            Revision
                                                        </button>
                                                    </>
                                                }
                                            </div>
                                        )
                                    }
                                }
                            ]}
                            data={[...idpAppDState.dataIDP]}
                        />
                    </div>
                    <div className='card-footer'>
                        <Link to="/idp-online/approval">
                            <button className='btn btn-sm btn-secondary my-2'>Back</button>
                        </Link>
                    </div>
                </div>
            </div>
            {/* <Modal
                show={idpAppDState.showApprove}
                onHide={() => idpAppDAction.setShowApprove(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Approve</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault(e);
                            idpAppDAction.approve(idpAppDState.idApprove);
                        }}
                    >
                        <textarea
                            value={idpAppDState.noteA}
                            onChange={(e) => idpAppDAction.setNoteA(e.target.value)}
                            row={5}
                            required
                            className='form-control form-control-sm'
                        ></textarea>
                        <div className='text-right mt-3'>
                            <button
                                type='button'
                                onClick={() => idpAppDAction.setShowApprove(false)}
                                className='btn btn-sm btn-secondary mr-1'>Batal</button>
                            <button
                                type='submit'
                                className='btn btn-sm btn-success'>Approve</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal> */}
            <Modal
                show={idpAppDState.showRevise}
                backdropClassName='bg-transparent'
                onHide={() => idpAppDAction.setShowRevise(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Revision</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault(e);
                            idpAppDAction.revision(idpAppDState.idRevise);
                        }}
                    >
                        <textarea
                            value={idpAppDState.noteR}
                            onChange={(e) => idpAppDAction.setNoteR(e.target.value)}
                            row={5}
                            required
                            className='form-control form-control-sm'
                        ></textarea>
                        <div className='text-right mt-3'>
                            <button
                                type='button'
                                onClick={() => idpAppDAction.setShowRevise(false)}
                                className='btn btn-sm btn-secondary mr-1'>Batal</button>
                            <button
                                type='submit'
                                className='btn btn-sm btn-danger'>Revision</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
            <Modal
                show={idpAppDState.showReject}
                backdropClassName='bg-transparent'
                onHide={() => idpAppDAction.setShowReject(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Decline</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault(e);
                            idpAppDAction.reject(idpAppDState.idReject);
                        }}
                    >
                        <textarea
                            value={idpAppDState.noteR}
                            onChange={(e) => idpAppDAction.setNoteR(e.target.value)}
                            row={5}
                            required
                            className='form-control form-control-sm'
                        ></textarea>
                        <div className='text-right mt-3'>
                            <button
                                type='button'
                                onClick={() => idpAppDAction.setShowReject(false)}
                                className='btn btn-sm btn-secondary mr-1'>Batal</button>
                            <button
                                type='submit'
                                className='btn btn-sm btn-danger'>Decline</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default (props) => <IdpApprovalDProvider><IdpApprovalDetail {...props} /></IdpApprovalDProvider>;