
function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }
function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }
function base64(s) {
    return window.btoa(unescape(encodeURIComponent(s)));
}
function format(s, c) {
    return s.replace(/{(\w+)}/g, function (m, p) {
        return c[p];
    });
}
function htmlTableToExcel(props) {
    _classCallCheck(this, htmlTableToExcel);

    var _this = _possibleConstructorReturn(this, (htmlTableToExcel.__proto__ || Object.getPrototypeOf(htmlTableToExcel)).call(this, props));

    _this.handleDownload = _this.handleDownload.bind(_this);
    return _this;
}


export default function (id, nameFile, sheet) {
    if (!document) {
        if (process.env.NODE_ENV !== 'production') {
            // console.error('Failed to access document object');
        }

        return null;
    }

    if (document.getElementById(id).nodeType !== 1 || document.getElementById(id).nodeName !== 'TABLE') {
        if (process.env.NODE_ENV !== 'production') {
            // console.error('Provided table property is not html table element');
        }

        return null;
    }

    const table = document.getElementById(id).outerHTML;
    const sheetName = String(sheet);
    const filename = `${String(nameFile)}.xls`;

    const uri = 'data:application/vnd.ms-excel;base64,';
    const template =
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-mic' +
        'rosoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta cha' +
        'rset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:Exce' +
        'lWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/>' +
        '</x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></' +
        'xml><![endif]--></head><body>{table}</body></html>';

    const context = {
        worksheet: sheet || 'Worksheet',
        table,
    };

    // If IE11
    if (window.navigator.msSaveOrOpenBlob) {
        const fileData = [
            `${'<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-mic' + 'rosoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta cha' + 'rset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:Exce' + 'lWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/>' + '</x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></' + 'xml><![endif]--></head><body>'}${table}</body></html>`,
        ];
        const blobObject = new Blob(fileData);
        document.getElementById('react-html-table-to-excel').click()(() => {
            window.navigator.msSaveOrOpenBlob(blobObject, filename);
        });

        return true;
    }

    const element = window.document.createElement('a');
    element.href =
        uri +
        base64(
            format(template, context),
        );
    element.download = filename;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);

    return true;
}