import React from 'react';
import { SelectSearch, StatusIcon, TableList } from '../../../components';
import DropDownList from './components/dropDown';
import useIdpRegister, { RegProvider } from './useIdpRegister';
import { BiPlusMedical } from "react-icons/bi";
function IdprPage() {
    const { regState, regAction } = useIdpRegister();
    return (
        <>
            <div className='row'>
                <div className='col-6'>
                    <section className="content-header">
                        <div className="container-fluid">
                            <h4>IDP Online</h4>
                            <h6>IDP Register</h6>
                        </div>
                    </section>
                </div>
                <div className='col-6'>

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end">
                            <li className="breadcrumb-item active" aria-current="page">IDP Register</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='row'>

                                <div className='col-md-3'>
                                    <div className='form-group'>
                                        <SelectSearch
                                            zIndex={2}
                                            placeholder={regState.loadingGrade ? 'Loading ... ' : 'Select Grade'}
                                            value={regState.filter?.filter_grade}
                                            onChange={({ value }) => regAction.setFilter(prevState => ({ ...prevState, filter_grade: value }))}
                                            options={(regState.dataComp?.dropdowGrade || []).map((op) => ({
                                                key: op,
                                                display: op
                                            }))}
                                        />
                                    </div>
                                </div>

                                <div className='col-md-3'>
                                    <div className='form-group'>
                                        <SelectSearch
                                            zIndex={2}
                                            placeholder={regState.loadingCluster ? 'Loading ... ' : 'Select Cluster'}
                                            value={regState.filter?.filter_cluster}
                                            onChange={({ value }) => regAction.setFilter(prevState => ({ ...prevState, filter_cluster: value }))}
                                            options={(regState.dataComp?.dropdowCluster || []).map((op) => ({
                                                key: op,
                                                display: op
                                            }))}
                                        />
                                    </div>
                                </div>

                                <div className='col-md-3'>
                                    <div className='form-group'>
                                        <SelectSearch
                                            zIndex={2}
                                            placeholder='Select Group Competency'
                                            value={regState.filter?.filter_group}
                                            onChange={({ value }) => regAction.setFilter(prevState => ({ ...prevState, filter_group: value }))}
                                            options={(regState.dataComp?.dropdowGroup || []).map((op) => ({
                                                key: op,
                                                display: op
                                            }))}
                                        />
                                    </div>
                                </div>
{/* 
                                <div className='col-md-3'>
                                    <SelectSearch
                                        zIndex={2}
                                        placeholder=''
                                        value={regState.filter?.type}
                                        onChange={({ value }) => regAction.setFilter(prevState => ({ ...prevState, type: value }))}
                                        options={[
                                            { key: "Mechanic", display: "Mechanic" },
                                            { key: "Operator", display: "Operator" },
                                            { key: "Pengawas", display: "Pengawas" }
                                        ]}
                                    />
                                </div> */}

                                <div className='col-md-3'>
                                    <div className='form-group'>
                                        <input className='form-control '
                                            value={regState.filter?.filter_name}
                                            onChange={(e) => regAction.setFilter(prevState => ({ ...prevState, filter_name: e.target.value }))}
                                            placeholder='Search Competency Name'
                                        />
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <button onClick={regAction.doFilter} className='btn btn-primary'>
                                        Search
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <TableList
                                withCard={false}
                                // onClickRows={(item, key)=> console.log(item, key)}
                                isLoading={regState.loadingGet}
                                data={regState.dataComp.listData}
                                onChangeShow={regAction.changeShow}
                                onNextPage={regAction.nextPage}
                                onPrevPage={regAction.onPrevPage}
                                defaultShow={regState.pagination?.show}
                                curPage={regState.pagination?.page}
                                maxPage={regState.dataComp?.totalPage || 0}
                                orderData={{
                                    order_by: regState.pagination.order_by,
                                    order_type: regState.pagination.order_type
                                }}
                                onOrdering={regAction.onOrdering}
                                withActions={false}
                                totalData={regState.dataComp?.totalData || 0}
                                // actionType={1}
                                actionItems={[
                                    {
                                        name: 'Pilih competency',
                                        onClick: function (item) {
                                            regAction.pilihCompetency(item)
                                        }
                                    }
                                ]}
                                columns={
                                    [
                                        {
                                            name: "#",
                                            field: '#'
                                        },
                                        {
                                            name: "Grade",
                                            field: 'grade',
                                            ordering: true,
                                        },
                                        {
                                            name: "Cluster",
                                            field: 'cluster',
                                            ordering: true,
                                        },
                                        {
                                            name: "Group Competency",
                                            field: 'group',
                                            minWidth: '220px',
                                            ordering: true,
                                        },
                                        {
                                            name: "Competency Name",
                                            field: 'name',
                                            minWidth: '220px',
                                            ordering: true,
                                        },
                                        {
                                            name: "Point",
                                            field: 'point',
                                            ordering: true,
                                        },
                                        {
                                            name: "Course Name",
                                            field: 'grade',
                                            minWidth: '220px',
                                            onRender: ({ name, type, id, cluster, group }, i) => {
                                                const value = (regState.dropData || []).find((c) => {
                                                    return parseInt(c.id) === parseInt(id)
                                                })?.course_id
                                                return (
                                                    <DropDownList
                                                        competency_name={name}
                                                        group={group}
                                                        cluster={cluster}
                                                        competency_id={id}
                                                        type={type}
                                                        value={value || ""}
                                                        onChange={
                                                            function (data) {
                                                                regAction.changeCourse({ ...data, id })
                                                            }
                                                        }
                                                    />
                                                )
                                            }
                                        },
                                        {
                                            name: "",
                                            onRender: (item, i) => {
                                                return (
                                                    <div className='tw-flex tw-h-[38px] tw-items-center'>
                                                        <button className='btn btn-sm btn-outline-primary'
                                                            onClick={() => regAction.pilihCompetency(item)}
                                                        >
                                                            <BiPlusMedical />
                                                        </button>
                                                    </div>
                                                )
                                            }
                                        }
                                    ]
                                }
                            />
                        </div>
                    </div>

                    <div className='row ' style={{
                        marginTop: 60
                    }}>
                        <div className='col'>
                            <TableList
                                withCard={false}
                                pagination={false}
                                withActions={false}
                                isLoading={regState.loadingDetail}
                                data={regState.formData || []}
                                columns={
                                    [
                                        {
                                            name: "#",
                                            field: '#'
                                        },
                                        {
                                            name: "Grade",
                                            field: 'grade'
                                        },
                                        {
                                            name: "Cluster",
                                            field: 'cluster'
                                        },
                                        {
                                            name: "Group Competency",
                                            field: 'competency_group'
                                        },
                                        {
                                            name: "Competency Name",
                                            field: 'competency_name'
                                        },
                                        {
                                            name: "Point",
                                            field: 'point'
                                        },
                                        {
                                            name: "Course Name",
                                            field: 'course_name'
                                        },
                                        {
                                            name: 'Status',
                                            field: 'status',
                                            // maxWidth: '150px',
                                            onRender: ({ status }) => {
                                                return <StatusIcon status={status} /> //<span className={
                                                //     `badge badge-pill badge-${status === 'draft' ? 'warning' :
                                                //         status === 'submitted' || status === 'submit' ? 'success' :
                                                //             status === 'approved' ? 'primary' :
                                                //                 'danger'
                                                //     }`
                                                // }>
                                                //     {status}
                                                // </span>
                                            }
                                        },
                                        {
                                            name: "",
                                            field: 'course_name',
                                            onRender: ({ course_id, competency_id, id, status }) => {

                                                // if (status != "draft" && status != "declined" && status != "") return <></>
                                                if (status == "approved" || status == "submitted" || status == "remedial") return <></>
                                                return (<button className='btn btn-sm btn-outline-danger'
                                                    onClick={() => regAction.deleteFormData({
                                                        course_id, competency_id, id
                                                    })}
                                                >
                                                    X
                                                </button>)
                                            }
                                        },
                                    ]
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className='card-footer'>
                    <div className='row'>
                        <div className='col'>
                            <button
                                onClick={() => regAction.confirmSubmit("submitted")}
                                className='float-right btn btn-primary ml-3'>
                                Submit
                            </button>

                            <button

                                onClick={() => regAction.confirmSubmit("draft")}
                                className='float-right btn btn-default ml-3'>
                                Save as Draft
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default () => <RegProvider><IdprPage /></RegProvider>
