import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { useModalAlert } from "../../components/modalAlert";
import useFetch from "../../hooks/useFetch";
import * as pages from "./pages";

const attContext = createContext({
    attAction: {},
    attState: {},
});
let pageNames = Object.keys(pages);

export const AttProvider = ({ children }) => {
    const { doGet, doPost, wait } = useFetch();
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading,
    } = modalAction;
    const [pageStatus, setPageStatus] = useState({
        rendered: "ListJobs",
        paramState: {},
        isLoading: true,
        title: "List Jobsite",
    });
    const [historyPage, setHistoryPage] = useState({
        rendered: "ListJobs",
        paramState: {},
        isLoading: true,
        title: "List Jobsite",
    });

    const [dataJobsite, setDataJobsite] = useState([]);
    const getJobsites = useCallback(
        async function () {
            setPageStatus((v) => ({ ...v, isLoading: true }));

            try {
                const { status, statusText, payload } = await doGet({
                    url: "/api/list-all-jobsite",
                });
                if (status === 200) {
                    const { data = {}, code, msg } = payload;
                    if (code === "00") {
                        setDataJobsite(data?.jobsites);
                    } else
                        setDataAlert({
                            show: true,
                            body: msg,
                            header: "Get All Jobsite",
                            callBack: () => null,
                        });
                } else
                    setDataAlert({
                        show: true,
                        body: statusText,
                        header: "Get All Jobsite",
                        callBack: () => null,
                    });
            } catch (error) {
                setDataAlert({
                    show: true,
                    body: error.toString(),
                    header: "error",
                    callBack: () => null,
                });
            }
            setPageStatus((v) => ({ ...v, isLoading: false }));
        },
        [
            setDataAlert,
            closeAlert,
            openLoading,
            closeLoading,
            doGet,
            setPageStatus,
        ]
    );

    const [detilData, setDetilData] = useState({
        totalData: 0,
        totalPage: 0,
    });
    const [pagination, setPagination] = useState({
        page: 1,
        show: 10,
        order_by: "",
        order_type: "",
        // totalData:0, totalPage:0
    });
    const [filter, setFilter] = useState({
        filter_nik: "",
        filter_name: "",
        jobsite: "",
    });
    const [dataList, setDataList] = useState([])
    const getDataList = useCallback(
        async function () {
            setPageStatus((v) => ({ ...v, isLoading: true }));
            try {
                const { status, statusText, payload } = await doGet({
                    param: {
                        row_in_page: pagination.show,
                        page: pagination.page,
                        ...filter,
                        ...pagination,
                    },
                    url: "/api/dashboard/trainee-lcofficer",
                });
                if (status === 401) {
                    closeLoading();
                } else if (status === 200) {
                    const { data = {}, code, msg } = payload;
                    // console.log(data)

                    if (code === "00") {
                        const {
                            totalData = 0,
                            totalPage = 0,
                            listData = [],
                        } = data;
                        setDataList(listData);
                        setDetilData({ totalData, totalPage });
                        // setDataList({ ...data })
                        // setData
                    } else
                        setDataAlert({
                            show: true,
                            body: msg,
                            header: "Info",
                            callBack: () => null,
                        });
                } else
                    setDataAlert({
                        show: true,
                        body: statusText,
                        header: "Info",
                        callBack: () => null,
                    });
            } catch (error) {
                setDataAlert({
                    show: true,
                    body: error.toString(),
                    header: "Error",
                    callBack: () => null,
                });
            }
            setPageStatus((v) => ({ ...v, isLoading: false }));
        },
        [pagination, setDataList]
    );

    function toPage(pageName = "", title = "", paramState = {}) {
        try {
            if (!pageNames.some((v) => v === pageName))
                throw new Error("Page Not Found");
            setHistoryPage(pageStatus);
            setPageStatus((v) => ({
                ...v,
                paramState,
                rendered: pageName,
                title,
                isLoading: true,
            }));
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "info",
                callBack: () => null,
            });
        }
    }

    function doFilter() {
        setPagination((prevState) => ({
            ...prevState,
            page: 1,
        }));
    }
    function nextPage({ page, limit }) {
        setPagination((prevState) => ({
            ...prevState,
            page,
            show: limit,
        }));
    }
    function prevPage({ page, limit }) {
        setPagination((prevState) => ({
            ...prevState,
            page,
            show: limit,
        }));
    }
    function changeShow({ page, limit }) {
        setPagination((prevState) => ({
            ...prevState,
            page,
            show: limit,
        }));
    }

    function onOrdering({ order_by, order_type }) {
        setPagination((prevState) => ({
            ...prevState,
            order_by,
            order_type,
        }));
    }

    useEffect(
        function () {
            if (pageStatus.rendered === "ListJobs") getJobsites();
            if (pageStatus.rendered === "ListAR") {
                setFilter({
                    filter_nik: "",
                    filter_name: "",
                    jobsite: pageStatus.paramState?.jobsite_code
                });
                setPagination({
                    page: 1,
                    show: 10,
                    order_by: "",
                    order_type: "",
                });
            }
        },
        [pageStatus.rendered]
    );

    useEffect(
        function () {
            if (pageStatus.rendered === "ListAR") getDataList();
        },
        [pagination]
    );
    return (
        <attContext.Provider
            value={{
                attAction: {
                    toPage,
                    nextPage,
                    prevPage,
                    changeShow,
                    onOrdering,
                    doFilter,
                    setFilter,
                },
                attState: {
                    pageStatus,
                    historyPage,
                    dataJobsite,
                    dataList,
                    pagination,
                    detilData,
                    filter,
                },
            }}
        >
            {children}
        </attContext.Provider>
    );
};

export default () => useContext(attContext);
