import React, { createContext, useContext, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { useModalAlert } from '../components/modalAlert';
import moment from 'moment';
import jwt_decode from 'jwt-decode'
import useFetch from '../hooks/useFetch';
import { apiBaseUrl } from '../../config';
const authContext = createContext({
    initialized: false,
    authenticated: false,
    doLogin: () => null,
    doLogout: () => null,
    token: '',
    refreshToken: '',
    tokenParsed: {

    },
    tokenExp: {
        access_token_exp: null,
        refresh_token_exp: null
    },
    userInfo: {

    },
    getToken: () => null,
    loadingUserInfo: false,
    menus: [],
    setMenus: () => null
});

export const AuthProvider = ({ children }) => {

    const cookie = new Cookies();
    const [initialized, setInit] = useState(false);
    const [authenticated, setAuth] = useState(false);
    const [token, setToken] = useState(null);
    const [refreshToken, setRefreshToken] = useState(null)
    const [activity, setActivity] = useState(null)
    const [tokenParsed, setTokenParsed] = useState({});
    const [userInfo, setUserInfo] = useState({});
    const { doGet, wait, doPost } = useFetch();
    const [tokenExp, setTokenExp] = useState({
        access_token_exp: null,
        access_token_exp_date: null,
        refresh_token_exp: null
    })
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const [loadingUserInfo, setLoadingUserInfo] = useState(false)
    const [menus, setMenus] = useState([])

    // const { }= useModalAlert()
    function createListenIdle() {
        document.addEventListener("click", function () {
            setActivity(moment(Date.now()))
        })
        document.addEventListener("mouseover", function () {
            setActivity(moment(Date.now()))
        })
        document.addEventListener("keyup", function () {
            setActivity(moment(Date.now()))
        })
        // createAutoRefresh()
    }

    function stopListenIdle() {
        document.removeEventListener("*", () => {

        })
        // clearInterval(interVal);
    }

    function cleanUp() {
        stopListenIdle();
        createListenIdle();
    }

    async function getUserInfo() {
        openLoading("getting user info")
        setLoadingUserInfo(true)
        const tokenJWT = await getToken()
        // console.log(tokenJWT)
        await wait(500)
        try {
            const { status, statusText, payload } = await doGet({
                url: "/api/profile",
                token: false,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${tokenJWT}`
                }
            })
            // console.log(data)
            if (status === 401) {
                closeLoading();
                setDataAlert({
                    show: true, body: "Session login has been expired", title: `Expired Session`, callBack: () => doLogout(true)
                })
            }
            else if (status === 200) {
                const { data = {}, code } = payload;
                if (code === "00") {

                    setUserInfo(data.thisUser || {})
                } else setDataAlert({
                    show: true,
                    body: data.msg,
                    // titile: ``, 
                    header: "Get user info",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                // titile: ``, 
                header: "Get user info",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                // titile: ``, 
                header: "Get user info",
                callBack: () => null
            })
        }
        closeLoading();
        setLoadingUserInfo(false)
    }

    useEffect(function () {
        // console.log(authenticated)
        if (authenticated) getUserInfo()
    }, [authenticated])

    useEffect(function () {
        setInit(false)
        let access_token = cookie.get("access_token")
        let expires_in = cookie.get("expires_in")
        let refresh_token = cookie.get("refresh_token")
        if (access_token !== null && access_token !== "" && access_token !== undefined) {
            createLogin({ access_token, expires_in })
        } else {
            doLogout()
        }
        setInit(true)
    }, [])


    async function doLogout(expired = false) {
        let access_token = cookie.get("access_token")
        var opts = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        }
        openLoading();
        if (access_token) {
            try {
                if (expired === false) {
                    await fetch(`${apiBaseUrl}/api/logout`, opts);
                }
                openLoading("clearing routes");
                await wait(500)
                openLoading("clearing user");
                await wait(250)
                openLoading("please wait. logging out..");
                await wait(250)
                cookie.remove("access_token", { domain: document.domain });
                cookie.remove("refresh_token", { domain: document.domain });
                cookie.remove("expires_in", { domain: document.domain });
                setTokenParsed({});
                setTokenExp({
                    access_token_exp: null,
                    refresh_token_exp: null
                })
                setUserInfo({});
                setAuth(false);
                stopListenIdle();
            } catch (error) {
                setDataAlert({ show: true, body: error?.message, titile: 'Error', callBack: () => null });
            }
        }
        closeLoading();
        // window.location.href = '/login';
    }


    function createLogin({ access_token, expires_in }, refresh_token) {
        cookie.set("access_token", access_token, { domain: document.domain });
        cookie.set("expires_in", expires_in, { domain: document.domain })
        cookie.remove("refresh_token", refresh_token, { domain: document.domain });
        setToken(access_token);
        setTokenExp({
            access_token_exp_date: moment(Date.now()).add(expires_in, "minutes"),
            refresh_token_exp: null,

            access_token_exp: expires_in,
        })
        setTokenParsed(jwt_decode(access_token))
        setAuth(true);
    }
    async function doLogin(body) {
        var opts = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body
        }
        openLoading()
        try {
            var fetchResponse = await fetch(`${apiBaseUrl}/api/login`, opts)
            if (fetchResponse.status !== 200) {
                setDataAlert({ show: true, body: "Usename or pass salah", titile: ``, callBack: () => null });
                // // console.log('tidak 200');
            } else {
                fetchResponse = await fetchResponse.json();
                if (fetchResponse.code === '00') {
                    createLogin({ access_token: fetchResponse.data.access_token, expires_in: fetchResponse.data.expires_in })
                } else {
                    closeLoading();
                    setDataAlert({ show: true, body: fetchResponse.msg, titile: fetchResponse.errors, callBack: () => null });
                }
                // window.location.href = '/';
                // // console.log('Berhasil');
            }
            // cookie.set("refresh_token", "ganti_sama_refresh_token")
        } catch (error) {
            // console.log(error.toString());
        }
        // closeLoading()
    }

    async function doRefreshToken() {
        let acc_token = token;
        try {

            var fetchResponse = await fetch(`${apiBaseUrl}/api/refresh`, {
                method: "POST",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            })
            if (fetchResponse.status !== 200) {
                setDataAlert({
                    show: true, body: fetchResponse.statusText, title: `Refresh Token`, callBack: () => doLogout(true)
                })
                // return setDataAlert({
                //     show: true, body: data.msg, title: `Expired Session`, callBack: () => doLogout()
                // })
                // setDataAlert({ show: true, body: "Usename or pass salah", titile: ``, callBack: () => null });
                // // console.log('tidak 200');
            } else {
                fetchResponse = await fetchResponse.json();
                if (fetchResponse.code === '00') {
                    createLogin({
                        access_token: fetchResponse.data.access_token,
                        expires_in: fetchResponse.data.expires_in
                    })
                    acc_token = fetchResponse.data.access_token
                } else {
                    closeLoading();
                    setDataAlert({ show: true, body: fetchResponse.msg, titile: fetchResponse.errors, callBack: () => null });
                }
                // window.location.href = '/';
                // // console.log('Berhasil');
            }

        } catch (error) {
            // console.log(error.toString())
        }

        return acc_token
    }

    async function getToken() {
        let token = cookie.get("access_token")
        // console.log(moment(tokenExp.access_token_exp_date).toString())
        // console.log(moment(Date.now()).toString(), '>', moment(tokenExp.access_token_exp_date).subtract(tokenExp.access_token_exp - ((tokenExp.access_token_exp - (tokenExp.access_token_exp * 0.3))), "minutes").toString(), "if true refresh token")
        if (
            // moment(activity).add(tokenExp.access_token_exp, "second") > moment(tokenExp.access_token_exp_date) &&
            (moment(Date.now()) > moment(tokenExp.access_token_exp_date).subtract(tokenExp.access_token_exp - ((tokenExp.access_token_exp - (tokenExp.access_token_exp * 0.3))), "minutes"))
            // moment(activity).add(tokenExp.access_token_exp + 60, "second") > moment(tokenExp.access_token_exp_date) &&
            // moment(Date.now()).add(60, "second") > moment(tokenExp.access_token_exp_date)
        ) {
            token = await doRefreshToken();
        }
        return token;
    }
    return (
        <authContext.Provider value={{
            initialized,
            authenticated,
            doLogin,
            doLogout,
            token,
            refreshToken,
            tokenExp,
            tokenParsed,
            userInfo,
            getUserInfo,
            getToken,
            loadingUserInfo,
            menus,
            setMenus
        }}>
            {children}
        </authContext.Provider>
    )
}

export default function useAuth() {
    return useContext(authContext);
}