import React, { useEffect, useState } from 'react';
import useFetch from '../../../../hooks/useFetch';
import { useModalAlert } from '../../../../components/modalAlert';
import Select, { components } from 'react-select';
import { Form } from 'react-bootstrap';

const iniStateForm = {
    "nik": "",
    "job_type": "",
    "name": "",
    "level": "",
    "grade": "",
    "job_title": "",
    "job_title_id": "",
    "function": "",
    "division": "",
    "department": "",
    "section": "",
    "directorat": "",
    "pers_area_code": "",
    "pers_area_name": "",
    "poh": "",
    "nik_direct_supervisor": "",
    "direct_supervisor_name": "",
    "role_id": 3,
    "current_point": 10,
    "attended_ratio": "",
    "join_date": "",
    "maritial_status": "",
    "age": 29,
    "nik_indirect_supervisor": "",
    "indirect_supervisor_name": "",
    "type": ""
}

function FormEditor(
    {
        edit = false,
        kembaliOnclick = () => null,
        OnValidSubmit = () => null,
        defaultForm = null
    }
) {
    const [firstRender, setFirstRender] = useState(true);
    const [validated, setValidated] = useState(false);
    const { doGetMultiple } = useFetch();
    const { modalAction } = useModalAlert();
    const {
        closeLoading,
        setDataAlert,
        openLoading,
    } = modalAction;
    const [dataRoles, setDataRoles] = useState([]);
    const [dataJobtypes, setDataJobtypes] = useState([]);
    const [dataJobsites, setDataJobsites] = useState([]);
    const [dataJobTitle, setDataJobTitle] = useState([]);
    const GetOptionSources = async function () {
        try {
            openLoading("Please wait ...");
            const { roles, jobType, jobsiteCode, jobTitle } = await doGetMultiple([
                {
                    url: '/api/data-master/roles',
                    responseVar: "roles"
                },
                {
                    url: '/api/data-master/job-types',
                    responseVar: "jobType"
                },
                {
                    url: '/api/list-all-jobsite',
                    responseVar: "jobsiteCode"
                },
                {
                    url: '/api/data-master/job-titles',
                    responseVar: "jobTitle"
                },
            ])
            //handle request1
            if (roles?.status === 401) {
                closeLoading();
            }
            else if (roles?.status === 200) {
                const data = { ...roles?.payload };
                if (data?.code === "404") {
                    closeLoading();
                    return setDataAlert({
                        show: true,
                        body: data?.msg,
                        header: "Get List Roles",
                        callBack: () => null
                    });
                } else {
                    setDataRoles(data?.data);
                }
            } else setDataAlert({
                show: true,
                body: roles?.msg,
                header: "Get List Roles",
                callBack: () => null
            })

            //handle request2
            if (jobType?.status === 401) {
                closeLoading();
            }
            else if (jobType?.status === 200) {
                const data = { ...jobType?.payload };
                if (data?.code === "404") {
                    closeLoading();
                    return setDataAlert({
                        show: true,
                        body: data?.msg,
                        header: "Get List Job type",
                        callBack: () => null
                    });
                } else {
                    setDataJobtypes(data?.data);
                }
            } else setDataAlert({
                show: true,
                body: jobType?.msg,
                header: "Get List Job type",
                callBack: () => null
            })

            //handle request3
            if (jobsiteCode?.status === 401) {
                closeLoading();
            }
            else if (jobsiteCode?.status === 200) {
                const data = { ...jobsiteCode?.payload };
                if (data?.code === "404") {
                    closeLoading();
                    return setDataAlert({
                        show: true,
                        body: data?.msg,
                        header: "Get List Jobsite",
                        callBack: () => null
                    });
                } else {
                    setDataJobsites(data?.data?.jobsites);
                }
            } else setDataAlert({
                show: true,
                body: roles?.msg,
                header: "Get List Jobsite",
                callBack: () => null
            })

            //handle request4
            if (jobTitle?.status === 401) {
                closeLoading();
            }
            else if (jobTitle?.status === 200) {
                const data = { ...jobTitle?.payload };
                if (data?.code === "404") {
                    closeLoading();
                    return setDataAlert({
                        show: true,
                        body: data?.msg,
                        header: "Get List Job title",
                        callBack: () => null
                    });
                } else {
                    setDataJobTitle([...(data?.data || [])].map(d => ({ value: `${d.job_title_id}|${d?.job_title}`, label: d?.job_title })));
                }
            } else setDataAlert({
                show: true,
                body: roles?.msg,
                header: "Get List Job title",
                callBack: () => null
            })

            setFirstRender(false);
            closeLoading();

        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Source filter",
                callBack: () => null
            })
        }
    }

    const [formData, setFormData] = useState(iniStateForm);
    const handeChange = (name, val) => {
        setFormData((prev) => ({ ...prev, [name]: val }))
    }
    const SelectMenuButton = (props) => {
        return (
            <components.MenuList  {...props}>
                <div className='tw-w-full tw-text-center tw-py-[5px] bg-light'>
                    <button className='btn btn-sm btn-info'>Tambah Job Title</button>
                </div>
                {props.children}
            </components.MenuList >
        )
    }

    useEffect(() => {
        if (firstRender){
            GetOptionSources();
        }
        if (defaultForm) {
            setFormData((prev) => ({...prev, ...defaultForm}));
        }
    }, [defaultForm]);

    return (
        <Form
            noValidate
            validated={validated}
            onSubmit={(e) => {
                const form = e.currentTarget;
                e.preventDefault(e);
                e.stopPropagation(e);
                if (form.checkValidity() === true) {
                    OnValidSubmit({
                        ...formData,
                        pers_area_name: dataJobsites.find(d => d.jobsite_code?.toString() === formData.pers_area_code)?.jobsite_name || ""
                    });
                }
                setValidated(true);
            }}
        >
            <h5>{edit ? "Edit" : "Add"} Form</h5>
            <hr />
            <div className='row'>
                <div className='col-md-6'>
                    <div className="form-group row">
                        <label htmlFor="nik" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">NIK</label>
                        <div className="col-sm-8">
                            <input required value={formData.nik} onChange={(e) => handeChange("nik", e.target.value)} type="text" className="form-control form-control-sm" id="nik" placeholder='NIK Pegawai' />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="nama" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Nama</label>
                        <div className="col-sm-8">
                            <input required value={formData.name} onChange={(e) => handeChange("name", e.target.value)} type="text" className="form-control form-control-sm" id="nama" placeholder='Nama Pegawai' />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="age" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Umur</label>
                        <div className="col-sm-8">
                            <input required value={formData.age} onChange={(e) => handeChange("age", e.target.value)} type="number" className="form-control form-control-sm" id="age" placeholder='Umur' />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="join" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Tanggal bergabung</label>
                        <div className="col-sm-8">
                            <input required value={formData.join_date} onChange={(e) => handeChange("join_date", e.target.value)} type='date' className="form-control form-control-sm" id="join" placeholder="Tanggal bergabung" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="point" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Point of Hire</label>
                        <div className="col-sm-8">
                            <input required value={formData.poh} onChange={(e) => handeChange("poh", e.target.value)} type="text" className="form-control form-control-sm" id="point" placeholder='Point of Hire' />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="role" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Role</label>
                        <div className="col-sm-8">
                            <select required value={formData.role_id} onChange={(e) => handeChange("role_id", e.target.value)} className="form-select form-control-sm" id="level" placeholder='Level'>
                                <option value={""}>-Pilih-</option>
                                {
                                    [...(dataRoles || [])].map((d, i) => (
                                        <option key={i} value={d.id}>{d.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    {
                        formData.role_id.toString() === "2" && (
                            <div className="form-group row">
                                <label htmlFor="type" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Type</label>
                                <div className="col-sm-8">
                                    <select required value={formData.type} onChange={(e) => handeChange("type", e.target.value)} className="form-select form-control-sm" id="type" placeholder='Type'>
                                        <option value={""}>-Pilih-</option>
                                        {
                                            ["Maintenance", "Production"].map((d, i) => (
                                                <option key={i} value={d}>{d}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                        )
                    }
                    <div className="form-group row">
                        <label htmlFor="job_type" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Job Type</label>
                        <div className="col-sm-8">
                            <select required value={formData.job_type} onChange={(e) => handeChange("job_type", e.target.value)} className="form-select form-control-sm" id="job_type" placeholder='Job Type'>
                                <option value={""}>-Pilih-</option>
                                {
                                    [...(dataJobtypes || [])].map((d, i) => (
                                        <option key={i} value={d.job_type}>{d.job_type}</option>
                                    ))
                                }

                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="jobsite" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Jobsite</label>
                        <div className="col-sm-8">
                            <select required value={formData.pers_area_code} onChange={(e) => handeChange("pers_area_code", e.target.value)} className="form-select form-control-sm" id="jobsite" placeholder='Jobsite'>
                                <option value={""}>-Pilih-</option>
                                {
                                    [...(dataJobsites || [])].map((d, i) => (
                                        <option key={i} value={d?.jobsite_code}>{d?.jobsite_name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="job_title_id" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Job Title</label>
                        <div className="col-sm-8">
                            <Select required value={{ value: formData.job_title_id, label: formData.job_title }} onChange={(e) => {
                                setFormData((prev) => ({
                                    ...prev,
                                    job_title_id: e.value.split("|")?.[0],
                                    job_title: e.label
                                }))
                            }} options={dataJobTitle} components={{ MenuList: SelectMenuButton }} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="level" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Level</label>
                        <div className="col-sm-8">
                            <input required value={formData.level} onChange={(e) => handeChange("level", e.target.value)} type="text" className="form-control form-control-sm" id="level" placeholder='Level' />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="grade" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Grade</label>
                        <div className="col-sm-8">
                            <input required value={formData.grade} onChange={(e) => handeChange("grade", e.target.value)} type="text" className="form-control form-control-sm" id="grade" placeholder='Grade' />
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className="form-group row">
                        <label htmlFor="current_point" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Current Point</label>
                        <div className="col-sm-8">
                            <input required value={formData.current_point} onChange={(e) => handeChange("current_point", e.target.value)} type="number" className="form-control form-control-sm" id="current_point" placeholder='Current Point' />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="attended_ratio" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Attended Ratio</label>
                        <div className="col-sm-8">
                            <input required value={formData.attended_ratio} onChange={(e) => handeChange("attended_ratio", e.target.value)} type="number" className="form-control form-control-sm" id="attended_ratio" placeholder='Attended Ratio' />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="maritial_status" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Maritial Status</label>
                        <div className="col-sm-8">
                            <input required value={formData.maritial_status} onChange={(e) => handeChange("maritial_status", e.target.value)} type="text" className="form-control form-control-sm" id="maritial_status" placeholder='Maritial Status' />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="function" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Fungsi</label>
                        <div className="col-sm-8">
                            <input required value={formData.function} onChange={(e) => handeChange("function", e.target.value)} type="text" className="form-control form-control-sm" id="fungsi" placeholder='Fungsi' />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="division" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Divisi</label>
                        <div className="col-sm-8">
                            <input required value={formData.division} onChange={(e) => handeChange("division", e.target.value)} type="text" className="form-control form-control-sm" id="division" placeholder='Divisi' />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="department" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Department</label>
                        <div className="col-sm-8">
                            <input required value={formData.department} onChange={(e) => handeChange("department", e.target.value)} type="text" className="form-control form-control-sm" id="department" placeholder='Department' />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="section" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Section</label>
                        <div className="col-sm-8">
                            <input required value={formData.section} onChange={(e) => handeChange("section", e.target.value)} type="text" className="form-control form-control-sm" id="section" placeholder='Section' />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="direktorat" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Direktorat</label>
                        <div className="col-sm-8">
                            <input required value={formData.directorat} onChange={(e) => handeChange("directorat", e.target.value)} type="text" className="form-control form-control-sm" id="direktorat" placeholder='Direktorat' />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="nik_direct_supervisor" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">NIK Direct Supervisor</label>
                        <div className="col-sm-8">
                            <input required value={formData.nik_direct_supervisor} onChange={(e) => handeChange("nik_direct_supervisor", e.target.value)} type="text" className="form-control form-control-sm" id="nik_direct_supervisor" placeholder='NIK Direct Supervisor' />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="direct_supervisor_name" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Nama Direct Supervisor</label>
                        <div className="col-sm-8">
                            <input required value={formData.direct_supervisor_name} onChange={(e) => handeChange("direct_supervisor_name", e.target.value)} type="text" className="form-control form-control-sm" id="direct_supervisor_name" placeholder='Nama Direct Supervisor' />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="nik_indirect_supervisor" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">NIK Indirect Supervisor</label>
                        <div className="col-sm-8">
                            <input required value={formData.nik_indirect_supervisor} onChange={(e) => handeChange("nik_indirect_supervisor", e.target.value)} type="text" className="form-control form-control-sm" id="nik_indirect_supervisor" placeholder='NIK Indirect Supervisor' />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="indirect_supervisor_name" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Nama Indirect Supervisor</label>
                        <div className="col-sm-8">
                            <input required value={formData.indirect_supervisor_name} onChange={(e) => handeChange("indirect_supervisor_name", e.target.value)} type="text" className="form-control form-control-sm" id="indirect_supervisor_name" placeholder='Nama Indirect Supervisor' />
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className='row'>
                <div className='col-md-12'>
                    <div className='tw-flex tw-full tw-justify-between'>
                        <button type='button' className='btn btn-sm btn-secondary'
                            onClick={kembaliOnclick}
                        >Kembali</button>
                        <button type='submit' className='btn btn-sm btn-success'>Simpan</button>
                    </div>
                </div>
            </div>
        </Form>
    );
}

export default FormEditor;