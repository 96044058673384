import React, { createContext, useContext, useReducer } from 'react';
import { useModalAlert } from '../components/modalAlert';
import useFetch from '../hooks/useFetch';
import { reducers } from './';

const initialState = {
    dataFirst: "first data",
    filter: {
        country: "id",
        apikey: "6a0230af7ed84bd897a31f6cbfeb5782",
        category: ""
    },
    errFetch: {
        status: false,
        msg: ""
    }
}

const globalContext = createContext();

export function GlobalProvider({ children }) {
    const [globalState, dispatch] = useReducer(reducers, initialState);
    const { doGet } = useFetch();
    const {
        modalAction, modalState
    } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;

    const actions = {
        setState: function (payload) {
            return dispatch({
                type: "setState",
                payload
            });
        },
        getData: async function ({
            url,
            param = {},
            _var = null,
            _loadingVar = "loadingGet",
            _varResp = "articles"
        }) {
            dispatch({
                type: "setLoading",
                payload: {
                    [_loadingVar]: true
                }
            })
            var uri = url

            if (param != null) {
                var keys = Object.keys(param)
                keys.forEach((val, key) => {
                    if (key == 0)
                        uri = uri + "?"
                    uri = uri + (val + "=" + param[val])
                    if (key != (keys.length - 1))
                        uri = uri + "&"
                })
            }
            // console.log(uri)
            const response = await doFetch(uri);
            if (response.code === "02") {

                dispatch({
                    "type": "fetchError",
                    payload: response.message
                })
            }
            var payload = { ...response.data, [_loadingVar]: false };
            if (_var !== null) payload = { [_var]: response[_varResp], [_loadingVar]: false }
            if (response.status === "ok") {
                // Swal.close()
                dispatch({
                    type: "getSuccess",
                    payload
                })
            }
            return response;
        },
        getNotification: async () => {
            try {
                const { status, statusText, payload } = await doGet({
                    param: {
                        "filter_has_read": 0,
                        "filter_group": "",
                        "filter_name": "",
                        "row_in_page": "5",
                        "page": 1,
                        "order_by": "",
                        "order_type": "asc"
                    },
                    url: '/api/my-notifications'
                });
                if (status === 401) {
                    closeLoading();
                }
                else if (status === 200) {
                    const { data = {}, code, msg } = payload;
                    if (code === "00") {
                        dispatch({
                            type: "setNotification",
                            payload: { notif: data }
                        })
                    } else setDataAlert({
                        show: true,
                        body: msg,
                        header: "Get Notification",
                        callBack: () => null
                    })
                } else setDataAlert({
                    show: true,
                    body: statusText,
                    header: "Get Notification",
                    callBack: () => null
                })
            } catch (error) {
                setDataAlert({
                    show: true,
                    body: error.toString(),
                    header: "Get Notification",
                    callBack: () => null
                })
            }
        }
    }

    async function doFetch(url, opts = {
        method: 'GET',
        headers: {

        }
    }) {
        var auth = {
            "X-Api-Key": "6a0230af7ed84bd897a31f6cbfeb5782"
        }

        if (opts.headers === undefined) {
            opts = {
                ...opts,
                headers: {
                    ...auth
                }
            }
        } else {
            opts = {
                ...opts,
                headers: {
                    ...opts.headers,
                    ...auth
                }
            }
        }
        //console.log(opts )
        try {
            const res = await fetch(url, opts);
            const response = await res.json();
            if (response.statusCode !== undefined && response.statusCode !== 200) {
                //console.log("test",response.statusCode)
                response = {
                    code: '02',
                    message: response.message
                }
            }
            return response
        } catch (error) {
            return {
                code: '02',
                message: error.message
            }
        }
    }

    return (
        <globalContext.Provider value={{ globalState, dispatch, actions }}>
            {children}
        </globalContext.Provider>
    )
}
export default () => useContext(globalContext);