import React, { createContext, useContext, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useModalAlert } from "../../../components/modalAlert";

const context = createContext({
    dwnldDtHmAction: {},
    dwnldDtHmState: {}
});

const statusSources = [
    { val: "submitted", name: "Submitted" },
    { val: "approved", name: "Approved" }
]
export function DwnldDataHmProvider({ children }) {
    const { modalAction } = useModalAlert();
    const {
        closeLoading,
        setDataAlert,
    } = modalAction;

    const { doGet } = useFetch();
    const [dataList, setDataList] = useState([]);
    const [loadingGet, setLoadingGet] = useState(false);
    const [batch, setBatch] = useState('');
    const [jobsite_code, setJobsite_code] = useState('');
    const [stts, setStatus] = useState('submitted');

    async function getDataList() {
        setLoadingGet(true)
        try {
            const { status, statusText, payload } = await doGet({
                url: '/api/download-data/download-hm-operator',
                param: {
                    batch,
                    jobsite_code,
                    status:stts
                }
            });
            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDataList(data.dataHM || []);
                    setBatch(data?.batch || '');
                    setJobsite_code(data?.jobsiteCode);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Download HM Operator",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Download HM Operator",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Download HM Operator",
                callBack: () => null
            })
        }
        setLoadingGet(false)
    }

    const [dataJobsite, setDataJobsite] = useState([]);
    async function getAllJobsite() {
        try {
            const { status, statusText, payload } = await doGet({
                url: '/api/list-all-jobsite',
            });
            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDataJobsite(data?.jobsites);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get All Jobsite",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get All Jobsite",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get All Jobsite",
                callBack: () => null
            })
        }
    }
    function onFilter() {
        getDataList();
    }
    useEffect(function () {
        getAllJobsite();
        getDataList()
    }, [])

    return <context.Provider
        value={{
            dwnldDtHmAction: {
                onFilter,
                setJobsite_code,
                setBatch,
                setStatus
            },
            dwnldDtHmState: {
                jobsite_code,
                batch,
                dataJobsite,
                dataList,
                loadingGet,
                stts,
                statusSources
            }
        }}
    >{children}</context.Provider>
}

export default function useDwnldDataHm() {
    return useContext(context);
}