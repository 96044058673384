import { TableList } from "../../../components";
import useAttRatio from "../useAttRatio";

export default function () {
    const {
        attAction: {
            toPage,
            nextPage,
            prevPage,
            changeShow,
            onOrdering,
            doFilter,
            setFilter,
        },
        attState: {
            pageStatus: { isLoading = true },
            dataList,
            historyPage: { rendered, paramState = {}, title },
            detilData: { otalData = 0, totalPage = 0 },
            filter: { filter_nik = "", filter_name = "" },
            pagination: { page = 1, show = 10, order_by = "", order_type = "" },
        },
    } = useAttRatio();

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">List Jobsite</div>
                        </div>
                        <div className="card-body">
                            <TableList
                                withCard={false}
                                withActions={false}
                                isLoading={isLoading}
                                data={dataList}
                                onOrdering={onOrdering}
                                curPage={page}
                                maxPage={totalPage}
                                defaultShow={show}
                                onChangeShow={changeShow}
                                onNextPage={nextPage}
                                onPrevPage={prevPage}
                                filter={true}
                                resetFilter={false}
                                formsFilter={[
                                    {
                                        type: "text",
                                        label: "Name",
                                        placeholder: "Name",
                                        value: filter_name,
                                        onChange: (val) =>
                                            setFilter((x) => ({
                                                ...x,
                                                filter_name: val,
                                            })),
                                    },
                                    {
                                        type: "text",
                                        label: "NIK",
                                        placeholder: "NIK",
                                        value: filter_nik,
                                        onChange: (val) =>
                                            setFilter((x) => ({
                                                ...x,
                                                filter_nik: val,
                                            })),
                                    },
                                ]}
                                onFilter={doFilter}
                                columns={[
                                    {
                                        name: "#",
                                        field: "#",
                                        maxWidth: "50px",
                                    },
                                    {
                                        name: "NIK",
                                        field: "nik",
                                        ordering: true,
                                        // maxWidth: '250px',
                                    },
                                    {
                                        name: "Nama",
                                        field: "name",
                                        ordering: true,
                                        // maxWidth: '150px',
                                    },
                                    {
                                        name: "Jobsite",
                                        field: "pers_area_name",
                                        // maxWidth: '150px',
                                    },
                                    {
                                        name: "Attended Ratio",
                                        field: "attended_ratio",
                                        ordering: true,
                                        // maxWidth: '250px',
                                        onRender: (item) => {
                                            return (
                                                <div>
                                                    {item.attended_ratio * 100}{" "}
                                                    %
                                                </div>
                                            );
                                        },
                                    },
                                ]}
                            />
                        </div>

                        <div className="card-header">
                            <button
                                onClick={() =>
                                    toPage(rendered, title, paramState)
                                }
                                className="btn btn-secondary btn-sm"
                            >
                                back
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
