import React from 'react';
import { Link } from 'react-router-dom';
import * as pages from './pages';
import useCrtSch, { CrtSchProvider } from './useCrtSch';

function Index(props) {
    const { crtSchState, crtSchAction } = useCrtSch();
    const PageRender = pages[crtSchState.pageState.type];
    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <div className='row'>
                        <div className='col-md-6'>
                            <h4>Create Schedule Assesment</h4>
                            <h6>{
                                crtSchState.pageState.title
                            }</h6>
                        </div>
                        <div className='col-md-6'>
                            {
                                crtSchState.pageState.type === "FormCreate" ? (
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb justify-content-end">
                                            <li className="breadcrumb-item" onClick={() => crtSchAction.toListPage()}><Link to={"#"}>List Training</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">{crtSchState.pageState.title}</li>
                                        </ol>
                                    </nav>
                                ):(
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb justify-content-end">
                                            <li className="breadcrumb-item active" aria-current="page">{crtSchState.pageState.title}</li>
                                        </ol>
                                    </nav>
                                )
                            }
                        </div>
                    </div>
                </div>
            </section>
            <PageRender />
        </>
    );
}

export default (props) => <CrtSchProvider><Index {...props} /></CrtSchProvider>;