import React from 'react';
import 'anychart';
import AnyChart from 'anychart-react';

import useReportDataEmployee from './useReportDataEmployee';

const ChartData = () => {
    const { reportDtEmployeeState } = useReportDataEmployee();

    // set the chart type
    const chart = anychart.line();
    chart.credits().enabled(false);

    // set the chart title
    chart.title("Jumlah Karyawan");

    // create a series, set the data and name
    const series = chart.column(reportDtEmployeeState.dataList);
    
    series.labels(true);
    // series.labels().fontColor("green");
    // series.labels().fontWeight(900);
    series.labels().format("{%value}");

    chart.container('container');

    return (
        <AnyChart
            instance={chart}
            height={window.innerHeight - 450}
            id="chart-employee-container"
        />
    )
}

export default ChartData;
