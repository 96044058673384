import React from 'react';
import buma from './img.png';

const LMSPage = () => {
    return (<>

        <div className='row'>
            <div className='col-6'>
                <section className="content-header">
                    <div className="container-fluid">
                        <h4>Development</h4>
                        <h6>Learning Management System</h6>
                    </div>
                </section>
            </div>
            <div className='col-6'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb justify-content-end">
                        <li className="breadcrumb-item active" aria-current="page">Learning Management System</li>
                    </ol>
                </nav>
            </div>
        </div>

        <div className='container'>
            <div className='card' style={{
                width: "75%",
                margin: "auto"
            }}>
                <div className='card-body'>
                    <img src={buma} style={{ width: "100%" }} />
                </div>

            </div>

            <div className='row mt-3'>
                <div className='col'>
                    <h6 className='text-center'>
                        Untuk melanjutkan login
                    </h6>
                    <div style={{
                        width: "fit-content",
                        margin: "auto"
                    }}>
                        <a href='https://ol.buma.sambaash.com/' target={"_blank"} className='btn btn-primary btn sm' style={{ margin: "auto" }}>
                            Klik disini
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default LMSPage;
