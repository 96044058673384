import React from 'react';
import { Modal } from 'react-bootstrap';
import ModalDecline from '../modal-decline/modalDecline';
import useTirApproval from '../useTirApproval';

function ModalTautan(props) {
    const { tirApvAction, tirApvState } = useTirApproval();
    return (
        <div>
            <ModalDecline />
            <Modal
                dialogClassName='modal-editor-trainee'
                backdropClassName='bg-transparent'
                show={tirApvState.modalTautan?.show}
                onHide={() => tirApvAction.resetModalTautan()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Link Video
                    </Modal.Title>
                </Modal.Header>
                <form
                    onSubmit={(e) => {
                        e.preventDefault(e);
                        // tirApvAction.confirmAddUpdate({
                        //     "tir_id": tirApvState.formDataTautan?.tir_id,
                        //     "video_url": tirApvState.formDataTautan?.url,
                        //     "training_id": tirApvState.formDataTautan?.training_id
                        // });
                    }}
                >

                    <Modal.Body>
                        {
                            (tirApvState.formDataTautan?.status === "declined") &&
                            <div className="form-group row text-danger rounded mx-1" style={{ backgroundColor: '#ffe2e5' }}>
                                <label className="col-sm-2 col-form-label">Note : </label>
                                <label className="col-sm-10 col-form-label">{tirApvState.formDataTautan?.note}</label>
                            </div>
                        }
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">URL</label>
                            <div className="col-sm-10">
                                {/* <div className='text-muted border border-secondary p-2 rounded'>
                                    {tirApvState.formDataTautan?.url}
                                </div> */}
                                <span className='text-wrap'>{tirApvState.formDataTautan?.url}</span>
                                {/* <input
                                    value={tirApvState.formDataTautan?.url}
                                    onChange={(e) => tirApvAction.setFormDataTautan((prev) => ({ ...prev, url: e.target.value }))}
                                    required
                                    readOnly
                                    disabled
                                    type="url"
                                    placeholder='example: https://frontlinebuma.afledu.com/'
                                    className="form-control"
                                /> */}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            // tirApvState.modalTautan?.modeEdit ? (
                            //     <>
                            //         <button type='button' className='btn btn-sm btn-danger mr-1'
                            //             onClick={() => tirApvAction.confirmDelete(tirApvState.formDataTautan)}
                            //         >Delete</button>
                            //         <a href={tirApvState.formDataTautan?.url} target="_blank">
                            //             <button type='button' className='btn btn-sm btn-primary mr-1'>Go to URL</button>
                            //         </a>
                            //         <button type='submit' className='btn btn-sm btn-success mr-1'>Save</button>
                            //     </>
                            // ) : (
                            //     <>
                            //         <button
                            //             type='button'
                            //             onClick={() => tirApvAction.resetModalTautan()}
                            //             className='btn btn-sm btn-secondary mr-1'>Cancel</button>
                            //         <button type='submit' className='btn btn-sm btn-success mr-1'>Save</button>
                            //     </>
                            // )
                        }
                        <button
                            type='button'
                            onClick={() => tirApvAction.resetModalTautan()}
                            className='btn btn-sm btn-secondary mr-1'>Back</button>
                        <a href={tirApvState.formDataTautan?.url} target="_blank">
                            <button type='button' className='btn btn-sm btn-primary mr-1'>Go to URL</button>
                        </a>
                        {
                            tirApvState.modalTautan?.data?.status === "submitted" &&
                            <>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-danger mr-1'
                                    onClick={() => tirApvAction.openModalDecline({
                                        "tir_id": tirApvState.formDataTautan?.tir_id,
                                        "status": 'declined',
                                        "trainee_nik": tirApvState.formDataTautan?.trainee_nik
                                    })}
                                // onClick={() => tirApvAction.confirmUpdateStatus({
                                //     "tir_id": tirApvState.formDataTautan?.tir_id,
                                //     "status": 'declined'
                                // }, tirApvState.formDataTautan?.trainee_nik)}
                                >Decline</button>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-success mr-1'
                                    onClick={() => tirApvAction.confirmUpdateStatus({
                                        "tir_id": tirApvState.formDataTautan?.tir_id,
                                        "status": 'approved'
                                    }, tirApvState.formDataTautan?.trainee_nik)}
                                >Approve</button>
                            </>
                        }
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    );
}

export default ModalTautan;