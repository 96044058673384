import React from 'react';
import { TableList } from '../../../../components';
import useMenSts from '../useMentSts';

function index(props) {
    const { menStsAction: {
        setFilter = null,
        changeShow = () => null,
        nextPage = () => null,
        prevPage = () => null,
        onFilter = () => null,
        toListPage = () => null
    }, menStsState: {
        pageState = {},
        filter = {},
        loadingTrainee = false,
        dataTrainee = {},
        pagination = {},
    } } = useMenSts();
    return (
        <div className='card'>
            <div className='card-body mb-3 row'>
                <div className='col-md-12'>
                    <TableList
                        withCard={false}
                        filter={true}
                        formsFilter={[
                            {
                                type: 'text',
                                placeholder: 'Name or NIK',
                                value: filter?.search,
                                onChange: (val) => setFilter(e => ({ ...e, search: val }))
                            }
                        ]}
                        resetFilter={false}
                        onFilter={onFilter}
                        submitButton={<><i className='fa fa-search'></i> Search</>}

                        minHeight="300px"
                        maxHeight="500px"
                        navtable={"TABLE 1"}
                        isLoading={loadingTrainee}

                        onChangeShow={changeShow}
                        onNextPage={nextPage}
                        onPrevPage={prevPage}
                        defaultShow={pagination?.show}
                        curPage={pagination?.page}
                        maxPage={dataTrainee?.totalPage || 0}
                        orderData={{
                            order_by: pagination.order_by,
                            order_type: pagination.order_type
                        }}
                        totalData={dataTrainee?.totalData || 0}
                        withActions={false}
                        columns={[
                            {
                                name: '#',
                                field: '#',
                                maxWidth: '50px',
                            },
                            {
                                name: "Nik Trainee",
                                field: 'trainee_nik',
                            },
                            {
                                name: "Name Trainee",
                                field: 'trainee_name',
                            },
                            {
                                name: 'Aksi',
                                field: '',
                                maxWidth: '150px',
                                onRender: (item, i) => (
                                    <button className='btn btn-sm btn-success'
                                        onClick={() => toListPage({ trainee_nik: item?.trainee_nik })}
                                    >Detail</button>
                                )
                            },
                        ]}
                        data={(dataTrainee?.listData || [])}
                    />
                </div>
            </div>
        </div>
    );
}

export default index;