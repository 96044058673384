import React from 'react';
import { Modal } from 'react-bootstrap';
import useTirTrainer from '../useTirTrainer';
import { useHistory } from 'react-router-dom';

function ModalTitleForm(props) {
    const history = useHistory();
    const { tirAction: {
        closeModalTitle = () => null,
        setModalAddForm = null
    }, tirState: {
        trainee_nik = null,
        modalAddForm: {
            show = false,
            title = '',
            data = {}
        }
    } } = useTirTrainer();
    return (
        <div>
            <Modal
                dialogClassName='modal-editor-trainee'
                backdropClassName='bg-transparent'
                show={show}
                onHide={closeModalTitle}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Judul Form
                    </Modal.Title>
                </Modal.Header>
                <form
                    onSubmit={(e) => {
                        e.preventDefault(e);
                        closeModalTitle();
                        history.push({
                            pathname: '/development/tir-for-trainer/form',
                            state: {
                                form: {
                                    title: "Form Add",
                                    type: "Form",
                                    dataPage: {
                                        detail: { ...data, title, trainee_nik },
                                        modeEdit: false
                                    }
                                }
                            }
                        })
                    }}
                >

                    <Modal.Body>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Judul Form</label>
                            <div className="col-sm-8">
                                <input
                                    value={title}
                                    onChange={(e) => setModalAddForm((prev) => ({ ...prev, title: e.target.value }))}
                                    required
                                    placeholder='Judul form lengkap'
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            type='button'
                            onClick={closeModalTitle}
                            className='btn btn-sm btn-secondary mr-1'>Cancel</button>
                        <button type='submit' className='btn btn-sm btn-success mr-1'>Save</button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    );
}

export default ModalTitleForm;