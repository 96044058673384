import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useModalAlert } from "../../components/modalAlert";
import { useAuth } from "../../context";
import useFetch from "../../hooks/useFetch";
import ModalDetail from "./detail/modal-detail";

const dashContext = createContext({
    dashState: {},
    dashAction: {}
})

const iniStatePage = {
    title: "Dashboard",
    type: "ComReport",
    dataPage: {}
};
export function DashboardProvider({ children }) {
    const { userInfo } = useAuth();
    const role_id = userInfo?.role_id;
    const [searchForm, setSearchForm] = useState('');
    const [showDetail, setShowDetail] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1, show: 10, order_by: "trainee_name", order_type: "asc"
    });

    const [filter, setFilter] = useState({
        search: "",
    });
    const [pageState, setPageState] = useState({
        title: "",
        type: "",
        dataPage: {}
    });
    const { doGet, doPost } = useFetch()
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const [modalDetail, setModalDetail] = useState({
        show: false,
        data: {}
    });
    const [loadingTrainee, setLoadingTrainee] = useState(false)
    const [dataTrainee, setDataTrainee] = useState({});
    const getListTrainee = useCallback(async function () {
        try {
            setLoadingTrainee(true);
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/job-assignment/list-trainee',
                    param: {
                        ...filter,
                        "page": pagination.page,
                        "row_in_page": pagination.show,
                        "order_by": pagination.order_by,
                        "order_type": pagination.order_type,
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDataTrainee({ ...data });
                    setFilter(e => ({ ...e, batch: data?.batch }));
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get List Trainee",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List Trainee",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Trainee",
                callBack: () => null
            })
        }
        setLoadingTrainee(false)
    }, [pageState, pagination, filter])
    const getListTraineeLC = useCallback(async function () {
        try {
            setLoadingTrainee(true);
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/dashboard/trainee-lcofficer',
                    param: {
                        ...filter,
                        "page": pagination.page,
                        "row_in_page": pagination.show,
                        "order_by": pagination.order_by,
                        "order_type": pagination.order_type,
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDataTrainee({ ...data });
                    setFilter((e) => ({
                        ...e,
                        job_type: data?.job_type
                    }))
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get List Trainee",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List Trainee",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Trainee",
                callBack: () => null
            })
        }
        setLoadingTrainee(false)
    }, [pageState, pagination, filter])
    function nextPage({ page, limit }) {
        setPagination(prevState => ({
            ...prevState,
            page, show: limit,
        }))
    }
    function prevPage({ page, limit }) {
        setPagination(prevState => ({
            ...prevState,
            page, show: limit,
        }))
    }
    function changeShow({ page, limit }) {
        setPagination(prevState => ({
            ...prevState,
            page, show: limit,
        }))
    }
    function onFilter() {
        //Trainer
        if (role_id === 2) {
            getListTrainee();
        }
        //LC Officer
        if (role_id === 5) {
            getListTraineeLC();
        }
    }

    const [loadingJr, setLoadingJr] = useState(false)
    const [dataJr, setDataJr] = useState([]);
    const getJunior = useCallback(async function () {
        setLoadingJr(true);

        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/list-junior',
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = [], code, msg } = payload;
                if (code === "00") {
                    setDataJr([...data]);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Data Dashboard",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Data Dashboard",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Data Dashboard",
                callBack: () => null
            })
        }
        setLoadingJr(false)
    }, []);

    const [loadingHeader, setLoadingHeader] = useState(false)
    const [dataHeader, setDataHeader] = useState({});
    const getHeader = useCallback(async function () {
        setLoadingHeader(true);

        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/dashboard/info-trainee',
                    param: { trainee_nik: pageState?.dataPage?.nik }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDataHeader({ ...data?.thisUser })
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Data Header",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Data Header",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Data Header",
                callBack: () => null
            })
        }
        setLoadingHeader(false)
    }, [pageState]);

    const [loadingDash, setLoadingDash] = useState(false)
    const [dataDash, setDataDash] = useState([]);
    const [dataNonIDP, setNonIdp] = useState([]);
    const [dataAssesmentNonIDP, setAssesmentNonIdp] = useState([]);
    const getDashboard = useCallback(async function () {
        setLoadingDash(true);

        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/dashboard/index',
                    param: role_id === 3 ? null : { trainee_nik: pageState?.dataPage?.nik }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    // setDataDash(data?.dataCompetency || []);
                    var y = [...(data?.dataCompetency || [])];
                    const x = y.slice(0, y.length / 2);
                    const z = y.slice((y.length / 2), y.length);
                    setDataDash(x.map((d, i) => {
                        return {
                            ...d,
                            no_right: z[i] ? x.length + (i + 1) : '',
                            competency_id_right: z[i]?.competency_id || '',
                            competency_name_right: z[i]?.competency_name || '',
                            status_right: z[i]?.status || ''
                        }
                    }))
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Data Dashboard",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Data Dashboard",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Data Dashboard",
                callBack: () => null
            })
        }
        setLoadingDash(false)
    }, [pageState]);

    const getDashBoardNonIDP = useCallback(async function () {
        setLoadingDash(true);

        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/dashboard/index-non-idp',
                    param: role_id === 3 ? null : { trainee_nik: pageState?.dataPage?.nik }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    // setDataDash(data?.dataCompetency || []);
                    var y = [...(data || [])];
                    const x = y.slice(0, y.length / 2);
                    const z = y.slice((y.length / 2), y.length);
                    setNonIdp(data)
                    // setNonIdp(x.map((d, i) => {
                    //     return {
                    //         ...d,
                    //         no_right: z[i] ? x.length + (i + 1) : '',
                    //         competency_id_right: z[i]?.competency_id || '',
                    //         competency_name_right: z[i]?.competency_name || '',
                    //         status_right: z[i]?.status || ''
                    //     }
                    // }))
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Data Dashboard",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Data Dashboard",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Data Dashboard",
                callBack: () => null
            })
        }
        setLoadingDash(false)
    }, [pageState]);


    const getDashBoardAssesmentNonIDP = useCallback(async function () {
        setLoadingDash(true);

        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/dashboard/index-assessment-non-idp',
                    param: role_id === 3 ? null : { trainee_nik: pageState?.dataPage?.nik }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    // setDataDash(data?.dataCompetency || []);
                    var y = [...(data || [])];
                    const x = y.slice(0, y.length / 2);
                    const z = y.slice((y.length / 2), y.length);
                    setAssesmentNonIdp(data)
                    // setNonIdp(x.map((d, i) => {
                    //     return {
                    //         ...d,
                    //         no_right: z[i] ? x.length + (i + 1) : '',
                    //         competency_id_right: z[i]?.competency_id || '',
                    //         competency_name_right: z[i]?.competency_name || '',
                    //         status_right: z[i]?.status || ''
                    //     }
                    // }))
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Data Dashboard",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Data Dashboard",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Data Dashboard",
                callBack: () => null
            })
        }
        setLoadingDash(false)
    }, [pageState]);



    const [loadingHist, setLoadingHist] = useState(false)
    const [dataHist, setDataHist] = useState([]);
    const [batch, setBatch] = useState();
    const [tabActive, setTabActive] = useState(1)
    const getHistories = useCallback(async function () {
        setLoadingHist(true);

        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/dashboard/histories',
                    param: role_id === 3 ? {
                        competency_id: pageState.dataPage?.competency_id,
                        batch: batch || ''
                    } : {
                        competency_id: pageState.dataPage?.competency_id,
                        trainee_nik: pageState.dataPage?.nik,
                        batch: batch || ''
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDataHist(data?.dataCompetency || []);
                    setBatch(data?.batch)
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Data Histories",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Data Histories",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Data Histories",
                callBack: () => null
            })
        }
        setLoadingHist(false)
    }, [pageState, batch]);

    const toListPage = useCallback(function (data = {}) {
        setPageState((prev) => ({ ...iniStatePage, dataPage: { ...prev?.dataPage, ...data } }));
    }, [pageState]);
    const toDetailPage = useCallback(function (data = {}) {
        setPageState({
            title: 'History',
            type: 'Detail',
            dataPage: { ...data }
        });
    }, [pageState]);
    const toListJrPage = useCallback(function (data = {}) {
        setPageState({
            title: "Subordinate",
            type: "ListJr",
            dataPage: {}
        })
    }, [pageState]);
    const toListTraineePage = useCallback(function (data = {}) {
        setPageState({
            title: "List Trainee",
            type: "ListTrainee",
            dataPage: {}
        })
    }, [pageState]);
    const toSearchForm = useCallback(function (data = {}) {
        setPageState({
            title: "Search Form",
            type: "SearchPage",
            dataPage: {}
        })
    }, [pageState]);

    const onSearch = useCallback(() => {
        toListPage({ nik: searchForm })
    }, [searchForm]);
    const backOnclik = useCallback(() => {
        if (role_id === 4) {
            toListJrPage();
        }
        if (role_id === 2 || role_id === 5) {
            toListTraineePage();
        }
        if (role_id === 1) {
            toSearchForm();
        }
    }, [role_id])

    const closeModalDetail = () => {
        setModalDetail({
            show: false,
            data: {}
        })
    }
    const onFilterHistory = () => {
        getHistories();
    }
    // useEffect(() => {
    //     if (role_id === 2) {
    //         getListTrainee();
    //     }
    //     if (role_id === 5) {
    //         getListTraineeLC();
    //     }
    // }, [pagination])
    useEffect(() => {
        if (role_id === 4) {
            setPageState({
                title: "Subordinate",
                type: "ListJr",
                dataPage: {}
            })
        } else if (role_id === 3) {
            setPageState({ ...iniStatePage })
        } else if (role_id === 2) {
            setPageState({
                title: "List Trainee",
                type: "ListTrainee",
                dataPage: {}
            });
            setFilter({
                search: "",
                batch: ""
            })
            setPagination({
                page: 1, show: 10, order_by: "trainee_name", order_type: "asc"
            })
        } else if (role_id === 5) {
            setPageState({
                title: "List Trainee",
                type: "ListTrainee",
                dataPage: {}
            })
            setFilter({
                filter_nik: "",
                filter_name: "",
                job_type: ""
            })
            setPagination({
                page: 1, show: 10, order_by: "nik", order_type: "asc"
            })
        } else if (role_id === 1) {
            setPageState({
                title: "Search Form",
                type: "SearchPage",
                dataPage: {}
            })
        } else {
            setPageState({
                title: "",
                type: "",
                dataPage: {}
            })
        }
    }, [role_id]);

    
    useEffect(() => {
        if (pageState?.type === 'ComReport') {
            tabActive === 1 ? getDashboard() : 
            tabActive === 2 ? getDashBoardNonIDP() :
            getDashBoardAssesmentNonIDP();
            if (role_id === 3) {
                setDataHeader({
                    "name": userInfo.name,
                    "nik": userInfo.nik,
                    "job_title": userInfo.job_title,
                    "grade": userInfo.grade,
                    "pers_area": userInfo.pers_area,
                    "section": userInfo.section,
                    "join_date": userInfo.join_date,
                    "current_point": userInfo.current_point
                })
            } else {
                getHeader();
            }
        };
        if (pageState?.type === 'Detail') getHistories();
        if (pageState?.type === "ListJr") getJunior();
        if (pageState?.type === 'ListTrainee') {
            //Trainer
            if (role_id === 2) {
                getListTrainee();
            }
            //LC Officer
            if (role_id === 5) {
                getListTraineeLC();
            }
        }
    }, [pageState, pagination, tabActive]);
    return <dashContext.Provider
        value={{
            dashState: {
                showDetail,
                pageState,
                dataDash,
                loadingDash,
                loadingHist,
                dataHist,
                modalDetail,
                loadingJr,
                dataJr,
                role_id,
                loadingTrainee,
                dataTrainee,
                searchForm,
                dataHeader,
                loadingHeader,
                pagination,
                filter,
                batch,
                tabActive,
                dataNonIDP,
                dataAssesmentNonIDP

            },
            dashAction: {
                setShowDetail,
                setModalDetail,
                toListPage,
                toListJrPage,
                toDetailPage,
                toSearchForm,
                toListTraineePage,
                closeModalDetail,
                setSearchForm,
                onSearch,
                backOnclik,
                nextPage,
                prevPage,
                onFilter,
                changeShow,
                setFilter,
                onFilterHistory,
                setBatch,
                setTabActive
            }
        }}
    >
        <ModalDetail />
        {children}
    </dashContext.Provider>
}

export default function useDashboard() {
    return useContext(dashContext);
}
