import React from 'react';
import { TableList } from '../../../../components';
import useMentoringAdmin from '../useMentoringAdmin';

function Index(props) {
    const {
        menAdminAction: {
            nextPageTrainee = () => null,
            prevPageTrainee = () => null,
            changeShowTrainee = () => null,
            toSearchPage = () => null,
            toTrainingPage = () => null
        },
        menAdminState: {
            loadingTrainee = false,
            dataTrainee = {},
            paginationTrainee = {}
        }
    } = useMentoringAdmin();
    return (
        <div className='card'>
            <div className='card-body mb-3 row'>
                <div className='col-md-12'>
                    <TableList
                        withCard={false}
                        filter={false}
                        resetFilter={false}
                        minHeight="300px"
                        maxHeight="500px"
                        navtable={"TABLE 1"}
                        isLoading={loadingTrainee}

                        onChangeShow={changeShowTrainee}
                        onNextPage={nextPageTrainee}
                        onPrevPage={prevPageTrainee}
                        defaultShow={paginationTrainee?.show}
                        curPage={paginationTrainee?.page}
                        maxPage={dataTrainee?.totalPage || 0}
                        orderData={{
                            order_by: paginationTrainee?.order_by,
                            order_type: paginationTrainee?.order_type
                        }}
                        totalData={dataTrainee?.totalData || 0}
                        withActions={false}
                        columns={[
                            {
                                name: '#',
                                field: '#',
                                maxWidth: '50px',
                            },
                            {
                                name: "NIK Pengawas",
                                field: 'trainee_nik',
                            },
                            {
                                name: "Nama Pengawas",
                                field: 'trainee_name',
                            },
                            {
                                name: 'Aksi',
                                field: '',
                                maxWidth: '150px',
                                onRender: (item, i) => (
                                    <button className='btn btn-sm btn-success'
                                    onClick={toTrainingPage}
                                    >Pilih Form</button>
                                )
                            },
                        ]}
                        data={[...(dataTrainee?.listData || [])]}
                    />
                    <button className='btn btn-sm btn-secondary'
                        onClick={toSearchPage}>Back</button>
                </div>
            </div>
        </div>
    );
}

export default Index;