import React, { createContext, useContext, useEffect, useState } from 'react';
import { useModalAlert } from '../../../../components/modalAlert';
import useFetch from '../../../../hooks/useFetch';

const rsTrngContext = createContext({
    rsTrngState:{},
    rsTrngAction:{}
});

export function ResultTrainingProvider({children}){
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const { doGet } = useFetch();
    const [dataList, setDataList] = useState([]);
    const [loadingGet, setLoadingGet] = useState(false);
    const [modalTrainee, setModalTrainee] = useState({
        show: false,
        data: [],
    });
    const [batch, setBatch] = useState("");

    async function getList() {
        try {
            setLoadingGet(true);
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/ats',
                    param: {
                        type_page: "result_training",
                        batch
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDataList(data?.tableData || []);
                    setBatch(data?.batch);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get List Training",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List Training",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Training",
                callBack: () => null
            })
        }
        setLoadingGet(false)
    }
    const onFilter = () => {
        getList();
    }
    useEffect(() => {
        getList();
    },[])
    return <rsTrngContext.Provider
        value={{
            rsTrngState:{
                dataList,
                loadingGet,
                modalTrainee,
                batch
            },
            rsTrngAction:{
                setModalTrainee,
                setBatch,
                onFilter
            }
        }}
    >{children}</rsTrngContext.Provider>
}

export default function useResultTraining(){
    return useContext(rsTrngContext);
}