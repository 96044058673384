import moment from 'moment';
import React from 'react';
import { DateInput } from '../../../../components';
import useMentorApp from '../useMentorApp';
import './index.css';

function Index(props) {
    const { menAppAction: {
        toListPage = () => null,
        openModalDecline = () => null,
        confirmUpdateStatus = () => null
    }, menAppState } = useMentorApp();
    return (
        <div>
            <form>
                <div className='card'>
                    <div className='card-body p-5'>
                        <h6 className='text-center'>FORM PELAKSANAAN MENTORING</h6>
                        <h6 className='text-center'>{menAppState.pageState?.dataPage?.form_name}</h6>
                        <div className='form-row mt-5'>
                            <div className='col-md-6'>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>Nama Mentor</label>
                                        <span className='text-wrap'>{menAppState.formData?.trainer_name}</span>
                                    </div>
                                </div>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>NIK</label>
                                        <span className='text-wrap'>{menAppState.formData?.trainer_nik}</span>
                                    </div>
                                </div>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>Section</label>
                                        <span className='text-wrap'>{menAppState.formData?.section}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                {/* <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width'>Hari</label>
                                        <span className='text-wrap'>{menAppState.formData?.date !== "" && menAppState.formData?.date !== "Invalid date" ? moment(menAppState.formData?.date).format('dddd') : ''}</span>
                                    </div>
                                </div> */}
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill cs-date-picker">
                                        <label className='label-width'>Tanggal</label>
                                        {/* <DateInput
                                            value={menAppState.formData?.date}
                                            disabled
                                            // onChange={(val) => handleChange('date', val)}
                                            className='form-control flex-fill'
                                            popperClassName='z-index-date-input'
                                        /> */}
                                        <span className='text-wrap'>{menAppState.formData?.date}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='col-md-6'>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>Mentoring Activity ke - </label>
                                        {/* <input
                                            value={menAppState.formData?.order_mentoring_activity}
                                            disabled
                                            // onChange={(e) => handleChange('order_mentoring_activity', e.target.value)}
                                            type="number" className="form-control flex-fill align-self-start" placeholder='' /> */}
                                        <span className='text-wrap'>{menAppState.formData?.order_mentoring_activity}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='col-md-6'>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>Nama Pengawas</label>
                                        <span className='text-wrap'>{menAppState.formData?.trainee_name}</span>
                                    </div>
                                </div>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width'>NIK Pengawas</label>
                                        <span className='text-wrap'>{menAppState.formData?.trainee_nik}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='col-md-12'>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>Jabatan</label>
                                        <span className='text-wrap'>{menAppState.listForm?.position}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='col-md-12'>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>Lokasi / Pit</label>
                                        {/* <input
                                            value={menAppState.formData?.location}
                                            disabled
                                            // onChange={(e) => handleChange('location', e.target.value)}
                                            type="text" className="form-control flex-fill align-self-start" placeholder='Lokasi / Pit' /> */}
                                        <span className='text-wrap'>{menAppState.formData?.location}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-row mb-3'>
                            <div className='col-md-12'>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start mb-2'>Materi & Praktik</label>
                                        <div>
                                            {
                                                [...(menAppState.listForm?.listData || [])].map(({ data = [], parent }, i) => (
                                                    <div className='row px-1 mb-2'>
                                                        <div className='col-12'>
                                                            <b>{parent}</b>
                                                            {/* <div className="form-check justify-content-start">
                                                                <input className="form-check-input" type="checkbox" id={`parent_${i}`} />
                                                                <label className="form-check-label" htmlFor={`parent_${i}`}>
                                                                    <b>{parent}</b>
                                                                </label>
                                                            </div> */}
                                                        </div>
                                                        <div className='col-md-6'>
                                                            {
                                                                [...(data || [])].filter(d => d?.position === "left_col-6").map((d, j) => (

                                                                    <div className="form-check justify-content-start">
                                                                        <input
                                                                            disabled
                                                                            checked={menAppState.formCheck[d?.form_id]?.value}
                                                                            // onChange={(e) => handleFormCheck(d?.form_id, {
                                                                            //     "form_id": d?.form_id,
                                                                            //     "value": e.target.checked,
                                                                            //     "answer_id": menAppState.formCheck[d?.form_id]?.answer_id || null,
                                                                            // })}
                                                                            className="form-check-input align-self-start mt-1" type="checkbox" id={d?.form_id} />
                                                                        <label className="form-check-label" htmlFor={d?.form_id}>
                                                                            {d?.question}
                                                                        </label>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                        <div className='col-md-6'>
                                                            {
                                                                [...(data || [])].filter(d => d?.position === "right_col-6").map((d, j) => (

                                                                    <div className="form-check justify-content-start">
                                                                        <input
                                                                            disabled
                                                                            checked={menAppState.formCheck[d?.form_id]?.value}
                                                                            // onChange={(e) => handleFormCheck(d?.form_id, {
                                                                            //     "form_id": d?.form_id,
                                                                            //     "value": e.target.checked,
                                                                            //     "answer_id": menAppState.formCheck[d?.form_id]?.answer_id || null,
                                                                            // })}
                                                                            className="form-check-input align-self-start mt-1" type="checkbox" id={d?.form_id} />
                                                                        <label className="form-check-label" htmlFor={d?.form_id}>
                                                                            {d?.question}
                                                                        </label>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                                <small>Catatan : Checklist Poin Materi/ Praktik yang diberikan kepada mentee/ pengawas produksi</small>
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='col-md-6'>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>Achievement </label>
                                        <span className='text-wrap'>{menAppState.formData?.achievement } %</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='col-md-6'>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>Durasi </label>
                                        {/* <input
                                            disabled
                                            value={menAppState.formData?.duration}
                                            // onChange={(e) => handleChange('duration', e.target.value)}
                                            type="number" className="form-control flex-fill align-self-start" placeholder='' /> */}
                                        <span className='text-wrap'>{menAppState.formData?.duration}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='col-md-12'>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>Kendala & Support </label>
                                        <textarea
                                            disabled
                                            value={menAppState.formData?.constraint_supporting}
                                            // onChange={(e) => handleChange('constraint_supporting', e.target.value)}
                                            rows={5} type="number"
                                            className="form-control flex-fill align-self-start"
                                            placeholder='' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='col-md-12'>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>Catatan Pengembangan Selama Mentoring Berjalan baik/ Perlu ditingkatkan </label>
                                        <textarea
                                            disabled
                                            value={menAppState.formData?.note}
                                            // onChange={(e) => handleChange('note', e.target.value)}
                                            rows={5} type="number" className="form-control flex-fill align-self-start" placeholder='' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-row mt-5'>
                            <div className='col-md-12 text-md-right'>
                                <button type="button" className='btn btn-secondary btn-sm mr-1 float-left'
                                    onClick={toListPage}
                                >Back</button>
                                <button className='btn btn-sm btn-primary mr-1'
                                    onClick={() => window.print()}
                                ><i className='fa fa-print icon-nm mr-1'></i>Print</button>
                                {
                                    (menAppState.formData?.status !== 'approved' && menAppState.formData?.status !== 'declined') && (
                                        <>
                                            <button type='button' className='btn btn-sm btn-danger mr-1'
                                                onClick={() => openModalDecline({
                                                    "job_mentoring_id": menAppState.formData?.job_mentoring_id
                                                })}
                                            >Decline</button>
                                            <button type='button' className='btn btn-sm btn-success mr-1'
                                                onClick={() => confirmUpdateStatus({
                                                    "job_mentoring_id": menAppState.formData?.job_mentoring_id,
                                                    "status": 'approved',
                                                    "decline_note": ''
                                                })}
                                            >Approve</button>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Index;
