import React, { useEffect } from 'react';
import { TableList } from '../../../../../components';
import useModalTrainee, { MtrProvider } from './useModalTrainee';

function ModalTrainee(props) {
    const { mtrAction, mtrState } = useModalTrainee();

    useEffect(() => {
        mtrAction.setData([...(props?.data || [])]);
        mtrAction.setDataTable([...(props?.data || [])]);
    }, [])
    return (
        <div>
            <TableList
                filter={true}
                formsFilter={[
                    {
                        type: 'custom',
                        placeholder: 'Nama',
                        component: (
                            <input type='text' className='form-control form-control-sm'
                                placeholder='Name or NIK'
                                value={mtrAction.filter}
                                onChange={(val) => mtrAction.setFilter(val.target.value)}
                            >
                            </input>
                        )
                    }
                ]}
                resetFilter={false}
                onFilter={() => mtrAction.onFilter()}
                curPage={1}
                pagination={false}
                minHeight="300px"
                maxHeight="500px"
                navtable={"TABLE 1"}
                withCard={false}
                withActions={false}
                columns={[
                    {
                        name: '#',
                        field: '#',
                        maxWidth: '50px',
                    },
                    {
                        name: 'Trainee NIK',
                        field: 'trainee_nik',
                    },
                    {
                        name: 'Trainee Name',
                        field: 'trainee_name',
                    },
                ]}
                data={[...mtrState?.dataTable]}
            />
        </div>
    );
}

export default (props) => <MtrProvider><ModalTrainee {...props} /></MtrProvider>;