import React, { createContext, useContext, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useModalAlert } from "../../../components/modalAlert";
import moment from "moment";

const context = createContext({
    dwnldDtCompReportAction: {},
    dwnldDtCompReportState: {}
});

export function DwnldDataCompReportProvider({ children }) {
    const { modalAction } = useModalAlert();
    const {
        closeLoading,
        setDataAlert,
    } = modalAction;    

    const { doGet, doPost } = useFetch();
    const [dataList, setDataList] = useState([]);
    const [loadingGet, setLoadingGet] = useState(false);
    const [batch, setBatch] = useState(moment().format("YYYY"));
    const [years, setYears] = useState([]);
    const [jobsite_code, setJobsite_code] = useState('');
    const [yearList, setYearList] = useState([]);

    async function getDataList() {
        setLoadingGet(true)
        try {
            const { status, payload, statusText } = await doPost({
                url: '/api/download-data/download-employee-compatencies',
                data: {
                    jobsite_code,
                    batch,
                    years: (years || []).map(v => v.value)
                }
            });
            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = [], code, msg } = payload;
                if (code === "00") {
                    setDataList(data || []);
                } else setDataAlert({
                    show: true, 
                    body: msg,
                    header: "Get Download Job Assignment",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Download Job Assignment",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Download Demografi Karyawan",
                callBack: () => null
            })
        }
        setLoadingGet(false)
    }

    const[dataJobsite, setDataJobsite] = useState([]);
    async function getAllJobsite() {
        try {
            const { status, statusText, payload } = await doGet({
                url: '/api/list-all-jobsite',
            });
            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDataJobsite(data?.jobsites);
                    setJobsite_code(data?.jobsites[0]?.jobsite_code);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get All Jobsite",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get All Jobsite",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get All Jobsite",
                callBack: () => null
            })
        }
    }

    useEffect(function () {
        getAllJobsite();
        getListFilter();
    }, []);

    
    async function getListFilter() {
        try {
            const { status, statusText, payload } = await doGet({
                url: '/api/reporting/filters-passed-competency',
            });
            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    let { filterBatches = [], filterClusters = []} = await data ?? {};
                    let nFilterBatches = await filterBatches.map(d => ({ value: d.batch, label: d.batch }) );
                    setYearList(nFilterBatches)
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Filters Donwload Employe",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Filters Donwload Employe",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Filters Donwload Employe",
                callBack: () => null
            })
        }
    }

    return <context.Provider
        value={{
            dwnldDtCompReportAction: {
                setJobsite_code,
                setBatch,
                getDataList,
                setYears
            },
            dwnldDtCompReportState: {
                jobsite_code,
                batch,
                dataJobsite,
                dataList,
                loadingGet,
                years,
                yearList
            }
        }}
    >{children}</context.Provider>
}

export default function useDwnldDataCompReport() {
    return useContext(context);
}