import React from 'react';
import useMentoringAdmin from '../useMentoringAdmin';
import './index.css';

function Index(props) {
    const{menAdminState, menAdminAction} = useMentoringAdmin();
    return (
        <div>
            <form>
                <div className='card'>
                    <div className='card-body p-5'>
                        <h6 className='text-center'>FORM PELAKSANAAN MENTORING</h6>
                        <h6 className='text-center'>{menAdminState.pageState?.dataPage?.form_name}</h6>
                        <div className='form-row mt-5'>
                            <div className='col-md-6'>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>Nama Mentor</label>
                                        <span className='text-wrap'>{menAdminState.formData?.trainer_name}</span>
                                    </div>
                                </div>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>NIK</label>
                                        <span className='text-wrap'>{menAdminState.formData?.trainer_nik}</span>
                                    </div>
                                </div>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>Section</label>
                                        <span className='text-wrap'>{menAdminState.formData?.section}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>                                
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill cs-date-picker">
                                        <label className='label-width'>Tanggal</label>                                        
                                        <span className='text-wrap'>{menAdminState.formData?.date}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='col-md-6'>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>Mentoring Activity ke - </label>                                        
                                        <span className='text-wrap'>{menAdminState.formData?.order_mentoring_activity}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='col-md-6'>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>Nama Pengawas</label>
                                        <span className='text-wrap'>{menAdminState.formData?.trainee_name}</span>
                                    </div>
                                </div>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width'>NIK Pengawas</label>
                                        <span className='text-wrap'>{menAdminState.formData?.trainee_nik}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='col-md-12'>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>Jabatan</label>
                                        <span className='text-wrap'>{menAdminState.listForm?.position}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='col-md-12'>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>Lokasi / Pit</label>
                                        <span className='text-wrap'>{menAdminState.formData?.location}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-row mb-3'>
                            <div className='col-md-12'>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start mb-2'>Materi & Praktik</label>
                                        <div>
                                            {
                                                [...(menAdminState.listForm?.listData || [])].map(({ data = [], parent }, i) => (
                                                    <div className='row px-1 mb-2'>
                                                        <div className='col-12'>
                                                            <b>{parent}</b>                                                            
                                                        </div>
                                                        <div className='col-md-6'>
                                                            {
                                                                [...(data || [])].filter(d => d?.position === "left_col-6").map((d, j) => (

                                                                    <div className="form-check justify-content-start">
                                                                        <input
                                                                            disabled
                                                                            checked={menAdminState.formCheck[d?.form_id]?.value}                                                                            
                                                                            className="form-check-input align-self-start mt-1" type="checkbox" id={d?.form_id} />
                                                                        <label className="form-check-label" htmlFor={d?.form_id}>
                                                                            {d?.question}
                                                                        </label>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                        <div className='col-md-6'>
                                                            {
                                                                [...(data || [])].filter(d => d?.position === "right_col-6").map((d, j) => (

                                                                    <div className="form-check justify-content-start">
                                                                        <input
                                                                            disabled
                                                                            checked={menAdminState.formCheck[d?.form_id]?.value}                                                                            
                                                                            className="form-check-input align-self-start mt-1" type="checkbox" id={d?.form_id} />
                                                                        <label className="form-check-label" htmlFor={d?.form_id}>
                                                                            {d?.question}
                                                                        </label>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                                <small>Catatan : Checklist Poin Materi/ Praktik yang diberikan kepada mentee/ pengawas produksi</small>
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='col-md-6'>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>Achievement </label>
                                        <span className='text-wrap'>{menAdminState.formData?.achievement} %</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='col-md-6'>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>Durasi </label>                                        
                                        <span className='text-wrap'>{menAdminState.formData?.duration}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='col-md-12'>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>Kendala & Support </label>
                                        <textarea
                                            disabled
                                            value={menAdminState.formData?.constraint_supporting}
                                            rows={5} type="number"
                                            className="form-control flex-fill align-self-start"
                                            placeholder='' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='col-md-12'>
                                <div className='form-inline mb-3'>
                                    <div className="form-group flex-fill">
                                        <label className='label-width align-self-start'>Catatan Pengembangan Selama Mentoring Berjalan baik/ Perlu ditingkatkan </label>
                                        <textarea
                                            disabled
                                            value={menAdminState.formData?.note}
                                            rows={5} type="number" className="form-control flex-fill align-self-start" placeholder='' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-row mt-5'>
                            <div className='col-md-12 text-md-right'>
                                <button type="button" className='btn btn-secondary btn-sm mr-1 float-left'
                                    onClick={menAdminAction.toTrainingPage}
                                >Back</button>
                                <button className='btn btn-sm btn-primary mr-1'
                                    onClick={() => window.print()}
                                ><i className='fa fa-print icon-nm mr-1'></i>Print</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Index;