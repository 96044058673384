import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useModalAlert } from "../../../components/modalAlert";
import useFetch from "../../../hooks/useFetch";
import ModalDecline from "./modal-decline/modalDecline";

const menAppContext = createContext({
    menAppState: {},
    menAppAction: {}
});
const iniStatePage = {
    title: "List Competency",
    type: "ListCom",
    dataPage: {
    }
};
const iniStateForm = {
    "job_mentoring_id": null,
    "training_id": null,
    "trainee_nik": "",
    "trainee_name": "",
    "trainer_nik": "",
    "trainer_name": "",
    "section": "",
    "supervisor_nik": "",
    "supervisor_name": "",
    "production_type": "",
    "date": "",
    "order_mentoring_activity": 1,
    "position": "",
    "location": "",
    "duration": 30,
    "constraint_supporting": "",
    "note": "",
}
export function MenAppProvider({ children }) {
    const [pageState, setPageState] = useState(iniStatePage);
    const { doGet, doPost } = useFetch();
    const [formData, setFormData] = useState(iniStateForm);
    const [formCheck, setFormCheck] = useState({});
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;

    const [loadingCom, setLoadingCom] = useState(false)
    const [listCom, setListCom] = useState([]);
    const getListCom = useCallback(async function () {
        setLoadingCom(true);

        try {
            const { status, statusText, payload } = await doGet(
                {
                    // url: '/api/job-mentoring/list-training-trainee',
                    url:'/api/job-mentoring/list-courses'
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setListCom(data?.listData || []);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get List Training",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List Training",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Training",
                callBack: () => null
            })
        }
        setLoadingCom(false)
    }, []);
    const [loadingForm, setLoadingForm] = useState(false)
    const [listForm, setListForm] = useState({});
    const getForm = useCallback(async function () {
        setLoadingForm(true);

        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/job-mentoring/form',
                    param: {
                        training_id: pageState.dataPage?.training_id
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const data = { ...payload };

                setListForm({ ...data });
                // if (code === "00") {
                //     // setListCom(data || []);
                // } else setDataAlert({
                //     show: true,
                //     body: msg,
                //     header: "Get List Form",
                //     callBack: () => null
                // })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List Form",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Form",
                callBack: () => null
            })
        }
        setLoadingForm(false)
    }, [pageState]);
    const [loadingDetil, setLoadingDetil] = useState(false)
    const getDetilForm = useCallback(async function () {
        setLoadingDetil(true);
        openLoading("Get Detail");
        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/job-mentoring/detail-answer',
                    param: {
                        training_id: pageState.dataPage?.training_id
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const data = { ...payload };
                const new_data = {
                    status: data?.status,
                    "job_mentoring_id": data?.job_mentoring_id,
                    "supervisor_nik": data?.supervisor_nik,
                    "supervisor_name": data?.supervisor_name,
                    "date": data?.date,
                    "order_mentoring_activity": data?.order_mentoring_activity,
                    "location": data?.location,
                    "duration": data?.duration,
                    "constraint_supporting": data?.constraint_supporting,
                    "note": data?.note,
                    training_id: data?.training_id,
                    trainee_nik: data?.trainee_nik,
                    trainee_name: data?.trainee_name,
                    section: data?.section,
                    trainer_name: data?.trainer_name,
                    trainer_nik: data?.trainer_nik,
                    position: data?.position,
                    production_type: data?.production_type,
                    achievement: data?.achievement,
                    form_name: data?.form_name
                }
                // Object.keys(new_data).forEach((d) => new_data[d] === null && delete new_data[d])
                const answer = [...(data?.answer_lessons || [])]?.reduce((prev, obj, i) => ({
                    ...prev, [obj?.form_id]: {
                        value: obj?.answer,
                        answer_id: obj?.answer_id,
                        form_id: obj?.form_id,
                    }
                }), {});
                setFormData((e) => ({
                    ...e,
                    ...new_data,
                }));
                setFormCheck({
                    ...answer
                })
                // if (code === "00") {
                //     // setFormData([...(data_input || [])]?.reduce((prev, obj, i) => ({ ...prev, [obj?.form_id]: { ...obj } }), {}))
                // } else setDataAlert({
                //     show: true,
                //     body: msg,
                //     header: "Get Detil Form",
                //     callBack: () => null
                // })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Detil Form",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Detil Form",
                callBack: () => null
            })
        }
        closeLoading();
        setLoadingDetil(false)
    }, [pageState]);

    const toFormPage = useCallback(function (detailPage, modeEdit = false) {
        setPageState({
            title: "Form Detail",
            type: "FormPage",
            dataPage: {
                ...detailPage
            }
        })
        setFormData({});
    }, [pageState]);
    const toListPage = useCallback(function () {
        setPageState({ ...iniStatePage })
    }, [pageState]);

    const confirmUpdateStatus = useCallback((data = {}) => {
        setDataConfirm({
            show: true,
            body: "Apa anda yakin?",
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Yes", onClick: () => doUpdateStatus(data), className: "btn btn-sm btn-success" },
                { name: "No", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }, [pageState]);
    const doUpdateStatus = useCallback(async function (data = {}) {
        closeConfirm()
        openLoading("Sending data, please wait...");
        try {
            const { status, payload, statusText } = await doPost({
                url: "/api/job-mentoring/update-status",
                data: {
                    ...data
                },
                method: 'PUT'
            });

            if (status !== 401) {
                if (status === 200 || status === 201) {
                    const { code, msg } = payload;
                    if (code === "00") {
                        setDataAlert({
                            body: msg,
                            show: true,
                            header: "Success",
                            callBack: () => {
                                setFormData({ ...iniStateForm });
                                setFormCheck({});
                                toListPage();
                            }
                        })
                    } else setDataAlert({
                        show: true,
                        body: msg,
                        header: "Info",
                        callBack: () => null
                    })
                } else setDataAlert({
                    show: true,
                    body: statusText,
                    header: "Info",
                    callBack: () => null
                })
            }
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Info",
                callBack: () => null
            })
        }
        closeLoading();
    }, []);

    const [modalDecline, setModalDecline] = useState({
        show: false,
        data: {}
    });
    const closeModalDecline = useCallback(function () {
        setModalDecline({
            data: {},
            show: false
        })
    }, [setModalDecline])
    const openModalDecline = useCallback(function (data) {
        setModalDecline({
            data,
            show: true
        })
    }, [modalDecline, setModalDecline])
    const setNote = useCallback(function (note = '') {
        setModalDecline((prev) => ({
            ...prev,
            data: {
                ...prev?.data,
                note: note
            }
        }))
    }, [setModalDecline]);

    useEffect(() => {
        if (pageState.type === 'ListCom') getListCom();
        if (pageState.type === 'FormPage') {
            getForm();
            getDetilForm();
        }
    }, [pageState]);
    return <menAppContext.Provider
        value={{
            menAppState: {
                pageState,
                listCom,
                loadingCom,
                loadingForm,
                loadingDetil,
                listForm,
                formData,
                formCheck,
                modalDecline
            },
            menAppAction: {
                toFormPage,
                toListPage,
                closeModalDecline,
                openModalDecline,
                confirmUpdateStatus,
                setNote
            }
        }}
    >
        <ModalDecline />
        {children}
    </menAppContext.Provider>
}
export default function useMentorApp() {
    return useContext(menAppContext);
}
