import React, { useState, useEffect } from 'react';
import FormEditor from '../component/form-editor';
import { useHistory, useParams, useLocation, Link } from 'react-router-dom';
import useFetch from '../../../../hooks/useFetch';
import { useModalAlert } from '../../../../components/modalAlert';

function Index(props) {
    const { doGet, doPost } = useFetch();
    const { modalAction } = useModalAlert();
    const {
        closeLoading,
        setDataAlert,
        openLoading,
        setDataConfirm,
        closeConfirm,
    } = modalAction;
    const history = useHistory();
    const { nik } = useParams();
    const { state = {} } = useLocation();

    const [detail, setDetail] = useState({
        "nik": "",
        "job_type": "",
        "name": "",
        "level": "",
        "grade": "",
        "job_title": "",
        "job_title_id": "",
        "function": "",
        "division": "",
        "department": "",
        "section": "",
        "directorat": "",
        "pers_area_code": "",
        "pers_area_name": "",
        "poh": "",
        "nik_direct_supervisor": "",
        "direct_supervisor_name": "",
        "role_id": "",
        "current_point": "",
        "attended_ratio": "",
        "join_date": "",
        "maritial_status": "",
        "age": "",
        "nik_indirect_supervisor": "",
        "indirect_supervisor_name": "",
        "type": ""
    });
    const GetData = async function () {
        openLoading("Please wait ...");
        try {
            const { status, statusText, payload } = await doGet({
                url: `/api/data-master/detail-user/${nik}`,
            });

            if (status === 401) {
                closeLoading();
            } else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDetail({ ...data });
                } else
                    setDataAlert({
                        show: true,
                        body: msg,
                        header: "Get Detail Employee",
                        callBack: () => null,
                    });
            } else
                setDataAlert({
                    show: true,
                    body: statusText,
                    header: "Get Detail Employee",
                    callBack: () => null,
                });
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Detail Employee",
                callBack: () => null,
            });
        }
        closeLoading();
    }
    const doSubmit = async function (data) {
        closeConfirm()
        openLoading("Sending data, please wait...");
        try {
            const { status, payload, statusText } = await doPost({
                url: "/api/data-master/update-user",
                data: {
                    nik,
                    ...data
                },
                method: "PUT"
            });

            if (status !== 401) {
                if (status === 200 || status === 201) {
                    const { code, msg } = payload;
                    if (code === "00") {
                        setDataAlert({
                            body: msg,
                            show: true,
                            header: "Success",
                            callBack: () => history.replace({
                                pathname: "/master-data/demografi-employee",
                                state: state
                            })
                        })

                    } else setDataAlert({
                        show: true,
                        body: msg,
                        header: "Info",
                        callBack: () => null
                    })
                } else setDataAlert({
                    show: true,
                    body: statusText,
                    header: "Info",
                    callBack: () => null
                })
            }
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Info",
                callBack: () => null
            })
        }
        closeLoading();
    };
    const confirmSubmit = (data = {}) => {
        setDataConfirm({
            show: true,
            body: "Sebelum melanjutkan, silahkan cek kembali. Lanjutkan ?",
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                {
                    name: "Yes", onClick: () => doSubmit(data), className: "btn btn-sm btn-success"
                },
                { name: "No", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    useEffect(() => {
        if (nik) {
            GetData();
        }
    }, [nik])

    return (
        <div>
            <div className="row">
                <div className="col-6">
                    <section className="content-header">
                        <div className="container-fluid">
                            <h4>Master Data</h4>
                            <h6>Edit Demografi Employee</h6>
                        </div>
                    </section>
                </div>
                <div className="col-6">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end">
                            <li className="breadcrumb-item" aria-current="page">
                                Master Data
                            </li>
                            <li className="breadcrumb-item" aria-current="page">
                                <Link to={{
                                    pathname: "/master-data/demografi-employee",
                                    state: state
                                }} >
                                    Demografi Employee
                                </Link>
                            </li>
                            <li
                                className="breadcrumb-item active"
                                aria-current="page"
                            >
                                Edit
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <FormEditor defaultForm={detail} edit={true} kembaliOnclick={() => history.replace({
                        pathname: "/master-data/demografi-employee",
                        state: state
                    })} OnValidSubmit={confirmSubmit} />
                </div>
            </div>
        </div>
    );
}

export default Index;