import React from 'react';
import moment from 'moment';
import useDwnldDataCompReport, { DwnldDataCompReportProvider } from './useDwnldDataCompReport';

import { BiFile } from 'react-icons/bi';
import TableToHTML from './export';
import { htmlToExcel } from '../../../modules';

function Index(props) {
    const { dwnldDtCompReportAction, dwnldDtCompReportState } = useDwnldDataCompReport();

    return (
        <div>
            <div className='row'>
                <div className='col-6'>
                    <section className="content-header">
                        <div className="container-fluid">
                            <h4>Download Data</h4>
                            <h6>Assesment Report</h6>
                        </div>
                    </section>
                </div>
                <div className='col-6'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end">
                            <li className="breadcrumb-item active" aria-current="page">
                                Assesment Report
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>

            {/*  */}
            <div className="card pb-2">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <span>Download data berdasarkan :</span>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-md col-lg col-sm-6">
                            <div className="form-group row mb-1">
                                <label htmlFor="inputEmail3" className="col-sm-3 font-weight-normal">Jobsite</label>
                                <div className="col-sm-9">
                                    <select className='form-select'
                                        value={dwnldDtCompReportState?.jobsite_code}
                                        onChange={(val) => dwnldDtCompReportAction.setJobsite_code(val.target.value)}
                                    >
                                        {
                                            [...(dwnldDtCompReportState.dataJobsite || [])].map((d, i) => (
                                                <option value={d?.jobsite_code} key={i}>{d?.jobsite_name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="form-group row mb-1">
                                <label htmlFor="inputPassword3" className="col-sm-3 font-weight-normal">Batch</label>
                                <div className="col-sm-9">
                                    <input type='number' className='form-control form-control-sm'
                                        placeholder='Batch'
                                        value={dwnldDtCompReportState?.batch}
                                        onChange={(val) => dwnldDtCompReportAction.setBatch(val.target.value)}
                                    >
                                    </input>
                                </div>
                            </div>

                            
                            <div className="form-group row mb-1 mt-4">
                                <div className='d-flex justify-content-end h-100 mb-3'>
                                    <button className='btn btn-sm btn-success mt-auto'
                                        style={{ height: 'fit-content' }}
                                        disabled={dwnldDtCompReportState.loadingGet}
                                        onClick={async () => {
                                            await dwnldDtCompReportAction.getDataList();
                                            htmlToExcel("table-to-xls", `${dwnldDtCompReportState?.batch}_Data Assesment Report_${moment(Date.now()).format("YYYY-MM-DD HH_mm_SS")}`, "statement")
                                        } }
                                    >
                                        <BiFile style={{ fonstSize: '12pt' }} /> 
                                        {dwnldDtCompReportState.loadingGet ? "Mohon menunggu" : "Export Excel" }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TableToHTML />
        </div>
    );
}

export default () => <DwnldDataCompReportProvider><Index /></DwnldDataCompReportProvider>;