import React from 'react';
import { TableList } from '../../../../components';
import useTirRev from '../useTirRev';

function Index(props) {
    const { tirRevAction, tirRevState } = useTirRev();
    return (
        <div className='card'>
            <div className='card-body'>
                <div className='row mb-3'>
                    <div className='col-md-12 col-sm-12'>
                        <TableList
                            isLoading={tirRevState.loadingTraining}
                            data={tirRevState.listTraining || []}
                            withCard={false}
                            columns={[
                                {
                                    name: '#',
                                    field: '#',
                                },
                                {
                                    name: 'Competency',
                                    field: 'competency_name',
                                },
                                {
                                    name: 'Course',
                                    field: 'course_name',
                                },
                                {
                                    name: 'Form',
                                    field: '',
                                    minWidth: 100,
                                    onRender: (item) => (
                                        <div>
                                            <ul>
                                                {
                                                    [...(item?.listForm || [])].map((d, i) => (
                                                        <li className='text-primary' role={'button'}
                                                            onClick={() => tirRevAction.toFormPage(d, true)}
                                                        >Form {i + 1}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    )
                                },
                                {
                                    name: 'Link Video',
                                    field: '',
                                    minWidth: 100,
                                    onRender: (item) => (
                                        <div>
                                            <ul>
                                                {
                                                    [...(item?.listVideoUrl || [])].map((d, i) => (
                                                        <li key={i} className='text-primary' role={'button'} onClick={() => tirRevAction.setModalTautan({
                                                            show: true,
                                                            modeEdit: true,
                                                            data: { ...d, training_id: item?.training_id }
                                                        })}>Link {i + 1}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    )
                                },                                
                            ]}
                            withActions={false}
                            pagination={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;