import React from 'react';
import useDwnldDataJM from './useDwnldDataJM';

const TableToHTML = () => {
    const { dwnldDtJMState } = useDwnldDataJM();
    return (
        <table className="w-100 f-10" id="table-to-xls" style={{ display: "none" }}>
            <thead>
                <tr>
                    <th>#</th>
                    <th>NIK</th>
                    <th>Nama Karyawan</th>
                    <th>Competency Name</th>
                    <th>Course Name</th>
                    <th>Judul Mentoring</th>
                    <th>Tanggal Create</th>
                    <th>Tanggal Update</th>
                    <th>Status</th>
                    {/* <th>Pers Area Code</th> */}
                </tr>
            </thead>
            <tbody>
                {
                    (dwnldDtJMState.dataList || []).map(({
                        nik,
                        name,
                        competency_name,
                        course_name,
                        form_title,
                        created_at,
                        updated_at,
                        jm_status,
                        // pers_area_code,
                    }, i) => (
                        <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{nik}</td>
                            <td>{name}</td>
                            <td>{competency_name}</td>
                            <td>{course_name}</td>
                            <td>{form_title}</td>
                            <td>{created_at}</td>
                            <td>{updated_at}</td>
                            <td>{jm_status}</td>
                            {/* <td>{pers_area_code}</td> */}
                        </tr>
                    ))
                }
            </tbody>
        </table>
    );
}

export default TableToHTML;