import React, { createContext, useContext } from "react";

const pInfoContext = createContext({
    pInfoState: {},
    pInfoAction: {}
})

export function PersonalInfoProvider({ children }) {

    function getInitialName(fullName = '') {
        var nameSplit = fullName.split(' ');
        if(nameSplit.length > 1){
            return (nameSplit.shift()?.charAt(0) + nameSplit.pop()?.charAt(0)).toUpperCase();
        }else{
            return nameSplit[0].charAt(0).toUpperCase();
        }
    }
    return <pInfoContext.Provider
        value={{
            pInfoState: {},
            pInfoAction: {
                getInitialName
            }
        }}
    >{children}</pInfoContext.Provider>
}

export default function usePersonalInfo() {
    return useContext(pInfoContext)
}