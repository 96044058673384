import React from 'react';
import { StatusIcon, TableList } from '../../../../components';
import useTirOnline from '../useTirOnline';

function Index(props) {
    const { tirAction, tirState } = useTirOnline();
    return (
        <div className='card'>
            <div className='card-body'>
                <div className='row mb-3'>
                    <div className='col-md-12 col-sm-12'>
                        <TableList
                            isLoading={tirState.loadingTraining}
                            data={tirState.listTraining || []}
                            withCard={false}
                            columns={[
                                {
                                    name: '#',
                                    field: '#',
                                },
                                {
                                    name: 'Competency',
                                    field: 'competency_name',
                                },
                                {
                                    name: 'Course',
                                    field: 'course_name',
                                },
                                {
                                    name: 'Isi Form',
                                    field: '',
                                    minWidth: 300,
                                    // className: 'text-right',
                                    onRender: (item) => (
                                        <div>
                                            <ul>
                                                {
                                                    [...(item?.listForm || [])].map((d, i) => (
                                                        <>
                                                            <li className='text-primary tw-mb-[5px]' role={'button'}
                                                                onClick={() => tirAction.toFormPage({ ...d, training_id: item?.training_id }, true)}
                                                            >
                                                                <div className='tw-flex tw-justify-between'>
                                                                    <span>{d?.title}</span>
                                                                    <div><StatusIcon status={d?.status} /></div>
                                                                </div>
                                                            </li>
                                                        </>
                                                    ))
                                                }
                                            </ul>
                                            <button
                                                onClick={() => tirAction.setModalAddForm({ show: true, title: '', data: { ...item } })//tirAction.toFormPage({ ...item }, false)
                                                }
                                                className='btn btn-sm btn-primary'>
                                                Add Form
                                            </button>
                                        </div>
                                    )
                                },
                                {
                                    name: 'Input Link Video',
                                    field: '',
                                    minWidth: 300,
                                    // className: 'text-right',
                                    onRender: (item) => (
                                        <div>
                                            <ul>
                                                {
                                                    [...(item?.listVideoUrl || [])].map((d, i) => (
                                                        <>
                                                            <li key={i} className='text-primary tw-mb-[5px]' role={'button'}
                                                                onClick={() => tirAction.setModalTautan({
                                                                    show: true,
                                                                    modeEdit: true,
                                                                    data: { ...d, training_id: item?.training_id }
                                                                })}
                                                            >
                                                                <div className='tw-flex tw-justify-between'>
                                                                    <span>{d?.title}</span>
                                                                    <div><StatusIcon status={d?.status} /></div>
                                                                </div>                                                             
                                                            </li>                                                            
                                                        </>                                                        
                                                    ))
                                                }
                                            </ul>
                                            {/* dikomen untuk sementara waktu */}
                                            {/* <button
                                                onClick={() => tirAction.setModalTautan({
                                                    show: true,
                                                    modeEdit: false,
                                                    data: { training_id: item?.training_id, url: "https://" }
                                                })}
                                                className='btn btn-sm btn-primary'>
                                                Add Link
                                            </button> */}
                                        </div>
                                    )
                                },
                            ]}
                            withActions={false}
                            pagination={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;