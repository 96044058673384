import React from 'react';
import { ListGroup, Tab, Tabs } from 'react-bootstrap';
import { TabComponent, TabContent, TableList } from '../../components';
import TabRead from './component/tabRead';
import TabUnread from './component/tabUnread';
import useAllNotif, { AllNotifProvider } from './useAllNotif';

function Index(props) {
    const { allNotAction, allNotState } = useAllNotif();
    return (
        <div>
            <section className="content-header">
                <div className="container-fluid">
                    <h4>All Notification</h4>
                    <h6></h6>
                </div>
            </section>
            <div className='card mt-3'>
                <div className='card-body p-2'>
                    <Tabs activeKey={allNotState.tab}
                        onSelect={(e) => allNotAction.setTab(e)}
                    >
                        <Tab eventKey="unread" title="Unread">
                            <TabUnread />
                        </Tab>
                        <Tab eventKey="read" title="Read">
                            <TabRead />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}

export default (props) => <AllNotifProvider><Index {...props} /> </AllNotifProvider>;