import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useModalAlert } from '../../../components/modalAlert';
import useFetch from '../../../hooks/useFetch';

const PHMOContext = createContext({
    PHMOState: {},
    PHMOAction: {}
});


export function PHMOProvider({ children }) {
    const { doGet, doPost, wait } = useFetch();
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const [pageStatus, setPageStatus] = useState({
        rendered: "ListTraining",
        paramState: {},
        isLoading: true
    });
    const [modalStatus, setModalStatus] = useState({
        show: false,
        data: {}
    });
    const [modalDecline, setModalDecline] = useState({
        show: false,
        data: {}
    });
    useEffect(function () { console.log(pageStatus) }, [pageStatus])
    const [listTraining, setListTraining] = useState([]);
    const [listHm, setListHm] = useState([]);
    const [listTrainee, setListTrainee] = useState([])
    const setLoadingPage = (isLoading) => setPageStatus(v => ({ ...v, isLoading }))
    const [paramHistory, setParamHistory] = useState({})
    const getListTraining = useCallback(async function () {
        setLoadingPage(true);
        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/hm/list-training',
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setListTraining(data || []);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get List Training",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List Training",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Training",
                callBack: () => null
            })
        }
        setLoadingPage(false)
    }, [pageStatus])

    useEffect(function () {
        console.log(paramHistory)
    }, [paramHistory])
    const getListHm = async function () {
        setLoadingPage(true);
        const { trainee_nik, training_id } = pageStatus.paramState
        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/hm/mylist',
                    param: {
                        "trainee_nik": trainee_nik,
                        "training_id": training_id
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = [], code, msg } = payload;
                if (code === "00") {
                    setListHm(data || []);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get List Hm",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List Hm",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Hm",
                callBack: () => null
            })
        }
        setLoadingPage(false)
    }

    const getListTrainee = async function () {
        setLoadingPage(true);
        const { training_id, course_id, competency_id } = pageStatus.paramState
        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/hm/list-trainee',
                    param: {

                        "competency_id": competency_id,
                        "course_id": course_id

                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data: {
                    listData = []
                }, code, msg } = payload;
                if (code === "00") {
                    console.log(listData)
                    setListTrainee(listData || []);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get List Hm",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List Hm",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Hm",
                callBack: () => null
            })
        }
        setLoadingPage(false)
    }
    const toApprovalPage = function (paramState) {
        setParamHistory(pageStatus.paramState)
        setPageStatus({
            rendered: "Approval",
            paramState,
            isLoading: true
        })
    }

    const toListTrainieePage = function (paramState) {
        setPageStatus({
            rendered: "ListTrainee",
            paramState,
            isLoading: true
        })
    }
    const resetPage = () => setPageStatus({
        rendered: "ListTraining",
        paramState: {},
        isLoading: true
    })
    useEffect(function () {
        if (pageStatus.rendered === "ListTraining") getListTraining()
        if (pageStatus.rendered === "Approval") getListHm()
        if (pageStatus.rendered === "ListTrainee") getListTrainee()
    }, [pageStatus.rendered])
    const doApproval = useCallback(function (hm_id, status_, note_) {
        let body = `Anda akan ${status_ === "approved" ? "Approve " : "Decline "} HM ini. Lanjutkan?`
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                {
                    name: "Yes", onClick: async () => {

                        closeModal()
                        closeConfirm();
                        openLoading("Submit data ...")
                        try {
                            const { status, payload, statusText } = await doPost({
                                url: `/api/hm/${hm_id}`,
                                method: "put",
                                data: {
                                    status: status_,
                                    note: note_
                                }
                            });
                            const { rendered, paramState, isLoading } = pageStatus
                            if (status !== 401) {
                                if (status === 200 || status === 201) {
                                    const { code, msg } = payload;
                                    if (code === "00") {
                                        setDataAlert({
                                            body: msg,
                                            show: true,
                                            header: "Success",
                                            callBack: () => {
                                                getListHm();


                                            }
                                        })
                                    } else setDataAlert({
                                        show: true,
                                        body: msg,
                                        header: "Approval HM",
                                        callBack: () => null
                                    })
                                } else setDataAlert({
                                    show: true,
                                    body: statusText,
                                    header: "Approval HM",
                                    callBack: () => null
                                })
                            }
                        } catch (error) {
                            setDataAlert({
                                show: true,
                                body: error.toString(),
                                header: "Info",
                                callBack: () => null
                            })
                        }
                        closeLoading()


                    }, className: "btn btn-sm btn-success"
                },
                { name: "No", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }, [pageStatus, closeModal])
    const closeModal = useCallback(function () {
        setModalStatus({
            data: {},
            show: false
        })
    }, [setModalStatus])
    const openModalDetil = useCallback(function (data) {
        setModalStatus({
            data,
            show: true
        })
    }, [modalStatus, setModalStatus])

    const closeModalDecline = useCallback(function () {
        setModalDecline({
            data: {},
            show: false
        })
    }, [setModalDecline])
    const openModalDecline = useCallback(function (data) {
        setModalDecline({
            data,
            show: true
        })
    }, [modalDecline, setModalDecline])
    const setNote = useCallback(function (note = '') {
        setModalDecline((prev) => ({
            ...prev,
            data: {
                ...prev?.data,
                note: note
            }
        }))
    }, [setModalDecline])
    return <PHMOContext.Provider value={{
        PHMOState: {
            listHm,
            pageStatus,
            listTraining,
            modalStatus,
            modalDecline,
            listTrainee,
            paramHistory
        },
        PHMOAction: {
            toApprovalPage,
            resetPage,
            doApproval,
            closeModal,
            openModalDetil,
            closeModalDecline,
            openModalDecline,
            toListTrainieePage,
            setNote
        }
    }}>{children}</PHMOContext.Provider>
}

export default () => useContext(PHMOContext)
