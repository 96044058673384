import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useModalAlert } from "../../../components/modalAlert";
import useFetch from "../../../hooks/useFetch";

const menStsContext = createContext({
    menStsState: {},
    menStsAction: {}
})

const iniStatePage = {
    title: "List Pengawas",
    type: "ListTrainee",
    dataPage: {
    }
};
const iniStateForm = {
    "job_mentoring_id": null,
    "training_id": null,
    "trainee_nik": "",
    "trainee_name": "",
    "trainer_nik": "",
    "trainer_name": "",
    "section": "",
    "supervisor_nik": "",
    "supervisor_name": "",
    "production_type": "",
    "date": "",
    "order_mentoring_activity": 1,
    "position": "",
    "location": "",
    "duration": 30,
    "constraint_supporting": "",
    "note": "",
}
export function MenStsProvider({ children }) {
    const { doGet, doPost } = useFetch();
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const [pageState, setPageState] = useState(iniStatePage);
    const [formData, setFormData] = useState(iniStateForm);
    const [btnSubType, setBtnSubType] = useState("draft");
    const [formCheck, setFormCheck] = useState({});

    const [loadingForm, setLoadingForm] = useState(false)
    const [listForm, setListForm] = useState({});
    const getForm = useCallback(async function () {
        setLoadingForm(true);

        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/job-mentoring/form',
                    param: {
                        training_id: pageState.dataPage?.training_id
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const data = { ...payload };

                setListForm({ ...data });
                setFormData((e) => ({
                    ...e,
                    position: data?.position,
                    section: data?.section,
                    trainee_name: data?.trainee_name,
                    trainee_nik: data?.trainee_nik,
                    trainer_name: data?.trainer_name,
                    trainer_nik: data?.trainer_nik,
                }))
                // if (code === "00") {
                //     // setListCom(data || []);
                // } else setDataAlert({
                //     show: true,
                //     body: msg,
                //     header: "Get List Form",
                //     callBack: () => null
                // })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List Form",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Form",
                callBack: () => null
            })
        }
        setLoadingForm(false)
    }, [pageState]);
    const [loadingDetil, setLoadingDetil] = useState(false)
    const getDetilForm = useCallback(async function () {
        setLoadingDetil(true);
        openLoading("Get Detail");
        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/job-mentoring/detail-answer',
                    param: {
                        training_id: pageState.dataPage?.training_id
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const data = { ...payload };
                const new_data = {
                    "job_mentoring_id": data?.job_mentoring_id,
                    "supervisor_nik": data?.supervisor_nik,
                    "supervisor_name": data?.supervisor_name,
                    "date": data?.date,
                    "order_mentoring_activity": data?.order_mentoring_activity,
                    "location": data?.location,
                    "duration": data?.duration,
                    "constraint_supporting": data?.constraint_supporting,
                    "note": data?.note,
                    training_id: data?.training_id,
                    trainee_nik: data?.trainee_nik,
                    trainee_name: data?.trainee_name,
                    section: data?.section,
                    trainer_name: data?.trainer_name,
                    trainer_nik: data?.trainer_nik,
                    position: data?.position,
                    production_type: data?.production_type,
                    status: data?.status,
                    achievement: data?.achievement
                }
                Object.keys(new_data).forEach((d) => (new_data[d] === null || new_data[d] === undefined) && delete new_data[d])
                const answer = [...(data?.answer_lessons || [])]?.reduce((prev, obj, i) => ({
                    ...prev, [obj?.form_id]: {
                        value: obj?.answer,
                        answer_id: obj?.answer_id,
                        form_id: obj?.form_id,
                    }
                }), {});
                setFormData((e) => ({
                    ...e,
                    ...new_data,
                }));
                setFormCheck({
                    ...answer
                })
                // if (code === "00") {
                //     // setFormData([...(data_input || [])]?.reduce((prev, obj, i) => ({ ...prev, [obj?.form_id]: { ...obj } }), {}))
                // } else setDataAlert({
                //     show: true,
                //     body: msg,
                //     header: "Get Detil Form",
                //     callBack: () => null
                // })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Detil Form",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Detil Form",
                callBack: () => null
            })
        }
        closeLoading();
        setLoadingDetil(false)
    }, [pageState, formData]);

    const [loadingCom, setLoadingCom] = useState(false)
    const [listCom, setListCom] = useState([]);
    const getListCom = useCallback(async function () {
        setLoadingCom(true);

        try {
            const { status, statusText, payload } = await doGet(
                {
                    // url: '/api/job-mentoring/list-training-trainer',
                    url: '/api/job-mentoring/list-courses',
                    param: {
                        ...pageState.dataPage
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setListCom(data?.listData || []);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get List Training",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List Training",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Training",
                callBack: () => null
            })
        }
        setLoadingCom(false)
    }, [pageState]);

    const [loadingTrainee, setLoadingTrainee] = useState(false)
    const [dataTrainee, setDataTrainee] = useState({});
    const [pagination, setPagination] = useState({
        page: 1, show: 10, order_by: "trainee_name", order_type: "asc"
    });
    const [filter, setFilter] = useState({
        search: "",
    });
    const getListTrainee = useCallback(async function () {
        try {
            setLoadingTrainee(true);
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/job-assignment/list-trainee',
                    param: {
                        ...filter,
                        "page": pagination.page,
                        "row_in_page": pagination.show,
                        "order_by": pagination.order_by,
                        "order_type": pagination.order_type,
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    // setDataTrainee((data || []));
                    setDataTrainee({ ...data });
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get List Trainee",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List Trainee",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Trainee",
                callBack: () => null
            })
        }
        setLoadingTrainee(false)
    }, [pageState, pagination, filter]);
    function nextPage({ page, limit }) {
        setPagination(prevState => ({
            ...prevState,
            page, show: limit,
        }))
    }
    function prevPage({ page, limit }) {
        setPagination(prevState => ({
            ...prevState,
            page, show: limit,
        }))
    }
    function changeShow({ page, limit }) {
        setPagination(prevState => ({
            ...prevState,
            page, show: limit,
        }))
    }
    function onFilter() {
        getListTrainee()
    }

    const handleChange = (name, val) => {
        setFormData((e) => ({
            ...e,
            [name]: val
        }))
    }
    const handleFormCheck = (name, val) => {
        setFormCheck((e) => ({
            ...e,
            [name]: val
        }))
    }
    const onSubmitForm = useCallback(() => {
        const dataCheck = Object.keys((formCheck || {})).map((d) => {
            const x = { ...formCheck[d] }
            return {
                answer_type: x?.answer_type,
                answer_id: x?.answer_id || null,
                form_id: x?.form_id,
                answer: x?.value ? 1 : 0
            }
        })
        setDataConfirm({
            show: true,
            body: "Apa anda yakin?",
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                {
                    name: "Yes", onClick: () => {
                        closeConfirm();
                        doSubmitForm({
                            ...formData,
                            answer_lessons: dataCheck,
                            status: btnSubType
                        })
                    }, className: "btn btn-sm btn-success"
                },
                { name: "No", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }, [btnSubType, formCheck, formData,])
    const doSubmitForm = useCallback(async function (data = {}) {
        closeConfirm()
        openLoading("Sending data, please wait...");
        try {
            const { status, payload, statusText } = await doPost({
                url: "/api/job-mentoring/add-edit-answer",
                data: {
                    ...data,
                    training_id: pageState.dataPage?.training_id
                }
            });

            if (status !== 401) {
                if (status === 200 || status === 201) {
                    const { code, msg } = payload;
                    if (code === "00") {
                        setDataAlert({
                            body: msg,
                            show: true,
                            header: "Success",
                            callBack: () => {
                                setFormData(iniStateForm);
                                setFormCheck({})
                                toListPage({ trainee_nik: pageState.dataPage?.trainee_nik });
                            }
                        })
                    } else setDataAlert({
                        show: true,
                        body: msg,
                        header: "Info",
                        callBack: () => null
                    })
                } else setDataAlert({
                    show: true,
                    body: statusText,
                    header: "Info",
                    callBack: () => null
                })
            }
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Info",
                callBack: () => null
            })
        }
        closeLoading();
    }, [pageState]);

    const toFormPage = useCallback(function (detailPage, modeEdit = false) {
        setPageState({
            title: "Form",
            type: "FormPage",
            dataPage: {
                ...detailPage,
                modeEdit: modeEdit
            }
        });
        setFormData((e) => ({
            ...e,
            training_id: detailPage?.training_id,
            // trainee_nik: detailPage?.trainee_nik,
            // trainee_name: detailPage?.trainee_name,
            // section: detailPage?.section,
            // trainer_name: userInfo?.name,
            // trainer_nik: userInfo?.nik,
            // position: userInfo?.job_title,
            production_type: "PIT Service"
        }))
    }, [pageState]);
    const toListPage = useCallback(function (data = {}) {
        setPageState({
            title: "List Course",
            type: "ListCom",
            dataPage: {
                ...data
            }
        })
    }, [pageState]);
    const toListTraineePage = useCallback(function (detailPage = {}) {
        setPageState({ ...iniStatePage })
    }, [pageState]);

    useEffect(() => {
        if (pageState.type === 'ListCom') getListCom();
        if (pageState.type === 'FormPage') {
            getForm();
            getDetilForm();
        }
        if (pageState.type === 'ListTrainee') getListTrainee();
    }, [pageState, pagination]);
    return <menStsContext.Provider
        value={{
            menStsState: {
                dataTrainee,
                pageState,
                formData,
                formCheck,
                btnSubType,
                pagination,
                filter,
                loadingCom,
                listCom,
                listForm,
                loadingTrainee,
                loadingCom

            },
            menStsAction: {
                nextPage,
                prevPage,
                changeShow,
                onFilter,
                toFormPage,
                toListPage,
                toListTraineePage,
                setBtnSubType,
                handleChange,
                handleFormCheck,
                onSubmitForm
            }
        }}
    >
        {children}
    </menStsContext.Provider>
}
export default function useMenSts() {
    return useContext(menStsContext);
}