import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { TableList } from '../../../../components';
import useDetailTrainee, { DtrProvider } from './useDetailTrainee';

function Index(props) {
    const { dtrAction, dtrState } = useDetailTrainee();
    const history = useHistory();
    return (
        <div>
            <section className="content-header">
                <div className="container-fluid">
                    <div className='row'>
                        <div className='col-md-6'>
                            <h4>Job Assignment</h4>
                            <h6>Detail Trainee</h6>
                        </div>
                        <div className='col-md-6'>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-end">
                                    <li className="breadcrumb-item"><Link to={"/development/job-assignment"}>List Trainee</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Detail Trainee</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <div className='card'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='font-weight-bold mb-5'><label style={{ width: '150px' }}>Trainee</label>: {dtrState.dataState?.trainee_name}</div>
                            <TableList
                                withCard={false}
                                filter={false}
                                isLoading={dtrState.loadingGet}
                                minHeight="300px"
                                maxHeight="500px"
                                navtable={"TABLE 1"}
                                withActions={false}
                                actionType={1}
                                columns={[
                                    {
                                        name: '#',
                                        field: '#',
                                        maxWidth: '50px',
                                    },
                                    {
                                        name: "Competency Name",
                                        field: 'competency_name',
                                    },
                                    {
                                        name: "Course Name",
                                        field: 'course_name',
                                    },
                                    {
                                        name: "Aksi",
                                        field: 'trainee_nik',
                                        className: "text-right",
                                        minWidth: '100px',
                                        onRender: (item) => (
                                            <button className='btn btn-sm btn-primary'
                                            onClick={() => history.push({
                                                pathname: '/development/job-assignment/list',
                                                state: {
                                                    trainee_name: dtrState.dataState?.trainee_name,
                                                    training_id : item?.training_id,
                                                    training_name : `${item?.competency_name} - ${item?.course_name}`
                                                }
                                            })}
                                            >Periksa Job Assigment</button>
                                        )
                                    }
                                ]}
                                data={[...(dtrState.data || [])]}
                            />
                        </div>
                    </div>
                    <div className='mt-3'>
                        <button className='btn btn-sm btn-secondary'
                            onClick={() => history.goBack()}
                        >Back</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default (props) => <DtrProvider><Index {...props} /></DtrProvider>;