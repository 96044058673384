import React from 'react';
import LogoBuma from '../../../images/logo2_revisi.png';
import './login.css';
import useLogin, { LoginProvider } from './use_login';

function Login(props) {
    const { lgnAction, lgnState } = useLogin();
    return (
        <div className='container-fluid bg-login'>
            <div className='row justify-content-center my-auto'>
                <div className='col-lg-4 col-md-4 col-sm-6 col-10 col-xl-3'>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault(e);
                            lgnAction.onSubmit();
                        }}
                    >
                        <div className='card bg-white card-login text-center px-4 py-5'>
                            <img src={LogoBuma} className="logo-buma mb-3"></img>
                            {/* <h5 className='font-color-login font-weight-bold'>DATA MANAGEMENT</h5>
                            <span className='text-wrap font-color-login mb-4'>FRONTLINE DEVELOPMENT</span> */}
                            <div className="input-group input-group-rounded mb-3 font-color-login ">
                                <div className="input-group-prepend">
                                    <span className="input-group-text input-group-icon border-0 bg-white shadow-sm" id="basic-addon1">
                                        <i className="fa fa-user font-color-login "></i>
                                    </span>
                                </div>
                                <input
                                    type="text"
                                    className="form-control input-group-field border-0 font-color-login shadow-sm"
                                    placeholder="Username / Nomor Induk" aria-label="Usernames"
                                    aria-describedby="basic-addon1"
                                    value={lgnState.username}
                                    onChange={(e) => lgnAction.setUsername(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="input-group input-group-rounded mb-5 font-color-login ">
                                <div className="input-group-prepend">
                                    <span className="input-group-text input-group-icon border-0 bg-white shadow-sm" id="basic-addon2">
                                        <i className="fa fa-lock font-color-login "></i>
                                    </span>
                                </div>
                                <input
                                    type="password"
                                    className="form-control input-group-field border-0 font-color-login shadow-sm"
                                    placeholder="Password" aria-label="Passwrod" aria-describedby="basic-addon2"
                                    value={lgnState.password}
                                    onChange={(e) => lgnAction.setPassword(e.target.value)}
                                    required
                                />
                            </div>
                            
                            <div className='mb-3'>
                                <button type='submit' className='btn btn-sm btn-success login-btn-cs px-4'>Login</button>
                            </div>
                            {/* <div className='mb-3 text-left'>
                                <span className='font-color-login'>Reset Password / Active My Account</span>
                            </div> */}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default () => <LoginProvider><Login /></LoginProvider>;