import moment from 'moment';
import React from 'react';
import { Modal } from 'react-bootstrap';
import useResultA from '../../useResultA';

function ModalEditor(props) {
    const { raAction: {
        closeModalEditor = () => null,
        handleChange = () => null,
        confirmSubmit = () => null
    }, raState: {
        showEditor: {
            show = false,
            data = {}
        },
        dataEditor = {}
    } } = useResultA();
    return (
        <div>
            <Modal
                dialogClassName='modal-editor-trainee'
                backdropClassName='bg-transparent'
                show={show}
                onHide={closeModalEditor}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        UPDATE DATA
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault(e);
                            confirmSubmit();
                        }}
                    >
                        <div className="form-group row">
                            <label className="col-sm-5 col-form-label">Result Assesment</label>
                            <div className="col-sm-7">
                                <select className='form-select'
                                    value={dataEditor?.score}
                                    required
                                    onChange={(e) => handleChange('score', e.target.value)}
                                >
                                    <option value={''}>- Pilih -</option>
                                    <option value={'competent'}>Competent</option>
                                    <option value={'not_competent'}>Not Competent</option>
                                </select>
                                {/* <input
                                    required
                                    type="number"
                                    className="form-control"
                                    min={0}
                                    max={100}
                                    value={dataEditor?.score}
                                    onChange={(e) => handleChange('score', e.target.value)}
                                /> */}
                            </div>
                        </div>
                        {/* <div className="form-group row">
                            <label className="col-sm-5 col-form-label">Nilai Post Test</label>
                            <div className="col-sm-7">
                                <input
                                    required
                                    type="number"
                                    className="form-control"
                                    min={0}
                                    max={100}
                                    value={dataEditor?.post_test_score}
                                    onChange={(e) => handleChange('post_test_score', e.target.value)}
                                />
                            </div>
                        </div> */}
                        <div className="form-group row">
                            <label className="col-sm-5 col-form-label">Start</label>
                            <div className="col-sm-7">
                                <span className='date-style'>{dataEditor?.start_date ? moment(dataEditor?.start_date).format('DD/MM/YYYY') : ''}</span>
                                {/* <input
                                    readOnly
                                    type="date"
                                    className="form-control"
                                    value={dataEditor?.start_date}
                                    max={dataEditor?.end_date}
                                    onChange={(e) => handleChange('start_date', e.target.value)}
                                /> */}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-5 col-form-label">Finish</label>
                            <div className="col-sm-7">
                                <span className='date-style'>{dataEditor?.end_date ? moment(dataEditor?.end_date).format('DD/MM/YYYY') : ''}</span>
                                {/* <input
                                    readOnly
                                    type="date"
                                    min={dataEditor?.start_date}
                                    className="form-control"
                                    value={dataEditor?.end_date}
                                    onChange={(e) => handleChange('end_date', e.target.value)}
                                /> */}
                            </div>
                        </div>
                        {/* <div className="form-group row">
                            <label className="col-sm-5 col-form-label">Kehadiran (hari)</label>
                            <div className="col-sm-7">
                                <input type="number" className="form-control" placeholder="jumlah hari hadir"
                                    required
                                    min={0}
                                    max={dataEditor?.duration}
                                    value={dataEditor?.day_present}
                                    onChange={(e) => handleChange('day_present', e.target.value)}
                                />
                                <div>
                                    <span className='d-block text-muted'>Persentase kehadiran = {(dataEditor?.day_present * 100 / dataEditor?.duration) || 0}%</span>
                                </div>
                            </div>
                        </div> */}
                        <div className='text-right'>
                            <button type='button' className='btn btn-sm btn-secondary mr-1'
                                onClick={closeModalEditor}
                            >Batal</button>
                            <button
                                disabled={data?.score == "competent" ? true : false}
                                type='submit' className='btn btn-sm btn-primary'>Simpan</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default ModalEditor;
