import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import usePeriksaJob from './usePeriksaJob';

function ModalDecline(props) {
    const [note, setNote] = useState("");
    const {
        prjAction: {
            closeModalDecline = () => null,
            confirmSubmit = () => null,
        },
        prjState: {
            modalDecline: {
                show = false,
                data: {
                    status = "",
                    job_assignment_id = ""
                }
            }
        } } = usePeriksaJob();

    useEffect(() => { setNote(""); }, [show])
    return (
        <Modal
            show={show}
            backdropClassName='bg-transparent'
            onHide={closeModalDecline}
        >
            <Modal.Header closeButton>
                <Modal.Title>Decline</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form
                    onSubmit={(e) => {
                        e.preventDefault(e);
                        closeModalDecline();
                        confirmSubmit(status, job_assignment_id, note);
                    }}
                >
                    <textarea
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        row={5}
                        required
                        className='form-control form-control-sm'
                    ></textarea>
                    <div className='text-right mt-3'>
                        <button
                            type='button'
                            onClick={closeModalDecline}
                            className='btn btn-sm btn-secondary mr-1'>Batal</button>
                        <button
                            type='submit'
                            className='btn btn-sm btn-danger'>Decline</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default ModalDecline;