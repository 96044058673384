import React, { createContext, useContext, useEffect, useState } from "react";
import { useModalAlert } from "../../../../components/modalAlert";
import useFetch from "../../../../hooks/useFetch";

const jbAsAdminContext = createContext({
    jobAsAdState: {},
    jobAsAdAction: {}
});

export function JbAsAdminProvider({ children }) {
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const { doGet, doPost } = useFetch();
    const [loadingGet, setLoadingGet] = useState(false);
    const [data, setData] = useState({});

    const [pagination, setPagination] = useState({
        page: 1, show: 10, order_by: "trainee_name", order_type: "asc"
    });

    const [filter, setFilter] = useState({
        search: "",
        batch: ""
    });

    async function getList() {
        try {
            setLoadingGet(true);
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/job-assignment/list-trainee',
                    param: {
                        ...filter,
                        "page": pagination.page,
                        "row_in_page": pagination.show,
                        "order_by": pagination.order_by,
                        "order_type": pagination.order_type,
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setData({ ...data });
                    setFilter(e => ({ ...e, batch: data?.batch }))
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Job Assignment",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Job Assignment",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Job Assignment",
                callBack: () => null
            })
        }
        setLoadingGet(false)
    }
    function nextPage({ page, limit }) {
        setPagination(prevState => ({
            ...prevState,
            page, show: limit,
        }))
    }
    function prevPage({ page, limit }) {
        setPagination(prevState => ({
            ...prevState,
            page, show: limit,
        }))
    }
    function changeShow({ page, limit }) {
        setPagination(prevState => ({
            ...prevState,
            page, show: limit,
        }))
    }
    function onFilter() {
        getList();
    }
    function onResetFilter() {

    }

    useEffect(() => {
        getList();
    }, [pagination])
    return <jbAsAdminContext.Provider
        value={{
            jobAsAdState: {
                data,
                loadingGet,
                pagination,
                filter
            },
            jobAsAdAction: {
                nextPage,
                prevPage,
                changeShow,
                setFilter,
                onFilter
            }
        }}
    >{children}</jbAsAdminContext.Provider>
};

export default function useJobAssigmentAdmin() {
    return useContext(jbAsAdminContext);
}