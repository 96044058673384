import React, { useEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

export default function () {


    useEffect(function () {
        let chart = am4core.create("chartdiv", am4charts.RadarChart);

        /* Add data */
        chart.data = [{
            "country": "Lithuania",
            "litres": 501
        }, {
            "country": "Czech Republic",
            "litres": 301
        }, {
            "country": "Ireland",
            "litres": 266
        }, {
            "country": "Germany",
            "litres": 165
        }, {
            "country": "Australia",
            "litres": 139
        }, {
            "country": "Austria",
            "litres": 336
        }, {
            "country": "UK",
            "litres": 290
        }, {
            "country": "Belgium",
            "litres": 325
        }, {
            "country": "The Netherlands",
            "litres": 40
        }];

        /* Create axes */
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "country";

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

        /* Create and configure series */
        let series = chart.series.push(new am4charts.RadarSeries());
        series.dataFields.valueY = "litres";
        series.dataFields.categoryX = "country";
        series.name = "Sales";
        series.strokeWidth = 3;
    }, [])

    return (
        <div>
            <section className="content-header">
                <div className="container-fluid">
                    <h4>Chart</h4>
                    <h6>Dummy</h6>
                </div>
            </section>

            <div className='row'>
                <div className='col-md-6'>
                    <div className='card'>
                        <div className='card-header'>
                            <div className='card-title'>
                                Spider Chart
                            </div>
                        </div>
                        <div className='card-body'>
                            <div style={{ height:"350px" }} id="chartdiv" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}