import React from "react";
import useIdpDwnld, { IdpDwnldProvider } from "./useIdpDownload";
import "./index.css";
import { Badge, Modal } from "react-bootstrap";
import IdpDwnldDetail from "./component";
import { htmlToExcel } from "../../../modules";
import moment from "moment";
import { BiFile } from "react-icons/bi";
import TableToHTML from "./exports";
import { useAuth } from "../../../context";
import { SelectSearch, StatusIcon, TableList } from "../../../components";

function Index(props) {
    const { userInfo } = useAuth();
    const role_id = userInfo?.role_id;
    const { idpDwnldState, idpDwnldAction } = useIdpDwnld();
    return (
        <div>
            <div className="row">
                <div className="col-6">
                    <section className="content-header">
                        <div className="container-fluid">
                            <h4>IDP Online</h4>
                            <h6>Download IDP Status</h6>
                        </div>
                    </section>
                </div>
                <div className="col-6">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end">
                            <li
                                className="breadcrumb-item active"
                                aria-current="page"
                            >
                                Download IDP Status
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="row mb-5">
                <div className="col-md col-lg col-sm-12">
                    <div className="tw-rounded tw-border tw-bg-white tw-p-5">
                        <div className="form-group row mb-1">
                            <label
                                htmlFor="inputPassword3"
                                className="col-sm-3 font-weight-normal"
                            >
                                Batch
                            </label>
                            <div className="col-sm-9">
                                <input
                                    type="number"
                                    className="form-control form-control-sm"
                                    placeholder="Batch"
                                    value={idpDwnldState?.batch}
                                    onChange={(val) =>
                                        idpDwnldAction.setBatch(
                                            val.target.value
                                        )
                                    }
                                ></input>
                            </div>
                        </div>
                        <div className="form-group row mb-1">
                            <label
                                htmlFor="inputEmail3"
                                className="col-sm-3 font-weight-normal"
                            >
                                Jobsite
                            </label>
                            <div className="col-sm-9">
                                <select
                                    className="form-select"
                                    value={idpDwnldState?.jobsite_code}
                                    onChange={(val) =>
                                        idpDwnldAction.setJobsite_code(
                                            val.target.value
                                        )
                                    }
                                >
                                    {role_id !== 5 ? (
                                        <option value={""}>
                                            - All -
                                        </option>
                                    ) : null}

                                    {[
                                        ...(idpDwnldState.dataJobsite ||
                                            []),
                                    ].map((d, i) => (
                                        <option
                                            value={d?.jobsite_code}
                                            key={i}
                                        >
                                            {d?.jobsite_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="form-group row mb-1">
                            <label
                                htmlFor="inputEmail3"
                                className="col-sm-3 font-weight-normal"
                            >
                                Type
                            </label>
                            <div className="col-sm-9">
                                <select
                                    className="form-select"
                                    value={idpDwnldState?.type}
                                    onChange={(val) =>
                                        idpDwnldAction.setType(
                                            val.target.value
                                        )
                                    }
                                >

                                    {[
                                        ...(idpDwnldState.listType ||
                                            []),
                                    ].map((d, i) => (
                                        <option value={d} key={i}>
                                            {d}
                                        </option>
                                    ))}
                                </select>

                            </div>
                        </div>
                        <div className="form-group row mb-1">
                            <div className="col-sm-3"></div>
                            <div className="col-sm-9">
                                <button
                                    className="btn btn-sm btn-success mt-auto"
                                    style={{ height: "fit-content" }}
                                    onClick={() =>
                                        idpDwnldAction.onFilter()
                                        // htmlToExcel(
                                        //     "table-to-xls",
                                        //     `${idpDwnldState?.batch
                                        //     }_IDP Status_${moment(Date.now()).format(
                                        //         "YYYY-MM-DD HH_mm_SS"
                                        //     )}`,
                                        //     "statement"
                                        // )
                                    }
                                >
                                   
                                    <BiFile style={{ fonstSize: "12pt" }} />  {idpDwnldState?.loadingGet ? "Mohon Menunggu.." : "Expot Excel"}
                                </button>
                                {/* <button
                                    className="btn btn-sm btn-primary"
                                    onClick={() =>
                                        idpDwnldAction.onFilter()
                                    }
                                >
                                    <i className="fa fa-search"></i>{" "}
                                    Search
                                </button> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md col-lg col-sm-12">

                </div>
                {/* <div className="col-md col-lg col-sm-12">
                    <div className="d-flex justify-content-end h-100 mb-3">
                        <button
                            className="btn btn-sm btn-success mt-auto"
                            style={{ height: "fit-content" }}
                            onClick={() =>
                                htmlToExcel(
                                    "table-to-xls",
                                    `${idpDwnldState?.batch
                                    }_IDP Status_${moment(Date.now()).format(
                                        "YYYY-MM-DD HH_mm_SS"
                                    )}`,
                                    "statement"
                                )
                            }
                        >                            
                            <BiFile style={{ fonstSize: "12pt" }} /> Export
                            Excel
                            <Badge className="ml-4" bg="primary">{[...(idpDwnldState.dataList || [])].length}</Badge>
                        </button>
                    </div>
                </div> */}
            </div>
            {/* <TableList
                withButtonSubmit={false}
                filter={true}
                formsFilter={[
                    {
                        type: "custom",
                        placeholder: "Nama",
                        component: (
                            <>
                                <div className="form-group row mb-1">
                                    <label
                                        htmlFor="inputPassword3"
                                        className="col-sm-3 font-weight-normal"
                                    >
                                        Batch
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            type="number"
                                            className="form-control form-control-sm"
                                            placeholder="Batch"
                                            value={idpDwnldState?.batch}
                                            onChange={(val) =>
                                                idpDwnldAction.setBatch(
                                                    val.target.value
                                                )
                                            }
                                        ></input>
                                    </div>
                                </div>
                                <div className="form-group row mb-1">
                                    <label
                                        htmlFor="inputEmail3"
                                        className="col-sm-3 font-weight-normal"
                                    >
                                        Jobsite
                                    </label>
                                    <div className="col-sm-9">
                                        <select
                                            className="form-select"
                                            value={idpDwnldState?.jobsite_code}
                                            onChange={(val) =>
                                                idpDwnldAction.setJobsite_code(
                                                    val.target.value
                                                )
                                            }
                                        >
                                            {role_id !== 5 ? (
                                                <option value={""}>
                                                    - All -
                                                </option>
                                            ) : null}

                                            {[
                                                ...(idpDwnldState.dataJobsite ||
                                                    []),
                                            ].map((d, i) => (
                                                <option
                                                    value={d?.jobsite_code}
                                                    key={i}
                                                >
                                                    {d?.jobsite_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group row mb-1">
                                    <label
                                        htmlFor="inputEmail3"
                                        className="col-sm-3 font-weight-normal"
                                    >
                                        Type
                                    </label>
                                    <div className="col-sm-9">
                                        <select
                                            className="form-select"
                                            value={idpDwnldState?.type}
                                            onChange={(val) =>
                                                idpDwnldAction.setType(
                                                    val.target.value
                                                )
                                            }
                                        >                                            

                                            {[
                                                ...(idpDwnldState.listType ||
                                                    []),
                                            ].map((d, i) => (
                                                <option value={d} key={i}>
                                                    {d}
                                                </option>
                                            ))}
                                        </select>
                                        
                                    </div>
                                </div>
                                <div className="form-group row mb-1">
                                    <div className="col-sm-3"></div>
                                    <div className="col-sm-9">
                                        <button
                                            className="btn btn-sm btn-primary"
                                            onClick={() =>
                                                idpDwnldAction.onFilter()
                                            }
                                        >
                                            <i className="fa fa-search"></i>{" "}
                                            Search
                                        </button>
                                    </div>
                                </div>
                            </>
                        ),
                    },
                ]}
                customToolbar={
                    <div className="d-flex justify-content-end h-100 mb-3">
                        <button
                            className="btn btn-sm btn-success mt-auto"
                            style={{ height: "fit-content" }}
                            onClick={() =>
                                htmlToExcel(
                                    "table-to-xls",
                                    `${
                                        idpDwnldState?.batch
                                    }_IDP Status_${moment(Date.now()).format(
                                        "YYYY-MM-DD HH_mm_SS"
                                    )}`,
                                    "statement"
                                )
                            }
                        >
                            <BiFile style={{ fonstSize: "12pt" }} /> Export
                            Excel
                        </button>
                    </div>
                }
                labelFilter=""
                submitButton={
                    <>
                        <i className="fa fa-search"></i> Search
                    </>
                }
                resetFilter={false}
                onFilter={() => idpDwnldAction.onFilter()}
                isLoading={idpDwnldState.loadingGet}
                curPage={1}
                minHeight="600px"
                maxHeight="600px"
                navtable={"TABLE 1"}
                pagination={false}
                withActions={true}
                actionType={1}
                actionItems={[
                    {
                        name: "Lihat detail",
                        onClick: function (item) {
                            idpDwnldAction.setModalDetail({
                                show: true,
                                data: { ...item },
                            });
                        },
                    },
                ]}
                columns={[
                    {
                        name: "#",
                        field: "#",
                        maxWidth: "50px",
                    },
                    {
                        name: "Request by NIK",
                        field: "request_by_nik",
                    },
                    {
                        name: "Request by",
                        field: "request_by",
                    },
                    {
                        name: "Grade",
                        field: "grade",
                    },
                    {
                        name: "Cluster",
                        field: "cluster",
                    },
                    {
                        name: "Group Competency",
                        field: "competency_group",
                    },
                    {
                        name: "Competency Name",
                        field: "competency_name",
                    },
                    {
                        name: "Point",
                        field: "point",
                    },
                    {
                        name: "Course Name",
                        field: "course_name",
                    },
                    {
                        name: "Jobsite Name",
                        field: "jobsite_name",
                    },
                    {
                        name: "Catatan",
                        field: "note",
                    },
                    {
                        name: "Status",
                        field: "status",
                        onRender: ({ status }) => {
                            return <StatusIcon status={status} />; //<span className={                            
                        },
                    },
                ]}
                data={idpDwnldState.dataList || []}
            /> */}
            <Modal
                dialogClassName="cs-modal-status"
                show={idpDwnldState.modalDetail?.show}
                onHide={() =>
                    idpDwnldAction.setModalDetail({
                        show: false,
                        data: {},
                    })
                }
            >
                <Modal.Header closeButton>
                    <Modal.Title>IDP Status Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <IdpDwnldDetail data={idpDwnldState.modalDetail?.data} />
                </Modal.Body>
            </Modal>
            <TableToHTML />
        </div>
    );
}

export default (props) => (
    <IdpDwnldProvider>
        <Index {...props} />
    </IdpDwnldProvider>
);
