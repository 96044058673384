import React, { createContext, useContext, useState } from "react";
import { useModalAlert } from "../../../../components/modalAlert";
import useFetch from "../../../../hooks/useFetch";

const atsDetailContext = createContext({
    atsDetailState: {},
    atsDetailAction: {}
});
export function AtsDetailProvider({ children }) {
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const { doGet } = useFetch();
    const [dataDetail, setDataDetail] = useState({});
    const [loadingGet, setLoadingGet] = useState(false)
    async function getDetail(id = '', type_page  = '') {
        setLoadingGet(true);
        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/ats',
                    param: {
                        type_page: type_page,
                        ats_id: id
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDataDetail({...data})
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Detail ATS Status",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Detail ATS Status",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Detail ATS Status",
                callBack: () => null
            })
        }
        setLoadingGet(false);
    }
    return <atsDetailContext.Provider
        value={{
            atsDetailState: {
                dataDetail,
                loadingGet
            },
            atsDetailAction: {
                getDetail,
                setDataDetail
            }
        }}
    >{children}</atsDetailContext.Provider>
}

export default function useAtsDetail() {
    return useContext(atsDetailContext);
}