import React from 'react';
import useDwnldDataCompReport from './useDwnldDataCompReport';

const TableToHTML = () => {
    const { dwnldDtCompReportState } = useDwnldDataCompReport();

    return (
        <table className="w-100 f-10" id="table-to-xls" style={{ display: "none" }}>
            <thead>
                <tr>
                    <th>#</th>
                    <th>NIK</th>
                    <th>Nama</th>
                    <th>Jobsite</th>
                    <th>Training ID</th>
                    <th>Competency Name</th>
                    <th>Course Name</th>
                    <th>User Grade</th>
                    <th>Competency Grade</th>
                    <th>Level</th>
                    <th>Post Test Score</th>
                    <th>Attended Ratio</th>
                    <th>Total JA</th>
                    <th>Total TIR</th>
                    <th>Assessment Status</th>
                </tr>
            </thead>
            <tbody>
                {
                    (dwnldDtCompReportState.dataList || []).map((d, i) => (
                        <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{d?.nik}</td>
                            <td>{d?.name}</td>
                            <td>{d?.pers_area_name}</td>
                            <td>{d?.training_id}</td>
                            <td>{d?.competency_name}</td>
                            <td>{d?.course_name}</td>
                            <td>{d?.grade}</td>
                            <td>{d?.comp_grade}</td>
                            <td>{d?.level}</td>
                            <td>{d?.post_test_score}</td>
                            <td>{d?.attended_ratio}</td>
                            <td>{d?.total_ja}</td>
                            <td>{d?.total_approved_tir}</td>
                            <td>{d?.assessment_status}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    );
}

export default TableToHTML;
