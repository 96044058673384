import React, { createContext, useContext, useEffect, useState } from "react";
import { useModalAlert } from "../../../components/modalAlert";
import useFetch from "../../../hooks/useFetch";

const dtsContext = createContext({
    dtsState:{},
    dtsAction:{}
});

export function DTSProvider({children}){
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const { doGet, doPost } = useFetch();
    const [dataList, setDataList] = useState([]);
    const [batch, setBatch] = useState('');
    const [loadingGet, setLoadingGet] = useState(false);
    const [modalDetail, setModalDetail] = useState({
        show: false,
        id: '',
        data: {}
    });

    async function getList() {
        try {
            setLoadingGet(true);
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/ats',
                    param: {
                        type_page: "training_schedule",
                        batch
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDataList(data?.exportData?.sort((a, b) => a.trainee_nik.localeCompare(b.trainee_nik)) || []);
                    setBatch(data?.batch || "")
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Detail ATS Status",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Detail ATS Status",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Detail ATS Status",
                callBack: () => null
            })
        }
        setLoadingGet(false)
    }

    function onFilter() {
        getList();
    }

    useEffect(() => {
        getList();
    },[])

    return <dtsContext.Provider
        value={{
            dtsState:{
                dataList,
                loadingGet,
                batch,
                modalDetail
            },
            dtsAction:{
                setModalDetail,
                setBatch,
                onFilter
            }
        }}
    >{children}</dtsContext.Provider>
}

export default function useDts(){
    return useContext(dtsContext);
}