import { Button } from "react-bootstrap";
import { TableList, UploadFile } from "../../../components";
import useMidp, { MidpProvider } from "./useMasterIdp";
import { useHistory } from "react-router";

function MidpPage() {
    const { midpAction, midpState } = useMidp();
    const history = useHistory();
    return (
        <>
            <div className="row">
                <div className="col-6">
                    <section className="content-header">
                        <div className="container-fluid">
                            <h4>Master Data</h4>
                            <h6>IDP Register</h6>
                        </div>
                    </section>
                </div>
                <div className="col-6">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end">
                            <li className="breadcrumb-item" aria-current="page">
                                Master Data
                            </li>
                            <li
                                className="breadcrumb-item active"
                                aria-current="page"
                            >
                                IDP Register
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="tw-flex tw-items-center">
                                <h6>Upload IDP Register</h6>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="tw-grid md:tw-grid-cols-3 tw-grid-cols-1 tw-w-full tw-items-center tw-gap-x-3 tw-gap-y-2">
                                <div>
                                    <label> Type </label>
                                    <select
                                        onChange={(e) => {
                                            midpAction.setForm?.((v) => ({
                                                ...v,
                                                type: e.target.value,
                                            }));
                                        }}
                                        value={midpState.form?.type}
                                        className="form-control "
                                    >
                                        <option value={"Mechanic"}>
                                            Mechanic
                                        </option>
                                        <option value={"Operator"}>
                                            Operator
                                        </option>
                                    </select>
                                </div>
                                <div>
                                    <label>File</label>
                                    <input
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/csv, text/csv, .csv, .xls, .xlsx"
                                        onChange={(e) => {
                                            midpAction.setForm?.((v) => ({
                                                ...v,
                                                file: e.target.files[0],
                                            }));
                                        }}
                                        type="file"
                                        className="form-control"
                                    />
                                </div>
                                <div>
                                    <label>&nbsp;</label>
                                    <button
                                        className="btn lg:btn-sm btn-primary md:tw-mt-8"
                                        onClick={() => {
                                            midpAction.doUpload?.();
                                        }}
                                    >
                                        Upload
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <TableList
                                data={midpState.dataList || []}
                                filter={true}
                                labelFilter=""
                                withCard={false}
                                formsFilter={[
                                    {
                                        type: "custom",
                                        component: (
                                            <div className="tw-w-full tw-gap-3  tw-grid tw-grid-cols-1 lg:tw-grid-cols-4">
                                                <div>
                                                    <input
                                                        type="number"
                                                        className="form-control form-control-sm"
                                                        placeholder="batch"
                                                        value={
                                                            midpState.filter?.batch
                                                        }
                                                        onChange={(e) => {
                                                            midpAction.setFilter(
                                                                (vs) => ({
                                                                    ...vs,
                                                                    batch: e
                                                                        .target
                                                                        .value,
                                                                })
                                                            );
                                                        }}
                                                    ></input>
                                                </div>
                                                <div>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        placeholder="nik"
                                                        value={
                                                            midpState.filter
                                                                ?.nik
                                                        }
                                                        onChange={(e) => {
                                                            midpAction.setFilter(
                                                                (vs) => ({
                                                                    ...vs,
                                                                    nik: e
                                                                        .target
                                                                        .value,
                                                                })
                                                            );
                                                        }}
                                                    ></input>
                                                </div>
                                                <div>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        placeholder="Search"
                                                        value={
                                                            midpState.filter
                                                                ?.search
                                                        }
                                                        onChange={(e) => {
                                                            midpAction.setFilter(
                                                                (vs) => ({
                                                                    ...vs,
                                                                    search: e
                                                                        .target
                                                                        .value,
                                                                })
                                                            );
                                                        }}
                                                    ></input>
                                                </div>
                                                <div>
                                                    <select
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        placeholder="type"
                                                        value={
                                                            midpState.filter
                                                                ?.type
                                                        }
                                                        onChange={(e) => {
                                                            midpAction.setFilter(
                                                                (vs) => ({
                                                                    ...vs,
                                                                    type: e
                                                                        .target
                                                                        .value,
                                                                })
                                                            );
                                                        }}
                                                    >
                                                        <option
                                                            value={""}
                                                            className="tw tw-text-gray-500"
                                                        >
                                                            {" "}
                                                            --- Select All Type ---
                                                        </option>
                                                        <option
                                                            value={"Mechanic"}
                                                        >
                                                            Mechanic
                                                        </option>
                                                        <option
                                                            value={"Operator"}
                                                        >
                                                            Operator
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        ),
                                    },
                                ]}
                                submitButton={
                                    <>
                                        <i className="fa fa-search"></i> Search
                                    </>
                                }
                                onFilter={() => {
                                    midpAction.doFilter();
                                }}
                                resetFilter={false}
                                isLoading={midpState.loadingList}
                                minHeight="300px"
                                maxHeight="500px"
                                navtable={"TABLE 1"}
                                onChangeShow={midpAction.changeShow}
                                onNextPage={midpAction.nextPage}
                                onPrevPage={midpAction.onPrevPage}
                                defaultShow={midpState.pagination?.show}
                                curPage={midpState.pagination?.page || 1}
                                maxPage={midpState.pagination?.totalPage || 1}
                                totalData={midpState.pagination?.totalData || 0}
                                withActions={true}
                                actionType={1}
                                actionItems={[
                                    {
                                        name: "Lihat detail",
                                        onClick: function (item) {
                                            history.push(
                                                `/master-data/idp-register/detil?id=${item.id}`
                                            );
                                        },
                                    },
                                    {
                                        name: "Ubah",
                                        onClick: function (item) {
                                            history.push(
                                                `/master-data/idp-register/ubah?id=${item.id}`
                                            );
                                        },
                                    },
                                    {
                                        name: "Hapus",
                                        onClick: function (item) {
                                            midpAction.doDelete(item.id);
                                        },
                                    },
                                ]}
                                columns={[
                                    {
                                        name: "#",
                                        field: "#",
                                    },
                                    {
                                        name: "NIK",
                                        field: "nik",
                                    },
                                    {
                                        name: "Jobsite Name",
                                        field: "jobsite_name",
                                    },
                                    {
                                        name: "Competency Name",
                                        field: "competency_name",
                                    },
                                    {
                                        name: "Course Name",
                                        field: "course_name",
                                    },
                                ]}
                            />
                        </div>
                        <div className="card-footer"></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default () => (
    <MidpProvider>
        <MidpPage />
    </MidpProvider>
);
