import React, { useEffect, useRef, useState } from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { useModalAlert } from '../../../../components/modalAlert';
import useFetch from '../../../../hooks/useFetch';

function Index(props) {
    const refInputFile = useRef();
    const history = useHistory();
    const { state = {} } = useLocation();
    const { modalAction } = useModalAlert();
    const { doPost, doGet } = useFetch()
    const [file, setFile] = useState();
    const [role_id, setRole_id] = useState("3");
    const {
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        openLoading
    } = modalAction;

    const doUpload = function () {
        if (!file) {
            return setDataAlert({
                show: true,
                body: "File belum dipilih",
                header: "Info",
                callBack: () => null
            })
        }
        let body = "Pastikan form terisi dengan benar. Lanjutkan?"
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                {
                    name: "Yes", onClick: async () => {
                        closeConfirm();
                        openLoading("Uploading ...")
                        try {
                            const { status, payload, statusText } = await doPost({
                                url: "/api/data-master/add-users-bulk",
                                data: {
                                    file,
                                    role_id
                                },

                                type: 1,
                                paramImage: 'file',
                            });

                            if (status !== 401) {
                                if (status === 200 || status === 201) {
                                    const { code, msg } = payload;
                                    if (code === "00") {
                                        setDataAlert({
                                            body: msg,
                                            show: true,
                                            header: "Success",
                                            callBack: () => {
                                                if (refInputFile.current) {
                                                    refInputFile.current.value = null;
                                                }
                                            }
                                        })
                                    } else setDataAlert({
                                        show: true,
                                        body: msg,
                                        header: "Info",
                                        callBack: () => null
                                    })
                                } else setDataAlert({
                                    show: true,
                                    body: statusText,
                                    header: "Info",
                                    callBack: () => null
                                })
                            }
                        } catch (error) {
                            setDataAlert({
                                show: true,
                                body: error.toString(),
                                header: "Info",
                                callBack: () => null
                            })
                        }
                        closeLoading()


                    }, className: "btn btn-sm btn-success"
                },
                { name: "No", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }
    const [loading, setLoading] = useState(false);
    const [dataRoles, setDataRoles] = useState([]);
    const getRoles = async function () {
        setLoading(true);
        try {
            const { status, statusText, payload } = await doGet({
                url: "/api/data-master/roles"
            });

            if (status === 401) {
                closeLoading();
            } else if (status === 200) {
                const { data = [], code, msg } = payload;
                if (code === "00") {
                    setDataRoles(data);
                } else
                    setDataAlert({
                        show: true,
                        body: msg,
                        header: "Get List Roles",
                        callBack: () => null,
                    });
            } else
                setDataAlert({
                    show: true,
                    body: statusText,
                    header: "Get List Roles",
                    callBack: () => null,
                });
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Roles",
                callBack: () => null,
            });
        }
        setLoading(false);
    }

    useEffect(() => {
        getRoles();
    }, []);

    return (
        <div>
            <div className="row">
                <div className="col-6">
                    <section className="content-header">
                        <div className="container-fluid">
                            <h4>Master Data</h4>
                            <h6>Bulk Upload Demografi Employee</h6>
                        </div>
                    </section>
                </div>
                <div className="col-6">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end">
                            <li className="breadcrumb-item" aria-current="page">
                                Master Data
                            </li>
                            <li className="breadcrumb-item" aria-current="page">
                                <Link to={{ 
                                    pathname: "/master-data/demografi-employee",
                                    state: state
                                 }} >
                                    Demografi Employee
                                </Link>
                            </li>
                            <li
                                className="breadcrumb-item active"
                                aria-current="page"
                            >
                                Bulk Upload
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <h5>Bulk Upload</h5>
                    <hr />
                    <div className='row justify-content-center'>
                        <div className='col-md-6'>
                            <div className="row mb-3">
                                <label htmlFor="inputEmail3" className="col-sm-3 col-form-label col-form-label-sm">Role</label>
                                <div className="col-sm-9">
                                    <select value={role_id} className='form-select form-select-sm'
                                        onChange={(e) => setRole_id(e.target.value)}
                                    >
                                        {
                                            [...(dataRoles || [])].map((d, i) => (
                                                <option key={i} value={d.id}>{d.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className='tw-flex tw-flex-col tw-justify-center tw-mt-[20px] tw-border-dashed tw-border-1 tw-border-slate-500 tw-rounded-lg tw-min-h-[200px] tw-p-[20px]'>
                                <div className="input-group mb-3">
                                    <input
                                        ref={refInputFile}
                                        onChange={(e) => setFile(e.target.files[0])}
                                        accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                        type="file"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='tw-flex tw-full tw-justify-between'>
                                <button className='btn btn-sm btn-secondary'
                                    onClick={() => history.goBack()}
                                >Kembali</button>
                                <button className='btn btn-sm btn-success' onClick={doUpload}>Simpan</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;