import React, { createContext, useContext, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useModalAlert } from "../../../../components/modalAlert";
import useFetch from "../../../../hooks/useFetch";

const resTeContext = createContext({
    resTeState: {},
    resTeAction: {}
});


export function ResultTraineeProvider({ children }) {
    const [showEditor, setShowEditor] = useState({
        show: false,
        data: {}
    });
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const { doGet, doPost } = useFetch();
    const [loadingGet, setLoadingGet] = useState(false);
    const [dataDetail, setDataDetail] = useState({});
    const { competency_id = '', course_id = '', batch = '' } = useParams();
    const { state } = useLocation();
    const { header } = state;

    const iniStateEditor = {
        "ats_id": '',
        "start_date": "",
        "end_date": "",
        "pre_test_score": '',
        "post_test_score": '',
        "day_present": 0,
        "batch": batch,
        "file": ""
    }

    const [dataEditor, setDataEditor] = useState(iniStateEditor);

    async function getDetail() {
        try {
            setLoadingGet(true);
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/ats/trainees-per-ats',
                    param: {
                        "competency_id": competency_id,
                        "course_id": course_id,
                        "batch": batch 
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDataDetail(data || []);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Detail Training",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Detail Training",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Detail Training",
                callBack: () => null
            })
        }
        setLoadingGet(false)
    }
    async function doSubmit() {
        closeConfirm()
        openLoading("Sending data, please wait...");
        try {
            const { status, payload, statusText } = await doPost({
                url: "/api/ats/store-score",
                data: {
                    ...dataEditor,
                    file: dataEditor?.file || ""
                },
                type: 1
            });

            if (status !== 401) {
                if (status === 200 || status === 201) {
                    const { code, msg } = payload;
                    if (code === "00") {
                        setShowEditor({ show: false, data: {} });
                        setDataAlert({
                            body: msg,
                            show: true,
                            header: "Success",
                            callBack: () => getDetail()
                        })

                    } else setDataAlert({
                        show: true,
                        body: msg,
                        header: "Info",
                        callBack: () => null
                    })
                } else setDataAlert({
                    show: true,
                    body: statusText,
                    header: "Info",
                    callBack: () => null
                })
            }
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Info",
                callBack: () => null
            })
        }
        closeLoading();
    }
    function handleChange(name = '', value = '') {
        setDataEditor({
            ...dataEditor,
            [name]: value,
        })
    }
    function confirmSubmit() {
        setDataConfirm({
            show: true,
            body: "Sebelum melanjutkan, cek kembali data yang diupdate. Lanjutkan ?",
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Yes", onClick: () => doSubmit(), className: "btn btn-sm btn-success" },
                { name: "No", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }
    useEffect(() => {
        if (competency_id === '' && course_id === '') {
            return setDataAlert({
                show: true,
                body: "Silakan buka halaman ini melalui 'Data Result Training'",
                header: "Tidak ada data terpilih",
                callBack: () => null
            })
        }
        getDetail();
    }, []);
    useEffect(() => {
        if (showEditor.show) {
            setDataEditor({ ...showEditor.data })
        } else {
            setDataEditor({ ...iniStateEditor })
        }
    }, [showEditor])
    return <resTeContext.Provider
        value={{
            resTeState: {
                showEditor,
                dataDetail,
                loadingGet,
                dataEditor,
                header
            },
            resTeAction: {
                setShowEditor,
                handleChange,
                confirmSubmit
            }
        }}
    >{children}</resTeContext.Provider>
};

export default function useResTrainee() {
    return useContext(resTeContext);
}