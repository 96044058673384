import React, { useMemo } from "react";
import useMatrixComp, { MatrixCompProvider } from "./useMatrixComp";
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Skeleton from "react-loading-skeleton";
import { DateInput } from "../../../components";
import { toString } from 'lodash';

import TabelCourse from "./tabel-course";

export function InputCustom({
    title = "",
    val = "",
    disabled = true,
    isLoading = true,
    type = "text",
    dataOptions = [],
    onChange = () => { },
    onRender = () => null,
    required = true,
    withTitle = true
}) {

    let newVal = useMemo(function () {
        let xval = onRender(val);
        if (!xval)
            xval = val;
        return xval;

    }, [val, onRender])


    function getInput() {
        if (type === "select") return (
            <select required={required} onChange={onChange} value={toString(newVal)} className="form-select" disabled={disabled}>
                {
                    dataOptions.map((v, i) => <option key={i} value={toString(v.key)}>{v.label}</option>)
                }
            </select>
        )
        else if (type === "date") return (
            <DateInput
                required={required}
                className="form-control"
                disabled={disabled}
                value={newVal}
                onChange={onChange}
            />
        )
        else if (type === "file") {
            return (
                <>
                    {
                        !disabled && (
                            <input
                                id="inputfile"
                                accept="image/png, image/jpg, image/jpeg, image/png, image/bmp, image/gif, image/webp, image/image"
                                onChange={onChange}
                                type="file"
                                className="form-control"
                            />
                        )
                    }
                </>
            )
        }
        else return (
            <input
                onChange={onChange}
                type={type}
                value={newVal}
                disabled={disabled}
                required={required}
                className="form-control disabled:tw-bg-white"
                placeholder={title}
            />
        )
    }
    return (
        <div className="tw-px-2">
            {
                withTitle && <label>{title}</label>
            }
            {isLoading ? <Skeleton height={30} width={"100%"} /> : getInput()}
        </div>
    );
}

function FormMatrixComp() {
    const {
        matrixCompState: {
            loadingList,
            tipe,
            dataType,
            formData,
            disabledForm
        },
        matrixCompAction: {
            handleChangeFom,
            doUbah = () => { },
            doCreate = () => { }
        },
    } = useMatrixComp();

    const history = useHistory();

    return (
        <>
            <div className="row">
                <div className="col-6">
                    <section className="content-header">
                        <div className="container-fluid">
                            <h4>Master Data</h4>
                            <h6>Matriks Kompetensi</h6>
                        </div>
                    </section>
                </div>
                <div className="col-6">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end">
                            <li className="breadcrumb-item" aria-current="page">
                                Master Data
                            </li>
                            <li className="breadcrumb-item" aria-current="page">
                                <Link to="/master-data/matrix-competency" >
                                    Matriks Kompetensi
                                </Link>
                            </li>
                            <li
                                className="breadcrumb-item active"
                                aria-current="page"
                            >
                                <span className="tw-capitalize">{tipe}</span>
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <form onSubmit={(e) => {
                        e.preventDefault(e);
                        if (tipe === "add") {
                            doCreate();
                        }
                        if (tipe === "ubah") {
                            doUbah();
                        }
                    }}>
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title tw-capitalize">
                                    {tipe}
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="tw-px-5 tw-py-3 tw-rounded-md tw-border tw-border-solid tw-border-success tw-mb-2">
                                    <div className="tw-mb-6 tw-mx-3 tw-mt-3 tw-border-solid tw-border-t-0 tw-border-x-0 tw-border-b-2 tw-border-b-success">
                                        <h5 className="text-success"> Detail Matrik Kompetensi</h5>
                                    </div>
                                    <div className="tw-grid tw-grid-cols-1 tw-gap-2 lg:tw-grid-cols-3">
                                        {[
                                            {
                                                title: "Type",
                                                field: "type",
                                                disabled: disabledForm,
                                                type: "select",
                                                dataOptions: [ { key: "", label: "- Pilih -" }, ...(dataType.map(d => ({ key: d, label: d}) )) ],
                                                onChange: (e) => handleChangeFom("type", e.target.value)
                                            },
                                            {
                                                title: "Cluster",
                                                field: "cluster",
                                                disabled: disabledForm,
                                                onChange: (e) => handleChangeFom("cluster", e.target.value)
                                            },
                                            {
                                                title: "Grade",
                                                field: "grade",
                                                disabled: disabledForm,
                                                onChange: (e) => handleChangeFom("grade", e.target.value)
                                            },
                                            {
                                                title: "Group",
                                                field: "group",
                                                disabled: disabledForm,
                                                onChange: (e) => handleChangeFom("group", e.target.value)
                                            },
                                            {
                                                title: "Name",
                                                field: "name",
                                                disabled: disabledForm,
                                                onChange: (e) => handleChangeFom("name", e.target.value)
                                            },
                                            {
                                                title: "Point",
                                                field: "point",
                                                disabled: disabledForm,
                                                type: "number",
                                                onChange: (e) => handleChangeFom("point", e.target.value)
                                            },
                                            {
                                                title: "Cluster Id",
                                                field: "cluster_id",
                                                disabled: disabledForm,
                                                type: "number",
                                                onChange: (e) => handleChangeFom("cluster_id", e.target.value)
                                            },
                                        ].map(({ onChange = () => { }, ...val }, x) => {
                                            return (
                                                <div key={x}>
                                                    <InputCustom
                                                        isLoading={loadingList}
                                                        title={val.title}
                                                        val={formData?.[val.field]}
                                                        disabled={val.disabled}
                                                        onChange={onChange}
                                                        type={val.type}
                                                        dataOptions={val.dataOptions}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>

                                    {tipe === "detail" && <TabelCourse />}
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="tw-flex tw-justify-between">
                                    <button
                                        type="button"
                                        onClick={() =>
                                            history.push(
                                                "/master-data/matrix-competency"
                                            )
                                        }
                                        className="btn btn-outline-secondary"
                                    >
                                        Back
                                    </button>
                                    {(tipe === "add" || tipe === "ubah") && (
                                        <button type="submit" className="btn btn-primary"
                                        >
                                            {tipe === "add" ? "Tambah" : "Ubah"}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default () => (
    <MatrixCompProvider>
        <FormMatrixComp />
    </MatrixCompProvider>
);