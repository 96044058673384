import React from 'react';
import ModalDecline from '../modal-decline/modalDecline';
import useTirApproval from '../useTirApproval';

function Index(props) {
    const { tirApvAction, tirApvState } = useTirApproval();
    return (
        <div>
            <ModalDecline />
            <form
                onSubmit={(e) => {
                    e.preventDefault(e);
                    tirApvAction.confirmAddUpdateForm(Object.keys((tirApvState.formData || {})).map((d) => {
                        const x = { ...tirApvState.formData[d] }
                        return {
                            answer_type: x?.answer_type,
                            form_id: x?.form_id,
                            value: x?.value
                        }
                    }), tirApvState.pageState, tirApvState.pageState?.dataPage?.modeEdit)
                }}
            >
                {
                    (tirApvState.pageState?.dataPage?.detail?.status === "declined") && (
                        <div className='card mb-3' style={{ backgroundColor: '#ffe2e5' }}>
                            <div className='card-body text-danger'>
                                <h5 className='text-center'>Declined</h5>
                                <h7>Note :</h7>
                                <span>{tirApvState.pageState?.dataPage?.detail?.note}</span>
                            </div>
                        </div>
                    )
                }
                <div className='card'>
                    <div className='card-body p-5'>
                        {
                            [...(tirApvState.listForm || [])].map(({ sub_title_en, sub_title_id, questions = [] }, i) => (
                                <div className='form row mb-5' key={i}>
                                    <div className='col-md-12'>
                                        <div className='text-center'>
                                            <span className='d-block text-center font-weight-bold mb-1'>{sub_title_en}</span>
                                            <span className='d-block font-weight-normal font-italic mb-5'>{sub_title_id}</span>
                                        </div>
                                        <div className='row'>
                                            {
                                                [...(questions || [])].map(({
                                                    question_parent,
                                                    question_parent_id,
                                                    type_list,
                                                    position,
                                                    sub_questions = [] }, i) => (
                                                    <div className={position === null ? `col-md-12` : `col-md-${position?.split('-')?.[1]}`} key={i}>
                                                        {
                                                            question_parent && <div className='d-inline-flex font-weight-bold border-bottom border-dark mb-2'>{question_parent}</div>
                                                        }
                                                        <div className={type_list === "horizontal" ? "row" : ""}>

                                                            {
                                                                [...(sub_questions || [])].map((d, j) => (
                                                                    <>
                                                                        {
                                                                            d?.answer_type === 'longtext' ? (
                                                                                <div className="form-group">
                                                                                    <span className='d-block font-weight-bold mb-2'>{d?.question_en} <span className='font-weight-normal font-italic'>/ {d?.question_id}</span></span>
                                                                                    {createForm(d)}
                                                                                </div>
                                                                            ) : d?.answer_type === 'checkbox' && type_list === 'horizontal' ? (
                                                                                <div className="col-md-3">
                                                                                    <div className={question_parent ? "form-check" : "form-check pl-0"}>
                                                                                        <div className='row'>
                                                                                            <div className='col col-sm-7'>
                                                                                                <label className="form-check-label float-left" htmlFor={d?.id}>
                                                                                                    <span className='d-block font-weight-bold'>{d?.question_en}</span>
                                                                                                    <small className='font-italic'>{d?.question_id}</small>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className='col-auto col-sm-5 text-center'>
                                                                                                {createForm(d)}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* <div className="form-group row mr-2" key={j}>
                                                                                        <div className='col-sm-auto col-form-label py-0'>
                                                                                            <span className='d-block font-weight-bold'>{d?.question_en}</span>
                                                                                            <small className='font-italic'>{d?.question_id}</small>
                                                                                        </div>
                                                                                        <div className="col-sm">
                                                                                            {createForm(d)}
                                                                                        </div>
                                                                                    </div> */}
                                                                                </div>
                                                                            ) : d?.answer_type === 'img' ? (
                                                                                <div className="form-group">
                                                                                    <span className='d-block font-weight-bold mb-2'>{d?.question_en} <span className='font-weight-normal font-italic'>/ {d?.question_id}</span></span>
                                                                                    {createForm(d)}
                                                                                </div>
                                                                            ) : (
                                                                                <>
                                                                                    {
                                                                                        (d?.answer_type === "checkbox" || d?.answer_type === "optional") && (
                                                                                            <div className={question_parent ? "form-check" : "form-check pl-0"}>
                                                                                                <div className='row'>
                                                                                                    <div className='col col-sm-7'>
                                                                                                        <label className="form-check-label float-left" htmlFor={d?.id}>
                                                                                                            <span className='d-block font-weight-bold'>{d?.question_en}</span>
                                                                                                            <small className='font-italic'>{d?.question_id}</small>
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='col-auto col-sm-5 text-center'>
                                                                                                        {createForm(d)}
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                    {
                                                                                        (d?.answer_type !== "checkbox" && d?.answer_type !== "optional") && (
                                                                                            <div className="form-group row" key={j}>
                                                                                                <div className='col-sm-7 col-form-label py-0'>
                                                                                                    <span className='d-block font-weight-bold'>{d?.question_en}</span>
                                                                                                    <small className='font-italic'>{d?.question_id}</small>
                                                                                                </div>
                                                                                                <div className="col-sm-5">
                                                                                                    {createForm(d)}
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                </>
                                                                                // <div className="form-group row" key={j}>
                                                                                //     <div className='col-sm-7 col-form-label py-0'>
                                                                                //         <span className='d-block font-weight-bold'>{d?.question_en}</span>
                                                                                //         <small className='font-italic'>{d?.question_id}</small>
                                                                                //     </div>
                                                                                //     <div className="col-sm-5">
                                                                                //         {createForm(d)}
                                                                                //     </div>
                                                                                // </div>
                                                                            )
                                                                        }
                                                                    </>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        <div className="form-row mb-3">
                            <div className='col'>
                                <button
                                    type='button'
                                    onClick={() => tirApvAction.toListPage(tirApvState.pageState?.dataPage?.detail?.trainee_nik)}
                                    className='btn btn-sm btn-secondary'>Back</button>
                            </div>
                            <div className='col text-right'>
                                <button
                                    type="button"
                                    className='btn btn-sm btn-primary mr-1'
                                    onClick={() => window.print()}
                                ><i className='fa fa-print mr-1'></i> Print</button>
                                {
                                    tirApvState.pageState?.dataPage?.detail?.status === "submitted" &&
                                    <>
                                        <button
                                            type='button'
                                            className='btn btn-sm btn-danger mr-1'
                                            // onClick={() => tirApvAction.confirmUpdateStatus({
                                            //     "tir_id": tirApvState.pageState?.dataPage?.detail?.tir_id,
                                            //     "status": 'declined'
                                            // }, tirApvState.pageState?.dataPage?.detail?.trainee_nik)}
                                            onClick={() => tirApvAction.openModalDecline({
                                                "tir_id": tirApvState.pageState?.dataPage?.detail?.tir_id,
                                                "status": 'declined',
                                                "trainee_nik": tirApvState.pageState?.dataPage?.detail?.trainee_nik
                                            })}
                                        >Decline</button>
                                        <button
                                            type='button'
                                            className='btn btn-sm btn-success mr-1'
                                            onClick={() => tirApvAction.confirmUpdateStatus({
                                                "tir_id": tirApvState.pageState?.dataPage?.detail?.tir_id,
                                                "status": 'approved'
                                            }, tirApvState.pageState?.dataPage?.detail?.trainee_nik)}
                                        >Approve</button>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
function createForm(form) {
    const { tirApvAction, tirApvState } = useTirApproval();
    if (form.answer_type === "string")
        return (
            <div className="form-group">
                <input
                    name={form?.id}
                    readOnly
                    disabled
                    className="form-control form-control-sm"
                    // placeholder={form.placeholder}
                    value={tirApvState.formData[form?.id]?.value}
                    onChange={(e) => tirApvAction.handleChange(form?.id, {
                        "form_id": form?.id,
                        "value": e.target.value,
                        "answer_type": form?.answer_type
                    })}
                />
            </div>
        )
    if (form.answer_type === "date")
        return (
            <div className="form-group">
                <input
                    name={form?.id}
                    className="form-control form-control-sm"
                    // placeholder={form.placeholder}
                    readOnly
                    disabled
                    value={tirApvState.formData[form?.id]?.value}
                    onChange={(e) => tirApvAction.handleChange(form?.id, {
                        "form_id": form?.id,
                        "value": e.target.value,
                        "answer_type": form?.answer_type
                    })}
                />
            </div>
        )
    if (form.answer_type === "longtext")
        return (
            <div className="form-group">
                <textarea
                    name={form?.id}
                    className="form-control form-control-sm"
                    rows={4}
                    readOnly
                    disabled
                    value={tirApvState.formData[form?.id]?.value}
                    onChange={(e) => tirApvAction.handleChange(form?.id, {
                        "form_id": form?.id,
                        "value": e.target.value,
                        "answer_type": form?.answer_type
                    })}
                />
            </div>
        )
    if (form.answer_type === "optional")
        return (
            <div>
                <div className="form-check form-check-inline">
                    <label className="form-check-label mr-1" >Y</label>
                    <input className="form-check-input" type="radio" name={form?.id}
                        value={'Y'}
                        readOnly
                        disabled
                        checked={tirApvState.formData[form?.id]?.value === 'Y'}
                        onChange={(e) => tirApvAction.handleChange(form?.id, {
                            "form_id": form?.id,
                            "value": e.target.value,
                            "answer_type": form?.answer_type
                        })}
                    />
                </div>
                <div className="form-check form-check-inline">
                    <label className="form-check-label mr-1" >N</label>
                    <input className="form-check-input" type="radio" name={form?.id}
                        value={'N'}
                        readOnly
                        disabled
                        checked={tirApvState.formData[form?.id]?.value === 'N'}
                        onChange={(e) => tirApvAction.handleChange(form?.id, {
                            "form_id": form?.id,
                            "value": e.target.value,
                            "answer_type": form?.answer_type
                        })}
                    />
                </div>
            </div>
        )
    if (form.answer_type === "checkbox")
        return (
            <input
                type="checkbox"
                name={form?.id}
                readOnly
                disabled
                className="form-control-file py-5"
                checked={tirApvState.formData[form?.id]?.value}
                onChange={(e) => tirApvAction.handleChange(form?.id, {
                    "form_id": form?.id,
                    "value": e.target.checked,
                    "answer_type": form?.answer_type
                })}
            />
        )
    if (form.answer_type === "img")
        return (
            <div>
                <img className='mb-2' width={240} height={240} src={tirApvState.formData[form?.id]?.path}></img>
                {/* <input
                    style={{ width: '320px' }}
                    required={form?.is_required ? (tirApvState.formData[form?.id]?.path ? false : true) : false}
                    onChange={(e) => tirApvAction.doUploadImage(e.target.files[0], form?.id, form?.answer_type, tirApvState.formData[form?.id]?.answer_id || 'XX')}
                    accept="image/png, image/jpeg"
                    className='form-control' type='file' /> */}
            </div>
        )
}

export default Index;