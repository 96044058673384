import React from 'react';
import { TableList } from '../../../../components';
import usePencapaianHm from '../usePencapaianHm';

function Index(props) {
    const {phmState, phmAction} = usePencapaianHm();
    return (
        <div>
            <div className='card'>
                <div className='card-header'>
                    <div className='card-title'>
                        <h5> List Training</h5>
                    </div>
                </div>
                <div className='card-body'>
                    <div className='row mt-2'>
                        <div className='col-md-12'>
                            <TableList
                                isLoading={phmState.loadingTraining}
                                minHeight='max-content'
                                withCard={false}
                                withActions={false}
                                filter={false}
                                data={phmState.listTraining}
                                pagination={false}
                                columns={[
                                    {
                                        name: "Competency Name",
                                        field: "competency_name"
                                    },
                                    {
                                        name: "Course Name",
                                        field: "course_name"
                                    },
                                    {
                                        name: "Total Hm",
                                        field: "total_hm"
                                        
                                    }                                    
                                ]}
                            />
                        </div>
                    </div>
                </div>
                <div className='card-footer'>
                    <button className='btn btn-sm btn-secondary'
                        onClick={() => phmAction.toListTraineePage()}
                    >Back</button>
                </div>
            </div>
        </div>
    );
}

export default Index;