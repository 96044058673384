import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useModalAlert } from "../../../../components/modalAlert";
import useFetch from "../../../../hooks/useFetch";

const lsIdpContext = createContext({
    lsIdpState: {},
    lsIdpAction: {}
});

export function LsIdpProvider({ children }) {
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const { doGet, doPost } = useFetch();
    const history = useHistory();
    const [dataState, setdataState] = useState(history.location.state);
    const [dataGet, setDataGet] = useState({});
    const [loadingGet, setLoadingGet] = useState(false);
    const [batch, setBatch] = useState(dataState?.batch);

    const [course_name, setCourse_name] = useState("");
    
    const [pagination, setPagination] = useState({
        page: 1, row_in_page: 10
    });

    async function getList() {
        try {
            setLoadingGet(true);
            const { status, statusText, payload } = await doPost(
                {
                    url: '/api/ats/ats-all',
                    data: {
                        jobsite_code: dataState?.jobsite_code,
                        course_name,
                        batch,
                        ...pagination
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDataGet({ ...data });
                    setBatch(data?.batch)
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get List IDP",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List IDP",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Jobsite",
                callBack: () => null
            })
        }
        setLoadingGet(false)
    }
    function ConfirmDelete(ats_id = '',) {
        setDataConfirm({
            show: true,
            body: `Anda yakin akan menghapus data ini secara permanent?`,
            witTextArea: false,
            header: "Hapus Data",
            title: "",
            closeButton: false,
            footerButton: [
                {
                    name: "Yes", onClick: async () => {
                        closeConfirm()
                        openLoading("Deletting data ...")
                        try {
                            const { status, payload, statusText } = await doPost({
                                url: "/api/ats/delete-ats",
                                method: "delete",
                                data: {
                                    ats_id
                                },
                            });

                            if (status !== 401) {
                                if (status === 200 || status === 201) {
                                    const { code, msg } = payload;
                                    if (code === "00") {
                                        setDataAlert({
                                            body: msg,
                                            show: true,
                                            header: "Success",
                                            callBack: () => {
                                                getList();                                               
                                            }
                                        })
                                    } else setDataAlert({
                                        show: true,
                                        body: msg,
                                        header: "Delete ATS",
                                        callBack: () => null
                                    })
                                } else setDataAlert({
                                    show: true,
                                    body: statusText,
                                    header: "Delete ATS",
                                    callBack: () => null
                                })
                            }
                        } catch (error) {
                            setDataAlert({
                                show: true,
                                body: error.toString(),
                                header: "Info",
                                callBack: () => null
                            })
                        }
                        closeLoading()
                    }, className: "btn btn-sm btn-danger"
                },
                { name: "No", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    function nextPage({ page, limit }) {
        setPagination((prevState) => ({
            ...prevState,
            page,
            row_in_page: limit,
        }));
    }
    function prevPage({ page, limit }) {
        setPagination((prevState) => ({
            ...prevState,
            page,
            row_in_page: limit,
        }));
    }
    function changeShow({ page, limit }) {
        setPagination((prevState) => ({
            ...prevState,
            page,
            row_in_page: limit,
        }));
    }

    function onFilter() {
        getList();
    }
    
    useEffect(() => {
        if (!dataState?.jobsite_code) {
            return setDataAlert({
                show: true,
                body: "Buka halaman ini melalui menu ATS Create",
                header: "Jobsite tidak di pilih",
                callBack: () => null
            })
        }
        getList();
    }, [pagination])
    return <lsIdpContext.Provider
        value={{
            lsIdpState: {
                dataGet,
                loadingGet,
                dataState,
                batch,
                course_name,
                pagination
            },
            lsIdpAction: {
                setBatch,
                onFilter,
                ConfirmDelete,
                setCourse_name,
                nextPage,
                prevPage,
                changeShow
            }
        }}
    >{children}</lsIdpContext.Provider>
}
export default function useLsIdp() {
    return useContext(lsIdpContext);
}