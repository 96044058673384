import React, { createContext, useContext, useEffect, useState } from "react";
import 'anychart';

import useFetch from "../../../hooks/useFetch";
import { useModalAlert } from "../../../components/modalAlert";

const context = createContext({
    reportDtCompPassedAction: {},
    reportDtCompPassedState: {}
});

export function ReportDataCompPassedProvider({ children }) {
    const { modalAction } = useModalAlert();
    const {
        closeLoading,
        setDataAlert,
    } = modalAction;    

    const { doGet, doPost } = useFetch();
    const [dataList, setDataList] = useState([]);
    const [loadingGet, setLoadingGet] = useState(false);
    
    const [dataJobsite, setDataJobsite] = useState([]);
    const [jobsite_code, setJobsite_code] = useState([]);
    const [stream, setStream] = useState("Operator");
    const [clusters, setClusters] = useState([]);
    const [batches, setBatches] = useState([]);
    const [filters, setFilter] = useState({});

    async function getDataList(njobsite, nbatch, ncluster) {
        setLoadingGet(true)
        try {
            const { status, payload, statusText } = await doPost({
                url: '/api/reporting/report-passed-competency',
                data: { 
                    jobsite_codes: (njobsite ?? jobsite_code).map(d => d.jobsite_code),
                    batches: (nbatch ?? batches).map(d => d.batch),
                    clusters: (ncluster ?? clusters).map(d => d.value),
                    stream 
                }
            });
            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = [], code, msg } = payload;
                if (code === "00") {
                    setDataList(data?.map(d => ({ x: d.grade, value: d.competent_percentage })) || []);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Report CompPassed",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Report CompPassed",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Report CompPassed",
                callBack: () => null
            })
        }
        setLoadingGet(false)
    }

    async function getAllJobsite() {
        try {
            const { status, statusText, payload } = await doGet({
                url: '/api/list-all-jobsite',
            });
            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    let jobsiteList = await (data.jobsites ?? []).map(d => ({ ...d, value: d.jobsite_code, label: d.jobsite_name }) );
                    await setDataJobsite(jobsiteList);
                    await setJobsite_code([jobsiteList[0]]);
                    getListFilter([jobsiteList[0]]);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get All Jobsite",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get All Jobsite",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get All Jobsite",
                callBack: () => null
            })
        }
    }

    async function getListFilter(njobsite) {
        try {
            const { status, statusText, payload } = await doGet({
                url: '/api/reporting/filters-passed-competency',
            });
            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    let { filterBatches = [], filterClusters = []} = await data ?? {};
                    let nFilterBatches = await filterBatches.map(d => ({ ...d, value: d.batch, label: d.batch }) );
                    let nFilterClusters = await filterClusters.map(d => ({ ...d, value: d.cluster, label: d.cluster }) );
                    setFilter({ filterBatches: nFilterBatches, filterClusters: nFilterClusters });
                    await setBatches([nFilterBatches[0]]);
                    await setClusters([nFilterClusters[0]]);
                    getDataList(njobsite, [nFilterBatches[0]], [nFilterClusters[0]])
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Filters Donwload Employe",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Filters Donwload Employe",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Filters Donwload Employe",
                callBack: () => null
            })
        }
    }

    function onFilter() {
        getDataList();
    }

    useEffect(function () {
        getAllJobsite();
    }, []);

    return <context.Provider
        value={{
            reportDtCompPassedAction: {
                onFilter,
                setJobsite_code,
                setStream,
                setClusters,
                setBatches,
                getDataList,
            },
            reportDtCompPassedState: {
                dataJobsite,
                jobsite_code,
                stream,
                clusters,
                batches,
                filters,
                dataList,
                loadingGet
            }
        }}
    >{children}</context.Provider>
}

export default function useReportDataCompPassed() {
    return useContext(context);
}