import React, { createContext, useContext, useState } from 'react'
import { useModalAlert } from '../../../components/modalAlert';
import useFetch from '../../../hooks/useFetch';

const idpApprvContext = createContext({
    idpAppState: {},
    idpAppAction: {}
});

export function IdpApprovalProvider({ children }) {
    const [loadingGet, setLoadingGet] = useState(false);
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const { doGet, doPost } = useFetch();
    const [batch, setBatch] = useState('');
    const [dataJun, setDataJun] = useState([]);

    async function getDataJunior() {
        // openLoading("Get competency")
        setLoadingGet(true)
        try {
            const { status, statusText, payload } = await doGet({
                param: { batch },
                url: '/api/list-junior'
            });
            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDataJun([...data])
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Subordinate",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Subordinate",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Subordinate",
                callBack: () => null
            })
        }
        setLoadingGet(false)
    }

    return <idpApprvContext.Provider
        value={{
            idpAppState: {
                loadingGet,
                batch,
                dataJun
            },
            idpAppAction: {
                getDataJunior, 
                setBatch
            }
        }}
    >{children}</idpApprvContext.Provider>
}

export default function useIdpApproval() {
    return useContext(idpApprvContext);
}