import React from 'react';
import { StatusIcon, TableList } from '../../../components';
import { BiFile, BiPencil } from "react-icons/bi";
import './index.css';
import useDts, { DTSProvider } from './useDts';
import TableToHTML from './export';
import { htmlToExcel } from '../../../modules';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import ModalAtsDetail from '../components/modal-ats-detail';
import ModalTrainee from '../components/modalTrainee/modalTrainee';

function DTSPage(props) {
    const { dtsAction, dtsState } = useDts();

    return (
        <div>
            <div className='row'>
                <div className='col-6'>
                    <section className="content-header">
                        <div className="container-fluid">
                            <h4>Training Schedule</h4>
                            <h6>List Training</h6>
                        </div>
                    </section>
                </div>
                <div className='col-6'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end">
                            <li className="breadcrumb-item active" aria-current="page">List Training</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='card'>
                        <div className='card-body'>
                            {/* <div className='text-right mb-3'>
                                <button className='btn btn-sm btn-success'
                                    onClick={() => htmlToExcel("table-to-xls", `${dtsState?.batch}_Schedule_${moment(Date.now()).format("YYYY-MM-DD HH_mm_SS")}`, "statement")}
                                >
                                    <BiFile style={{ fonstSize: '12pt' }} /> Export Excel
                                </button>
                            </div> */}
                            <TableList
                                filter={true}
                                formsFilter={[
                                    {
                                        type: 'custom',
                                        component: (
                                            <input type='number' className='form-control form-control-sm'
                                                placeholder='Batch'
                                                min={0}
                                                value={dtsState?.batch}
                                                onChange={(val) => dtsAction.setBatch(val.target.value)}
                                            >
                                            </input>
                                        )
                                    }
                                ]}
                                labelFilter="Tahun IDP Register :"
                                submitButton={<><i className='fa fa-search'></i> Search</>}
                                onFilter={() => dtsAction.onFilter()}
                                resetFilter={false}
                                curPage={1}
                                minHeight="300px"
                                maxHeight="500px"
                                navtable={"TABLE 1"}
                                withCard={false}
                                isLoading={dtsState.loadingGet}
                                // onChangeShow={rekAction.changeShow}
                                // onNextPage={rekAction.nextPage}
                                // onPrevPage={rekAction.prevPage}
                                withActions={true}
                                actionType={1}
                                actionItems={[
                                    {
                                        name: 'Lihat detail',
                                        onClick: function (item) {
                                            dtsAction.setModalDetail({ show: true, id: item?.ats_id, data: { ...item } })
                                        }
                                    },
                                ]}
                                columns={[
                                    {
                                        name: '#',
                                        field: '#',
                                        maxWidth: '50px',
                                    },
                                    {
                                        name: "Jobsite",
                                        field: 'jobsite',
                                        minWidth: '100px',
                                    },
                                    // {
                                    //     name: 'Competency Name',
                                    //     field: 'competency_name',
                                    //     maxWidth: '220px',
                                    // },
                                    {
                                        name: 'Course Name',
                                        field: 'course_name',
                                        maxWidth: '250px',
                                    },
                                    {
                                        name: 'Trainer',
                                        field: 'trainer_name',
                                        maxWidth: '150px',
                                        onRender: (item) => {
                                            return (
                                                <span className='text-wrap'>{`${item?.trainer_name || ''} - ${item?.trainer_nik || ''}`}</span>
                                            )
                                        }
                                    },
                                    {
                                        name: 'Trainee',
                                        field: 'trainees',
                                        maxWidth: '300px',
                                        onRender: (item) => {
                                            return (
                                                <div>
                                                    {
                                                        [...(item?.trainees || [])].slice(0, 5).map((d, i) => (
                                                            <span className='badge badge-info mr-1'>
                                                                {
                                                                    d?.trainee_name
                                                                }
                                                            </span>
                                                        ))
                                                    }
                                                    {/* {
                                                        [...(item?.trainees || [])].length > 5 && (
                                                            <div className='d-block'><span type='button' className="badge badge-warning"
                                                                onClick={() => dtsAction.setModalDetail({ show: true, id: item?.ats_id, data: { ...item } })}
                                                            >see more</span></div>
                                                        )
                                                    } */}
                                                </div>
                                            )
                                        }
                                    },
                                    {
                                        name: 'Start Date',
                                        field: 'start_date',
                                        maxWidth: '150px',
                                        onRender: (item) => (
                                            <span className='text-wrap'>{moment(item?.start_date).format('DD/MM/YYYY')}</span>
                                        )
                                    },
                                    {
                                        name: 'Finish',
                                        field: 'end_date',
                                        maxWidth: '150px',
                                        onRender: (item) => (
                                            <span className='text-wrap'>{moment(item?.end_date).format('DD/MM/YYYY')}</span>
                                        )
                                    }
                                ]}
                                data={[...(dtsState.dataList || [])]}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                dialogClassName='modal-ats-cs'
                backdropClassName='bg-transparent'
                show={dtsState.modalDetail?.show}
                onHide={() => dtsAction.setModalDetail({ show: false, id: '', data: {} })}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Detail Training</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ModalAtsDetail {...dtsState.modalDetail} displayValue={[
                        { fieldName: 'type', caption: 'Type' },
                        { fieldName: 'jobsite', caption: 'Jobsite' },
                        { fieldName: 'cluster', caption: 'Cluster' },
                        { fieldName: 'competency_name', caption: 'Competency Name' },
                        { fieldName: 'course_name', caption: 'Course Name' },
                        { fieldName: 'trainer_name', caption: 'Trainer', onRender: (item) => <span className='text-wrap'>{`${item?.trainer_nik || ''} - ${item?.trainer_name || ''}`}</span> },
                        {
                            fieldName: 'trainees', caption: 'Trainee', onRender: (item) => <div>
                                {
                                    [...(item?.trainees || [])].slice(0, 5).map((d, i) => (
                                        <span className='badge badge-info mr-1 text-wrap text-left'>
                                            {
                                                d?.trainee_name
                                            }
                                        </span>
                                    ))
                                }
                                {
                                    [...(item?.trainees || [])].length > 5 && (
                                        <div className='d-block'><span type='button' className="badge badge-warning"
                                            onClick={() => dtsAction.setModalTrainee({ show: true, data: [...(item?.trainees || [])] })}
                                        >See Detail</span></div>
                                    )
                                }
                            </div>
                        },
                        { fieldName: 'start_date', caption: 'Start Date' },
                        { fieldName: 'end_date', caption: 'End Date' },
                        {
                            fieldName: 'status', caption: 'Status', onRender: (item) => {
                                return (
                                    <StatusIcon status={item?.status} />
                                    // <span className='text-wrap text-capitalize'>{item?.status}</span>
                                )
                            }
                        },
                    ]} />
                </Modal.Body>
                <Modal.Footer>
                    <div className='text-right'>
                        <button
                            onClick={() => dtsAction.setModalDetail({ show: false, id: '', data: {} })}
                            className='btn btn-sm btn-secondary'
                        >
                            Back
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal
                dialogClassName='modal-mts-create'
                // backdropClassName='bg-transparent'
                show={dtsState.modalTrainee?.show}
                onHide={() => dtsAction.setModalTrainee({ show: false, data: [] })}
            >
                <Modal.Header closeButton>
                    <Modal.Title>List Trainee</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ModalTrainee {...dtsState.modalTrainee} />
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default (props) => <DTSProvider> <DTSPage {...props} /></DTSProvider> 