import React from 'react';
import { useGlobalState } from '../../context';

export default function(){
    const { globalState, globalAction } = useGlobalState();
    return(
        <>
            {
                JSON.stringify(globalState)
            }
        </>
    )
}