import moment from 'moment';
import React from 'react';
import { useAuth } from '../../context';
import './personal_info.css';
import usePersonalInfo, { PersonalInfoProvider } from './use_personal_info';

function PersonalInfo(props) {
    const { userInfo } = useAuth();
    const { pInfoAction, pInfoState } = usePersonalInfo();
    return (
        <div>
            <div className='row'>
                <div className='col-6'>
                    <section className="content-header">
                        <div className="container-fluid">
                            <h4>Personal Information</h4>
                            <h6>Detail</h6>
                        </div>
                    </section>

                </div>
                <div className='col-6'>
                    <nav aria-label="breadcrumb">
                    <ol className="breadcrumb justify-content-end">
                        <li className="breadcrumb-item active" aria-current="page">Personal Info</li>
                    </ol>
                </nav>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='card'>
                        <div className='card-body p-3'>
                            <span className='d-block font-weight-bold'>Informasi Pekerja</span>
                            <div className='border-title'></div>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className='mb-3'>
                                        <span className='d-block text-wrap font-weight-bold'>Nama</span>
                                        <span className='d-block text-wrap'>{userInfo?.name || '-'}</span>
                                    </div>
                                    <div className='mb-3'>
                                        <span className='d-block text-wrap font-weight-bold'>NIK</span>
                                        <span className='d-block text-wrap'>{userInfo?.nik || '-'}</span>
                                    </div>
                                    <div className='mb-3'>
                                        <span className='d-block text-wrap font-weight-bold'>Tanggal bergabung</span>
                                        <span className='d-block text-wrap'>{
                                           userInfo.join_date ? moment( userInfo.join_date).format('DD/MM/YYYY') : '-'
                                        }</span>
                                    </div>
                                    <div className='mb-3'>
                                        <span className='d-block text-wrap font-weight-bold'>Point of Hire</span>
                                        <span className='d-block text-wrap'>{userInfo?.poh || '-'}</span>
                                    </div>
                                    <div className='mb-3'>
                                        <span className='d-block text-wrap font-weight-bold'>Jobsite</span>
                                        <span className='d-block text-wrap'>{userInfo?.pers_area || '-'}</span>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='mb-3'>
                                        <span className='d-block text-wrap font-weight-bold'>Function</span>
                                        <span className='d-block text-wrap'>{userInfo?.function || '-'}</span>
                                    </div>
                                    <div className='mb-3'>
                                        <span className='d-block text-wrap font-weight-bold'>Jabatan</span>
                                        <span className='d-block text-wrap'>{userInfo?.job_title || '-'}</span>
                                    </div>
                                    <div className='mb-3'>
                                        <span className='d-block text-wrap font-weight-bold'>Level</span>
                                        <span className='d-block text-wrap'>{userInfo?.level || '-'}</span>
                                    </div>
                                    <div className='mb-3'>
                                        <span className='d-block text-wrap font-weight-bold'>Grade</span>
                                        <span className='d-block text-wrap'>{userInfo?.grade || '-'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='card'>
                        <div className='card-body p-3'>
                            <span className='d-block font-weight-bold'>Struktur Organisasi</span>
                            <div className='border-title'></div>
                            <div className='row'>
                                <div className='col-12 text-center'>
                                    {
                                        userInfo?.indirect_supervisor_name &&
                                        <div className='mb-3'>
                                            <div className='p-2 badge-lblue rounded'>{
                                                pInfoAction.getInitialName(userInfo?.indirect_supervisor_name)
                                            }</div>
                                            <span className='d-block text-wrap font-weight-bold'>{userInfo?.nik_indirect_supervisor} - {userInfo?.indirect_supervisor_name}</span>
                                            <span className='d-block text-wrap font-weight-bold'>{userInfo?.indirect_supervisor_job}</span>
                                        </div>
                                    }
                                    {
                                        userInfo?.direct_supervisor_name &&
                                        <div className='mb-3'>
                                            <div className='p-2 badge-lblue rounded'>{
                                                pInfoAction.getInitialName(userInfo?.direct_supervisor_name)
                                            }</div>
                                            <span className='d-block text-wrap font-weight-bold'>{userInfo?.nik_direct_supervisor} - {userInfo?.direct_supervisor_name}</span>
                                            <span className='d-block text-wrap font-weight-bold'>{userInfo?.direct_supervisor_job}</span>
                                        </div>
                                    }
                                    {
                                        userInfo?.name &&
                                        <div className='mb-3'>
                                            <div className='p-2 badge-lblue rounded'>{
                                                pInfoAction.getInitialName(userInfo?.name)
                                            }</div>
                                                <span className='d-block text-wrap font-weight-bold'>{userInfo?.nik} - {userInfo?.name}</span>
                                            <span className='d-block text-wrap font-weight-bold'>{userInfo?.job_title}</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default (props) => <PersonalInfoProvider><PersonalInfo {...props} /></PersonalInfoProvider>;