import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useModalAlert } from "../../../components/modalAlert";
import useFetch from "../../../hooks/useFetch";
import ModalEditor from "./result-list-trainee/components/modal-editor";
import ModalTrainee from "./result-list-training/components/modal-trainee";

const raContext = createContext({
    raState: {},
    raAction: {}
})

const iniStatePage = {
    title: "List Training",
    type: "ResultTraining",
    dataPage: {}
};
const iniStateModalTrainee = {
    show: false,
    data: [],
    dataFilter: [],
    search: ''
}
const iniStateEditor = {
    "ats_id": '',
    "start_date": "",
    "end_date": "",
    "pre_test_score": '',
    "post_test_score": '',
    "day_present": 0
}
export function RaProvider({ children }) {
    const [showEditor, setShowEditor] = useState({
        show: false,
        data: {}
    });
    const [pageState, setPageState] = useState(iniStatePage);
    const [dataEditor, setDataEditor] = useState(iniStateEditor);
    const { doGet, doPost } = useFetch()
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;

    const [batch, setBatch] = useState();
    const [lsTraining, setLsTraining] = useState([]);
    const [loadingTraining, setoadingTraining] = useState(false);
    const getListTraining = useCallback(async function () {
        try {
            setoadingTraining(true);
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/assessment/training-list-result',
                    param: {
                        type_page: "result_training",
                        batch: batch || ''
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setLsTraining(data?.tableData || []);
                    setBatch(data?.batch);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get List Training",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List Training",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Training",
                callBack: () => null
            })
        }
        setoadingTraining(false)
    }, [batch]);

    const [loadingDetail, setLoadingDetail] = useState(false);
    const [dataDetail, setDataDetail] = useState([]);
    const getDetail = useCallback(async function () {
        try {
            setLoadingDetail(true);
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/assessment/assessee-list',
                    param: {
                        "competency_id": pageState.dataPage?.competency_id,
                        "course_id": pageState.dataPage?.course_id,
                        batch: batch || ''
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDataDetail(data?.listTrainee || []);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Detail Training",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Detail Training",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Detail Training",
                callBack: () => null
            })
        }
        setLoadingDetail(false)
    }, [pageState, batch]);
    const doSubmit = useCallback(async function (data) {
        closeConfirm()
        openLoading("Sending data, please wait...");
        try {
            const { status, payload, statusText } = await doPost({
                url: "/api/assessment/store-score",
                data: {
                    ...data
                }
            });

            if (status !== 401) {
                if (status === 200 || status === 201) {
                    const { code, msg } = payload;
                    if (code === "00") {
                        setShowEditor({ show: false, data: {} });
                        setDataAlert({
                            body: msg,
                            show: true,
                            header: "Success",
                            callBack: () => getDetail()
                        })

                    } else setDataAlert({
                        show: true,
                        body: msg,
                        header: "Info",
                        callBack: () => null
                    })
                } else setDataAlert({
                    show: true,
                    body: statusText,
                    header: "Info",
                    callBack: () => null
                })
            }
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Info",
                callBack: () => null
            })
        }
        closeLoading();
    }, [pageState])
    function handleChange(name = '', value = '') {
        setDataEditor({
            ...dataEditor,
            [name]: value,
        })
    }
    function confirmSubmit() {
        setDataConfirm({
            show: true,
            body: "Sebelum melanjutkan, cek kembali data yang diupdate. Lanjutkan ?",
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                {
                    name: "Yes", onClick: () => doSubmit({
                        training_id: dataEditor?.training_id,
                        score: dataEditor?.score
                    }), className: "btn btn-sm btn-success"
                },
                { name: "No", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }
    const openModalEditor = (data = {}) => {
        setShowEditor({
            show: true,
            data: data,
        })
    }
    const closeModalEditor = () => {
        setShowEditor({ ...iniStateEditor });
    }


    const toTrainingPage = useCallback(function () {
        setPageState({ ...iniStatePage });
    }, [pageState]);
    const toTraineePage = useCallback(function (data = {}) {
        setPageState({
            title: 'List Trainee',
            type: 'ResultTrainee',
            dataPage: { ...data }
        });
    }, [pageState]);

    const [modalTrainee, setModalTrainee] = useState(iniStateModalTrainee);
    const openModalTrainee = (data = []) => {
        setModalTrainee({
            show: true,
            data: data,
            dataFilter: data,
            search: ''
        })
    }
    const closeModalTrainee = () => {
        setModalTrainee({ ...iniStateModalTrainee });
    }
    const onFilterModalTrainee = () => {
        let newData = [];
        newData = modalTrainee.data.filter((e) => {
            return (e.trainee_name || '').toLowerCase().indexOf(modalTrainee.search.toLowerCase()) > -1 || (e.trainee_nik || '').toLowerCase().indexOf(modalTrainee.search.toLowerCase()) > -1
        });
        setModalTrainee((e) => ({
            ...e,
            dataFilter: newData
        }));
    }
    const onFilter = () => {
        getListTraining();
    }

    useEffect(() => {
        if (pageState.type === 'ResultTraining') getListTraining();
        if (pageState.type === 'ResultTrainee') {
            getDetail()
        }
    }, [pageState]);
    useEffect(() => {
        if (showEditor.show) {
            setDataEditor({ ...showEditor.data })
        } else {
            setDataEditor({ ...iniStateEditor })
        }
    }, [showEditor])
    return <raContext.Provider
        value={{
            raState: {
                pageState,
                lsTraining,
                loadingTraining,
                modalTrainee,
                loadingDetail,
                dataDetail,
                showEditor,
                dataEditor,
                batch
            },
            raAction: {
                toTrainingPage,
                toTraineePage,
                openModalTrainee,
                closeModalTrainee,
                onFilterModalTrainee,
                setModalTrainee,
                handleChange,
                confirmSubmit,
                openModalEditor,
                closeModalEditor,
                setBatch,
                onFilter
            }
        }}
    >
        {children}
        <ModalTrainee />
        <ModalEditor />
    </raContext.Provider>
}

export default function useResultA() {
    return useContext(raContext);
}
