import moment from 'moment';
import React from 'react';
import { TableList } from '../../../components';
import useRtTrainee, { RttProvider } from './useRTTrainee';

function Index(props) {
    const { rttAction, rttState } = useRtTrainee();
    return (
        <div>
            <div className='row'>
                <div className='col-6'>
                    <section className="content-header">
                        <div className="container-fluid">
                            <h4>Training Result</h4>
                            <h6>List Training</h6>
                        </div>
                    </section>
                </div>
                <div className='col-6'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end">
                            <li className="breadcrumb-item active" aria-current="page">List Training</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='card'>
                        <div className='card-body'>
                            <TableList
                                filter={true}
                                formsFilter={[
                                    {
                                        type: 'custom',
                                        placeholder: 'Nama',
                                        component: (
                                            <input type='number' className='form-control form-control-sm'
                                                value={rttState?.batch}
                                                onChange={(val) => rttAction.setBatch(val.target.value)}
                                            >
                                            </input>
                                        )
                                    }
                                ]}
                                labelFilter="Tahun IDP Register :"
                                resetFilter={false}
                                onFilter={() => rttAction.onFilter()}
                                curPage={1}
                                minHeight="300px"
                                maxHeight="500px"
                                navtable={"TABLE 1"}
                                withCard={false}
                                // isLoading={dtsState.loadingGet}
                                // onChangeShow={rekAction.changeShow}
                                // onNextPage={rekAction.nextPage}
                                // onPrevPage={rekAction.prevPage}
                                withActions={false}
                                actionType={1}
                                // actionItems={[
                                //     {
                                //         name: 'Lihat detail',
                                //         onClick: function (item) {
                                //             dtsAction.setModalDetail({ show: true, id: item?.ats_id, data: { ...item } })
                                //         }
                                //     },
                                // ]}
                                columns={[
                                    {
                                        name: '#',
                                        field: '#',
                                        maxWidth: '50px',
                                    },
                                    {
                                        name: "Competency Name",
                                        field: 'competency_name',
                                        minWidth: '100px',
                                    },
                                    {
                                        name: 'Course Name',
                                        field: 'course_name',
                                        maxWidth: '250px',
                                    },
                                    {
                                        name: 'Trainer',
                                        field: 'trainer_name',
                                        maxWidth: '150px',
                                        onRender: (item) => {
                                            return (
                                                <span className='text-wrap'>{`${item?.trainer_name || ''} - ${item?.trainer_nik || ''}`}</span>
                                            )
                                        }
                                    },
                                    {
                                        name: "Nilai Pre Test",
                                        field: 'pre_test_score',
                                        minWidth: '100px',
                                        onRender: (item) => {
                                            return (
                                                <span className={`text-wrap ${!item?.pre_test_score ? "text-secondary" : ""}`}>{`${item?.pre_test_score || 'Belum ada nilai'}`}</span>
                                            )
                                        }
                                    },
                                    {
                                        name: "Nilai Post Test",
                                        field: 'post_test_score',
                                        minWidth: '100px',
                                        onRender: (item) => {
                                            return ( 
                                                <span className={`text-wrap ${!item?.post_test_score ? "text-secondary" : ""}`}>{`${item?.post_test_score || 'Belum ada nilai'}`}</span>
                                            )
                                        }
                                    },
                                    {
                                        name: '% Kehadiran',
                                        field: 'day_present',
                                        maxWidth: '150px',
                                        onRender:(item) => (
                                            <span>{(item?.day_present * 100 / item?.duration) || 0} %</span>
                                        )
                                    },
                                    {
                                        name: 'Start Date',
                                        field: 'start_date',
                                        maxWidth: '150px',
                                        onRender: (item) => {
                                            return (
                                                <span className={`text-wrap ${!item?.start_date ? "text-secondary" : ""}`}>{`${item?.start_date ? moment( item?.start_date) .format("DD/MM/YYYY"): 'Belum ada jadwal'}`}</span>
                                            )
                                        }
                                    },
                                    {
                                        name: 'Finish',
                                        field: 'end_date',
                                        maxWidth: '150px',
                                        onRender: (item) => {
                                            return (
                                                <span className={`text-wrap ${!item?.end_date ? "text-secondary" : ""}`}>{`${item?.end_date? moment(item?.end_date).format("DD/MM/YYYY") : 'Belum ada jadwal'}`}</span>
                                            )
                                        }
                                    }
                                ]}
                                data={[...rttState.data]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default (props) => <RttProvider><Index {...props} /></RttProvider>;