import React, { useEffect, useState } from 'react';
import Select from 'react-select';

export default function ({
    placeholder = "",
    onChange = () => null,
    display = "display",
    keyValue = "key",
    value = "",
    options = [],
    disabled = false,
    className = "",
    required = false,
    size = "md",
    zIndex = null,
    onFocus = () => null,
    onMenuOpen = () => null,
    isInValid = false
}) {

    const [errRequired, setErrorRequired] = useState("#ced4da")
    const height = size === "sm" ? 28 : size === "md" ? 38 : 38;
    // sm = 30px,
    // md = 38px
    function getRequiredColor() {
        if (required) {
            if (value === "" || value === undefined || value === null) return setErrorRequired("red")
            else return setErrorRequired("#ced4da")
        } else return setErrorRequired("#ced4da")
    }
    const styles = {
        control: styles => {
            // console.log(styles)
            return ({
                ...styles,
                minHeight: 10,
                borderColor: isInValid ? 'red' : '#ced4da',
                height,
                // fontSize:10,
                // margin:4,
                padding: 0,
                ...(zIndex !== null ? { zIndex } : {})
            })
        },
        placeholder: styles => {
            return {
                ...styles,
                top: size === "sm" ? 5 : 13,
                // position:"static",
                // paddingTop:20
            }
        },
        input: styles => {
            // console.log(styles)
            return ({
                ...styles,
                paddingBottom: 10,
                position: "absolute",
                ...(zIndex !== null ? { zIndex } : {})
                // height:10,
                // margin:3
            })
        },
        indicatorContainer: (styles, { data, isDisabled, isFocused, isSelected }) => {
            //console.log(isFocused)
            return {
                ...styles,
                padding: "4px 8px",
                height: height,
                ...(zIndex !== null ? { zIndex } : {})

                // position:"absolute"
                // position:"static",
                // paddingTop:20
            }
        },
        valueContainer: styles => ({
            ...styles,
            height: 20,
            minHeight: 10,
            position: "static",
            zIndex,
            ...(size === "sm" ? {
                position: "relative",
                top: "-4px"
            } : {})

        }),
        indicatorSeparator: style => ({
            display: "none"
        }),
        container: styles => ({
            ...styles,
            height,
            minHeight: 10,
            ...(zIndex !== null ? { zIndex } : {})
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected, ...sty }) => {
            return {
                ...styles,
                color: isFocused ? 'white' : 'black',
                cursor: disabled ? 'not-allowed' : 'default',
                padding: 1,
                paddingLeft: 10,
                backgroundColor: isFocused ? "#007bff" : "default",
                fontSize: "12pt",
                innerHeight: 6,
                // height: 20

            };
        },
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({ ...provided, zIndex: 9999 })
    };


    return <Select
        // className={className}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={getRequiredColor}
        styles={styles}
        onMenuOpen={() => onMenuOpen?.()}
        onFocus={() => {
            // getRequiredColor();
            onFocus?.();
        }}
        menuPortalTarget={document.body}
        menuPosition={"fixed"}
        // maxMenuHeight={500}
        value={
            value !== null ? ([
                [
                    {
                        value: "",
                        label: placeholder
                    },
                    ...(
                        options?.map(v => (
                            { value: v[keyValue], label: `${v[display]}` }
                        )) || []
                    )
                ]
                    .find(c => c.value === value) || null
            ]) : null
        }
        isDisabled={disabled}
        options={
            [{
                value: "",
                label: placeholder
            }, ...(options?.map(v => ({ value: v[keyValue], label: `${v[display]}` })) || [])]
        }
    />
}