import React from 'react';
import useDwnldDataCompReport from './useDwnldDataCompReport';

const TableToHTML = () => {
    const { dwnldDtCompReportState } = useDwnldDataCompReport();

    return (
        <table className="w-100 f-10" id="table-to-xls" style={{ display: "none" }}>
            <thead>
                <tr>
                    <th>#</th>
                    <th>NIK SAP</th>
                    <th>Nama</th>
                    <th>Jobsite</th>
                    <th>Jobsite Code</th>
                    <th>Group</th>
                    <th>Grade</th>
                    <th>Cluster</th>
                    <th>Competency Name</th>
                    <th>Point</th>
                    <th>Course Name</th>
                    <th>Post Test</th>
                    <th>Tanggal Training</th>
                    <th>NIK Trainer</th>
                    <th>Trainer</th>
                    <th>Kehadiran Training</th>
                    <th>Tanggal Assesment</th>
                    <th>NIK Assesor</th>
                    <th>Assesor</th>
                    <th>Kehadiran Assessment</th>
                    <th>Assesment Result</th>
                    <th>Year of Training</th>
                    <th>NIK Approver</th>
                    <th>Requested Training Date</th>
                    <th>Approved Training Date</th>
                    <th>Passed Assessment Date</th>
                </tr>
            </thead>
            <tbody>
                {
                    (dwnldDtCompReportState.dataList || []).map((d, i) => (
                        <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{d?.nik}</td>
                            <td>{d?.employee_name}</td>
                            <td>{d?.jobsite_name}</td>
                            <td>{d?.jobsite_code}</td>
                            <td>{d?.group}</td>
                            <td>{d?.grade}</td>
                            <td>{d?.cluster}</td>
                            <td>{d?.competency_name}</td>
                            <td>{d?.point}</td>
                            <td>{d?.course_name}</td>
                            <td>{d?.post_test_score}</td>
                            <td>{d?.training_date}</td>
                            <td>{d?.trainer_nik}</td>
                            <td>{d?.trainer_name}</td>
                            <td>{d?.percent_day_present_training}</td>
                            <td>{d?.assessment_date}</td>
                            <td>{d?.assessor_nik}</td>
                            <td>{d?.assessor_name}</td>
                            <td>{d?.percent_day_present_assessment}</td>
                            <td>{d?.assessment_result}</td>
                            <td>{dwnldDtCompReportState.batch}</td>
                            <td>{d?.nik_approver}</td>
                            <td>{d?.requested_training_date}</td>
                            <td>{d?.approved_training_date}</td>
                            <td>{["competent", "Competent"].includes(d?.assessment_result) ? d?.update_at_assessment : ""}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    );
}

export default TableToHTML;
