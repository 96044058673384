import React from 'react';
import useLsJobsite, { LsJobProvider } from './useLsJobsite';
import { useHistory } from 'react-router-dom'
import { TableList } from '../../../../components';

function Index(props) {
    const { lsJobState, lsJobAction } = useLsJobsite();
    const history = useHistory();
    return (
        <div>
            <section className="content-header">
                <div className="container-fluid">
                    <div className='row'>
                        <div className='col-md-6'>
                            <h4>Status Annual Training Schedule</h4>
                            <h6>List Jobsite</h6>
                        </div>
                        <div className='col-md-6'>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-end">
                                    <li className="breadcrumb-item active" aria-current="page">List Jobsite</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <div className='card'>
                <div className='card-body'>
                    <TableList
                        filter={true}
                        formsFilter={[
                            {
                                type: 'custom',
                                placeholder: 'Nama',
                                component: (
                                    <input type='number' className='form-control form-control-sm'
                                        value={lsJobState?.batch}
                                        onChange={(val) => lsJobAction.setBatch(val.target.value)}
                                    >
                                    </input>
                                )
                            }
                        ]}
                        labelFilter="Tahun IDP Register :"
                        resetFilter={false}
                        onFilter={() => lsJobAction.onFilter()}
                        curPage={1}
                        minHeight="300px"
                        maxHeight="500px"
                        navtable={"TABLE 1"}
                        withCard={false}
                        isLoading={lsJobState.loadingGet}
                        // onChangeShow={rekAction.changeShow}
                        // onNextPage={rekAction.nextPage}
                        // onPrevPage={rekAction.prevPage}
                        withActions={false}
                        columns={[
                            {
                                name: '#',
                                field: '#',
                                maxWidth: '50px',
                            },
                            {
                                name: 'Jobsite',
                                field: 'jobsite_name',
                            },
                            {
                                name: 'Action',
                                field: '',
                                maxWidth: '50px',
                                minWidth: '50px',
                                onRender: (item) => (
                                    <button className='btn btn-sm btn-primary'
                                        onClick={() => history.push({
                                            pathname: '/ats/ats-status/list-idp',
                                            state: {
                                                jobsite_code: item?.jobsite_code,
                                                jobsite_name: item?.jobsite_name,
                                                batch: lsJobState?.batch
                                            }
                                        })}
                                    >Lihat</button>
                                )
                            },
                        ]}
                        pagination={false}
                        data={[...(lsJobState.dataList?.jobsites || [])]}
                    />
                </div>
            </div>
        </div>
    );
}

export default (props) => <LsJobProvider><Index {...props} /></LsJobProvider>;
