import React from 'react';
import useMatrixComp, { MatrixCompProvider } from './useMatrixComp';
import { TableList } from '../../../components';
import { Link, useHistory } from 'react-router-dom';

function Index(props) {
    const { matrixCompAction, matrixCompState } = useMatrixComp();
    const history = useHistory();
    const CustomToolbar = () => (
        <div className='tw-flex tw-justify-end'>
            <Link to="/master-data/matrix-competency/add">
                <button className='btn btn-sm btn-success'>Create</button>
            </Link>
        </div>
    )

    return (
        <>
            <div className="row">
                <div className="col-6">
                    <section className="content-header">
                        <div className="container-fluid">
                            <h4>Master Data</h4>
                            <h6>Matriks Kompetensi</h6>
                        </div>
                    </section>
                </div>
                <div className="col-6">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end">
                            <li className="breadcrumb-item" aria-current="page">
                                Master Data
                            </li>
                            <li
                                className="breadcrumb-item active"
                                aria-current="page"
                            >
                                Matriks Kompetensi
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="tw-flex tw-items-center">
                                <h6>Upload Matriks Kompetensi</h6>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="tw-grid md:tw-grid-cols-3 tw-grid-cols-1 tw-w-full tw-items-center tw-gap-x-3 tw-gap-y-2">
                                <div>
                                    <label> Type </label>
                                    <select
                                        onChange={(e) => matrixCompAction.handleChangeFom("type", e.target.value)}
                                        value={matrixCompState.formData?.type}
                                        className="form-select "
                                    >
                                        <option value={""}>
                                            - Pilih -
                                        </option>
                                        {matrixCompState.dataType.map(d => (
                                            <option key={"upload-data-type-" + d} value={d}>
                                                {d}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <label>File</label>
                                    <input
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/csv, text/csv, .csv, .xls, .xlsx"
                                        onChange={(e) => matrixCompAction.handleChangeFom("file", e.target.files[0])}
                                        type="file"
                                        className="form-control"
                                    />
                                </div>
                                <div>

                                    <label>&nbsp;</label>
                                    <button
                                        className="btn lg:btn-sm btn-primary md:tw-mt-8"
                                        onClick={() => {
                                            matrixCompAction.doUpload()
                                        }}
                                    >
                                        Upload
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div className='tw-grid md:tw-grid-cols-6 tw-grid-cols-1 tw-w-full tw-items-center tw-gap-x-3 tw-gap-y-2'>
                                <div>
                                    <label className="font-weight-normal">Type</label>
                                    <select
                                        onChange={(e) => matrixCompAction.setFilter(vs => ({ ...vs, type: e.target.value }))}
                                        value={matrixCompState.filter?.type}
                                        className="form-select form-select-sm"
                                    >
                                        <option value={""}>
                                            - Semua -
                                        </option>
                                        {matrixCompState.dataType.map(d => (
                                            <option key={"data-type-" + d} value={d}>
                                                {d}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <label className="font-weight-normal">Cluster</label>
                                    <input type='text' className='form-control form-control-sm'
                                        placeholder='Cluster'
                                        value={matrixCompState.filter?.cluster}
                                        onChange={(e) => matrixCompAction.setFilter(vs => ({ ...vs, cluster: e.target.value }))}
                                    >
                                    </input>
                                </div>
                                <div>
                                    <label className="font-weight-normal">Grade</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Grade"
                                        value={matrixCompState.filter?.grade}
                                        onChange={(e) => {
                                            matrixCompAction.setFilter(vs => ({ ...vs, grade: e.target.value }))
                                        }}
                                    ></input>
                                </div>
                                <div>
                                    <label className="font-weight-normal">Group</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Group"
                                        value={matrixCompState.filter?.group}
                                        onChange={(e) => {
                                            matrixCompAction.setFilter(vs => ({ ...vs, group: e.target.value }))
                                        }}
                                    ></input>
                                </div>
                                <div>
                                    <label className="font-weight-normal">Name</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Name"
                                        value={matrixCompState.filter?.name}
                                        onChange={(e) => {
                                            matrixCompAction.setFilter(vs => ({ ...vs, name: e.target.value }))
                                        }}
                                    ></input>
                                </div>
                                <div className="tw-flex tw-h-full">
                                    <button className='btn btn-sm btn-primary tw-mt-auto'
                                        onClick={() => matrixCompAction.doFilter()}
                                    ><i className='fa fa-search'></i> Search</button>
                                </div>
                            </div>
                            <TableList
                                filter={true}
                                data={matrixCompState.dataList || []}
                                labelFilter=""
                                withCard={false}
                                resetFilter={false}
                                isLoading={matrixCompState.loadingList}
                                minHeight="300px"
                                maxHeight="500px"
                                navtable={"TABLE 1"}
                                customToolbar={<CustomToolbar />}
                                withButtonSubmit={false}

                                onChangeShow={matrixCompAction.changeShow}
                                onNextPage={matrixCompAction.nextPage}
                                onPrevPage={matrixCompAction.onPrevPage}
                                defaultShow={matrixCompState.pagination?.show}
                                curPage={matrixCompState.pagination?.page || 1}
                                maxPage={matrixCompState.pagination?.totalPage || 1}
                                totalData={matrixCompState.pagination?.totalData || 0}
                                withActions={true}
                                actionType={1}
                                actionItems={[
                                    {
                                        name: "Lihat detail",
                                        onClick: function (item) {
                                            history.push(`/master-data/matrix-competency/detail?id=${item.id}`)
                                        },
                                    },
                                    {
                                        name: "Ubah",
                                        onClick: function (item) {
                                            history.push(`/master-data/matrix-competency/ubah?id=${item.id}`)
                                        },
                                    },
                                    {
                                        name: "Hapus",
                                        onClick: function (item) {
                                            matrixCompAction.doDelete(item.id)
                                        },
                                    },
                                ]}
                                columns={[
                                    {
                                        name: "#",
                                        field: "#",
                                    },
                                    {
                                        name: "Grade",
                                        field: "grade",
                                    },
                                    {
                                        name: "Cluster",
                                        field: "cluster",
                                    },
                                    {
                                        name: "Group Competency",
                                        field: "group",
                                    },
                                    {
                                        name: "Competency Name",
                                        field: "name",
                                    },
                                    {
                                        name: "Point",
                                        field: "point",
                                    },
                                    {
                                        name: "Cource Name",
                                        field: "course",
                                    },
                                ]}
                            />
                        </div>
                        <div className="card-footer"></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default () => <MatrixCompProvider><Index /></MatrixCompProvider>;
