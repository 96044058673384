import { toInteger } from "lodash";
import moment from "moment";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useModalAlert } from "../../../../components/modalAlert";
import useFetch from "../../../../hooks/useFetch";

const crScContext = createContext({
    crScState: {},
    crScAction: {}
});

const iniStateFormData = {
    "ats_id": "",
    "trainer_nik": "",
    "quota": 0,
    "start_date": "",
    "end_date": "",
    "duration": 1
}

export function CrScProvider({ children }) {
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const { doGet, doPost } = useFetch();
    const history = useHistory();
    const [dataState, setdataState] = useState(history.location.state);
    const [dataGet, setDataGet] = useState({});
    const [loadingGet, setLoadingGet] = useState(false);
    const [dataTrainer, setDataTrainer] = useState([]);
    const [loadingTrainer, setLoadingTrainer] = useState(false);
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState(iniStateFormData);
    const [dataTable, setDataTable] = useState([]);
    const [loadingGetTable, setLoadingGetTable] = useState(false);
    const [deleted_ats_ids, setDeleted_ats_ids] = useState([]);

    async function getCreateSchedule() {
        try {
            setLoadingGet(true);
            const { status, statusText, payload } = await doPost(
                {
                    url: '/api/ats/create-schedule',
                    queryParam: {
                        "jobsite_code": dataState?.jobsite_code,
                        "course_name": dataState?.course_name,
                        "batch": dataState?.batch
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDataGet({ ...data });
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Create Schedule",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Create Schedule",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Create Schedule",
                callBack: () => null
            })
        }
        setLoadingGet(false)
    }
    async function getDataList() {
        try {
            setLoadingGetTable(true);
            const { status, statusText, payload } = await doPost(
                {
                    url: '/api/ats/ats-percourse',
                    queryParam: {
                        "jobsite_code": dataState?.jobsite_code,
                        "course_name": dataState?.course_name,
                        "batch": dataState?.batch
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = [], code, msg } = payload;
                if (code === "00") {
                    setDataTable([...data]);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Data Table",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Data Table",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Data Table",
                callBack: () => null
            })
        }
        setLoadingGetTable(false)
    }
    async function getTrainer() {
        try {
            setLoadingTrainer(true);
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/ats/list-trainer',
                    param: {
                        "jobsite_code": dataGet?.jobsiteCode,
                        "course_type": dataGet?.coureType
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = [], code, msg } = payload;
                if (code === "00") {
                    setDataTrainer((data || []).map(d => ({
                        nik: d?.nik,
                        name: `${d?.nik} - ${d?.name}`
                    })));
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Create Schedule",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Create Schedule",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Create Schedule",
                callBack: () => null
            })
        }
        setLoadingTrainer(false)
    }

    function resetForm() {
        setFormData({ ...iniStateFormData });
        setValidated(false);
        setDeleted_ats_ids([]);
    }
    function handleChange(name = '', val = '') {
        if (name === "duration") {
            setFormData((prev) => {
                return {
                    ...prev,
                    [name]: val,
                    end_date: (val && (prev.start_date !== '' && prev.start_date !== "Invalid date")) ? moment(prev.start_date).add(val - 1, 'days').format('YYYY-MM-DD') : ''
                }
            })
        } else if (name === 'start_date') {
            setFormData((prev) => {
                return {
                    ...prev,
                    [name]: val,
                    end_date: (val !== '' && val !== "Invalid date") && prev.duration ? moment(val).add(prev.duration - 1, 'days').format('YYYY-MM-DD') : ''
                }
            })
        } else {
            setFormData((e) => (
                {
                    ...e,
                    [name]: val
                }
            ))
        }
    }
    function onSubmit() {
        const validate = validation([...dataTable, { ...formData }]);
        if (validate.isValid === false) {
            return setDataAlert({
                show: true,
                body: <div className='text-center'>{validate.message}</div>,
                header: <div className='text-center'>Warning !</div>,
                callBack: () => null
            })
        }
        setDataTable([...dataTable, {
            ...formData,
            trainer_name: dataTrainer.find(d => d?.nik === formData?.trainer_nik)?.name
        }])
    }
    function validation(dt = []) {
        const tTable = dt.reduce((tot, prev) => tot += toInteger(prev?.quota, 0), 0);
        return {
            isValid: (dataGet?.totalTrainee || 0) < tTable ? false : true,
            message: (dataGet?.totalTrainee || 0) < tTable ? "Kuota Training melebihi peserta terdaftar" : ''
        }
    }
    function confirmSubmit(status) {
        // let formType = paramUrl.mode === "edit" ? "ubah data" : "pendaftaran";
        let body = `Sebelum melanjutkan, cek kembali data yang disubmit. Lanjutkan ?`;
        if (status === 'submitted') {
            setDataConfirm({
                show: true,
                body,
                witTextArea: false,
                header: "Confirmation",
                title: "",
                closeButton: false,
                footerButton: [
                    { name: "Yes", onClick: () => doSubmit(status), className: "btn btn-sm btn-success" },
                    { name: "No", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
                ]
            })
        } else {
            doSubmit(status);
        }
    }
    function deleteDataKirim(ats_id = '', rowNumb = 0) {
        setDataConfirm({
            show: true,
            body: `Data akan terhapus. Lanjutkan?`,
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                {
                    name: "Yes", onClick: () => {
                        closeConfirm()
                        let newData = [];
                        if (ats_id !== undefined && ats_id !== null && ats_id !== "") {
                            setDeleted_ats_ids(v => ([...v, ats_id]));
                            newData = dataTable.filter((d, i) => d?.ats_id !== ats_id);
                        } else {
                            newData = dataTable.filter((d, i) => i !== rowNumb);
                        }
                        setDataTable(newData);
                        setDataAlert({
                            body: <>
                                <h5 className='text-center mb-3'>Berhasil dihapus</h5>
                                <p className='text-center ' style={{ fontSize: "small" }}> Data akan terhapus permanent setelah disubmit atau simpan sebagai draft </p>
                            </>,

                            show: true,
                            header: "",
                        })
                    }, className: "btn btn-sm btn-success"
                },
                { name: "No", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }
    async function doSubmit(sts) {
        closeConfirm()
        openLoading("Sending data, please wait...");
        const dataKirim = {
            "status": sts,
            "jobsite_code": dataGet?.jobsiteCode,
            "course_type": dataGet?.coureType,
            "course_name": dataGet?.courseName,
            "data_input": dataTable.map(d => ({
                "ats_id": d?.ats_id,
                "trainer_nik": d?.trainer_nik,
                "quota": toInteger(d?.quota || '0'),
                "start_date": d?.start_date,
                "end_date": moment(new Date(d?.end_date)).format('YYYY-MM-DD'),
                "duration": d?.duration || 0
            })),
            "deleted_ats_ids": [...deleted_ats_ids],
            "total_trainee": dataGet?.totalTrainee
        }
        // console.log(dataKirim)
        try {
            const { status, payload, statusText } = await doPost({
                url: "/api/ats/store-ats",
                data: {
                    ...dataKirim
                }
            });

            if (status !== 401) {
                if (status === 200 || status === 201) {
                    const { code, msg } = payload;
                    if (code === "00") {
                        setDataAlert({
                            body: msg,
                            show: true,
                            header: "Success",
                            callBack: () => { resetForm(); getDataList(); }
                        })
                    } else setDataAlert({
                        show: true,
                        body: msg,
                        header: "Info",
                        callBack: () => null
                    })
                } else setDataAlert({
                    show: true,
                    body: statusText,
                    header: "Info",
                    callBack: () => null
                })
            }
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Info",
                callBack: () => null
            })
        }
        closeLoading();
    }
    useEffect(() => {
        if (!dataState?.course_name && !dataState?.jobsite_code) {
            return setDataAlert({
                show: true,
                body: "Buka halaman ini melalui menu ATS Create",
                header: "IDP tidak di pilih",
                callBack: () => null
            })
        }
        getCreateSchedule();
        getDataList();
    }, []);

    return <crScContext.Provider
        value={{
            crScState: {
                dataState,
                dataGet,
                loadingGet,
                dataTrainer,
                loadingTrainer,
                validated,
                formData,
                dataTable,
                loadingGetTable
            },
            crScAction: {
                getTrainer,
                setValidated,
                handleChange,
                resetForm,
                onSubmit,
                confirmSubmit,
                deleteDataKirim
            }
        }}
    >{children}</crScContext.Provider>
}

export default function useCreateSchedule() {
    return useContext(crScContext);
}
