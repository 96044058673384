import React from 'react';
import useDashboard from '../useDashboard';

function Index(props) {
    const { dashAction: {
        setSearchForm = () => null,
        onSearch = () => null
    }, dashState: {
        searchForm = "",
    } } = useDashboard();
    return (
        <div className='card p-3'>
            <div className='card-body'>
                <form
                    onSubmit={(e) => {
                        e.preventDefault(e);
                        onSearch();
                    }}
                >
                    <div className="form-group">
                        <label htmlFor="inputEmail4">Cari berdasarkan NIK : </label>
                        <div className="input-group mb-3">
                            <input type="text"
                                value={searchForm}
                                required
                                onChange={(e) => setSearchForm(e.target.value)}
                                className="form-control" placeholder="Recipient's username" aria-label="Cari berdasarkan NIK" aria-describedby="basic-addon2" />
                            <div className="input-group-append">
                                <button type='submit' className='btn btn-sm btn-primary'><i className='fa fa-search icon-nm mr-1'></i>Search</button>
                            </div>
                        </div>
                    </div>
                    {/* <div className='form-row'>
                        <div className='col-6'>
                            <button className='btn btn-sm btn-primary'><i className='fa fa-search icon-nm mr-1'>Search</i></button>
                        </div>
                    </div> */}
                </form>
            </div>
        </div>
    );
}

export default Index;