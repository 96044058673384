import moment from 'moment';
import React from 'react';
import { Modal } from 'react-bootstrap';
import usePHMO from './usePHMO';
import { StatusIcon } from '../../../components';


// competecy_std,
// dat,
// description,
// hm_off,
// hm_on,
// hm_total,
// id,
// shift,
// status,
// trainee_name,
// trainee_nik,
// training_unit
const ModalDetil = () => {
    const {
        PHMOAction: {
            closeModal = () => null,
            doApproval = () => null,
            openModalDecline = (item) => null,
        },
        PHMOState: {
            pageStatus: {
                paramState: {
                    trainee_name = "",
                    trainee_nik = "",
                }

            },
            paramHistory: { },
            modalStatus: {
                show = false,
                data = {
                }
            }
        }
    } = usePHMO()
    return (
        <Modal show={show} size="lg" >
            <Modal.Header closeButton onHide={closeModal}>
                <Modal.Title>Detail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    Object.keys(data).length >= 0 && [
                        // { name: "Trainee NIK", fields: "trainee_nik" },
                        { name: "Trainee NIK", fields: "trainee_name", onRender: () => trainee_nik },
                        { name: "Trainee Name", fields: "trainee_name", onRender: () => trainee_name },
                        { name: "Date", fields: "date", onRender: ({ fields }) => moment(data[fields]).format("DD/MM/YYY") },
                        { name: "Shift", fields: "shift" },
                        { name: "Unit Training", fields: "training_unit" },
                        { name: "Standard Competency", fields: "competecy_std" },
                        { name: "HM ON", fields: "hm_on" },
                        { name: "HM OFF", fields: "hm_off" },
                        { name: "HM Total", fields: "hm_total" },
                        {
                            name: "Status", fields: "status",
                            onRender: ({ fill }) => {
                                return (
                                    <StatusIcon status={fill}/>
                                    // <span style={{ textTransform: "capitalize" }} className={`
                                    //         badge badge-${fill === "declined" ? "danger" :
                                    //         fill === "approved" ? "success" :
                                    //             fill === "submitted" ? "primary" : ""
                                    //     }`}>
                                    //     {fill}
                                    // </span>
                                )
                            }
                        },
                        { name: "Description", fields: "description" },

                    ].map(({ name, fields, onRender = null }, i) => {
                        return (
                            <dl className='row' key={i} style={{ marginBottom: 0 }}>
                                <dt className="col">{name} <span className='float-right'>:</span> </dt>
                                <dd className="col">{
                                    onRender === null ?
                                        data[fields] :
                                        (typeof onRender === "function") ? onRender({
                                            name, fields, fill: data[fields]
                                        }) : "-"
                                }
                                </dd>
                            </dl>
                        )
                    })
                }
            </Modal.Body>
            <Modal.Footer>
                <div className='row'>
                    <div className='col'>
                        <button className='btn btn-sm btn-default float-right' onClick={closeModal}>
                            Close
                        </button>
                        {
                            (data.status === "submitted") && <>
                                <button
                                    onClick={() => openModalDecline(data)}
                                    // onClick={() => doApproval(data.id, "declined")}
                                    className='btn btn-sm btn-danger float-right mr-2'>
                                    Decline
                                </button>
                                <button onClick={() => doApproval(data.id, "approved")} className='btn btn-sm btn-success mr-2 float-right'>
                                    Approve
                                </button>

                            </>
                        }

                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalDetil;