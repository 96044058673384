import React from 'react';
import useIdpDwnld from './useIdpDownload';

const TableToHTML = () => {
    const { idpDwnldState } = useIdpDwnld();
    return (
        <table className="w-100 f-10" id="table-to-xls" style={{ display: "none" }}>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Request by NIK</th>
                    <th>Request by</th>
                    <th>Request Date</th>
                    <th>Jobsite Name</th>
                    <th>Grade</th>
                    <th>Cluster</th>
                    <th>Group Competency</th>
                    <th>Competency Name</th>
                    <th>Point</th>
                    <th>Course Name</th>
                    <th>Approval by</th>
                    <th>Approval Date</th>
                    <th>Status</th>
                    <th>Catatan</th>
                </tr>
            </thead>
            <tbody>
                {
                    (idpDwnldState.dataList || []).map(({
                        grade,
                        cluster,
                        competency_group,
                        competency_name,
                        point,
                        course_name,
                        jobsite_name,
                        request_by,
                        request_by_nik,
                        request_date,
                        approval_by,
                        approval_date,
                        note,
                        status,
                    }, i) => (
                        <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{request_by_nik}</td>
                            <td>{request_by}</td>
                            <td>{request_date}</td>
                            <td>{jobsite_name}</td>
                            <td>{grade}</td>
                            <td>{cluster}</td>
                            <td>{competency_group}</td>
                            <td>{competency_name}</td>
                            <td>{point}</td>
                            <td>{course_name}</td>
                            <td>{approval_by}</td>
                            <td>{approval_date}</td>
                            <td>{status}</td>
                            <td>{note}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    );
}

export default TableToHTML;