import React from 'react';
import useDashboard, { DashboardProvider } from './useDashboard';
import * as pages from './pages';
import './index.css';
import { Link } from 'react-router-dom';

function Index(props) {
    const { dashAction, dashState } = useDashboard();
    const PageRender = pages[dashState.pageState?.type];
    return (
        <div>
            <section className="content-header">
                <div className="container-fluid">
                    <div className='row'>
                        <div className='col-6'>
                            <h4>Dashboard</h4>
                            <h6>{
                                dashState.pageState?.title
                            }</h6>
                        </div>
                        <div className='col-6'>
                            {
                                dashState.role_id === 3 && (
                                    <>
                                        {
                                            dashState.pageState?.type === "Detail" ? (
                                                <nav aria-label="breadcrumb">
                                                    <ol className="breadcrumb justify-content-end">
                                                        <li className="breadcrumb-item" onClick={() => dashAction.toListPage()}><Link to={"#"}>Dashboard</Link></li>
                                                        <li className="breadcrumb-item active" aria-current="page">{dashState.pageState?.title}</li>
                                                    </ol>
                                                </nav>
                                            ) : (
                                                <nav aria-label="breadcrumb">
                                                    <ol className="breadcrumb justify-content-end">
                                                        <li className="breadcrumb-item active" aria-current="page">{dashState.pageState?.title}</li>
                                                    </ol>
                                                </nav>
                                            )
                                        }
                                    </>
                                )
                            }
                            {
                                dashState.role_id === 4 && (
                                    <>
                                        {
                                            dashState.pageState?.type === "Detail" ? (
                                                <nav aria-label="breadcrumb">
                                                    <ol className="breadcrumb justify-content-end">
                                                        <li className="breadcrumb-item" onClick={() => dashAction.toListJrPage()}><Link to={"#"}>Subordinate</Link></li>
                                                        <li className="breadcrumb-item" onClick={() => dashAction.toListPage({ nik: dashState.pageState.dataPage?.nik })}><Link to={"#"}>Dashboard</Link></li>
                                                        <li className="breadcrumb-item active" aria-current="page">{dashState.pageState?.title}</li>
                                                    </ol>
                                                </nav>
                                            ) : dashState.pageState?.type === "ComReport" ? (
                                                <nav aria-label="breadcrumb">
                                                    <ol className="breadcrumb justify-content-end">
                                                        <li className="breadcrumb-item" onClick={() => dashAction.toListJrPage({ nik: dashState.pageState.dataPage?.nik })}><Link to={"#"}>Subordinate</Link></li>
                                                        <li className="breadcrumb-item active" aria-current="page">{dashState.pageState?.title}</li>
                                                    </ol>
                                                </nav>
                                            ) : (
                                                <nav aria-label="breadcrumb">
                                                    <ol className="breadcrumb justify-content-end">
                                                        <li className="breadcrumb-item active" aria-current="page">{dashState.pageState?.title}</li>
                                                    </ol>
                                                </nav>
                                            )
                                        }
                                    </>
                                )
                            }
                            {
                                (dashState.role_id === 2 ||dashState.role_id === 5) && (
                                    <>
                                        {
                                            dashState.pageState?.type === "Detail" ? (
                                                <nav aria-label="breadcrumb">
                                                    <ol className="breadcrumb justify-content-end">
                                                        <li className="breadcrumb-item" onClick={() => dashAction.toListTraineePage()}><Link to={"#"}>List Trainee</Link></li>
                                                        <li className="breadcrumb-item" onClick={() => dashAction.toListPage({ nik: dashState.pageState.dataPage?.nik })}><Link to={"#"}>Dashboard</Link></li>
                                                        <li className="breadcrumb-item active" aria-current="page">{dashState.pageState?.title}</li>
                                                    </ol>
                                                </nav>
                                            ) : dashState.pageState?.type === "ComReport" ? (
                                                <nav aria-label="breadcrumb">
                                                    <ol className="breadcrumb justify-content-end">
                                                        <li className="breadcrumb-item" onClick={() => dashAction.toListTraineePage({ nik: dashState.pageState.dataPage?.nik })}><Link to={"#"}>List Trainee</Link></li>
                                                        <li className="breadcrumb-item active" aria-current="page">{dashState.pageState?.title}</li>
                                                    </ol>
                                                </nav>
                                            ) : (
                                                <nav aria-label="breadcrumb">
                                                    <ol className="breadcrumb justify-content-end">
                                                        <li className="breadcrumb-item active" aria-current="page">{dashState.pageState?.title}</li>
                                                    </ol>
                                                </nav>
                                            )
                                        }
                                    </>
                                )
                            }
                            {
                                dashState.role_id === 1 && (
                                    <>
                                        {
                                            dashState.pageState?.type === "Detail" ? (
                                                <nav aria-label="breadcrumb">
                                                    <ol className="breadcrumb justify-content-end">
                                                        <li className="breadcrumb-item" onClick={() => dashAction.toSearchForm()}><Link to={"#"}>Subordinate</Link></li>
                                                        <li className="breadcrumb-item" onClick={() => dashAction.toListPage({ nik: dashState.pageState.dataPage?.nik })}><Link to={"#"}>Dashboard</Link></li>
                                                        <li className="breadcrumb-item active" aria-current="page">{dashState.pageState?.title}</li>
                                                    </ol>
                                                </nav>
                                            ) : dashState.pageState?.type === "ComReport" ? (
                                                <nav aria-label="breadcrumb">
                                                    <ol className="breadcrumb justify-content-end">
                                                        <li className="breadcrumb-item" onClick={() => dashAction.toSearchForm({ nik: dashState.pageState.dataPage?.nik })}><Link to={"#"}>Subordinate</Link></li>
                                                        <li className="breadcrumb-item active" aria-current="page">{dashState.pageState?.title}</li>
                                                    </ol>
                                                </nav>
                                            ) : (
                                                <nav aria-label="breadcrumb">
                                                    <ol className="breadcrumb justify-content-end">
                                                        <li className="breadcrumb-item active" aria-current="page">{dashState.pageState?.title}</li>
                                                    </ol>
                                                </nav>
                                            )
                                        }
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
            </section>
            {
                dashState.pageState?.type && <PageRender />
            }

        </div>
    );
}

export default (props) => <DashboardProvider><Index {...props} /></DashboardProvider>