import moment from 'moment';
import React from 'react';
import { TableList } from '../../../components';
import usePHMO from './usePHMO';

export default () => {
    const { 
        PHMOState : {
            pageStatus : {
                isLoading = false,

            },
            listTraining = []
        },
        PHMOAction :{
            resetPage = () => null,
            toApprovalPage = () => null,
            toListTrainieePage= ()=>null
        }
    } = usePHMO()
    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <h4>Hours Meter (HM) Operator</h4>
                    <h6>Pencapaian HM Operator</h6>
                </div>
            </section>

            <div className='row'>
                <div className='col-md-12'>
                    <div className='card'>
                        <div className='card-header'>
                            <div className='card-title'>
                                <h5> List Training</h5>
                            </div>
                        </div>
                        <div className='card-body'>
                            
                            <div className='row mt-2'>
                                <div className='col-md-12'>

                                    <TableList
                                        isLoading={isLoading}
                                        minHeight='max-content'
                                        withCard={false}
                                        withActions={false}
                                        filter={false}
                                        data={listTraining}
                                        pagination={false}
                                        columns={[
                                            {
                                                name: "Competency Name",
                                                field: "competency_name"
                                            },
                                            {
                                                name: "Course Name",
                                                field: "course_name"
                                            },
                                            {
                                                name:"",
                                                className:"text-right",
                                                onRender: (item)=> <>
                                                    <button
                                                        className='btn btn-sm btn-primary'
                                                        onClick={()=> toListTrainieePage(item)}
                                                    > Detail </button>
                                                </>
                                            }
                                           
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}