import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useModalAlert } from "../../../../components/modalAlert";
import useFetch from "../../../../hooks/useFetch";

const lsIdpContext = createContext({
    lsIdpState: {},
    lsIdpAction: {}
});

export function LsIdpProvider({ children }) {
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const { doGet, doPost } = useFetch();
    const history = useHistory();
    const [dataState, setdataState] = useState(history.location.state);
    const [dataGet, setDataGet] = useState({});
    const [loadingGet, setLoadingGet] = useState(false);
    const [batch, setBatch] = useState(dataState?.batch);

    const [course_name, setCourse_name] = useState("");
    const [cluster, setCluster] = useState("");
    const [group_competency, setGroup_competency] = useState("");

    async function getList() {
        try {
            setLoadingGet(true);
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/ats/list-idp',
                    param: {
                        jobsite_code: dataState?.jobsite_code,
                        course_name,
                        cluster,
                        group_competency,
                        batch,
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDataGet(data);
                    setBatch(data?.batch);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get List IDP",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List IDP",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Jobsite",
                callBack: () => null
            })
        }
        setLoadingGet(false)
    }

    function onFilter(){
        getList();
    }
    useEffect(() => {
        if (!dataState?.jobsite_code) {
            return setDataAlert({
                show: true,
                body: "Buka halaman ini melalui menu ATS Create",
                header: "Jobsite tidak di pilih",
                callBack: () => null
            })
        }
        getList();
    }, [])
    return <lsIdpContext.Provider
        value={{
            lsIdpState: {
                dataGet,
                loadingGet,
                dataState,
                batch,
                course_name,
                cluster,
                group_competency,
            },
            lsIdpAction: {
                setBatch,
                onFilter,
                setCourse_name,
                setCluster,
                setGroup_competency
            }
        }}
    >{children}</lsIdpContext.Provider>
}
export default function useLsIdp() {
    return useContext(lsIdpContext);
}