export const menuItems = [
    {
        hasChild: false,
        name: "Dashboard",
        url: "/",
        icon: "fas fa-tachometer-alt",
        iconType: "css",
        badge: <></>,
        onClick: () => null
    },
    {
        hasChild: false,
        name: "Personal Info",
        url: "/personal-info",
        icon: "fas fa-user-circle",
        iconType: "css",
        badge: <></>,
        onClick: () => null
    },
    {
        hasChild: true,
        name: "IDP Online",
        url: "/idp-online",
        icon: "fa fa-edit",
        iconType: "css",
        childs: [
            {
                hasChild: false,
                name: "IDP Register",
                url: "/idp-online/register",
                // icon : "fas fa-tachometer-alt",
                iconType: false,
                badge: <></>,
                onClick: () => null
            },
            {
                hasChild: false,
                name: "IDP Status",
                url: "/idp-online/status",
                // icon : "fas fa-tachometer-alt",
                iconType: false,
                badge: <></>,
                onClick: () => null
            },
            {
                hasChild: false,
                name: "IDP Approval",
                url: "/idp-online/approval",
                // icon : "fas fa-tachometer-alt",
                iconType: false,
                badge: <></>,
                onClick: () => null
            },
        ]
    },
    {
        hasChild: true,
        name: "Development",
        url: "/development",
        icon: "fa fa-edit",
        iconType: "css",
        childs: [
            {
                hasChild: false,
                name: "Upload Job Assignment",
                url: "/development/upload-job-assigment",
                // icon : "fas fa-tachometer-alt",
                iconType: false,
                badge: <></>,
                onClick: () => null
            }
        ]
    },
    {
        hasChild: true,
        name: "Annual Training S.",
        url: "/ats",
        icon: "fas fa-calendar",
        iconType: "css",
        childs: [
            {
                hasChild: false,
                name: "ATS Create",
                url: "/ats/ats-create",
                // icon : "fas fa-tachometer-alt",
                iconType: false,
                badge: <></>,
                onClick: () => null
            },
            {
                hasChild: false,
                name: "ATS Status",
                url: "/ats/ats-status",
                // icon : "fas fa-tachometer-alt",
                iconType: false,
                badge: <></>,
                onClick: () => null
            }
        ]
    },
    {
        hasChild: false,
        name: "Data Result Train.",
        url: "/data-result-training/list-training",
        icon: "fas fa-book",
        iconType: "css",
        badge: <></>,
        onClick: () => null
    },
    {
        hasChild: true,
        name: "Master Data",
        url: "/master-data",
        icon: "fa fa-database",
        iconType: "css",
        childs: [
            {
                hasChild: false,
                name: "IDP Register",
                url: "/master-data/idp-register",
                // icon : "fas fa-tachometer-alt",
                iconType: false,
                badge: <></>,
                onClick: () => null
            }
        ]
    },
]
