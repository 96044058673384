import React from 'react';
import { TableList, UploadFile } from '../../components';
import useUploadAR, { ARProvider } from './useUploadAR';

function ARPage() {
    const {
        ARActions : {
            setFormData = () => null,
            doUpload = () => null,
            nextPage= () => null,
            prevPage= () => null,
            changeShow= () => null,
            onOrdering= () => null,
            doFilter= () => null,
            setFilter= () => null,
        },
        ARState : {
            formAction,
            dataList=[],
            loadingList=true,
            detilData: {
                otalData=0, totalPage=0
            },
            filter: {
                filter_nik ="",
                filter_name="",
            },

            refInputFile,
            pagination : {
                page=1,
                show=10,
                order_by="",
                order_type="",
            }
        }
     } = useUploadAR()

    return <div>
        <section className="content-header">
            <div className="container-fluid">
                <div className='row'>
                    <div className='col-6'>
                        <h4>Upload AR </h4>
                    </div>
                    <div className='col-6'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-end">
                                <li className="breadcrumb-item active" aria-current="page">Upload AR</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>

        <div className='card'>
            <div className='card-header'>
                <div className='card-title'>
                    Upload
                </div>
            </div>
            <div className='card-body'>
                <div className='form-group'>
                    <label>Excel File</label>
                    <input
                        ref={refInputFile}
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={(e) => setFormData(p => ({
                            ...p,
                            file: e.target.files[0]
                        }))}
                        //value={jobState.formUpload.file?.name}
                        className='form-control' type='file' />
                </div>
            </div>
            <div className='card-footer'>
                <button className='btn btn-sm btn-primary' onClick={doUpload}>Submit</button>
            </div>
        </div>


        <div className='card'>
            <div className='card-header'>
                <div className='card-title'>
                    Users List
                </div>
            </div>
            <div className='card-body'>
                <div className='row'>
                    <div className='col-md-12'>
                        <TableList
                            withCard={false}
                            isLoading={loadingList}
                            data={dataList}
                            onOrdering={onOrdering}
                            curPage={page}
                            maxPage={totalPage}
                            defaultShow={show}
                            onChangeShow={changeShow}
                            onNextPage={nextPage}
                            onPrevPage={prevPage}
                            filter={true}
                            resetFilter={false}
                            formsFilter={[
                                {
                                    type:"text",
                                    label:"Name",
                                    placeholder: "Name",
                                    value:filter_name,
                                    onChange:(val) => setFilter( x=> ({...x, filter_name : val}))
                                },
                                {
                                    type:"text",
                                    label:"NIK",
                                    placeholder: "NIK",
                                    value:filter_nik,
                                    onChange:(val) => setFilter( x=> ({...x, filter_nik : val}))

                                }
                            ]}
                            onFilter={doFilter}
                            columns={[
                                {
                                    name: '#',
                                    field: '#',
                                    maxWidth: '50px',
                                },
                                {
                                    name: 'NIK',
                                    field: 'nik',
                                    ordering: true,
                                    // maxWidth: '250px',
                                },
                                {
                                    name: 'Nama',
                                    field: 'name',
                                    ordering: true,
                                    // maxWidth: '150px',
                                },
                                {
                                    name: 'Jobsite',
                                    field: 'pers_area_name',
                                    ordering: true,
                                    // maxWidth: '150px',
                                },
                                {
                                    name: 'Attended Ratio',
                                    field: 'attended_ratio',
                                    ordering: true,
                                    // maxWidth: '250px',
                                    onRender: (item) => {
                                        return (
                                            <div>
                                                {
                                                    (item.attended_ratio * 100).toFixed(2)
                                                } %
                                            </div>
                                        )
                                    }
                                },



                            ]}

                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
}


export default () => <ARProvider> <ARPage /> </ARProvider>
