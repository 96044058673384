import React from 'react';
import { StatusIcon, TableList } from '../../../../components';
import useTirApproval from '../useTirApproval';

function Index(props) {
    const { tirApvAction, tirApvState } = useTirApproval();
    return (
        <div className='card'>
            <div className='card-body'>
                <div className='row mb-3'>
                    <div className='col-md-12 col-sm-12'>
                        <TableList
                            isLoading={tirApvState.loadingTraining}
                            data={tirApvState.listTraining || []}
                            withCard={false}
                            columns={[
                                {
                                    name: '#',
                                    field: '#',
                                },
                                {
                                    name: 'Competency',
                                    field: 'competency_name',
                                },
                                {
                                    name: 'Course',
                                    field: 'course_name',
                                },
                                {
                                    name: 'Form',
                                    field: '',
                                    minWidth: 300,
                                    onRender: (item) => (
                                        <div>
                                            <ul>
                                                {
                                                    [...(item?.listForm || [])].map((d, i) => (
                                                        <>
                                                            <li className='text-primary' role={'button'}
                                                                onClick={() => tirApvAction.toFormPage({ ...d, trainee_nik: tirApvState.pageState?.dataPage?.trainee_nik }, true)}
                                                            >{d?.title}
                                                                <StatusIcon status={d?.status} />
                                                                {/* <span className={
                                                                    `text-capitalize badge badge-pill badge-${d?.status === 'approved' ? 'success' :
                                                                        d?.status === 'declined' ? 'danger' :
                                                                            'primary'
                                                                    }`
                                                                }>
                                                                    {d?.status}
                                                                </span> */}
                                                            </li>
                                                            {/* {
                                                                (d?.status === 'approved' || d?.status === 'declined') ? (
                                                                    <li className='text-mutted'
                                                                    // onClick={() => tirApvAction.toFormPage({ ...d, trainee_nik: tirApvState.pageState?.dataPage?.trainee_nik }, true)}
                                                                    >Form {i + 1} <span className={
                                                                        `badge badge-pill badge-${d?.status === 'approved' ? 'success' :
                                                                            d?.status === 'declined' ? 'danger' :
                                                                                'primary'
                                                                        }`
                                                                    }>
                                                                            {d?.status}
                                                                        </span></li>
                                                                ) : (
                                                                    <li className='text-primary' role={'button'}
                                                                        onClick={() => tirApvAction.toFormPage({ ...d, trainee_nik: tirApvState.pageState?.dataPage?.trainee_nik }, true)}
                                                                    >Form {i + 1}</li>
                                                                )
                                                            } */}
                                                        </>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    )
                                },
                                {
                                    name: 'Link Video',
                                    field: '',
                                    minWidth: 300,
                                    onRender: (item) => (
                                        <div>
                                            <ul>
                                                {
                                                    [...(item?.listVideoUrl || [])].map((d, i) => (
                                                        <>
                                                            <li key={i} className='text-primary' role={'button'}
                                                                onClick={() => tirApvAction.setModalTautan({
                                                                    show: true,
                                                                    modeEdit: true,
                                                                    data: { ...d, training_id: item?.training_id, trainee_nik: tirApvState.pageState?.dataPage?.trainee_nik }
                                                                })}
                                                            >{d?.title}
                                                                <StatusIcon status={d?.status} />
                                                                {/* <span className={
                                                                    `text-capitalize badge badge-pill badge-${d?.status === 'approved' ? 'success' :
                                                                        d?.status === 'declined' ? 'danger' :
                                                                            'primary'
                                                                    }`
                                                                }>
                                                                    {d?.status}
                                                                </span> */}
                                                            </li>
                                                            {
                                                                // (d?.status === 'approved' || d?.status === 'declined') ? (
                                                                //     <li key={i} className='text-mutted'
                                                                //         role={'button'} >Link {i + 1} <span className={
                                                                //             `badge badge-pill badge-${d?.status === 'approved' ? 'success' :
                                                                //                 d?.status === 'declined' ? 'danger' :
                                                                //                     'primary'
                                                                //             }`
                                                                //         }>
                                                                //             {d?.status}
                                                                //         </span></li>
                                                                // ) : (
                                                                //     <li key={i} className='text-primary' role={'button'} onClick={() => tirApvAction.setModalTautan({
                                                                //         show: true,
                                                                //         modeEdit: true,
                                                                //         data: { ...d, training_id: item?.training_id, trainee_nik: tirApvState.pageState?.dataPage?.trainee_nik }
                                                                //     })}>Link {i + 1}</li>
                                                                // )
                                                            }
                                                        </>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    )
                                },
                                // {
                                //     name: '',
                                //     field: '',
                                //     minWidth: 200,
                                //     onRender: (item) => (
                                //         <div>
                                //             <button className='btn btn-sm btn-success mr-1 mb-1'>Approve</button>
                                //             <button className='btn btn-sm btn-danger mr-1 mb-1'>Decline</button>
                                //         </div>
                                //     )
                                // },
                            ]}
                            withActions={false}
                            pagination={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;