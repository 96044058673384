import useResPass, { RPProvider } from "./useResPass";

function RessPassPage() {
   const {
      RPAction : {
         setFormData = ()=>null,
         doSubmit = ()=>null,
      },
      RPState : {
         formData : {
            nik,
            password,
            password_confirmation
         }
      }
   } = useResPass()
    return (
        <>
        <section className="content-header">
            <div className="container-fluid">
                <div className='row'>
                    <div className='col-6'>
                        <h4>Reset Password </h4>
                    </div>
                    <div className='col-6'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-end">
                                <li className="breadcrumb-item active" aria-current="page">Reset Password</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
            <div className="card mx-auto" style={{width:"75%"}}>
               <div className="card-header">
                  <div className="card-title">
                     Form Reset Password
                  </div>
               </div>
                <div className="card-body">
                    <div className="form-group">
                     <label> NIK </label>
                     <input autoComplete="new-password" value={nik} onChange={(e) => setFormData( v => ( { ... v,nik: e.target.value }))}  placeholder="nik" className="form-control form-control-sm" />
                    </div>
                    
                    <div className="form-group">
                     <label> Password </label>
                     <input autoComplete="new-password" value={password} onChange={(e) => setFormData( v => ( { ... v,password: e.target.value }))}  type="password" placeholder="password" className="form-control form-control-sm" />
                    </div>

                    
                    <div className="form-group">
                     <label> Re-Type Password </label>
                     <input autoComplete="new-password" value={password_confirmation} onChange={(e) => setFormData( v => ( { ... v,password_confirmation: e.target.value }))} type="password" placeholder="re-type password" className="form-control form-control-sm" />
                     { password !== "" && password !== password_confirmation && <span className="text-danger" style={{fontSize:"9pt"}}>Password not match</span>}
                    </div>
                </div>
                <div className="card-footer">
                  <div className="row">
                     <div className="col">
                        <button disabled={password === "" || password !== password_confirmation} onClick={doSubmit} className="btn btn-sm btn-success float-right ml-2">
                           Submit
                        </button>
                        
                     </div>
                  </div>
                </div>
            </div>
        </>
    );
}

export default (props) => (
    <RPProvider>
        <RessPassPage {...props} />
    </RPProvider>
);
