import React from 'react';
import useDwnldDataEmployee from './useDwnldDataEmployee';

const TableToHTML = () => {
    const { dwnldDtEmployeeState } = useDwnldDataEmployee();

    return (
        <table className="w-100 f-10" id="table-to-xls" style={{ display: "none" }}>
            <thead>
                <tr>
                    <th>#</th>
                    <th>NIK</th>
                    <th>Nama</th>
                    <th>Tanggal Bergabung</th>
                    <th>Point of Hire</th>
                    <th>Jobsite</th>
                    <th>Function</th>
                    <th>Jabatan</th>
                    <th>Level</th>
                    <th>Grade</th>
                    <th>NIK Atasan Langsung</th>
                    <th>Nama Atasan Langsung</th>
                    <th>NIK Atasan Tidak Langsung</th>
                    <th>Nama Atasan Tidak Langsung</th>
                </tr>
            </thead>
            <tbody>
                {
                    (dwnldDtEmployeeState.dataList || []).map(({
                        nik,
                        name,
                        join_date,
                        poh,
                        pers_area_name,
                        function_,
                        job_title,
                        level,
                        grade,
                        nik_direct_supervisor,
                        direct_supervisor_name,
                        nik_indirect_supervisor,
                        indirect_supervisor_name
                    }, i) => (
                        <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{nik}</td>
                            <td>{name}</td>
                            <td>{join_date}</td>
                            <td>{poh}</td>
                            <td>{pers_area_name}</td>
                            <td>{function_}</td>
                            <td>{job_title}</td>
                            <td>{level}</td>
                            <td>{grade}</td>
                            <td>{nik_direct_supervisor}</td>
                            <td>{direct_supervisor_name}</td>
                            <td>{nik_indirect_supervisor}</td>
                            <td>{indirect_supervisor_name}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    );
}

export default TableToHTML;
