import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import useTirOnline from '../useTirOnline';

function ModalTautan(props) {
    const { tirAction, tirState } = useTirOnline();    
    return (
        <div>
            <Modal
                dialogClassName='modal-editor-trainee'
                backdropClassName='bg-transparent'
                show={tirState.modalTautan?.show}
                onHide={() => tirAction.resetModalTautan()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {tirState.modalTautan?.modeEdit ? (tirState.formDataTautan?.status === 'submitted' ? 'Update Link Video' : 'Link Video') : 'Add Link Video'}
                    </Modal.Title>
                </Modal.Header>
                <form
                    onSubmit={(e) => {
                        e.preventDefault(e);
                        tirAction.customValidation({
                            "title": tirState.formDataTautan?.title,
                            "tir_id": tirState.formDataTautan?.tir_id,
                            "video_url": tirState.formDataTautan?.url,
                            "training_id": tirState.formDataTautan?.training_id
                        });
                        // tirAction.confirmAddUpdate({
                        //     "title": tirState.formDataTautan?.title,
                        //     "tir_id": tirState.formDataTautan?.tir_id,
                        //     "video_url": tirState.formDataTautan?.url,
                        //     "training_id": tirState.formDataTautan?.training_id
                        // });
                    }}
                >

                    <Modal.Body>
                        {
                            (tirState.formDataTautan?.status === "declined") &&
                            <div className="form-group row text-danger rounded mx-1" style={{ backgroundColor: '#ffe2e5' }}>
                                <label className="col-sm-2 col-form-label">Note : </label>
                                <label className="col-sm-10 col-form-label">{tirState.formDataTautan?.note}</label>
                            </div>
                        }
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Judul Link</label>
                            <div className="col-sm-9">
                                {
                                    tirState.formDataTautan?.status === "approved" ?
                                        <span className='text-wrap'>{tirState.formDataTautan?.title}</span> :
                                        <>
                                            <input
                                                value={tirState.formDataTautan?.title}
                                                onChange={(e) => tirAction.setFormDataTautan((prev) => ({ ...prev, title: e.target.value }))}
                                                // required
                                                disabled={tirState.modalTautan?.modeEdit ? (tirState.formDataTautan?.status === "submitted" || tirState.formDataTautan?.status === "") ? false : true : false}
                                                placeholder='Judul link lengkap'
                                                className="form-control"
                                            />
                                            {tirState.val.valid === false && (
                                                <small className='text-danger'>{tirState.val.title}</small>
                                            )}
                                        </>
                                }

                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">URL</label>
                            <div className="col-sm-9">
                                {
                                    tirState.formDataTautan?.status === "approved" ?
                                        <span className='text-wrap'>{tirState.formDataTautan?.url}</span> : <>
                                            <input
                                                value={tirState.formDataTautan?.url}
                                                onChange={(e) => tirAction.setFormDataTautan((prev) => ({ ...prev, url: e.target.value }))}
                                                // required
                                                disabled={tirState.modalTautan?.modeEdit ? (tirState.formDataTautan?.status === "submitted" || tirState.formDataTautan?.status === "") ? false : true : false}
                                                type="text"
                                                // placeholder='example: https://frontlinebuma.afledu.com/'
                                                className="form-control"
                                                aria-describedby="smal"
                                            />
                                            {tirState.val.valid === false && (
                                                <small className='text-danger'>{tirState.val.url}</small>
                                            )}
                                            <small id="smal" className="form-text text-muted">
                                                example: https://www.youtube.com/
                                            </small>
                                        </>
                                }

                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            tirState.modalTautan?.modeEdit &&
                            <button
                                type='button'
                                onClick={() => tirAction.resetModalTautan()}
                                className='btn btn-sm btn-secondary mr-1'>Close</button>
                        }
                        {
                            (tirState.formDataTautan?.status !== "approved" && tirState.modalTautan?.modeEdit === true) &&
                            <button type='button' className='btn btn-sm btn-danger mr-1'
                                onClick={() => tirAction.confirmDelete(tirState.formDataTautan)}
                            >Delete</button>
                        }
                        {
                            tirState.modalTautan?.modeEdit ? (
                                <>
                                    <a href={tirState.formDataTautan?.url} target="_blank">
                                        <button type='button' className='btn btn-sm btn-primary mr-1'>Go to URL</button>
                                    </a>
                                    {
                                        (tirState.formDataTautan?.status === "submitted" || tirState.formDataTautan?.status === "") &&
                                        <button type='submit' className='btn btn-sm btn-success mr-1'>Save</button>
                                    }
                                </>
                            ) : (
                                <>
                                    <button
                                        type='button'
                                        onClick={() => tirAction.resetModalTautan()}
                                        className='btn btn-sm btn-secondary mr-1'>Cancel</button>
                                    <button type='submit' className='btn btn-sm btn-success mr-1'>Save</button>
                                </>
                            )
                        }
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    );
}

export default ModalTautan;