import React from 'react';

function IdpDwnldDetail({ data = {} }) {
    return (
        <div className='row'>
            <div className='col'>
                <form>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className="form-group row">
                                <label className="col-md-6 col-form-label">Cluster<span className='float-md-right'>:</span></label>
                                <div className="col-md-6">
                                    <span type="text" readonly className="form-control-plaintext">{data?.cluster}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-md-6 col-form-label">Grade<span className='float-md-right'>:</span></label>
                                <div className="col-md-6">
                                    <span type="text" readonly className="form-control-plaintext">{data?.grade}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-md-6 col-form-label">Competency Group<span className='float-md-right'>:</span></label>
                                <div className="col-md-6">
                                    <span type="text" readonly className="form-control-plaintext">{data?.competency_group}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-md-6 col-form-label">Competency Name<span className='float-md-right'>:</span></label>
                                <div className="col-md-6">
                                    <span type="text" readonly className="form-control-plaintext">{data?.competency_name}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-md-6 col-form-label">Course Name<span className='float-md-right'>:</span></label>
                                <div className="col-md-6">
                                    <span type="text" readonly className="form-control-plaintext">{data?.course_name}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-md-6 col-form-label">Jobsite Name<span className='float-md-right'>:</span></label>
                                <div className="col-md-6">
                                    <span type="text" readonly className="form-control-plaintext">{data?.jobsite_name}</span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form-group row">
                                <label className="col-md-6 col-form-label">Request By<span className='float-md-right'>:</span></label>
                                <div className="col-md-6">
                                    <span type="text" readonly className="form-control-plaintext">{data?.request_by}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-md-6 col-form-label">Request Date<span className='float-md-right'>:</span></label>
                                <div className="col-md-6">
                                    <span type="text" readonly className="form-control-plaintext">{data?.request_date}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-md-6 col-form-label">Approval By<span className='float-md-right'>:</span></label>
                                <div className="col-md-6">
                                    <span type="text" readonly className="form-control-plaintext">{data?.approval_by}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-md-6 col-form-label">Approval Date<span className='float-md-right'>:</span></label>
                                <div className="col-md-6">
                                    <span type="text" readonly className="form-control-plaintext">{data?.approval_date}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-md-6 col-form-label">Point<span className='float-md-right'>:</span></label>
                                <div className="col-md-6">
                                    <span type="text" readonly className="form-control-plaintext">{data?.point}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default IdpDwnldDetail;