import React from 'react';
import { TableList } from '../../../components';
import useInputHmOperator from './useInputHmOperator';


export default () => {
    const {
        HMOActions,
        HMOState : {
            listTraining = [],
            pageStatus: {
                isLoading = true
            }
        }
     } = useInputHmOperator()
    return (<>


        <section className="content-header">
            <div className="container-fluid">
                <h4>Hours Meter (HM) Operator</h4>
                <h6>Input HM Operator</h6>
            </div>
        </section>
        <div className='card '>
            <div className='card-header'>
                <div className='card-title'>
                    <h5>
                        List Training

                    </h5>
                </div>
            </div>
            <div className='card-body'>
                <div className='row mb-3'>
                    <div className='col-md-12 col-sm-12'>
                        <TableList
                            isLoading={isLoading}
                            data={listTraining || []}
                            withCard={false}
                            columns={[
                                {
                                    name: '#',
                                    field: '#',
                                },
                                {
                                    name: 'Competency',
                                    field: 'competency_name',
                                },
                                {
                                    name: 'Course',
                                    field: 'course_name',
                                },
                                {
                                    name: 'Total HM',
                                    field: 'total_hm',
                                },
                                {
                                    name: 'Aksi',
                                    field: '',
                                    className: 'text-right',
                                    onRender: (item)=> <button onClick={()=> HMOActions.toInputPage(item)} className='btn bg-base text-white btn-sm float-right'>
                                        Lihat / Input HM
                                    </button>
                                },
                            ]}
                            withActions={false}
                            pagination={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    </>);
}
