import React from 'react';
import './index.css';
import moment from 'moment';
import { TableList } from '../../../../components';
import useResultA from '../useResultA';

function Index(props) {
    const { raAction, raState } = useResultA();
    return (
        <div>
            <div className="card">
                <div className="card-body p-3">
                    <div className='row'>
                        <div className='col-md-6 mb-2'>
                            <div className="form-group row mb-0">
                                <label className="col-sm-3 col-form-label">Jobsite<span className='float-right'>:</span></label>
                                <div className="col-sm-9">
                                    <span type="text" readonly className="form-control-plaintext">{raState.pageState.dataPage?.jobsite || ''}</span>
                                </div>
                            </div>
                            <div className="form-group row mb-0">
                                <label className="col-sm-3 col-form-label">Cluster<span className='float-right'>:</span></label>
                                <div className="col-sm-9">
                                    <span type="text" readonly className="form-control-plaintext">{raState.pageState.dataPage?.cluster || ''}</span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 mb-2'>
                            <div className="form-group row mb-0">
                                <label className="col-sm-4 col-form-label">Competency Name<span className='float-right'>:</span></label>
                                <div className="col-sm-8">
                                    <span type="text" readonly className="form-control-plaintext">{raState.pageState.dataPage?.competency_name || ''}</span>
                                </div>
                            </div>
                            <div className="form-group row mb-0">
                                <label className="col-sm-4 col-form-label">Course Name<span className='float-right'>:</span></label>
                                <div className="col-sm-8">
                                    <span type="text" readonly className="form-control-plaintext">{raState.pageState.dataPage?.course_name || ''}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <TableList
                        withCard={false}
                        curPage={1}
                        pagination={true}
                        minHeight="300px"
                        maxHeight="500px"
                        navtable={"TABLE 1"}
                        isLoading={raState.loadingDetail}
                        // onChangeShow={rekAction.changeShow}
                        // onNextPage={rekAction.nextPage}
                        // onPrevPage={rekAction.prevPage}
                        withActions={true}
                        actionType={1}
                        actionItems={[
                            {
                                name: 'Update Nilai',
                                onClick: function (item) {
                                    raAction.openModalEditor(item)
                                }
                            },
                        ]}
                        columns={[
                            {
                                name: '#',
                                field: '#',
                                maxWidth: '50px',
                            },
                            {
                                name: "Nama",
                                field: 'trainee_name',
                                minWidth: '100px',
                            },
                            {
                                name: "NIK",
                                field: 'trainee_nik',
                                minWidth: '100px',
                                ordering: true,
                            },
                            // {
                            //     name: "Pre Test",
                            //     field: 'pre_test_score',
                            //     minWidth: '100px',
                            //     onRender: (item) => {
                            //         return (
                            //             <span className='text-wrap'>{`${item?.pre_test_score || 'Belum di isi'}`}</span>
                            //         )
                            //     }
                            // },
                            {
                                name: "Result",
                                field: 'score',
                                minWidth: '100px',
                                onRender: (item) => {
                                    return (
                                        <span className={
                                            `text-capitalize badge badge-pill badge-${item?.score === 'competent' ? 'success' :
                                                item?.score === 'not_competent' ? 'danger' :
                                                    'secondary'
                                            }`
                                        }>
                                            {item?.score === "not_competent" ? "not competent" : item?.score}
                                        </span>
                                    )
                                }
                            },
                            // {
                            //     name: '% Kehadiran',
                            //     field: 'day_present',
                            //     maxWidth: '150px',
                            //     onRender: (item) => (
                            //         <span>{(item?.day_present * 100 / item?.duration) || 0} %</span>
                            //     )
                            // },
                            {
                                name: "Start Date",
                                field: 'start_date',
                                minWidth: '100px', onRender: (item) => (
                                    <span className='text-wrap'>{moment(item?.start_date).format('DD/MM/YYYY')}</span>
                                )
                            },
                            {
                                name: "End Date",
                                field: 'end_date',
                                minWidth: '100px',
                                onRender: (item) => (
                                    <span className='text-wrap'>{moment(item?.end_date).format('DD/MM/YYYY')}</span>
                                )
                            },
                        ]}
                        data={[...(raState.dataDetail || [])]}
                    />
                    <button className='btn btn-sm btn-secondary'
                        onClick={() => raAction.toTrainingPage()}
                    >Back</button>
                </div>
            </div>
        </div>
    );
}

export default Index;
