import React from 'react';
import { Link } from 'react-router-dom';
import * as pages from './pages';
import useTirApproval, { TirApvProvider } from './useTirApproval';

function Index(props) {
    const { tirApvAction, tirApvState } = useTirApproval();
    const PageRender = pages[tirApvState.pageState.type];

    function loadBreadcrumbs() {

        if (tirApvState.pageState.type === "TableList")
            return (
                (
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end">
                            <li className="breadcrumb-item" onClick={() => tirApvAction.toListJr()}><Link to={"#"}>Subordinate</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">{tirApvState.pageState.title}</li>
                        </ol>
                    </nav>
                )
            )
        if (tirApvState.pageState.type === "Form")
            return (
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb justify-content-end">
                        <li className="breadcrumb-item" onClick={() => tirApvAction.toListJr()}><Link to={"#"}>Subordinate</Link></li>
                        <li className="breadcrumb-item" onClick={() => tirApvAction.toListPage(tirApvState.pageState?.dataPage?.detail?.trainee_nik)}><Link to={"#"}>List Assignment</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">{tirApvState.pageState.title}</li>
                    </ol>
                </nav>
            )
        else
            return (
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb justify-content-end">
                        <li className="breadcrumb-item active" aria-current="page">{tirApvState.pageState.title}</li>
                    </ol>
                </nav>
            )
    }

    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <div className='row'>
                        <div className='col-md-6'>
                            <h4>Approval Technical Information Report (TIR) Online</h4>
                            <h6>{
                                tirApvState.pageState.title
                            }</h6>
                        </div>
                        <div className='col-md-6'>
                            {loadBreadcrumbs()}
                        </div>
                    </div>
                </div>
            </section>
            <PageRender />
        </>
    );
}

export default (props) => <TirApvProvider><Index {...props} /></TirApvProvider>;