import React from 'react';
import { TableList, UploadFile } from '../../../components';
import useJobAssigment, { JobProvider } from './useJobAssigment';

import { AiOutlineCloudUpload } from 'react-icons/ai'
function ListTrainingPage() {
    const { jobAction, jobState } = useJobAssigment()

    return (<>
        <div className='card '>
            <div className='card-header'>
                <div className='card-title'>
                    <h5>
                        List Training

                    </h5>
                </div>
            </div>
            <div className='card-body'>
                <div className='row mb-3'>
                    <div className='col-md-12 col-sm-12'>
                        <TableList
                            isLoading={jobState.loadingTraining}
                            data={jobState.listTraining || []}
                            withCard={false}
                            columns={[
                                {
                                    name: '#',
                                    field: '#',
                                },
                                {
                                    name: 'Competency',
                                    field: 'competency_name',
                                },
                                {
                                    name: 'Course',
                                    field: 'course_name',
                                },
                                {
                                    name: 'Aksi',
                                    field: '',
                                    className: 'text-right',
                                    onRender: (item)=> <button onClick={()=> jobAction.toUploadPage(item)} className='btn bg-base text-white btn-sm float-right'>
                                        Upload Dokumen
                                    </button>
                                },
                            ]}
                            withActions={false}
                            pagination={false}
                        />
                        {/* <div className='form-group'>
                            <label> Jenis Job Assignment </label>
                            <select value={jobState.tipeUpload} onChange={(e) => jobAction.setTipeUpload(parseInt(e.target.value))} className='form-control'>
                                <option value={0} >Upload File</option>
                                <option value={1} >Video</option>
                            </select>
                        </div> */}
                    </div>
                </div>
                {/* {
                    jobState.tipeUpload === 0 ? (
                        <div className='row'>
                            <div className='col-md-12 col-12 col-lg-4 col-sm-12 col-xs-12'>
                                <UploadFile />
                                <label>File</label>
                                <input type='file' className='form-control' />
                            </div>
                        </div>
                    ) : (
                        <div className='row'>
                            <div className='col-md-12 col-4 col-lg-4 col-sm-12'>
                                <div className='form-group'>
                                    <label>Link Video</label>
                                    <input className='form-control' />
                                </div>
                            </div>
                        </div>
                    )
                } */}
            </div>
            {/* <div className='card-footer'>
                <button className='btn btn-primary float-right'>
                    Submit
                </button>
            </div> */}
        </div>
    </>)
}

export default ListTrainingPage