import React from 'react';
import useDwnldDataJa, { DwnldDataJaProvider } from './useDwnldDataJa';
import { StatusIcon, TableList } from '../../../components';
import { BiFile } from 'react-icons/bi';
import TableToHTML from './export';
import { htmlToExcel } from '../../../modules';
import moment from 'moment';

function Index(props) {
    const { dwnldDtJaAction, dwnldDtJaState } = useDwnldDataJa();
    return (
        <div>
            <div className='row'>
                <div className='col-6'>
                    <section className="content-header">
                        <div className="container-fluid">
                            <h4>Download Data</h4>
                            <h6>Job Assignment</h6>
                        </div>
                    </section>
                </div>
                <div className='col-6'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end">
                            <li className="breadcrumb-item active" aria-current="page">
                                Job Assignment
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <TableList
                withButtonSubmit={false}
                filter={true}
                formsFilter={[
                    {
                        type: 'custom',
                        placeholder: 'Nama',
                        component: (
                            <>
                                <div className="form-group row mb-1">
                                    <label htmlFor="inputPassword3" className="col-sm-3 font-weight-normal">Batch</label>
                                    <div className="col-sm-9">
                                        <input type='number' className='form-control form-control-sm'
                                            placeholder='Batch'
                                            value={dwnldDtJaState?.batch}
                                            onChange={(val) => dwnldDtJaAction.setBatch(val.target.value)}
                                        >
                                        </input>
                                    </div>
                                </div>
                                <div className="form-group row mb-1">
                                    <label htmlFor="inputEmail3" className="col-sm-3 font-weight-normal">Jobsite</label>
                                    <div className="col-sm-9">
                                        <select className='form-select'
                                            value={dwnldDtJaState?.jobsite_code}
                                            onChange={(val) => dwnldDtJaAction.setJobsite_code(val.target.value)}
                                        >
                                            {
                                                [...(dwnldDtJaState.dataJobsite || [])].map((d, i) => (
                                                    <option value={d?.jobsite_code} key={i}>{d?.jobsite_name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row mb-1">
                                    <div className='col-sm-3'></div>
                                    <div className='col-sm-9'>
                                        <button className='btn btn-sm btn-primary'
                                            onClick={() => dwnldDtJaAction.onFilter()}
                                        ><i className='fa fa-search'></i> Search</button>
                                    </div>
                                </div>
                            </>
                        )
                    }
                ]}
                customToolbar={<div className='d-flex justify-content-end h-100 mb-3'>
                    <button className='btn btn-sm btn-success mt-auto'
                        style={{ height: 'fit-content' }}
                        onClick={() => htmlToExcel("table-to-xls", `${dwnldDtJaState?.batch}_Job Assignment_${moment(Date.now()).format("YYYY-MM-DD HH_mm_SS")}`, "statement")}
                    >
                        <BiFile style={{ fonstSize: '12pt' }} /> Export Excel
                    </button>
                </div>}
                labelFilter=""
                submitButton={<><i className='fa fa-search'></i> Search</>}
                resetFilter={false}
                onFilter={() => dwnldDtJaAction.onFilter()}
                isLoading={dwnldDtJaState.loadingGet}
                curPage={1}
                minHeight="600px"
                maxHeight="600px"
                navtable={"TABLE 1"}
                pagination={false}
                withActions={false}
                actionType={1}
                // actionItems={[
                //     {
                //         name: 'Lihat detail',
                //         onClick: function (item) {
                //             dwnldDtJaAction.setModalDetail({
                //                 show: true,
                //                 data: { ...item }
                //             });
                //         }
                //     }
                // ]}
                columns={[
                    {
                        name: '#',
                        field: '#',
                        maxWidth: '50px',
                    },
                    {
                        name: "NIK",
                        field: 'nik'
                    },
                    {
                        name: "Nama Karyawan",
                        field: 'name'
                    },
                    {
                        name: "Competency Name",
                        field: 'competency_name'
                    },
                    {
                        name: "Course Name",
                        field: 'course_name'
                    },
                    {
                        name: "Judul Dokumen",
                        field: 'ja_title'
                    },
                    {
                        name: 'Status',
                        field: 'ja_status',
                        onRender: ({ ja_status }) => {
                            return <StatusIcon status={ja_status} />
                        }
                    }
                ]}
                data={dwnldDtJaState.dataList || []}
            />
            <TableToHTML />
        </div>
    );
}

export default () => <DwnldDataJaProvider><Index /></DwnldDataJaProvider>;