import React from 'react';
import useMdEmp, { MdEmpProvider } from './useMasterDemEmp';
import { TableList } from '../../../components';
import { Link, useHistory } from 'react-router-dom';

function Index(props) {
    const { mdEmpAction, mdEmpState } = useMdEmp();
    const history = useHistory();
    const CustomToolbar = () => (
        <div className='tw-flex tw-justify-end'>
            <Link to={{
                pathname: "/master-data/demografi-employee/add",
                state: { filterState: mdEmpState.filter }
            }}><button className='btn btn-sm btn-primary'>+ Add</button></Link>
            <Link to={{
                pathname: "/master-data/demografi-employee/bulk-upload",
                state: { filterState: mdEmpState.filter }
            }}><button className='btn btn-sm btn-success ml-1'>Bulk Upload</button></Link>
            <Link to={{
                pathname: "/master-data/demografi-employee/update-atasan",
                state: { filterState: mdEmpState.filter }
            }}><button className='btn btn-sm btn-info ml-1'>Update Atasan Langsung dan Section</button></Link>
        </div>
    )
    return (
        <div>
            <div className="row">
                <div className="col-6">
                    <section className="content-header">
                        <div className="container-fluid">
                            <h4>Master Data</h4>
                            <h6>Demografi Employee</h6>
                        </div>
                    </section>
                </div>
                <div className="col-6">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end">
                            <li className="breadcrumb-item" aria-current="page">
                                Master Data
                            </li>
                            <li
                                className="breadcrumb-item active"
                                aria-current="page"
                            >
                                Demografi Employee
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <TableList
                        filter={true}
                        formsFilter={[
                            {
                                type: 'custom',
                                component: (
                                    <div>

                                        <div className="row mb-3">
                                            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label col-form-label-sm">Role</label>
                                            <div className="col-sm-9">
                                                <select value={mdEmpState.filter.role_id} className='form-select form-select-sm'
                                                    onChange={(e) => mdEmpAction.setFilter((prev) => ({ ...prev, role_id: e.target.value }))}
                                                >
                                                    {
                                                        [...(mdEmpState.dataRoles || [])].map((d, i) => (
                                                            <option key={i} value={d.id}>{d.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="inputPassword3" className="col-sm-3 col-form-label col-form-label-sm">Job Type</label>
                                            <div className="col-sm-9">
                                                <select value={mdEmpState.filter.job_type} className='form-select form-select-sm'
                                                    onChange={(e) => mdEmpAction.setFilter((prev) => ({ ...prev, job_type: e.target.value }))}
                                                >
                                                    <option value={""}>-Pilih Job type-</option>
                                                    {
                                                        [...(mdEmpState.dataJobtypes || [])].map((d, i) => (
                                                            <option key={i} value={d.job_type}>{d.job_type}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label col-form-label-sm">Jobsite</label>
                                            <div className="col-sm-9">
                                                <select value={mdEmpState.filter.jobsite_code} className='form-select form-select-sm'
                                                    onChange={(e) => mdEmpAction.setFilter((prev) => ({ ...prev, jobsite_code: e.target.value }))}
                                                >
                                                    <option value={""}>-Pilih Jobsite-</option>
                                                    {
                                                        [...(mdEmpState.dataJobsites || [])].map((d, i) => (
                                                            <option key={i} value={d?.jobsite_code}>{d?.jobsite_name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="nik" className="col-sm-3 col-form-label col-form-label-sm">NIK</label>
                                            <div className="col-sm-9">
                                                <input className='form-control form-control-sm' value={mdEmpState.filter.nik}
                                                    onChange={(e) => mdEmpAction.setFilter((prev) => ({ ...prev, nik: e.target.value }))}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label htmlFor="search" className="col-sm-3 col-form-label col-form-label-sm">Search</label>
                                            <div className="col-sm-9">
                                                <input className='form-control form-control-sm' value={mdEmpState.filter.search}
                                                    onChange={(e) => mdEmpAction.setFilter((prev) => ({ ...prev, search: e.target.value }))}
                                                />
                                            </div>
                                        </div>
                                        <div className="tw-flex tw-h-full tw-justify-end">
                                            <button className='btn btn-sm btn-primary tw-mt-auto'
                                                onClick={() => mdEmpAction.doFilter()}
                                            ><i className='fa fa-search'></i> Search</button>
                                        </div>
                                    </div>
                                )
                            }
                        ]}
                        onFilter={() => mdEmpAction.doFilter()}
                        resetFilter={false}
                        pagination={true}
                        withCard={false}
                        withButtonSubmit={false}

                        minHeight="450px"
                        maxHeight="500px"
                        navtable={"TABLE 1"}
                        customToolbar={<CustomToolbar />}
                        isLoading={mdEmpState.loadingList}

                        onChangeShow={mdEmpAction.changeShow}
                        onNextPage={mdEmpAction.nextPage}
                        onPrevPage={mdEmpAction.prevPage}
                        defaultShow={mdEmpState.pagination?.show}
                        curPage={mdEmpState.pagination?.page || 1}
                        maxPage={mdEmpState.pagination?.totalPage || 1}
                        totalData={mdEmpState.pagination?.totalData || 0}

                        withActions={true}
                        actionType={1}
                        actionItems={[
                            {
                                name: 'Lihat detail',
                                onClick: (item) => {
                                    history.push({
                                        pathname: `/master-data/demografi-employee/detail/${item.nik}`,
                                        state: { filterState: mdEmpState.filter }
                                    })
                                }
                            },
                            {
                                name: 'Edit',
                                onClick: (item) => {
                                    history.push({
                                        pathname: `/master-data/demografi-employee/edit/${item.nik}`,
                                        state: { filterState: mdEmpState.filter }
                                    })
                                }
                            },
                            {
                                name: 'Hapus',
                                onClick: (item) => mdEmpAction.confirmDelete(item.nik)
                            }
                        ]}
                        columns={[
                            {
                                name: '#',
                                field: '#',
                                maxWidth: '50px',
                            },
                            {
                                name: 'NIK',
                                field: 'nik',
                                maxWidth: '250px',
                            },
                            {
                                name: 'Nama',
                                field: 'name',
                                maxWidth: '150px',
                            },
                            {
                                name: 'Job Type',
                                field: 'job_type',
                                maxWidth: '150px',
                            },
                            {
                                name: 'Tanggal Bergabung',
                                field: 'join_date',
                                maxWidth: '250px',
                            },
                            {
                                name: 'Point of Hire',
                                field: 'poh',
                                maxWidth: '150px',
                            },
                            {
                                name: 'Jobsite',
                                field: 'jobsite_name',
                                maxWidth: '150px',
                            },
                            {
                                name: 'Fungsi',
                                field: 'function',
                                maxWidth: '150px',
                            },
                            {
                                name: 'Job Title',
                                field: 'job_title',
                                maxWidth: '150px',
                            },
                            {
                                name: 'Level',
                                field: 'level',
                                maxWidth: '150px',
                            },
                            {
                                name: 'Grade',
                                field: 'grade',
                                maxWidth: '150px',
                            },
                            {
                                name: 'Role',
                                field: 'role_name',
                                maxWidth: '150px',
                            },
                        ]}
                        data={mdEmpState.dataList}
                    />

                </div>
                <div className='card-footer'>
                    <div className='row'>
                        <div className='col'>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default () => <MdEmpProvider><Index /></MdEmpProvider>;