import React, { createContext, useContext, useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useModalAlert } from "../../../components/modalAlert";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";


const context = createContext({
    mdEmpState: {},
    mdEmpAction: {}
});

export function MdEmpProvider({ children }) {
    const [firstRender, setFirstRender] = useState(true);
    const { state = {} } = useLocation();
    const [pagination, setPagination] = useState({
        page: 1,
        show: 10,
        totalPage: 1,
        totalData: 0,
    });

    const [filter, setFilter] = useState({
        search: "",
        nik: "",
        role_id: "3",
        job_type: "",
        jobsite_code: "",
        ...state?.filterState
    });
    const { doGet, doGetMultiple, doPost } = useFetch();
    const { modalAction } = useModalAlert();
    const {
        closeLoading,
        setDataAlert,
        setDataConfirm,
        closeConfirm,
        openLoading
    } = modalAction;

    const [loadingList, setLoadingList] = useState(false);
    const [dataList, setDataList] = useState([]);
    const GetDataList = async function (page = pagination.page) {
        setLoadingList(true);
        try {
            const { status, statusText, payload } = await doGet({
                url: "/api/data-master/users",
                param: {
                    ...filter,
                    page: page,
                    row_in_page: pagination.show,
                },
            });

            if (status === 401) {
                closeLoading();
            } else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setPagination((v) => ({
                        ...v,
                        totalPage: data.totalPage,
                        totalData: data.totalData,
                    }));
                    setDataList(data.listData || []);
                } else
                    setDataAlert({
                        show: true,
                        body: msg,
                        header: "Get List Demografi",
                        callBack: () => null,
                    });
            } else
                setDataAlert({
                    show: true,
                    body: statusText,
                    header: "Get List Demografi",
                    callBack: () => null,
                });
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Demografi",
                callBack: () => null,
            });
        }
        setLoadingList(false);
    }

    const [dataRoles, setDataRoles] = useState([]);
    const [dataJobtypes, setDataJobtypes] = useState([]);
    const [dataJobsites, setDataJobsites] = useState([]);
    const [loadingSources, setLoadingSources] = useState(false);
    const GetFilterSources = async function () {
        try {
            openLoading("Please wait ...");
            setLoadingSources(true);
            const { roles, jobType, jobsiteCode } = await doGetMultiple([
                {
                    url: '/api/data-master/roles',
                    responseVar: "roles"
                },
                {
                    url: '/api/data-master/job-types',
                    responseVar: "jobType"
                },
                {
                    url: '/api/list-all-jobsite',
                    responseVar: "jobsiteCode"
                },
            ])
            //handle request1
            if (roles?.status === 401) {
                closeLoading();
            }
            else if (roles?.status === 200) {
                const data = { ...roles?.payload };
                if (data?.code === "404") {
                    closeLoading();
                    return setDataAlert({
                        show: true,
                        body: data?.msg,
                        header: "Get List Roles",
                        callBack: () => null
                    });
                } else {
                    setDataRoles(data?.data);
                }
            } else setDataAlert({
                show: true,
                body: roles?.msg,
                header: "Get List Roles",
                callBack: () => null
            })

            //handle request2
            if (jobType?.status === 401) {
                closeLoading();
            }
            else if (jobType?.status === 200) {
                const data = { ...jobType?.payload };
                if (data?.code === "404") {
                    closeLoading();
                    return setDataAlert({
                        show: true,
                        body: data?.msg,
                        header: "Get List Job type",
                        callBack: () => null
                    });
                } else {
                    setDataJobtypes(data?.data);
                }
            } else setDataAlert({
                show: true,
                body: jobType?.msg,
                header: "Get List Job type",
                callBack: () => null
            })

            //handle request3
            if (jobsiteCode?.status === 401) {
                closeLoading();
            }
            else if (jobsiteCode?.status === 200) {
                const data = { ...jobsiteCode?.payload };
                if (data?.code === "404") {
                    closeLoading();
                    return setDataAlert({
                        show: true,
                        body: data?.msg,
                        header: "Get List Jobsite",
                        callBack: () => null
                    });
                } else {
                    setDataJobsites(data?.data?.jobsites);
                }
            } else setDataAlert({
                show: true,
                body: roles?.msg,
                header: "Get List Jobsite",
                callBack: () => null
            })

            setLoadingSources(false);
            setFirstRender(false);
            closeLoading();

        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Source filter",
                callBack: () => null
            })
        }
    }

    function nextPage({ page, limit }) {
        setPagination((prevState) => ({
            ...prevState,
            page,
            show: limit,
        }));
    }
    function prevPage({ page, limit }) {
        setPagination((prevState) => ({
            ...prevState,
            page,
            show: limit,
        }));
    }
    function changeShow({ page, limit }) {
        setPagination((prevState) => ({
            ...prevState,
            page,
            show: limit,
        }));
    }
    function doFilter() {
        GetDataList(1);
        setPagination((v) => ({
            ...v,
            page: 1,
        }));
    }

    const doDelete = async function (nik = '') {
        closeConfirm()
        openLoading("Deleting data, please wait...");
        try {
            const { status, payload, statusText } = await doPost({
                url: `/api/data-master/delete-user/${nik}`,
                method: 'DELETE'
            });

            if (status !== 401) {
                if (status === 200 || status === 201) {
                    const { code, msg } = payload;
                    if (code === "00") {
                        setDataAlert({
                            body: msg,
                            show: true,
                            header: "Success",
                            callBack: () => { 
                                GetDataList();
                            }
                        })
                    } else setDataAlert({
                        show: true,
                        body: msg,
                        header: "Info",
                        callBack: () => null
                    })
                } else setDataAlert({
                    show: true,
                    body: statusText,
                    header: "Info",
                    callBack: () => null
                })
            }
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Info",
                callBack: () => null
            })
        }
        closeLoading();
    };

    const confirmDelete = function (nik = "") {
        setDataConfirm({
            show: true,
            body: "Apa anda yakin?",
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Yes", onClick: () => doDelete(nik), className: "btn btn-sm btn-danger" },
                { name: "No", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    };

    useEffect(() => {
        console.log(state);
        if (firstRender) {
            GetFilterSources();
        } else {
            GetDataList();
        }
    }, [pagination.page, pagination.show])
    return <context.Provider
        value={{
            mdEmpState: {
                loadingList,
                dataList,
                dataRoles,
                dataJobtypes,
                dataJobsites,
                filter,
                pagination,
                loadingSources
            },
            mdEmpAction: {
                nextPage,
                prevPage,
                changeShow,
                doFilter,
                setFilter,
                confirmDelete
            }
        }}
    >{children}</context.Provider>
}

export default function useMdEmp() {
    return useContext(context);
}