import React, { useMemo } from "react";
import useMidp, { MidpProvider } from "./useMasterIdp";
import { useHistory } from "react-router";
import Skeleton from "react-loading-skeleton";
import { DateInput } from "../../../components";
import { Link } from 'react-router-dom';

function InputCustom({
   title="",
   val="",
   disabled=true,
   isLoading=true,
   type="text",
   dataOptions=[],
   onChange=()=>{},
   onRender=()=> null,
}) {

    let newVal = useMemo(function(){
        let xval = onRender(val);
        if(!xval)
            xval = val;
        return xval;

    },[val, onRender])

    console.log({newVal})

    function getInput(){ 
        if(type === "select") return (
            <select onChange={onChange} value={newVal} className="form-control" disabled={disabled}>
                {
                    dataOptions.map((v,i)=> <option key={i} value={v.key}>{v.label}</option>)
                }
            </select>
        )
        else if(type ==="date") return (
            <DateInput 
                className="form-control"
                disabled={disabled}
                value={newVal}
                onChange={onChange}
            />
        )
        
        else if (type === "file") {
            return (
                <>
                    {
                        !disabled && (
                            <input
                                id="inputfile"
                                accept="image/png, image/jpg, image/jpeg, image/png, image/bmp, image/gif, image/webp, image/image"
                                onChange={onChange}
                                type="file"
                                className="form-control"
                            />
                        )
                    }
                </>
            )
        }
        else return (
            <input
                onChange={onChange}
                type={type}
                value={newVal}
                disabled={disabled}
                className="form-control disabled:tw-bg-white"
                placeholder={title}
            />
        )
    }
    return (
        <div className="tw-px-2">
            <label>{title}</label>
            { isLoading ? <div>
                <Skeleton height={30} width={"100%"} />
            </div>: getInput() }
        </div>
    );
}

function FormMasterIDP() {
    const {
        midpState: {
            form,
            loadingList,
            dataList,
            pagination,
            filter,
            tipe,
            formIdp,
        },
        midpAction: {
            setForm,
            doUpload,
            prevPage,
            nextPage,
            changeShow,
            setFilter,
            doFilter,
            setFormIdp,
            doUbah=()=>{},
            handleChangeFom=()=>{}
        },
    } = useMidp();

    const imageUrl = useMemo(() => {
        if (formIdp?.file) {
            return URL.createObjectURL(formIdp?.file);
        } else {
            return formIdp?.certificate_path
        }
    }, [formIdp])
    const history = useHistory();
    return (
        <>
            <div className="row">
                <div className="col-6">
                    <section className="content-header">
                        <div className="container-fluid">
                            <h4>Master Data</h4>
                            <h6>IDP Register</h6>
                        </div>
                    </section>
                </div>
                <div className="col-6">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end">
                            <li className="breadcrumb-item" aria-current="page">
                                Master Data
                            </li>
                            <li className="breadcrumb-item" aria-current="page">
                                <Link to="/master-data/idp-register" >
                                    IDP Register
                                </Link> 
                            </li>
                            <li
                                className="breadcrumb-item active"
                                aria-current="page"
                            >
                                <span className="tw-capitalize">{tipe}</span>
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title tw-capitalize">
                                {tipe}
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="tw-px-5 tw-py-3 tw-rounded-md tw-border tw-border-solid tw-border-success tw-mb-5">
                                <div className="tw-mb-6 tw-mx-3 tw-mt-3 tw-border-solid tw-border-t-0 tw-border-x-0 tw-border-b-2 tw-border-b-success">
                                    <h5 className="text-success"> Detail Trainee</h5>
                                </div>
                                <div className="tw-grid tw-grid-cols-1 tw-gap-2 lg:tw-grid-cols-3">
                                    {[
                                        {
                                            title: "NIK",
                                            field: "nik",
                                            disabled: tipe === "detil",
                                            onChange:(e)=>{
                                                setFormIdp( v => {
                                                    return {
                                                        ...v,
                                                        nik:e.target?.value
                                                    }
                                                })
                                            }
                                        },
                                        {
                                            title: "Nama",
                                            field: "name",
                                            disabled: tipe === "detil",  
                                            onChange:(e)=>{
                                                setFormIdp( v => {
                                                    return {
                                                        ...v,
                                                        name:e.target?.value
                                                    }
                                                })
                                            }
                                        },
                                        {
                                            title: "Tipe Competency",
                                            field: "competency_type",
                                            disabled: tipe === "detil",
                                            onChange:(e)=>{
                                                setFormIdp( v => {
                                                    return {
                                                        ...v,
                                                        competency_type:e.target?.value
                                                    }
                                                })
                                            }
                                        },
                                        {
                                            title: "Nama Competency",
                                            field: "competency_name",
                                            disabled: tipe === "detil",  
                                            onChange:(e)=>{
                                                setFormIdp( v => {
                                                    return {
                                                        ...v,
                                                        competency_name:e.target?.value
                                                    }
                                                })
                                            }
                                        },
                                        {
                                            title: "Group Competency",
                                            field: "group",
                                            disabled: tipe === "detil",
                                            onChange:(e)=>{
                                                setFormIdp( v => {
                                                    return {
                                                        ...v,
                                                        group:e.target?.value
                                                    }
                                                })
                                            }
                                        },
                                        {
                                            title: "Jobsite Code",
                                            field: "jobsite_code",
                                            disabled: tipe === "detil",
                                            onChange:(e)=>{
                                                setFormIdp( v => {
                                                    return {
                                                        ...v,
                                                        jobsite_code:e.target?.value
                                                    }
                                                })
                                            }
                                        },
                                        {
                                            title: "Jobsite Name",
                                            field: "jobsite_name",
                                            disabled: tipe === "detil",
                                            onChange:(e)=>{
                                                setFormIdp( v => {
                                                    return {
                                                        ...v,
                                                        jobsite_name:e.target?.value
                                                    }
                                                })
                                            }
                                        },
                                    ].map(({onChange=()=>{}, ...val}, x) => {
                                        return (
                                            <div key={x}>
                                                <InputCustom
                                                    isLoading={loadingList}
                                                    title={val.title}
                                                    val={formIdp?.[val.field]}
                                                    disabled={val.disabled}
                                                    onChange={onChange}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="tw-px-5 tw-py-3 tw-rounded-md tw-border tw-border-solid tw-border-success  tw-mb-5">
                                <div className="tw-mb-6 tw-mx-3 tw-mt-3 tw-border-solid tw-border-t-0 tw-border-x-0 tw-border-b-2 tw-border-b-success">
                                    <h5 className="text-success"> Detail Training</h5>
                                </div>
                                <div className="tw-grid tw-grid-cols-1 tw-gap-2 lg:tw-grid-cols-3">
                                    {[
                                        {
                                            title: "Cluster",
                                            field: "cluster",
                                            disabled: tipe === "detil",
                                            onChange:(e)=>{
                                                setFormIdp( v => {
                                                    return {
                                                        ...v,
                                                        cluster:e.target?.value
                                                    }
                                                })
                                            }
                                        },
                                        {
                                            title: "Course Name",
                                            field: "course_name",
                                            disabled: tipe === "detil",
                                            onChange:(e)=>{
                                                setFormIdp( v => {
                                                    return {
                                                        ...v,
                                                        course_name:e.target?.value
                                                    }
                                                })
                                            }
                                        },
                                        {
                                            title: "Point",
                                            field: "point",
                                            disabled: tipe === "detil",
                                            type:"number",
                                            onChange:(e)=>{
                                                setFormIdp( v => {
                                                    return {
                                                        ...v,
                                                        point:e.target?.value
                                                    }
                                                })
                                            }
                                        },
                                        {
                                            title: "NIK Approver",
                                            field: "training_nik_approver",
                                            disabled: tipe === "detil",
                                            onChange:(e)=>{
                                                setFormIdp( v => {
                                                    return {
                                                        ...v,
                                                        training_nik_approver:e.target?.value
                                                    }
                                                })
                                            }
                                        },
                                        {
                                            title: "Tanggal Approver",
                                            field: "training_approval_date",
                                            type:"date",
                                            disabled: tipe === "detil",  
                                            onChange:(e)=>{
                                                setFormIdp( v => {
                                                    return {
                                                        ...v,
                                                        training_approval_date:e
                                                    }
                                                })
                                            }
                                        },
                                        {
                                            title: "Training Batch",
                                            field: "training_batch",
                                            disabled: tipe === "detil",
                                            onChange:(e)=>{
                                                setFormIdp( v => {
                                                    return {
                                                        ...v,
                                                        training_batch:e.target?.value
                                                    }
                                                })
                                            }
                                        },
                                        {
                                            title: "Training Date",
                                            field: "training_date",
                                            type:"date",
                                            disabled: tipe === "detil",
                                            onChange:(e)=>{
                                                setFormIdp( v => {
                                                    return {
                                                        ...v,
                                                        training_date:e
                                                    }
                                                })
                                            }
                                        },
                                        {
                                            title: "NIK Trainer",
                                            field: "nik_trainer",
                                            disabled: tipe === "detil",
                                            onChange:(e)=>{
                                                setFormIdp( v => {
                                                    return {
                                                        ...v,
                                                        nik_trainer:e.target?.value
                                                    }
                                                })
                                            }
                                        },
                                        {
                                            title: "Trainer Name",
                                            field: "trainer_name",
                                            disabled: tipe === "detil",
                                            onChange:(e)=>{
                                                setFormIdp( v => {
                                                    return {
                                                        ...v,
                                                        trainer_name:e.target?.value
                                                    }
                                                })
                                            }
                                        },
                                        {
                                            title: "Training Day",
                                            field: "training_percent_day_present",
                                            disabled: tipe === "detil",
                                            type:"number",
                                            onChange:(e)=>{
                                                setFormIdp( v => {
                                                    return {
                                                        ...v,
                                                        training_percent_day_present:e.target?.value
                                                    }
                                                })
                                            }
                                        },
                                        {
                                            title: "Training Score",
                                            field: "training_score",
                                            disabled: tipe === "detil",
                                            type:"number",
                                            onChange:(e)=>{
                                                setFormIdp( v => {
                                                    return {
                                                        ...v,
                                                        training_score:e.target?.value
                                                    }
                                                })
                                            }
                                        },
                                        {
                                            title: "Assesment Date",
                                            field: "assessment_date",
                                            type:"date",
                                            disabled: tipe === "detil",
                                            onChange:(e)=>{
                                                setFormIdp( v => {
                                                    return {
                                                        ...v,
                                                        assessment_date:e
                                                    }
                                                })
                                            }
                                        },
                                        {
                                            title: "NIK Assessor",
                                            field: "nik_assessor",
                                            disabled: tipe === "detil",
                                            onChange:(e)=>{
                                                setFormIdp( v => {
                                                    return {
                                                        ...v,
                                                        nik_assessor:e.target?.value
                                                    }
                                                })
                                            }
                                        },
                                        {
                                            title: "Assesor Name",
                                            field: "assessor_name",
                                            disabled: tipe === "detil",
                                            onChange:(e)=>{
                                                setFormIdp( v => {
                                                    return {
                                                        ...v,
                                                        assessor_name:e.target?.value
                                                    }
                                                })
                                            }
                                        },
                                        {
                                            title: "Attended Ratio",
                                            field: "assessment_attended_ratio",
                                            disabled: tipe === "detil",
                                            type:"number",
                                            onChange:(e)=>{
                                                setFormIdp( v => {
                                                    return {
                                                        ...v,
                                                        assessment_attended_ratio:e.target?.value
                                                    }
                                                })
                                            }
                                        },
                                        {
                                            title: "Assessment Score",
                                            field: "assessment_score",
                                            disabled: tipe === "detil",
                                            type:"select",
                                            dataOptions: [
                                                { key: "competent", label:"Competent"  },
                                                { key:"not competent", label: "Not Competent" }
                                            ],
                                            onChange:(e)=>{
                                                setFormIdp( v => {
                                                    return {
                                                        ...v,
                                                        assessment_score:e.target?.value
                                                    }
                                                })
                                            }
                                        },
                                        {
                                            title: "Assesment Date",
                                            field: "assessment_passed_date",
                                            type:"date",
                                            disabled: tipe === "detil",
                                            onChange:(e)=>{
                                                setFormIdp( v => {
                                                    return {
                                                        ...v,
                                                        assessment_passed_date:e
                                                    }
                                                })
                                            }
                                        },
                                    ].map(({onChange=()=>{}, ...val}, x) => {
                                        return (
                                            <div key={x}>
                                                <InputCustom
                                                    isLoading={loadingList}
                                                    title={val.title}
                                                    val={formIdp?.[val.field]}
                                                    disabled={val.disabled}
                                                    type={val.type}
                                                    dataOptions={val.dataOptions || []}
                                                    onChange={onChange}
                                                    onRender={(item)=>{

                                                    }}
                                                />
                                            </div>
                                        );
                                    })}
                                    
                                    <div className="tw-col-span-1 sm:tw-col-span-3 tw-col-start-1 tw-ml-[5px]">
                                            <div className="tw-w-[240px]">
                                                <label>Certificate</label>
                                                <div className='d-block tw-h-[240px] tw-w-[240px] border border-secondary mb-3'>
                                                    {
                                                        imageUrl && (
                                                            <img className='mb-2 tw-w-full tw-h-full' src={imageUrl}></img>
                                                        )
                                                    }
                                                </div>
                                                <InputCustom
                                                    withTitle={false}
                                                    isLoading={loadingList}
                                                    val={formIdp?.["file"]}
                                                    disabled={ tipe === "detil"}
                                                    onChange={(e) => handleChangeFom("file", e.target.files[0])}
                                                    type={"file"}
                                                    dataOptions={[]}
                                                    formData={formIdp}
                                                />
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="tw-flex tw-justify-between">
                                <button
                                    onClick={() =>
                                        history.push(
                                            "/master-data/idp-register"
                                        )
                                    }
                                    className="btn btn-outline-secondary"
                                >
                                    Back
                                </button>
                                {tipe === "ubah" && (
                                    <button className="btn btn-primary" onClick={()=>doUbah()}>
                                        Submit
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default () => (
    <MidpProvider>
        <FormMasterIDP />
    </MidpProvider>
);
