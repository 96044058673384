import React from 'react';
import { StatusIcon, TableList } from '../../../../components';
import useMenSts from '../useMentSts';

function Index(props) {
    const { menStsState: { loadingCom = false, listCom = [], pageState }, menStsAction: {
        toFormPage = () => null,
        toListTraineePage = () => null
    } } = useMenSts();
    return (
        <div className='card'>
            <div className='card-body'>
                <div className='row mb-3'>
                    <div className='col-md-12 col-sm-12'>
                        <TableList
                            isLoading={loadingCom}
                            data={[...listCom]}
                            withCard={false}
                            columns={[
                                {
                                    name: '#',
                                    field: '#',
                                },
                                // {
                                //     name: 'Competency',
                                //     field: 'competency_name',
                                // },
                                {
                                    name: 'Course Name',
                                    field: 'course_name',
                                },
                                {
                                    name: 'Form Name',
                                    field: 'form_name',
                                },
                                {
                                    name: 'Status',
                                    field: 'status',
                                    onRender: (d) => (
                                        <>
                                            {
                                                d?.status && (
                                                    <div className='tw-flex' onClick={() => toFormPage({ ...pageState, ...d }, d?.status === 'declined' ? true : false)}>
                                                        <StatusIcon status={d?.status} />
                                                    </div>
                                                    // <button className={`btn btn-sm btn-${d?.status === 'approved' ? 'success' :
                                                    //     d?.status === 'declined' ? 'danger' : d?.status === 'submitted' ? 'primary' : 'secondary'} text-capitalize`}
                                                    //     onClick={() => toFormPage({ ...pageState, ...d }, d?.status === 'declined' ? true : false)}
                                                    // >
                                                    //     {d?.status}
                                                    // </button>
                                                )
                                            }
                                        </>

                                        // <span className={
                                        //     `text-capitalize badge badge-pill badge-${d?.status === 'approved' ? 'success' :
                                        //         d?.status === 'declined' ? 'danger' : d?.status === 'submitted' ? 'primary' : 'secondary'
                                        //     }`
                                        // }>
                                        //     {d?.status}
                                        // </span>
                                    )
                                },
                                // {
                                //     name: 'Aksi',
                                //     field: '',
                                //     minWidth: 200,
                                //     maxWidt: 200,
                                //     // className: 'text-right',
                                //     onRender: (item) => (
                                //         <div>
                                //             {/* <ul>
                                //                 {
                                //                     [...(item?.listForm || [])].map((d, i) => (
                                //                         <>
                                //                             <li className='text-primary' role={'button'}
                                //                             onClick={() => tirAction.toFormPage({ ...d, training_id: item?.training_id }, true)}
                                //                             >{d?.title} <span className={
                                //                                 `text-capitalize badge badge-pill badge-${d?.status === 'approved' ? 'success' :
                                //                                     d?.status === 'declined' ? 'danger' :
                                //                                         'primary'
                                //                                 }`
                                //                             }>
                                //                                     {d?.status}
                                //                                 </span></li>
                                //                         </>
                                //                     ))
                                //                 }
                                //             </ul> */}
                                //             <button
                                //                 // onClick={() => toListTraineePage({ competency_id: item?.competency_id })}
                                //                 onClick={() => toFormPage({ ...pageState, ...item })}
                                //                 className='btn btn-sm btn-success'>
                                //                 Isi Form
                                //             </button>
                                //         </div>
                                //     )
                                // },
                            ]}
                            withActions={false}
                            pagination={false}
                        />
                        <button className='btn btn-sm btn-secondary mt-3'
                            onClick={toListTraineePage}>Back</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;