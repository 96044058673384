import moment from 'moment';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { StatusIcon, TableList } from '../../../components';
import ModalDecline from './modalDecline';
import ModalDetil from './modalDetil';
import usePHMO from './usePHMO';

export default () => {
    const {
        PHMOState: {
            pageStatus: {
                isLoading = false,
                paramState: {
                    trainee_name = "",
                    trainee_nik = "",
                }

            },

            paramHistory = {},
            listHm = []
        },
        PHMOAction: {
            resetPage = () => null,
            doApproval = () => null,
            openModalDetil = (item) => null,
            openModalDecline = (item) => null,
            toListTrainieePage = (item) => null
        }
    } = usePHMO()
    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <h4>Hours Meter (HM) Operator</h4>
                    <h6>Approval HM Operator</h6>
                </div>
            </section>

            <div className='row'>
                <div className='col-md-12'>
                    <div className='card'>
                        <div className='card-header'>
                            <div className='card-title'>
                                <h5> {trainee_nik} - {trainee_name} </h5>
                            </div>
                        </div>
                        <div className='card-body'>
                            {/* <div className='row'>
                                <div className='col-md-2'>
                                    Course Name <span className='float-right'>:</span>
                                </div>

                                <div className='col-md-3'>
                                    <div className='input-group'>
                                        {course_name}
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-2'>
                                    Competency Name <span className='float-right'>:</span>
                                </div>

                                <div className='col-md-3'>
                                    {competency_name}
                                </div>
                            </div> */}
                            <div className='row mt-2'>
                                <div className='col-md-12'>
                                    <TableList
                                        isLoading={isLoading}
                                        withCard={false}
                                        withActions={true}
                                        actionItems={[
                                            {
                                                name: "Approve",
                                                onClick: ({ id }) => doApproval(id, "approved"),
                                                onRender: ({ status }) => (status === "submitted")
                                            },
                                            {
                                                name: "Decline",
                                                // onClick: ({ id }) => doApproval(id, "declined"),
                                                onClick: (item) => openModalDecline(item),
                                                onRender: ({ status }) => (status === "submitted")
                                            },
                                            {
                                                name: "Detail",
                                                onClick: (item) => openModalDetil(item),
                                            },
                                        ]}
                                        filter={false}
                                        data={listHm || []}
                                        pagination={false}
                                        columns={[
                                            {
                                                name: "Date",
                                                onRender: ({ date }) => moment(date).format("DD/MM/YYYY")
                                            },
                                            // {
                                            //     name: "Trainee Name",
                                            //     field: "trainee_name",
                                            //     onRender: ({ trainee_name, trainee_nik }) => `${trainee_nik} - ${trainee_name}`
                                            // },
                                            {
                                                name: "Shift",
                                                field: "shift"
                                            },
                                            {
                                                name: "Unit Training",
                                                field: "training_unit"
                                            },
                                            {
                                                name: "HM Total",
                                                field: "hm_total"
                                            },
                                            {
                                                name: "Status",
                                                onRender: ({ status }) => {
                                                    return (
                                                        <StatusIcon status={status}/>
                                                        //                 <span style={{ textTransform: "capitalize" }} className={`
                                                        // badge badge-${status === "declined" ? "danger" :
                                                        //                         status === "approved" ? "success" :
                                                        //                             status === "submitted" ? "primary" : ""
                                                        //                     }`}>
                                                        //                     {status}
                                                        //                 </span>
                                                    )
                                                }
                                            },
                                            // {
                                            //     name: "",
                                            //     className: "text-right",
                                            //     onRender: ({ id }) => (status === "submitted" || status === "declined") && 
                                            //     <div style={{ minWidth:"max-content" }}>
                                            //         <button onClick={()=> doApproval(id, "approved")} className='btn btn-sm btn-success mr-2'>
                                            //             Approve    
                                            //         </button>
                                            //         <button onClick={()=> doApproval(id, "declined")} className='btn btn-sm btn-danger'>
                                            //             Decline    
                                            //         </button>
                                            //     </div>

                                            // }
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='card-footer'>
                            <button onClick={() => toListTrainieePage(paramHistory)} className="btn btn-default"> Back </button>
                        </div>
                    </div>
                </div>
            </div>

            <ModalDetil />
            <ModalDecline />

        </>
    )
}