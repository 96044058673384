import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useModalAlert } from '../../components/modalAlert';
import useFetch from '../../hooks/useFetch';

const ARContext = createContext({
    ARState : {},
    ARActions : {}
});

export function ARProvider({children}){

    const { doGet, doPost, wait } = useFetch()
    const { modalState, modalAction } = useModalAlert();
    
    const [loadingList, setLoadingList] = useState(false)
    const [dataList, setDataList] = useState([])
    const [ detilData, setDetilData] = useState({
        totalData:0, totalPage:0
    })
    const [pagination, setPagination] = useState({
        page:1, show:10, 
        order_by:"", order_type:"",
        // totalData:0, totalPage:0
    })
    const [filter, setFilter] = useState({
        filter_nik :"",
        filter_name:"",
        
    })
    
    const refInputFile = useRef();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const [ formData, setFormData ] = useState({
        file: null
    })
    const doUpload = useCallback(function () {
        // console.log(data)
        if (!formData.file){
            return setDataAlert({
                show: true,
                body: "File belum dipilih",
                header: "Info",
                callBack: () => null
            })
        }
        let body = "Pastikan form terisi dengan benar. Lanjutkan?"
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                {
                    name: "Yes", onClick: async () => {
                        closeConfirm();
                        openLoading("Uploading ...")
                        try {
                            const { status, payload, statusText } = await doPost({
                                url: "/api/upload-data-ar",
                                data: {
                                    ...formData
                                },

                                type: 1,
                                paramImage: 'file',
                            });

                            if (status !== 401) {
                                if (status === 200 || status === 201) {
                                    const { code, msg } = payload;
                                    if (code === "00") {
                                        setDataAlert({
                                            body: msg,
                                            show: true,
                                            header: "Success",
                                            callBack: () => {
                                                if(refInputFile.current){
                                                    refInputFile.current.value = null;
                                                }
                                                getDataList()
                                                setFormData((e) => ({
                                                    ...e,
                                                    file: null
                                                }))
                                            }
                                        })
                                    } else setDataAlert({
                                        show: true,
                                        body: msg,
                                        header: "Info",
                                        callBack: () => null
                                    })
                                } else setDataAlert({
                                    show: true,
                                    body: statusText,
                                    header: "Info",
                                    callBack: () => null
                                })
                            }
                        } catch (error) {
                            setDataAlert({
                                show: true,
                                body: error.toString(),
                                header: "Info",
                                callBack: () => null
                            })
                        }
                        closeLoading()


                    }, className: "btn btn-sm btn-success"
                },
                { name: "No", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }, [formData, getDataList, setFormData])

    const getDataList = useCallback(async function(){
        setLoadingList(true);
        try {
            const { status, statusText, payload } = await doGet({
                param: {
                    "row_in_page": pagination.show,
                    "page": pagination.page,
                    ...filter,
                    ...pagination
                }
                ,
                url: '/api/dashboard/trainee-lcofficer'
            });
            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                // console.log(data)
                
                if (code === "00") {
                    const {
                        totalData=0, totalPage=0, listData= []
                    } = data
                    setDataList(listData)
                    setDetilData({totalData, totalPage})
                    // setDataList({ ...data })
                    // setData
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Info",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Info",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Error",
                callBack: () => null
            })
        }
        setLoadingList(false)
    },[pagination, setLoadingList, setDataList]);

    useEffect(function(){
        getDataList()
    },[getDataList])

    
    function doFilter() {
        setPagination(prevState => ({
            ...prevState,
            page: 1
        }))
    }
    function nextPage({ page, limit }) {
        setPagination(prevState => ({
            ...prevState,
            page, show: limit,
        }))
    }
    function prevPage({ page, limit }) {
        setPagination(prevState => ({
            ...prevState,
            page, show: limit,
        }))
    }
    function changeShow({ page, limit }) {
        setPagination(prevState => ({
            ...prevState,
            page, show: limit,
        }))
    }
    
    function onOrdering({ order_by, order_type }) {
        setPagination(prevState => ({
            ...prevState,
            order_by, order_type
        }))
    }
    
    return <ARContext.Provider value={{
        ARState : {
            formData,
            loadingList,
            dataList,
            pagination,
            detilData, filter, refInputFile
        },
        ARActions : {
            setFormData,
            doUpload,
            nextPage, 
            prevPage,
            changeShow,
            onOrdering,
            doFilter,
            setFilter
            
        }
    }}>
        {children}
    </ARContext.Provider>
}

export default function useUploadAR(){
    return useContext(ARContext)
}