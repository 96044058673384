import React from 'react';
import useLsIdp from './useLsIdp';
// import useAtsStatus from './useAtsStatus';

const TableToHTML = () => {
    // const { lsIdpState } = useAtsStatus()
    const { lsIdpState } = useLsIdp();
    return (
        <table className="w-100 f-10" id="table-to-xls" style={{ display: "none" }}>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Category Training</th>
                    <th>Training Name</th>
                    <th>Trainer NIK</th>
                    <th>Trainer Name</th>
                    <th>Kuota Training</th>
                    <th>Trainee</th>
                    <th>Start ATS</th>
                    <th>Finish ATS</th>
                    <th>Start MTS</th>
                    <th>Finish MTS</th>
                    <th>Remark</th>
                </tr>
            </thead>
            <tbody>
                {
                    (lsIdpState.dataGet?.exportData || []).map(({
                        type,
                        course_name,
                        end_date_ats,
                        end_date_mts,
                        start_date_mts,
                        start_date_ats,
                        trainer_name_ats,
                        trainer_name_mts,
                        trainer_nik_mts,
                        trainer_nik_ats,
                        trainee_name,
                        quota,
                        remark
                    }, i) => (
                        <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{type}</td>
                            <td>{course_name}</td>
                            <td>{trainer_nik_mts ? trainer_nik_mts : trainer_nik_ats}</td>
                            <td>{trainer_name_mts ? trainer_name_mts : trainer_name_ats}</td>
                            <td>{quota}</td>
                            <td>{trainee_name}</td>
                            <td>{start_date_ats}</td>
                            <td>{end_date_ats}</td>
                            <td>{start_date_mts}</td>
                            <td>{end_date_mts}</td>
                            <td>{remark}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    );
}

export default TableToHTML;