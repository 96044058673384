import moment from 'moment';
import React from 'react';
import { StatusIcon, TableList } from '../../../components';
import useSca, { ScaProvider } from './useSca';

function Index(props) {
    const { assAction, assState } = useSca();
    return (
        <div>

            <div className='row'>
                <div className='col-6'>
                    <section className="content-header">
                        <div className="container-fluid">
                            <h4>Schedule Assesment</h4>
                            <h6>List Training</h6>
                        </div>
                    </section>
                </div>
                <div className='col-6'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end">
                            <li className="breadcrumb-item active" aria-current="page">List Training</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <TableList
                        filter={true}
                        formsFilter={[
                            {
                                type: 'custom',
                                placeholder: 'Nama',
                                component: (
                                    <input type='number' className='form-control form-control-sm'
                                        value={assState?.batch}
                                        onChange={(val) => assAction.setBatch(val.target.value)}
                                    >
                                    </input>
                                )
                            }
                        ]}
                        labelFilter="Tahun IDP Register :"
                        submitButton={<><i className='fa fa-search'></i> Search</>}
                        onFilter={() => assAction.onFilterBatch()}
                        resetFilter={false}
                        // customToolbar={<div className='text-right mb-3'>
                        //     <button className='btn btn-sm btn-success'
                        //         onClick={() => htmlToExcel("table-to-xls", `${assState?.batch}_MTS_${moment(Date.now()).format("YYYY-MM-DD HH_mm_SS")}`, "statement")}
                        //     >
                        //         <BiFile style={{ fonstSize: '12pt' }} /> Export Excel
                        //     </button>
                        // </div>}
                        // resetFilter={false}
                        // onFilter={() => lsIdpAction.onFilter()}
                        curPage={1}
                        minHeight="300px"
                        maxHeight="500px"
                        navtable={"TABLE 1"}
                        withCard={false}
                        isLoading={assState.loadingTraining}
                        // onChangeShow={rekAction.changeShow}
                        // onNextPage={rekAction.nextPage}
                        // onPrevPage={rekAction.prevPage}
                        withActions={false}
                        columns={[
                            {
                                name: '#',
                                field: '#',
                                maxWidth: '50px',
                            },
                            {
                                name: "Jobsite",
                                field: 'jobsite',
                                minWidth: '100px',
                            },
                            {
                                name: 'Competency Name',
                                field: 'competency_name',
                                maxWidth: '220px',
                            },
                            {
                                name: 'Course Name',
                                field: 'course_name',
                                maxWidth: '250px',
                            },
                            {
                                name: 'Assessor',
                                field: 'trainer_name',
                                maxWidth: '150px',
                                onRender: (item) => {
                                    return (
                                        <span className='text-wrap'>{`${item?.trainer_name || ''} - ${item?.trainer_nik || ''}`}</span>
                                    )
                                }
                            },
                            {
                                name: 'Assessee',
                                field: 'trainees',
                                maxWidth: '300px',
                                onRender: (item) => {
                                    return (
                                        <div>
                                            {
                                                [...(item?.trainees || [])].slice(0, 5).map((d, i) => (
                                                    <span className='badge badge-info mr-1'>
                                                        {
                                                            d?.trainee_name
                                                        }
                                                    </span>
                                                ))
                                            }
                                            {
                                                [...(item?.trainees || [])].length > 5 && (
                                                    <div className='d-block'><span type='button' className="badge badge-warning"
                                                        onClick={() => raAction.openModalTrainee([...(item?.trainees || [])])}
                                                    >See Detail</span></div>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            },
                            {
                                name: 'Start Date',
                                field: 'start_date',
                                maxWidth: '150px',
                                onRender: (item) => (
                                    <span className='text-wrap'>{moment(item?.start_date).format('DD/MM/YYYY')}</span>
                                )
                            },
                            {
                                name: 'Finish',
                                field: 'end_date',
                                maxWidth: '150px',
                                onRender: (item) => (
                                    <span className='text-wrap'>{moment(item?.end_date).format('DD/MM/YYYY')}</span>
                                )
                            },
                            {
                                name: 'Status',
                                field: 'status',
                                maxWidth: '150px',
                                onRender: (item) => {
                                    return (
                                        <>
                                            <StatusIcon status={item?.status} />
                                            {/* {
                                                item?.status === 'draft' &&
                                                <span className="badge badge-primary text-capitalize">{(item?.status || '')}</span>
                                            }
                                            {
                                                item?.status === 'submitted' &&
                                                <span className="badge badge-success text-capitalize">{(item?.status || '')}</span>
                                            } */}
                                        </>
                                    )
                                }
                            },
                        ]}
                        data={[...(assState.listTraining || [])]}
                    />
                </div>
            </div>
        </div>
    );
}

export default (props) => <ScaProvider><Index {...props} /></ScaProvider>;