import moment from 'moment';
import React from 'react';
import { Form } from 'react-bootstrap';
import { DateInput, SelectSearch, TableList } from '../../../components';

function Index(props) {
    return (
        <div>
            <div className='card'>
                <div className='card-body'>
                    <h5 className='font-weight-bold text-center mb-5'>Form Create</h5>
                    <Form
                    // noValidate
                    // validated={crScState.validated}
                    // onSubmit={(e) => {
                    //     const form = e.currentTarget;
                    //     e.preventDefault(e);
                    //     e.stopPropagation(e);
                    //     if (form.checkValidity() === true) {
                    //         if (crScState.formData?.trainer_nik) {
                    //             crScAction.onSubmit();
                    //         }
                    //     }
                    //     crScAction.setValidated(true);
                    // }}
                    >
                        <div className='row'>
                            <div className='col-md-6 col-sm-12'>
                                <div className='form-group row'>
                                    <label className='col-sm-3'>Jobsite</label>
                                    <div className='col-sm-9'>
                                        <SelectSearch
                                            zIndex={2}
                                            // placeholder={crScState.loadingTrainer ? "Loading Data" : "Select Trainer"}
                                            // required={crScState.validated}
                                            // options={crScState?.dataTrainer}
                                            // onMenuOpen={crScAction.getTrainer}
                                            keyValue='nik'
                                            display='name'
                                        // isInValid={crScState.formData?.trainer_nik === '' && crScState.validated ? true : false}
                                        // value={crScState.formData?.trainer_nik}
                                        // onChange={(val) => crScAction.handleChange('trainer_nik', val.value)}
                                        />
                                        <div className="invalid-feedback">
                                            Please select trainer.
                                        </div>
                                    </div>
                                </div>
                                <div className='form-group row'>
                                    <label className='col-sm-3'>Trainer</label>
                                    <div className='col-sm-9'>
                                        <SelectSearch
                                            zIndex={2}
                                            // placeholder={crScState.loadingTrainer ? "Loading Data" : "Select Trainer"}
                                            // required={crScState.validated}
                                            // options={crScState?.dataTrainer}
                                            // onMenuOpen={crScAction.getTrainer}
                                            keyValue='nik'
                                            display='name'
                                        // isInValid={crScState.formData?.trainer_nik === '' && crScState.validated ? true : false}
                                        // value={crScState.formData?.trainer_nik}
                                        // onChange={(val) => crScAction.handleChange('trainer_nik', val.value)}
                                        />
                                        <div className="invalid-feedback">
                                            Please select trainer.
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='form-group row'>
                                    <label className='col-sm-3'>Kuota Training</label>
                                    <div className='col-sm-9'>
                                        <input
                                            required
                                            type='number'
                                            min={1}
                                            // max={
                                            //     (crScState.dataGet?.totalTrainee || 0) //- [...(crScState.dataTable || [])].reduce((tot, prev) => tot += toInteger(prev?.quota, 0), 0)
                                            // }
                                            className='form-control'
                                            // value={crScState.formData?.quota}
                                            onChange={(e) => crScAction.handleChange('quota', e.target.value)}
                                        ></input>
                                    </div>
                                </div> */}
                            </div>
                            <div className='col-md-6 col-sm-12'>
                                <div className='form-group row'>
                                    <label className='col-sm-3'>Start Date</label>
                                    <div className='col-sm-9'>
                                        <DateInput className='form-control'
                                            popperClassName='z-index-date-input'
                                            required
                                            // minDate={moment().format('YYYY-MM-DD')}
                                        // value={crScState.formData?.start_date}
                                        // onChange={(val) => crScAction.handleChange('start_date', val)}
                                        />
                                    </div>
                                </div>
                                <div className='form-group row'>
                                    <label className='col-sm-3'>Duration</label>
                                    <div className='col-sm-9'>
                                        <input type='number' className='form-control' min={1}
                                            required
                                        // value={crScState.formData?.duration}
                                        // onChange={(val) => crScAction.handleChange('duration', val.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='form-group row'>
                                    <label className='col-sm-3'>End Date</label>
                                    <div className='col-sm-9'>
                                        {/* <span className='text-muted p-2'>{moment(crScState.formData?.end_date).format('DD/MM/YYYY') === 'Invalid date' ? '' : moment(crScState.formData?.end_date).format('DD/MM/YYYY')}</span> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='text-md-right'>
                            <button type='submit' className='btn btn-sm btn-primary'>Add</button>
                        </div>
                    </Form>
                    <TableList
                        filter={true}
                        formsFilter={[
                            {
                                type: 'text',
                                placeholder: 'NIK',
                                // value: crtMtsState.filter?.trainee_nik,
                                // onChange: (val) => crtMtsAction.setFilter(e => ({ ...e, trainee_nik: val }))
                            },
                            {
                                type: 'text',
                                placeholder: 'Nama',
                                // value: crtMtsState.filter?.trainee_name,
                                // onChange: (val) => crtMtsAction.setFilter(e => ({ ...e, trainee_name: val }))
                            }
                        ]}
                        resetFilter={false}
                        // onFilter={() => crtMtsAction.onFilter()}
                        // isLoading={crtMtsState.loadingTrainee}
                        withCard={false}
                        minHeight="300px"
                        submitButton={<><i className='fa fa-search'></i> Search</>}
                        maxHeight="300px"
                        navtable={"TABLE 1"}
                        pagination={true}
                        withActions={false}
                        selectionMode={2}
                        // onSelection={(e) => crtMtsAction.handleChange('training_id', e)}
                        fieldSelectionKey="training_id"
                        // defaultSelection={[...(crtMtsState.formData?.training_id || [])]}

                        // onChangeShow={crtMtsAction.changeShow}
                        // onNextPage={crtMtsAction.nextPage}
                        // onPrevPage={crtMtsAction.onPrevPage}
                        // defaultShow={crtMtsState.pagination?.show}
                        // curPage={crtMtsState.pagination?.page}
                        // maxPage={crtMtsState.dataTrainee?.totalPage || 0}
                        // orderData={{
                        //     order_by: crtMtsState.pagination.order_by,
                        //     order_type: crtMtsState.pagination.order_type
                        // }}
                        // onOrdering={crtMtsAction.onOrdering}
                        // totalData={crtMtsState.dataTrainee?.totalData || 0}

                        columns={[
                            {
                                name: "#",
                                field: '#'
                            },
                            {
                                name: "Trainee NIK",
                                field: 'trainee_nik',
                            },
                            {
                                name: "Trainee Name",
                                field: 'trainee_name',
                            },
                        ]}
                        data={[]}
                    />
                </div>
            </div>
        </div>
    );
}

export default Index;