import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Sidemenu = ({
    hasChild = false,
    childs = [],
    name = "Nama Menu",
    url = undefined,
    icon,
    iconType = "css",
    active = false,
    badge,
    onClick = () => null,
    childMode = false
}) => {

    const location = useLocation();
    if (hasChild)
        return (
            <>
                <a role='button' style={{
                    paddingLeft: "5px"
                }} className={`nav-link ${childs.some(c => location.pathname.indexOf( c.url) > -1) ? "active" : ""}`}>

                    <div
                        title={name}
                        style={{
                            width: "70%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {
                            iconType === "css" ? <i className={`nav-icon ${icon}`}></i> : (
                                icon
                            )
                        }
                        {(location.pathname.indexOf( url) > -1) ? <b> {name} </b> : name}
                        {badge && badge}
                    </div>
                        <i className="right float-right fas fa-angle-left"></i>
                </a>
                <CreateChild
                    childs={childs}
                    active={active}
                />

            </>
        )

    return (
        <>

            <Link style={{
                ...(childMode ? {} : { paddingLeft: "5px" })
            }} to={url} className={`nav-link ${active ? "active" : ""}`}>
                <div
                    title={name}
                    style={{
                        width: "80%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        ...(
                            iconType === "css" && icon !== undefined && icon !== null && icon !== "" ? {} : { marginLeft: "10px" }
                        )
                    }}
                >
                    {
                        iconType === "css" && icon !== undefined && icon !== null && icon !== "" ? <i className={`nav-icon ${icon}`}></i> : (
                            icon
                        )
                    }
                    {(location.pathname.indexOf( url) > -1) ? <b> {name} </b> : name}
                    {badge && badge}
                </div>
            </Link>
        </>
    );
}

function CreateChild({
    childs
}) {

    const location = useLocation();
    return <ul className="nav nav-treeview">
        {
            childs.map((c, a) => (
                <li key={a} className="nav-item">
                    <Sidemenu {
                        ...{
                            ...c,
                            active: (location.pathname.indexOf( c.url) > -1),
                            name: c.name
                        }
                    }
                        childMode={true}
                    />
                </li>
            ))
        }
    </ul>

}
export default Sidemenu;

