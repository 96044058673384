import React, { createContext, useContext, useEffect, useState } from 'react'
import { useModalAlert } from '../../../components/modalAlert';
import useFetch from '../../../hooks/useFetch';

const idpStatusContext = createContext({
    idpStatState: {},
    idpStatAction: {}
});

export function IdpStatusProvider({ children }) {
    const [modalDetail, setModalDetail] = useState({
        show: false,
        data: {}
    });
    const [loadingGet, setLoadingGet] = useState(false)
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const { doGet, doPost } = useFetch();
    const [dataList, setDataList] = useState([]);
    const [batch, setBatch] = useState('');
    async function getDataList() {
        // openLoading("Get competency")
        setLoadingGet(true)
        try {
            const { status, statusText, payload } = await doGet({
                url: '/api/detail-regis-idp',
                param: {
                    batch
                }
            });
            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDataList(data.dataIDP || [])
                    setBatch(data?.batch || '');
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get IDP Status",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get IDP Status",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get detail register",
                callBack: () => null
            })
        }
        setLoadingGet(false)
    }
    function onFilter() {
        getDataList();
    }
    useEffect(function () {
        getDataList()
    }, [])

    return <idpStatusContext.Provider
        value={{
            idpStatState: {
                modalDetail,
                dataList,
                loadingGet,
                batch
            },
            idpStatAction: {
                setModalDetail,
                setBatch,
                onFilter
            }
        }}
    >{children}</idpStatusContext.Provider>
}

export default function useIdpStatus() {
    return useContext(idpStatusContext);
}