import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import useAtsDetail, { AtsDetailProvider } from './useAtsDetail';
import { StatusIcon } from '../../../../components';

const displayValueIniState = [
    { fieldName: 'type', caption: 'Type' },
    { fieldName: 'jobsite', caption: 'Jobsite' },
    { fieldName: 'cluster', caption: 'Cluster' },
    { fieldName: 'grade', caption: 'Grade' },
    { fieldName: 'group', caption: 'Group Competency' },
    { fieldName: 'competency_name', caption: 'Competency Name' },
    { fieldName: 'course_name', caption: 'Course Name' },
    { fieldName: 'trainer_name', caption: 'Trainer', onRender: (item) => <span className='text-wrap'>{`${item?.trainer_nik || ''} - ${item?.trainer_name || ''}`}</span> },
    { fieldName: 'trainee_name', caption: 'Trainee', onRender: (item) => <span className='text-wrap'>{`${item?.trainee_nik || ''} - ${item?.trainee_name || ''}`}</span> },
    { fieldName: 'start_date', caption: 'Start Date' },
    { fieldName: 'end_date', caption: 'End Date' },
    { fieldName: 'status', caption: 'Status' },
]

function ModalAtsDetail({
    id = '',
    show = false,
    type_page = 'ats_create',
    displayValue = [...displayValueIniState],
    getWithId = false,
    data = {}
}) {
    const { atsDetailAction, atsDetailState } = useAtsDetail();
    useEffect(() => {
        if (show && getWithId) {
            atsDetailAction.getDetail(id, type_page);
        }
        if (show && getWithId === false) {
            atsDetailAction.setDataDetail({ ...data });
        }
    }, [])
    return (
        <div>
            <form>
                <div className='row'>
                    <div className='col'>
                        {
                            [...(displayValue || [])].slice(0, (displayValue || []).length / 2).map(({
                                fieldName = '',
                                caption = '',
                                onRender
                            }, i) => (
                                <div className="form-group row">
                                    <label className="col-sm-5 col-form-label">{caption}</label>
                                    <div className="col-sm-7">
                                        {
                                            atsDetailState.loadingGet ? (
                                                <Skeleton height={'38px'} />
                                            ) : (
                                                <span type="text" readonly className="form-control-plaintext">{
                                                    onRender ? onRender(atsDetailState.dataDetail) : atsDetailState.dataDetail[fieldName]
                                                }</span>
                                            )
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className='col'>
                        {
                            [...(displayValue || [])].slice(-((displayValue || []).length / 2)).map(({
                                fieldName = '',
                                caption = '',
                                onRender
                            }, i) => (
                                <div className="form-group row">
                                    <label className="col-sm-5 col-form-label">{caption}</label>
                                    <div className="col-sm-7">
                                        {
                                            atsDetailState.loadingGet ? (
                                                <Skeleton height={'38px'} />
                                            ) : (
                                                <>
                                                {
                                                    fieldName === "status" ? (
                                                        <div className='d-flex h-100 w-100 align-items-center'>
                                                            <StatusIcon status={atsDetailState.dataDetail[fieldName]}/>
                                                        </div>
                                                    ):(
                                                        <span type="text" readonly class="form-control-plaintext">{
                                                            onRender ? onRender(atsDetailState.dataDetail) : atsDetailState.dataDetail[fieldName]
                                                        }</span>
                                                    )
                                                }
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </form>
        </div>
    );
}

export default (props) => <AtsDetailProvider><ModalAtsDetail {...props} /></AtsDetailProvider>;