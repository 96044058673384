import React from 'react';
import { StatusIcon, TableList } from '../../../components';
import useAssStatus, { AssStatProvider } from './useAssStatus';
import './index.css';

function Index(props) {
    const { assAction, assState } = useAssStatus();
    return (
        <div>
            <div className='row'>
                <div className='col-6'>
                    <section className="content-header">
                        <div className="container-fluid">
                            <h4>Assesment Status</h4>
                            <h6>List Training</h6>
                        </div>
                    </section>
                </div>
                <div className='col-6'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end">
                            <li className="breadcrumb-item active" aria-current="page">List Training</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <TableList
                filter={true}
                labelFilter="Tahun IDP Register :"
                formsFilter={[
                    {
                        type: 'custom',
                        component: (
                            <input type='number' className='form-control form-control-sm'
                                placeholder='Batch'
                                min={0}
                                value={assState?.batch}
                                onChange={(val) => assAction.setBatch(val.target.value)}
                            >
                            </input>
                        )
                    }
                ]}
                submitButton={<><i className='fa fa-search'></i> Search</>}
                onFilter={() => assAction.onFilter()}
                resetFilter={false}
                isLoading={assState.loadingGet}
                curPage={1}
                minHeight="300px"
                maxHeight="500px"
                navtable={"TABLE 1"}
                // onChangeShow={rekAction.changeShow}
                // onNextPage={rekAction.nextPage}
                // onPrevPage={rekAction.prevPage}
                withActions={true}
                actionType={1}
                actionItems={[
                    {
                        name: 'Lihat detail',
                        onClick: function (item) {
                            assAction.setModalDetail({
                                show: true,
                                data: { ...item }
                            });
                        }
                    }
                ]}
                columns={[
                    {
                        name: "#",
                        field: '#'
                    },
                    // {
                    //     name: "Grade",
                    //     field: 'grade'
                    // },
                    // {
                    //     name: "Cluster",
                    //     field: 'cluster'
                    // },
                    // {
                    //     name: "Group Competency",
                    //     field: 'competency_group'
                    // },
                    {
                        name: "Competency Name",
                        field: 'competency_name'
                    },
                    {
                        name: "Course Name",
                        field: 'course_name'
                    },
                    {
                        name: "Point",
                        field: 'point'
                    },
                    {
                        name: "Start",
                        field: 'start_date'
                    },
                    {
                        name: "Finish",
                        field: 'end_date'
                    },
                    {
                        name: "Assessor",
                        field: 'assessor',
                        onRender: ({ assessor_nik, assessor_name }) => {
                            return <span>
                                {assessor_nik} - {assessor_name}
                            </span>
                        }
                    },
                    {
                        name: "Result",
                        field: 'score',
                        minWidth: '100px',
                        onRender: (item) => {
                            return (
                                <span className={
                                    `text-capitalize badge badge-pill badge-${item?.score === 'competent' ? 'success' :
                                        item?.score === 'not_competent' ? 'danger' :
                                            'secondary'
                                    }`
                                }>
                                    {item?.score === "not_competent" ? "not competent" : item?.score}
                                </span>
                            )
                        }
                    },
                    // {
                    //     name: "Catatan",
                    //     field: 'note'
                    // },
                    {
                        name: 'Status Assessment',
                        field: 'assessment_status',
                        // maxWidth: '150px',
                        onRender: ({ assessment_status, score }) => {

                            return (
                                <StatusIcon status={assessment_status} />
                                // <span className={
                                //     `text-capitalize badge badge-pill
                                // badge-${assessment_status === "Passed" ? 'success' :
                                //         (assessment_status === "Failed") ? "danger" :
                                //             (assessment_status === "Not Available") ? "warning" :
                                //                 'info'}`
                                // }>
                                //     {assessment_status}
                                // </span>
                            )
                        }
                    },
                    {
                        name: "Note",
                        field: 'note'
                    }
                ]}
                data={[...(assState.dataList || [])]}
            />
        </div>
    );
}

export default (props) => <AssStatProvider><Index {...props} /></AssStatProvider>;
