import React, { createContext, useContext, useEffect, useState } from "react";
import 'anychart';

import useFetch from "../../../hooks/useFetch";
import { useModalAlert } from "../../../components/modalAlert";

const context = createContext({
    reportDtEmployeeAction: {},
    reportDtEmployeeState: {}
});

export function ReportDataEmployeeProvider({ children }) {
    const { modalAction } = useModalAlert();
    const {
        closeLoading,
        setDataAlert,
    } = modalAction;    

    const { doGet, doPost } = useFetch();
    const [dataList, setDataList] = useState([]);
    const [loadingGet, setLoadingGet] = useState(false);
    
    const [dataJobsite, setDataJobsite] = useState([]);
    const [jobsite_code, setJobsite_code] = useState([]);
    const [stream, setStream] = useState("Operator");

    async function getDataList(newJobsite) {
        setLoadingGet(true)
        try {
            const { status, payload, statusText } = await doPost({
                url: '/api/reporting/report-employee',
                data: { 
                    jobsite_code: (newJobsite ?? jobsite_code).map(d => d.jobsite_code), 
                    stream 
                }
            });
            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = [], code, msg } = payload;
                if (code === "00") {
                    setDataList(data.map(d => ({ x: d.grade, value: d.total_employee }))|| []);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Report Employee",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Report Employee",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Report Employee",
                callBack: () => null
            })
        }
        setLoadingGet(false)
    }

    async function getAllJobsite() {
        try {
            const { status, statusText, payload } = await doGet({
                url: '/api/list-all-jobsite',
            });
            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    let jobsiteList = await (data.jobsites ?? []).map(d => ({ ...d, value: d.jobsite_code, label: d.jobsite_name }) );
                    setDataJobsite(jobsiteList);
                    setJobsite_code([jobsiteList[0]]);
                    getDataList([jobsiteList[0]]);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get All Jobsite",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get All Jobsite",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get All Jobsite",
                callBack: () => null
            })
        }
    }

    function onFilter() {
        getDataList();
    }

    useEffect(function () {
        getAllJobsite();
    }, []);

    return <context.Provider
        value={{
            reportDtEmployeeAction: {
                onFilter,
                setJobsite_code,
                setStream,
                getDataList
            },
            reportDtEmployeeState: {
                dataJobsite,
                jobsite_code,
                stream,
                dataList,
                loadingGet
            }
        }}
    >{children}</context.Provider>
}

export default function useReportDataEmployee() {
    return useContext(context);
}