import React from 'react';
import { Link } from 'react-router-dom';
import * as pages from './pages';
import useTirOnline, { TirProvider } from './useTirOnline';

function Index(props) {
    const { tirAction, tirState } = useTirOnline();
    const PageRender = pages[tirState.pageState.type];
    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <div className='row'>
                        <div className='col'>
                            <h4>Technical Information Report (TIR) Online</h4>
                            <h6>{
                                tirState.pageState.title
                            }</h6>
                        </div>
                        <div className='col'>
                            {
                                tirState.pageState.type !== "TableList" ? (
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb justify-content-end">
                                            <li className="breadcrumb-item" onClick={() => tirAction.formBackButton(tirState.imageChanged)}><Link to={"#"}>List Assignment</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">{tirState.pageState.title}</li>
                                        </ol>
                                    </nav>
                                ) :(
                                    <nav aria-label="breadcrumb">
                                                    <ol className="breadcrumb justify-content-end">
                                                        <li className="breadcrumb-item active" aria-current="page">{tirState.pageState.title}</li>
                                                    </ol>
                                                </nav>
                                )
                            }
                        </div>
                    </div>
                </div>
            </section>
            <PageRender />
        </>
    );
}

export default (props) => <TirProvider><Index {...props} /></TirProvider>;