import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import useMatrixComp, { MatrixCompProvider } from './useMatrixComp';
import { TableList } from '../../../components';

function TableCourse(props) {
    const { matrixCompAction, matrixCompState } = useMatrixComp();
    const history = useHistory();

    const CustomToolbar = () => (
        <div className='tw-flex tw-justify-end'>
            <Link to={`/master-data/course-matrix-competency/add?id=${matrixCompState.dataDetail?.id}`}>
                <button className='btn btn-sm btn-success'>Create</button>
            </Link>
        </div>
    )

    return (
        <>
            <div className="row mt-4 tw-py-0">
                <div className="col-md-12 tw-py-0">
                    <div className="card">
                        <div className="card-body">
                            <TableList
                                filter={true}
                                data={matrixCompState.dataDetail?.courses || []}
                                labelFilter=""
                                withCard={false}
                                resetFilter={false}
                                isLoading={matrixCompState.loadingList}
                                minHeight="300px"
                                maxHeight="500px"
                                navtable={"TABLE 1"}
                                customToolbar={<CustomToolbar />}
                                withButtonSubmit={false}
                                pagination={false}
                                withActions={true}
                                actionType={1}
                                actionItems={[
                                    {
                                        name: "Lihat detail",
                                        onClick: function (item) {
                                            history.push(`/master-data/course-matrix-competency/detail?id=${matrixCompState.dataDetail?.id}&&cid=${item.id}`)
                                        },
                                    },
                                    {
                                        name: "Ubah",
                                        onClick: function (item) {
                                            history.push(`/master-data/course-matrix-competency/ubah?id=${matrixCompState.dataDetail?.id}&&cid=${item.id}`)
                                        },
                                    },
                                    {
                                        name: "Hapus",
                                        onClick: function (item) {
                                            matrixCompAction.doActCourse(item.id)
                                        },
                                    },
                                ]}
                                columns={[
                                    {
                                        name: "#",
                                        field: "#",
                                    },
                                    {
                                        name: "Competency Name",
                                        field: "competency_name",
                                    },
                                    {
                                        name: "Cource Name",
                                        field: "name",
                                    },
                                    {
                                        name: "Type",
                                        field: "type",
                                    },
                                ]}
                            />
                        </div>
                        <div className="card-footer"></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default () => <MatrixCompProvider><TableCourse /></MatrixCompProvider>;
