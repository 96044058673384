import moment from "moment";
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useModalAlert } from "../../../components/modalAlert";
import useFetch from "../../../hooks/useFetch";
import ModalCreate from "./component/modal-create";
import ModalTrainee from "./component/modal-trainee";

const crtSchContext = createContext({
    crtSchState: {},
    crtSchAction: {}
});
const iniStatePage = {
    title: "List Training",
    type: "ListTraining",
    dataPage: {}
};
const iniStateModalCreate = {
    show: false,
    modeEdit: false,
    data: {}
}
const iniStateModalTrainee = {
    show: false,
    data: [],
    dataFilter: [],
    search: ''
}
const iniStateFormData = {
    "jobsite_code": "",
    "start_date": "",
    "end_date": "",
    "training_id": [],
    "trainer_nik": "",
    "deleted_training_ids": []
}
export function CrtSchProvider({ children }) {
    const [pageState, setPageState] = useState(iniStatePage);
    const [formData, setFormData] = useState(iniStateFormData);
    const { doGet, doPost } = useFetch()
    const [loadingTraining, setLoadingTraining] = useState(false)
    const [listTraining, setListTraining] = useState([]);
    const [loadingTrainee, setLoadingTrainee] = useState(false)
    const [listTrainee, setListTrainee] = useState([]);
    const [loadingTrainer, setLoadingTrainer] = useState(false)
    const [listTrainer, setListTrainer] = useState([]);
    const [loadingJobsite, setLoadingJobsite] = useState(false)
    const [listJobsite, setListJobsite] = useState([]);
    const [modalCreate, setModalCreate] = useState(iniStateModalCreate);
    const [modalTrainee, setModalTrainee] = useState(iniStateModalTrainee);
    const [batch, setBatch] = useState();
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;

    const toFormPage = useCallback(function (detailPage, modeEdit = false) {
        setPageState({
            title: modeEdit ? "Form Update" : "Form Create",
            type: "FormCreate",
            dataPage: {
                detail: { ...detailPage },
                modeEdit: modeEdit
            }
        })
    }, [pageState]);
    const toListPage = useCallback(function () {
        setPageState({ ...iniStatePage });
    });
    const getListTraining = useCallback(async function () {
        setLoadingTraining(true);

        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/assessment/training-list',
                    param: {
                        batch : batch || ''
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setListTraining(data?.dataTraining || []);
                    setBatch(data?.batch);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get List Training",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List Training",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Training",
                callBack: () => null
            })
        }
        setLoadingTraining(false)
    }, [pageState, batch]);
    const [filter, setFilter] = useState({name:'', nik:''});
    const getListTrainee = useCallback(async function (competency_id = '', course_id = '') {
        setLoadingTrainee(true);

        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/assessment/trainee-list',
                    param: {
                        competency_id,
                        course_id,
                        batch: batch || '',
                        ...filter
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setListTrainee(data?.listTrainee || []);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get List Trainee",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List Trainee",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Trainee",
                callBack: () => null
            })
        }
        setLoadingTrainee(false)
    }, [pageState, batch, filter]);
    const getTrainer = useCallback(async function (jobsite_code = '2002', course_type = '') {
        try {
            setLoadingTrainer(true);
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/ats/list-trainer',
                    param: {
                        jobsite_code,
                        course_type
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = [], code, msg } = payload;
                if (code === "00") {
                    setListTrainer((data || []).map(d => ({
                        nik: d?.nik,
                        name: `${d?.nik} - ${d?.name}`
                    })));
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Data Trainer",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Data Trainer",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Data Trainer",
                callBack: () => null
            })
        }
        setLoadingTrainer(false)
    }, [])
    const getJobsite = useCallback(async function () {
        try {
            setLoadingJobsite(true);
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/assessment/jobsite-list',
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setListJobsite((data?.jobsites || []));
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Data Jobsite",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Data Jobsite",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Data Trainer",
                callBack: () => null
            })
        }
        setLoadingJobsite(false)
    }, [])
    const doSave = useCallback(async function (data = {}) {
        closeConfirm()
        openLoading("Sending data, please wait...");
        try {
            const { status, payload, statusText } = await doPost({
                url: "/api/assessment/store",
                data: {
                    ...data
                }
            });

            if (status !== 401) {
                if (status === 200 || status === 201) {
                    const { code, msg } = payload;
                    if (code === "00") {
                        setDataAlert({
                            body: msg,
                            show: true,
                            header: "Success",
                            callBack: () => {
                                closeModalCreate();
                                getListTraining();
                            }
                        })
                    } else setDataAlert({
                        show: true,
                        body: msg,
                        header: "Info",
                        callBack: () => null
                    })
                } else setDataAlert({
                    show: true,
                    body: statusText,
                    header: "Info",
                    callBack: () => null
                })
            }
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Info",
                callBack: () => null
            })
        }
        closeLoading();
    }, []);

    const openModalCreate = (data = {}, modeEdit = false) => {
        setModalCreate({
            show: true,
            modeEdit: modeEdit,
            data: { ...data }
        })
    }
    const closeModalCreate = () => {
        setModalCreate({ ...iniStateModalCreate });
        setFormData({ ...iniStateFormData });
        setFilter({name:'', nik:''})
    }
    const openModalTrainee = (data = []) => {
        setModalTrainee({
            show: true,
            data: data,
            dataFilter: data,
            search: ''
        })
    }
    const closeModalTrainee = () => {
        setModalTrainee({ ...iniStateModalTrainee });
    }
    const onFilterModalTrainee = () => {
        let newData = [];
        newData = modalTrainee.data.filter((e) => {
            return (e.trainee_name || '').toLowerCase().indexOf(modalTrainee.search.toLowerCase()) > -1 || (e.trainee_nik || '').toLowerCase().indexOf(modalTrainee.search.toLowerCase()) > -1
        });
        setModalTrainee((e) => ({
            ...e,
            dataFilter: newData
        }));
    }
    const handleChange = (name = '', val = '') => {
        if (name === 'jobsite_code') {
            setFormData((e) => (
                {
                    ...e,
                    [name]: val,
                    "trainer_nik": ''
                }
            ))
        } else {
            setFormData((e) => (
                {
                    ...e,
                    [name]: val
                }
            ))
        }
    }
    const confirmSave = useCallback(function (data = {}) {
        var dataKirim = {
            ...data,
            deleted_training_ids: modalCreate?.modeEdit ? [...(modalCreate?.data?.trainees || [])].filter(d => ![...(data?.training_id || [])].some(x => x === d?.training_id)).map(y => y.training_id).filter(z => z !== null) : []
        }
        // console.log(modalCreate, dataKirim)
        const val = validationData({
            ...dataKirim
        });
        if (val.isValid) {
            setDataConfirm({
                show: true,
                body: "Apa anda yakin?",
                witTextArea: false,
                header: "Confirmation",
                title: "",
                closeButton: false,
                footerButton: [
                    {
                        name: "Yes", onClick: () => {
                            doSave(dataKirim)
                        }, className: "btn btn-sm btn-success"
                    },
                    { name: "No", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
                ]
            })
        } else {
            return setDataAlert({
                show: true,
                body: val.message,
                header: "Validation Save",
                callBack: () => null
            })
        }
    }, [modalCreate]);
    const validationData = (data) => {
        var message = [];
        var messageCs = '';
        var isValid = true;
        if (data?.trainer_nik === '' || data?.trainer_nik === null || data?.trainer_nik === undefined) {
            message = [...message, 'Trainer'];
            isValid = false;
        }
        if (data?.start_date === '' || data?.start_date === 'Invalid date' || data?.start_date === null || data?.start_date === undefined) {
            message = [...message, 'Start date'];
            isValid = false;
        }
        if (data?.end_date === '' || data?.end_date === 'Invalid date' || data?.end_date === null || data?.end_date === undefined) {
            message = [...message, 'End date'];
            isValid = false;
        }
        if ([...(data?.training_id || [])].length <= 0) {
            message = [...message, 'Trainee'];
            isValid = false;
        }
        if (isValid && moment(data?.end_date).isBefore(moment(data?.start_date))) {
            messageCs = 'Start date tidak boleh lebih dari end date';
            isValid = false;
        }
        return {
            isValid,
            message: messageCs ? messageCs : message.join(', ') + ' Tidak boleh kosong'
        }
    }
    const onFilter = () => {
        getListTraining();
    }
    const onFilterTrainee = () => {
        getListTrainee(modalCreate?.data?.competency_id, modalCreate.data?.course_id);
    }
    useEffect(() => {
        if (pageState.type === 'ListTraining') getListTraining();
    }, [pageState]);
    useEffect(() => {
        if (modalCreate.show === true) {
            if (modalCreate.modeEdit) {
                getJobsite();
                getTrainer(modalCreate?.data?.jobsite_code, modalCreate?.data?.course_type)
            }
            getListTrainee(modalCreate?.data?.competency_id, modalCreate.data?.course_id);
            setFormData({
                jobsite_code: modalCreate.data?.jobsite_code,
                start_date: modalCreate.data?.start_date,
                end_date: modalCreate.data?.end_date,
                trainer_nik: modalCreate?.data?.trainer_nik,
                training_id: [...(modalCreate.data?.trainees || [])].map(d => d?.training_id),
                deleted_training_ids: []
            })
        }
    }, [modalCreate]);
    return <crtSchContext.Provider
        value={{
            crtSchState: {
                pageState,
                loadingTraining,
                listTraining,
                modalCreate,
                loadingTrainee,
                listTrainee,
                loadingTrainer,
                listTrainer,
                loadingJobsite,
                listJobsite,
                formData,
                modalTrainee,
                batch,
                filter
            },
            crtSchAction: {
                toFormPage,
                toListPage,
                openModalCreate,
                closeModalCreate,
                getTrainer,
                handleChange,
                getJobsite,
                confirmSave,
                openModalTrainee,
                closeModalTrainee,
                setModalTrainee,
                onFilterModalTrainee,
                setBatch,
                onFilter,
                setFilter,
                onFilterTrainee
            }
        }}
    >
        {children}
        <ModalCreate />
        <ModalTrainee />
    </crtSchContext.Provider>
}
export default function useCrtSch() {
    return useContext(crtSchContext);
}