export default function (state = {}, action) {
    const { payload, type } = action;
    switch (type) {
        case "setState":
            return {
                ...state,
                ...payload
            }
        case "setLoading":
            return {
                ...state,
                ...payload
            }
        case "setNotification":
            return {
                ...state,
                ...payload
            }
        case "getSuccess":
            return {
                ...state,
                ...payload,
                errFetch: {
                    status: false,
                    msg: ""
                }
            }
        case "fetchError":
            return {
                ...state,
                errFetch: {
                    status: true,
                    msg: payload
                }
            }
        case "resetFilter":
            return {
                ...state,
                filter: {
                    country: "id",
                    apikey: "6a0230af7ed84bd897a31f6cbfeb5782"
                }
            }
        case "resetErrorFetch":
            return {
                ...state,
                errFetch: {
                    status: false,
                    msg: ""
                }
            }
        default:
            throw `Type '${type}' of dispatch not match`;
    }
}