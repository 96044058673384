import { TableList } from "../../../components";
import useAttRatio from "../useAttRatio";

export default function () {
    const {
        attAction: {
         toPage=()=>null,
        },
        attState: {
            pageStatus: { isLoading = true },
            dataJobsite,
        },
    } = useAttRatio();

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">List Jobsite</div>
                        </div>
                        <div className="card-body">
                            <TableList
                                withCard={false}
                                withActions={false}
                                pagination={false}
                                isLoading={isLoading}
                                columns={[
                                    {
                                        name: "#",
                                        field: "#",
                                    },
                                    {
                                        name: "jobsite",
                                        field: "jobsite_name",
                                    },
                                    {
                                        name: "Action",
                                        field: "",
                                        className:"text-right",
                                        maxWidth: '50px',
                                        minWidth: '50px',
                                        onRender: ({jobsite_code,jobsite_name }) => (
                                          <button onClick={()=>toPage("ListAR", "Attended Ratio List", {jobsite_code, jobsite_name})} className="btn btn-success btn-sm">Lihat</button>
                                        )
                                    },
                                ]}
                                data={dataJobsite}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
