import moment from 'moment';
import React from 'react';
import { TableList } from '../../../components';
import usePHMO, { PHMOProvider } from './usePHMO';
import * as pages from './pages'

function PHMOPage() {
    const { 
        PHMOState : {
            pageStatus:{
                rendered
            }
        }
    } = usePHMO()

    const PageRendered = pages[rendered]
    return <>
        <PageRendered />
    </>
}

export default () => <PHMOProvider>
    <PHMOPage />
</PHMOProvider>