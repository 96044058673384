import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useAuth } from '../../context';
import userdummy from '../images/userdummy.png'

const SideUser = () => {
    const { userInfo, loadingUserInfo } = useAuth()
    return (
        <>
            <div className='user-panel mt-3 pb-3'
                style={{
                    wordWrap: "break-word",
                    padding: 10,
                    border: "none !important"
                }}
            >
                <div className='' style={{
                    width: "100%",
                    textAlign: "center"
                }}>
                    {loadingUserInfo ? <Skeleton circle={true} style={{margin:15}} height={140} width={140} /> : <img src={userdummy} style={{
                        width: "140px",
                        background: "#DAE3F3"
                    }} className='img-circle' />}
                </div>
                <div className='text-center text-base w-100 pt-3' style={{
                    overflowWrap: "break-word",
                    display: "inline-block",
                    whiteSpace: "nowrap"
                }}>
                    {
                        loadingUserInfo ? <Skeleton height={20} style={{margin:15}} width={"70%"} />
                    : <h6 className='text-wrap'>
                        {userInfo.name}
                    </h6>
                    }
                    {
                        loadingUserInfo ? <div><Skeleton height={15} style={{margin:15}} width={"50%"} /></div>
                    : <span className='text-wrap'>{userInfo.job_title || ""}</span>
                    }
                </div>
            </div>
        </>
    );
}

export default SideUser;