import React from 'react';
import { TableList } from '../../../../components';
import useMentoring from '../useMentoring';

function Index(props) {
    const { menState: { loadingCom = false, listCom = [], pageState }, menAction: {
        toFormPage = () => null,
        toListTraineePage = () => null
    } } = useMentoring();
    return (
        <div className='card'>
            <div className='card-body'>
                <div className='row mb-3'>
                    <div className='col-md-12 col-sm-12'>
                        <TableList
                            isLoading={loadingCom}
                            data={[...listCom]}
                            withCard={false}
                            columns={[
                                {
                                    name: '#',
                                    field: '#',
                                },
                                // {
                                //     name: 'Competency',
                                //     field: 'competency_name',
                                // },
                                {
                                    name: 'Course Name',
                                    field: 'course_name',
                                },
                                {
                                    name: 'Form Name',
                                    field: 'form_name',
                                },
                                {
                                    name: 'Aksi',
                                    field: '',
                                    minWidth: 200,
                                    maxWidt: 200,
                                    // className: 'text-right',
                                    onRender: (item) => (
                                        <div>
                                            {/* <ul>
                                                {
                                                    [...(item?.listForm || [])].map((d, i) => (
                                                        <>
                                                            <li className='text-primary' role={'button'}
                                                            onClick={() => tirAction.toFormPage({ ...d, training_id: item?.training_id }, true)}
                                                            >{d?.title} <span className={
                                                                `text-capitalize badge badge-pill badge-${d?.status === 'approved' ? 'success' :
                                                                    d?.status === 'declined' ? 'danger' :
                                                                        'primary'
                                                                }`
                                                            }>
                                                                    {d?.status}
                                                                </span></li>
                                                        </>
                                                    ))
                                                }
                                            </ul> */}
                                            <button
                                                // onClick={() => toListTraineePage({ competency_id: item?.competency_id })}
                                                onClick={() => toFormPage({ ...pageState, ...item })}
                                                className='btn btn-sm btn-success'>
                                                Isi Form
                                            </button>
                                        </div>
                                    )
                                },
                            ]}
                            withActions={false}
                            pagination={false}
                        />
                        <button className='btn btn-sm btn-secondary mt-3'
                            onClick={toListTraineePage}>Back</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;