import React from 'react';
import useLsIdp from './list-idp/useLsIdp';
// import useAtsStatus from './useAtsStatus';

const TableToHTML = () => {
    // const { lsIdpState } = useAtsStatus()
    const { lsIdpState } = useLsIdp();
    return (
        <table className="w-100 f-10" id="table-to-xls" style={{ display: "none" }}>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Category Training</th>
                    {/* <th>Jobsite</th> */}
                    {/* <th>Cluster</th>
                    <th>Grade</th>
                    <th>Group Competency</th>
                    <th>Competency Name</th> */}
                    <th>Training Name</th>
                    <th>Trainer NIK</th>
                    <th>Trainer Name</th>
                    <th>Kuota Training</th>
                    <th>Start</th>
                    <th>Finish</th>
                    {/* <th>Status</th> */}
                </tr>
            </thead>
            <tbody>
                {
                    (lsIdpState.dataGet?.atsAll || []).map(({
                        ats_id,
                        // cluster,
                        // competency_name,
                        category,
                        course_name,
                        end_date,
                        // grade,
                        // group,
                        // jobsite,
                        start_date,
                        //status,
                        // trainee_name,
                        trainer_name,
                        trainer_nik,
                        // training_id,
                        quota,
                    }, i) => (
                        <tr key={i}>
                            <td>{i + 1}</td>
                            {/* <td>{type}</td>
                            <td>{jobsite}</td>
                            <td>{cluster}</td>
                            <td>{grade}</td>
                            <td>{group}</td> */}
                            <td>{category}</td>
                            <td>{course_name}</td>
                            <td>{trainer_nik}</td>
                            <td>{trainer_name}</td>
                            <td>{quota}</td>
                            <td>{start_date}</td>
                            <td>{end_date}</td>
                            {/* <td>{status}</td> */}


                        </tr>
                    ))
                }
            </tbody>
        </table>
    );
}

export default TableToHTML;