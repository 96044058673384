import React from 'react';
import { Link } from 'react-router-dom';
import * as pages from './pages';
import useTirRev, { TirRevProvider } from './useTirRev';

function Index(props) {
    const { tirRevAction, tirRevState } = useTirRev();
    const PageRender = pages[tirRevState.pageState.type];
    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <div className='row'>
                        <div className='col-md-6'>
                            <h4>Review Technical Information Report (TIR) Online</h4>
                            <h6>{
                                tirRevState.pageState.title
                            }</h6>
                        </div>
                        <div className='col-md-6'>
                            {
                                tirRevState.pageState.type !== "TableList" ? (
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb justify-content-end">
                                            <li className="breadcrumb-item" onClick={() => tirRevAction.toListPage()}><Link to={"#"}>List Assignment</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">{tirRevState.pageState.title}</li>
                                        </ol>
                                    </nav>
                                ):(
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb justify-content-end">
                                            <li className="breadcrumb-item active" aria-current="page">{tirRevState.pageState.title}</li>
                                        </ol>
                                    </nav>
                                )
                            }
                        </div>
                    </div>
                </div>
            </section>
            <PageRender />
        </>
    );
}

export default (props) => <TirRevProvider><Index {...props} /></TirRevProvider>;