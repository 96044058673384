import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { TableList } from '../../../components';
import IdpApprovalDetail from './idp-approval-detail';
import useIdpApproval, { IdpApprovalProvider } from './useIdpApproval';
import './idp-approval.css';
import { useHistory } from 'react-router-dom';

function Index(props) {
    const { idpAppAction, idpAppState } = useIdpApproval();
    const history = useHistory();

    useEffect(() => {
        idpAppAction.getDataJunior();
    }, [])

    return (
        <div>
            <div className='row'>
                <div className='col-6'>
                    <section className="content-header">
                        <div className="container-fluid">
                            <h4>IDP Online</h4>
                            <h6>IDP Approval</h6>
                        </div>
                    </section>
                </div>
                <div className='col-6'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end">
                            <li className="breadcrumb-item active" aria-current="page">IDP Approval</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <TableList
                curPage={1}
                minHeight="300px"
                maxHeight="500px"
                navtable={"TABLE 1"}
                filter={true}
                formsFilter={[
                    { 
                        type: 'custom',
                        placeholder: 'Batch',
                        component: (
                            <input type='number' className='form-control form-control-sm'
                                placeholder='Batch'
                                value={idpAppState?.batch}
                                onChange={(val) => idpAppAction.setBatch(val.target.value)}
                            >
                            </input>
                        )
                    }
                ]}
                labelFilter="Tahun IDP Register :"
                submitButton={<><i className='fa fa-search'></i> Search</>}
                resetFilter={false}
                onFilter={() => idpAppAction.getDataJunior()}
                isLoading={idpAppState.loadingGet}
                // onChangeShow={rekAction.changeShow}
                // onNextPage={rekAction.nextPage}
                // onPrevPage={rekAction.prevPage}
                withActions={true}
                actionType={1}
                actionItems={[
                    {
                        name: 'Lihat detail',
                        onClick: function (item) {
                            history.push(`/idp-online/approval/detail/${item?.nik || ''}`, { ...item})
                        }
                    }
                ]}
                columns={[
                    {
                        name: '#',
                        field: '#',
                        maxWidth: '50px',
                    },
                    {
                        name: 'NIK',
                        field: 'nik',
                        maxWidth: '150px',
                    },
                    {
                        name: 'Nama',
                        field: 'name',
                        maxWidth: '250px',
                    },
                    {
                        name: 'Jabatan',
                        field: 'job_title',
                        maxWidth: '150px',
                    },
                    {
                        name: 'Grade',
                        field: 'grade',
                        maxWidth: '250px',
                    },
                    {
                        name: '',
                        field: 'has_new_notif',
                        maxWidth: '10px',
                        onRender: (item, i) => {
                            return (
                                <div>
                                    {
                                        item?.has_new_notif === true ?
                                            <span className="badge badge-primary">New</span>
                                            :
                                            <></>
                                    }
                                </div>
                            )
                        }
                    },
                ]}
                data={[...(idpAppState.dataJun || [])]}
            />
            {/* <Modal
                dialogClassName='cs-modal'                
                show={idpAppState.showDetail} onHide={() => idpAppAction.setShowDetail(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Approval Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <IdpApprovalDetail />
                </Modal.Body>
            </Modal> */}
        </div>
    );
}

export default (props) => <IdpApprovalProvider><Index {...props} /></IdpApprovalProvider>;