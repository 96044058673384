import React from 'react';
import { DateInput } from '../../../components';

import useReportDataStartTraining, { ReportDataStartTrainingProvider } from './useReportDataStartTraining';

import ChartData from './chart-data';

function Index(props) {
    const { reportDtStartTrainingAction, reportDtStartTrainingState } = useReportDataStartTraining();

    return (
        <div>
            <div className='row'>
                <div className='col-6'>
                    <section className="content-header">
                        <div className="container-fluid">
                            <h4>Report Data</h4>
                            <h6>Presentase Pencapaian Pelaksanaan Training yang Sudah Berjalan</h6>
                        </div>
                    </section>
                </div>
                <div className='col-6'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end">
                            <li className="breadcrumb-item active" aria-current="page">
                                Presentase Pencapaian Pelaksanaan Training yang Sudah Berjalan
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>

            {/*  */}
            <div className="card pb-2">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <span>Sebaran data berdasarkan :</span>
                        </div>
                    </div>

                    <div className="row mb-1">
                        <div className="col-md col-lg col-sm-6">
                            <div className="form-group row mb-1">
                                <label htmlFor="inputEmail3" className="col-sm-3 font-weight-normal">Stream</label>
                                <div className="col-sm-9">
                                    <select className={`form-select ${reportDtStartTrainingState?.stream === "" ? "border border-danger" : ""}`}
                                        value={reportDtStartTrainingState?.stream}
                                        onChange={(val) => reportDtStartTrainingAction.setStream(val.target.value)}
                                    >
                                        {
                                            ["Operator", "Mechanic", "Pengawas"].map((d, i) => (
                                                <option value={d} key={i}>{d}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="form-group row mb-1">
                                <label htmlFor="inputEmail3" className="col-sm-3 font-weight-normal">Start Date</label>
                                <div className="col-sm-9">
                                    <DateInput className='form-control'
                                        required
                                        value={reportDtStartTrainingState?.start_date}
                                        onChange={(val) => reportDtStartTrainingAction.setStartDdate(val)}
                                    />
                                </div>
                            </div>

                            <div className="form-group row mb-1">
                                <label htmlFor="inputEmail3" className="col-sm-3 font-weight-normal">End Date</label>
                                <div className="col-sm-9">
                                    <DateInput className='form-control'
                                        required
                                        value={reportDtStartTrainingState?.end_date}
                                        onChange={(val) => reportDtStartTrainingAction.setEndDate(val)}
                                    />
                                </div>
                            </div>

                            <div className="form-group row mb-1 mt-4">
                                <div className='d-flex justify-content-end h-100'>
                                    <button className='btn btn-sm btn-success mt-auto'
                                        style={{ height: 'fit-content' }}
                                        disabled={
                                            reportDtStartTrainingState.loadingGet ||
                                            reportDtStartTrainingState?.stream === "" ||
                                            reportDtStartTrainingState?.start_date === "" ||
                                            reportDtStartTrainingState?.end_date === ""
                                        }
                                        onClick={() => reportDtStartTrainingAction.onFilter()}
                                    >
                                        {reportDtStartTrainingState.loadingGet ? "Mohon menunggu" : "Show Chart" }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ChartData />
            
        </div>
    );
}

export default () => <ReportDataStartTrainingProvider><Index /></ReportDataStartTrainingProvider>;