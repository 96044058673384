import React from 'react';
import Select from 'react-select'

import useReportDataEmployee, { ReportDataEmployeeProvider } from './useReportDataEmployee';

import ChartData from './chart-data';

function Index(props) {
    const { reportDtEmployeeAction, reportDtEmployeeState } = useReportDataEmployee();

    return (
        <div>
            <div className='row'>
                <div className='col-6'>
                    <section className="content-header">
                        <div className="container-fluid">
                            <h4>Report Data</h4>
                            <h6>Sebaran Karyawan</h6>
                        </div>
                    </section>
                </div>
                <div className='col-6'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end">
                            <li className="breadcrumb-item active" aria-current="page">
                                Sebaran Karyawan
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>

            {/*  */}
            <div className="card pb-2">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <span>Sebaran data berdasarkan :</span>
                        </div>
                    </div>

                    <div className="row mb-1">
                        <div className="col-md col-lg col-sm-6">
                            <div className="form-group row mb-1">
                                <label htmlFor="inputEmail3" className="col-sm-3 font-weight-normal">Jobsite</label>
                                <div className="col-sm-9">
                                    <Select
                                        className={reportDtEmployeeState?.jobsite_code.length === 0 ? "border border-danger" : ""}
                                        options={[...(reportDtEmployeeState.dataJobsite || [])]} 
                                        value={reportDtEmployeeState?.jobsite_code}
                                        isMulti
                                        onChange={reportDtEmployeeAction.setJobsite_code}
                                    />
                                </div>
                            </div>

                            <div className="form-group row mb-1">
                                <label htmlFor="inputEmail3" className="col-sm-3 font-weight-normal">Stream</label>
                                <div className="col-sm-9">
                                    <select className={`form-select ${reportDtEmployeeState?.stream === "" ? "border border-danger" : ""}`}
                                        value={reportDtEmployeeState?.stream}
                                        onChange={(val) => reportDtEmployeeAction.setStream(val.target.value)}
                                    >
                                        {
                                            ["Operator", "Mechanic", "Pengawas"].map((d, i) => (
                                                <option value={d} key={i}>{d}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>

                            
                            <div className="form-group row mb-1 mt-4">
                                <div className='d-flex justify-content-end h-100'>
                                    <button className='btn btn-sm btn-success mt-auto'
                                        style={{ height: 'fit-content' }}
                                        disabled={
                                            reportDtEmployeeState.loadingGet ||
                                            reportDtEmployeeState?.jobsite_code.length === 0 ||
                                            reportDtEmployeeState?.stream === ""
                                        }
                                        onClick={() => reportDtEmployeeAction.onFilter()}
                                    >
                                        {reportDtEmployeeState.loadingGet ? "Mohon menunggu" : "Show Chart" }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ChartData />
            
        </div>
    );
}

export default () => <ReportDataEmployeeProvider><Index /></ReportDataEmployeeProvider>;