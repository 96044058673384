import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { TableList } from '../../../../components';
import useLsIdp, { LsIdpProvider } from './useLsIdp';

function Index(props) {
    const history = useHistory();
    const { lsIdpState, lsIdpAction } = useLsIdp();
    return (
        <div>
            <section className="content-header">
                <div className="container-fluid">
                    <div className='row'>
                        <div className='col-md-6'>
                            <h4>Create Annual Training Schedule</h4>
                            <h6>List IDP</h6>
                        </div>
                        <div className='col-md-6'>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-end">
                                    <li className="breadcrumb-item"><Link to={"/ats/ats-create"}>List Jobsite</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">List IDP</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <div className='card'>
                <div className='card-body'>
                    <div className='font-weight-bold mb-5'><label style={{ width: '150px' }}>Nama Jobsite</label>: {lsIdpState.dataState?.jobsite_name}</div>
                    <TableList
                        filter={true}
                        formsFilter={[
                            {
                                type: 'custom',
                                placeholder: 'Nama',
                                component: (
                                    <input type='number' className='form-control form-control-sm'
                                        value={lsIdpState?.batch}
                                        onChange={(val) => lsIdpAction.setBatch(val.target.value)}
                                    >
                                    </input>
                                )
                            },
                            {
                                type: 'custom',
                                placeholder: 'Nama',
                                component: (
                                    <input type='text' className='form-control form-control-sm mb-2'
                                        value={lsIdpState?.cluster}
                                        placeholder='Cluster'
                                        onChange={(val) => lsIdpAction.setCluster(val.target.value)}
                                    >
                                    </input>
                                )
                            },
                            {
                                type: 'custom',
                                placeholder: 'Nama',
                                component: (
                                    <input type='text' className='form-control form-control-sm mb-2'
                                        value={lsIdpState?.group_competency}
                                        placeholder='Group Competency'
                                        onChange={(val) => lsIdpAction.setGroup_competency(val.target.value)}
                                    >
                                    </input>
                                )
                            },
                            {
                                type: 'custom',
                                placeholder: 'Nama',
                                component: (
                                    <input type='text' className='form-control form-control-sm mb-2'
                                        value={lsIdpState?.course_name}
                                        placeholder='Training Name'
                                        onChange={(val) => lsIdpAction.setCourse_name(val.target.value)}
                                    >
                                    </input>
                                )
                            },   
                        ]}
                        labelFilter="Tahun IDP Register :"
                        pagination={false}
                        resetFilter={false}
                        onFilter={() => lsIdpAction.onFilter()}
                        curPage={1}
                        minHeight="300px"
                        maxHeight="500px"
                        navtable={"TABLE 1"}
                        withCard={false}
                        isLoading={lsIdpState.loadingGet}
                        // onChangeShow={rekAction.changeShow}
                        // onNextPage={rekAction.nextPage}
                        // onPrevPage={rekAction.prevPage}
                        withActions={false}
                        columns={[
                            {
                                name: '#',
                                field: '#',
                                maxWidth: '50px',
                            },
                            {
                                name: 'Nama Training',
                                field: 'course_name',
                            },
                            {
                                name: 'Action',
                                field: '',
                                maxWidth: '70px',
                                minWidth: '70px',
                                onRender: (item) => (
                                    <button className='btn btn-sm btn-primary'
                                        onClick={() => history.push({
                                            pathname: '/ats/ats-create/create-schedule',
                                            state: {
                                                course_name: item?.course_name,
                                                jobsite_name: lsIdpState.dataState?.jobsite_name,
                                                jobsite_code: lsIdpState.dataState?.jobsite_code,
                                                batch: lsIdpState.dataState?.batch
                                            }
                                        })}
                                    >Create Schedule</button>
                                )
                            },
                        ]}
                        data={[...(lsIdpState.dataGet?.listIdp || [])]}
                    />


                </div>
                <div className='card-footer'>
                    <button className='btn btn-sm btn-secondary'
                        onClick={() => history.goBack()}
                    >Back</button>
                </div>
            </div>
        </div>
    );
}

export default (props) => <LsIdpProvider><Index {...props} /></LsIdpProvider>;
