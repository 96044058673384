import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useTirTrainer, { TirTrainerProvider } from "./useTirTrainer";
import { TableList } from "../../../components";

function Page(){

   const { tirAction, tirState } = useTirTrainer();
   const history = useHistory();
   
   return (
       <div>
           <section className="content-header">
               <div className="container-fluid">
                   <div className='row'>
                       <div className='col-md-6'>
                           <h4>Development</h4>
                           <h6>Tir For Trainer</h6>
                       </div>
                       <div className='col-md-6'>
                           <nav aria-label="breadcrumb">
                               <ol className="breadcrumb justify-content-end">
                                   <li className="breadcrumb-item active" aria-current="page">Tir For Trainer</li>
                               </ol>
                           </nav>
                       </div>
                   </div>
               </div>
           </section>
           <TableList
               filter={true}
               withButtonSubmit={false}
               labelFilter=""
               formsFilter={[
                   {
                       type: "custom",
                       component: (
                           <>
                               <div className="form-group row mb-1">
                                   <label htmlFor="inputEmail3" className="col-sm-3 font-weight-normal">Batch</label>
                                   <div className="col-sm-9">
                                       <input type='number' className='form-control form-control-sm' placeholder='Batch'
                                           value={tirState.filter?.batch}
                                           onChange={(val) => tirAction.setFilter(e => ({ ...e, batch: val.target.value }))}
                                       >
                                       </input>
                                   </div>
                               </div>
                               <div className="form-group row mb-1">
                                   <label htmlFor="inputPassword3" className="col-sm-3 font-weight-normal">Name/NIK</label>
                                   <div className="col-sm-9">
                                       <input type='text' className='form-control form-control-sm'
                                           placeholder='Name/NIK'
                                           value={tirState.filter?.search}
                                           onChange={(val) => tirAction.setFilter(e => ({ ...e, search: val.target.value }))}
                                       >
                                       </input>
                                   </div>
                               </div>
                               <div className="form-group row mb-1">
                                   <div className='col-sm-3'></div>
                                   <div className='col-sm-9'>
                                       <button className='btn btn-sm btn-primary'
                                           onClick={() => tirAction.onFilter()}
                                       ><i className='fa fa-search'></i> Search</button>
                                   </div>
                               </div>
                           </>
                       )
                   }
                   // {
                   //     type: 'text',
                   //     placeholder: 'Name or NIK',
                   //     value: tirState.filter?.search,
                   //     onChange: (val) => tirAction.setFilter(e => ({ ...e, search: val }))
                   // }
               ]}
               resetFilter={false}
               onFilter={() => tirAction.onFilter()}
               submitButton={<><i className='fa fa-search'></i> Search</>}
               isLoading={tirState.loadingGet}
               minHeight="300px"
               maxHeight="500px"
               navtable={"TABLE 1"}

               onChangeShow={tirAction.changeShow}
               onNextPage={tirAction.nextPage}
               onPrevPage={tirAction.onPrevPage}
               defaultShow={tirState.pagination?.show}
               curPage={tirState.pagination?.page}
               maxPage={tirState.data?.totalPage || 0}
               orderData={{
                   order_by: tirState.pagination?.order_by,
                   order_type: tirState.pagination?.order_type
               }}
               onOrdering={tirAction.onOrdering}
               totalData={tirState.data?.totalData || 0}

               withActions={false}
               actionType={1}
               actionItems={[
                   {
                       name: 'Lihat detail',
                       onClick: function (item) {

                       }
                   },
               ]}
               columns={[
                   {
                       name: '#',
                       field: '#',
                       maxWidth: '50px',
                   },
                   {
                       name: "Nik Trainee ",
                       field: 'trainee_nik',
                   },
                   {
                       name: "Name Trainee",
                       field: 'trainee_name',
                   },
                   {
                       name: "Aksi",
                       field: 'trainee_nik',
                       className: "text-right",
                       minWidth: '100px',
                       onRender: (item) => (
                           <button className='btn btn-sm btn-primary'
                               onClick={() => history.push({
                                   pathname: '/development/tir-for-trainer/detail',
                                   state: {
                                       trainee_nik: item?.trainee_nik,
                                   }
                               })}
                           >Lihat</button>
                       )
                   }
               ]}
               data={[...(tirState.data?.listData || [])]}
           />
       </div>
   );
}

export default () => <TirTrainerProvider>
   <Page/>
</TirTrainerProvider>