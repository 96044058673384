import React, { Suspense } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Dashboard, Test, LoginPage } from '../pages';
import { routes } from './route.config';
import { LoadingIcon } from '../components';
import { useAuth } from '../context';

const LoginRedrect = () => {
    window.location.href = "/login"
    return (<>
        <div style={{
            margin: "auto",
            width: "fit-content",
            paddingTop: "20%",
            textAlign: "center"
        }}>
            <LoadingIcon width="100px" />
            <h5 className="text-base">Please wait</h5>
        </div>
    </>)
}
const HomeRedrect = () => {
    window.location.href = "/"
    return (<>
        <div style={{
            margin: "auto",
            width: "fit-content",
            paddingTop: "20%",
            textAlign: "center"
        }}>
            <LoadingIcon width="100px" />
            <h5 className="text-base">Please wait</h5>
        </div>
    </>)
}

export default function () {

    const { authenticated, initialized } = useAuth()
    function LoadingFalback() {

        return (<>
            <div style={{
                margin: "auto",
                width: "fit-content",
                paddingTop: "20%",
                textAlign: "center"
            }}>
                <LoadingIcon width="100px" />
                <h5 className="text-base">Initializing User</h5>
            </div>
        </>)
    }

    const history = useHistory()
    return (
        <>
            <Switch>
                <Suspense fallback={<LoadingFalback />}>
                    {
                        <Route
                            key={'/login'}
                            path='/login'
                            exact={true}
                            render={(props) => authenticated ? <HomeRedrect /> : <LoginPage {...props} />}
                        />
                    }
                    {
                        routes.map(function ({ key, exact, ...route }) {
                            return (
                                <Route
                                    key={key}
                                    path={key}
                                    exact={exact}
                                    render={
                                        (props) => authenticated || !initialized ? <route.component {...props} /> :
                                            <LoginRedrect />
                                    }
                                />
                            )
                        })
                    }
                </Suspense>
            </Switch>
        </>
    )
}