import moment from "moment";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useModalAlert } from "../../../../components/modalAlert";
import useFetch from "../../../../hooks/useFetch";

const crtMtsContext = createContext({
    crtMtsState: {},
    crtMtsAction: {}
});
const iniStateFormData = {
    "mts_id": "",
    "ats_id": 0,
    "start_date": "",
    "end_date": "",
    "duration": 0,
    "training_id": [],
    "trainer_nik": "",
    "deleted_training_ids": []
}
export function CrtMtsProvider({ children,
    dataProps = {} }) {
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const { doGet, doPost } = useFetch();
    const [formData, setFormData] = useState(iniStateFormData);
    const [modeEdit, setModeEdit] = useState(false);
    const [dataTrainer, setDataTrainer] = useState([]);
    const [loadingTrainer, setLoadingTrainer] = useState(false);
    const [dataTrainee, setDataTrainee] = useState({});
    const [loadingTrainee, setLoadingTrainee] = useState(false);
    const [validated, setValidated] = useState(false);
    const [dataParam, setDataParam] = useState({});

    const [pagination, setPagination] = useState({
        page: 1, show: 10, order_by: "trainee_name", order_type: "asc"
    });

    const [filter, setFilter] = useState({
        trainee_name: "",
        trainee_nik: ""
    });

    async function getTrainer(jobsite_code = '', course_type = '') {
        try {
            setLoadingTrainer(true);
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/ats/list-trainer',
                    param: {
                        jobsite_code,
                        course_type
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = [], code, msg } = payload;
                if (code === "00") {
                    setDataTrainer((data || []).map(d => ({
                        nik: d?.nik,
                        name: `${d?.nik} - ${d?.name}`
                    })));
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Create Schedule",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Create Schedule",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Create Schedule",
                callBack: () => null
            })
        }
        setLoadingTrainer(false)
    }
    async function getSourceTrainee(course_name = '') {
        try {
            setLoadingTrainee(true);
            console.log(dataParam);
            console.log(dataProps);
            // console.log(pagination);
            const { status, statusText, payload } = await doPost(
                {
                    queryParam: {
                        mts_id: dataParam?.mts_id,
                        jobsite_code: dataProps?.jobsite_code,
                        course_name,
                        "page": pagination.page,
                        "row_in_page": pagination.show,
                        "order_by": pagination.order_by,
                        "order_type": pagination.order_type,
                        "batch": dataParam.tahun || "",
                        ...filter
                    },
                    url: '/api/mts/list-trainee',
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDataTrainee({ ...data });
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Trainee",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Trainee",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Trainee",
                callBack: () => null
            })
        }
        setLoadingTrainee(false)
    }

    function setForm(data = {}, modeEdit = false) {
        setModeEdit(modeEdit);
        getTrainer(data?.jobsite_code, data?.type);
        // getSourceTrainee(data?.course_name);
        setDataParam(data);
        setFormData(e => ({
            ...e,
            ats_id: data?.ats_id,
            mts_id: data?.mts_id || '',
            duration: data?.duration || 0,
            start_date: data?.start_date_mts ? data?.start_date_mts : data?.start_date_ats,
            end_date: data?.end_date_mts ? data?.end_date_mts : data?.end_date_ats,
            training_id: [...(data?.trainees || [])].filter(d => d?.training_id !== null).map(d => d?.training_id),
            trainer_nik: data?.trainer_nik,
        }))
    }
    function handleChange(name = '', val = '') {
        if (name === "duration") {
            setFormData((prev) => {
                return {
                    ...prev,
                    [name]: val,
                    end_date: (val && (prev.start_date !== '' && prev.start_date !== "Invalid date")) ? moment(prev.start_date).add(val - 1, 'days').format('YYYY-MM-DD') : ''
                }
            })
        } else if (name === 'start_date') {
            setFormData((prev) => {
                return {
                    ...prev,
                    [name]: val,
                    end_date: (val !== '' && val !== "Invalid date") && prev.duration ? moment(val).add(prev.duration - 1, 'days').format('YYYY-MM-DD') : ''
                }
            })
        } else {
            setFormData((e) => (
                {
                    ...e,
                    [name]: val
                }
            ))
        }
    }
    function nextPage({ page, limit }) {
        setPagination(prevState => ({
            ...prevState,
            page, show: limit,
        }))
    }
    function prevPage({ page, limit }) {
        setPagination(prevState => ({
            ...prevState,
            page, show: limit,
        }))
    }
    function changeShow({ page, limit }) {
        setPagination(prevState => ({
            ...prevState,
            page, show: limit,
        }))
    }
    function onFilter() {
        getSourceTrainee(dataParam?.course_name);
    }
    function btnSave() {
        const val = validationData(formData);
        if (val.isValid === false) {
            return setDataAlert({
                show: true,
                body: val.message,
                header: "Validation Save",
                callBack: () => null
            })
        }
        dataProps?.onSubmit?.({
            ...formData,
            training_id: [...(formData?.training_id || [])],
            deleted_training_ids: dataProps?.modeEdit ? [...(dataProps?.data?.trainees || [])].filter(d => ![...(formData?.training_id || [])].some(x => x === d?.training_id)).map(y => y.training_id).filter(z => z !== null) : []
        })
    }
    function validationData(data) {
        var message = [];
        var messageCs = '';
        var isValid = true;
        if (data?.start_date === '') {
            message = [...message, 'Start date'];
            isValid = false;
        }
        if (data?.end_date === '') {
            message = [...message, 'End date'];
            isValid = false;
        }
        if ([...(data?.training_id || [])].length <= 0) {
            message = [...message, 'Trainee'];
            isValid = false;
        }
        if (isValid && moment(data?.end_date).isBefore(moment(data?.start_date))) {
            messageCs = 'Start date tidak boleh lebih dari end date';
            isValid = false;
        }
        return {
            isValid,
            message: messageCs ? messageCs : message.join(', ') + ' Tidak boleh kosong'
        }
    }

    useEffect(() => {
        if (dataParam?.course_name) {
            // console.log(dataProps)
            getSourceTrainee(dataParam?.course_name)
        }
    }, [pagination, dataParam])

    return <crtMtsContext.Provider
        value={{
            crtMtsState: {
                formData,
                dataTrainer,
                loadingTrainer,
                dataTrainee,
                loadingTrainee,
                validated,
                filter,
                pagination,
                modeEdit
            },
            crtMtsAction: {
                setForm,
                getTrainer,
                getSourceTrainee,
                handleChange,
                setValidated,
                nextPage,
                prevPage,
                changeShow,
                onFilter,
                setFilter,
                btnSave
            }
        }}
    >{children}</crtMtsContext.Provider>
}

export default function useCreateMts() {
    return useContext(crtMtsContext);
}
