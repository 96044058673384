import React, { createContext, useContext, useState, useEffect } from "react";
import { useModalAlert } from "../../../components/modalAlert";
import useFetch from "../../../hooks/useFetch";
import { useLocation, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ModalTitleForm from "./component/modalTitleForm";

const context = createContext({
    tirAction: {},
    tirState: {},
});

export function TirTrainerProvider({ children }) {
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading,
    } = modalAction;
    const { doGet, doPost } = useFetch();
    const [loadingGet, setLoadingGet] = useState(false);
    const [data, setData] = useState({});

    const [pagination, setPagination] = useState({
        page: 1,
        show: 10,
        order_by: "trainee_name",
        order_type: "asc",
    });

    const [filter, setFilter] = useState({
        search: "",
        batch: "",
    });
    const history = useHistory();
    const [listTraining, setListTraining] = useState([]);
    const [loadingTraining, setLoadingTraining] = useState(false);
    const { state = {} } = useLocation();
    const { trainee_nik } = state;
    const { form } = state;

    async function getList() {
        try {
            setLoadingGet(true);
            const { status, statusText, payload } = await doGet({
                url: "/api/job-assignment/list-trainee",
                param: {
                    ...filter,
                    page: pagination.page,
                    row_in_page: pagination.show,
                    order_by: pagination.order_by,
                    order_type: pagination.order_type,
                },
            });

            if (status === 401) {
                closeLoading();
            } else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setData({ ...data });
                    setFilter((e) => ({ ...e, batch: data?.batch }));
                } else
                    setDataAlert({
                        show: true,
                        body: msg,
                        header: "Get Job Assignment",
                        callBack: () => null,
                    });
            } else
                setDataAlert({
                    show: true,
                    body: statusText,
                    header: "Get Job Assignment",
                    callBack: () => null,
                });
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Job Assignment",
                callBack: () => null,
            });
        }
        setLoadingGet(false);
    }

    async function getListTraining() {
        try {
            setLoadingTraining(true);
            const { status, statusText, payload } = await doGet({
                url: "/api/tir/list-training",
                param: {
                    ...filter,
                    trainee_nik
                },
            });

            if (status === 401) {
                closeLoading();
            } else if (status === 200) {
                const { data = [], code, msg } = payload;
                if (code === "00") {
                    setListTraining(data);
                } else
                    setDataAlert({
                        show: true,
                        body: msg,
                        header: "GetListTraining",
                        callBack: () => null,
                    });
            } else
                setDataAlert({
                    show: true,
                    body: statusText,
                    header: "GetListTraining",
                    callBack: () => null,
                });
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "GetListTraining",
                callBack: () => null,
            });
        }
        setLoadingTraining(false);
    }

    function nextPage({ page, limit }) {
        setPagination((prevState) => ({
            ...prevState,
            page,
            show: limit,
        }));
    }
    function prevPage({ page, limit }) {
        setPagination((prevState) => ({
            ...prevState,
            page,
            show: limit,
        }));
    }
    function changeShow({ page, limit }) {
        setPagination((prevState) => ({
            ...prevState,
            page,
            show: limit,
        }));
    }
    function onFilter() {
        getList();
    }
    function onResetFilter() { }


    //start form page handle
    const [loadingForm, setLoadingForm] = useState(false);
    const [formData, setFormData] = useState({});
    const [listForm, setListForm] = useState([]);
    const [modalAddForm, setModalAddForm] = useState({ show: false, title: '', data: {} });
    const addForm = (data = {}) => {
        setModalAddForm({ show: true, title: '', data: { ...data } })
    }
    const editForm = (data = {}) => {
        history.push({
            pathname: '/development/tir-for-trainer/form',
            state: {
                form: {
                    title: "Form Edit",
                    type: "Form",
                    dataPage: {
                        detail: { ...data, trainee_nik },
                        modeEdit: true
                    }
                }
            }
        })
    }
    const closeModalTitle = () => {
        setModalAddForm({ show: false, title: '' })
    }
    const getListForm = async function () {
        setLoadingForm(true);

        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/form/TIR',
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setListForm(data || []);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get List Form",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List Form",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Form",
                callBack: () => null
            })
        }
        setLoadingForm(false)
    };
    const getDetilForm = async function (tir_id = '') {
        openLoading("Get Detail");
        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/tir/detail-form',
                    param: {
                        tir_id
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data: { data_input = [] }, code, msg } = payload;
                if (code === "00") {
                    // console.log([...(data_input || [])]?.reduce((prev, obj, i) => ({...prev,[obj?.form_id]:{...obj}}), {}))
                    setFormData([...(data_input || [])]?.reduce((prev, obj, i) => ({ ...prev, [obj?.form_id]: { ...obj } }), {}))
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Detil Form",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Detil Form",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Detil Form",
                callBack: () => null
            })
        }
        closeLoading();
    };
    const [imageChanged, setImageChanged] = useState({
        "is_new": false,
        "img_id": ""
    });
    const doUploadImage = async function (file, form_id = '', answer_type = '', answer_id = '') {
        closeConfirm()
        openLoading("Uploading Image...");
        try {
            const { status, payload, statusText } = await doPost({
                url: "/api/tir/upload-img",
                data: {
                    file
                },
                type: 1,
                paramImage: 'file',
            });

            if (status !== 401) {
                if (status === 200 || status === 201) {
                    const { data, code, msg } = payload;
                    if (code === "00") {
                        setImageChanged({
                            "is_new": true,
                            "img_id": data?.img_id
                        })
                        setFormData((e) => ({
                            ...e, [form_id]: {
                                "form_id": form_id,
                                "value": data?.img_id,
                                "path": data?.path,
                                "answer_type": answer_type,
                                "answer_id": answer_id
                            }
                        }))
                    } else setDataAlert({
                        show: true,
                        body: msg,
                        header: "Upload Image",
                        callBack: () => null
                    })
                } else setDataAlert({
                    show: true,
                    body: statusText,
                    header: "Upload Image",
                    callBack: () => null
                })
            }
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Upload Image",
                callBack: () => null
            })
        }
        closeLoading();
    };
    const doDeleteImage = async function (img_id = '') {
        openLoading("Clearing Editor..");
        try {
            const { status, payload, statusText } = await doPost({
                url: `/api/tir/delete-img/${img_id}`,
                method: 'DELETE'
            });

            if (status !== 401) {
                if (status === 200 || status === 201) {
                    const { code, msg } = payload;
                    if (code === "00") {
                        history.push({
                            pathname: '/development/tir-for-trainer/detail',
                            state: {
                                trainee_nik : form?.dataPage?.detail?.trainee_nik,
                            }
                        });
                    } else setDataAlert({
                        show: true,
                        body: msg,
                        header: "Info",
                        callBack: () => null
                    })
                } else setDataAlert({
                    show: true,
                    body: statusText,
                    header: "Info",
                    callBack: () => null
                })
            }
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Info",
                callBack: () => null
            })
        }
        closeLoading();
    };
    const handleChangeForm = (name, val) => {
        setFormData((e) => ({
            ...e,
            [name]: val
        }))
    }
    const formBackButton = () => {
        if (imageChanged?.is_new) {
            doDeleteImage(imageChanged?.img_id);
        } else {
            history.push({
                pathname: '/development/tir-for-trainer/detail',
                state: {
                    trainee_nik : form?.dataPage?.detail?.trainee_nik,
                }
            });
        }
    }
    const confirmAddUpdateForm = function (data = [], dataPage_, modeEdit_ = false, title = '') {
        setDataConfirm({
            show: true,
            body: "Apa anda yakin?",
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                {
                    name: "Yes", onClick: () => {
                        if (modeEdit_ === false) {
                            doSubmitForm({
                                title,
                                training_id: dataPage_?.dataPage?.detail?.training_id,
                                data_input: data
                            })
                        } else {
                            doUpdateForm({
                                title,
                                tir_id: dataPage_?.dataPage?.detail?.tir_id,
                                data_input: data
                            })
                        }
                    }, className: "btn btn-sm btn-success"
                },
                { name: "No", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    };
    const confirmDeleteForm= function (data = {}) {
        setDataConfirm({
            show: true,
            body: "Apa anda yakin?",
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Yes", onClick: () => doDeleteForm(data?.tir_id), className: "btn btn-sm btn-danger" },
                { name: "No", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    };
    const doDeleteForm = async function (tir_id = '') {
        closeConfirm()
        openLoading("Sending data, please wait...");
        try {
            const { status, payload, statusText } = await doPost({
                url: `/api/tir/delete/${tir_id}`,
                method: 'DELETE'
            });

            if (status !== 401) {
                if (status === 200 || status === 201) {
                    const { code, msg } = payload;
                    if (code === "00") {
                        setDataAlert({
                            body: msg,
                            show: true,
                            header: "Success",
                            callBack: () => {
                                history.push({
                                    pathname: '/development/tir-for-trainer/detail',
                                    state: {
                                        trainee_nik : form?.dataPage?.detail?.trainee_nik,
                                    }
                                });
                            }
                        })
                    } else setDataAlert({
                        show: true,
                        body: msg,
                        header: "Info",
                        callBack: () => null
                    })
                } else setDataAlert({
                    show: true,
                    body: statusText,
                    header: "Info",
                    callBack: () => null
                })
            }
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Info",
                callBack: () => null
            })
        }
        closeLoading();
    };
    const doSubmitForm = async function (data = {}) {
        closeConfirm()
        openLoading("Sending data, please wait...");
        try {
            const { status, payload, statusText } = await doPost({
                url: "/api/tir/add-answer-form",
                data: {
                    ...data
                }
            });

            if (status !== 401) {
                if (status === 200 || status === 201) {
                    const { code, msg } = payload;
                    if (code === "00") {
                        setDataAlert({
                            body: msg,
                            show: true,
                            header: "Success",
                            callBack: () => {
                                history.push({
                                    pathname: '/development/tir-for-trainer/detail',
                                    state: {
                                        trainee_nik : form?.dataPage?.detail?.trainee_nik,
                                    }
                                });
                            }
                        })
                    } else setDataAlert({
                        show: true,
                        body: msg,
                        header: "Info",
                        callBack: () => null
                    })
                } else setDataAlert({
                    show: true,
                    body: statusText,
                    header: "Info",
                    callBack: () => null
                })
            }
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Info",
                callBack: () => null
            })
        }
        closeLoading();
    };
    const doUpdateForm = async function (data = {}) {
        closeConfirm()
        openLoading("Sending data, please wait...");
        try {
            const { status, payload, statusText } = await doPost({
                url: "/api/tir/update-answer-form",
                data: {
                    ...data
                },
                method: 'PUT'
            });

            if (status !== 401) {
                if (status === 200 || status === 201) {
                    const { code, msg } = payload;
                    if (code === "00") {
                        setDataAlert({
                            body: msg,
                            show: true,
                            header: "Success",
                            callBack: () => {
                                history.push({
                                    pathname: '/development/tir-for-trainer/detail',
                                    state: {
                                        trainee_nik : form?.dataPage?.detail?.trainee_nik,
                                    }
                                });
                            }
                        })
                    } else setDataAlert({
                        show: true,
                        body: msg,
                        header: "Info",
                        callBack: () => null
                    })
                } else setDataAlert({
                    show: true,
                    body: statusText,
                    header: "Info",
                    callBack: () => null
                })
            }
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Info",
                callBack: () => null
            })
        }
        closeLoading();
    };
    //end form page handle

    useEffect(() => {
        if (!trainee_nik) getList();
    }, [pagination]);

    useEffect(() => {
        if (trainee_nik) getListTraining();
    }, [trainee_nik])

    useEffect(() => {
        if (form){
            getListForm();
            if (form.dataPage?.modeEdit) {
                getDetilForm(form?.dataPage?.detail?.tir_id);
            }
        }
    }, [form])

    return (
        <context.Provider
            value={{
                tirAction: {
                    nextPage,
                    prevPage,
                    changeShow,
                    setFilter,
                    onFilter,
                    addForm,
                    editForm,
                    closeModalTitle,
                    setModalAddForm,
                    handleChangeForm,
                    doUploadImage,
                    formBackButton,
                    confirmAddUpdateForm,
                    confirmDeleteForm,
                },
                tirState: {
                    data,
                    loadingGet,
                    pagination,
                    filter,
                    listTraining,
                    loadingTraining,
                    modalAddForm,
                    listForm,
                    formData,
                    loadingForm,
                    trainee_nik,
                    form
                },
            }}
        >
            <ModalTitleForm />
            {children}
        </context.Provider>
    );
}

export default function useTirTrainer() {
    return useContext(context);
}
