import React, { memo, useEffect, useLayoutEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { DateInput, SelectSearch } from '..';
// import { useOutside } from '../../../hooks';
import { useOutside } from '../../hooks';
import './style.css';
import { BiSortAZ, BiSortZA, BiSortAlt2 } from "react-icons/bi";
export default
    function ({
        columns = [],
        data = [],
        filter,
        resetFilter = true,
        formsFilter = [],
        submitButton = 'Submit',
        labelFilter = "Cari data berdasarkan :",
        penCarian = 'Submit',
        onFilter = () => null,
        withCard = true,
        withActions = true,
        withButtonSubmit = true,
        actionItems = [],
        customToolbar = null,
        selectionMode = 0,
        onResetFilter = () => null,
        pagination = true,
        onChangeShow = () => null,
        onNextPage = () => null,
        onPrevPage = () => null,
        store,
        navtable = null,
        navicon1 = null,
        navicon2 = null,
        iconprinter,
        iconcetak,
        isLoading = false,
        showChoices = [5, 10, 25, 50, 100, "All"],
        defaultShow = 15,
        maxHeight = '350px',
        minHeight = '300px',
        curPage = 1,
        onSelection = (keys) => null,
        fieldSelectionKey = null,
        defaultSelection = [],
        maxPage = 1,
        totalData = 1,
        orderData = {
            order_by: null, order_type: null
        },
        onOrdering = () => null,
        onClickRows = () => null,
        compactTable = false,
        actionType = 1, // default action type 1 ( on list item click ) 2 actions item right click
        filterSelection = (item) => false,
        ...props
    }) {
    var loadingGet = isLoading;
    const [orderingData, setOrderingData] = useState({
        order_by: null, order_type: null
    });

    useEffect(function () {
        setOrderingData({
            order_by: orderData.order_by,
            order_type: orderData.order_type
        })
    }, [orderData.order_by])

    function onOrderingData(order_by) {
        let order_type = orderingData.order_type === "asc" ? "desc" : "asc";
        if (order_by !== orderingData.order_by) {
            order_type = "asc"
        }
        setOrderingData({
            order_by,
            order_type
        })
        return onOrdering({
            order_by,
            order_type
        })
    }

    const [pagingDetil, setPagination] = useState({
        currentPage: 1, showingPage: 15
    });

    const [selection, setSelection] = useState([]);

    useLayoutEffect(function () {
        setSelection(defaultSelection)
    }, []);


    function changeSelection(select, key, dt, type) {
        let newData = [];
        if (type === 'All') {
            if (select) {
                newData = [...(data.map(d => d[fieldSelectionKey]) || [])]
                setSelection(prevState => newData)
            } else {
                newData = []
                setSelection(prevState => newData)
            }
        } else {
            if (select) {
                newData = [...(defaultSelection || []), key]
                setSelection(prevState => newData)
            } else {
                newData = (defaultSelection || []).filter(v => v !== key)
                setSelection(prevState => newData)
            }
        }
        onSelection(newData, dt);
    }

    // useEffect(function(){
    //     onSelection(selection)
    // },[selection])

    useEffect(function () {
        setPagination(p => ({
            ...p,
            currentPage: curPage
        }))
    }, [curPage])

    const { currentPage, showingPage } = pagingDetil;
    const next = (page = null) => {
        const detil = {
            page: currentPage + 1,
            // offset: (currentPage + 1) * showingPage,
            limit: showingPage,
        };
        // console.log(detil)
        if (curPage < maxPage) {
            setPagination({
                currentPage: detil.page,
                showingPage: detil.limit
            });
            onNextPage(detil);
        }
    };

    const setPage = (page) => {
        const detil = {
            page,
            // offset: (currentPage + 1) * showingPage,
            limit: showingPage,
        };
        // console.log(detil)
        if (page <= maxPage && page >= 1) {
            setPagination({
                currentPage: detil.page,
                showingPage: detil.limit
            });
            onNextPage(detil);
        }
    }
    useEffect(function () {
        setPagination(prevState => ({
            currentPage: 1,
            showingPage: defaultShow
        }))
    }, [defaultShow]);

    const prev = () => {
        const detil = {
            page: currentPage - 1,
            offset: (currentPage - 1) * showingPage,
            limit: showingPage,
        };
        setPagination({
            currentPage: detil.page,
            showingPage: detil.limit
        });
        // appAction.current.prevPage();
        onNextPage(detil);
    };

    const show = (showingPage) => {
        let detil = {
            page: 1,
            offset: (currentPage - 1) * showingPage,
            limit: showingPage,
        };
        if (showingPage === '') {
            detil = {
                page: currentPage - 1,
                offset: (currentPage - 1) * showingPage,
            };
        }
        setPagination({
            currentPage: detil.page,
            showingPage: detil.limit
        });
        // appAction.current.resetPagination(showingPage);
        onChangeShow(detil);
    };
    const colsArray = [];
    for (var i = 0; i < 5; i++) {
        colsArray.push(i)
    }
    const [contextMenu, setContextMenu] = useState({
        show: false,
        x: 0,
        y: 0,
        listMenu: [],
        indexSelected: null
    });
    const [triggerRef, setTriggerRef] = useState(null);
    const [boxRef, setBoxRef] = useState(null);
    useOutside(boxRef, triggerRef, () => {
        setContextMenu(p => ({
            ...p,
            show: false,
            indexSelected: null
        }))
    })

    function createArrayPagination() {
        let pages = [];
        let first = (curPage < 4) ? 1 : curPage - 3;
        let max = 5 + first;
        max = (maxPage < max) ? maxPage : max;
        let diff = first - max + 5;
        first -= (first - diff > 0) ? diff : 0
        for (let i = first - 1; i < max; i++) {
            pages.push(i);
        }

        return pages;
    }
    return (
        <>
            {/* <div className="card">
                    <div className="card-body"> */}
            <div className={`dropdown-menu context-menu-animation ${contextMenu.show ? "show" : "hide"}`} style={{
                // display: contextMenu.show ? "block" : "none",
                left: contextMenu.x,
                top: contextMenu.y,
                position: "fixed",
                zIndex: 999,
                overflow: "hidden"
                // transition:"all 2s linear"
            }}
                ref={(r) => setBoxRef(r)}
            >
                {(contextMenu.listMenu || [])?.map((item, i) => {
                    let enabled = false;

                    if (item.enabled === undefined) {
                        enabled = true;
                    } else {
                        enabled = item.enabled(item.data);
                    }

                    if (!enabled) return null;

                    return (
                        <>
                            {item.withDivider && <div className="dropdown-divider"></div>}
                            <button
                                className={`dropdown-item ${props.className}`}
                                onClick={() => {
                                    setContextMenu(p => ({
                                        ...p,
                                        show: false
                                    }))
                                    return item.onClick();
                                }}
                                type="button"
                            >
                                {item.name}
                            </button>
                        </>
                    );
                })}
            </div>
            {/* </div>
                </div> */}
            <div className={withCard ? 'card pb-2' : ''}>
                <div className={withCard ? 'card-body' : ''}>
                    {/* Filter */}
                    {filter && (
                        <>
                            <div className="row">
                                <div className="col-md-12 mb-2">
                                    <span>{labelFilter}</span>
                                </div>
                            </div>
                            <div className="row mb-2">
                                {formsFilter?.map((ff, key) => {
                                    if (ff.type === 'text')
                                        return (
                                            <div className="col-md col-lg col-sm-12">
                                                <div className="form-group form-group-sm">
                                                    <input
                                                        className="form-control form-control-sm"
                                                        value={ff.value}
                                                        onChange={(e) =>
                                                            ff.onChange?.(e.target.value)
                                                        }
                                                        disabled={ff.disabled}
                                                        readOnly={ff.readOnly}
                                                        placeholder={ff.placeholder}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    if (ff.type === 'select')
                                        return (
                                            <div className="col-md col-lg col-sm-12">
                                                <div className="form-group form-group-sm">
                                                    <SelectSearch
                                                        className="form-control form-control-sm"
                                                        value={ff.value}
                                                        onChange={(e) =>
                                                            ff.onChange?.(e.value)
                                                        }
                                                        disabled={ff.disabled}
                                                        readOnly={ff.readOnly}
                                                        placeholder={ff.placeholder}
                                                        options={ff.options}
                                                        display={"display"}
                                                        keyValue={"key"}
                                                        size="sm"
                                                    />
                                                    {/* <option value="">{ff.placeholder}</option>
                                                            {ff.options?.map((opt, i) => (
                                                                <option value={opt.key}>
                                                                    {opt.display}
                                                                </option>
                                                            ))}
                                                        </select> */}
                                                </div>
                                            </div>
                                        );
                                    if (ff.type === 'date')
                                        return (
                                            <div className="col-md col-lg col-sm-12">
                                                <div className="form-group form-group-sm">
                                                    <DateInput
                                                        type="date"
                                                        className="form-control form-control-sm"
                                                        value={ff.value}
                                                        onChange={(e) =>
                                                            ff.onChange?.(e)
                                                        }
                                                        disabled={ff.disabled}
                                                        readOnly={ff.readOnly}
                                                        placeholder={ff.placeholder}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    if (ff.type === 'custom')
                                        return (
                                            <div className="col-md col-lg col-sm-6">
                                                {ff.component}
                                            </div>
                                        );
                                })}

                                <div className={`col-md`}>
                                    {resetFilter && (
                                        <button onClick={() => onResetFilter()} className="btn btn-sm mr-2 text-danger">
                                            {/* <p style={{ color: '#16D090' }}><strong> */}
                                            <i className='fa fa-undo'></i> Reset
                                            {/* </strong></p> */}
                                        </button>
                                    )}
                                    {
                                        withButtonSubmit && <button
                                            type='button'
                                            className="btn btn-sm btn-primary tw-mt-3 md:tw-mt-0"
                                            // style={{ backgroundColor: '#16D090' }}
                                            onClick={() => onFilter?.()}
                                        >
                                            {/* <p className="text-white"><strong> */}
                                            {submitButton}
                                            {/* </strong> </p> */}
                                        </button>
                                    }



                                    <button
                                        className="btn btn-sm"
                                        onClick={() => onFilter?.()}
                                    >
                                        {iconprinter}
                                    </button>


                                    <button
                                        className="btn btn-sm"
                                        onClick={() => onFilter?.()}
                                    >
                                        {iconcetak}
                                    </button>
                                </div>

                                {customToolbar && (
                                    <div className="col-md col-lg col-sm-12">
                                        {customToolbar}
                                    </div>
                                )}
                            </div>
                        </>
                    )}

                    <div className="row mt-2">
                        <div className="col-md-12">
                            <div
                                className="table-responsive"
                                style={{
                                    maxHeight,
                                    minHeight,
                                    borderBottom: '0.5pt grey solid',
                                }}
                            >
                                {/* {loadingGet ? (
                                        <Loading />
                                    ) : ( */}
                                <table className={`table ${compactTable ? "table-sm" : ""} tableFixHead table-hover`}>
                                    <thead
                                        style={{
                                            background: '#FAFAFA',
                                        }}
                                    >
                                        <tr>
                                            {selectionMode != 0 && (
                                                <th>
                                                    {selectionMode == 2 && (
                                                        <input type="checkbox"
                                                            checked={defaultSelection.length === data.length ? true : false}
                                                            onChange={(e) => changeSelection(e.target.checked, null, null, 'All')}
                                                        />
                                                    )}
                                                </th>
                                            )}
                                            {columns?.map(({
                                                style = {},
                                                className = "",
                                                maxWidth = null,
                                                minWidth = "max-content",
                                                ordering = false,
                                                orderField = null,
                                                ...head
                                            }, i) => {
                                                let sortField = head.field;
                                                if (orderField !== null) {
                                                    sortField = orderField
                                                }


                                                return (
                                                    <th
                                                        onClick={() => ordering && onOrderingData(sortField)} style={{ ...style, maxWidth, minWidth, cursor: ordering ? "pointer" : "auto" }} key={i}>
                                                        <div style={{ minWidth: "max-content" }}
                                                            className={className}
                                                        >
                                                            {head.name}
                                                            {
                                                                ordering && (orderingData.order_by === sortField ? (
                                                                    orderingData.order_type === "asc" ? <BiSortAZ style={{ float: "right", fontSize: "15pt", marginLeft: "10px", color: "#4d4d4d" }} /> :
                                                                        (orderingData.order_type === "desc" ? <BiSortZA style={{ float: "right", fontSize: "15pt", marginLeft: "10px", color: "#4d4d4d" }} /> : "")
                                                                ) : <BiSortAlt2 style={{ float: "right", fontSize: "15pt", marginLeft: "10px", color: "#a7a7a7" }} />)
                                                            }
                                                        </div>
                                                    </th>
                                                )
                                            })}
                                            {(withActions && actionType === 1) && <th></th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loadingGet ? (
                                            (colsArray).map((v, key) => (
                                                <tr key={key}
                                                    onContextMenu={(e) => {
                                                        e.preventDefault(e)
                                                    }}

                                                >
                                                    {
                                                        columns?.map((col, j) => (
                                                            <td>
                                                                <Skeleton key={j} height={30} count={1} />
                                                            </td>
                                                        ))
                                                    }
                                                </tr>
                                            )
                                            )

                                        ) :
                                            data.length <= 0 ? <>
                                                <tr><td colSpan={columns.length}> <div className='p-3'>No Data Records Found ...</div></td></tr>
                                            </> : data?.map((item, key) => (
                                                <tr
                                                    onContextMenu={(e) => {
                                                        if (actionType === 2) {
                                                            let listMenu = actionItems
                                                                .filter(a => (a.onRender !== undefined &&
                                                                    a.onRender !== null &&
                                                                    typeof a.onRender === "function")
                                                                    ? (a.onRender(item)) : true)
                                                                .map(
                                                                    (act) => ({
                                                                        ...act,
                                                                        onClick: () =>
                                                                            act.onClick(item, key),
                                                                        data: item,
                                                                    })
                                                                )
                                                            if (withActions && listMenu.length > 0) {
                                                                e.preventDefault();
                                                                setContextMenu(xc => ({
                                                                    ...xc,
                                                                    show: true,
                                                                    x: e.clientX,
                                                                    y: e.clientY,
                                                                    listMenu,
                                                                    indexSelected: key
                                                                }))
                                                            }
                                                        }
                                                    }}
                                                    style={{
                                                        ...(
                                                            selectionMode !== 0 ? ({
                                                                cursor: "pointer"
                                                            }) : {}
                                                        ),
                                                        ...(
                                                            (key === contextMenu.indexSelected) || (
                                                                (fieldSelectionKey === null ?
                                                                    defaultSelection.some(k => k === key) :
                                                                    defaultSelection.some(k => k === item[fieldSelectionKey]))
                                                            ) ? {
                                                                background: "#e8e8e8"
                                                            } : {})
                                                    }}
                                                    onClick={() => {

                                                        if (typeof onClickRows === "function") {
                                                            onClickRows(item, key)
                                                        }
                                                        if (selectionMode !== 0) {
                                                            changeSelection(!(
                                                                fieldSelectionKey === null ?
                                                                    defaultSelection.some(k => k === key) :
                                                                    defaultSelection.some(k => k === item[fieldSelectionKey])
                                                            ), fieldSelectionKey === null ? key : item[fieldSelectionKey], item)
                                                        }
                                                    }}
                                                    key={key}>
                                                    {selectionMode != 0 && (
                                                        <td >
                                                            <input
                                                                type="checkbox"
                                                                disabled={filterSelection(item)}
                                                                checked={
                                                                    fieldSelectionKey === null ?
                                                                        defaultSelection.some(k => k === key) :
                                                                        defaultSelection.some(k => k === item[fieldSelectionKey])
                                                                }
                                                                onChange={(e) => changeSelection(e.target.checked, fieldSelectionKey === null ? key : item[fieldSelectionKey], item)}
                                                            />
                                                        </td>
                                                    )}
                                                    {columns?.map(({
                                                        style = {},
                                                        className = "",
                                                        maxWidth = null,
                                                        minWidth = null,
                                                        onStyling = () => ({}),
                                                        ...col }, j) => {
                                                        var dataView = item[col.field];

                                                        if (
                                                            col.onRender !== undefined &&
                                                            col.onRender !== null
                                                        ) {
                                                            dataView = col.onRender(item, key);
                                                        }
                                                        function getStyling() {
                                                            let sty = onStyling(item)
                                                            if (typeof sty !== "object") throw "onStyling must be return an object";
                                                            return {
                                                                ...sty,
                                                                ...style
                                                            }
                                                        }
                                                        return (
                                                            <td style={{ ...getStyling(), maxWidth, minWidth }} className={className}>
                                                                {col.field === '#' || col.field === 'no'
                                                                    ? (currentPage - 1) *
                                                                    (!isNaN(parseInt(showingPage)) ? parseInt(showingPage) : 0) +
                                                                    (key + 1)
                                                                    : dataView}
                                                            </td>
                                                        );
                                                    })}
                                                    {(withActions && actionType === 1) && actionItems
                                                        .filter(a => (a.onRender !== undefined &&
                                                            a.onRender !== null &&
                                                            typeof a.onRender === "function")
                                                            ? (a.onRender(item)) : true).length > 0 && (
                                                            <td>
                                                                <DropDown
                                                                    items={actionItems
                                                                        .filter(a => (a.onRender !== undefined &&
                                                                            a.onRender !== null &&
                                                                            typeof a.onRender === "function")
                                                                            ? (a.onRender(item)) : true)
                                                                        .map(
                                                                            (act) => ({
                                                                                ...act,
                                                                                onClick: () =>
                                                                                    act.onClick(item, key),
                                                                                data: item,
                                                                            })
                                                                        )}
                                                                />
                                                            </td>
                                                        )}
                                                </tr>
                                            ))
                                        }

                                    </tbody>
                                </table>
                                {/* )} */}
                            </div>
                        </div>
                    </div>
                    {pagination && (
                        <div className="row mt-3">
                            <div className="col-md-12">

                                <div className="float-md-right info-page">
                                    <span>
                                        Menampilkan{' '}
                                        {` ${(currentPage - 1) * (typeof showingPage === 'number' ? showingPage : 0) + 1} - ${totalData}`}{' '}
                                        data
                                    </span>
                                </div>
                                <div className="float-md-left form-inline info-page mt-3 mt-md-0">
                                    <span>Baris Per-halaman </span>
                                    <select
                                        value={showingPage}
                                        onChange={(e) => show(e.target.value)}
                                        className="form-control form-control-sm ml-2"
                                    // style={{ border: 'none' }}
                                    >
                                        {
                                            showChoices.map((v, i) => (
                                                <option key={i} value={v}>{v}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="mx-auto mt-md-0 mt-md-0 mt-4" style={{ width: "max-content" }}>
                                    <nav aria-label="Page navigation example">
                                        <ul className="pagination pagination-sm">
                                            <li className="page-item">
                                                <button
                                                    onClick={prev}
                                                    disabled={currentPage === 1}
                                                    className="page-link"
                                                    href="#"
                                                >
                                                    Previous
                                                </button>
                                            </li>
                                            {
                                                (curPage - 3 > 1) && <>
                                                    <li className="page-item"><button onClick={() => setPage(1)} className="page-link" href="#">1</button></li>
                                                    <li className="page-item">...</li>
                                                </>
                                            }
                                            {(
                                                (createArrayPagination() || [])).map(function (v, i) {
                                                    // console.log(v === curPage, v,)
                                                    return <li className={`page-item ${v + 1 === curPage ? "active" : ''}`}>

                                                        <button key={i} onClick={() => setPage(v + 1)} className={`page-link ${v + 1 === curPage ? "active" : ''}`} href="#">
                                                            {v + 1}
                                                        </button>
                                                    </li>

                                                })
                                            }
                                            {
                                                (curPage + 3 <= maxPage) && (maxPage > 6) && <>
                                                    <li className="page-item">...</li>
                                                    <li className="page-item">
                                                        <button className="page-link" onClick={() => setPage(maxPage)} href="#">{maxPage}</button>
                                                    </li>
                                                </>
                                            }
                                            {/* <li className="page-item"><button className="page-link" href="#">3</button></li> */}
                                            <li className="page-item">
                                                <button
                                                    disabled={currentPage === maxPage}
                                                    onClick={next}
                                                    className="page-link"
                                                    href="#"
                                                >
                                                    Next
                                                </button>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* </div>
            </div> */}
        </>
    );
};
const DropDown = memo(function (props) {
    const [show, setShow] = useState('');
    const [position, setPosition] = useState({
        x: 0, y: 0
    })
    // console.log(props)
    const reffDrop = useRef(null)
    const onclickAnother = function (e) {
        if (reffDrop.current && !reffDrop.current.contains(e.target)) {
            setShow('')
        }
    }
    useEffect(() => {
        document.addEventListener('mouseup', onclickAnother);
        return () => {
            document.removeEventListener('mouseup', onclickAnother);
        }
    }, [])
    const containerRef = useRef(null)
    return (
        <div ref={reffDrop} className="btn-group">
            <div className="btn-group dropleft" role="group">
                <button
                    style={{
                        border: 'none',
                        background: 'none',
                    }}
                    onClick={(e) => {
                        console.log(e.clientX, e.clientY)
                        setShow((prevState) => (prevState === 'show' ? '' : 'show'))
                        setPosition({
                            x: e.clientX, y: e.clientY
                        })
                    }}
                >
                    <i
                        className="fa fa-ellipsis-v"
                        style={{
                            transform: 'rotate(90deg)',
                        }}
                    ></i>
                </button>
                <div
                    ref={containerRef}
                    className={`dropdown-menu  context-menu-animation ${show}`}
                    style={{
                        // position:"fixed",
                        // top:position.y,
                        // left:position.x 
                    }}
                >
                    {props.items?.map((item, i) => {
                        let enabled = false;

                        if (item.enabled === undefined) enabled = true;
                        else enabled = item.enabled(item.data);
                        if (!enabled) return null;

                        return (
                            <>
                                {item.withDivider && <div className="dropdown-divider"></div>}
                                <button
                                    className={`dropdown-item ${props.className}`}
                                    onClick={() => {
                                        setShow('');
                                        return item.onClick();
                                    }}
                                    type="button"
                                >
                                    {item.name}
                                </button>
                            </>
                        );
                    })}
                </div>
            </div>
        </div>
    );
});

function Loading() {
    return (
        <>
            <main className="text-white">
                <div
                    className="card card-body d-border-active text-center bg-orange-1"
                    style={{
                        width: '30%',
                        margin: 'auto',
                        marginTop: '30px',
                    }}
                >
                    <div className="text-center w-100 f-30">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <p className="f-14 text-white mt-3">Please wait a few moments...</p>
                    </div>
                </div>
            </main>
        </>
    );
}
