import React, { createContext, useContext, useEffect, useState } from "react";
import { useModalAlert } from "../../../components/modalAlert";
import useFetch from "../../../hooks/useFetch";

const rttContext = createContext({
    rttState:{},
    rttAction:{}
})

export function RttProvider({children}){
    const [batch, setBatch] = useState('');
    const [loadingGet, setLoadingGet] = useState(false);
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const { doGet, doPost } = useFetch();
    const [data, setData] = useState([]);

    async function getList() {
        // openLoading("Get competency")
        setLoadingGet(true)
        try {
            const { status, statusText, payload } = await doGet({
                param: {
                    batch
                },
                url: '/api/mts/score-trainee'
            });
            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = [], code, msg } = payload;
                if (code === "00") {
                    setData([...data])
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get List",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List",
                callBack: () => null
            })
        }
        setLoadingGet(false)
    }

    function onFilter(){
        getList();
    }
    useEffect(() => {
        getList();
    },[])
    return <rttContext.Provider
        value={{
            rttState:{
                batch,
                data
            },
            rttAction:{
                onFilter,
                setBatch
            }
        }}
    >{children}</rttContext.Provider>
}

export default function useRtTrainee(){
    return useContext(rttContext);
}