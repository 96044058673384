import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useModalAlert } from "../../../components/modalAlert";
import useFetch from "../../../hooks/useFetch";
import ModalTautan from "./modal-tautan";
import ModalTitleForm from "./table-list/modal-title-form";

const tirContext = createContext({
    tirState: {},
    tirAction: {}
});

const iniStatePage = {
    title: "List Assignment",
    type: "TableList",
    dataPage: {
        actions: ["getListTraining"]
    }
};
const iniStatModalTautan = {
    show: false,
    modeEdit: false,
    data: {}
}
export function TirProvider({ children }) {
    const [pageState, setPageState] = useState(iniStatePage);
    const [modalTautan, setModalTautan] = useState(iniStatModalTautan);
    const [modalAddForm, setModalAddForm] = useState({ show: false, title: '', data:{} });
    const [formData, setFormData] = useState({});
    const { doGet, doPost } = useFetch()
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const [loadingTraining, setLoadingTraining] = useState(false)
    const [listTraining, setListTraining] = useState([]);
    const [loadingForm, setLoadingForm] = useState(false)
    const [listForm, setListForm] = useState([]);
    const [loadingDetil, setLoadingDetil] = useState(false)
    const [detil, setDetil] = useState([]);
    const [formDataTautan, setFormDataTautan] = useState({ tir_id: '', url: '' });
    const [imageChanged, setImageChanged] = useState({
        "is_new": false,
        "img_id": ""
    });

    const getListTraining = useCallback(async function () {
        setLoadingTraining(true);

        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/tir/list-training',
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setListTraining(data || []);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get List Training",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List Training",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Training",
                callBack: () => null
            })
        }
        setLoadingTraining(false)
    }, []);
    const getListForm = useCallback(async function () {
        setLoadingForm(true);

        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/form/TIR',
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setListForm(data || []);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get List Form",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List Form",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Form",
                callBack: () => null
            })
        }
        setLoadingForm(false)
    }, []);
    const getDetilForm = useCallback(async function (tir_id = '') {
        setLoadingDetil(true);
        openLoading("Get Detail");
        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/tir/detail-form',
                    param: {
                        tir_id
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data: { data_input = [] }, code, msg } = payload;
                if (code === "00") {
                    // console.log([...(data_input || [])]?.reduce((prev, obj, i) => ({...prev,[obj?.form_id]:{...obj}}), {}))
                    setFormData([...(data_input || [])]?.reduce((prev, obj, i) => ({ ...prev, [obj?.form_id]: { ...obj } }), {}))
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Detil Form",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Detil Form",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Detil Form",
                callBack: () => null
            })
        }
        closeLoading();
        setLoadingDetil(false)
    }, []);
    const doSubmitTautan = useCallback(async function (data = {}) {
        closeConfirm()
        openLoading("Sending data, please wait...");
        try {
            const { status, payload, statusText } = await doPost({
                url: "/api/tir/add-update-url",
                data: {
                    ...data
                }
            });

            if (status !== 401) {
                if (status === 200 || status === 201) {
                    const { code, msg } = payload;
                    if (code === "00") {
                        setDataAlert({
                            body: msg,
                            show: true,
                            header: "Success",
                            callBack: () => {
                                resetModalTautan();
                                getListTraining();
                            }
                        })
                    } else setDataAlert({
                        show: true,
                        body: msg,
                        header: "Info",
                        callBack: () => null
                    })
                } else setDataAlert({
                    show: true,
                    body: statusText,
                    header: "Info",
                    callBack: () => null
                })
            }
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Info",
                callBack: () => null
            })
        }
        closeLoading();
    }, []);
    const doDeleteTir = useCallback(async function (tir_id = '') {
        closeConfirm()
        openLoading("Sending data, please wait...");
        try {
            const { status, payload, statusText } = await doPost({
                url: `/api/tir/delete/${tir_id}`,
                method: 'DELETE'
            });

            if (status !== 401) {
                if (status === 200 || status === 201) {
                    const { code, msg } = payload;
                    if (code === "00") {
                        setDataAlert({
                            body: msg,
                            show: true,
                            header: "Success",
                            callBack: () => {
                                setFormData({});
                                resetModalTautan();
                                toListPage();
                            }
                        })
                    } else setDataAlert({
                        show: true,
                        body: msg,
                        header: "Info",
                        callBack: () => null
                    })
                } else setDataAlert({
                    show: true,
                    body: statusText,
                    header: "Info",
                    callBack: () => null
                })
            }
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Info",
                callBack: () => null
            })
        }
        closeLoading();
    }, []);
    const doSubmitForm = useCallback(async function (data = {}) {
        closeConfirm()
        openLoading("Sending data, please wait...");
        try {
            const { status, payload, statusText } = await doPost({
                url: "/api/tir/add-answer-form",
                data: {
                    ...data
                }
            });

            if (status !== 401) {
                if (status === 200 || status === 201) {
                    const { code, msg } = payload;
                    if (code === "00") {
                        setDataAlert({
                            body: msg,
                            show: true,
                            header: "Success",
                            callBack: () => {
                                setFormData({});
                                toListPage();
                            }
                        })
                    } else setDataAlert({
                        show: true,
                        body: msg,
                        header: "Info",
                        callBack: () => null
                    })
                } else setDataAlert({
                    show: true,
                    body: statusText,
                    header: "Info",
                    callBack: () => null
                })
            }
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Info",
                callBack: () => null
            })
        }
        closeLoading();
    }, []);
    const doUpdateForm = useCallback(async function (data = {}) {
        closeConfirm()
        openLoading("Sending data, please wait...");
        try {
            const { status, payload, statusText } = await doPost({
                url: "/api/tir/update-answer-form",
                data: {
                    ...data
                },
                method: 'PUT'
            });

            if (status !== 401) {
                if (status === 200 || status === 201) {
                    const { code, msg } = payload;
                    if (code === "00") {
                        setDataAlert({
                            body: msg,
                            show: true,
                            header: "Success",
                            callBack: () => {
                                setFormData({});
                                toListPage();
                            }
                        })
                    } else setDataAlert({
                        show: true,
                        body: msg,
                        header: "Info",
                        callBack: () => null
                    })
                } else setDataAlert({
                    show: true,
                    body: statusText,
                    header: "Info",
                    callBack: () => null
                })
            }
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Info",
                callBack: () => null
            })
        }
        closeLoading();
    }, []);
    const doUploadImage = useCallback(async function (file, form_id = '', answer_type = '', answer_id = '') {
        closeConfirm()
        openLoading("Uploading Image...");
        try {
            const { status, payload, statusText } = await doPost({
                url: "/api/tir/upload-img",
                data: {
                    file
                },
                type: 1,
                paramImage: 'file',
            });

            if (status !== 401) {
                if (status === 200 || status === 201) {
                    const { data, code, msg } = payload;
                    if (code === "00") {
                        setImageChanged({
                            "is_new": true,
                            "img_id": data?.img_id
                        })
                        setFormData((e) => ({
                            ...e, [form_id]: {
                                "form_id": form_id,
                                "value": data?.img_id,
                                "path": data?.path,
                                "answer_type": answer_type,
                                "answer_id": answer_id
                            }
                        }))
                    } else setDataAlert({
                        show: true,
                        body: msg,
                        header: "Upload Image",
                        callBack: () => null
                    })
                } else setDataAlert({
                    show: true,
                    body: statusText,
                    header: "Upload Image",
                    callBack: () => null
                })
            }
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Upload Image",
                callBack: () => null
            })
        }
        closeLoading();
    }, []);
    const doDeleteImage = useCallback(async function (img_id = '') {
        openLoading("Clearing Editor..");
        try {
            const { status, payload, statusText } = await doPost({
                url: `/api/tir/delete-img/${img_id}`,
                method: 'DELETE'
            });

            if (status !== 401) {
                if (status === 200 || status === 201) {
                    const { code, msg } = payload;
                    if (code === "00") {
                        toListPage();
                    } else setDataAlert({
                        show: true,
                        body: msg,
                        header: "Info",
                        callBack: () => null
                    })
                } else setDataAlert({
                    show: true,
                    body: statusText,
                    header: "Info",
                    callBack: () => null
                })
            }
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Info",
                callBack: () => null
            })
        }
        closeLoading();
    }, []);
    const resetPage = useCallback(function () {
        setPageState({ ...iniStatePage });
    }, [])
    const resetModalTautan = useCallback(function () {
        setModalTautan({ ...iniStatModalTautan });
        setFormDataTautan({ tir_id: '', url: '' });
    }, [])
    const confirmDelete = useCallback(function (data = {}) {
        setDataConfirm({
            show: true,
            body: "Apa anda yakin?",
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Yes", onClick: () => doDeleteTir(data?.tir_id), className: "btn btn-sm btn-danger" },
                { name: "No", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }, []);
    const confirmAddUpdate = useCallback(function (data = {}) {
        setDataConfirm({
            show: true,
            body: "Apa anda yakin?",
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Yes", onClick: () => doSubmitTautan(data), className: "btn btn-sm btn-success" },
                { name: "No", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }, []);
    const toFormPage = useCallback(function (detailPage, modeEdit = false) {
        setImageChanged({
            "is_new": false,
            "img_id": ""
        });
        setPageState({
            title: modeEdit ? "Form Edit" : "Form Add",
            type: "Form",
            dataPage: {
                detail: { ...detailPage },
                actions: ['getListForm'],
                modeEdit: modeEdit
            }
        })
        setFormData({});
    }, [pageState]);
    const toListPage = useCallback(function (detailPage) {
        setPageState({ ...iniStatePage })
    }, [pageState]);
    const formBackButton = (data = {}) => {
        if (data?.is_new) {
            doDeleteImage(data?.img_id);
        } else {
            toListPage();
        }
    }
    let firstActions = {
        getListTraining,
        getListForm,
        getDetilForm
    }
    const handleChange = (name, val) => {
        setFormData((e) => ({
            ...e,
            [name]: val
        }))
    }
    const confirmAddUpdateForm = useCallback(function (data = [], dataPage_, modeEdit_ = false, title = '') {
        setDataConfirm({
            show: true,
            body: "Apa anda yakin?",
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                {
                    name: "Yes", onClick: () => {
                        if (modeEdit_ === false) {
                            doSubmitForm({
                                title,
                                training_id: dataPage_?.dataPage?.detail?.training_id,
                                data_input: data
                            })
                        } else {
                            doUpdateForm({
                                title,
                                tir_id: dataPage_?.dataPage?.detail?.tir_id,
                                data_input: data
                            })
                        }
                    }, className: "btn btn-sm btn-success"
                },
                { name: "No", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }, []);
    const [val, setVal] = useState({ valid: true, title: "", url: "" });
    const customValidation = (data = {}) => {
        const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
        var x = { valid: true, title: "", url: "" }
        setVal({ valid: true, title: "", url: "" });
        if (data?.title === '' || data?.title === undefined || data?.title === null) {
            x = { ...x, valid: false, title: "Please fill out this field" }
        }
        if (data?.video_url === '' || data?.video_url === undefined || data?.video_url === null) {
            x = { ...x, valid: false, url: "Please fill out this field" }
        } else {
            if (!regex.test(data?.video_url)) {
                x = { ...x, valid: false, url: "URL is not valid" };
            }
        }
        setVal({ ...x });
        if (x.valid) {
            confirmAddUpdate({...data});
        }
    }
    useEffect(() => {
        // (pageState.dataPage?.actions || []).forEach(function (v) {
        //     if (typeof firstActions[v] === "function") firstActions[v]()
        // })
        if (pageState.type === 'TableList') getListTraining();
        if (pageState.type === 'Form') {
            getListForm();
            if (pageState.dataPage?.modeEdit) {
                getDetilForm(pageState.dataPage?.detail?.tir_id)
            }
        }
    }, [pageState]);
    useEffect(() => {
        if (modalTautan.show) {
            setVal({ valid: true, title: "", url: "" });
            setFormDataTautan({ ...modalTautan.data });
        }
    }, [modalTautan])

    return <tirContext.Provider
        value={{
            tirState: {
                pageState,
                listTraining,
                loadingTraining,
                modalTautan,
                formDataTautan,
                listForm,
                loadingForm,
                formData,
                imageChanged,
                modalAddForm,
                val
            },
            tirAction: {
                setPageState,
                toFormPage,
                toListPage,
                setModalTautan,
                resetModalTautan,
                setFormDataTautan,
                doSubmitTautan,
                confirmDelete,
                confirmAddUpdate,
                handleChange,
                confirmAddUpdateForm,
                doUploadImage,
                formBackButton,
                setModalAddForm,
                customValidation
            }
        }}
    >
        <ModalTautan />
        <ModalTitleForm/>
        {children}
    </tirContext.Provider>
}

export default function useTirOnline() {
    return useContext(tirContext);
}