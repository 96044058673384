import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useFetch from '../../../../hooks/useFetch';
import { useModalAlert } from '../../../../components/modalAlert';

function Index(props) {
    const { doGet } = useFetch();
    const { modalAction } = useModalAlert();
    const {
        closeLoading,
        setDataAlert,
        openLoading
    } = modalAction;
    const history = useHistory();
    const { nik } = useParams();
    const { state = {} } = useLocation();

    const [detail, setDetail] = useState({
        "nik": "",
        "job_type": "",
        "name": "",
        "level": "",
        "grade": "",
        "job_title": "",
        "job_title_id": "",
        "function": "",
        "division": "",
        "department": "",
        "section": "",
        "directorat": "",
        "pers_area_code": "",
        "pers_area_name": "",
        "poh": "",
        "nik_direct_supervisor": "",
        "direct_supervisor_name": "",
        "role_id": "",
        "current_point": "",
        "attended_ratio": "",
        "join_date": "",
        "maritial_status": "",
        "age": "",
        "nik_indirect_supervisor": "",
        "indirect_supervisor_name": "",
        "type": ""
    });
    const GetData = async function () {
        openLoading("Please wait ...");
        try {
            const { status, statusText, payload } = await doGet({
                url: `/api/data-master/detail-user/${nik}`,
            });

            if (status === 401) {
                closeLoading();
            } else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDetail({ 
                        ...data,
                        job_title: data?.job_title,
                        job_title_id: `${data?.job_title_id}|${data?.job_title}`
                     });
                } else
                    setDataAlert({
                        show: true,
                        body: msg,
                        header: "Get Detail Employee",
                        callBack: () => null,
                    });
            } else
                setDataAlert({
                    show: true,
                    body: statusText,
                    header: "Get Detail Employee",
                    callBack: () => null,
                });
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Detail Employee",
                callBack: () => null,
            });
        }
        closeLoading();
    }

    useEffect(() => {
        if (nik) {
            GetData();
        }
    }, [nik])

    return (
        <div>
            <div className="row">
                <div className="col-6">
                    <section className="content-header">
                        <div className="container-fluid">
                            <h4>Master Data</h4>
                            <h6>Detail Demografi Employee</h6>
                        </div>
                    </section>
                </div>
                <div className="col-6">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end">
                            <li className="breadcrumb-item" aria-current="page">
                                Master Data
                            </li>
                            <li className="breadcrumb-item" aria-current="page">
                                Demografi Employee
                            </li>
                            <li
                                className="breadcrumb-item active"
                                aria-current="page"
                            >
                                Detail
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <h5>Detail Employee</h5>
                    <hr />
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className="form-group row">
                                <label htmlFor="nik" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">NIK</label>
                                <div className="col-sm-8">
                                    <span className='tw-px-[7px] tw-py-[5px] tw-rounded tw-bg-stone-100 tw-block tw-min-h-[32px]'>{detail.nik}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="nama" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Nama</label>
                                <div className="col-sm-8">
                                    <span className='tw-px-[7px] tw-py-[5px] tw-rounded tw-bg-stone-100 tw-block tw-min-h-[32px]'>{detail.name}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="age" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Umur</label>
                                <div className="col-sm-8">
                                    <span className='tw-px-[7px] tw-py-[5px] tw-rounded tw-bg-stone-100 tw-block tw-min-h-[32px]'>{detail.age}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="join" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Tanggal bergabung</label>
                                <div className="col-sm-8">
                                    <span className='tw-px-[7px] tw-py-[5px] tw-rounded tw-bg-stone-100 tw-block tw-min-h-[32px]'>{detail.join_date}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="point" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Point of Hire</label>
                                <div className="col-sm-8">
                                    <span className='tw-px-[7px] tw-py-[5px] tw-rounded tw-bg-stone-100 tw-block tw-min-h-[32px]'>{detail.poh}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="role" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Role</label>
                                <div className="col-sm-8">
                                    <span className='tw-px-[7px] tw-py-[5px] tw-rounded tw-bg-stone-100 tw-block tw-min-h-[32px]'>{detail.role_name}</span>
                                </div>
                            </div>
                            {
                                detail.role_id?.toString() === "2" && (
                                    <div className="form-group row">
                                        <label htmlFor="type" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Type</label>
                                        <div className="col-sm-8">
                                            <span className='tw-px-[7px] tw-py-[5px] tw-rounded tw-bg-stone-100 tw-block tw-min-h-[32px]'>{detail.type}</span>
                                        </div>
                                    </div>
                                )
                            }
                            <div className="form-group row">
                                <label htmlFor="job_type" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Job Type</label>
                                <div className="col-sm-8">
                                    <span className='tw-px-[7px] tw-py-[5px] tw-rounded tw-bg-stone-100 tw-block tw-min-h-[32px]'>{detail.job_type}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="jobsite" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Jobsite</label>
                                <div className="col-sm-8">
                                    <span className='tw-px-[7px] tw-py-[5px] tw-rounded tw-bg-stone-100 tw-block tw-min-h-[32px]'>{detail.pers_area_name}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="job_title_id" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Job Title</label>
                                <div className="col-sm-8">
                                    <span className='tw-px-[7px] tw-py-[5px] tw-rounded tw-bg-stone-100 tw-block tw-min-h-[32px]'>{detail.job_title}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="level" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Level</label>
                                <div className="col-sm-8">
                                    <span className='tw-px-[7px] tw-py-[5px] tw-rounded tw-bg-stone-100 tw-block tw-min-h-[32px]'>{detail.level}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="grade" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Grade</label>
                                <div className="col-sm-8">
                                    <span className='tw-px-[7px] tw-py-[5px] tw-rounded tw-bg-stone-100 tw-block tw-min-h-[32px]'>{detail.grade}</span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form-group row">
                                <label htmlFor="current_point" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Current Point</label>
                                <div className="col-sm-8">
                                    <span className='tw-px-[7px] tw-py-[5px] tw-rounded tw-bg-stone-100 tw-block tw-min-h-[32px]'>{detail.current_point}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="attended_ratio" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Attended Ratio</label>
                                <div className="col-sm-8">
                                    <span className='tw-px-[7px] tw-py-[5px] tw-rounded tw-bg-stone-100 tw-block tw-min-h-[32px]'>{detail.attended_ratio}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="maritial_status" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Maritial Status</label>
                                <div className="col-sm-8">
                                    <span className='tw-px-[7px] tw-py-[5px] tw-rounded tw-bg-stone-100 tw-block tw-min-h-[32px]'>{detail.maritial_status}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="function" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Fungsi</label>
                                <div className="col-sm-8">
                                    <span className='tw-px-[7px] tw-py-[5px] tw-rounded tw-bg-stone-100 tw-block tw-min-h-[32px]'>{detail.function}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="division" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Divisi</label>
                                <div className="col-sm-8">
                                    <span className='tw-px-[7px] tw-py-[5px] tw-rounded tw-bg-stone-100 tw-block tw-min-h-[32px]'>{detail.division}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="department" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Department</label>
                                <div className="col-sm-8">
                                    <span className='tw-px-[7px] tw-py-[5px] tw-rounded tw-bg-stone-100 tw-block tw-min-h-[32px]'>{detail.department}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="section" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Section</label>
                                <div className="col-sm-8">
                                    <span className='tw-px-[7px] tw-py-[5px] tw-rounded tw-bg-stone-100 tw-block tw-min-h-[32px]'>{detail.section}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="direktorat" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Direktorat</label>
                                <div className="col-sm-8">
                                    <span className='tw-px-[7px] tw-py-[5px] tw-rounded tw-bg-stone-100 tw-block tw-min-h-[32px]'>{detail.directorat}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="nik_direct_supervisor" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">NIK Direct Supervisor</label>
                                <div className="col-sm-8">
                                    <span className='tw-px-[7px] tw-py-[5px] tw-rounded tw-bg-stone-100 tw-block tw-min-h-[32px]'>{detail.nik_direct_supervisor}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="direct_supervisor_name" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Nama Direct Supervisor</label>
                                <div className="col-sm-8">
                                    <span className='tw-px-[7px] tw-py-[5px] tw-rounded tw-bg-stone-100 tw-block tw-min-h-[32px]'>{detail.direct_supervisor_name}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="nik_indirect_supervisor" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">NIK Indirect Supervisor</label>
                                <div className="col-sm-8">
                                    <span className='tw-px-[7px] tw-py-[5px] tw-rounded tw-bg-stone-100 tw-block tw-min-h-[32px]'>{detail.nik_indirect_supervisor}</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="indirect_supervisor_name" className="col-sm-4 col-form-label col-form-label-sm font-weight-normal">Nama Indirect Supervisor</label>
                                <div className="col-sm-8">
                                    <span className='tw-px-[7px] tw-py-[5px] tw-rounded tw-bg-stone-100 tw-block tw-min-h-[32px]'>{detail.indirect_supervisor_name}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='tw-flex tw-full tw-justify-between'>
                                <button type='button' className='btn btn-sm btn-secondary'
                                    onClick={() => history.replace({
                                        pathname: "/master-data/demografi-employee",
                                        state: state
                                    })}
                                >Kembali</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;