import React from 'react';
import { Modal } from 'react-bootstrap';
import useTirOnline from '../useTirOnline';

function ModalTitleForm(props) {
    const { tirAction, tirState: {
        modalAddForm: {
            show = false,
            title = '',
            data = {}
        }
    } } = useTirOnline();
    return (
        <div>
            <Modal
                dialogClassName='modal-editor-trainee'
                backdropClassName='bg-transparent'
                show={show}
                onHide={() => tirAction.setModalAddForm({ show: false, title: '' })}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Judul Form
                    </Modal.Title>
                </Modal.Header>
                <form
                    onSubmit={(e) => {
                        e.preventDefault(e);
                        tirAction.setModalAddForm({ show: false, title: '' });
                        tirAction.toFormPage({ ...data, title }, false);
                    }}
                >

                    <Modal.Body>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Judul Form</label>
                            <div className="col-sm-8">
                                <input
                                    value={title}
                                    onChange={(e) => tirAction.setModalAddForm((prev) => ({ ...prev, title: e.target.value }))}
                                    required
                                    placeholder='Judul form lengkap'
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            type='button'
                            onClick={() => tirAction.setModalAddForm({ show: false, title: '' })}
                            className='btn btn-sm btn-secondary mr-1'>Cancel</button>
                        <button type='submit' className='btn btn-sm btn-success mr-1'>Save</button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    );
}

export default ModalTitleForm;