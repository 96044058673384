import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useModalAlert } from "../../../components/modalAlert";
import useFetch from "../../../hooks/useFetch";

const menAdminContext = createContext({
    menAdminState: {},
    menAdminAction: {}
});
const iniStatePage = {
    title: "Search Pengawas",
    type: "SearchPage",
    dataPage: {
    }
};
const iniStateForm = {
    "job_mentoring_id": null,
    "training_id": null,
    "trainee_nik": "",
    "trainee_name": "",
    "trainer_nik": "",
    "trainer_name": "",
    "section": "",
    "supervisor_nik": "",
    "supervisor_name": "",
    "production_type": "",
    "date": "",
    "order_mentoring_activity": "",
    "position": "",
    "location": "",
    "duration": "",
    "constraint_supporting": "",
    "note": "",
}

export function MenAdminProvider({ children }) {
    const { doGet, doGetMultiple } = useFetch();
    const { modalAction } = useModalAlert();
    const {
        openLoading,
        closeLoading,
        setDataAlert,
    } = modalAction;
    const [pageState, setPageState] = useState(iniStatePage);
    const [searchNik, setSearchNik] = useState('');

    //page state management
    const toSearchPage = useCallback(() => {
        setPageState({ ...iniStatePage });
    }, [pageState]);
    const toTraineePage = useCallback((param = {}) => {
        setPageState({
            title: "List Pengawas",
            type: "ListTraineePage",
            dataPage: { ...param }
        })
    }, [pageState, searchNik]);
    const toTrainingPage = useCallback((param = {}) => {
        setPageState({
            title: "List Course",
            type: "ListTrainingPage",
            dataPage: { ...param }
        })
    }, [pageState, searchNik]);
    const toFormPage = useCallback((param = {}) => {
        setFormData({...iniStateForm});
        setFormCheck({});
        setListForm({});
        setPageState({
            title: "Form Detail",
            type: "FormPage",
            dataPage: { ...param }
        })
    }, [pageState])

    //get data page
    const [paginationTrainee, setPaginationTraineeTrainee] = useState({ page: 1, show: 10, order_by: "trainee_name", order_type: "asc" });
    const [loadingTrainee, setLoadingTrainee] = useState(false);
    const [dataTrainee, setDataTrainee] = useState({});
    const getListTrainee = useCallback(async function () {
        try {
            setLoadingTrainee(true);
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/job-assignment/list-trainee',
                    param: {
                        "search": searchNik,
                        "page": paginationTrainee.page,
                        "row_in_page": paginationTrainee.show,
                        "order_by": paginationTrainee.order_by,
                        "order_type": paginationTrainee.order_type,
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDataTrainee({ ...data });
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get List Trainee",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List Trainee",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Trainee",
                callBack: () => null
            })
        }
        setLoadingTrainee(false)
    }, [pageState, paginationTrainee, searchNik]);
    const [loadingTraining, setLoadingTraining] = useState(false)
    const [dataTraining, setDataTraining] = useState({});
    const getListTraining = useCallback(async function () {
        setLoadingTraining(true);
        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/job-mentoring/list-courses',
                    param: {
                        trainee_nik: searchNik
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDataTraining(data);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get List Training",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List Training",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Training",
                callBack: () => null
            })
        }
        setLoadingTraining(false)
    }, [pageState, searchNik]);
    const [loadingForm, setLoadingForm] = useState(false)
    const [listForm, setListForm] = useState({});
    const [formData, setFormData] = useState(iniStateForm);
    const [formCheck, setFormCheck] = useState({});
    const getForm = useCallback(async function () {
        openLoading("Please wait...");
        setLoadingForm(true);
        try {
            const { resp1, resp2 } = await doGetMultiple([
                {
                    url: '/api/job-mentoring/form',
                    param: {
                        training_id: pageState.dataPage?.training_id
                    },
                    responseVar: "resp1"
                },
                {
                    url: '/api/job-mentoring/detail-answer',
                    param: {
                        training_id: pageState.dataPage?.training_id
                    },
                    responseVar: "resp2"
                }
            ])
            //handle request1
            if (resp1?.status === 401) {
                closeLoading();
            }
            else if (resp1?.status === 200) {
                const data = { ...resp1?.payload };
                if (data?.code === "404") {
                    closeLoading();
                    return setDataAlert({
                        show: true,
                        body: data?.msg,
                        header: "Get List Form",
                        callBack: () => null
                    });
                } else {
                    setListForm({ ...data });
                }
            } else setDataAlert({
                show: true,
                body: resp1?.msg,
                header: "Get List Form",
                callBack: () => null
            })

            //handle request2
            if (resp2?.status === 401) {
                closeLoading();
            }
            else if (resp2?.status === 200) {
                const data = { ...resp2?.payload };
                if (data?.code === "404") {
                    closeLoading();
                    return setDataAlert({
                        show: true,
                        body: data?.msg,
                        header: "Get Answer Form",
                        callBack: () => null
                    });
                } else {
                    const new_data = {
                        status: data?.status,
                        "job_mentoring_id": data?.job_mentoring_id,
                        "supervisor_nik": data?.supervisor_nik,
                        "supervisor_name": data?.supervisor_name,
                        "date": data?.date,
                        "order_mentoring_activity": data?.order_mentoring_activity,
                        "location": data?.location,
                        "duration": data?.duration,
                        "constraint_supporting": data?.constraint_supporting,
                        "note": data?.note,
                        training_id: data?.training_id,
                        trainee_nik: data?.trainee_nik,
                        trainee_name: data?.trainee_name,
                        section: data?.section,
                        trainer_name: data?.trainer_name,
                        trainer_nik: data?.trainer_nik,
                        position: data?.position,
                        production_type: data?.production_type,
                        achievement: data?.achievement,
                        form_name: data?.form_name
                    }
                    const answer = [...(data?.answer_lessons || [])]?.reduce((prev, obj, i) => ({
                        ...prev, [obj?.form_id]: {
                            value: obj?.answer,
                            answer_id: obj?.answer_id,
                            form_id: obj?.form_id,
                        }
                    }), {});
                    setFormData((e) => ({
                        ...e,
                        ...new_data,
                    }));
                    setFormCheck({
                        ...answer
                    })
                }
            } else setDataAlert({
                show: true,
                body: resp2?.msg,
                header: "Get Answer Form",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Form",
                callBack: () => null
            })
        }
        setLoadingForm(false);
        closeLoading();
    }, [pageState]);

    //pagination Page trainee handle
    function nextPageTrainee({ page, limit }) {
        setPaginationTrainee(prevState => ({
            ...prevState,
            page, show: limit,
        }))
    }
    function prevPageTrainee({ page, limit }) {
        setPaginationTrainee(prevState => ({
            ...prevState,
            page, show: limit,
        }))
    }
    function changeShowTrainee({ page, limit }) {
        setPaginationTrainee(prevState => ({
            ...prevState,
            page, show: limit,
        }))
    }


    useEffect(() => {
        if (pageState.type === 'ListTraineePage') getListTrainee();
        if (pageState.type === 'ListTrainingPage') getListTraining();
        if (pageState.type === 'FormPage') getForm();
    }, [pageState, paginationTrainee]);
    return <menAdminContext.Provider
        value={{
            menAdminState: {
                pageState,
                searchNik,
                loadingTrainee,
                dataTrainee,
                paginationTrainee,
                loadingTraining,
                dataTraining,
                loadingForm,
                listForm,
                formData,
                formCheck
            },
            menAdminAction: {
                toSearchPage,
                toTraineePage,
                toTrainingPage,
                toFormPage,
                setSearchNik,
                nextPageTrainee,
                prevPageTrainee,
                changeShowTrainee
            }
        }}
    >
        {children}
    </menAdminContext.Provider>
}

export default function useMentoringAdmin() {
    return useContext(menAdminContext);
}