import React from 'react';
const pages = (namePage) =>
    React.lazy(() =>
        import('../pages')
            .then((m) => ({ default: m[namePage] }))
            .catch((err) => err)
    );
export const routes = [
    {
        key: "/",
        component: pages("Dashboard"),
        exact: true
    },
    {
        key: "/all-notification",
        component: pages("AllNotifPage"),
        exact: true
    },
    {

        key: "/idp-online/register",
        component: pages("IDPRegPage"),
        exact: true
    },
    {

        key: "/idp-online/status",
        component: pages("IDPStatPage"),
        exact: true
    },
    {

        key: "/idp-online/download",
        component: pages("IDPDownloadPage"),
        exact: true
    },
    {

        key: "/idp-online/approval",
        component: pages("IDPApproval"),
        exact: true
    },
    {

        key: "/idp-online/approval/detail/:nik",
        component: pages("IDPApprovalDetail"),
        exact: true
    },
    {

        key: "/personal-info",
        component: pages("PersonalInfoPage"),
        exact: true
    },
    {

        key: "/ats/ats-create",
        component: pages("ATSCreate"),
        exact: true
    },
    {

        key: "/ats/ats-create/list-idp",
        component: pages("ATSCreateListIDP"),
        exact: true
    },
    {

        key: "/ats/ats-create/create-schedule",
        component: pages("ATSCreateCS"),
        exact: true
    },
    {

        key: "/ats/ats-status",
        component: pages("ATSStatusListJobsite"),
        exact: true
    },
    {

        key: "/ats/ats-status/list-idp",
        component: pages("ATSStatusListIDP"),
        exact: true
    },
    {

        key: "/ats/mts-create",
        component: pages("MTSCreateLsJobsite"),
        exact: true
    },
    {

        key: "/ats/mts-create/list-idp",
        component: pages("MTSCreateLSIDP"),
        exact: true
    },
    // {

    //     key: "/ats/mts-create/create-mts",
    //     component: pages("MTSCreateCreateUpdate"),
    //     exact: true
    // },
    {

        key: "/development/training-schedule",
        component: pages("TrainingSchedulePage"),
        exact: true
    },
    {

        key: "/development/training-schedule-trainer",
        component: pages("TrainingScheduleTrainer"),
        exact: true
    },  
    {

        key: "/development/tir-for-trainer",
        component: pages("TirForTrainer"),
        exact: true
    },  
    {

        key: "/development/tir-for-trainer/detail",
        component: pages("TirForTrainerDetail"),
        exact: true
    },  
    {

        key: "/development/tir-for-trainer/form",
        component: pages("TirForTrainerForm"),
        exact: true
    },    
    {

        key: "/development/learn-mng-system",
        component: pages("LMSPage"),
        exact: true
    },

    {

        key: "/development/data-result-training",
        component: pages("ResultTraining"),
        exact: true
    },
    {

        key: "/development/data-result-training/trainee",
        component: pages("DRSTrainer"),
        exact: true
    },
    {

        key: "/development/data-result-training/list-trainee/:competency_id/:course_id/:batch",
        component: pages("ResultTrainee"),
        exact: true
    },
    {

        key: "/development/upload-assignment",
        component: pages("UploadJobPage"),
        exact: true
    },
    {

        key: "/development/job-assignment",
        component: pages("JobAssigmentAdmin"),
        exact: true
    },
    {

        key: "/development/job-assignment/detail-trainee",
        component: pages("JobAssigmentDetail"),
        exact: true
    },
    {

        key: "/development/job-assignment/list",
        component: pages("JobAssigmentPeriksa"),
        exact: true
    },
    {
        key: "/development/tir-online",
        component: pages("TirOnlinePage"),
        exact: true
    },
    {
        key: "/development/approval-tir-online",
        component: pages("ApprovalTOPage"),
        exact: true
    },
    // {
    //     key: "/development/review-tir-online",
    //     component: pages("ReviewTOPage"),
    //     exact: true
    // },
    {
        key: "/hm-operator/input-hm-operator",
        component: pages("HMOPage"),
        exact: true
    },
    {
        key: "/hm-operator/approval-hm-operator",
        component: pages("ApprovalHMOPage"),
        exact: true
    },
    {
        key: "/hm-operator/pencapaian-hm-operator",
        component: pages("PencapaianHMOPage"),
        exact: true
    },
    {
        key: "/assesment/status",
        component: pages("AssesmentStatus"),
        exact: true
    },
    {
        key: "/assesment/create-schedule",
        component: pages("AssesmentCreateSch"),
        exact: true
    },
    {
        key: "/assesment/schedule",
        component: pages("AssesmentSch"),
        exact: true
    },
    {
        key: "/assesment/result-assesment",
        component: pages("AssesmentResult"),
        exact: true
    },
    {
        key: "/development/mentoring-observation",
        component: pages("MentoringPage"),
        exact: true
    },
    {
        key: "/development/mentoring-observation-admin",
        component: pages("MentoringAdminPage"),
        exact: true
    },
    {
        key: "/development/status-mentoring-observation",
        component: pages("MentoringStatusPage"),
        exact: true
    },
    {
        key: "/development/approval-mentoring-observation",
        component: pages("MentoringApprovalPage"),
        exact: true
    },
    {
        key: "/chart/dummy",
        component: pages("ChartDummy"),
        exact: true
    },
    {
        key: "/upload-ar",
        component: pages("ARPage"),
        exact: true
    },
    {
        key: "/user-management/reset-password",
        component: pages("ResetPassPage"),
        exact: true
    },
    {
        key: "/attended-ratio",
        component: pages("AttPage"),
        exact: true
    },{
        key: "/master-data/idp-register",
        component: pages("MasterIdpPage"),
        exact:true
    },{
        key: "/master-data/idp-register/:tipe",
        component: pages("MasterIdpForm"),
        exact:true
    }
    ,{
        key: "/master-data/demografi-employee",
        component: pages("MasterDemEmpPage"),
        exact:true
    }
    ,{
        key: "/master-data/demografi-employee/add",
        component: pages("MasterDemEmpAddPage"),
        exact:true
    }
    ,{
        key: "/master-data/demografi-employee/detail/:nik",
        component: pages("MasterDemEmpDetailPage"),
        exact:true
    }
    ,{
        key: "/master-data/demografi-employee/edit/:nik",
        component: pages("MasterDemEmpEditPage"),
        exact:true
    }
    ,{
        key: "/master-data/demografi-employee/bulk-upload",
        component: pages("MasterDemEmpBulkUploadPage"),
        exact:true
    }
    ,{
        key: "/master-data/demografi-employee/update-atasan",
        component: pages("MasterDemEmpUpdateAtasanPage"),
        exact:true
    }   
    ,{
        key: "/download-data/job-assignment",
        component: pages("DwnldDataJaPage"),
        exact:true
    }
    ,{
        key: "/download-data/tir-online",
        component: pages("DwnldDataTirPage"),
        exact:true
    }
    ,{
        key: "/download-data/hm-operator",
        component: pages("DwnldDataHmPage"),
        exact:true
    },
    ,{
        key: "/download-data/job-mentoring",
        component: pages("DwnldDataJMPage"),
        exact:true
    },
    {
        key: "/download-data/employee",
        component: pages("DwnldDataEmployeePage"),
        exact:true
    },
    {
        key: "/download-data/competency-report",
        component: pages("DwnldDataCompReportPage"),
        exact:true
    },
    {
        key: "/download-data/assesment",
        component: pages("DwnldDataAssesment"),
        exact:true
    },
    
    {
        key: "/master-data/training-non-idp",
        component: pages("MasterNonIdpPage"),
        exact:true
    },{
        key: "/master-data/training-non-idp/:tipe",
        component: pages("MasterNonIdpForm"),
        exact:true
    },
    {
        key: "/master-data/assessment-non-idp",
        component: pages("MaterAssNonIdpPage"),
        exact:true
    } 
    ,{
        key: "/master-data/assessment-non-idp/:tipe",
        component: pages("MasterAssNonIdpForm"),
        exact:true
    },
    {
        key: "/master-data/competency-report",
        component: pages("MasterCompReportPage"),
        exact:true
    },{
        key: "/master-data/competency-report/:tipe",
        component: pages("MasterCompReportForm"),
        exact:true
    },
    {
        key: "/report-dasboard/employee",
        component: pages("ReportDataEmployeePage"),
        exact:true
    },
    {
        key: "/report-dasboard/competency-passed",
        component: pages("ReportDataCompPassedPage"),
        exact:true
    },
    {
        key: "/report-dasboard/started-training",
        component: pages("ReportDataStartTrainingPage"),
        exact:true
    },
    {
        key: "/report-dasboard/trainer-did-training",
        component: pages("ReportDataTrainerDidTrainingPage"),
        exact:true
    },
    {
        key: "/master-data/matrix-competency",
        component: pages("MasterMatrixComp"),
        exact:true
    },{
        key: "/master-data/matrix-competency/:tipe",
        component: pages("MasterMatrixCompForm"),
        exact:true
    },{
        key: "/master-data/course-matrix-competency/:ctipe",
        component: pages("MasterCourseMatrixCompForm"),
        exact:true
    },
]

