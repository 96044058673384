import React from 'react';
import { useHistory } from 'react-router-dom';
import { StatusIcon, TableList } from '../../../../components';
import useResultTraining, { ResultTrainingProvider } from './useResultTraining';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import ModalTrainee from '../../components/modalTrainee/modalTrainee';

function ResultListTraining(props) {
    const history = useHistory();
    const { rsTrngAction, rsTrngState } = useResultTraining();
    return (
        <div>
            <Modal
                dialogClassName='modal-mts-create'
                // backdropClassName='bg-transparent'
                show={rsTrngState.modalTrainee?.show}
                onHide={() => rsTrngAction.setModalTrainee({ show: false, data: [] })}
            >
                <Modal.Header closeButton>
                    <Modal.Title>List Trainee</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ModalTrainee {...rsTrngState.modalTrainee} />
                </Modal.Body>
            </Modal>

            <div className='row'>
                <div className='col-6'>
                    <section className="content-header">
                        <div className="container-fluid">
                            <h4>Training Result</h4>
                            <h6>List Training</h6>
                        </div>
                    </section>
                </div>
                <div className='col-6'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end">
                            <li className="breadcrumb-item active" aria-current="page">List Training</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <TableList
                filter={true}
                formsFilter={[
                    {
                        type: 'custom',
                        component: (
                            <input type='number' className='form-control form-control-sm'
                                placeholder='Batch'
                                min={0}
                                value={rsTrngState?.batch}
                                onChange={(val) => rsTrngAction.setBatch(val.target.value)}
                            >
                            </input>
                        )
                    }
                ]}
                labelFilter="Tahun IDP Register :"
                submitButton={<><i className='fa fa-search'></i> Search</>}
                onFilter={() => rsTrngAction.onFilter()}
                resetFilter={false}
                curPage={1}
                minHeight="300px"
                maxHeight="500px"
                navtable={"TABLE 1"}
                // onChangeShow={rekAction.changeShow}
                // onNextPage={rekAction.nextPage}
                // onPrevPage={rekAction.prevPage}
                isLoading={rsTrngState.loadingGet}
                withActions={true}
                actionType={1}
                actionItems={[
                    {
                        name: 'Lihat detail',
                        onClick: function (item) {
                            history.push({
                                pathname: `/development/data-result-training/list-trainee/${item?.competency_id}/${item?.course_id}/${rsTrngState?.batch}`,
                                state: { header: { ...item } }
                            })
                        }
                    },
                ]}
                columns={[
                    {
                        name: '#',
                        field: '#',
                        maxWidth: '50px',
                    },
                    {
                        name: "Jobsite",
                        field: 'jobsite',
                        minWidth: '100px',
                    },
                    {
                        name: 'Competency Name',
                        field: 'competency_name',
                        maxWidth: '220px',
                    },
                    {
                        name: 'Course Name',
                        field: 'course_name',
                        maxWidth: '250px',
                    },
                    {
                        name: 'Trainer',
                        field: 'trainer_name',
                        maxWidth: '150px',
                        onRender: (item) => {
                            return (
                                <span className='text-wrap'>{`${item?.trainer_name || ''} - ${item?.trainer_nik || ''}`}</span>
                            )
                        }
                    },
                    {
                        name: 'Trainee',
                        field: 'trainees',
                        maxWidth: '300px',
                        onRender: (item) => {
                            return (
                                <div>
                                    {
                                        [...(item?.trainees || [])].slice(0, 5).map((d, i) => (
                                            <span className='badge badge-info mr-1'>
                                                {
                                                    d?.trainee_name
                                                }
                                            </span>
                                        ))
                                    }
                                    {
                                        [...(item?.trainees || [])].length > 5 && (
                                            <div className='d-block'><span type='button' className="badge badge-warning"
                                                onClick={() => rsTrngAction.setModalTrainee({ show: true, data: [...(item?.trainees || [])] })}
                                            >See Detail</span></div>
                                        )
                                    }
                                </div>
                            )
                        }
                    },                   
                    {
                        name: 'Status',
                        field: 'status_filled_score',
                        maxWidth: '115px',
                        onRender: (item) => {
                            return (
                                <div className='tw-w-[110px]'>
                                    <StatusIcon status={item?.status_filled_score} />                                    
                                </div>
                            )
                        }
                    },
                ]}
                data={[...(rsTrngState.dataList || [])]}
            />
        </div>
    );
}

export default (props) => <ResultTrainingProvider><ResultListTraining {...props} /> </ResultTrainingProvider>;
