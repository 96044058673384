import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useModalAlert } from "../../../../components/modalAlert";
import useFetch from "../../../../hooks/useFetch";
import ModalDetil from "./modalDetil";
import ModalDecline from "./modalDecline";

const prjContext = createContext({
    prjState: {},
    prjAction: {}
});
export function PrjProvider({ children }) {
    const history = useHistory();
    const [dataState, setdataState] = useState(history.location.state);
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const { doGet, doPost } = useFetch();
    const [loadingGet, setLoadingGet] = useState(false);
    const [data, setData] = useState([]);


    async function getList() {
        try {
            setLoadingGet(true);
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/job-assignment/list',
                    param: {
                        training_id: dataState?.training_id || ''
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = [], code, msg } = payload;
                if (code === "00") {
                    setData([...data]);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Job Assignment",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Job Assignment",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get Job Assignment",
                callBack: () => null
            })
        }
        setLoadingGet(false)
    }
    function confirmSubmit(status = '', job_assignment_id = 0, note = "") {
        // let formType = paramUrl.mode === "edit" ? "ubah data" : "pendaftaran";
        let body = `Sebelum melanjutkan, cek kembali data yang akan di ${status === 'approved' ? "Approved" : "Decline"}. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Yes", onClick: () => doSubmit(status, job_assignment_id, note), className: "btn btn-sm btn-success" },
                { name: "No", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    async function doSubmit(stts = '', job_assignment_id = 0, note = "") {
        closeConfirm()
        openLoading("Sending data, please wait...");
        try {
            const { status, payload, statusText } = await doPost({
                url: "/api/job-assignment/update-status",
                data: {
                    'job_assignment_id': job_assignment_id,
                    'status': stts,
                    note
                },
                method: 'PUT'
            });

            if (status !== 401) {
                if (status === 200 || status === 201) {
                    const { code, msg } = payload;
                    if (code === "00") {
                        setDataAlert({
                            body: msg,
                            show: true,
                            header: "Success",
                            callBack: () => getList()
                        })
                    } else setDataAlert({
                        show: true,
                        body: msg,
                        header: "Info",
                        callBack: () => null
                    })
                } else setDataAlert({
                    show: true,
                    body: statusText,
                    header: "Info",
                    callBack: () => null
                })
            }
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Info",
                callBack: () => null
            })
        }
        closeLoading();
    }
    useEffect(() => {
        if (!dataState?.training_id) {
            return setDataAlert({
                show: true,
                body: "Buka halaman ini melalui menu Job Assigment [Trainer]",
                header: "Trainee tidak di pilih",
                callBack: () => null
            })
        }
        getList();
    }, [])

    const [dataModal, setDataModal] = useState({
        show: false,
        data:{}
    })


    const openModalDetil = useCallback(function (data = {}) {
        setDataModal(prevState => ({ show: true, data }))
    }, [])

    const closeModalDetil = useCallback(function () {
        setDataModal({ show: false, data })
    }, [])

    const [modalDecline, setModalDecline] = useState({
        show: false,
        data: {}
    });
    const openModalDecline = useCallback(function (data = {}) {
        setModalDecline({ show: true, data })
    }, [])
    const closeModalDecline = useCallback(function () {
        setModalDecline({ show: false, data: {} })
    }, [])

    return <prjContext.Provider
        value={{
            prjState: {
                data,
                loadingGet,
                dataState,
                dataModal,
                modalDecline
            },
            prjAction: {
                confirmSubmit,
                closeModalDetil,
                openModalDetil,
                openModalDecline,
                closeModalDecline
            }
        }}
    >
        <ModalDecline/>
        <ModalDetil />
        {children}</prjContext.Provider>
}
export default function usePeriksaJob() {
    return useContext(prjContext);
}