import React, { createContext, useContext, useEffect, useState } from "react";
import { useModalAlert } from "../../../components/modalAlert";
import useFetch from "../../../hooks/useFetch";
import ModalTrainee from "./components/modal-trainee";

const scaContext = createContext({
    assState: {},
    assAction: {}
});
const iniStateModalTrainee = {
    show: false,
    data: [],
    dataFilter: [],
    search: ''
}
export function ScaProvider({ children }) {
    const [loadingTraining, setLoadingTraining] = useState(false)
    const [listTraining, setListTraining] = useState([]);
    const [modalTrainee, setModalTrainee] = useState(iniStateModalTrainee);
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const { doGet, doPost } = useFetch();

    const [batch, setBatch] = useState('');
    const getListTraining = async function () {
        setLoadingTraining(true);

        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/assessment/training-list-result',
                    param: {
                        type_page: "result_training",
                        batch:batch
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    // console.log(data)
                    setListTraining(data?.tableData || []);
                    setBatch(data?.batch);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get List Training",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List Training",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Training",
                callBack: () => null
            })
        }
        setLoadingTraining(false)
    };

    const openModalTrainee = (data = []) => {
        setModalTrainee({
            show: true,
            data: data,
            dataFilter: data,
            search: ''
        })
    }
    const closeModalTrainee = () => {
        setModalTrainee({ ...iniStateModalTrainee });
    }
    const onFilterModalTrainee = () => {
        let newData = [];
        newData = modalTrainee.data.filter((e) => {
            return (e.trainee_name || '').toLowerCase().indexOf(modalTrainee.search.toLowerCase()) > -1 || (e.trainee_nik || '').toLowerCase().indexOf(modalTrainee.search.toLowerCase()) > -1
        });
        setModalTrainee((e) => ({
            ...e,
            dataFilter: newData
        }));
    }
    const onFilterBatch = () => {
        getListTraining();
    }
    useEffect(() => {
        getListTraining();
    }, [])

    return <scaContext.Provider
        value={{
            assState: {
                listTraining,
                loadingTraining,
                modalTrainee,
                batch
            },
            assAction: {
                openModalTrainee,
                closeModalTrainee,
                onFilterModalTrainee,
                setModalTrainee,
                setBatch,
                onFilterBatch
            }
        }}
    >
        {children}
        <ModalTrainee />
    </scaContext.Provider>
}
export default function useSca() {
    return useContext(scaContext);
}