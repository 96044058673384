import moment from 'moment';
import React, { useEffect } from 'react';
import { DateInput, StatusIcon, TableList } from '../../../components';
import useInputHmOperator from './useInputHmOperator';

export default () => {
    const {
        HMOActions: {
            resetPage = () => null,
            toInputPage = () => null,
            doDelete = () => null,
            setFormHm = () => null,
            postHm = () => null
        },
        HMOState: {
            listTraining = [],
            pageStatus: {
                isLoading = true,
                paramState: {
                    course_name = "",
                    training_id = 0,
                    competency_name = ""
                },
            },
            formHm = {},
            listHm = []
        }
    } = useInputHmOperator()

    return (<>



        <section className="content-header">
            <div className="container-fluid">
                <h4>Hours Meter (HM) Operator</h4>
                <h6>Input HM Operator</h6>
            </div>
        </section>
        <form onSubmit={(e) => {
            e.preventDefault();
            postHm()
        }}>
            <div className='row'>
                <div className='col'>
                    <div className='card'>
                        <div className='card-header'>
                            <div className='card-title'>
                                Input {course_name}
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label>Tanggal</label>
                                        <DateInput
                                            className='form-control'
                                            required={true}
                                            value={formHm.date}
                                            onChange={(va) => { console.log(va)
                                                setFormHm(v => ({ ...v, date: va }))
                                            }}
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label>Shift</label>
                                        <select className='form-control'
                                            onChange={({ target: { value } }) => setFormHm(v => ({ ...v, shift: value }))}
                                            value={formHm.shift}
                                            required={true}
                                        >
                                            <option value={""}> -- select shift -- </option>
                                            <option value={1}> 1 </option>
                                            <option value={2}>2</option>
                                        </select>
                                    </div>
                                    <div className='form-group'>
                                        <label>Training Unit</label>
                                        <input className='form-control'
                                            required={true}
                                            placeholder="Training Unit"
                                            value={formHm.trainingUnit}
                                            onChange={({ target: { value } }) => setFormHm(v => ({ ...v, trainingUnit: value }))}
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label>Standard Competency</label>
                                        <input className='form-control'
                                            required={true}
                                            placeholder="Standard Competency"
                                            value={formHm.competecyStd}
                                            onChange={({ target: { value } }) => setFormHm(v => ({ ...v, competecyStd: value }))}
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label>Description</label>
                                        <textarea rows={3} className='form-control'
                                            required={true}
                                            placeholder="Description"
                                            value={formHm.description}
                                            onChange={({ target: { value } }) => setFormHm(v => ({ ...v, description: value }))}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label>HM On</label>
                                        <input min={0} type='number' className='form-control'
                                            required={true}
                                            placeholder="HM ON"
                                            value={formHm.hmOn}
                                            onChange={({ target: { value } }) => setFormHm(v => ({ ...v, hmOn: value }))}
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label>HM Off</label>
                                        <input min={0} type='number' className='form-control'
                                            required={true}
                                            placeholder="HM OFF"
                                            value={formHm.hmOff}
                                            onChange={({ target: { value } }) => setFormHm(v => ({ ...v, hmOff: value }))}
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label>HM Total</label>
                                        <input disabled className='form-control'
                                            required={true}
                                            placeholder="HM Total"
                                            value={formHm.hmTotal}
                                            onChange={({ target: { value } }) => setFormHm(v => ({ ...v, hmTotal: value }))}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='card-footer'>
                            <div className='row'>
                                <div className='col'>
                                    <button
                                        type='submit'
                                        className='btn btn-primary float-right'
                                    >Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div className='row'>
            <div className='col'>
                <div className='card'>
                    <div className='card-header'>
                        <div className='card-title'>
                            <h5> List Hm </h5>
                        </div>
                    </div>
                    <div className='card-body'>
                        <div className='row mt-2'>
                            <div className='col-md-12'>
                                <TableList
                                    isLoading={isLoading}
                                    minHeight='max-content'
                                    withCard={false}
                                    withActions={false}
                                    filter={false}
                                    data={listHm || []}
                                    pagination={false}
                                    columns={[
                                        {
                                            name: "Date",
                                            onRender:({date}) => moment(date).format("DD/MM/YYYY")
                                        },
                                        {
                                            name: "Shift",
                                            field:"shift"
                                        },
                                        {
                                            name: "Unit Training",
                                            field:"training_unit"
                                        },
                                        {
                                            name: "Std. Kompetensi",
                                            field:"competecy_std"
                                        },
                                        {
                                            name: "Deskripsi Kegiatan",
                                            field:"description"
                                        },
                                        {
                                            name: "HM Engine ON",
                                            field:"hm_on"
                                        },
                                        {
                                            name: "HM Engine OFF",
                                            field:"hm_off"
                                        },
                                        {
                                            name: "HM Total",
                                            field:"hm_total"
                                        },
                                        {
                                            name: "Status",
                                             onRender: ({ status }) => <StatusIcon status={status}/> //<span style={{textTransform:"capitalize"}} className={`
                                            // badge badge-${status === "declined" ? "danger" :
                                            //         status === "approved" ? "success" :
                                            //             status === "submitted" ? "primary" : ""
                                            //     }`}>
                                            //     { status}
                                            // </span>
                                        },
                                        {
                                            name: "",
                                            className: "text-right",
                                            onRender: ({id, status}) => (status === "submitted" || status === "declined") && <>
                                                <button onClick={()=> doDelete(id)} className='btn btn-sm btn-danger'>
                                                    Delete    
                                                </button>
                                            </>
                                            
                                        }
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='card-footer'>
                        <button onClick={resetPage} className="btn btn-default"> Back </button>
                    </div>
                </div>
            </div>
        </div>



    </>)
}