import React from 'react';
import { Link } from 'react-router-dom';
import * as pages from './pages';
import useResultA, { RaProvider } from './useResultA';

function Index(props) {
    const { raState, raAction } = useResultA();
    const PageRender = pages[raState.pageState.type];
    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <div className='row'>
                        <div className='col-md-6'>
                            <h4>Assesment Result</h4>
                            <h6>{
                                raState.pageState.title
                            }</h6>
                        </div>
                        <div className='col-md-6'>
                            {
                                raState.pageState.type !== "ResultTraining" ? (
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb justify-content-end">
                                            <li className="breadcrumb-item" onClick={() => raAction.toTrainingPage()}><Link to={"#"}>List Training</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">{raState.pageState.title}</li>
                                        </ol>
                                    </nav>
                                ) : (
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb justify-content-end">
                                            <li className="breadcrumb-item active" aria-current="page">{raState.pageState.title}</li>
                                        </ol>
                                    </nav>
                                )
                            }
                        </div>
                    </div>
                </div>
            </section>
            <PageRender />
        </>
    );
}

export default (props) => <RaProvider><Index {...props} /></RaProvider>;