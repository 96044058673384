import React from 'react';
import { Modal } from 'react-bootstrap';
import usePHMO from './usePHMO';

function ModalDecline(props) {
    const {
        PHMOAction: {
            closeModalDecline = () => null,
            doApproval = () => null,
            setNote = () => null
        },
        PHMOState: {
            modalDecline: {
                show = false,
                data = {
                    note: ''
                }
            }
        }
    } = usePHMO();
    return (
        <Modal
            show={show}
            backdropClassName='bg-transparent'
            onHide={() => closeModalDecline(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Decline</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form
                    onSubmit={(e) => {
                        e.preventDefault(e);
                        closeModalDecline(false);
                        doApproval(data.id, "declined", data?.note)
                    }}
                >
                    <textarea
                        value={data?.note}
                        onChange={(e) => setNote(e.target.value)}
                        row={5}
                        required
                        className='form-control form-control-sm'
                    ></textarea>
                    <div className='text-right mt-3'>
                        <button
                            type='button'
                            onClick={() => closeModalDecline(false)}
                            className='btn btn-sm btn-secondary mr-1'>Batal</button>
                        <button
                            type='submit'
                            className='btn btn-sm btn-danger'>Decline</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default ModalDecline;