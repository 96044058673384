import React from 'react';
import { StatusIcon, TableList, UploadFile } from '../../../components';
import useJobAssigment, { JobProvider } from './useJobAssigment';

import { AiOutlineCloudUpload } from 'react-icons/ai'
function UploadPage() {
    const { jobAction, jobState } = useJobAssigment()

    return (<>
        <div className='row'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='card-header'>
                        <div className='card-title'>
                            <h6>
                                Upload Job Assignment [{`${jobState.pageStatus?.detailPage?.competency_name} - ${jobState.pageStatus?.detailPage?.course_name}`}]

                            </h6>
                        </div>
                    </div>
                    <div className='card-body'>
                        <div className='form-group'>
                            <label>Nomor MO (Mechanic Order)</label>
                            <input value={jobState.formUpload.title}  
                                placeholder="Nomor MO (Mechanic Order)"
                                onChange={(e) => jobAction.setFormUpload(p => ({
                                    ...p,
                                    title: e.target.value
                                }))} className='form-control' type='text' />
                        </div>

                        <div className='form-group'>
                            <label>File (PDF/Image)</label>
                            <input
                                ref={jobState.refInputFile}
                                // accept="application/pdf"
                                onChange={(e) => jobAction.setFormUpload(p => ({
                                    ...p,
                                    file: e.target.files[0]
                                }))}
                                //value={jobState.formUpload.file?.name}
                                className='form-control' type='file' />
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="row">
                            <div className='col-12'>
                                <button onClick={() => jobAction.doUpload(jobState.formUpload)} className='btn btn-primary float-right' >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div className='row'>
            <div className='col'>
                <div className='card '>
                    <div className='card-header'>
                        <div className='card-title'>
                            <h5>
                                List Dokumen

                            </h5>
                        </div>
                    </div>
                    <div className='card-body'>
                        <div className='row mb-3'>
                            <div className='col-md-12 col-sm-12'>
                                <TableList
                                    withCard={false}
                                    isLoading={jobState.loadingDoc}
                                    data={jobState.dataDoc || []}
                                    columns={[
                                        {
                                            name: '#',
                                            field: '#',
                                        },
                                        {
                                            name: 'Judul',
                                            field: 'title',
                                        },
                                        {
                                            name: 'Status',
                                            field: 'status',
                                            onRender: ({ status }) => {
                                                return (
                                                    <StatusIcon status={status}/>
                                        //             <span style={{ textTransform: "capitalize" }} className={`
                                        // badge badge-${status === "declined" ? "danger" :
                                        //                     status === "approved" ? "success" :
                                        //                         status === "submitted" ? "primary" : ""
                                        //                 }`}>
                                        //                 {status}
                                        //             </span>
                                                    )
                                            }
                                        },
                                    ]}
                                    withActions={true}
                                    pagination={false}
                                    actionItems={[
                                        {
                                            name: "Lihat Detail",
                                            onClick: (item) => jobAction.openModalDetil(item)
                                        },
                                        {
                                            name: "Delete",
                                            onRender: ({ status }) => status !== "approved",
                                            onClick: (item) => jobAction.doDelete(item)
                                        }
                                    ]}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="card-footer">
                        <div className="row">
                            <div className='col-12'>

                                <button onClick={jobAction.resetPage} className='btn btn-default' >
                                    Back
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </>)
}

export default UploadPage
