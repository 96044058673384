import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useTirTrainer, { TirTrainerProvider } from "./useTirTrainer";
import { StatusIcon, TableList } from "../../../components";

function Page() {

    const { tirAction, tirState } = useTirTrainer();
    const history = useHistory();
    return (
        <div>
            <section className="content-header">
                <div className="container-fluid">
                    <div className='row'>
                        <div className='col-md-6'>
                            <h4>Development</h4>
                            <h6>Tir For Trainer</h6>
                        </div>
                        <div className='col-md-6'>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-end">
                                    <li className="breadcrumb-item" aria-current="page">
                                        <Link to="/development/tir-for-trainer"> Tir For Trainer </Link> </li>
                                    <li className="breadcrumb-item active" aria-current="page">Detail</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <div className='card'>
                <div className='card-body'>
                    <div className='row mb-3'>
                        <div className='col-md-12 col-sm-12'>
                            <TableList
                                isLoading={tirState.loadingTraining}
                                data={tirState.listTraining || []}
                                withCard={false}
                                columns={[
                                    {
                                        name: '#',
                                        field: '#',
                                    },
                                    {
                                        name: 'Competency',
                                        field: 'competency_name',
                                    },
                                    {
                                        name: 'Course',
                                        field: 'course_name',
                                    },
                                    {
                                        name: 'Form',
                                        field: '',
                                        minWidth: 300,
                                        onRender: (item) => (
                                            <div>
                                                <ul>
                                                    {
                                                        [...(item?.listForm || [])].map((d, i) => (
                                                            <>
                                                                <li className='text-primary' role={'button'}
                                                                    onClick={() => tirAction.editForm(d)}
                                                                >
                                                                    <div className='tw-flex tw-justify-between'>
                                                                        <span>{d?.title}</span>
                                                                        <div><StatusIcon status={d?.status} /></div>
                                                                    </div>
                                                                </li>
                                                            </>
                                                        ))
                                                    }
                                                </ul>
                                                {/* <button
                                                    onClick={() => tirAction.addForm(item)
                                                    }
                                                    className='btn btn-sm btn-primary'>
                                                    Add Form
                                                </button> */}
                                            </div>
                                        )
                                    },
                                    {
                                        name: 'Link Video',
                                        field: '',
                                        minWidth: 300,
                                        onRender: (item) => (
                                            <div>
                                                 <ul>
                                                {
                                                    [...(item?.listVideoUrl || [])].map((d, i) => (
                                                        <>
                                                            <li key={i} className='text-primary tw-mb-[5px]' role={'button'}
                                                                onClick={() => tirAction.setModalTautan({
                                                                    show: true,
                                                                    modeEdit: true,
                                                                    data: { ...d, training_id: item?.training_id }
                                                                })}
                                                            >
                                                                <div className='tw-flex tw-justify-between'>
                                                                    <span>{d?.title}</span>
                                                                    <div><StatusIcon status={d?.status} /></div>
                                                                </div>
                                                            </li>
                                                        </>
                                                    ))
                                                }
                                            </ul>
                                            {/* dikomen untuk sementara waktu */}
                                            {/* <button
                                                onClick={() => tirAction.setModalTautan({
                                                    show: true,
                                                    modeEdit: false,
                                                    data: { training_id: item?.training_id, url: "https://" }
                                                })}
                                                className='btn btn-sm btn-primary'>
                                                Add Link
                                            </button> */}
                                            </div>
                                        )
                                    },
                                    // {
                                    //     name: '',
                                    //     field: '',
                                    //     minWidth: 200,
                                    //     onRender: (item) => (
                                    //         <div>
                                    //             <button className='btn btn-sm btn-success mr-1 mb-1'>Approve</button>
                                    //             <button className='btn btn-sm btn-danger mr-1 mb-1'>Decline</button>
                                    //         </div>
                                    //     )
                                    // },
                                ]}
                                withActions={false}
                                pagination={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default () => <TirTrainerProvider>
    <Page />
</TirTrainerProvider>
