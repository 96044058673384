import React, { useEffect } from 'react';
import FormEditor from '../component/form-editor';
import { useHistory, useLocation, Link } from 'react-router-dom';
import useFetch from '../../../../hooks/useFetch';
import { useModalAlert } from '../../../../components/modalAlert';

function Index(props) {
    const history = useHistory();
    const { state = {} } = useLocation();
    const { doPost } = useFetch();
    const { modalAction } = useModalAlert();
    const {
        closeLoading,
        setDataAlert,
        openLoading,
        setDataConfirm,
        closeConfirm,
    } = modalAction;

    const doSubmit = async function (data) {
        closeConfirm()
        openLoading("Sending data, please wait...");
        try {
            const { status, payload, statusText } = await doPost({
                url: "/api/data-master/add-single-user",
                data: {
                    ...data
                }
            });

            if (status !== 401) {
                if (status === 200 || status === 201) {
                    const { code, msg } = payload;
                    if (code === "00") {
                        setDataAlert({
                            body: msg,
                            show: true,
                            header: "Success",
                            callBack: () => history.replace({
                                pathname: "/master-data/demografi-employee",
                                state: state
                            })
                        })

                    } else setDataAlert({
                        show: true,
                        body: msg,
                        header: "Info",
                        callBack: () => null
                    })
                } else setDataAlert({
                    show: true,
                    body: statusText,
                    header: "Info",
                    callBack: () => null
                })
            }
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Info",
                callBack: () => null
            })
        }
        closeLoading();
    };
    const confirmSubmit = (data = {}) => {
        setDataConfirm({
            show: true,
            body: "Sebelum melanjutkan, silahkan cek kembali. Lanjutkan ?",
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                {
                    name: "Yes", onClick: () => doSubmit(data), className: "btn btn-sm btn-success"
                },
                { name: "No", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    return (
        <div>
            <div className="row">
                <div className="col-6">
                    <section className="content-header">
                        <div className="container-fluid">
                            <h4>Master Data</h4>
                            <h6>Add Demografi Employee</h6>
                        </div>
                    </section>
                </div>
                <div className="col-6">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end">
                            <li className="breadcrumb-item" aria-current="page">
                                Master Data
                            </li>
                            <li className="breadcrumb-item" aria-current="page">
                                <Link to={{
                                    pathname: "/master-data/demografi-employee",
                                    state: state
                                }} >
                                    Demografi Employee
                                </Link>
                            </li>
                            <li
                                className="breadcrumb-item active"
                                aria-current="page"
                            >
                                Add
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <FormEditor edit={false} kembaliOnclick={() => history.replace({
                        pathname: "/master-data/demografi-employee",
                        state: state
                    })} OnValidSubmit={confirmSubmit} />
                </div>
            </div>
        </div>
    );
}

export default Index;