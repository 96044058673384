import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { SwitchRouter } from './router';
import { AutoSwitchLayout } from './layouts';
import { LoadingIcon } from './components';

const AppIndex = () => {

    return ( 
        <>
            <Router basename="/">
                <AutoSwitchLayout>
                    <SwitchRouter />
                </AutoSwitchLayout>
            </Router>
        </>
     );
}
 
export default AppIndex;