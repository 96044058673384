import React from 'react';
import { TableList } from '../../../../components';
import useTirApproval from '../useTirApproval';

function Index(props) {
    const { tirApvAction, tirApvState } = useTirApproval();
    return (
        <div className='card'>
            <div className='card-body'>
                <div className='row mb-3'>
                    <div className='col-md-12 col-sm-12'>
                        <TableList
                            isLoading={tirApvState.loadingJr}
                            data={tirApvState.listJr || []}
                            withCard={false}
                            columns={[
                                {
                                    name: "Nama",
                                    field: "name"
                                },
                                {
                                    name: "NIK",
                                    field: "nik"
                                },                                
                                {
                                    name: "",
                                    className: "text-right",
                                    onRender: (item) => <>
                                        <button
                                            className='btn btn-sm btn-primary'
                                            onClick={() => tirApvAction.toListPage(item?.nik)}
                                        > Detail </button>
                                    </>
                                }
                            ]}
                            withActions={false}
                            pagination={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;