import React, { useEffect } from 'react';
import { TableList } from '../../../components';
import useNtRd, { NtRdProvider } from './useTabRead';

function TabRead(props) {
    const { ntRdAction, ntRdState } = useNtRd();
    return (
        <div>
            <div className='row m-5 justify-content-center'>
                <div className='col'>
                    <TableList
                        curPage={ntRdState.pagination?.page}
                        isLoading={ntRdState?.loadingGet}
                        withCard={false}
                        minHeight="300px"
                        maxHeight="500px"
                        withActions={false}
                        onChangeShow={ntRdAction.changeShow}
                        onNextPage={ntRdAction.nextPage}
                        onPrevPage={ntRdAction.onPrevPage}
                        defaultShow={ntRdState.pagination?.show}
                        maxPage={ntRdState.dataNotifRead?.totalPage || 0}
                        totalData={ntRdState.dataNotifRead?.totalData}
                        orderData={{
                            order_by: ntRdState.pagination?.order_by,
                            order_type: ntRdState.pagination?.order_type
                        }}
                        onOrdering={ntRdAction.onOrdering}
                        onClickRows={(item, key) => ntRdAction.rowOnclick(item?.url, item?.type, item)}
                        columns={[
                            {
                                name: "Notification readed",
                                field: 'message'
                            },
                        ]}
                        data={[...(ntRdState.dataNotifRead?.listData || [])]}
                    />
                </div>
            </div>
        </div>
    );
}

export default (props) => <NtRdProvider><TabRead {...props} /></NtRdProvider>;