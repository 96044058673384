import React from "react";
import { Link } from "react-router-dom";
import * as pages from "./pages";
import useAttRatio, { AttProvider } from "./useAttRatio";

let pageName = Object.keys(pages);
function AttPage(props) {
    const {
        attState: { pageStatus },
        attAction: {
         toPage = ()=>null
        }
    } = useAttRatio();

    if (!pageName.some((v) => v === pageStatus.rendered)) {
        return <>Page Not Found</>;
    }
    const PageRendered = pages[pageStatus.rendered];
    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <h4>Attended Ratio</h4>
                            <h6>{pageStatus.title}</h6>
                        </div>
                        <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb justify-content-end">
                                       {
                                          pageStatus.rendered !== "ListJobs" && (
                                             <li
                                                className="breadcrumb-item"
                                                onClick={() => toPage("ListJobs", "List Jobsites", {})
                                                      // phmAction.toListTraineePage()
                                                }
                                             >
                                                <Link to={"#"}>List Jobsite</Link>
                                             </li>
                                          )
                                       }
                                        <li
                                            className="breadcrumb-item active"
                                            aria-current="page"
                                        >
                                            {pageStatus.title}
                                        </li>
                                    </ol>
                                </nav>
                        </div>
                    </div>
                </div>
            </section>
            <PageRendered {...props} />
        </>
    );
}

export default (props) => (
    <AttProvider>
        <AttPage {...props} />
    </AttProvider>
);
