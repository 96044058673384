import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useModalAlert } from "../../../components/modalAlert";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { useParams, useHistory } from 'react-router-dom';

const context = createContext({
    assNonIdpState: {},
    assNonIdpAction: {}
});

export function AssNonIdpProvider({ children }) {
    const { tipe = "" } = useParams();
    const history = useHistory();
    const queryParam = useQueryParams();
    const id = queryParam.get("id");
    const [formData, setFormData] = useState({});
    const [disabledForm, setDisabledForm] = useState(true);
    const [pagination, setPagination] = useState({
        page: 1,
        show: 10,
        totalPage: 1,
        totalData: 0,
    });

    const [filter, setFilter] = useState({
        search: "",
        nik: "",
        batch: "",
        type: ""
    });
    const { doGet, doPost, wait } = useFetch();
    const { modalAction } = useModalAlert();
    const {
        closeLoading,
        setDataAlert,
        setDataConfirm,
        closeConfirm,
        openLoading
    } = modalAction;
    const [loadingList, setLoadingList] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [dataDetail, setDataDetail] = useState({});
    const [dataJobsite, setDataJobsite] = useState([]);
    const getDataList = useCallback(
        async function (page = pagination.page) {
            setLoadingList(true);
            try {
                const { status, statusText, payload } = await doPost({
                    url: "/api/data-master/assessment-non-idp/list",
                    data: {
                        ...filter,
                        page: page,
                        row_in_page: pagination.show,
                    },
                });

                if (status === 401) {
                    closeLoading();
                } else if (status === 200) {
                    const { data = {}, code, msg } = payload;
                    if (code === "00") {
                        setPagination((v) => ({
                            ...v,
                            totalPage: data.totalPage,
                            totalData: data.totalData,
                        }));
                        setDataList(data.listData || []);
                        setFilter((prev) => ({ ...prev, batch: data?.batch, nik: data?.nik }));
                    } else
                        setDataAlert({
                            show: true,
                            body: msg,
                            header: "Get List Training",
                            callBack: () => null,
                        });
                } else
                    setDataAlert({
                        show: true,
                        body: statusText,
                        header: "Get List Training",
                        callBack: () => null,
                    });
            } catch (error) {
                setDataAlert({
                    show: true,
                    body: error.toString(),
                    header: "Get List Training",
                    callBack: () => null,
                });
            }
            setLoadingList(false);
        },
        [filter, pagination]
    );
    async function getAllJobsite() {
        try {
            const { status, statusText, payload } = await doGet({
                url: '/api/list-all-jobsite',
            });
            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDataJobsite(data?.jobsites);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get All Jobsite",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get All Jobsite",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get All Jobsite",
                callBack: () => null
            })
        }
    }
    const getDetail = useCallback(
        async function () {
            setLoadingList(true);
            try {
                const { status, statusText, payload } = await doGet({
                    url: `/api/data-master/assessment-non-idp/${id}`,
                });

                if (status === 401) {
                    closeLoading();
                } else if (status === 200) {
                    const { data = {}, code, msg } = payload;
                    if (code === "00") {
                        setFormData({ ...data?.data });
                        setDataDetail(data?.data);
                    } else
                        setDataAlert({
                            show: true,
                            body: msg,
                            header: "Get Detail Training",
                            callBack: () => null,
                        });
                } else
                    setDataAlert({
                        show: true,
                        body: statusText,
                        header: "Get Detail Training",
                        callBack: () => null,
                    });
            } catch (error) {
                setDataAlert({
                    show: true,
                    body: error.toString(),
                    header: "Get Detail Training",
                    callBack: () => null,
                });
            }
            setLoadingList(false);
        },
        [id]
    );

    const doCreate = () => {
        setDataConfirm({
            show: true,
            body: "Apakah anda yakin?",
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                {
                    name: "Yes",
                    onClick: async () => {
                        closeConfirm();
                        await tambahData();
                    },
                    className: "btn btn-sm btn-success",
                },
                {
                    name: "No",
                    onClick: closeConfirm,
                    className: "btn btn-sm btn-secondary",
                },
            ],
        });
    }
    const tambahData = useCallback(
        async function () {
            openLoading("Tambah Data ...");
            try {
                const { status, statusText, payload } = await doPost({
                    url: `/api/data-master/assessment-non-idp`,
                    method: "POST",
                    data: {
                        nik: formData?.nik,
                        assessment_name: formData?.assessment_name,
                        date: formData?.date,
                        assessor_nik: formData?.assessor_nik,
                        assessor_name: formData?.assessor_name,
                        score: formData?.score,
                        batch: formData?.batch,
                        type: formData?.type,
                        jobsite_code: formData?.jobsite_code,
                        file: formData?.file || ""
                    },
                    type: 1,
                });

                if (status === 401) {
                    closeLoading();
                } else if (status === 200) {
                    const { data = {}, code, msg } = payload;
                    if (code === "00") {
                        setDataAlert({
                            show: true,
                            body: msg,
                            header: "Success",
                            callBack: () => {
                                // getDetail();
                                history.push("/master-data/assessment-non-idp")
                            },
                        });
                    } else
                        setDataAlert({
                            show: true,
                            body: msg,
                            header: "Tambah data",
                            callBack: () => {
                                closeLoading();
                            },
                        });
                } else
                    setDataAlert({
                        show: true,
                        body: statusText,
                        header: "Update data",
                        callBack: () => {
                            closeLoading();
                        },
                    });
            } catch (error) {
                setDataAlert({
                    show: true,
                    body: error.toString(),
                    header: "Update data",
                    callBack: () => null,
                });
            }
            closeLoading();
        },
        [formData,]
    );

    const doUbah = () => {
        setDataConfirm({
            show: true,
            body: "Apakah anda yakin ingin meubah data?",
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                {
                    name: "Yes",
                    onClick: async () => {
                        closeConfirm();
                        await ubahData();
                    },
                    className: "btn btn-sm btn-success",
                },
                {
                    name: "No",
                    onClick: closeConfirm,
                    className: "btn btn-sm btn-secondary",
                },
            ],
        });
    }
    const ubahData = useCallback(
        async function () {
            openLoading("Update Data ...");
            try {
                const { status, statusText, payload } = await doPost({
                    url: `/api/data-master/assessment-non-idp?_method=PUT`,
                    method: "POST",
                    data: {
                        id,
                        nik: formData?.nik,
                        assessment_name: formData?.assessment_name,
                        date: formData?.date,
                        assessor_nik: formData?.assessor_nik,
                        assessor_name: formData?.assessor_name,
                        score: formData?.score,
                        batch: formData?.batch,
                        type: formData?.type,
                        jobsite_code: formData?.jobsite_code,
                        file: formData?.file || ""
                    },
                    type: 1,
                });

                if (status === 401) {
                    closeLoading();
                } else if (status === 200) {
                    const { data = {}, code, msg } = payload;
                    if (code === "00") {
                        setDataAlert({
                            show: true,
                            body: msg,
                            header: "Success",
                            callBack: () => {
                                // getDetail();
                                history.push("/master-data/assessment-non-idp")
                            },
                        });
                    } else
                        setDataAlert({
                            show: true,
                            body: msg,
                            header: "Update data",
                            callBack: () => {
                                closeLoading();
                            },
                        });
                } else
                    setDataAlert({
                        show: true,
                        body: statusText,
                        header: "Update data",
                        callBack: () => {
                            closeLoading();
                        },
                    });
            } catch (error) {
                setDataAlert({
                    show: true,
                    body: error.toString(),
                    header: "Update data",
                    callBack: () => null,
                });
            }
            closeLoading();
        },
        [formData,]
    );

    const doDelete = (idDel) => {
        setDataConfirm({
            show: true,
            body: "Apakah akan menghapus data?",
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                {
                    name: "Yes",
                    onClick: async () => {
                        closeConfirm();
                        await deleteData(idDel);
                    },
                    className: "btn btn-sm btn-success",
                },
                {
                    name: "No",
                    onClick: closeConfirm,
                    className: "btn btn-sm btn-secondary",
                },
            ],
        });
    }
    const deleteData = useCallback(
        async function (idDelete) {
            openLoading("Menghapus Data ...");
            try {
                const { status, statusText, payload } = await doPost({
                    url: `/api/data-master/assessment-non-idp/${idDelete}`,
                    method: "DELETE",
                });

                if (status === 401) {
                    closeLoading();
                } else if (status === 200) {
                    const { data = {}, code, msg } = payload;
                    if (code === "00") {
                        setDataAlert({
                            show: true,
                            body: msg,
                            header: "Success",
                            callBack: () => {
                                getDataList();
                            },
                        });
                    } else
                        setDataAlert({
                            show: true,
                            body: msg,
                            header: "Delete data",
                            callBack: () => {
                                closeLoading();
                            },
                        });
                } else
                    setDataAlert({
                        show: true,
                        body: statusText,
                        header: "Delete data",
                        callBack: () => {
                            closeLoading();
                        },
                    });
            } catch (error) {
                setDataAlert({
                    show: true,
                    body: error.toString(),
                    header: "delete data",
                    callBack: () => null,
                });
            }
            closeLoading();
        },
        [getDataList]
    );


    function nextPage({ page, limit }) {
        setPagination((prevState) => ({
            ...prevState,
            page,
            show: limit,
        }));
    }
    function prevPage({ page, limit }) {
        setPagination((prevState) => ({
            ...prevState,
            page,
            show: limit,
        }));
    }
    function changeShow({ page, limit }) {
        setPagination((prevState) => ({
            ...prevState,
            page,
            show: limit,
        }));
    }

    function handleChangeFom(name, val) {
        setFormData((prev) => ({
            ...prev,
            [name]: val
        }));
    }
    function doFilter() {
        getDataList(1);
        setPagination((v) => ({
            ...v,
            page: 1,
        }));
    }

    const doUpload = useCallback(
        function () {
            let body = "Pastikan form terisi dengan benar. Lanjutkan?";
            if (!formData?.type) {
                return setDataAlert({
                    body: "Harap pilih tipe",
                    show: true,
                    header: "Info",
                    callBack: () => { },
                });
            }
            if (!formData.file) {
                return setDataAlert({
                    body: "File Belum ada",
                    show: true,
                    header: "Info",
                    callBack: () => { },
                });
            }
            setDataConfirm({
                show: true,
                body,
                witTextArea: false,
                header: "Confirmation",
                title: "",
                closeButton: false,
                footerButton: [
                    {
                        name: "Yes",
                        onClick: async () => {
                            closeConfirm();
                            openLoading("Uploading ...");
                            try {
                                const { status, payload, statusText } =
                                    await doPost({
                                        url: "/api/data-master/assessment-non-idp/add-bulk",
                                        data: {
                                            ...formData,
                                        },

                                        type: 1,
                                        paramImage: "file",
                                    });

                                if (status !== 401) {
                                    if (status === 200 || status === 201) {
                                        const { code, msg } = payload;
                                        if (code === "00") {
                                            setDataAlert({
                                                body: "Unggah file berhasil",
                                                show: true,
                                                header: "Success",
                                                callBack: () => {
                                                    getDataList();
                                                    setFormData({});
                                                },
                                            });
                                        } else
                                            setDataAlert({
                                                show: true,
                                                body: msg,
                                                header: "Info",
                                                callBack: () => null,
                                            });
                                    } else
                                        setDataAlert({
                                            show: true,
                                            body: statusText,
                                            header: "Info",
                                            callBack: () => null,
                                        });
                                }
                            } catch (error) {
                                setDataAlert({
                                    show: true,
                                    body: error.toString(),
                                    header: "Info",
                                    callBack: () => null,
                                });
                            }
                            closeLoading();
                        },
                        className: "btn btn-sm btn-success",
                    },
                    {
                        name: "No",
                        onClick: closeConfirm,
                        className: "btn btn-sm btn-secondary",
                    },
                ],
            });
        },
        [formData, getDataList]
    );
    useEffect(() => {
        if (tipe === "") {
            getDataList();
        } else if (tipe === "add") {
            setDisabledForm(false);
            getAllJobsite();
        } else if (tipe === "ubah" && id) {
            setDisabledForm(false);
            getDetail(id);
            getAllJobsite();
        } else if (tipe === "detail" && id) {
            setDisabledForm(true);
            getDetail(id);
            getAllJobsite();
        } else {
            history.push("/master-data/assessment-non-idp");
        }
    }, [pagination.page, pagination.show, tipe, id]);
    return <context.Provider
        value={{
            assNonIdpState: {
                loadingList,
                dataList,
                dataDetail,
                pagination,
                formData,
                tipe,
                disabledForm,
                filter,
                dataJobsite
            },
            assNonIdpAction: {
                nextPage,
                prevPage,
                changeShow,
                handleChangeFom,
                setFilter,
                doCreate,
                doUbah,
                doDelete,
                doFilter,
                doUpload
            }
        }}
    >{children}</context.Provider>
}

export default function useAssNonIdp() {
    return useContext(context);
}
