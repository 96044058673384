import moment from 'moment';
import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import { SelectSearch, DateInput, TableList } from '../../../../components';
import useCrtSch from '../useCrtSch';

function ModalCreate(props) {
    const { crtSchAction: {
        closeModalCreate = () => null,
        getTrainer = () => null,
        handleChange = () => null,
        getJobsite = () => null,
        confirmSave = () => null,
        onFilterTrainee = () => null,
        setFilter = null,
    }, crtSchState: {
        loadingTrainee = false,
        listTrainee = [],
        listTrainer = [],
        loadingTrainer = false,
        loadingJobsite = false,
        listJobsite = [],
        modalCreate: {
            show = false,
            modeEdit = false,
            data = {}
        },
        formData = {},
        filter
    } } = useCrtSch();
    return (
        <Modal
            dialogClassName='modal-mts-create'
            backdropClassName='bg-transparent'
            show={show}
            onHide={() => closeModalCreate()}
        >
            <Modal.Header closeButton>
                <Modal.Title>Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className='row'>
                        <div className='col-md-6 mb-3'>
                            <div className="form-group row mb-0">
                                <label htmlFor="staticEmail" className="col-sm-4 col-form-label">Category</label>
                                <div className="col-sm-8">
                                    <span className="form-control-plaintext">{data?.course_type}</span>
                                </div>
                            </div>
                            <div className="form-group row mb-0">
                                <label htmlFor="staticEmail" className="col-sm-4 col-form-label">Training Name</label>
                                <div className="col-sm-8">
                                    <span className="form-control-plaintext">{data?.course_name}</span>
                                </div>
                            </div>
                            <div className="form-group row mb-2">
                                <label htmlFor="staticEmail" className="col-sm-4 col-form-label">Jobsite Assessor</label>
                                <div className="col-sm-8">
                                    <SelectSearch
                                        zIndex={3}
                                        placeholder={loadingJobsite ? "Loading Data" : "Select Jobsite"}
                                        // required={crtMtsState.validated}
                                        options={listJobsite}
                                        onMenuOpen={getJobsite}
                                        keyValue='jobsite_code'
                                        display='jobsite_name'
                                        // disabled={moment(moment(new Date)).isBefore(moment(crtMtsState.formData?.start_date)) ? false : true}
                                        // isInValid={crtMtsState.formData?.trainer_nik === '' && crtMtsState.validated ? true : false}
                                        value={formData?.jobsite_code}
                                        onChange={(val) => handleChange('jobsite_code', val.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row mb-0">
                                <label htmlFor="staticEmail" className="col-sm-4 col-form-label">Assessor</label>
                                <div className="col-sm-8">
                                    <SelectSearch
                                        zIndex={2}
                                        placeholder={loadingTrainer ? "Loading Data" : "Select Trainer"}
                                        // required={crtMtsState.validated}
                                        options={listTrainer}
                                        onMenuOpen={() => getTrainer(formData?.jobsite_code, data?.course_type)}
                                        keyValue='nik'
                                        display='name'
                                        disabled={formData.jobsite_code ? false : true}
                                        // isInValid={crtMtsState.formData?.trainer_nik === '' && crtMtsState.validated ? true : false}
                                        value={formData?.trainer_nik}
                                        onChange={(val) => handleChange('trainer_nik', val.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 mb-3'>
                            {/* <div className="form-group row mb-2">
                                <label htmlFor="staticEmail" className="col-sm-4 col-form-label pt-0">Kuota Training</label>
                                <div className="col-sm-8">
                                    <span className='text-wrap'>{props?.data?.quota}</span>
                                </div>
                            </div> */}
                            <div className="form-group row mb-2">
                                <label htmlFor="staticEmail" className="col-sm-4 col-form-label pt-0">Start</label>
                                <div className="col-sm-8">
                                    <DateInput className='form-control'
                                        popperClassName='z-index-date-input'
                                        required
                                        disabled={formData?.trainer_nik ? false : true}
                                        // minDate={new Date()}
                                        value={formData?.start_date}
                                        onChange={(val) => handleChange('start_date', val)}
                                    />
                                </div>
                            </div>
                            {/* <div className="form-group row mb-2">
                                <label htmlFor="staticEmail" className="col-sm-4 col-form-label pt-0">Duration</label>
                                <div className="col-sm-8">
                                    <input type='number' className='form-control' min={1}
                                        required
                                        value={crtMtsState.formData?.duration}
                                        onChange={(val) => crtMtsAction.handleChange('duration', val.target.value)}
                                    />
                                </div>
                            </div> */}
                            <div className="form-group row mb-2">
                                <label htmlFor="staticEmail" className="col-sm-4 col-form-label pt-0">End Date</label>
                                <div className="col-sm-8">
                                    <DateInput className='form-control'
                                        popperClassName='z-index-date-input'
                                        required
                                        disabled={formData.start_date ? false : true}
                                        minDate={new Date(formData?.start_date)}
                                        value={formData?.end_date}
                                        onChange={(val) => handleChange('end_date', val)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <div className='col-md-12'>
                            <h6 className='font-weight-bold'>Assessee</h6>
                            {
                                // ([...(formData?.training_id || [])].length <= 0 && validated) && (
                                //     <span className='text-danger'>Trainee tidak boleh kosong</span>
                                // )
                            }
                            <TableList
                                filter={true}
                                formsFilter={[
                                    {
                                        type: 'text',
                                        placeholder: 'NIK',
                                        value: filter?.nik,
                                        onChange: (val) => setFilter(e => ({ ...e, nik: val }))
                                    },
                                    {
                                        type: 'text',
                                        placeholder: 'Nama',
                                        value: filter?.name,
                                        onChange: (val) => setFilter(e => ({ ...e, name: val }))
                                    }
                                ]}
                                resetFilter={false}
                                onFilter={onFilterTrainee}
                                isLoading={loadingTrainee}
                                withCard={false}
                                minHeight="300px"
                                submitButton={<><i className='fa fa-search'></i> Search</>}
                                maxHeight="300px"
                                navtable={"TABLE 1"}
                                pagination={true}
                                withActions={false}
                                selectionMode={2}
                                onSelection={(e) => handleChange('training_id', e)}
                                fieldSelectionKey="training_id"
                                defaultSelection={[...(formData?.training_id || [])]}

                                // onChangeShow={crtMtsAction.changeShow}
                                // onNextPage={crtMtsAction.nextPage}
                                // onPrevPage={crtMtsAction.onPrevPage}
                                // defaultShow={crtMtsState.pagination?.show}
                                // curPage={crtMtsState.pagination?.page}
                                // maxPage={crtMtsState.dataTrainee?.totalPage || 0}
                                // orderData={{
                                //     order_by: crtMtsState.pagination.order_by,
                                //     order_type: crtMtsState.pagination.order_type
                                // }}
                                // onOrdering={crtMtsAction.onOrdering}
                                // totalData={crtMtsState.dataTrainee?.totalData || 0}

                                columns={[
                                    {
                                        name: "#",
                                        field: '#'
                                    },
                                    {
                                        name: "Assessee NIK",
                                        field: 'nik',
                                    },
                                    {
                                        name: "Assessee Name",
                                        field: 'name',
                                    },
                                ]}
                                data={[...listTrainee]}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12 text-md-right'>
                            <button type='button' className='btn btn-sm btn-secondary mr-1'
                                onClick={closeModalCreate}
                            >Batal</button>
                            <button className='btn btn-sm btn-primary'
                                onClick={() => confirmSave(formData)}
                            >Save</button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ModalCreate;
