import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useModalAlert } from "../../../../components/modalAlert";
import useFetch from "../../../../hooks/useFetch";

const lsIdpContext = createContext({
    lsIdpState: {},
    lsIdpAction: {}
});

export function LsIdpProvider({ children }) {
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const { doGet, doPost } = useFetch();
    const history = useHistory();
    const [dataState, setdataState] = useState(history.location.state);
    const [dataGet, setDataGet] = useState({});
    const [loadingGet, setLoadingGet] = useState(false);
    const [modal, setModal] = useState({
        show: false,
        data: {},
        modeEdit: false
    });
    const [modalTrainee, setModalTrainee] = useState({
        show: false,
        data: [],
    });
    const [batch, setBatch] = useState(dataState?.batch);
    const [course_name, setCourse_name] = useState("");
    const [cluster, setCluster] = useState("");
    const [group_competency, setGroup_competency] = useState("");
    const [pagination, setPagination] = useState({
        page: 1, row_in_page: 10
    });

    async function getList() {
        try {
            setLoadingGet(true);
            const { status, statusText, payload } = await doPost(
                {
                    url: '/api/mts/list-idp',
                    data: {
                        jobsite_code: dataState?.jobsite_code,
                        course_name,
                        cluster,
                        group_competency,
                        batch,
                        ...pagination
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setDataGet(data);
                    setBatch(data?.batch);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get List IDP",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List IDP",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Jobsite",
                callBack: () => null
            })
        }
        setLoadingGet(false)
    }
    function confirmSubmit(data = {}) {
        // let formType = paramUrl.mode === "edit" ? "ubah data" : "pendaftaran";
        let body = `Sebelum melanjutkan, cek kembali data yang disubmit. Lanjutkan ?`;
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Yes", onClick: () => doSubmit(data), className: "btn btn-sm btn-success" },
                { name: "No", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }

    async function doSubmit(data = {}) {
        closeConfirm()
        openLoading("Sending data, please wait...");
        try {
            const { status, payload, statusText } = await doPost({
                url: "/api/mts/store-mts",
                data: {
                    ...data
                }
            });

            if (status !== 401) {
                if (status === 200 || status === 201) {
                    const { code, msg } = payload;
                    if (code === "00") {
                        setDataAlert({
                            body: msg,
                            show: true,
                            header: "Success",
                            callBack: () => refresh()
                        })
                    } else setDataAlert({
                        show: true,
                        body: msg,
                        header: "Info",
                        callBack: () => null
                    })
                } else setDataAlert({
                    show: true,
                    body: statusText,
                    header: "Info",
                    callBack: () => null
                })
            }
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Info",
                callBack: () => null
            })
        }
        closeLoading();
    }
    function nextPage({ page, limit }) {
        setPagination((prevState) => ({
            ...prevState,
            page,
            row_in_page: limit,
        }));
    }
    function prevPage({ page, limit }) {
        setPagination((prevState) => ({
            ...prevState,
            page,
            row_in_page: limit,
        }));
    }
    function changeShow({ page, limit }) {
        setPagination((prevState) => ({
            ...prevState,
            page,
            row_in_page: limit,
        }));
    }

    function onFilter() {
        getList();
    }
    function refresh() {
        setModal({
            show: false,
            data: {},
            modeEdit: false
        });
        getList()
    }
    useEffect(() => {
        if (!dataState?.jobsite_code) {
            return setDataAlert({
                show: true,
                body: "Buka halaman ini melalui menu ATS Create",
                header: "Jobsite tidak di pilih",
                callBack: () => null
            })
        }
        getList();
    }, [pagination]);

    return <lsIdpContext.Provider
        value={{
            lsIdpState: {
                dataGet,
                loadingGet,
                dataState,
                modal,
                batch,
                modalTrainee,
                pagination,
                course_name,
                cluster,
                group_competency
            },
            lsIdpAction: {
                setModal,
                confirmSubmit,
                onFilter,
                setBatch,
                setCourse_name,
                setCluster,
                setGroup_competency,
                setModalTrainee,
                nextPage,
                prevPage,
                changeShow
            }
        }}
    >{children}</lsIdpContext.Provider>
}
export default function useLsIdp() {
    return useContext(lsIdpContext);
}