import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useModalAlert } from '../../../components/modalAlert';
import useFetch from '../../../hooks/useFetch';

const IHMOContext = createContext({
    HMOActions: {},
    HMOState: {}
});

export const HMOProvider = ({ children }) => {

    const [pageStatus, setPageStatus] = useState({
        rendered: "ListTraining",
        paramState: {},
        isLoading: true
    });
    const { doGet, doPost } = useFetch();
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;
    const [listTraining, setListTraining] = useState([]);
    const [formHm, setFormHm] = useState({
        "trainingId": null,
        "date": null,
        "shift": null,
        "trainingUnit": null,
        "competecyStd": null,
        "description": null,
        "hmOn": 0,
        "hmOff": 0,
        "hmTotal": 0
    });
    useEffect(function(){
        // console.log(formHm.hmOff, formHm.hmOn)
        let total = parseInt(formHm.hmOff)-parseInt(formHm.hmOn)
        setFormHm( v => ({
            ...formHm,
            hmTotal :total
        }))
    },[formHm.hmOff, formHm.hmOn])
    const [listHm, setListHm] = useState([])
    const resetPage = useCallback(function () {
        setPageStatus({
            rendered: "ListTraining",
            paramState: {},
            isLoading: false
        })
        resetFormHm()
    }, [setPageStatus])

    const toInputPage = useCallback(function (paramState) {
        setFormHm( v => ({
            ...v,
            trainingId: paramState.training_id
        }))
        setPageStatus({
            rendered: "Input",
            paramState,
            isLoading: true
        })
    }, [setPageStatus])

    const setLoadingPage = (isLoading) => setPageStatus(v => ({ ...v, isLoading }))

    const getListTraining = useCallback(async function () {
        setLoadingPage(true);

        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/hm/training-trainee',
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setListTraining(data || []);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get List Training",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List Training",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Training",
                callBack: () => null
            })
        }
        setLoadingPage(false)
    }, [pageStatus])

    const resetFormHm = () => setFormHm({
        "trainingId": null,
        "date": null,
        "shift": null,
        "trainingUnit": null,
        "competecyStd": null,
        "description": null,
        "hmOn": 0,
        "hmOff": 0,
        "hmTotal": 0
    })
    const getListHm = useCallback(async function () {
        setLoadingPage(true);

        try {
            const { status, statusText, payload } = await doGet(
                {
                    url: '/api/hm/mylist',
                    param: {
                        training_id: pageStatus.paramState.training_id
                    }
                }
            )

            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    // console.log(data)
                    setListHm(data || []);
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get List Hm",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get List Hm",
                callBack: () => null
            })
        } catch (error) {
            setDataAlert({
                show: true,
                body: error.toString(),
                header: "Get List Hm",
                callBack: () => null
            })
        }
        setLoadingPage(false)
    }, [pageStatus])

    const postHm = useCallback(async function () {
        let body = "Pastikan form terisi dengan benar. Lanjutkan?"
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                {
                    name: "Yes", onClick: async () => {
                        closeConfirm();
                        openLoading("Uploading ...")
                        try {
                            const { status, payload, statusText } = await doPost({
                                url: "/api/hm/store",
                                data: {
                                    ...formHm
                                },
                            });

                            if (status !== 401) {
                                if (status === 200 || status === 201) {
                                    const { code, msg } = payload;
                                    if (code === "00") {
                                        setDataAlert({
                                            body: msg,
                                            show: true,
                                            header: "Success",
                                            callBack: () => {
                                                getListHm();
                                                resetFormHm()
                                            }
                                        })
                                    } else setDataAlert({
                                        show: true,
                                        body: msg,
                                        header: "Info",
                                        callBack: () => null
                                    })
                                } else setDataAlert({
                                    show: true,
                                    body: statusText,
                                    header: "Info",
                                    callBack: () => null
                                })
                            }
                        } catch (error) {
                            setDataAlert({
                                show: true,
                                body: error.toString(),
                                header: "Info",
                                callBack: () => null
                            })
                        }
                        closeLoading()


                    }, className: "btn btn-sm btn-success"
                },
                { name: "No", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }, [formHm, pageStatus])
    const doDelete = useCallback(function ( hm_id) {
        let body = "Dokument yang dihapus, akan hilang secara permanen. Lanjutkan?"
        setDataConfirm({
            show: true,
            body,
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                {
                    name: "Yes", onClick: async () => {
                        closeConfirm();
                        openLoading("Deletting data ...")
                        try {
                            const { status, payload, statusText } = await doPost({
                                url: `/api/hm/${hm_id}`,
                                method:"delete"
                            });

                            if (status !== 401) {
                                if (status === 200 || status === 201) {
                                    const { code, msg } = payload;
                                    if (code === "00") {
                                        setDataAlert({
                                            body: msg,
                                            show: true,
                                            header: "Success",
                                            callBack: () => {
                                                getListHm();
                                                resetFormHm()
                                            }
                                        })
                                    } else setDataAlert({
                                        show: true,
                                        body: msg,
                                        header: "Delete HM",
                                        callBack: () => null
                                    })
                                } else setDataAlert({
                                    show: true,
                                    body: statusText,
                                    header: "Delete HM",
                                    callBack: () => null
                                })
                            }
                        } catch (error) {
                            setDataAlert({
                                show: true,
                                body: error.toString(),
                                header: "Info",
                                callBack: () => null
                            })
                        }
                        closeLoading()


                    }, className: "btn btn-sm btn-success"
                },
                { name: "No", onClick: closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }, [pageStatus])
    useEffect(function () {
        if (pageStatus.rendered === "ListTraining") getListTraining()
        if (pageStatus.rendered === "Input") getListHm()
    }, [pageStatus.rendered])

    return <IHMOContext.Provider value={{
        HMOActions: {
            setPageStatus,
            resetPage,
            toInputPage,
            doDelete,
            setFormHm,
            postHm
        },
        HMOState: {
            listTraining,
            pageStatus,
            formHm, listHm
        }
    }}>{children}</IHMOContext.Provider>
}

export default () => useContext(IHMOContext);
