import React from 'react';
import Cookies from 'universal-cookie';
import { useModalAlert } from '../components/modalAlert';
import useAuth from '../context/useAuth';
// import useLocalStorage from '../contexts/useLocalStorage';

const cookie = new Cookies();

export default function () {
    const { modalState, modalAction } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;

    // const [tokenJWT, setTokenJWT] = useLocalStorage("token", null);
    //cookie.get("access_token");
    const { doLogout, getToken } = useAuth()
    async function wait(n) {
        return new Promise((resolve) => {
            setTimeout(() => resolve(), n);
        });
    }
    const doGet = async function ({
        param = {},
        url,
        token = true,
        headers = null,
    }) {

        let uri = url


        if (param != null) {
            var keys = Object.keys(param);
            keys.forEach((val, key) => {
                if (key == 0) uri = uri + '?';
                uri = uri + (val + '=' + param[val]);
                if (key != keys.length - 1) uri = uri + '&';
            });
        }

        try {

            let resp = await fetchApi({ url: `${uri}`, token, opts: { method:"GET", headers } });
            return resp;
        } catch (error) {
            return error.toString()
        }
    }


    async function doGetMultiple(options = []) {
        const response = await Promise.all(options.map(v => doGet(v)));
        var resp = {

        }
        options.map(({ responseVar }, i) => {
            resp = {
                ...resp,
                [responseVar]: response[i]
            }
        });
        return resp;
    }

    // contoh multiple get
    // function getMultiple(){
    //     let { view, detail } = doGetMultiple([
    //         { url: `/api/ext-partnership/view/${props.data.data?.id}`, responseVar: "view"},
    //         { url: `/api/ext-partnership/detail/${props.data.data?.id}`, responseVar: "detail"},
    //     ])
    // }
    async function doPostMultiple(options = []) {
        const response = await Promise.all(options.map(v => doPost(v)));
        var resp = {

        }
        options.map(({ responseVar }, i) => {
            resp = {
                ...resp,
                [responseVar]: response[i]
            }
        });
        return resp;
    }

    async function doPost({
        url = "/",
        data = {},
        type = 0,
        paramImage = '',
        method = 'POST',
        token = true,
        headers = null,
        queryParam = null
    }, callback = () => null) {
        var opts = {
            ...(data === null ? {} : { body: type === 0 ? JSON.stringify(data) : data }),
            method,
        };
        if (headers !== null) opts = {
            ...(data === null ? {} :
                {
                    body: JSON.stringify(data)
                }
            ), headers,
            method
        };

        if (type == 1) {
            var keys = Object.keys(data);
            var form = new FormData();

            keys.forEach((val, key) => {
                if (val === 'img' && val === 'image' && val === paramImage) {
                    form.append(val, data[val], data[val].name);
                } else {
                    form.append(val, data[val]);
                }
                // // // // console.log(form.values())
            });
            opts = {
                body: form,
                method,
                headers: {}
            };
        }
        if (type == 2) {
            opts = {
                body: JSON.stringify(data),
                method,
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            };
        }


        let uri = url


        if (queryParam != null) {
            var keys = Object.keys(queryParam);
            keys.forEach((val, key) => {
                if (key == 0) uri = uri + '?';
                uri = uri + (val + '=' + queryParam[val]);
                if (key != keys.length - 1) uri = uri + '&';
            });
        }
        const resp = await fetchApi({ url: `${ uri}`, opts, token });
        callback();
        return resp;
    }

    async function fetchApi(
        {
            url = "/",
            opts = {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            },
            token = true,
            typeResponse = 'json'
        }
    ) {

        var data = {};
        let headers = opts.headers;
        const tokenJWT = await getToken() 
        // console.log("header", headers)
        if (token) {
            if (headers === undefined || headers === null) {
                opts = {
                    ...opts,
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${tokenJWT}`
                    }
                }
            } else {
                opts = {
                    ...opts,
                    headers: {
                        ...headers,
                        Authorization: `Bearer ${tokenJWT}`
                    }
                }
            }
        }
        // console.log(opts)
        try {
            var fetchResponse = await fetch(`${url}`, opts);
            // if(moment(lastActivity).add(5, "minutes") < moment(Date.now())){
            //     setLastActivity(undefined)
            //     setDataAlert({ show: true, body: "Maaf sesi login telah habis. silahkan login kembali", titile: `Session habis`, callBack: () =>  keycloak.logout() });
            //     return { statusCode : 401, statusText: "Session Habis", remark:"Maaf sesi login telah habis. silahkan login kembali"  }
            // }else{
            //     setLastActivity((Date.now()))
            // }
            // if (fetchResponse.status === 401 || fetchResponse.status === 403) {

            //     setDataAlert({ show: true, body: "Maaf sesi login telah habis. silahkan login kembali", titile: `Session habis`, callBack: () => keycloak.logout() });
            //     return { statusCode: 401, statusText: "Session Habis", remark: "Maaf sesi login telah habis. silahkan login kembali" }
            // }
            if(fetchResponse.status === 401){
                // // console.log(fetchResponse.status)
                closeLoading()
                // doLogout()
                setDataAlert({
                    show: true, body: "Session login has been expired", title: `Expired Session`, callBack: () => doLogout(true)
                })
            }
            if (fetchResponse.ok){
                if(fetchResponse.status === 200){
                    
                    if (typeResponse === 'blob') {
                        data = await fetchResponse.blob();
                        // // // console.log("res")
                    } else {
                        data = await fetchResponse.json();
                        if(token){
                            if(data?.code === "401"){
                                closeLoading()
                                setDataAlert({
                                    show: true, body: data.msg, title: `Expired Session`, callBack: () => doLogout()
                                })
                            }
                        }
            
                    }
                }else data = await fetchResponse.json();
            }
            // else data = fetchResponse.
            return {
                payload: data,
                status: fetchResponse.status,
                statusText: fetchResponse.statusText,
            }
        } catch (err) {
            return {
                statusText: err.toString(),
                status: 99
            }
        }
    }


    return {
        doGet,
        doPost,
        doGetMultiple,
        doPostMultiple,
        wait
    };
}