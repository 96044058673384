import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { useModalAlert } from "../../components/modalAlert";
import { useAuth, useGlobalState } from "../../context";
import useFetch from "../../hooks/useFetch";
import { useLocation, useHistory } from 'react-router-dom';

const navContext = createContext({
    navState: {},
    navAction: {}
});

export function NavProvider({ children }) {
    const { globalState, dispatch, actions } = useGlobalState();
    const {
        modalAction, modalState
    } = useModalAlert();
    const {
        closeAlert,
        closeConfirm,
        closeLoading,
        setDataAlert,
        setDataConfirm,
        setLoading,
        closeConfirmCustom,
        openAlertCustom,
        openLoading
    } = modalAction;

    const { doLogout } = useAuth();
    const [show, setShow] = useState(false);
    const { doPost, doGet } = useFetch();
    const refNew = useRef();
    const refConf = useRef();
    const [oldPass, setOldPass] = useState('');
    const [newPass, setNewPass] = useState('');
    const [confPass, setConfPass] = useState('');
    const { pathname } = useLocation();
    const history = useHistory();

    const [notif, setNotif] = useState({});

    function showModalChangePass() {
        setOldPass('');
        setNewPass('');
        setConfPass('');
        setShow(true);
    }
    function confirmChangePass() {
        modalAction.setDataConfirm({
            show: true,
            body: <>Apa anda yakin ?</>,
            witTextArea: false,
            header: "Confirmation",
            title: "",
            closeButton: false,
            footerButton: [
                { name: "Yes", onClick: () => { modalAction.closeConfirm(); doNext(); }, className: "btn btn-sm btn-success" },
                { name: "No", onClick: modalAction.closeConfirm, className: "btn btn-sm btn-secondary" }
            ]
        })
    }
    function doNext() {
        doPost({
            url: '/api/reset-password',
            data: {
                "old_password": oldPass,
                "password": newPass,
                "password_confirmation": confPass
            }
        }).then((resp) => {
            if (resp.status === 200) {
                if (resp.payload?.code === '00') {
                    setShow(false);
                    modalAction.setDataAlert({
                        show: true,
                        body: <div className="d-flex justify-content-center">
                            <div className="d-flex align-items-center p-2 justify-content-center display-1">
                                <i className="fa fa-check text-success"></i>
                            </div>
                        </div>,
                        header: 'Success',
                        title: resp.payload?.msg,
                        callBack: () => doLogout()
                    })
                } else {
                    modalAction.setDataAlert({ show: true, body: resp.payload?.msg, header: 'Info', title: "", callBack: () => null })
                }
            } else if (resp.status === 401) {
                modalAction.closeLoading();
            }
            else {
                modalAction.setDataAlert({ show: true, body: resp.statusText, header: 'Error', title: "", callBack: () => null })
            }

        })
    }
    async function getNotofication() {
        try {
            const { status, statusText, payload } = await doGet({
                param: {
                    "filter_has_read": 0,
                    "filter_group": "",
                    "filter_name": "",
                    "row_in_page": "5",
                    "page": 1,
                    "order_by": "",
                    "order_type": "asc"
                },
                url: '/api/my-notifications'
            });
            if (status === 401) {
                closeLoading();
            }
            else if (status === 200) {
                const { data = {}, code, msg } = payload;
                if (code === "00") {
                    setNotif({ ...data })
                } else setDataAlert({
                    show: true,
                    body: msg,
                    header: "Get Notification",
                    callBack: () => null
                })
            } else setDataAlert({
                show: true,
                body: statusText,
                header: "Get Notification",
                callBack: () => null
            })
        } catch (error) {

        }
    }
    function notifOnclick(url = '', type = '', data={}) {
        if (type === 'submit_idp') {
            const arr = url.split('=');
            history.push(`/idp-online/approval/detail/${arr[1]}`, {...data});
        }
        if (type === 'approval_idp') {
            history.push(url);
        }
    }
    useEffect(() => {
        // getNotofication();
        actions.getNotification();
    }, [pathname]);
    return <navContext.Provider
        value={{
            navState: {
                show,
                refConf,
                refNew,
                oldPass,
                newPass,
                confPass,
                notif
            },
            navAction: {
                showModalChangePass,
                confirmChangePass,
                setShow,
                setOldPass,
                setNewPass,
                setConfPass,
                notifOnclick
            }
        }}
    >{children}</navContext.Provider>
}

export default function useNav() {
    return useContext(navContext);
}