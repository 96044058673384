import React, { useCallback, useMemo, useState } from 'react';
import useCompReport, { CompReportProvider} from './useCompReport';
import { TableList } from '../../../components';
import { Link, useHistory } from 'react-router-dom';

const samples = Array.from({length: 40}, 
    (v,k) => ({
        "id": k,
        "nik": "10000335",
        "name": "Jefri Pampang",
        "training_name": "Training " + k,
        "date": "2023-10-20",
        "trainer_nik": "10000331",
        "trainer_name": "Endang Kusnandang",
        "score": 90,
        "batch": 2023
    }) 
);
const maxDataByPage = 10;


function Index(props) {
    const { compReportAction, compReportState } = useCompReport();

    const history = useHistory();

    /** start dummy */
    const [pagination, setPagenation] = useState({
        maxDataByPage,
        limit: maxDataByPage,
        currPage: 1,
        page: 1,
        totalPage: Math.ceil(samples.length / maxDataByPage),
        data: samples.slice(0, maxDataByPage),
    });

    const listData = useMemo(() => {
        let nextPage = pagination.page > 0 ? pagination.page - 1 : 0;
        return samples.slice((nextPage * maxDataByPage) + 1, (nextPage * maxDataByPage) + maxDataByPage + 1)
    },[pagination])

    const onChangeShow = useCallback(({ page, limit}) => {
        setPagenation(s => ({ ...s,
            page, 
            limit,
        }));
    },[pagination])

    const onNextPage = useCallback(({ page, limit }) => {
        if(pagination.currPage < samples.length){
            setPagenation(s => ({ ...s,
                page, 
                limit,
                // data: stocks.slice(page, page + maxPages)
            }));
        }
    },[pagination])

    const onPrevPage = useCallback(({ page, limit }) => {
        if(pagination.currPage !== 1){
            setPagenation(s => ({ ...s,
                page, 
                limit,
                // data: stocks.slice(page, page + maxPages)
            }));
        }
    },[pagination])
    
    /** end dummy */

    const CustomToolbar = () => (
        <div className='tw-flex tw-justify-end'>
            <Link to="/master-data/competency-report/add">
                <button className='btn btn-sm btn-success'>
                    Create
                </button>
            </Link>
        </div>
    )

    return (
        <>
            <div className="row">
                <div className="col-6">
                    <section className="content-header">
                        <div className="container-fluid">
                            <h4>Master Data</h4>
                            <h6>Competency Report</h6>
                        </div>
                    </section>
                </div>
                <div className="col-6">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end">
                            <li className="breadcrumb-item" aria-current="page">
                                Master Data
                            </li>
                            <li
                                className="breadcrumb-item active"
                                aria-current="page"
                            >
                                Competency Report
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="tw-flex tw-items-center">
                                <h6>Upload Competency Report</h6>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="tw-grid md:tw-grid-cols-3 tw-grid-cols-1 tw-w-full tw-items-center tw-gap-x-3 tw-gap-y-2">
                                <div>
                                    <label> Type </label>
                                    <select
                                        onChange={(e) => compReportAction.handleChangeFom("type", e.target.value)}
                                        value={compReportState.formData?.type}
                                        className="form-select "
                                    >
                                        <option value={""}>
                                            - Pilih -
                                        </option>
                                        <option value={"Mechanic"}>
                                            Mechanic
                                        </option>
                                        <option value={"Operator"}>
                                            Operator
                                        </option>
                                    </select>
                                </div>
                                <div>
                                    <label>File</label>
                                    <input
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/csv, text/csv, .csv, .xls, .xlsx"
                                        onChange={(e) => compReportAction.handleChangeFom("file", e.target.files[0])}
                                        type="file"
                                        className="form-control"
                                    />
                                </div>
                                <div>

                                    <label>&nbsp;</label>
                                    <button
                                        className="btn lg:btn-sm btn-primary md:tw-mt-8"
                                        onClick={() => {
                                            compReportAction.doUpload()
                                        }}
                                    >
                                        Upload
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div className='tw-grid md:tw-grid-cols-5 tw-grid-cols-1 tw-w-full tw-items-center tw-gap-x-3 tw-gap-y-2'>
                                <div>
                                    <label className="font-weight-normal">Type</label>
                                    <select
                                        onChange={(e) => compReportAction.setFilter(vs => ({ ...vs, type: e.target.value }))}
                                        value={compReportState.filter?.type}
                                        className="form-select form-select-sm"
                                    >
                                        <option value={""}>
                                            - Semua -
                                        </option>
                                        <option value={"Mechanic"}>
                                            Mechanic
                                        </option>
                                        <option value={"Operator"}>
                                            Operator
                                        </option>
                                    </select>
                                </div>
                                <div>
                                    <label className="font-weight-normal">Batch</label>
                                    <input type='number' className='form-control form-control-sm'
                                        placeholder='Batch'
                                        value={compReportState.filter?.batch}
                                        onChange={(e) => compReportAction.setFilter(vs => ({ ...vs, batch: e.target.value }))}
                                    >
                                    </input>
                                </div>
                                <div>
                                    <label className="font-weight-normal">NIK</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="NIK"
                                        value={compReportState.filter?.nik}
                                        onChange={(e) => {
                                            compReportAction.setFilter(vs => ({ ...vs, nik: e.target.value }))
                                        }}
                                    ></input>
                                </div>
                                <div>
                                    <label className="font-weight-normal">Search</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Search"
                                        value={compReportState.filter?.search}
                                        onChange={(e) => {
                                            compReportAction.setFilter(vs => ({ ...vs, search: e.target.value }))
                                        }}
                                    ></input>
                                </div>
                                <div className="tw-flex tw-h-full">
                                    <button className='btn btn-sm btn-primary tw-mt-auto'
                                        onClick={() => compReportAction.doFilter()}
                                    ><i className='fa fa-search'></i> Search</button>
                                </div>
                            </div>
                            <TableList
                                filter={true}
                                // data={compReportState.dataList || []}
                                data={listData}
                                labelFilter=""
                                withCard={false}
                                resetFilter={false}
                                isLoading={compReportState.loadingList}
                                minHeight="300px"
                                maxHeight="500px"
                                navtable={"TABLE 1"}
                                customToolbar={<CustomToolbar />}
                                withButtonSubmit={false}

                                // onChangeShow={compReportAction.changeShow}
                                // onNextPage={compReportAction.nextPage}
                                // onPrevPage={compReportAction.onPrevPage}
                                // defaultShow={compReportState.pagination?.show}
                                // curPage={compReportState.pagination?.page || 1}
                                // maxPage={compReportState.pagination?.totalPage || 1}
                                // totalData={compReportState.pagination?.totalData || 0}

                                /** start dummy */
                                onChangeShow={onChangeShow}
                                onNextPage={onNextPage}
                                onPrevPage={onPrevPage}
                                defaultShow={pagination.maxDataByPage}
                                curPage={pagination.page || 1}
                                maxPage={pagination.totalPage || 1}
                                totalData={samples.length  || 0}
                                /** end dummy */
                                withActions={true}
                                actionType={1}
                                actionItems={[
                                    {
                                        name: "Lihat detail",
                                        onClick: function (item) {
                                            history.push(`/master-data/competency-report/detail?id=${item.id}`)
                                        },
                                    },
                                    {
                                        name: "Ubah",
                                        onClick: function (item) {
                                            history.push(`/master-data/competency-report/ubah?id=${item.id}`)
                                        },
                                    },
                                    {
                                        name: "Hapus",
                                        onClick: function (item) {
                                            compReportAction.doDelete(item.id)
                                        },
                                    },
                                ]}
                                columns={[
                                    {
                                        name: "#",
                                        field: "#",
                                    },
                                    {
                                        name: "Tahun IDP Register",
                                        field: "date",
                                    },
                                    {
                                        name: "Competency Name",
                                        field: "training_name",
                                    },
                                    {
                                        name: "Course Name",
                                        field: "date",
                                    },
                                    {
                                        name: "Point",
                                        field: "trainer_nik",
                                    },
                                    {
                                        name: "Tanggal Training",
                                        field: "date",
                                    },
                                    {
                                        name: "Score Training",
                                        field: "score",
                                    },
                                    {
                                        name: "Trainer",
                                        field: "trainer_name",
                                    },
                                    {
                                        name: "Presensi",
                                        field: "trainer_nik",
                                    },
                                ]}
                            />
                        </div>
                        <div className="card-footer"></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default () => <CompReportProvider><Index /></CompReportProvider>;