import React from 'react';
import { Link } from 'react-router-dom';
import * as pages from './pages';
import useMentorApp, { MenAppProvider } from './useMentorApp';

function Index(props) {
    const { menAppAction: {
        toListPage = () => null
    }, menAppState } = useMentorApp();
    const PageRender = pages[menAppState.pageState.type];
    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <div className='row'>
                        <div className='col'>
                            <h4>Data Mentoring / Observation</h4>
                            <h6>{menAppState.pageState?.title}</h6>
                        </div>
                        <div className='col'>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-end">
                                    {
                                        menAppState.pageState.type === "ListCom" && (
                                            <>
                                                <li className="breadcrumb-item active" aria-current="page">{menAppState.pageState.title}</li>
                                            </>
                                        )
                                    }
                                    {
                                        menAppState.pageState?.type === "FormPage" && (
                                            <>
                                                <li className="breadcrumb-item" aria-current="page"><Link onClick={toListPage}>List Competency</Link></li>
                                                <li className="breadcrumb-item active" aria-current="page">{menAppState.pageState.title}</li>
                                            </>
                                        )
                                    }
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <PageRender />
        </>
    );
}

export default (props) => <MenAppProvider><Index /></MenAppProvider>;